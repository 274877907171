import { Button, FormControl, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Search } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import zhPatientService from "../../../../../services/ZhPatientService";
import DateUtils from "../../../../../utils/DateUtils";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { useTranslation } from "react-i18next";
import {
  getCallingCodeList,
  getCountryDtlByCode,
} from "../../../../../services/AdminService";

export default function FilterPopperBox({ selectPatient }) {
  const { t } = useTranslation();
  const loginUserDetail = getLoginUserDetails();
  const [searchName, setSearchName] = useState("");
  const [searchMobileNumber, setSearchMobileNumber] = useState("");
  const [searchEmailId, setSearchEmailId] = useState("");
  const [cdCodeList, setCdCodeList] = useState([]);
  const [phoneRegexPattern, setPhoneRegexPattern] = useState(/^[0-9]{10,}$/);
  const [emplyPhnCC, setEmplyPhnCC] = useState("");

  const [filteredUser, setFilteredUser] = useState([]);

  function calculateDateDifference(dateString) {
    // Parse the input date string "DD-MM-YYYY"
    const [day, month, year] = dateString.split("-").map(Number);
    const givenDate = new Date(year, month - 1, day);
    const today = new Date();

    let years = today.getFullYear() - givenDate.getFullYear();
    let months = today.getMonth() - givenDate.getMonth();
    let days = today.getDate() - givenDate.getDate();

    // Adjust if days are negative
    if (days < 0) {
      months--;
      days += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }

    // Adjust if months are negative
    if (months < 0) {
      years--;
      months += 12;
    }

    return `${years}Y ${months}M ${days}D`;
  }

  useEffect(() => {
    getCallingCodeList().then((response) => {
      setCdCodeList(response.data);
    });
  }, []);

  useEffect(() => {
    if (loginUserDetail.orgCountryCode) {
      getCountryDtlByCode(loginUserDetail.orgCountryCode).then((response) => {
        // console.log('response' , response.data);
        setEmplyPhnCC(response.data.callingCd);
      });
    }
  }, [loginUserDetail.orgCountryCode]);

  const handleChangeemplyPhnCC = (event) => {
    setEmplyPhnCC(event.target.value);
    switch (event.target.value) {
      case "+91": // India
        setPhoneRegexPattern(/^[6-9]\d{9}$/);
        break;
      case "+52": // Mexico
        setPhoneRegexPattern(/^[1-9]\d{9}$/);
        break;
      case "+971": // UAE
        setPhoneRegexPattern(/^(?:\50|51|52|55|56|2|3|4|6|7|9)\d{7}$/);
        break;
      default:
        setPhoneRegexPattern(/^[0-9]{10,}$/); // Default pattern for other countries
        break;
    }
  };

  const searchFullPatientList = async () => {
    if (searchMobileNumber && !phoneRegexPattern.test(searchMobileNumber)) {
      toast.error(t("please_enter_a_valid_phone_number"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const resSearchPatient = await zhPatientService.getPatListInUnitByDate(
      DateUtils.getDateInYYYYMMDD(new Date(2024, 7, 1)),
      DateUtils.getDateInYYYYMMDD(new Date()),
      loginUserDetail.signleUnitId,
      loginUserDetail.orgId
    );

    if (resSearchPatient?.data) {
      console.log(resSearchPatient?.data);
      const tempFilter = resSearchPatient?.data.filter((item) => {
        const nameMatches = searchName
          ? item?.patientFullName
              ?.toLowerCase()
              .includes(searchName.toLowerCase())
          : true;
        const emailMatches = searchEmailId
          ? item?.patientEmailId === searchEmailId
          : true;

        const contactMatches = searchMobileNumber
          ? item?.patientContactNumber === searchMobileNumber
          : true;

        return nameMatches && emailMatches && contactMatches;
      });
      console.log(tempFilter);
      setFilteredUser(tempFilter);
    }
  };

  return (
    <>
      <div className="rfContContainDetails ">
        <div className="elementFormContainer">
          <div className="tskElemHeding">{t("filter_patient")}</div>
          <div className="formElementGrp ">
            <div className="formElement ">
              <FormControl className="formControl">
                <TextField
                  // hiddenLabel
                  label={t("name")}
                  autoComplete="off"
                  variant="outlined"
                  className="formTextFieldArea"
                  value={searchName}
                  onChange={(e) => {
                    setSearchName(e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </div>
          <div className="formElementGrp">
            <div className="custContactNo ">
              <select
                name="countryCode"
                id="countryCode"
                className="countryCode"
                value={emplyPhnCC}
                onChange={handleChangeemplyPhnCC}
              >
                {/* <option value="+91">+91</option>
                <option value="+1">+1</option>
                <option value="+635">+635</option> */}
                {cdCodeList.map((cdCodeList) => (
                  <option key={cdCodeList} value={cdCodeList}>
                    {cdCodeList}
                  </option>
                ))}
              </select>
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    // hiddenLabel
                    autoComplete="off"
                    label={t("contact_number")}
                    variant="outlined"
                    className="formTextFieldArea"
                    value={searchMobileNumber}
                    onChange={(e) => setSearchMobileNumber(e.target.value)}
                  />
                </FormControl>
              </div>
            </div>

            <div className="formElement ">
              <FormControl className="formControl">
                <TextField
                  // hiddenLabel
                  autoComplete="off"
                  label={t("email_id")}
                  variant="outlined"
                  className="formTextFieldArea"
                  value={searchEmailId}
                  onChange={(e) => setSearchEmailId(e.target.value)}
                />
              </FormControl>
            </div>

            <Button
              startIcon={<Search />}
              className="dfultPrimaryBtn"
              onClick={searchFullPatientList}
            >
              {t("search")}
            </Button>
          </div>
          <div className="bb"></div>

          <div className="filterPatlist scrlList420">
            {filteredUser &&
              filteredUser.map((item) => (
                <>
                  <div
                    className="filterPatlistInd"
                    onClick={() => {
                      zhPatientService.updatePatientDataLastAccessTime(
                        item.rowPatientId
                      );
                      selectPatient(item);
                    }}
                    key={item.rowPatientId}
                  >
                    <div className="filPatImg">
                      <img src={item.patientImageUrl} alt="patImage" />
                    </div>
                    <div className="filtPatDtl">
                      <div className="filtPatNm">
                        {item.patientFullName}{" "}
                        <span>
                          ( {item.patientGender},{" "}
                          {calculateDateDifference(item.patientDob)} )
                        </span>
                      </div>
                      <div className="filtPatOthDtl">
                        <span>
                          {item.patientPhCountryCode}
                          {" " + item.patientContactNumber}
                        </span>
                        <span>{item.patientEmailId}</span>
                      </div>
                    </div>
                  </div>
                </>
              ))}

            {filteredUser && filteredUser.length === 0 && (
              <div className="noDataCard mt10">
                <span>{t("oops")}</span>
                {t("no_data_found")}
              </div>
            )}

            {/* <ul>
              <li>
                <div className="filterPatlistInd">
                  <div className="filPatImg">
                    <img
                      src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="patImage"
                    />
                  </div>
                  <div className="filtPatDtl">
                    <div className="filtPatNm">
                      Sanket Santra <span>( Male, 32Y 6M 12D )</span>
                    </div>
                    <div className="filtPatOthDtl">
                      <span>+917384XXX442</span>
                      <span>uiux-designer@miniontek.com</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="filterPatlistInd">
                  <div className="filPatImg">
                    <img
                      src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="patImage"
                    />
                  </div>
                  <div className="filtPatDtl">
                    <div className="filtPatNm">Sanket Santra</div>
                    <div className="filtPatOthDtl">
                      <span>32Y 6M 12D</span>
                      <span>+917384XXX442</span>
                      <span>uiux-designer@miniontek.com</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </>
  );
}
