import { Add, Edit, Search } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Trash } from "react-bootstrap-icons";
import {
  getRightsToSelect,
  getOrgTermsAndConditions,
  deactivateTermsAndConditions,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function DefineTermsConditions({
  handelOpenCreateTermsConditions,
  refreshTermsConditions,
  handelOpenEditTermsCondition
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [transTypeData, setTransTypeData] = useState([]);

  const [termsAndConditionData, setTermsAndConditionData] = useState([]);

  const [FilterTermsAndConditionData, setFilterTermsAndConditionData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getRightsToSelect("TERMS_CONDITIONS_FOR").then((response) => {
      console.log("termsConditionFor", response.data);
      setTransTypeData(response.data);
    });
  }, []);

  useEffect(() => {
    fetchDataTermCondition();
  }, [userDetails.orgId, transTypeData, refreshTermsConditions]);


  const fetchDataTermCondition = async () => {
    setLoading(true);
    const newData = []; 
    for (let i = 0; i < transTypeData.length; i++) {
      const id = transTypeData[i].id;
      try {
        const response = await getOrgTermsAndConditions(userDetails.orgId, id);
        console.log(`Data for ${id}:`, response.data);
        newData.push(response.data); 
      } catch (error) {
        console.error(`Error fetching data for ${id}:`, error);
      }
    }
    console.log('newData',newData);
    const MyData = newData.flat()
    console.log('MyData', MyData)
    setTermsAndConditionData(MyData);
    setFilterTermsAndConditionData(MyData);
    setLoading(false);
  };
  

  console.log('termsAndConditionData', termsAndConditionData)

 
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredData = termsAndConditionData.filter(
      (term) =>
      term.termsConditionsHeading &&
      term.termsConditionsHeading.toLowerCase().includes(query.toLowerCase())
    );

    setFilterTermsAndConditionData(filteredData);
  };
  
  
  console.log("FilterTermsAndConditionData:", FilterTermsAndConditionData);
  

  const [openModal, setOpenModal] = useState(false);

  const [termAndConditionToDelete, setTermAndConditionToDelete] =
    useState(null);

  const handleDelete = (termId) => {
    console.log("handleDelete", termId);
    setTermAndConditionToDelete(termId);
    setOpenModal(true);
  };

  const confirmDelete = () => {
    if (termAndConditionToDelete) {
      deactivateTermsAndConditions(
        termAndConditionToDelete,
        userDetails.userId
      ).then((response) => {
        console.log("data deleted", response.data);
        if (response.data.returnValue === "1") {
          toast.success(t("deactivate_terms_and_conditions_success"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchDataTermCondition();
          setOpenModal(false);
        }
      });
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className="fullContainArea">
        <div className="cuHeaderGrp">
          {/* <div className="cuhederTxt">Departmment List</div> */}
          <div className="searchTasklist">
            <input
              type="text"
              class="serchTaskInput"
              placeholder={t("terms_and_conditions")}
              value={searchQuery}
              onChange={handleSearch}
            />
            <IconButton className="seacrhIcon">
              <Search />
            </IconButton>
          </div>

          <div className="addUnitsBtn">
            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => handelOpenCreateTermsConditions()}
            >
              {t("terms_and_conditions")}
            </Button>
          </div>
        </div>

        <div className="cuContainArea">
          {loading ? (
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          ) : termsAndConditionData.length === 0 ? (
            <div class="noDataCard ">
              <span>{t("oops")}</span>{t("no_terms_and_condition_define_yet")}
              <br />
              <span className="sallMsg">
              {t("click_add_terms_and_condition_button_to_create_new_terms_and_condition")}
              </span>
            </div>
          ) : (
            <div className="TableContainer">
              <table className="taskListTable">
                <thead className="taskListtableHeader">
                  <tr>
                    <th className="width100">{t("sl_no")}</th>
                    <th>{t("t_and_c_heading")}</th>
                    <th>{t("t_and_c_for")}</th>
                    <th>{t("t_and_c_type")}</th>
                    <th>{t("terms_and_conditions")}</th>
                    <th className="width100">{t("action")}</th>
                  </tr>
                </thead>
                {/* <tbody className="scrolableTableBody">
                  
                    <tr>
                        <td>
                            <div className="tdText">1.</div>
                        </td>
                        <td>
                            <div className="tdText">Payment Mode</div>
                        </td>
                        <td>
                            <div className="tdText">Deliverable</div>
                        </td>
                        <td>
                            <Tooltip arrow title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.">
                              <div className="tskLstTaskDescription">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</div>
                            </Tooltip>
                        </td>
                        <td>
                            <div className="tblActionBtnGrp">
                            <IconButton>
                                <Edit />
                            </IconButton>
                            <IconButton className="removeRowBtn">
                                <Trash />
                            </IconButton>
                            </div>
                        </td>
                    </tr>
                </tbody> */}
                <tbody className="scrolableTableBody">
                  {FilterTermsAndConditionData.map((term, index) =>
                      <tr key={index}>
                        <td>
                          <div className="tdText">{index+1}.</div>
                        </td>
                        <td>
                          <div className="tdText">
                            {term.termsConditionsHeading}
                          </div>
                        </td>
                        <td>
                          <div className="tdText">
                            {term.transType}
                          </div>
                        </td>
                        <td>
                          <div className="tdText">
                            {term.termsConditionsType}
                          </div>
                        </td>
                        <td>
                          <Tooltip arrow title={term.termsConditions}>
                            <div className="tskLstTaskDescription">
                              {term.termsConditions}
                            </div>
                          </Tooltip>
                        </td>
                        <td>
                          <div className="tblActionBtnGrp">
                            <IconButton onClick={()=>handelOpenEditTermsCondition(term)}>
                              <Edit />
                            </IconButton>
                            <IconButton
                              className="removeRowBtn"
                              onClick={() => handleDelete(term.id)}
                            >
                              <Trash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    
                  )}
                </tbody>
              </table>
            </div>
          )}

          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                // width: "250px",
                // height: "250px",
                maxWidth: "600px",
                minHeight: "150px",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <h2 id="modal-modal-title">
              {t("do_you_want_to_delete_this_terms_and_conditions")} ?
              </h2>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  // variant="contained"
                  // color="secondary"
                  className="dfultPrimaryBtn"
                  onClick={confirmDelete}
                >
                  {t("yes")}
                </Button>
                <Button
                  // variant="contained"
                  className="dfultDarkSecondaryBtn"
                  onClick={handleCloseModal}
                >
                  {t("no")}
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}
