import React, { useEffect, useRef, useState } from "react";
import Support from "./Support";

export default function RightSideSupportContainer({
  setShowSupportRightPanel,
}) {
  return (
    <>
      <div className="RightFlotingContainerArea">
        <Support setShowSupportRightPanel={setShowSupportRightPanel} />
      </div>
    </>
  );
}
