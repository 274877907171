import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getLoginUserDetails } from "../../../../utils/Utils";
import ReactDatePicker from "react-datepicker";
import { NumericFormatCustom } from "../../../task/view/component/Forms/NumericFormatCustom";
import { SalaryTable } from "./SalaryTable";
import { Bank, Trash } from "react-bootstrap-icons";
import {
  getItems,
  getItemsOfInvoiceForGrn,
  getItemsOfPoForGrn,
  getPendingGrns,
} from "../../../../services/AdminService";
import DashboardServices from "../../../../services/DashboardServices";
import IntegerTextField from "../../../../common/IntegerTextField";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import TaskService from "../../../../services/TaskService";
import { FloatTextField } from "../../../../common/FloatTextField";

export const GrnInvoice = forwardRef(
  ({ formData, setFormData, handleShowPreview }, grnRef) => {
    const loginUserDetail = getLoginUserDetails();
    const { t } = useTranslation();

    const [poId, setPoId] = useState(null);
    const [invoiceNo, setInvoiceNo] = useState(null);
    const [invoiceDate, setInvoiceDate] = useState(null);

    const [invoiceAmount, setInvoiceAmount] = useState(0);
    const [dwWbNo, setDwWbNo] = useState("");
    const [transportCoName, setTransportCoName] = useState("");
    const [vehicldNo, setVehicleNo] = useState("");
    const [origin, setOrigin] = useState("");
    const [driver, setDriver] = useState("");
    const [lrNo, setLrNo] = useState("");
    const [amountDue, setAmountDue] = useState(0);
    const [advanceAmount, setAdvanceAmount] = useState(0);
    const [poList, setPoList] = useState([]);
    const [invoiceList, setInvoiceList] = useState([]);
    const [itemList, setItemList] = useState([]);

    const mutateItemData = (itemDataList) => {
      const temp = itemDataList?.map((item) => {
        return {
          ...item,
          rcptQty: 0,
          dlWbQty: 0,
          excessQty: 0,
          shortQty: 0,
          rejected: 0,
          accepted: 0,
          returned: 0,
        };
      });
      return temp;
    };
    const fetchPoForGRN = (poItem) => {
      const { poSystemNo } = poItem;
      getItemsOfPoForGrn(poSystemNo).then((response) => {
        if (response.data) {
          const tempData = mutateItemData(response.data);
          setItemList(tempData);
        }
      });
    };
    const fetchInvoiceForGRN = (invoiceItem) => {
      const { invoiceSystemNo } = invoiceItem;
      getItemsOfInvoiceForGrn(invoiceSystemNo).then((response) => {
        if (response.data) {
          const tempData = mutateItemData(response.data);
          setItemList(tempData);
        }
      });
    };

    const calculateTotalPoQty = () => {
      let value = 0;
      for (let i = 0; i < itemList.length; i++) {
        const { poQty } = itemList[i];
        value = value + poQty;
      }
      return value ? value : 0;
    };
    const calculateTotalDnWbQty = () => {
      let value = 0;
      for (let i = 0; i < itemList.length; i++) {
        const { dlWbQty } = itemList[i];
        value = value + dlWbQty;
      }
      return value ? value : 0;
    };
    const calculateTotalReceivedQty = () => {
      let value = 0;
      for (let i = 0; i < itemList.length; i++) {
        const { rcptQty } = itemList[i];
        value = value + rcptQty;
      }
      return value ? value : 0;
    };
    const calculateTotalExcessQty = () => {
      let value = 0;
      for (let i = 0; i < itemList.length; i++) {
        const { excessQty } = itemList[i];
        value = value + excessQty;
      }
      return value ? value : 0;
    };
    const calculateTotalShortQty = () => {
      let value = 0;
      for (let i = 0; i < itemList.length; i++) {
        const { shortQty } = itemList[i];
        value = value + shortQty;
      }
      return value ? value : 0;
    };
    const calculateTotalRejected = () => {
      let value = 0;
      for (let i = 0; i < itemList.length; i++) {
        const { rejected } = itemList[i];
        value = value + rejected;
      }
      return value ? value : 0;
    };
    const calculateTotalReturned = () => {
      let value = 0;
      for (let i = 0; i < itemList.length; i++) {
        const { returned } = itemList[i];
        value = value + returned;
      }
      return value ? value : 0;
    };
    const calculateTotalAccepted = () => {
      let value = 0;
      for (let i = 0; i < itemList.length; i++) {
        const { accepted } = itemList[i];
        value = value + accepted;
      }
      return value ? value : 0;
    };

    useEffect(() => {
      //   getItems(loginUserDetail.orgId).then((response) => {
      //     if (response.data) {
      //       setItemData(response.data);
      //     }
      //   });
      getPendingGrns(loginUserDetail.signleUnitId).then((response) => {
        if (response.data) {
          const { poList, invoiceList } = response.data;
          setPoList(poList);
          setInvoiceList(invoiceList);
        }
      });
    }, []);

    return (
      <>
        <div class="tskElemHeding mt10">GRN Details</div>

        <div className="formElementGrp flex-wrap">
          <div className="formElement width13">
            <FormControl className="formControl">
              {/* <TextField
                label="Po Number"
                required
                placeholder="Enter Po Number"
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={poId}
                onChange={(e) => setPoId(e.target.value)}
              /> */}

              <Autocomplete
                noOptionsText={"Please Type email Id"}
                className="formAutocompleteField"
                variant="outlined"
                value={poId}
                options={poList}
                getOptionLabel={(option) => option.poNo}
                onChange={(e, newValue) => {
                  setPoId(newValue);
                  let foundItem = null;
                  if (newValue) {
                    fetchPoForGRN(newValue);
                    foundItem = invoiceList?.find(
                      (item) => item.invoiceNo === newValue.poNo
                    );
                    if (foundItem) {
                      setInvoiceNo(foundItem);
                    } else {
                      setInvoiceNo(null);
                    }
                  } else {
                  }
                }}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{ padding: "4px 12px" }}
                    className="sPatOptList"
                    key={option.poSystemNo}
                  >
                    <div className="spname">{option.seller}</div>
                    <div className="spage">
                      {`${option.poNo} (${option.poDate}) (${option.poAmount})`}
                    </div>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="formAutoComInputField autocompFildPlaceholder"
                    placeholder={"Search Po Id"}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              {/* <TextField
                label="Tax Invoice Number"
                required
                placeholder="Enter Tax Invoice Number"
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={invoiceNo}
                onChange={(e) => setInvoiceNo(e.target.value)}
              /> */}

              <Autocomplete
                noOptionsText={"Please Type email Id"}
                className="formAutocompleteField"
                variant="outlined"
                value={invoiceNo}
                options={invoiceList}
                getOptionLabel={(option) => option.invoiceNo}
                onChange={(e, newValue) => {
                  setInvoiceNo(newValue);
                  let foundPoNo = null;
                  if (newValue) {
                    fetchInvoiceForGRN(newValue);
                    foundPoNo = poList?.find(
                      (item) => item.poNo === newValue.poNo
                    );
                    if (foundPoNo) {
                      setPoId(foundPoNo);
                    } else {
                      setPoId(null);
                    }
                  } else {
                    setPoId(foundPoNo);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="formAutoComInputField autocompFildPlaceholder"
                    placeholder={"Seller Email Id"}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="employeeJoingDate" className="setCompletionDate">
                GRN Date*
              </InputLabel>
              <ReactDatePicker
                showIcon
                labelId="employeeJoingDate"
                className="formDatePicker"
                selected={invoiceDate}
                // minDate={new Date()}
                // maxDate={new Date()}
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  setInvoiceDate(date);
                }}
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <FloatTextField
                label="Invoice Amount"
                required
                placeholder="Enter Invoice Amount"
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={invoiceAmount}
                onChange={(data) => setInvoiceAmount(data)}
              />
            </FormControl>
          </div>
        </div>

        <div class="tskElemHeding mt10">GRN Info</div>

        <div className="formElementGrp flex-wrap">
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                label="DW/WB Number"
                required
                placeholder="Enter DW/WB Number"
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={dwWbNo}
                onChange={(e) => setDwWbNo(e.target.value)}
              />
            </FormControl>
          </div>
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                label="Transport Company"
                required
                placeholder="Enter Transport Co. Name"
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={transportCoName}
                onChange={(e) => setTransportCoName(e.target.value)}
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                label="Origin"
                required
                placeholder="Enter Origin"
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={origin}
                onChange={(e) => setOrigin(e.target.value)}
              />
            </FormControl>
          </div>
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                label="Driver"
                required
                placeholder="Enter Driver"
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={driver}
                onChange={(e) => setDriver(e.target.value)}
              />
            </FormControl>
          </div>
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                label="LR No"
                required
                placeholder="Enter LR No"
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={lrNo}
                onChange={(e) => setLrNo(e.target.value)}
              />
            </FormControl>
          </div>
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                label="Vehicle No"
                required
                placeholder="Enter Vehicle No"
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={vehicldNo}
                onChange={(e) => setVehicleNo(e.target.value)}
              />
            </FormControl>
          </div>
        </div>

        <div class="tskElemHeding mt10"></div>

        <div className="indentFormtable">
          <div className="indtableResponsive">
            <table>
              <thead>
                <tr>
                  <th>{t("itemName")}</th>
                  <th>{t("itemDescription")}</th>
                  <th>{"QTY(PO)"}</th>
                  <th>{"QTY(DN/WB)"}</th>
                  <th>{"QTY(RECEIVED)"}</th>
                  <th>{"QTY(Excess) (Attachment as a pic)"}</th>
                  <th>{"QTY(Short) (Attachment as a pic)"}</th>
                  <th>{"REJECTED/DAMAGED (Attachment as a pic)"}</th>
                  <th>{"Accepted"}</th>
                  <th>{"Returned"}</th>
                </tr>
              </thead>
              <tbody>
                {itemList.map((row, index) => (
                  <tr key={index}>
                    <td>{row.itemName}</td>
                    <td>
                      <div className="textOnly">{row.itemDescription}</div>
                    </td>
                    <td>{row.poQty}</td>

                    <td>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <IntegerTextField
                            label=""
                            variant="outlined"
                            className="formTextFieldArea"
                            required
                            fullWidth
                            size="small"
                            autoComplete="off"
                            value={row.dlWbQty}
                            onChange={(data) => {
                              const temp = [...itemList];
                              temp[index].dlWbQty = data;
                              setItemList(temp);
                            }}
                          />
                        </FormControl>
                      </div>
                    </td>
                    <td>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <IntegerTextField
                            label=""
                            variant="outlined"
                            className="formTextFieldArea"
                            required
                            fullWidth
                            size="small"
                            autoComplete="off"
                            value={row.rcptQty}
                            onChange={(data) => {
                              const temp = [...itemList];
                              temp[index].rcptQty = data;
                              setItemList(temp);
                            }}
                          />
                        </FormControl>
                      </div>
                    </td>
                    <td>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <IntegerTextField
                            label=""
                            variant="outlined"
                            className="formTextFieldArea"
                            required
                            fullWidth
                            size="small"
                            autoComplete="off"
                            value={row.excessQty}
                            onChange={(data) => {
                              const temp = [...itemList];
                              temp[index].excessQty = data;
                              setItemList(temp);
                            }}
                          />
                        </FormControl>
                      </div>
                    </td>
                    <td>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <IntegerTextField
                            label=""
                            variant="outlined"
                            className="formTextFieldArea"
                            required
                            fullWidth
                            size="small"
                            autoComplete="off"
                            value={row.shortQty}
                            onChange={(data) => {
                              const temp = [...itemList];
                              temp[index].shortQty = data;
                              setItemList(temp);
                            }}
                          />
                        </FormControl>
                      </div>
                    </td>
                    <td>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <IntegerTextField
                            label=""
                            variant="outlined"
                            className="formTextFieldArea"
                            required
                            fullWidth
                            size="small"
                            autoComplete="off"
                            value={row.rejected}
                            onChange={(data) => {
                              const temp = [...itemList];
                              temp[index].rejected = data;
                              setItemList(temp);
                            }}
                          />
                        </FormControl>
                      </div>
                    </td>
                    <td>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <IntegerTextField
                            label=""
                            variant="outlined"
                            className="formTextFieldArea"
                            required
                            fullWidth
                            size="small"
                            autoComplete="off"
                            value={row.accepted}
                            onChange={(data) => {
                              const temp = [...itemList];
                              temp[index].accepted = data;
                              setItemList(temp);
                            }}
                          />
                        </FormControl>
                      </div>
                    </td>
                    <td>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <IntegerTextField
                            label=""
                            variant="outlined"
                            className="formTextFieldArea"
                            required
                            fullWidth
                            size="small"
                            autoComplete="off"
                            value={row.returned}
                            onChange={(data) => {
                              const temp = [...itemList];
                              temp[index].returned = data;
                              setItemList(temp);
                            }}
                          />
                        </FormControl>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={2}>{t("total")}</td>
                  <td>{calculateTotalPoQty()}</td>
                  <td>{calculateTotalDnWbQty()}</td>
                  <td>{calculateTotalReceivedQty()}</td>
                  <td>{calculateTotalExcessQty()}</td>
                  <td>{calculateTotalShortQty()}</td>
                  <td>{calculateTotalRejected()}</td>
                  <td>{calculateTotalAccepted()}</td>
                  <td>{calculateTotalReturned()}</td>
                  {/* <td colSpan={4}></td> */}
                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <div className="formElementGrp flex-wrap">
          <div className="formElement width13">
            <FormControl className="formControl">
              <FloatTextField
                label="Advance Amount "
                required
                placeholder="Enter Due Amount"
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={advanceAmount}
                onChange={(data) => setAdvanceAmount(data)}
              />
            </FormControl>
          </div>
          <div className="formElement width13">
            <FormControl className="formControl">
              <FloatTextField
                label="Amount Due"
                required
                placeholder="Enter Due Amount"
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={amountDue}
                onChange={(data) => setAmountDue(data)}
              />
            </FormControl>
          </div>
        </div>

        <Button
          ref={grnRef}
          style={{ display: "none" }}
          onClick={(e) => {
            const data = {
              orgId: loginUserDetail.orgId,
              poNo: poId.poNo || "",
              invoiceNo: invoiceNo.invoiceNo || "",
              poSystemNo: poId.poSystemNo || 0,
              invoiceSystemNo: invoiceNo.invoiceSystemNo || 0,
              dnWbNo: dwWbNo || "",
              transportCo: transportCoName || "",
              vehicleNo: vehicldNo || "",
              origin: origin || "",
              driver: driver || "",
              lrNo: lrNo || "",
              poAmt: poId.poAmount || 0,
              advanceAmt: advanceAmount || 0,
              dueAmt: amountDue || 0,
              invoiceDate: invoiceDate,
              items:
                itemList?.map((item) => {
                  return {
                    poQty: item.poQty || 0,
                    rcptQty: item.rcptQty || 0,
                    dlWbQty: item.dlWbQty || 0,
                    excessQty: item.excessQty || 0,
                    missingShortqty: item.shortQty || 0,
                    damagedQty: item.rejected || 0,
                    acceptedQty: item.accepted || 0,
                    purchaseDtlId: 0,
                    itemId: item.itemId || 0,
                    itemName: item.itemName || "",
                    itemDescription: item.itemDescription || "",
                    batchNo: "",
                    expiryDate: "",
                    mrp: 0,
                  };
                }) || [],
            };
            handleShowPreview(data);
          }}
        ></Button>
      </>
    );
  }
);
