import { Add, CloseFullscreen, Edit, OpenInFull } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Trash, XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PreviewLOI from "./model/PreviewLOI";
import SalaryHeadAdd from "./model/SalaryHeadAdd";
import { useEffect } from "react";
import { getLoginUserDetails, validateEmail } from "../../../../utils/Utils";
import {
  getAllDepartment,
  getCountryList,
  getPositionsOfOrganization,
  getRoutineActivity,
  getStateList,
  getStaticDDOfOrg,
} from "../../../../services/AdminService";
import { Controller, useForm } from "react-hook-form";
import { NumericFormatCustom } from "../../../task/view/component/Forms/NumericFormatCustom";
import { SalaryTable } from "./SalaryTable";
import { toast } from "react-toastify";
import { LOIForm } from "./LOIForm";
import { AppoinmentLetter } from "./AppoinmentLetter";
import PreviewAppointment from "./model/PreviewAppointment";
// import PreviewAppointment from "./model/PreviewAppointment";
import { useTranslation } from "react-i18next";
import { PorformaInvoice } from "./PorformaInvoice";
import { PreviewTax } from "./model/PreviewTax";
import { TaxInvoice } from "./TaxInvoice";
import { PreviewPorforma } from "./model/PreviewPorforma";
import { GrnInvoice } from "./GrnInvoice";
import { ReleaseLetter } from "./ReleaseLetter";
import { PoInvoice } from "./PoInvoice";
import { ExperienceLetter } from "./ExperienceLetter";

export default function AddRoutineWork({
  handelCloseAddRoutineWork,
  isExpanded,
  setIsExpanded,
}) {
  const loginUserDetails = getLoginUserDetails();
  const { t } = useTranslation();
  const [selectActivity, setSelectActivity] = useState("");
  const [routineActivityList, setRoutineActivityList] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  const [formData, setFormData] = useState();
  const [showAddSalaryHeadModel, setAddSalaryHeadModel] = useState(false);
  const [showAppoinmentPreview, setAppoinmentPreview] = useState(false);
  const loiRef = useRef();
  const appointRef = useRef();
  const taxRef = useRef();
  const grnRef = useRef();
  const proformaRef = useRef();
  const releaseLetterRef = useRef();
  const poRef = useRef();
  const experienceLetterRef = useRef()

  const [showTaxInvoice, setShowTaxInvoice] = useState(false);
  const [showPorformaInvoice, setShowPorformaInvoice] = useState(false);

  const buttonNameMap = (activityId) => {
    switch (activityId) {
      case "TASK_FORM_0000000020":
        return "Submit";
      case "TASK_FORM_0000000021":
        return "Submit";
      default:
        break;
    }
  };

  const handleChangeSelectActivity = (event) => {
    setSelectActivity(event.target.value);
  };

  const [showPreviewLOIModel, setPreviewLOIModel] = useState(false);
  const handelClosePreviewLOI = () => {
    setPreviewLOIModel(false);
  };
  const handleClosePreviewApoinment = () => {
    setAppoinmentPreview(false);
  };
  const handleClosePreviewTax = () => {
    setShowTaxInvoice(false);
  };
  const handleClosePreviewProforma = () => {
    setShowPorformaInvoice(false);
  };

  const handelOpenPreviewLOI = (data) => {
    let isValid = true;
    if (data.firstName === "") {
      toast.error(t("please_enter_first_name"));
      isValid = false;
    }
    // if (data.middleName === "") {
    //   toast.error("Please Enter Middle Name");
    //   isValid = false;
    // }
    if (data.lastName === "") {
      toast.error(t("please_enter_last_name"));
      isValid = false;
    }
    if (!data.dob) {
      toast.error("Please enter date of birth");
      isValid = false;
    }

    if (data.address1 === "") {
      toast.error(t("please_enter_address_one"));
      isValid = false;
    }
    if (data.address2 === "") {
      toast.error(t("please_enter_address_two"));
      isValid = false;
    }
    if (data.city === "") {
      toast.error(t("please_enter_city"));
      isValid = false;
    }
    if (data.country && data.countryCode === "356") {
      if (!data.state || data.state === "") {
        toast.error(t("please_enter_state"));
        isValid = false;
      }
    }
    if (!data.country || data.country === "") {
      toast.error(t("please_enter_country"));
      isValid = false;
    }
    if (data.pin === "") {
      toast.error(t("please_enter_pin"));
      isValid = false;
    }
    // if (!data.department || data.department === "") {
    //   toast.error(t("please_enter_department"));
    //   isValid = false;
    // }

    if (!data.position || data.position === "") {
      toast.error(t("please_enter_position"));
      isValid = false;
    }

    if (data.email === "") {
      toast.error(t("please_enter_email"));
      isValid = false;
    }

    if (data.email !== "" && !validateEmail(data.email)) {
      toast.error(t("please_enter_valid_emailid"));
      isValid = false;
    }

    if (data.gpm === "") {
      toast.error(t("please_enter_gross_per_month"));
      isValid = false;
    }

    if (data.ctc === "") {
      toast.error(t("please_enter_ctc"));
      isValid = false;
    }

    if (data.salaryInfoList.lengh === 0) {
      toast.error(t("please_provide_salary_informations"));
      isValid = false;
    }

    if (isValid) {
      setPreviewLOIModel(true);
    }
  };

  const handelCloseSalaryHeadModel = () => {
    setAddSalaryHeadModel(false);
  };
  const handelOpenAppointmentPreview = (data) => {
    // showAddSalaryHeadModel(true);
    //
    console.log("data value =", data);
    let isValid = true;
    if (data.firstName === "") {
      toast.error(t("please_enter_first_name"));
      isValid = false;
    }
    // if (data.middleName === "") {
    //   toast.error("Please Enter Middle Name");
    //   isValid = false;
    // }
    if (data.lastName === "") {
      toast.error(t("please_enter_last_name"));
      isValid = false;
    }
    if (data.address1 === "") {
      toast.error("please_enter_address_one");
      isValid = false;
    }
    if (data.address2 === "") {
      toast.error("please_enter_address_two");
      isValid = false;
    }
    if (data.city === "") {
      toast.error(t("please_enter_city"));
      isValid = false;
    }
    if (data.country.countryCode === "356") {
      if (!data.state || data.state === "") {
        toast.error(t("please_enter_state"));
        isValid = false;
      }
    }
    if (!data.country || data.country === "") {
      toast.error(t("please_enter_country"));
      isValid = false;
    }
    if (data.pin === "") {
      toast.error("please_enter_pin");
      isValid = false;
    }
    // if (!data.department || data.department === "") {
    //   toast.error(t("please_enter_department"));
    //   isValid = false;
    // }

    if (!data.position || data.position === "") {
      toast.error(t("please_enter_position"));
      isValid = false;
    }
    if (!data.location || data.location === "") {
      toast.error(t("please_select_location"));
      isValid = false;
    }

    if (data.email === "") {
      toast.error(t("please_enter_email"));
      isValid = false;
    }

    if (data.email !== "" && !validateEmail(data.email)) {
      toast.error(t("please_enter_valid_emailid"));
      isValid = false;
    }

    if (!data.reportingHead) {
      toast.error(t("please_select_reporting_head"));
      isValid = false;
    }

    if (data.probationDay === "") {
      toast.error(t("please_select_probation_time"));
      isValid = false;
    }

    if (data.priorAddressChangeDays === "") {
      toast.error(t("please_select_address_change_notice_period"));
      isValid = false;
    }
    if (data.priorLeaveNoticeDays === "") {
      toast.error(t("please_select_leave_notice_period"));
      isValid = false;
    }

    if (
      data.corAddress.cor_address1 === "" ||
      data.corAddress.cor_address1 === undefined
    ) {
      toast.error(t("please_properly_fill_the_corresponding_address"));
      isValid = false;
    }
    if (
      data.corAddress.cor_address2 === "" ||
      data.corAddress.cor_address2 === undefined
    ) {
      toast.error(t("please_fill_corresponding_address_one"));
      isValid = false;
    }
    if (
      data.corAddress.cor_pin === "" ||
      data.corAddress.cor_pin === undefined
    ) {
      toast.error(t("please_fill_corresponding_address_one"));
      isValid = false;
    }
    if (
      data.corAddress.cor_city === "" ||
      data.corAddress.cor_city === undefined
    ) {
      toast.error(t("please_fill_the_corresponding_city"));
      isValid = false;
    }
    if (
      data.corAddress.cor_country === "" ||
      data.corAddress.cor_country === undefined
    ) {
      toast.error(t("please_fill_the_corresponding_country"));
      isValid = false;
    }
    if (
      data.corAddress.cor_country.countryCode === "356" &&
      (data.corAddress.cor_state === "" ||
        data.corAddress.cor_state === undefined)
    ) {
      toast.error(t("please_fill_the_corresponding_state"));
      isValid = false;
    }

    // if (data.gpm === "") {
    //   toast.error(t("please_enter_gross_per_month"));
    //   isValid = false;
    // }

    if (data.ctc === "") {
      toast.error(t("please_enter_ctc"));
      isValid = false;
    }
    if (data.salaryInfoList.lengh === 0) {
      toast.error(t("please_provide_salary_informations"));
      isValid = false;
    }
    if (data.absentDays === "" || data.absentDays === undefined) {
      toast.error(t("please_provide_absent_days"));
      isValid = false;
    }
    if (data.noticePeriod === "" || data.noticePeriod === undefined) {
      toast.error(t("please_provide_notice_period"));
      isValid = false;
    }
    // if (data.salaryLieu === "" || data.salaryLieu === undefined) {
    //   toast.error("Please Provide Salary In Lieu");
    //   isValid = false;
    // }
    if (isValid) {
      setAppoinmentPreview(true);
    }
  };

  const handleOpenProformaPreview = (data) => {
    const {
      bankingMethod,
      allTermsAndConditions,
      tableData,
      sellerName,
      buyerName,
      sellerAdress,
      sellerTaxId,
      sellerEmailId,
      buyerTaxId,
      buyerEmailId,
      placeOfSupply,
    } = data;

    let isValid = true;
    if (!sellerName) {
      toast.error("Please Select Seller");
      isValid = false;
    }
    if (!sellerTaxId) {
      toast.error("Please Add Seller Tax/GST Id");
      isValid = false;
    }
    if (sellerEmailId && !validateEmail(sellerEmailId)) {
      toast.error("Invalid seller email id");
      isValid = false;
    }
    if (buyerEmailId && !validateEmail(buyerEmailId)) {
      toast.error("Invalid buyer email id");
      isValid = false;
    }

    if (!buyerName) {
      toast.error("Please Select Buyer");
      isValid = false;
    }
    if (!buyerTaxId) {
      toast.error("Please Add Buyer Tax/GST Id");
      isValid = false;
    }
    if (!tableData || tableData?.length == 0) {
      toast.error("Please add items");
      isValid = false;
    }

    if (!placeOfSupply || !placeOfSupply?.trim()) {
      toast.error("Please add place of supply.");
      isValid = false;
    }

    if (!bankingMethod) {
      toast.error("Please select banking data");
      isValid = false;
    }
    if (!allTermsAndConditions || allTermsAndConditions?.lengh == 0) {
      toast.error("Please select terms and conditions");
      isValid = false;
    }

    if (!isValid) {
      return;
    }
    setShowPorformaInvoice(true);
  };

  const handleOpenTaxPreview = (data) => {
    const {
      bankingMethod,
      allTermsAndConditions,
      tableData,
      subTotalAmount,
      totalAmount,
      roundOffAmount,
      sellerName,
      buyerName,
      sellerTaxId,
      sellerEmailId,
      buyerTaxId,
      buyerEmailId,
    } = data;

    let isValid = true;
    if (!sellerName) {
      toast.error("Please Select Seller");
      isValid = false;
    }

    if (!sellerTaxId) {
      toast.error("Please Add Seller Tax/GST Id");
      isValid = false;
    }
    if (sellerEmailId && !validateEmail(sellerEmailId)) {
      toast.error("Invalid seller email id");
      isValid = false;
    }

    if (!buyerName) {
      toast.error("Please select Buyer");
      isValid = false;
    }
    if (buyerEmailId && !validateEmail(buyerEmailId)) {
      toast.error("Invalid buyer email id");
      isValid = false;
    }

    if (!buyerTaxId) {
      toast.error("Please Add Buyer Tax/GST Id");
      isValid = false;
    }

    if (!tableData || tableData?.length == 0) {
      toast.error("Please add items");
      isValid = false;
    }
    if (!bankingMethod) {
      toast.error("Please select banking data");
      isValid = false;
    }
    if (!allTermsAndConditions || allTermsAndConditions?.lengh == 0) {
      toast.error("Please select terms and conditions");
      isValid = false;
    }

    if (!isValid) {
      return;
    }
    setShowTaxInvoice(true);
  };

  const handleOpenGrnPreview = (data) => {
    console.log("Grn Data =", data);

    // setPreviewData([]);
    // setSelectActivity("");
  };

  useEffect(() => {
    getRoutineActivity(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        setRoutineActivityList(response.data);
      }
    });
  }, []);

  const onSubmit = (data) => {
    console.log("data =", data);
  };

  const [minprovp, setMinprovp] = useState("");

  const handleChangeMinProvp = (event) => {
    setMinprovp(event.target.value);
  };
  const [maxprovp, setMaxprovp] = useState("");

  const handleChangeMaxProvp = (event) => {
    setMaxprovp(event.target.value);
  };

  // const [isExpanded, setIsExpanded] = useState(false);
  const fullScreenStyle = {
    width: "100%",
  };

  return (
    <>
      <div
        className="rightFlotingPanel"
        // style={isExpanded ? fullScreenStyle : {}}
      >
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("add_routine_work")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <Tooltip
                  arrow
                  title={
                    isExpanded ? t("exit_full_screen") : t("enter_full_screen")
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      if (isExpanded) {
                        setIsExpanded(false);
                      } else {
                        setIsExpanded(true);
                      }
                    }}
                  >
                    {isExpanded ? <CloseFullscreen /> : <OpenInFull />}
                  </IconButton>
                </Tooltip>
              </div>
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddRoutineWork()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="SelectActivity">
                      {t("select_activity")}
                    </InputLabel>
                    <Select
                      labelId="SelectActivity"
                      id="Select-Activity"
                      value={selectActivity}
                      label={t("select_activity")}
                      onChange={handleChangeSelectActivity}
                      className="formInputField"
                      variant="outlined"
                    >
                      {routineActivityList &&
                        routineActivityList.map((activity) => (
                          <MenuItem value={activity}>{activity.name}</MenuItem>
                        ))}
                      {/* <MenuItem value="TASK_FORM_0000000020">
                         Release Letter/ Relieving Letter
                      </MenuItem> */}
                      {/* <MenuItem value="TASK_FORM_0000000021">
                         Service Certificate/ Experience Letter
                      </MenuItem> */}
                      {/* <MenuItem value="TASK_FORM_0000000017">
                        Tax Invoice
                      </MenuItem> */}
                      {/* <MenuItem value="AppointmentLetter">
                        Appointment Letter
                      </MenuItem> */}
                    </Select>
                  </FormControl>
                </div>

                {selectActivity.formId === "TASK_FORM_0000000014" && (
                  <LOIForm
                    ref={loiRef}
                    formData={formData}
                    setFormData={setFormData}
                    handleShowPreview={(data) => {
                      console.log("handlePreview =", data);
                      setPreviewData(data);
                      handelOpenPreviewLOI(data);
                    }}
                  />
                )}

                {selectActivity.formId === "TASK_FORM_0000000015" && (
                  <AppoinmentLetter
                    ref={appointRef}
                    formData={formData}
                    setFormData={setFormData}
                    handleShowPreview={(data) => {
                      setPreviewData(data);
                      // handelOpenPreviewLOI(data);
                      handelOpenAppointmentPreview(data);
                    }}
                  />
                )}
                {selectActivity.formId === "TASK_FORM_0000000017" && (
                  <PorformaInvoice
                    ref={proformaRef}
                    formData={formData}
                    setFormData={setFormData}
                    handleShowPreview={(data) => {
                      setPreviewData(data);
                      // handelOpenPreviewLOI(data);
                      handleOpenProformaPreview(data);
                    }}
                  />
                )}
                {selectActivity.formId === "TASK_FORM_0000000018" && (
                  <TaxInvoice
                    ref={taxRef}
                    formData={formData}
                    setFormData={setFormData}
                    handleShowPreview={(data) => {
                      setPreviewData(data);
                      // handelOpenPreviewLOI(data);
                      handleOpenTaxPreview(data);
                    }}
                  />
                )}

                {selectActivity.formId === "TASK_FORM_0000000019" && (
                  <ExperienceLetter
                    ref={experienceLetterRef}
                    formData={formData}
                    setFormData={setFormData}
                    handleShowPreview={(data) => {
                      setPreviewData(data);
                      // handelOpenPreviewLOI(data);
                      handleOpenTaxPreview(data);
                    }}
                    setSelectActivity={setSelectActivity}
                  />
                )}
                {selectActivity.formId === "TASK_FORM_0000000020" && (
                  <GrnInvoice
                    ref={grnRef}
                    formData={formData}
                    setFormData={setFormData}
                    handleShowPreview={(data) => {
                      setPreviewData(data);
                      // handelOpenPreviewLOI(data);
                      handleOpenGrnPreview(data);
                    }}
                  />
                )}

                {selectActivity.formId === "TASK_FORM_0000000021" && (
                  <ReleaseLetter
                    ref={releaseLetterRef}
                    formData={formData}
                    setFormData={setFormData}
                    handleShowPreview={(data) => {
                      setPreviewData(data);
                      // handelOpenPreviewLOI(data);
                      handleOpenTaxPreview(data);
                    }}
                    setSelectActivity={setSelectActivity}
                  />
                )}

                {selectActivity.formId === "TASK_FORM_0000000022" && (
                  <PoInvoice
                    ref={poRef}
                    handleShowPreview={(data) => {
                      setPreviewData(data);
                      // handelOpenPreviewLOI(data);
                      handleOpenGrnPreview(data);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="rfContFooter">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  // let data = getValues();
                  // data.salaryInfoList = salaryInfoList;
                  // data.staticDodList = staticDodList;
                  // data.joiningdate = joiningdate;
                  // data.loiexpdate = loiexpdate;
                  if (selectActivity.formId === "TASK_FORM_0000000015") {
                    appointRef.current.click();
                  } else if (selectActivity.formId === "TASK_FORM_0000000014") {
                    loiRef.current.click();
                  } else if (selectActivity.formId === "TASK_FORM_0000000018") {
                    taxRef.current.click();
                  } else if (selectActivity.formId === "TASK_FORM_0000000017") {
                    proformaRef.current.click();
                  } else if (selectActivity.formId === "TASK_FORM_0000000019") {
                    experienceLetterRef.current.click();
                  } else if (selectActivity.formId === "TASK_FORM_0000000021") {
                    releaseLetterRef.current.click();
                  } else if (selectActivity === "TASK_FORM_0000000021") {
                    grnRef.current.click();
                  } 
                  
                  // setPreviewData(data);
                  // handelOpenPreviewLOI(data);
                }}
              >
                {["TASK_FORM_0000000020", "TASK_FORM_0000000021" , "TASK_FORM_0000000019"].includes(
                  selectActivity.formId
                )
                  ? t("submit")
                  : t("preview")}
                {/* {selectActivity.formId === "TASK_FORM_0000000020" ||
                selectActivity === "TASK_FORM_0000000021"
                  ? t("submit")
                  : t("preview")} */}
              </Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseAddRoutineWork()}
              >
                {t("cancel")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {showPreviewLOIModel && (
        <>
          <PreviewLOI
            activity={selectActivity}
            showPreviewLOIModel={showPreviewLOIModel}
            handelClosePreviewLOI={handelClosePreviewLOI}
            previewData={previewData}
            onSubmitSuccess={() => {
              setPreviewLOIModel(false);
              setPreviewData([]);
              setSelectActivity("");
            }}
          />
        </>
      )}
      {showAppoinmentPreview && (
        <PreviewAppointment
          activity={selectActivity}
          handleClosePreviewApoinment={handleClosePreviewApoinment}
          previewData={previewData}
          onSubmitSuccess={() => {
            setAppoinmentPreview(false);
            setPreviewData([]);
            setSelectActivity("");
          }}
        />
      )}

      {showTaxInvoice && (
        <PreviewTax
          activity={selectActivity}
          previewData={previewData}
          handleClosePreviewTax={handleClosePreviewTax}
          onSubmitSuccess={() => {
            setShowTaxInvoice(false);
            setPreviewData([]);
            setSelectActivity("");
          }}
        />
      )}
      {showPorformaInvoice && (
        <PreviewPorforma
          activity={selectActivity}
          previewData={previewData}
          handleClosePreviewProforma={handleClosePreviewProforma}
          onSubmitSuccess={() => {
            setShowPorformaInvoice(false);
            setPreviewData([]);
            setSelectActivity("");
          }}
        />
      )}

      {showAddSalaryHeadModel && (
        <SalaryHeadAdd
          showAddSalaryHeadModel={showAddSalaryHeadModel}
          handelCloseSalaryHeadModel={handelCloseSalaryHeadModel}
        />
      )}
    </>
  );
}
