import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getBudgetHeads,
  getUnits,
  addBudget,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import DateUtils from "../../../../utils/DateUtils";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function NumberInput({ value, onChange }) {
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();

  const handleChangeAmount = (event) => {
    const inputValue = event.target.value;

    // Check if the input value is a number
    if (/^\d*$/.test(inputValue)) {
      // If it's a number, clear any existing error message
      setErrorMessage("");
      // Call the onChange function with the new value
      onChange(inputValue);
    } else {
      // If it's not a number, display a warning message
      setErrorMessage("Please enter only numbers.");
    }
  };

  return (
    <>
      <div className="formElement">
        <FormControl className="formControl">
          <TextField
            label={errorMessage}
            placeholder={t("amount")}
            variant="outlined"
            className="formTextFieldArea"
            value={value}
            onChange={handleChangeAmount}
          />
        </FormControl>
      </div>
    </>
  );
}

export default function AddBudget({ handelCloseAddBudget , refreshBudgetData , fetchDashBoardData}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  console.log("userDetails", userDetails);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [budgetHeadData, setBudgetHeadData] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetchBudgetHeadData();
  }, [userDetails.orgId]);

  const fetchBudgetHeadData = () => {
    getBudgetHeads(userDetails.orgId)
      .then((response) => {
        console.log("getBudgetHeads", response.data);
        setBudgetHeadData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [budgetName, setBudgetname] = useState("");

  const handleBudgetName = (event) => {
    console.log("handleBudgetName", event.target.value);
    setBudgetname(event.target.value);
  };

  const [budgetData, setBudgetData] = useState([]);

  const handleInputChange = (index, newValue) => {
    const updatedBudgetData = [...budgetData];

    updatedBudgetData[index].amount = newValue;

    setBudgetData(updatedBudgetData);
  };

  useEffect(() => {
    const initialData = budgetHeadData
      .filter((item) => item.category === "Operating Expense")
      .map((item) => ({
        category: item.category,
        head: item.head,
        id: item.id,
        amount: 0,
      }));
    setBudgetData(initialData);
  }, [budgetHeadData]);

  console.log("budgetData", budgetData);

  const [capitalBudgetData, setCapitalBudgetData] = useState([]);

  const handleCapitalInputChange = (index, newValue) => {
    const updatedCapitalBudgetData = [...capitalBudgetData];

    updatedCapitalBudgetData[index].amount = newValue;

    setCapitalBudgetData(updatedCapitalBudgetData);
  };

  useEffect(() => {
    const initialCapitalData = budgetHeadData
      .filter((item) => item.category === "Capital Expense")
      .map((item) => ({
        category: item.category,
        head: item.head,
        id: item.id,
        amount: 0,
      }));
    setCapitalBudgetData(initialCapitalData);
  }, [budgetHeadData]);

  console.log("capitalBudgetData", capitalBudgetData);

  const [hrExpense, setHrExpense] = useState("");

  const handlesetHrExpense = (newValue) => {
    setHrExpense(newValue);
  };

  const [goodsExpense, setGoodsExpense] = useState("");

  const handleGoodsExpenseChange = (newValue) => {
    setGoodsExpense(newValue);
  };

  const [landAmount, setLandAmount] = useState("");

  const handleLandAmountChange = (newValue) => {
    setLandAmount(newValue);
  };

  const [buildAmount, setBuildAmount] = useState("");

  const handleBuildAmountChange = (newValue) => {
    setBuildAmount(newValue);
  };

  const [budgetType, setBudgetType] = useState("consolidated");

  const handleChangeBudgetType = (event) => {
    console.log("handleChangeBudgetType", event.target.value);
    setBudgetType(event.target.value);
  };

  const [selectunit, setSelectunit] = useState(0);
  const [unitData, setUnitData] = useState([]);

  useEffect(() => {
    getUnits(userDetails.orgId)
      .then((response) => {
        console.log("getUnits", response.data);
        const sortedUnits = response.data.sort((a, b) => a.unitId - b.unitId);
        setUnitData(sortedUnits);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
      });
  }, [userDetails.orgId]);

  const handleChangSelectunit = (event) => {
    console.log("handleChangSelectunit", event.target.value);
    setSelectunit(event.target.value);
  };

  const [radioUnitWise, setRadioUnitWise] = useState("");

  console.log("budgetHeadData", budgetHeadData);

  const finalSubmit = () => {
    console.log("finalSubmit");
    setLoading(true);

    const headDetails = [...budgetData, ...capitalBudgetData];

    const NewBudgetType = budgetType === "consolidated" ? "C" : "U";

    if (budgetName.trim() === "") {
      toast.error(t("please_enter_budget_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }


      const totalAmount = headDetails.reduce((total, item) => total + parseInt(item.amount), 0);

      if (totalAmount === 0) {
          toast.error(t("please_enter_valid_amounts_for_budget_heads"), {
              position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
          return;
      }

   

    const reqDto = {
      budgetFor: NewBudgetType,
      unitId: selectunit,
      budgetName: budgetName,
      startDate: DateUtils.getDateInDDMMYYYY(fromDate),
      endDate: DateUtils.getDateInDDMMYYYY(toDate),
      headsWithAmount: headDetails,
    };

    console.log("reqDto", reqDto);

    // addBudget
    addBudget(userDetails.orgId, userDetails.userId, reqDto).then(
      (response) => {
        console.log("addBudget", response.data);
        setLoading(false);
        if (response.data.returnValue === "1") {
          toast.success(t("budget_added_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchDashBoardData();
          refreshBudgetData();
          setBudgetname("");
          setFromDate(new Date());
          setToDate(new Date());
          setBudgetData([]);
          setCapitalBudgetData([]);
          setSelectunit(0);
          fetchBudgetHeadData();
        } else {
          toast.error(t("fail_to_add_budget"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("add_budget")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddBudget()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails">
              {loading ? (
                <div className="meetingVEContainer">
                  <div className="center-progress" style={{ height: "65vh" }}>
                    <CircularProgress sx={{ marginTop: "180px" }} />
                  </div>
                </div>
              ) : (
                <div className="elementFormContainer">
                  <div className="formElement widthAuto">
                    <FormControl className="formControl">
                      <RadioGroup
                        className="formRadioGroup"
                        labelId="setTasktype"
                        name="setTasktype"
                        value={budgetType}
                        onChange={handleChangeBudgetType}
                      >
                        <FormControlLabel
                          className="formRadioField"
                          value="consolidated"
                          control={<Radio />}
                          label={t("consolidated")}
                        />
                        <FormControlLabel
                          className="formRadioField"
                          value="unit_wise"
                          control={<Radio />}
                          label={t("unit_wise")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  {budgetType === "unit_wise" && (
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel id="Selectunit">{t("select_unit")}*</InputLabel>
                        <Select
                          required
                          labelId="Selectunit"
                          id="Select-unit"
                          value={selectunit}
                          label={t("select_unit")}
                          onChange={handleChangSelectunit}
                          className="formInputField"
                          variant="outlined"
                        >
                          {unitData.map((unit, index) => (
                            <MenuItem key={index} value={unit.unitId}>
                              {unit.unitName}
                            </MenuItem>
                          ))}
                          {/* <MenuItem value="kolkataHO">
                          Kolkata HO, 1104, the Gellaria, New town, 700156.
                        </MenuItem>
                        <MenuItem value="delhi">
                          Delhi Branch, Dwarka, Corporate Area, 100023
                        </MenuItem> */}
                        </Select>
                      </FormControl>
                    </div>
                  )}

                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        // hiddenLabel
                        label={t("budget_name")}
                        required
                        variant="outlined"
                        value={budgetName}
                        className="formTextFieldArea"
                        onChange={(event) => handleBudgetName(event)}
                      />
                    </FormControl>
                  </div>

                  <div className="formElementGrp">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel id="fromDate" className="setCompletionDate">
                        {t("from_date")}
                        </InputLabel>
                        <ReactDatePicker
                          showIcon
                          labelId="fromDate"
                          className="formDatePicker"
                          selected={fromDate}
                          minDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => {
                            console.log("new date = ", date);
                            setFromDate(date);
                          }}
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel id="toDate" className="setCompletionDate">
                        {t("to_date")}
                        </InputLabel>
                        <ReactDatePicker
                          showIcon
                          labelId="toDate"
                          className="formDatePicker"
                          selected={toDate}
                          minDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => {
                            console.log("last date = ", date);
                            setToDate(date);
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>

                  <div class="tskElemHeding mt10">{t("capital_expenditure_head")}</div>

                  {/* <div className="budgetList">
                  <ul>
                    <li>
                      <div className="inputValue">
                        <div className="budLstNm">Land</div>
                        <NumberInput
                          value={landAmount}
                          onChange={handleLandAmountChange}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="inputValue">
                        <div className="budLstNm">Building</div>
                        <NumberInput
                          value={buildAmount}
                          onChange={handleBuildAmountChange}
                        />
                      </div>
                    </li>
                  </ul>
                </div> */}

                  <div className="budgetList">
                    <ul>
                      {capitalBudgetData.map((item, index) => (
                        <li key={index}>
                          <div className="inputValue">
                            <div className="budLstNm">{item.head}</div>
                            <NumberInput
                              value={item.amount}
                              onChange={(newValue) =>
                                handleCapitalInputChange(index, newValue)
                              }
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div class="tskElemHeding mt10">
                  {t("operating_expenditure_head")}
                  </div>

                  {/* <div className="budgetList">
                  <ul>
                    <li>
                      <div className="inputValue">
                        <div className="budLstNm">HR Expense</div>
                        <NumberInput
                          value={hrExpense}
                          onChange={handlesetHrExpense}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="inputValue">
                        <div className="budLstNm">Goods Expense</div>
                        <NumberInput
                          value={goodsExpense}
                          onChange={handleGoodsExpenseChange}
                        />
                      </div>
                    </li>
                  </ul>
                </div> */}

                  <div className="budgetList">
                    <ul>
                      {budgetData.map((item, index) => (
                        <li key={index}>
                          <div className="inputValue">
                            <div className="budLstNm">{item.head}</div>
                            <NumberInput
                              value={item.amount}
                              onChange={(newValue) =>
                                handleInputChange(index, newValue)
                              }
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="rfContFooter">
            <div className="formBtnElement">
              {/* <Button className="dfultPrimaryBtn" onClick={()=>finalSubmit()}>Save</Button> */}
              <Button
                className="dfultPrimaryBtn"
                onClick={() => finalSubmit()}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>{t("submit")}</>
                )}
              </Button>
              {!loading && (
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => handelCloseAddBudget()}
                >
                   {t("cancel")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
