import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getLoginUserDetails } from "../../../../utils/Utils";

// import CheckoutForm from "./CheckoutForm";
import DriveCheckOutForm from "./DriveCheckOutForm";
import "../../../organization/css/organization.css";
import PaymentService from "../../../../services/PaymentService";
import { Skeleton } from "@mui/material";
import { stripeKey } from "../../../../constants/url_provider";
import { useTranslation } from "react-i18next";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
// const stripePromise = loadStripe(
//   "pk_test_51MHn49EJpxma8o1MhWPAn6yBo0o4qpMqDKf2bln8Rqe6TodwjPSRiW9QEiDpwbohrA6YBtlWfX8G8v47MmHNUEns00o447ysov"
// );

const stripePromise = loadStripe(stripeKey);

export default function DriveStripe(props) {
  const { t } = useTranslation();
  const loginUserDetail = getLoginUserDetails();
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let reqDto = {
      itemId: props.itemId,
      itemHeader: props.itemHeader,
      itemUnits: props.itemUnits,
      amtToPay: props.amtToPay,
      recepientEmailId: loginUserDetail.userEmailId,
    };
    PaymentService.createPaymentIntent(
      loginUserDetail.userId,
      loginUserDetail.orgId,
      reqDto
    )
      .then((response) => {
        console.log(response.data);
        const obj = JSON.parse(response.data.paymentIntentRawJson);
        console.log(obj);
        if (response.data.returnValue === "1") {
          setClientSecret(response.data.clientSecret);
        } else {
          alert(t("something_went_wrong"));
          setError(t("payment_intent_failed"));
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error creating payment intent:", error);
        setError(t("payment_intent_failed"));
        setLoading(false);
      });
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="app-stripe">
      {loading && <p>{t("loading")}...</p>}
      {error && <p>{error}</p>}
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <DriveCheckOutForm
            itemId={props.itemId}
            itemHeader={props.itemHeader}
            itemUnits={props.itemUnits}
            onSuccess={() => props.onSuccess()}
            // handleNext={props.handleNext}
            amtToPay={props.amtToPay}
            currencySymbol={props.currencySymbol}
            selectedEdition={props.selectedEdition}
            coupon={props.coupon}
            handlePaymentModal={props.handlePaymentModal}
            handleCloseCreateOrgStorage={props.handleCloseCreateOrgStorage}
          />
        </Elements>
      )}
      {/* {!clientSecret && (
        <Skeleton
          width={"35vw"}
          height={"100vh"}
          sx={{ position: "absolute", bottom: "-100px" }}
        />
      )} */}
      {!clientSecret && !loading && (
        <p>{t("payment_info_not_available")}</p>
      )}
    </div>
  );
}
