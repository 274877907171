import {
  Add,
  ArrowBackIosNew,
  ArrowForwardIos,
  BloodtypeOutlined,
  Cancel,
  DescriptionOutlined,
  HeadsetMicRounded,
  HeightOutlined,
  MicOutlined,
  MonitorWeightOutlined,
  Refresh,
  Tune,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  DeviceHddFill,
  HeartPulse,
  Lungs,
  PersonBoundingBox,
  Plus,
  Search,
  ThermometerHalf,
  XLg,
} from "react-bootstrap-icons";
import { CustomModal } from "../../../../task/view/component/Modals/CustomModal";
import { FaceLivenessAwsAmplify } from "../../../../profile/view/FaceLiveness";
import BP from "../../../../dashboard/image/BP";
import SPO2 from "../../../../dashboard/image/SPO2";
import HeadC from "../../../../dashboard/image/HeadC";
import UAC from "../../../../dashboard/image/UAC";
import LMP from "../../../../dashboard/image/LMP";
import FamilyHistory from "../../../../scheduler/view/component/meetings/view/consultation/FamilyHistory";
import DrugAllergy from "../../../../scheduler/view/component/meetings/view/consultation/DrugAllergy";
import FoodAllergy from "../../../../scheduler/view/component/meetings/view/consultation/FoodAllergy";
import PatientComplaints from "../../../../scheduler/view/component/meetings/view/consultation/PatientComplaints";
import MedicalHistory from "../../../../scheduler/view/component/meetings/view/consultation/MedicalHistory";
import FileList from "../../../../task/view/component/FileList";
import FilterPopperBox from "./FilterPopperBox";
import DashboardServices from "../../../../../services/DashboardServices";
import { getHealthDto } from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import PatientFamilyHistory from "../../../../scheduler/view/component/meetings/view/consultation/patient/PatientFamilyHistory";
import PatientDrugAllergy from "../../../../scheduler/view/component/meetings/view/consultation/patient/PatientDrugAllurgy";
import PatientFoodAllergy from "../../../../scheduler/view/component/meetings/view/consultation/patient/PatientFoodAllergy";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import PatientFileUpload from "./PatientFileUpload";
import zhPatientService from "../../../../../services/ZhPatientService";
import DateUtils from "../../../../../utils/DateUtils";
import { ConfirmProvider } from "material-ui-confirm";
import PatientFacialBiometricsRegister from "./PatientFacialBiometricsRegister";
import { v4 as uuidv4 } from "uuid";
import MeetingsTabContainer from "../../../../scheduler/view/component/meetings/view/MeetingsTabContainer";
import { AddTabContext } from "../../../../dashboard/view/DashboardPage";
import { centerSocket } from "../../../../../socket";
import PatientMeetingsTabContainer from "../../../../scheduler/view/component/meetings/view/PatientMeetingsTabContainer";
import { useTranslation } from "react-i18next";
import useAudio from "../../../../../utils/useAudio";
import { Microphone } from "iconoir-react";
import RecordAudio from "../../../../../common/RecordAudio";
import { FileCursor } from "../../../../../common/FileCursor";
import { PatientVitals } from "./PatientVitals";
import {
  getCallingCodeList,
  getCountryDtlByCode,
} from "../../../../../services/AdminService";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function AddBookConsultation({
  handelCloseAddBookConsultation,
  faceId,
  setFaceId,
  setShowRegFace,
  registeredPatient,
  closeBookConsultation,
}) {
  const { t } = useTranslation();
  const { tempAudioChunk, getAudioBase64 } = useAudio();
  const [recordingLength, setRecordingLength] = useState(0);
  const [recordingLink, setRecordingLink] = useState();
  const [showRecAudio, setShowRecAudio] = useState(false);
  const [emplyPhnCC, setEmplyPhnCC] = useState("");
  const [phoneRegexPattern, setPhoneRegexPattern] = useState(/^[0-9]{10,}$/);
  const [cdCodeList, setCdCodeList] = useState([]);

  const patientThreshold = 2;
  const { addTab } = useContext(AddTabContext);
  const [registrationHistory, setRegistrationHistory] = useState("");
  const [registerFace, setRegisterFace] = useState(false);
  const [doctorInputString, setDoctorInputString] = useState("");

  const [patientList, setPatientList] = useState([]);

  const [doctorList, setDoctorList] = useState([]);

  const [unitAllDoctorList, setUnitAllDoctorList] = useState([]);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const [vitals, setVitals] = useState({});
  const [suggestedDoctorList, setSuggestedDoctorList] = useState();

  const handleChangeRegHistory = (event) => {
    setRegistrationHistory(event.target.value);
  };
  const handelOpenRegisterFace = () => {
    setRegisterFace(true);
  };
  const handelCloseRegisterFace = () => {
    setRegisterFace(false);
  };

  const [VitalErrorList, setVitalErrorList] = useState([]);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);
  const loginUserDetail = getLoginUserDetails();

  const [systemicIllness, setSystemicIllness] = useState("no");
  const [substanceAbuse, setSubstanceAbuse] = useState("no");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  const [systemicIllnessList, setSystemicIllnessList] = useState([]);
  const [systemicAbuseList, setSystemicAbuseList] = useState([]);

  const [patientInfo, setPatientInfo] = useState(null);

  const [referedBy, setReferedBy] = useState("");

  const [abuseName, setAbuseName] = useState([]);
  const [illnessName, setIllnessName] = useState([]);

  const [prescriptionData, setPrescriptionData] = useState({});

  //Vital Start

  const [temperatureUnit, setTemperatureUnit] = useState(null);
  const [heightUnit, setHeightUnit] = useState(null);
  const [weightUnit, setWeightUnit] = useState(null);
  const [upperArmUnit, setUpperArmUnit] = useState(null);
  const [headUnit, setHeadUnit] = useState(null);
  const [bloodGroup, setBloodGroup] = useState("");
  const [vitalUnitMap, setVitalUnitMap] = useState(null);
  const [vitalUnitList, setVitalUnitList] = useState([]);

  const [sos, setSos] = useState(false);

  const [lmp, setLmp] = useState(null);

  //Vital End

  const [previousMedicalRecords, setPreviousMedicalRecords] = useState(null);

  const mapUnitsToVitals = () => {
    const tempVitalUnitMap = {};
    vitalUnitList.forEach((item) => {
      if (!tempVitalUnitMap[item.vitalCode]) {
        tempVitalUnitMap[item.vitalCode] = [item.vitalUnit];
      } else {
        tempVitalUnitMap[item.vitalCode].push(item.vitalUnit);
      }
    });
    setVitalUnitMap(tempVitalUnitMap);
    const tempDefaultUnit = tempVitalUnitMap?.["TEMPERATURE"]?.[0];
    if (tempDefaultUnit) {
      setTemperatureUnit(tempDefaultUnit);
    }

    const heightDefaultUnit = tempVitalUnitMap?.["HEIGHT"]?.[0];
    if (heightDefaultUnit) {
      setHeightUnit(heightDefaultUnit);
    }

    const widthDefaultUnit = tempVitalUnitMap?.["WIDTH"]?.[0];
    if (widthDefaultUnit) {
      setWeightUnit(widthDefaultUnit);
    }

    const headDefaultUnit = tempVitalUnitMap?.["HEAD_CIRCUMFERENCE"]?.[0];
    if (headDefaultUnit) {
      setHeadUnit(headDefaultUnit);
    }

    const upperArmDefaultUnit =
      tempVitalUnitMap?.["UPPER_ARM_CIRCUMFERENCE"]?.[0];
    if (upperArmDefaultUnit) {
      setUpperArmUnit(upperArmDefaultUnit);
    }
  };

  const getUnitFromVitalCode = (vitalCode) => {
    const tempValue = vitalUnitMap?.[vitalCode];
    return tempValue?.[0] || null;
  };

  const selectSubstanceAbuse = (event) => {
    const value = event.target.value;
    if (value === "no") {
      setAbuseName([]);
    }
    setSubstanceAbuse(value);
  };

  const selectSystemicIllness = (event) => {
    const value = event.target.value;
    if (value === "no") {
      setIllnessName([]);
    }
    setSystemicIllness(event.target.value);
  };

  useEffect(() => {
    if (vitalUnitList && vitalUnitList.length > 0) {
      mapUnitsToVitals();
    }
  }, [vitalUnitList]);

  useEffect(() => {
    getCallingCodeList().then((response) => {
      console.log("getCallingCodeList", response.data);
      setCdCodeList(response.data);
    });
  }, []);

  useEffect(() => {
    if (loginUserDetail.orgCountryCode) {
      getCountryDtlByCode(loginUserDetail.orgCountryCode).then((response) => {
        console.log("response", response.data);
        setEmplyPhnCC(response.data.callingCd);
        switch (response.data.callingCd) {
          case "+91": // India
            setPhoneRegexPattern(/^[6-9]\d{9}$/);
            break;
          case "+52": // Mexico
            setPhoneRegexPattern(/^[1-9]\d{9}$/);
            break;
          case "+971": // UAE
            setPhoneRegexPattern(/^(?:\50|51|52|55|56|2|3|4|6|7|9)\d{7}$/);
            break;
          default:
            setPhoneRegexPattern(/^[0-9]{10,}$/); // Default pattern for other countries
            break;
        }
      });
    }
  }, [loginUserDetail.orgCountryCode]);

  const getVitalUnits = () => {
    const diseasetring = localStorage.getItem("vitalUnitList");
    if (diseasetring) {
      setVitalUnitList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getVitalUnits(reqDto).then((response) => {
      if (response.data) {
        setVitalUnitList(response.data);
        localStorage.setItem("vitalUnitList", JSON.stringify(response.data));
      }
    });
  };

  const handleChangeAbuse = (event) => {
    const {
      target: { value },
    } = event;
    setAbuseName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeIllness = (event) => {
    const {
      target: { value },
    } = event;
    setIllnessName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const getTemperaturePlaceholder = useMemo(() => {
    const temp = vitals["TEMPERATURE"];
    if (temp) {
      const { unit } = temp;
      if (unit) {
        return unit.includes("C") ? t("eg_35") : t("eg_96_6");
      } else {
        return "";
      }
    } else {
      if (vitalUnitMap?.["TEMPERATURE"]?.length > 0) {
        const defaultUnit = vitalUnitMap?.["TEMPERATURE"]?.[0];
        if (defaultUnit.includes("F")) {
          return t("eg_96_6");
        } else if (defaultUnit.includes("C")) {
          return t("eg_35");
        }
      } else {
        return "";
      }
    }
    return "";

    // vitalUnitMap?.["TEMPERATURE"]?.length > 0 &&
    //                             vitalUnitMap?.["TEMPERATURE"]?.[0]?.includes(
    //                               "F"
    //                             )
    //                               ? t("eg_96_6")
    //                               : "eg:35"
  }, [vitals, vitalUnitMap]);

  const getAge = (dob) => {
    // console.log("getAge", dob);
    const ageString = calculateDateDifference(dob);
    const ageComponent = ageString?.split(" ");
    if (ageComponent?.[0]?.includes("Y")) {
      return parseInt(ageComponent[0].split("Y")[0]) || 0;
    } else {
      return 0;
    }
  };

  function calculateDateDifference(dateString) {
    // Parse the input date string "DD-MM-YYYY"
    const [day, month, year] = dateString.split("-").map(Number);
    const givenDate = new Date(year, month - 1, day);
    const today = new Date();

    let years = today.getFullYear() - givenDate.getFullYear();
    let months = today.getMonth() - givenDate.getMonth();
    let days = today.getDate() - givenDate.getDate();

    // Adjust if days are negative
    if (days < 0) {
      months--;
      days += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }

    // Adjust if months are negative
    if (months < 0) {
      years--;
      months += 12;
    }

    const yr = years ? `${years}Y ` : "";
    const mnt = months ? `${months}M ` : "";
    const dy = days ? `${days}D ` : "";

    return `${yr}${mnt}${dy}`;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  const initializeForm = () => {
    setSystemicIllness("no");
    setSubstanceAbuse("no");
    setIllnessName([]);
    setAbuseName([]);
    setVitals({});
    setReferedBy("");
    setPatientInfo(null);
    setPrescriptionData({});
    setDocuments([]);
  };

  useEffect(() => {
    const tempSelectedDoctorList = doctorList?.filter((doctor) => {
      const nameSpecial = `${doctor?.doctorName}${doctor?.specializationName}`;
      return nameSpecial
        ?.toLowerCase()
        ?.includes(doctorInputString?.trim()?.toLowerCase());
    });
    setSuggestedDoctorList(tempSelectedDoctorList);
  }, [doctorList, doctorInputString]);

  useEffect(() => {
    console.log("registeredPatient", registeredPatient);
    getSystemicIllness();
    getSubstanceAbuse();
    getVitalUnits();
    // DashboardServices.getAvailableDoctorsofUnit("17").then((response) => {
    //   if (response.data) {
    //     setDoctorList(response.data);
    //   }
    // });
    getTodaysPatientList();
    if (registeredPatient?.rowPatientId > 0) {
      setSelectedPatient(registeredPatient);
      setBloodGroup(registeredPatient?.bloodType);
    }
    getAvailableDoctorsofUnit();
    if (registeredPatient) {
      initializeForm();
      fetchPatientData(registeredPatient);
    }
  }, []);

  const handleResponseOnlineDoctor = (doctorIdList) => {
    console.log("responseOnlineDoctor", doctorIdList);
    if (!doctorIdList || doctorIdList.length <= 0) {
      toast.error(t("no_doctor_is_online"));
      setDoctorList([]);
      return;
    }
    let tempResponse = null;
    DashboardServices.getDoctorsWithQueue(doctorIdList).then((response) => {
      const tempOnlineDoctor = [];
      tempResponse = response.data;
      unitAllDoctorList.forEach((item) => {
        const doctorQueLength = tempResponse[item.doctorId];
        let doesExist = doctorIdList.includes(item.doctorId);

        if (doctorQueLength) {
          tempOnlineDoctor.push({ ...item, queueCount: doctorQueLength });
        } else if (!doctorQueLength && doesExist) {
          tempOnlineDoctor.push({ ...item, queueCount: 0 });
        }
      });

      setDoctorList(tempOnlineDoctor);
    });
  };

  const [guardianName, setGuardianName] = useState(
    selectedPatient?.emergencyContactName || ""
  );
  const [emergencyCallingCode, setEmergencyCallingCode] = useState("");
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState(
    selectedPatient?.emergencyContactNo || ""
  );
  const [insuranceProvider, setInsuranceProvider] = useState(
    selectedPatient?.insuranceProvider || ""
  );
  const [insuranceNumber, setInsuranceNumber] = useState(
    selectedPatient?.insuranceNo || ""
  );

  useEffect(() => {
    setEmergencyCallingCode(
      selectedPatient?.emergencyContactCallingCode || emplyPhnCC || ""
    );
  }, [selectedPatient, emplyPhnCC]);

  useEffect(() => {
    if (
      centerSocket != null &&
      unitAllDoctorList &&
      unitAllDoctorList.length > 0 &&
      loginUserDetail
    ) {
      centerSocket.emit("requestOnlineDoctor", {
        orgId: loginUserDetail.orgId,
      });
      centerSocket.on("responseOnlineDoctor", handleResponseOnlineDoctor);
    }

    return () => {
      centerSocket.off("responseOnlineDoctor", handleResponseOnlineDoctor);
    };
  }, [centerSocket, unitAllDoctorList]);

  const getAvailableDoctorsofUnit = () => {
    const diseasetring = localStorage.getItem("unitDoctorList");
    if (diseasetring) {
      setUnitAllDoctorList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getAvailableDoctorsofUnit(
      loginUserDetail.signleUnitId
    ).then((response) => {
      if (response.data) {
        setUnitAllDoctorList(response.data);
        localStorage.setItem("unitDoctorList", JSON.stringify(response.data));
      }
    });
  };

  const getTodaysPatientList = async () => {
    let date = DateUtils.getDateInYYYYMMDD(new Date());
    const resGetTodayPatList = await zhPatientService.getPatListInUnitByDate(
      date,
      date,
      loginUserDetail.signleUnitId,
      loginUserDetail.orgId
    );
    console.log("getTodaysPatientList", resGetTodayPatList.data);
    setPatientList(resGetTodayPatList.data);
  };

  const getSystemicIllness = () => {
    const brandString = localStorage.getItem("substanceIllnessList");
    if (brandString) {
      setSystemicIllnessList(JSON.parse(brandString));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getSystemicIllness(reqDto).then((response) => {
      if (response.data) {
        setSystemicIllnessList(response.data);
        localStorage.setItem(
          "substanceIllnessList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getSubstanceAbuse = () => {
    const brandString = localStorage.getItem("substanceAbuseList");
    if (brandString) {
      setSystemicAbuseList(JSON.parse(brandString));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getSubstanceAbuse(reqDto).then((response) => {
      if (response.data) {
        setSystemicAbuseList(response.data);
        localStorage.setItem(
          "substanceAbuseList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const handlePatientInfo = (patientInfo) => {
    if (patientInfo) {
      const { systemicIllnesses, substanceAbuse } = patientInfo;
      let tempSelectedIllness = [];
      systemicIllnesses?.forEach((item) => {
        const illness = systemicIllnessList.find(
          (illness) => illness.systemicIllnessName === item
        );
        illness && tempSelectedIllness.push(illness);
      });
      if (tempSelectedIllness && tempSelectedIllness.length > 0) {
        setIllnessName(tempSelectedIllness);
        setSystemicIllness("yes");
      }

      tempSelectedIllness = [];

      substanceAbuse?.forEach((item) => {
        const illness = systemicAbuseList.find(
          (illness) => illness.substanceAbuseName === item
        );
        illness && tempSelectedIllness.push(illness);
      });
      if (tempSelectedIllness && tempSelectedIllness.length > 0) {
        setAbuseName(tempSelectedIllness);
        setSubstanceAbuse("yes");
      }
    }
  };

  const updateData = (data) => {
    // toast.success("successfully added data");
    console.log("data =", data);
    setPrescriptionData((prev) => ({ ...prev, ...data }));
  };

  const handleChangeSistolic = (vitalCode, e) => {
    let value = e.target.value;

    //need bug fixes

    let tempVital = { ...vitals };
    if (!vitals[vitalCode]) {
      tempVital[vitalCode] = {
        vitalName: "Blood Pressure",
        unit: getUnitFromVitalCode("BP"),
        vitalValue: "/",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: "BP",
      };
    }
    let sistolic = tempVital[vitalCode].vitalValue.split("/")[0] || "";
    let diastolic = tempVital[vitalCode].vitalValue.split("/")[1] || "";

    if (value === "") {
      const tempValue = `${value}/${diastolic}`;
      tempVital[vitalCode].vitalValue = tempValue;
      setVitals(tempVital);
      return;
    }
    if (value) {
      value = value.slice(0, 3);
    }

    sistolic = parseInt(value);
    if (isNaN(sistolic)) {
      return;
    }

    const tempValue = `${sistolic}/${diastolic}`;
    tempVital[vitalCode].vitalValue = tempValue;

    if (sistolic > 140 || sistolic < 90 || diastolic > 95 || diastolic < 61) {
      tempVital[vitalCode].isNormal = "N";
    } else {
      tempVital[vitalCode].isNormal = "Y";
    }

    setVitals(tempVital);
  };

  const handleChangeDiastolic = (vitalCode, e) => {
    let value = e.target.value;

    //need bug fixes

    let tempVital = { ...vitals };
    if (!vitals[vitalCode]) {
      tempVital[vitalCode] = {
        vitalName: "Blood Pressure",
        unit: getUnitFromVitalCode("BP"),
        vitalValue: "/",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: "BP",
      };
    }
    let sistolic = tempVital[vitalCode].vitalValue.split("/")[0] || "";
    let diastolic = tempVital[vitalCode].vitalValue.split("/")[1] || "";

    if (value === "") {
      const tempValue = `${sistolic}/${value}`;
      tempVital[vitalCode].vitalValue = tempValue;
      setVitals(tempVital);
      return;
    }
    if (value) {
      value = value.slice(0, 3);
    }

    diastolic = parseInt(value);
    if (isNaN(diastolic)) {
      return;
    }

    const tempValue = `${sistolic}/${diastolic}`;
    tempVital[vitalCode].vitalValue = tempValue;

    if (sistolic > 140 || sistolic < 90 || diastolic > 95 || diastolic < 61) {
      tempVital[vitalCode].isNormal = "N";
    } else {
      tempVital[vitalCode].isNormal = "Y";
    }

    setVitals(tempVital);
  };

  const handleChangeHeartRage = (vitalCode, e) => {
    const value = e.target.value;

    let tempVitals = { ...vitals };
    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Heart Rate",
        unit: getUnitFromVitalCode(vitalCode),
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }
    if (value === "" || value === null) {
      tempVitals[vitalCode].vitalValue = value;
      tempVitals[vitalCode].isNormal = "Y";
      setVitals(tempVitals);
    }
    const prValue = parseFloat(value.slice(0, 3));

    if (!prValue || isNaN(prValue) || prValue < 0) {
      return;
    }

    tempVitals[vitalCode].vitalValue = prValue;
    if (prValue > 90 || prValue < 50) {
      tempVitals[vitalCode].isNormal = "N";
    } else {
      tempVitals[vitalCode].isNormal = "Y";
    }
    setVitals(tempVitals);
  };

  const handleChangeRR = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Respiration Rate",
        unit: getUnitFromVitalCode(vitalCode),
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    const rrValue = parseFloat(value.slice(0, 3));

    if (value === "" || !value) {
      tempVitals[vitalCode].vitalValue = value;
      tempVitals[vitalCode].isNormal = "Y";
      setVitals(tempVitals);
    }

    if (!rrValue || isNaN(rrValue) || rrValue < 0) {
      return;
    }

    tempVitals[vitalCode].vitalValue = rrValue;
    if (rrValue > 20 || rrValue < 10) {
      tempVitals[vitalCode].isNormal = "N";
    } else {
      tempVitals[vitalCode].isNormal = "Y";
    }
    setVitals(tempVitals);
  };

  const handleChangeSpo2 = (value, vitalCode) => {
    // let spo2arr = value.split(".");
    // let spoBD = "";
    // let spoAD = "";
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "SPO2",
        unit: getUnitFromVitalCode(vitalCode),
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    if (value === "") {
      tempVitals[vitalCode].isNormal = "Y";
      tempVitals[vitalCode].vitalValue = "";
      setVitals(tempVitals);
      return;
    } else if (!parseFloat(value)) {
      return;
    }

    let [spoBD, spoAD] = value.split(".").map((part) => part || "");

    if (!spoAD) {
      spoAD = "";
    }

    if (spoBD.length > 2) {
      if (parseFloat(spoBD) === 100) {
        spoBD = "100";
        spoAD = "";
      } else {
        return;
        // spoBD = spoBD.slice(0, 2);
      }
    }

    if (spoAD.length > 2 && parseFloat(spoAD) > 0) {
      if (parseFloat(spoBD) >= 100) {
        spoAD = "";
      } else {
        spoAD = spoAD.slice(0, 2);
      }
    }

    let temp = "";
    if (spoAD.length > 0) {
      temp = `${spoBD}.${spoAD}`;
    } else {
      temp = value.includes(".") ? `${spoBD}.` : spoBD;
    }

    tempVitals[vitalCode].vitalValue = temp;

    let spo = value.split(".");
    if (parseFloat(spo[0]) >= 95) {
      tempVitals[vitalCode].isNormal = "Y";
    } else if (parseFloat(spo[0]) >= 91 || parseFloat(spo[1]) <= 94) {
      tempVitals[vitalCode].isNormal = "M";
    } else {
      tempVitals[vitalCode].isNormal = "N";
    }
    setVitals(tempVitals);
  };

  const handleChangeTemparature = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Temperature",
        unit: temperatureUnit || "°F",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let tmparr = value.split(".");
    let tmpBD = "";
    let tmpAD = "";

    let tmpTemparature = "";

    const unit = tempVitals[vitalCode].unit;

    if (tmparr[0] && tmparr[0].length > 0) {
      tmpBD = tmparr[0];
    }
    if (tmparr[1] && tmparr[1].length > 0) {
      tmpAD = tmparr[1];
    }

    if (unit.includes("F")) {
      if (tmparr[0] && tmparr[0].length > 3) {
        tmpBD = tmparr[0].slice(0, 3);
      }
      if (tmparr[1] && tmparr[1].length > 2) {
        tmpAD = tmparr[1].slice(0, 2);
      }

      if (value.includes(".")) {
        if (tmpAD.length === 0) {
          tmpTemparature = tmpBD + ".";
        } else {
          tmpTemparature = `${tmpBD}.${tmpAD}`;
        }
      } else {
        tmpTemparature = tmpBD;
      }

      tempVitals[vitalCode].vitalValue = tmpTemparature;

      if (
        parseFloat(tmpTemparature) >= 97 &&
        parseFloat(tmpTemparature) <= 99
      ) {
        tempVitals[vitalCode].isNormal = "Y";
      } else if (
        parseFloat(tmpTemparature) > 99 &&
        parseFloat(tmpTemparature) <= 100.3
      ) {
        tempVitals[vitalCode].isNormal = "M";
      } else {
        tempVitals[vitalCode].isNormal = "N";
      }
    } else if (unit.includes("C")) {
      if (tmparr[0] && tmparr[0].length > 3) {
        tmpBD = parseInt(tmparr[0].slice(0, 2));
      }
      if (tmparr[1] && tmparr[1].length > 2) {
        tmpAD = parseInt(tmparr[1].slice(0, 2));
      }

      if (isNaN(tmpBD) || isNaN(tmpAD)) {
        return;
      }

      if (value.includes(".")) {
        if (tmpAD.length === 0) {
          tmpTemparature = tmpBD + ".";
        } else {
          tmpTemparature = `${tmpBD}.${tmpAD}`;
        }
      } else {
        tmpTemparature = tmpBD;
      }

      tempVitals[vitalCode].vitalValue = tmpTemparature;

      if (parseFloat(tmpTemparature) < 36 || parseFloat(tmpTemparature) > 39) {
        tempVitals[vitalCode].isNormal = "N";
      } else {
        tempVitals[vitalCode].isNormal = "Y";
      }
    }
    setVitals(tempVitals);
  };

  const handleChangeHeadCircumference = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Head Circumference",
        unit: headUnit || "cm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let tmparr = value.split(".");
    let tmpBD = "";
    let tmpAD = "";

    let tmpTemparature = "";

    const { unit } = tempVitals[vitalCode];

    if (tmparr[0] && tmparr[0].length > 0) {
      tmpBD = tmparr[0];
    }
    if (tmparr[1] && tmparr[1].length > 0) {
      tmpAD = tmparr[1];
    }

    if (tmparr[0] && tmparr[0].length > 3) {
      tmpBD = parseInt(tmparr[0].slice(0, 3));
    }
    if (tmparr[1] && tmparr[1].length > 2) {
      tmpAD = parseInt(tmparr[1].slice(0, 2));
    }

    if (isNaN(tmpBD) || isNaN(tmpAD)) {
      return;
    }

    if (value.includes(".")) {
      if (tmpAD.length === 0) {
        tmpTemparature = tmpBD + ".";
      } else {
        tmpTemparature = `${tmpBD}.${tmpAD}`;
      }
    } else {
      tmpTemparature = tmpBD;
    }

    tempVitals[vitalCode].vitalValue = tmpTemparature;
    setVitals(tempVitals);
  };

  const handleChangeUpperArm = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Upper Arm Circumference",
        unit: upperArmUnit || "cm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let tmparr = value.split(".");
    let tmpBD = "";
    let tmpAD = "";

    let tmpTemparature = "";

    const unit = tempVitals[vitalCode].unit;

    if (tmparr[0] && tmparr[0].length > 0) {
      tmpBD = tmparr[0];
    }
    if (tmparr[1] && tmparr[1].length > 0) {
      tmpAD = tmparr[1];
    }

    if (tmparr[1] && tmparr[1].length > 2) {
      tmpAD = parseInt(tmparr[1].slice(0, 2));
    }
    if (tmparr[0] && tmparr[0].length > 2) {
      tmpBD = parseInt(tmparr[0].slice(0, 2));
    }

    if (isNaN(tmpBD) || isNaN(tmpAD)) {
      return;
    }

    if (value.includes(".")) {
      if (tmpAD.length === 0) {
        tmpTemparature = tmpBD + ".";
      } else {
        tmpTemparature = `${tmpBD}.${tmpAD}`;
      }
    } else {
      tmpTemparature = tmpBD;
    }

    tempVitals[vitalCode].vitalValue = tmpTemparature;
    setVitals(tempVitals);
  };

  const handleChangeWeight = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Weight",
        unit: weightUnit || "kg",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let weight = parseFloat(value);

    let wtarr = value.split(".");
    let wtBD = "";
    let wtAD = "";

    if (wtarr[0] && wtarr[0].length > 0) {
      wtBD = wtarr[0];
    }
    if (wtarr[1] && wtarr[1].length > 0) {
      wtAD = wtarr[1];
    }

    if (wtarr[0] && wtarr[0].length > 3) {
      wtBD = wtarr[0].slice(0, 3);
    }
    if (wtarr[1] && wtarr[1].length > 2) {
      wtAD = wtarr[1].slice(0, 2);
    }

    if (value === "" || !value) {
      tempVitals[vitalCode].vitalValue = value;
      setVitals(tempVitals);
      return;
    }

    if (!weight || isNaN(weight)) {
      return;
    }

    let tmpWeight = "";

    if (value.includes(".")) {
      if (wtAD.length === 0) {
        tmpWeight = wtBD + ".";
      } else {
        tmpWeight = `${wtBD}.${wtAD}`;
      }
    } else {
      tmpWeight = wtBD;
    }

    tempVitals[vitalCode].vitalValue = tmpWeight;

    setVitals(tempVitals);
  };

  const handleChangeHeight = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Height",
        unit: heightUnit || "",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    const unit = tempVitals[vitalCode].unit;
    let height = parseFloat(value);

    let htarr = value.split(".");
    let htBD = "";
    let htAD = "";

    if (htarr[0] && htarr[0].length > 0) {
      htBD = htarr[0];
      if (htBD.length > 3) {
        htBD = htBD.slice(0, 3);
      }
    }
    if (htarr[1] && htarr[1].length > 0) {
      htAD = htarr[1];
      if (htAD.length > 2) {
        htAD = htAD.slice(0, 2);
      }
    }

    if (value === "") {
      tempVitals[vitalCode].vitalValue = value;
      setVitals(tempVitals);
      return;
    }

    if (!height || isNaN(height)) {
      return;
    }

    if (unit === "cm") {
      if (htarr[0] && htarr[0].length > 3) {
        htBD = htarr[0].slice(0, 3);
      }
      if (htarr[1] && htarr[1].length > 2) {
        htAD = htarr[1].slice(0, 2);
      }
      let tempStr = "";
      if (value.includes(".")) {
        if (htAD.length === 0) {
          tempStr = htBD + ".";
        } else {
          tempStr = `${htBD}.${htAD}`;
        }
      } else {
        tempStr = htBD;
      }

      tempVitals[vitalCode].vitalValue = tempStr;
    } else if (unit === "ft~in") {
      tempVitals[vitalCode].vitalValue = htBD;
    }
    setVitals(tempVitals);
  };

  const handleChangeFoot = (value, vitalCode) => {
    let tempVitals = { ...vitals };
    let foot = 0;
    let inch = 0;

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Height",
        unit: heightUnit || "ft~in",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    if (
      tempVitals[vitalCode].vitalValue &&
      tempVitals[vitalCode].vitalValue !== ""
    ) {
      const tmp = parseInt(tempVitals[vitalCode].vitalValue);
      if (tmp) {
        foot = Math.floor(tmp / 12);
        inch = Math.floor(tmp % 12);
      }
    }

    if (!value || value === "") {
      tempVitals[vitalCode].vitalValue = 0 * 12 + inch;
      setVitals(tempVitals);
      return;
    }
    foot = parseInt(value);
    if (isNaN(foot) || foot > 10) {
      return;
    }
    tempVitals[vitalCode].vitalValue = foot * 12 + inch;
    setVitals(tempVitals);
  };

  const handleChangeInch = (value, vitalCode) => {
    let tempVitals = { ...vitals };
    let foot = 0;
    let inch = 0;

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Height",
        unit: heightUnit || "ft~in",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    if (
      tempVitals[vitalCode].vitalValue &&
      tempVitals[vitalCode].vitalValue !== ""
    ) {
      const tmp = parseInt(tempVitals[vitalCode].vitalValue);
      if (tmp) {
        foot = Math.floor(tmp / 12);
        inch = Math.floor(tmp % 12);
      }
    }

    if (!value || value === "") {
      tempVitals[vitalCode].vitalValue = foot * 12 + 0;
      setVitals(tempVitals);
      return;
    }
    inch = parseInt(value);
    if (isNaN(inch) || inch >= 12) {
      return;
    }
    tempVitals[vitalCode].vitalValue = foot * 12 + inch;
    setVitals(tempVitals);
  };

  const handleLmpChange = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "LMP",
        unit: null,
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    if (value) {
      tempVitals[vitalCode].vitalValue = value;
      setVitals(tempVitals);
    }
  };

  const validateVitals = () => {
    const tempVitals = Object.values(vitals);
    let isValid = true;
    const problemList = [];

    const vitalCodeNameMap = {
      BP: "Blood Pressure",
      TEMPERATURE: "Temperature",
      SPO2: "SPO2",
      RESPIRATION_RATE: "Respiratory Rate",
      HEART_RATE: "Heart Rate",
      WEIGHT: "Weight",
      HEIGHT: "Height",
      LMP: "LMP",
      UPPER_ARM_CIRCUMFERENCE: "Upper Arm Circumference",
      HEAD_CIRCUMFERENCE: "Head Circumference",
    };

    const vitalCodeList = [
      "BP",
      "TEMPERATURE",
      "SPO2",
      "RESPIRATION_RATE",
      "HEART_RATE",
      "WEIGHT",
      "HEIGHT",
    ];

    if (
      selectedPatient.patientGender === "Female" &&
      getAge(selectedPatient.patientDob) >= 12
    ) {
      vitalCodeList.push("LMP");
    }

    if (getAge(selectedPatient.patientDob) <= 4) {
      vitalCodeList.push("UPPER_ARM_CIRCUMFERENCE");
      vitalCodeList.push("HEAD_CIRCUMFERENCE");
    }
    let list = [];

    vitalCodeList.forEach((item) => {
      if (!vitals[item]) {
        // setVitalErrorList(prev=>[...prev,`${item} `])
        isValid = false;
        // list.push(`${vitalCodeNameMap[item]} is not provided `);
        list.push(vitalCodeNameMap[item]);
      } else if (
        vitals[item].vitalCode === "BP" &&
        vitals[item].vitalValue === "/"
      ) {
        isValid = false;
        // list.push(`${vitalCodeNameMap[item]} is not provided`);
        list.push(vitalCodeNameMap[item]);
      } else if (
        vitals[item].vitalValue === null ||
        vitals[item].vitalValue === ""
      ) {
        isValid = false;
        // list.push(`${vitalCodeNameMap[item]} is not provided`);
        list.push(vitalCodeNameMap[item]);
      }
    });
    let missingVitalString = list?.join(",");
    if (missingVitalString?.length > 0) {
      list = [missingVitalString + " are not provided"];
    }

    // if (!isValid) {
    //   setVitalErrorList((prev) => [...list]);

    //   setShowConfirmation(true);
    //   return false;
    // }

    const bp = tempVitals.find((item) => item.vitalCode === "BP");
    const temp = tempVitals.find((item) => item.vitalCode === "TEMPERATURE");
    if (bp) {
      const bpList = bp.vitalValue.split("/");
      const sistolic = parseInt(bpList[0]);
      const diastolic = parseInt(bpList[1]);

      if (sistolic || diastolic) {
        if (sistolic === "" || !sistolic) {
          problemList.push("Sistolic pressure cannot be empty");
          toast.error(t("sistolic_pressure_cannot_be_empty"));
          isValid = false;
        }

        if (diastolic === "" || !diastolic) {
          problemList.push("Diastolic pressure cannot be empty");
          toast.error(t("diastolic_pressure_cannot_be_empty"));
          isValid = false;
        }

        if (sistolic < diastolic) {
          problemList.push("Sistolic pressure must be greater than diastolic");
          toast.error(t("sistolic_pressure_must_be_greater_than_diastolic"));
          isValid = false;
        }

        if (sistolic - diastolic < 30) {
          problemList.push(
            "Sistolic and diastolic pressure difference must be greater than 30"
          );
          toast.error(
            t(
              "sistolic_and_diastolic_pressure_difference_must_be_greater_than_30"
            )
          );
          isValid = false;
        }
      }
    }

    if (temp) {
      const unit = temp.unit;
      const value = parseFloat(temp.vitalValue);
      if (unit.includes("C")) {
        if (value > 41.1 || value < 32.22) {
          problemList.push("Temparature must be within 32.22 to 41.10 °C");
          toast.error(t("temperature_range_error_in_celsius"));
          isValid = false;
        }
      } else if (unit.includes("F")) {
        if (value > 106 || value < 90) {
          problemList.push("Temparature must be within 90 to 106 °F");
          toast.error(t("temperature_range_error_in_fahrenheit"));
          isValid = false;
        }
      }
    }

    setVitalErrorList([...list, ...problemList]);

    if (!isValid) {
      setShowConfirmation(true);
    }

    return isValid;
  };

  const submitFormData = async () => {
    // if (
    //   !prescriptionData.patientComplaint ||
    //   (prescriptionData && prescriptionData.patientComplaint === "")
    // ) {
    //   toast.error(t("please_give_patient_complaints"));
    //   return;
    // }

    const toastLoaderId = toast.loading(t("submitting_details_please_wait"));

    let tempVisistData = await DashboardServices.generateVisitId(
      loginUserDetail.orgId
    );
    tempVisistData = tempVisistData?.data;
    const itemData = prescriptionData;
    console.log("Prescription Data", itemData);

    let doc = documents?.map((item) => {
      return {
        fileName: item.fileName,
        fileLink: item.fileLink,
        fileData: item.fileData,
        id: item.id,
      };
    });

    const prevDoc =
      previousMedicalRecords
        ?.filter((item) => item.isChecked)
        ?.map((item) => {
          return {
            fileName: item.fileName,
            fileLink: item.fileLink,
            fileData: item.fileData,
            id: item.id,
          };
        }) || [];

    doc = [...doc, ...prevDoc];

    const illnesses = illnessName?.map((item) => item.systemicIllnessName);

    const abuseNameList = abuseName?.map((item) => item.substanceAbuseName);

    const foodAllergies = prescriptionData?.allergicFood?.map(
      (item) => item.foodName
    );

    let hereditaryDiseases =
      prescriptionData?.familyHistory?.map((item) => {
        return {
          diseaseName: item.disease.diseaseName,
          relationship: item.relationShip,
          // id: item.disease.id,
        };
      }) || [];

    if (
      prescriptionData?.prevFamilyDisease &&
      prescriptionData?.prevFamilyDisease?.length > 0
    ) {
      hereditaryDiseases = [
        ...hereditaryDiseases,
        ...prescriptionData?.prevFamilyDisease,
      ];
    }
    // ;

    let medicineAllergies =
      prescriptionData?.allergicDrug?.map((item) => {
        const { brand, genericItem } = item;
        let type = null;
        if (brand) {
          return {
            type: "BRAND",
            genericBrandId: brand.id,
            genericBrandName: brand.brandName,
          };
        } else {
          return {
            type: "GENERIC",
            genericBrandId: genericItem.id,
            genericBrandName: genericItem.generic,
          };
        }
      }) || [];
    if (
      prescriptionData?.previousDrugAllergy &&
      prescriptionData?.previousDrugAllergy?.length > 0
    ) {
      medicineAllergies = [
        ...medicineAllergies,
        ...prescriptionData?.previousDrugAllergy,
      ];
    }

    let symptoms = prescriptionData.symptoms?.map((item, index) => {
      let symptomId = item.selectedSymptom.locallyAdded
        ? 0
        : item.selectedSymptom.id;
      return {
        symptomName: item.selectedSymptom.symptomName,
        symptomId,
        severity: item.severity,
        since: item.since,
        slNo: index + 1,
      };
    });

    let diseases =
      prescriptionData?.pastIllness?.map((item) => {
        return {
          diseaseName: item.illness.diseaseName,
          diagnosisDate: dayjs(item.date).format("DD-MM-YYYY"),
          id: 0,
          nature: "CONFIRM",
        };
      }) || [];

    if (prescriptionData?.prevIllness) {
      diseases = [...diseases, ...prescriptionData?.prevIllness];
    }

    let procedures =
      prescriptionData?.prevSurgery?.map((item) => {
        return {
          id: 0,
          procedureName: item.surgery.procedureName,
          procedureDate: dayjs(item.date).format("DD-MM-YYYY"),
        };
      }) || [];

    if (prescriptionData?.prevSurgerry) {
      procedures = [...procedures, ...prescriptionData?.prevSurgerry];
    }

    const currentMedications = prescriptionData?.medication?.map((item) => {
      const { brandName, frequency, dosage, genericName } = item;

      let type = null;
      let genericBrandId = 0;
      let genericBrandName = "";
      if (brandName) {
        type = "BRAND";
        genericBrandId = brandName.id;
        genericBrandName = brandName.brandName;
      } else {
        type = "GENERIC";
        genericBrandId = genericName.id;
        genericBrandName = genericName.generic;
      }

      return {
        type: type,
        genericBrandId: genericBrandId,
        genericBrandName: genericBrandName,
        dosages: dosage?.dosage || "",
        dosagesUnit: dosage?.dosageUnit || "",
        since: dayjs(item.since).format("DD-MM-YYYY"),
        frequency: frequency?.frequency || "",
      };
    });

    const allVitals = { ...vitals };
    //handle lmp date format it should be dd-mm-yyyy
    const tempLMP = allVitals["LMP"];
    if (tempLMP) {
      const { vitalValue } = tempLMP;
      if (vitalValue) {
        const tmp = dayjs(new Date(vitalValue)).format("DD-MM-YYYY");
        tempLMP.vitalValue = tmp;
        allVitals.LMP = tempLMP;
      }
    }
    const tempBP = allVitals["BP"];
    if (tempBP) {
      const { vitalValue } = tempBP;
      if (vitalValue === "/") {
        tempBP.vitalValue = "";
        allVitals.BP = tempBP;
      }
    }

    const tmpVitals = Object.values(allVitals)?.filter((item) => {
      if (!item.vitalValue) {
        return false;
      } else {
        return true;
      }
    });

    // const tmpVitals = Object.values(vitals);

    // const audioData = await getAudioBase64();
    const audioNoteS3TempUrl = recordingLink;
    const submitDto = {
      visitIdDisplay: tempVisistData,
      patientIdDisplay: selectedPatient.patientId,
      patientId: selectedPatient.rowPatientId,
      unitId: loginUserDetail.signleUnitId,
      doctorId: selectedDoctor.doctorId,
      orgId: loginUserDetail.orgId,
      audioNote: audioNoteS3TempUrl || "",
      dob: selectedPatient.patientDob,
      complaints: prescriptionData.patientComplaint || "",
      bloodType: bloodGroup || "",
      patientName: selectedPatient.patientFullName,
      gender: selectedPatient.patientGender || "",
      patientProfileUrl: selectedPatient.patientImageUrl,
      consultationType: "OPD",
      referredBy: referedBy,
      clinicName: loginUserDetail.unitLocationName
        ? loginUserDetail.unitLocationName
        : "",
      clinicContactNo: loginUserDetail.unitContactNo
        ? loginUserDetail.unitContactNo
        : "",
      patientType: "New",

      vitals: tmpVitals || [],
      documents: doc || [],
      systemicIllnesses: illnesses || [],
      substanceAbuse: abuseNameList || [],
      currentMedications: currentMedications || [],
      procedures: procedures || [],
      diseases: diseases || [],
      hereditaryDiseases: hereditaryDiseases || [],
      medicineAllergies: medicineAllergies || [],
      foodAllergies: foodAllergies || [],
      sos: sos ? "Y" : "N",
      doctorAvgConsultTime: selectedDoctor?.avgConsultTime || 0,
      doctorName: selectedDoctor?.doctorName,
      doctorSpecilization: selectedDoctor?.specializationName || "",
      slotId: 0,
      symptoms: symptoms || [],
      emergencyContactNo: emergencyPhoneNumber,
      emergencyContactCallingCode: emergencyCallingCode,
      emergencyContactName: guardianName,
      insuranceProvider: insuranceProvider,
      insuranceNo: insuranceNumber,
    };

    console.log("submit Dto", submitDto);
    // toast.dismiss(toastLoaderId);
    // return;

    DashboardServices.submitPatientVisit(submitDto)
      .then((response) => {
        if (response.data.returnValue === "1") {
          toast.update(toastLoaderId, {
            render: t("patient_visit_data_success"),
            type: "success",
            isLoading: false,
            autoClose: true,
          });

          if (response.data.page === "Q") {
            // toast.success("Consultation booking complete");
            closeBookConsultation();
            return;
          }

          const key = uuidv4();
          const eventDto = {
            eventTitle: `${response.data.visitId}) Consultation with ${selectedDoctor.doctorName}`,
            eventDescription: `Consultation with ${selectedDoctor.doctorName}`,
            eventStartTime: new Date().toString(),
            eventEndTime: new Date(Date.now() + 20 * 60 * 1000).toString(),
            eventId: response.data.visitId,
            eventType: "Consultation",
            eventLength: 20,
            eventLink: response.data.roomId,
            patientName: selectedPatient.patientFullName,
            patientImageUrl: selectedPatient.patientImageUrl,
          };

          const visitData = {
            visitId: response.data.visitId,
            patientId: selectedPatient.rowPatientId,
            orgId: loginUserDetail.orgId,
            doctorId: loginUserDetail.userId,
            unitId: loginUserDetail.signleUnitId,
          };

          // DashboardServices.startConsultation(response.data.visitId).then(
          //   (response) => response.data
          // );
          DashboardServices.connectDoctor(response.data.visitId).then(
            (response) => response.data
          );

          DashboardServices.getVisitInfoBeforeConsult(visitData).then(
            (visitInfo) => {
              const itemData = {
                queuePatientName: selectedPatient.patientFullName,
                // queueAttempsCount: data.queueAttempsCount,
                queuePatientAge: calculateDateDifference(
                  selectedPatient.patientDob
                ),
                // queueAddTime: data.queueAddTime,
                queuePatientGender: selectedPatient.patientGender,
                queuePatientProfileUrl: selectedPatient.patientImageUrl,
                queueConsultationType: "OPD",
                queueVitals: tmpVitals || [],
                // queueVitals: "",
                queueComplaints: "",
                queueReferredBy: referedBy || "",
                queueClinicName: loginUserDetail.unitLocationName || "",
                queueClinicContactNo: "",
                queuePatientType: "New",
                queueVisitId: response.data.visitId,
                queuePatientId: selectedPatient.rowPatientId,
                queuePatientUnitId: loginUserDetail.signleUnitId,
                queueRoomId: response.data.roomId,
                reqValue: visitInfo.data,
                doctorId: selectedDoctor.doctorId,
                doctorName: selectedDoctor.doctorName,
                isDoctor: false,
                noFeedback: true,
                symptoms: symptoms,
                bloodGroup: bloodGroup,
              };

              addTab({
                key: key,
                title: `${eventDto.eventTitle}`,
                content: (
                  <ConfirmProvider>
                    <PatientMeetingsTabContainer
                      eventData={eventDto}
                      tabKey={key}
                      extraData={itemData}
                    />
                  </ConfirmProvider>
                ),
                isDraggable: true,
                type: "Meeting",
              });
            }
          );
        } else {
          // toast.error(response.data.message);
          toast.update(toastLoaderId, {
            render: response.data.message,
            type: "error",
            isLoading: false,
            autoClose: true,
          });
        }
      })
      .catch((e) => {
        toast.dismiss(toastLoaderId);
      });
  };

  const fetchPatientData = (patientDto) => {
    DashboardServices.getPreviousMedicalRecords(patientDto.rowPatientId).then(
      (response) => {
        if (response.data) {
          let patientInfo = response.data;
          setPatientInfo(patientInfo);
          handlePatientInfo(patientInfo);
        }
      }
    );

    DashboardServices.getPatientPastUploadedDocuments(
      patientDto.rowPatientId
    ).then((response) => {
      if (response.data) {
        setPreviousMedicalRecords(response.data);
      }
    });
  };

  const onFaceLivenessCheckComplete = async (base64Image) => {
    setRegisterFace(false);
    console.log("onFaceLivenessCheckComplete", base64Image);

    let reqDto = {
      base64Image: base64Image,
      orgId: loginUserDetail.orgId,
    };
    const toastLoaderId = toast.loading(
      t("fetching_facial_biometrics_data_please_wait")
    );
    const resGetFaceData = await zhPatientService.getPatDtlsByFaceData(reqDto);

    console.log(resGetFaceData.data);

    if (resGetFaceData?.data.rowPatientId > 0) {
      toast.update(toastLoaderId, {
        render: t(
          "facial_biometrics_data_found_patient_details_fetched_successfully"
        ),
        type: "success",
        isLoading: false,
        autoClose: true,
      });
      setSelectedPatient(resGetFaceData.data);
      setBloodGroup(resGetFaceData.data?.bloodType);
      initializeForm();
      fetchPatientData(resGetFaceData.data);
      zhPatientService.updatePatientDataLastAccessTime(
        resGetFaceData.data.rowPatientId
      );
      // setPatientList((prevItems) => [...prevItems, resGetFaceData.data]);
      setPatientList((prevItems) =>
        prevItems.some(
          (item) => item.rowPatientId === resGetFaceData.data.rowPatientId
        )
          ? prevItems
          : [...prevItems, resGetFaceData.data]
      );
    } else {
      toast.update(toastLoaderId, {
        render: t("facial_biometrics_not_found_please_register_patient_first"),
        type: "info",
        isLoading: false,
        autoClose: true,
      });
    }
  };

  const [forwardTo, setForwardTo] = useState("add-to-queue");

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("book_consultation")}</div>
            <div className="rfcHActionBtnGrp">
              {/* <Button
                startIcon={<HeadsetMicRounded />}
                className="raiseSupportBtn"
              >
                Raise support request
              </Button> */}
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddBookConsultation()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer">
                <div className="formElementGrp">
                  <div className="searchWthfilterBtn">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <Autocomplete
                          freeSolo
                          className="formAutocompleteField"
                          variant="outlined"
                          options={patientList}
                          // value={selectedPatient}

                          onChange={(e, patientDto) => {
                            console.log("onChange", patientDto);
                            if (patientDto?.rowPatientId) {
                              setBloodGroup(patientDto?.bloodType);
                              initializeForm();
                              fetchPatientData(patientDto);
                              setSelectedPatient(patientDto);
                            } else {
                              setSelectedPatient(null);
                            }
                          }}
                          getOptionLabel={(option) =>
                            option.patientFullName || ""
                          }
                          renderOption={(props, option) => (
                            <li
                              {...props}
                              style={{ padding: "4px 12px" }}
                              className="sPatOptList"
                              key={option.rowPatientId}
                            >
                              <div className="spname">
                                {option.patientFullName}
                              </div>
                              <div className="spage">
                                {`${calculateDateDifference(
                                  option.patientDob
                                )} (${option.patientGender})`}
                              </div>
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("search_patient")}
                              className="formAutoComInputField autocompFildPlaceholder"
                            />
                          )}
                        />
                      </FormControl>
                    </div>

                    <IconButton
                      className="filterPatBtn"
                      onClick={handleClick}
                      aria-describedby={id}
                    >
                      <Tune />
                    </IconButton>

                    <Popper
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      transition
                      placement="bottom-end"
                      className="filterPopper"
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Box className="filterPopperBox">
                            <FilterPopperBox
                              // patientList={patientList}
                              selectPatient={(data) => {
                                const tempPatient = patientList?.find(
                                  (patient) =>
                                    patient.rowPatientId === data.rowPatientId
                                );
                                if (tempPatient) {
                                  setSelectedPatient(tempPatient);
                                } else {
                                  setSelectedPatient(data);
                                }
                                setOpen(false);
                                setPatientList((prevItems) => [
                                  ...prevItems,
                                  data,
                                ]);
                                console.log("patientData =", data);
                                setBloodGroup(data.bloodType);
                                initializeForm();
                                fetchPatientData(data);
                              }}
                            />
                          </Box>
                        </Fade>
                      )}
                    </Popper>
                  </div>
                  {/* <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel htmlFor="search-patient">
                        Search patient
                      </InputLabel>
                      <OutlinedInput
                        id="search-patient"
                        type="text"
                        autoComplete="off"
                        variant="outlined"
                        className="formTextFieldArea outlinedInputSP"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton edge="end" className="filterPat">
                              <Tune />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Search patient"
                      />
                    </FormControl>
                  </div> */}

                  <div className="scanFaceBtn">
                    <IconButton onClick={() => handelOpenRegisterFace()}>
                      <PersonBoundingBox />
                    </IconButton>
                  </div>
                </div>
                <div className="bb"></div>

                {selectedPatient && (
                  <>
                    <div className="pDemographyImgGrp">
                      <div className="pDemography">
                        <div className="pDemogInd pdlargTxt">
                          {/* <span>Name:</span> */}
                          <span className="pDVlue">
                            {selectedPatient?.patientFullName}
                          </span>
                        </div>
                        <div className="pDemogInd pdSmallTxt">
                          {/* <span>Gender:</span> */}
                          <span className="pDVlue">
                            {selectedPatient?.patientGender}
                          </span>
                          <span className="pDVlue">
                            {calculateDateDifference(
                              selectedPatient?.patientDob
                            )}
                          </span>
                        </div>
                        {bloodGroup && (
                          <div className="pDemogInd pdSmallTxt">
                            <span className="pDVlue">{t("blood_group")}:</span>
                            <span className="pDVlue">{bloodGroup}</span>
                          </div>
                        )}
                      </div>
                      <div className="patImage">
                        <img
                          src={selectedPatient?.patientImageUrl}
                          alt={t("patient_profile")}
                        />
                      </div>
                    </div>

                    <div className="taskElementGrp mt10">
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              autoComplete="off"
                              label={t("guardian_name")}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={guardianName}
                              onChange={(event) =>
                                setGuardianName(event.target.value)
                              }
                            />
                          </FormControl>
                        </div>
                        <div className="custContactNo">
                          <select
                            name="countryCode"
                            id="countryCode"
                            className="countryCode"
                            value={emergencyCallingCode}
                            onChange={(event) =>
                              setEmergencyCallingCode(event.target.value)
                            }
                            // onChange={(event) => {
                            //   const selectedValue =
                            //     event.target.value !== ""
                            //       ? event.target.value
                            //       : patientData?.emergencyContactCallingCode &&
                            //         patientData.emergencyContactCallingCode !== ""
                            //       ? patientData.emergencyContactCallingCode
                            //       : emplyPhnCC && emplyPhnCC !== ""
                            //       ? emplyPhnCC
                            //       : "";
                            //   updatePatientData(
                            //     "emergencyContactCallingCode",
                            //     selectedValue
                            //   );
                            //   switch(selectedValue) {
                            //     case "+91": // India
                            //       setPhoneRegexPattern(/^[6-9]\d{9}$/);
                            //       break;
                            //     case "+52": // Mexico
                            //       setPhoneRegexPattern(/^[1-9]\d{9}$/);
                            //       break;
                            //     case "+971": // UAE
                            //       setPhoneRegexPattern(/^(?:\50|51|52|55|56|2|3|4|6|7|9)\d{7}$/);
                            //       break;
                            //     default:
                            //       setPhoneRegexPattern(/^[0-9]{10,}$/); // Default pattern for other countries
                            //       break;
                            //   }
                            // }}
                          >
                            {cdCodeList.map((cdCodeList) => (
                              <option key={cdCodeList} value={cdCodeList}>
                                {cdCodeList}
                              </option>
                            ))}
                          </select>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                autoComplete="off"
                                label={t("emergency_contact_no")}
                                variant="outlined"
                                className="formTextFieldArea"
                                value={emergencyPhoneNumber}
                                onChange={(event) => {
                                  const value = event.target.value;
                                  if (/^\d*$/.test(value)) {
                                    setEmergencyPhoneNumber(value);
                                  }
                                }}
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                }}
                              />
                            </FormControl>
                          </div>
                        </div>
                      </div>

                      {/* </div>
                      <div className="taskElementGrp mt10"> */}
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // hiddenLabel
                              autoComplete="off"
                              label={t("insurance_provider_name")}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={insuranceProvider}
                              onChange={(event) =>
                                setInsuranceProvider(event.target.value)
                              }
                            />
                          </FormControl>
                        </div>

                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // hiddenLabel
                              autoComplete="off"
                              label={t("insurance_provider_no")}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={insuranceNumber}
                              onChange={(event) =>
                                setInsuranceNumber(event.target.value)
                              }
                            />
                          </FormControl>
                        </div>
                      </div>
                    </div>

                    <div className="tskElemHeding">{t("vitals")}</div>
                    {/* <div className="vitalsGroup">
                      <div className="vitalsInd">
                        <div className="vitIcon">
                          <BP />
                        </div>
                        <div className="vitInfo">
                          <div className="vitNm">{t("blood_pressure")}</div>
                          <div
                            className={`vitValue ${
                              vitals?.BP?.isNormal === "Y" ||
                              !vitals ||
                              !vitals.BP
                                ? "vNormal"
                                : "vRisk"
                            }`}
                          >
                            <input
                              className="editInputVitals"
                              type="text"
                              placeholder={t("sys")}
                              value={
                                vitals?.BP?.vitalValue?.split("/")?.[0] || ""
                              }
                              onChange={(e) => {
                                handleChangeSistolic("BP", e);
                              }}
                            />
                            <span className="slash"></span>
                            <input
                              className="editInputVitals"
                              type="text"
                              placeholder={t("dys")}
                              value={
                                vitals?.BP?.vitalValue?.split("/")?.[1] || ""
                              }
                              onChange={(e) => {
                                handleChangeDiastolic("BP", e);
                              }}
                            />
                            <span>
                              {vitals?.BP?.unit || getUnitFromVitalCode("BP")}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="vitalsInd">
                        <div className="vitIcon">
                          <HeartPulse />
                        </div>
                        <div className="vitInfo">
                          <div className="vitNm">{t("heart_rate")}</div>
                          <div
                            className={`vitValue ${
                              vitals?.HEART_RATE?.isNormal === "Y" ||
                              !vitals ||
                              !vitals.HEART_RATE
                                ? "vNormal"
                                : "vRisk"
                            }`}
                          >
                            <input
                              className="editInputVitals"
                              type="text"
                              placeholder={t("example_eighty")}
                              value={vitals?.HEART_RATE?.vitalValue || ""}
                              onChange={(e) =>
                                handleChangeHeartRage("HEART_RATE", e)
                              }
                            />
                            <span>
                              {getUnitFromVitalCode("HEART_RATE") || "bpm"}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="vitalsInd">
                        <div className="vitIcon">
                          <Lungs />
                        </div>
                        <div className="vitInfo">
                          <div className="vitNm">{t("respiration_rate")}</div>
                          <div
                            className={`vitValue ${
                              vitals?.RESPIRATION_RATE?.isNormal === "Y" ||
                              !vitals ||
                              !vitals.RESPIRATION_RATE
                                ? "vNormal"
                                : "vRisk"
                            }`}
                          >
                            <input
                              className="editInputVitals"
                              type="text"
                              placeholder={t("eg_12")}
                              value={vitals?.RESPIRATION_RATE?.vitalValue || ""}
                              onChange={(e) => {
                                handleChangeRR(
                                  e.target.value,
                                  "RESPIRATION_RATE"
                                );
                              }}
                            />
                            <span>
                              {getUnitFromVitalCode("RESPIRATION_RATE")}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="vitalsInd">
                        <div className="vitIcon">
                          <SPO2 />
                        </div>
                        <div className="vitInfo">
                          <div className="vitNm">{t("spo2")}</div>
                          <div
                            className={`vitValue ${
                              vitals?.SPO2?.isNormal === "Y" ||
                              !vitals ||
                              !vitals.SPO2
                                ? "vNormal"
                                : vitals?.SPO2?.isNormal === "M"
                                ? "vModarate"
                                : "vRisk"
                            }`}
                          >
                            <input
                              className="editInputVitals"
                              type="text"
                              placeholder={t("eg_98")}
                              value={vitals?.SPO2?.vitalValue || ""}
                              onChange={(e) => {
                                handleChangeSpo2(e.target.value, "SPO2");
                              }}
                            />
                            <span>{getUnitFromVitalCode("SPO2")}</span>
                          </div>
                        </div>
                      </div>

                      <div className="vitalsInd">
                        <div className="vitIcon">
                          <ThermometerHalf />
                        </div>
                        <div className="vitInfo">
                          <div className="vitNm">{t("temperature")}</div>
                          <div
                            className={`vitValue ${
                              vitals?.TEMPERATURE?.isNormal === "Y" ||
                              !vitals ||
                              !vitals.TEMPERATURE
                                ? "vNormal"
                                : vitals?.TEMPERATURE?.isNormal === "M"
                                ? "vModarate"
                                : "vRisk"
                            }`}
                          >
                            <input
                              className="editInputVitals"
                              type="text"
                              placeholder={getTemperaturePlaceholder}
                              value={vitals?.TEMPERATURE?.vitalValue || ""}
                              onChange={(e) => {
                                handleChangeTemparature(
                                  e.target.value,
                                  "TEMPERATURE"
                                );
                              }}
                            />
                            <select
                              className="editInputUnits"
                              value={temperatureUnit}
                              onChange={(e) => {
                                setTemperatureUnit(e.target.value);
                                const tempVital = { ...vitals };
                                if (!tempVital.TEMPERATURE) {
                                  tempVital.TEMPERATURE = {
                                    vitalName: "Temperature",
                                    unit: e.target.value,
                                    vitalValue: "",
                                    // recodedTime: "",
                                    isNormal: "Y",
                                    vitalCode: "TEMPERATURE",
                                  };
                                } else {
                                  tempVital.TEMPERATURE.unit = e.target.value;
                                }
                                setVitals(tempVital);
                              }}
                            >
                              {vitalUnitMap?.["TEMPERATURE"]?.map((unit) => {
                                return <option value={unit}>{unit} </option>;
                              })}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="vitalsInd">
                        <div className="vitIcon">
                          <MonitorWeightOutlined />
                        </div>
                        <div className="vitInfo">
                          <div className="vitNm">{t("weight")}</div>
                          {console.log("vital height =", vitals?.WEIGHT)}
                          <div
                            className={`vitValue ${
                              vitals?.WEIGHT?.isNormal === "Y" ||
                              !vitals ||
                              !vitals.WEIGHT
                                ? "vNormal"
                                : vitals?.WEIGHT?.isNormal === "M"
                                ? "vModarate"
                                : "vRisk"
                            }`}
                          >
                            <input
                              className="editInputVitals"
                              type="text"
                              placeholder={t("eg_80")}
                              value={vitals?.WEIGHT?.vitalValue || ""}
                              onChange={(e) => {
                                handleChangeWeight(e.target.value, "WEIGHT");
                              }}
                            />
                            <select
                              className="editInputUnits"
                              value={weightUnit}
                              onChange={(e) => {
                                setWeightUnit(e.target.value);
                                const tempVital = { ...vitals };
                                if (!tempVital.WEIGHT) {
                                  tempVital.WEIGHT = {
                                    vitalName: "Weight",
                                    unit: e.target.value,
                                    vitalValue: "",
                                    // recodedTime: "",
                                    isNormal: "Y",
                                    vitalCode: "WEIGHT",
                                  };
                                } else {
                                  tempVital.WEIGHT.unit = e.target.value;
                                }
                                setVitals(tempVital);
                              }}
                            >
                              {vitalUnitMap?.["WEIGHT"]?.map((unit) => {
                                return <option value={unit}>{unit} </option>;
                              })}
                             
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="vitalsInd">
                        <div className="vitIcon">
                          <HeightOutlined />
                        </div>
                        <div className="vitInfo">
                          <div className="vitNm">{t("height")}</div>
                          <div
                            className={`vitValue ${
                              vitals?.HEIGHT?.isNormal === "Y" ||
                              !vitals ||
                              !vitals.HEIGHT
                                ? "vNormal"
                                : "vRisk"
                            }`}
                          >
                            {heightUnit === "ft~in" && (
                              <>
                                <input
                                  className="editInputVitals"
                                  type="text"
                                  placeholder={t("eg_182")}
                                  value={
                                    Math.floor(
                                      parseInt(vitals?.HEIGHT?.vitalValue) / 12
                                    ) || ""
                                  }
                                  onChange={(e) => {
                                    handleChangeFoot(e.target.value, "HEIGHT");
                                  }}
                                />
                                <span>ft</span>
                                <input
                                  className="editInputVitals"
                                  type="text"
                                  placeholder={t("eg_182")}
                                  value={
                                    Math.floor(
                                      parseInt(vitals?.HEIGHT?.vitalValue) % 12
                                    ) || ""
                                  }
                                  onChange={(e) => {
                                    handleChangeInch(e.target.value, "HEIGHT");
                                  }}
                                />
                              </>
                            )}

                            {heightUnit !== "ft~in" && (
                              <input
                                className="editInputVitals"
                                type="text"
                                placeholder={t("eg_182")}
                                value={vitals?.HEIGHT?.vitalValue || ""}
                                onChange={(e) => {
                                  handleChangeHeight(e.target.value, "HEIGHT");
                                }}
                              />
                            )}
                            <select
                              className="editInputUnits"
                              value={heightUnit}
                              onChange={(e) => {
                                setHeightUnit(e.target.value);
                                const tempVital = { ...vitals };
                                if (!tempVital.HEIGHT) {
                                  tempVital.HEIGHT = {
                                    vitalName: "Weight",
                                    unit: e.target.value,
                                    vitalValue: "",
                                    // recodedTime: "",
                                    isNormal: "Y",
                                    vitalCode: "HEIGHT",
                                  };
                                } else {
                                  tempVital.HEIGHT.unit = e.target.value;
                                }
                                setVitals(tempVital);
                              }}
                            >
                              {vitalUnitMap?.["HEIGHT"]?.map((unit) => {
                                return <option value={unit}>{unit} </option>;
                              })}

                          
                            </select>
                          </div>
                        </div>
                      </div>
                      {selectedPatient &&
                        getAge(selectedPatient?.patientDob) <= 4 && (
                          <div className="vitalsInd">
                            <div className="vitIcon">
                              <HeadC />
                            </div>
                            <div className="vitInfo">
                              <div className="vitNm">
                                {t("head_circumference")}
                              </div>
                              <div
                                className={`vitValue ${
                                  vitals?.HEAD_CIRCUMFERENCE?.isNormal ===
                                    "Y" ||
                                  !vitals ||
                                  !vitals.HEAD_CIRCUMFERENCE
                                    ? "vNormal"
                                    : "vRisk"
                                }`}
                              >
                                <input
                                  className="editInputVitals"
                                  type="text"
                                  placeholder={t("eg_34_5")}
                                  value={
                                    vitals?.HEAD_CIRCUMFERENCE?.vitalValue || ""
                                  }
                                  onChange={(e) => {
                                    handleChangeHeadCircumference(
                                      e.target.value,
                                      "HEAD_CIRCUMFERENCE"
                                    );
                                  }}
                                />
                                <select
                                  className="editInputUnits"
                                  value={headUnit}
                                  onChange={(e) => {
                                    setHeadUnit(e.target.value);
                                    const tempVital = { ...vitals };
                                    if (!tempVital.HEAD_CIRCUMFERENCE) {
                                      tempVital.HEAD_CIRCUMFERENCE = {
                                        vitalName: "Head Circumference",
                                        unit: e.target.value,
                                        vitalValue: "",
                                        // recodedTime: "",
                                        isNormal: "Y",
                                        vitalCode: "HEAD_CIRCUMFERENCE",
                                      };
                                    } else {
                                      tempVital.HEAD_CIRCUMFERENCE.unit =
                                        e.target.value;
                                    }
                                    setVitals(tempVital);
                                  }}
                                >
                                  {vitalUnitMap?.["HEAD_CIRCUMFERENCE"]?.map(
                                    (unit) => {
                                      return (
                                        <option value={unit}>{unit} </option>
                                      );
                                    }
                                  )}
                             
                                </select>
                              </div>
                            </div>
                          </div>
                        )}

                      {selectedPatient &&
                        getAge(selectedPatient?.patientDob) <= 4 && (
                          <div className="vitalsInd">
                            <div className="vitIcon">
                              <UAC />
                            </div>
                            <div className="vitInfo">
                              <div className="vitNm">
                                {t("upper_arm_circumference")}
                              </div>
                              <div
                                className={`vitValue ${
                                  vitals?.HEAD_CIRCUMFERENCE?.isNormal ===
                                    "Y" ||
                                  !vitals ||
                                  !vitals.HEAD_CIRCUMFERENCE
                                    ? "vNormal"
                                    : "vRisk"
                                }`}
                              >
                                <input
                                  className="editInputVitals"
                                  type="text"
                                  placeholder={t("eg_12_5")}
                                  value={
                                    vitals?.UPPER_ARM_CIRCUMFERENCE
                                      ?.vitalValue || ""
                                  }
                                  onChange={(e) => {
                                    handleChangeUpperArm(
                                      e.target.value,
                                      "UPPER_ARM_CIRCUMFERENCE"
                                    );
                                  }}
                                />
                                <select
                                  className="editInputUnits"
                                  value={upperArmUnit}
                                  onChange={(e) => {
                                    setUpperArmUnit(e.target.value);
                                    const tempVital = { ...vitals };
                                    if (!tempVital.UPPER_ARM_CIRCUMFERENCE) {
                                      tempVital.UPPER_ARM_CIRCUMFERENCE = {
                                        vitalName: "Temperature",
                                        unit: e.target.value,
                                        vitalValue: "",
                                        // recodedTime: "",
                                        isNormal: "Y",
                                        vitalCode: "UPPER_ARM_CIRCUMFERENCE",
                                      };
                                    } else {
                                      tempVital.UPPER_ARM_CIRCUMFERENCE.unit =
                                        e.target.value;
                                    }
                                    setVitals(tempVital);
                                  }}
                                >
                                  {vitalUnitMap?.[
                                    "UPPER_ARM_CIRCUMFERENCE"
                                  ]?.map((unit) => {
                                    return (
                                      <option value={unit}>{unit} </option>
                                    );
                                  })}
                                  <option value="cm">cm </option>
                                  <option value="in">inch </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        )}

                      {selectedPatient?.patientGender === "Female" &&
                        getAge(selectedPatient?.patientDob) >= 12 && (
                          <div className="vitalsInd">
                            <div className="vitIcon">
                              <LMP />
                            </div>
                            <div className="vitInfo">
                              <div className="vitNm">{t("lmp")}</div>
                              <div className="vitValue">
                                <input
                                  className="editInputDate"
                                  type="date"
                                  value={
                                    vitals?.LMP?.vitalValue
                                      ? dayjs(vitals?.LMP?.vitalValue).format(
                                          "YYYY-MM-DD"
                                        )
                                      : lmp
                                  }
                                  onChange={(event) => {
                                    handleLmpChange(event.target.value, "LMP");
                                    setLmp(event.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                      {!bloodGroup && (
                        <div className="vitalsInd">
                          <div className="vitIcon">
                            <BloodtypeOutlined />
                          </div>
                          <div className="vitInfo">
                            <div className="vitNm">{t("blood_group")}</div>
                            <div className="vitValue">
                              <select
                                className="editInputUnits"
                                onChange={(e) => {
                                  const tempVitals = { ...vitals };
                                  const value = e.target.value;
                                  // if (!vitals["BLOOD_GROUP"]) {
                                  //   tempVitals["BLOOD_GROUP"] = {
                                  //     vitalName: "Blood Group",
                                  //     unit: null,
                                  //     vitalValue: value,
                                  //     // recodedTime: "",
                                  //     isNormal: "Y",
                                  //     vitalCode: "BLOOD_GROUP",
                                  //   };
                                  //   setVitals(tempVitals);
                                  // } else {
                                  //   tempVitals["BLOOD_GROUP"].vitalValue = value;
                                  // }
                                  setBloodGroup(value);
                                }}
                                value={bloodGroup || ""}
                              >
                                <option value="" disabled selected>
                                  {t("select")}
                                </option>
                                <option value="A+">A + </option>
                                <option value="A-">A - </option>
                                <option value="B+">B + </option>
                                <option value="B-">B - </option>
                                <option value="AB+">AB + </option>
                                <option value="AB-">AB - </option>
                                <option value="O+">O + </option>
                                <option value="O-">O - </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      )}

                      <Button
                        startIcon={<DeviceHddFill />}
                        className={"dfultPrimaryBtn"}
                      >
                        {t("get_vitals_from_device")}
                      </Button>
                    </div> */}

                    <PatientVitals
                      data={[]}
                      patientAge={getAge(selectedPatient?.patientDob)}
                      patientGender={selectedPatient?.patientGender}
                      showBloodgroup={bloodGroup ? false : true}
                      handleDataChange={(data) => {}}
                      editMode={true}
                      handleSelectBloodGroup={(data) => {
                        if (data) {
                          setBloodGroup(data);
                        }
                      }}
                      handleSaveVitals={(data) => {
                        if (data) {
                          setVitals(data);
                        }
                      }}
                    />

                    <div className="bb"></div>

                    <PatientComplaints
                      data={prescriptionData}
                      updateData={updateData}
                      extraData={patientInfo}
                    />

                    {/* files start */}

                    <div className="taskElementGrp">
                      <div className="tskElemHeding">{t("files")}</div>

                      <div class="elementFileListedGrp">
                        <FileCursor>
                          <>
                            {documents?.map((item, index) => {
                              return (
                                <>
                                  <div className="elemntFile">
                                    <Tooltip
                                      title={item.fileName}
                                      arrow
                                      className="elmFileTolTip"
                                    >
                                      <span className="elemntFilIoc">
                                        <DescriptionOutlined />
                                      </span>
                                      <span className="elemntFilNm">
                                        {item.fileName}
                                      </span>
                                      <span
                                        className="elemntFilIoc"
                                        onClick={(e) => {
                                          const temp = [...documents];
                                          temp.splice(index, 1);
                                          setDocuments(temp);
                                        }}
                                      >
                                        <Cancel />
                                      </span>
                                    </Tooltip>
                                  </div>
                                </>
                              );
                            })}

                            {previousMedicalRecords
                              ?.filter((item) => item.isChecked)
                              ?.map((item, index) => {
                                return (
                                  <>
                                    <div className="elemntFile">
                                      <Tooltip
                                        title={item.fileName}
                                        arrow
                                        className="elmFileTolTip"
                                      >
                                        <span className="elemntFilIoc">
                                          <DescriptionOutlined />
                                        </span>
                                        <span className="elemntFilNm">
                                          {item.fileName}
                                        </span>
                                        <span
                                          className="elemntFilIoc"
                                          onClick={(e) => {
                                            const temp = [
                                              ...previousMedicalRecords,
                                            ];
                                            const tempIndex = temp.findIndex(
                                              (record) => record.id === item.id
                                            );
                                            if (tempIndex !== -1) {
                                              temp[tempIndex].isChecked = false;
                                              setPreviousMedicalRecords(temp);
                                            }
                                          }}
                                        >
                                          <Cancel />
                                        </span>
                                      </Tooltip>
                                    </div>
                                  </>
                                );
                              })}
                          </>
                        </FileCursor>

                        <div class="elmntAddMrFiles">
                          <Button
                            className="elemntMrFileBtn"
                            variant="outlined"
                            // onClick={handleAddnewFile}
                            onClick={(e) => {
                              setShowFileUploadDialog(true);
                            }}
                          >
                            <Plus />
                          </Button>
                          <input
                            type="file"
                            // ref={fileInputRef}
                            // onChange={handleChangeFile}
                            style={{ display: "none" }}
                          ></input>
                        </div>
                      </div>
                    </div>

                    {/* files end */}

                    {/* Audio Start */}

                    <div className="taskElementGrp mt10">
                      <div className="tskElemHeding">{t("audio")}</div>

                      <div class="elementFileListedGrp">
                        <div className="horaizonScroll"></div>
                        <div class="elmntAddMrFiles">
                          <Button
                            className="elemntMrFileBtn"
                            variant="outlined"
                            // onClick={handleAddnewFile}
                            onClick={(e) => {
                              setShowRecAudio(true);
                            }}
                          >
                            <Microphone />
                          </Button>
                        </div>
                      </div>
                    </div>

                    {/* Audio Start */}

                    <div className="taskElementGrp mt10">
                      <div className="tskElemHeding">
                        {t("systemic_illness")}
                      </div>

                      <div className="formElementGrp flex-wrap">
                        <div className="formElement ">
                          <FormControl className="formControl">
                            <RadioGroup
                              className="formRadioGroup"
                              labelId="setTasktype"
                              name="setTasktype"
                              value={systemicIllness}
                              onChange={selectSystemicIllness}
                            >
                              <FormControlLabel
                                className="formRadioField"
                                value="yes"
                                control={<Radio />}
                                label={t("yes")}
                              />
                              <FormControlLabel
                                className="formRadioField"
                                value="no"
                                control={<Radio />}
                                label={t("no")}
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        {systemicIllness === "yes" && (
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="demo-multiple-checkbox-label">
                                {t("systemic_illness")}
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                className="formInputField"
                                variant="outlined"
                                value={illnessName}
                                onChange={handleChangeIllness}
                                input={
                                  <OutlinedInput
                                    label={t("systemic_illness")}
                                  />
                                }
                                renderValue={(selected) =>
                                  selected
                                    .map((item) => item.systemicIllnessName)
                                    .join(", ")
                                }
                                MenuProps={MenuProps}
                              >
                                {systemicIllnessList.map((item) => (
                                  <MenuItem key={item.id} value={item}>
                                    <Checkbox
                                      checked={
                                        illnessName.findIndex(
                                          (element) => element.id === item.id
                                        ) > -1
                                      }
                                    />
                                    <ListItemText
                                      primary={`${item.systemicIllnessName}`}
                                    />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="taskElementGrp mt10">
                      <div className="tskElemHeding">
                        {t("substance_abuse")}
                      </div>

                      <div className="formElementGrp flex-wrap">
                        <div className="formElement ">
                          <FormControl className="formControl">
                            <RadioGroup
                              className="formRadioGroup"
                              labelId="setTasktype"
                              name="setTasktype"
                              value={substanceAbuse}
                              onChange={selectSubstanceAbuse}
                            >
                              <FormControlLabel
                                className="formRadioField"
                                value="yes"
                                control={<Radio />}
                                label={t("yes")}
                              />
                              <FormControlLabel
                                className="formRadioField"
                                value="no"
                                control={<Radio />}
                                label={t("no")}
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        {substanceAbuse === "yes" && (
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="demo-multiple-checkbox-label">
                                {t("substance_abuse")}
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                className="formInputField"
                                variant="outlined"
                                value={abuseName}
                                onChange={handleChangeAbuse}
                                input={
                                  <OutlinedInput label={t("substance_abuse")} />
                                }
                                renderValue={(selected) =>
                                  selected
                                    .map((item) => item.substanceAbuseName)
                                    .join(", ")
                                }
                                MenuProps={MenuProps}
                              >
                                {systemicAbuseList.map((item) => (
                                  <MenuItem key={item.id} value={item}>
                                    <Checkbox
                                      checked={
                                        abuseName.findIndex(
                                          (element) => element.id === item.id
                                        ) > -1
                                      }
                                    />
                                    <ListItemText
                                      primary={item.substanceAbuseName}
                                    />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="taskElementGrp mt10">
                      <div className="tskElemHeding">
                        {t("reference_details")}
                      </div>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            label={t("referred_by")}
                            autoComplete="off"
                            variant="outlined"
                            className="formTextFieldArea"
                            value={referedBy}
                            onChange={(e) => {
                              setReferedBy(e.target.value);
                            }}
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className="bb"></div>

                    <MedicalHistory
                      data={patientInfo}
                      updateData={updateData}
                      previousInfo={patientInfo}
                    />

                    <PatientFamilyHistory
                      data={prescriptionData}
                      updateData={updateData}
                      previousInfo={patientInfo}
                    />

                    <PatientDrugAllergy
                      data={prescriptionData}
                      updateData={updateData}
                      extraData={patientInfo}
                      previousInfo={patientInfo}
                    />
                    <PatientFoodAllergy
                      data={prescriptionData}
                      updateData={updateData}
                      extraData={patientInfo}
                      previousInfo={patientInfo}
                    />
                    {/* <div className="formElement selectBookingType">
                      <FormControl className="formControl">
                        <RadioGroup
                          className="formRadioGroup"
                          labelId="setTasktype"
                          name="setTasktype"
                          value={forwardTo}
                          onChange={(e) => setForwardTo(e.target.value)}
                        >
                          <FormControlLabel
                            className="formRadioField"
                            value="add-to-queue"
                            control={<Radio />}
                            label="Add to queue"
                          />
                          <FormControlLabel
                            className="formRadioField"
                            value="video-page"
                            control={<Radio />}
                            label="Proceed to video consultation page"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div> */}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="rfContFooter">
            <div className="doctorSelectWthSubBtn">
              <div className="formElementGrp">
                {/* <div className="formElement width13">
                  <FormControl className="formControl">
                    <InputLabel id="DoctorSpeciality-select-label">
                      Doctor Speciality *
                    </InputLabel>
                    <Select
                      labelId="DoctorSpeciality-select-label"
                      id="DoctorSpeciality-select"
                      value={doctorSpeciality}
                      label="Doctor Speciality *"
                      onChange={handleChangeDoctorSpeciality}
                      className="formInputField"
                      variant="outlined"
                    >
                      <MenuItem value="Allergist">
                        Allergist/Immunologist
                      </MenuItem>
                      <MenuItem value="Anesthesiologist">
                        Anesthesiologist
                      </MenuItem>
                      <MenuItem value="Cardiologist">Cardiologist </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <InputLabel id="doctorSelect-select-label">
                      Select Doctor *
                    </InputLabel>
                    <Select
                      labelId="doctorSelect-select-label"
                      id="doctorSelect-select"
                      value={doctorSelect}
                      label="Select Doctor *"
                      onChange={handleChangeDoctorSelect}
                      className="formInputField"
                      variant="outlined"
                    >
                      <MenuItem value="sa">Dr. Sk Aftabuddin </MenuItem>
                      <MenuItem value="ps">Dr. Prashant Shah </MenuItem>
                      <MenuItem value="ss">Dr. Sanket Santra </MenuItem>
                    </Select>
                  </FormControl>
                </div> */}

                <div className="formElement">
                  <FormControl className="formControl">
                    <Autocomplete
                      freeSolo
                      className="formAutocompleteField"
                      variant="outlined"
                      options={doctorList}
                      value={selectedDoctor}
                      onChange={(e, newValue) => {
                        setSelectedDoctor(newValue);
                      }}
                      inputValue={doctorInputString}
                      onInputChange={(e, value) => {
                        setDoctorInputString(value);
                      }}
                      getOptionLabel={(option) => option.doctorName || ""}
                      filterOptions={(options, { inputValue }) => {
                        return (
                          options?.filter(
                            (option) =>
                              option?.doctorName
                                ?.toLowerCase()
                                ?.includes(inputValue?.toLowerCase()) ||
                              option?.specializationName
                                ?.toLowerCase()
                                ?.includes(inputValue?.toLowerCase())
                          ) || []
                        );
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li
                            {...props}
                            style={{ padding: "4px 12px" }}
                            className="sDocOptList"
                          >
                            <div className="sDname">{option.doctorName}</div>
                            <div className="sDElm">
                              {option.specializationName}
                            </div>
                            <div className="sDElmGrp">
                              <div className="sDElm">
                                {t("experience")}:{" "}
                                <span>{option.experience}</span>
                              </div>
                              <div className="sDElm">
                                {t("fees")}:
                                <span>
                                  {option.fees} {option.feesCurrency}
                                </span>
                              </div>
                              <div className="sDElm">
                                {t("language")}: <span>{option.languages}</span>
                              </div>
                              <div className="sDElm">
                                {t("patient_in_queue")}:{" "}
                                <span>{option.queueCount}</span>
                              </div>
                            </div>
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("search_doctor")}
                          className="formAutoComInputField autocompFildPlaceholder"
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="formBtnElement">
                <Button
                  className={
                    sos ? "dfultPrimaryBtnSOS" : "dfultDarkSecondaryBtnSOS"
                  }
                  onClick={(e) => {
                    setSos((prev) => !prev);
                  }}
                >
                  {t("sos")}
                </Button>
              </div>

              <div className="formBtnElement">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={async () => {
                    if (!selectedPatient) {
                      toast.error(t("please_select_patient"));
                      return;
                    }
                    if (!selectedDoctor) {
                      toast.error(t("please_select_doctor"));
                      return;
                    }

                    const isValid = validateVitals();
                    if (!isValid) {
                      return;
                    }

                    submitFormData();
                  }}
                >
                  {t("submit")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {registerFace && (
        <ConfirmProvider>
          <PatientFacialBiometricsRegister
            open={registerFace}
            onClose={() => setRegisterFace(false)}
            setRegisterFace={setRegisterFace}
            onFaceLivenessCheckComplete={onFaceLivenessCheckComplete}
          />
        </ConfirmProvider>
      )}

      {showFileUploadDialog && (
        <div className="RightFlotingContainerArea">
          <PatientFileUpload
            documents={documents}
            setDocuments={setDocuments}
            onClose={(e) => {
              setShowFileUploadDialog(false);
            }}
            previousMedicalRecords={previousMedicalRecords}
            setPreviousMedicalRecords={setPreviousMedicalRecords}
          />
        </div>
      )}

      {showConfirmation && (
        <CustomModal
          modalTitle={t("do_you_want_to_continue_with_given_parameters")}
          // style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowConfirmation(false);
          }}
          className={"width90"}
        >
          <div>
            <ul>
              {VitalErrorList &&
                VitalErrorList.map((item) => {
                  return <li>{item}</li>;
                })}
            </ul>
          </div>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => {
                submitFormData();
                setShowConfirmation(false);
              }}
            >
              {t("continue")}
            </Button>
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                setShowConfirmation(false);
              }}
            >
              {t("cancel")}
            </Button>
          </div>
        </CustomModal>
      )}

      {showRecAudio && (
        <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
          <div className="addMoreAgendaModel">
            <div className="modelTitle">Audio Message</div>
            {/* <div className="modActionBtnGrp"></div> */}
            <RecordAudio
              closeDialog={() => setShowRecAudio(false)}
              tempAudioChunk={tempAudioChunk}
              recordingLength={recordingLength}
              setRecordingLength={setRecordingLength}
              recordingLink={recordingLink}
              setRecordingLink={setRecordingLink}
            />
          </div>
        </Box>
      )}
    </>
  );
}
