import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import TreeViewPlugin from "./plugins/TreeViewPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
// import { TableNode } from "./nodes/TableNode";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import "./zoyelRTE.css";
import { Suspense, useEffect, useState } from "react";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { Button } from "@mui/material";
import OnChangePlugin from "./plugins/OnChangePlugin";
import { ImageNode } from "./nodes/ImageNode";
import ContextMenuPlugin from "./plugins/ContextMenuPlugin";
import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin";
import { TablePlugin } from "./plugins/TablePlugin";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { ExcalidrawNode } from "./nodes/ExcalidrawNodes";
import ExcalidrawPlugin from "./plugins/ExcalidrawPlugin";
import { CollaborationPlugin } from "@lexical/react/LexicalCollaborationPlugin";
import * as Y from "yjs";
import { WebsocketProvider } from "y-websocket";
import { $createParagraphNode, $createTextNode, $getRoot } from "lexical";
import { StickyNode } from "./nodes/StickyNode";

function initialEditorState(editor, initialHtml) {
  const root = $getRoot();
  let nodes = null;
  if (initialHtml) {
    const parser = new DOMParser();
    const dom = parser.parseFromString(initialHtml, "text/html");
    nodes = $generateNodesFromDOM(editor, dom.body);
    root.clear();
    root.append(...nodes);
    return;
  }
  const paragraph = $createParagraphNode();
  const text = $createTextNode("Welcome to collab!");
  paragraph.append(text);
  root.append(paragraph);
}

function Placeholder({ placeholder }) {
  return <div className="editor-placeholder">{placeholder}</div>;
}

const editorConfig = {
  // The editor theme
  editorState: null,
  namespace: "Demo1",
  theme: ExampleTheme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableRowNode,
    TableCellNode,
    AutoLinkNode,
    LinkNode,
    ImageNode,
    HorizontalRuleNode,
    ExcalidrawNode,
    StickyNode,
  ],
};

// function ExportToHtml() {
//   const [editor] = useLexicalComposerContext();
//   const handleClick = () => {
//     editor.update(() => {
//       const editorState = editor.getEditorState();
//       console.log("jsonString", editorState.toJSON());
//       const htmlString = $generateHtmlFromNodes(editor);
//       console.log("htmlString", htmlString);
//     });
//   };
//   return (
//     <>
//       <Button onClick={() => handleClick()}>Submit</Button>
//     </>
//   );
// }

export default function MailRTE(props) {
  //   const [editorState, setEditorState] = useState();
  const {
    initialHtml,
    setRteHtml,
    setRteText,
    setImageList,
    imageList,
    placeholder,
  } = props;

  function fetchHtmlAndJSON(jsonStr, htmlStr) {
    // console.log("fetchHtmlAndJSON jsonStr", jsonStr);
    console.log("fetchHtmlAndJSON htmlStr", htmlStr);
    setRteHtml(htmlStr);
  }

  function fetchText(text) {
    console.log("fetchText jsonStr", text);
    // setRteText(text);
  }

  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);

  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  return (
    <LexicalComposer
      initialConfig={{
        ...editorConfig,
        editorState: (editor) => initialEditorState(editor, initialHtml),
      }}
    >
      <div className="editor-container">
        <ToolbarPlugin
          setImageList={setImageList}
          imageList={imageList}
          mode={"mail"}
        />
        <div className="editor-inner custom-scrollbar">
          <RichTextPlugin
            contentEditable={
              <div className="editor-scroller">
                <div className="editor-input" ref={onRef}>
                  <ContentEditable />
                </div>
              </div>
            }
            placeholder={
              <Placeholder placeholder={"Type Your Message Here..."} />
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HorizontalRulePlugin />

          <HistoryPlugin />
          <ExcalidrawPlugin />
          {/* <TreeViewPlugin /> */}
          <AutoFocusPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <OnChangePlugin
            fetchHtmlAndJSON={fetchHtmlAndJSON}
            fetchText={fetchText}
          />
          <ContextMenuPlugin />
          <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
          {/* <ExportToHtml /> */}
        </div>
      </div>
    </LexicalComposer>
  );
}
