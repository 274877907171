import {
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect } from "react";
import { Trash } from "react-bootstrap-icons";
import AddCompound from "./AddCompound";
import { useTranslation } from "react-i18next";

export const CompoundItem = ({
  compoundItem,
  compoundType,
  parentId,
  updateCompound,
  compoundList,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    console.log("compount item =", compoundItem);
  }, []);
  const classMap = { or: "compOr", then: "compThen" };
  return (
    <>
      <div
        className={`compoundIndv ${compoundType ? classMap[compoundType] : ""}`}
      >
        {compoundType && (
          <div className="compChild">
            {compoundType === "or" ? t("or") : t("then")}
          </div>
        )}
        <div className="compfulDtl">
          <div className="compHeader">
            <div className="compName">
              {compoundItem?.compound?.compoundName}
            </div>
            <div className="orThenCompGrp">
              <div className="formElement">
                <FormControl className="formControl">
                  <RadioGroup
                    className="formRadioGroup"
                    labelId="setTasktype"
                    name="regimeCondition"
                    value={compoundItem?.compoundOrThen}
                    onChange={(e) => {
                      const dto = {
                        type: "changeCompoundType",
                        payload: {
                          itemId: compoundItem?.localId,
                          data: e.target.value,
                        },
                      };
                      updateCompound(dto);
                    }}
                  >
                    {!(compoundItem?.orList?.length > 0) && (
                      <FormControlLabel
                        className="formRadioField "
                        value="or"
                        control={<Radio />}
                        label={t("or")}
                      />
                    )}
                    {!(compoundItem?.thenList?.length > 0) && (
                      <FormControlLabel
                        className="formRadioField"
                        value="then"
                        control={<Radio />}
                        label={t("then")}
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </div>
              <IconButton
                className="rmvCompound"
                onClick={() => {
                  updateCompound({
                    type: "deleteCompound",
                    payload: {
                      parentId: parentId,
                      itemId: compoundItem.localId,
                    },
                  });
                }}
              >
                <Trash />
              </IconButton>
            </div>
          </div>
          <div className="medRecStpGrp">
            {compoundItem.form && (
              <div className="medRecStpInd">
                <span>{t("form")}:</span>
                {compoundItem.form.formName}
              </div>
            )}
            {compoundItem.route && (
              <div className="medRecStpInd">
                <span>{t("rout")}:</span>
                {compoundItem.route.route}
              </div>
            )}
            {compoundItem.dose && (
              <div className="medRecStpInd">
                <span>{t("dose")}:</span>
                {compoundItem.dose}
              </div>
            )}
            {compoundItem.doseUnit && (
              <div className="medRecStpInd">
                <span>{t("dose_unit")}:</span>
                {compoundItem.doseUnit}
              </div>
            )}
            {compoundItem.doseLimit && (
              <div className="medRecStpInd">
                <span>{t("dose_limit")}:</span>
                {compoundItem.doseLimit}
              </div>
            )}
            {compoundItem.duration && (
              <div className="medRecStpInd">
                <span>{t("duration")}:</span>
                {compoundItem.duration.durationName}
              </div>
            )}
            {compoundItem.frequency && (
              <div className="medRecStpInd">
                <span>{t("frequency")}:</span>
                {compoundItem.frequency.frequency}
              </div>
            )}
          </div>
        </div>
        {compoundItem?.compoundOrThen === "then" && (
          <>
            <div className={`compoundIndv compThen`}>
              {<div className="compChild">{t("then")}</div>}
              <div className="compfulDtl">
                <AddCompound
                  compoundList={compoundList}
                  handleAddCompound={(data) => {
                    if (compoundItem?.compoundOrThen === "or") {
                      const dto = {
                        type: "addOr",
                        payload: {
                          itemId: compoundItem.localId,
                          prevIndex: compoundItem.index,
                          data,
                          parentId,
                        },
                      };
                      updateCompound(dto);
                    } else if (compoundItem?.compoundOrThen === "then") {
                      const dto = {
                        type: "addThen",
                        payload: {
                          itemId: compoundItem.localId,
                          prevIndex: compoundItem.index,
                          data,
                        },
                      };
                      updateCompound(dto);
                    }
                  }}
                />
              </div>
            </div>
          </>
        )}
        {compoundItem?.thenList?.map((item) => {
          return (
            <CompoundItem
              compoundList={compoundList}
              compoundItem={item}
              parentId={compoundItem?.localId}
              updateCompound={updateCompound}
              compoundType={"then"}
            />
          );
        })}

        {compoundItem?.orList?.map((item) => {
          return (
            <CompoundItem
              compoundList={compoundList}
              compoundItem={item}
              parentId={compoundItem?.localId}
              updateCompound={updateCompound}
              compoundType={"or"}
            />
          );
        })}
        {compoundItem?.compoundOrThen === "or" && (
          <AddCompound
            compoundList={compoundList}
            handleAddCompound={(data) => {
              if (compoundItem?.compoundOrThen === "or") {
                const dto = {
                  type: "addOr",
                  payload: {
                    itemId: compoundItem.localId,
                    prevIndex: compoundItem.index,
                    data,
                    parentId,
                  },
                };
                updateCompound(dto);
              } else if (compoundItem?.compoundOrThen === "then") {
                const dto = {
                  type: "addThen",
                  payload: {
                    itemId: compoundItem.localId,
                    prevIndex: compoundItem.index,
                    data,
                  },
                };
                updateCompound(dto);
              }
            }}
          />
        )}
      </div>
    </>
  );
};
