import React, { useState } from "react";
import ZoyelRTERnd from "./zoyelRTERnd";

export const RteContainer = () => {
  const [rteHtml, setRteHtml] = useState(
    "<p>This is some normal text.</p><p><small>This is some smaller text.</small></p>"
  );
  const [rteRawJson, setRteRawJson] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [rteText, setRteText] = useState("");
  const [showMom, setShowMom] = useState(false);
  return (
    <ZoyelRTERnd
      setRteHtml={setRteHtml}
      setRteText={setRteText}
      setImageList={setImageList}
      imageList={imageList}
      placeholder={"write_about_your_product_here"}
      rteRawJson={rteRawJson}
      rteText={rteText}
      rteHtml={rteHtml}
      setRteRawJson={setRteRawJson}
    />
  );
};
