import React from "react";
import AddPeople from "./component/AddPeople";

export default function RightFlotingAnalyticsDashboard({
  children,
  isExpanded,
}) {
  const fullScreenStyle = {
    width: "100%",
  };
  return (
    <>
      <div
        className="RightFlotingContainerArea"
        style={isExpanded ? fullScreenStyle : {}}
      >
        {children}
      </div>
    </>
  );
}
