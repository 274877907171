import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import DashboardServices from "../../../../../../services/DashboardServices";
import { getLoginUserDetails } from "../../../../../../utils/Utils";
import { toast } from "react-toastify";
import { doctorSocket } from "../../../../../../socket";
import { useTranslation } from "react-i18next";

export const CancelConsultation = ({ closeModal, closeMeeting, extraData }) => {
  const loginUserDetail = getLoginUserDetails();
  const { t } = useTranslation();
  const cancelReasonList = [
    "Poor Video Quality",
    "Poor audio quality",
    "Poor video & audio quality",
    "Couldn't see patient video",
    "Couldn't hear patient clearly",
  ];
  const [selectedReason, setSelectedReason] = useState(null);
  const [cancellationRemark, setCencallationRemark] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
      <div className="addMoreAgendaModel">
        <div className="modelTitle">{t("cancel_consultation")}</div>

        <div className="elementFormContainer">
          <div className="formElement">
            <FormControl className="formControl">
              {/* <InputLabel id="adduser">Add User</InputLabel> */}
              {/* <Autocomplete
                id="adduser-select"
                className="formAutocompleteField"
                variant="outlined"
                noOptionsText={"Please Type Recipient Name"}
                value={selectedReason}
                options={cancelReasonList}
                getOptionLabel={(option) => option}
                onChange={(e, newValue) => {
                  console.log("new value = ", newValue);
                  setSelectedReason(newValue);
                }}
                getOptionSelected={(option) => {
                  console.log("option is ", option);
                  return option.userId;
                }}
                renderOption={(props, option) => {
                  return (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {`${option} `}
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="modelformAutocompleteField"
                    placeholder="Select Cancellation Reason*"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              /> */}
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                className="modelTextFild"
                required
                label={t("remark")}
                variant="outlined"
                multiline
                rows={4}
                maxRows={7}
                value={cancellationRemark}
                onChange={(e) => {
                  setCencallationRemark(e.target.value);
                }}
              />
            </FormControl>
          </div>
        </div>

        {/* <div className="modAtchFileBtn">
          <label class="btn-up">
            <input type="file" hidden />
            Attach File
          </label>
        </div> */}
        <div className="modActionBtnGrp">
          <Button
            className="dfultPrimaryBtn"
            onClick={() => {
              if (!cancellationRemark || !cancellationRemark.trim()) {
                toast.error(t("cancellation_reason_cannot_be_empty"));
                return;
              }

              doctorSocket.emit("cancelPatientFromVideo", {
                pvId: extraData.queueVisitId,
              });

              DashboardServices.cancelConsultation(
                extraData.queueVisitId,
                loginUserDetail.userId,
                cancellationRemark
              ).then((response) => {
                if (response.data == "1") {
                  toast.success(t("consultation_cancelled_successfully"));
                  closeMeeting();
                  doctorSocket.emit("cancelPatientFromVideo", {
                    pvId: extraData.queueVisitId,
                  });
                } else {
                  toast.error(t("consultation_cannot_be_cancelled"));
                }
              });
            }}
          >
           {t("submit")}
          </Button>
          <Button
            className="dfultDarkSecondaryBtn"
            onClick={() => {
              closeModal();
            }}
          >
            {t("close")}
          </Button>
        </div>
      </div>
      {loading && (
        <div className="elementFormContainer">
          <CircularProgress />
        </div>
      )}
    </Box>
  );
};
