import React, { useReducer, useState } from "react";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { CloseFullscreen, OpenInFull, Visibility } from "@mui/icons-material";
import { Download, XLg } from "react-bootstrap-icons";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import TaskService from "../../../../../services/TaskService";
import { VendorPaymentMethods } from "./VendorPaymentMethods";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import DateUtils from "../../../../../utils/DateUtils";
import { useMemo } from "react";
import { NumericFormatCustom } from "./NumericFormatCustom";
import { downloadFileFromUrl } from "../../../../../utils/FileUtils";
import { useTranslation } from "react-i18next";

export const PaymentAgainstReimbursementForm = ({
  taskId,
  formDataTrans,
  taskNature,
  formData,
  handleClose,
}) => {
  const fullScreenStyle = {
    width: "100%",
  };
  const { t } = useTranslation();
  const loginUserDetail = getLoginUserDetails();
  const { register, reset, handleSubmit } = useForm();
  const [description, setDescription] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  const [selectedMethodId, setSelectedMethodId] = useState(0);

  const [reimburseMentItemList, setReimbursementItemList] = useState([]);
  const [showPaymentMehtod, setShowPaymentMehtod] = useState(false);
  const [ledgerHeadList, setLedgerHeadList] = useState([]);

  const initialState = { isExpanded: false };
  const reducer = (state, action) => {
    switch (action.type) {
      case "expandOrShrinkWindow":
        return { ...state, isExpanded: !state.isExpanded };
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const submitRequest = (data) => {
    let isValid = true;
    if (description.trim() === "") {
      toast.error("Please Provide Remark");
      isValid = false;
    }

    if (referenceNo.trim() === "") {
      toast.error("Please provide reference no");
      isValid = false;
    }

    if (selectedMethodId === 0) {
      toast.error("Please select payment method");
      isValid = false;
    }

    if (isValid) {
      const reqDto = {
        expenseId: formData.expenseId,
        expenseType: "reimbursement",
        paymentMethodId: selectedMethodId,
        paymentAmount: parseInt(formData.estimatedCost),
        notes: description,
        accountHeadId: 0,
        costCenterId: formData.costCenter,
        taskDetailId: taskId,
        orgId: loginUserDetail.orgId,
        referenceNo: referenceNo,
      };

      console.log("******** reqDto =", reqDto);
      // return;

      TaskService.releasePayment(
        loginUserDetail.orgId,
        loginUserDetail.userId,
        reqDto
      ).then((response) => {
        // console.log("response =",respo)
        if (response.data.returnValue === "1") {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      });
    }
  };

  const fetchLedgerHeadData = () => {
    TaskService.getBudgetHeads(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        console.log("fetchLedgerHeadData", response.data);
        setLedgerHeadList(response.data);
      }
    });
  };

  const filteredBudgetHeadList = useMemo(() => {
    // console.log("ceoe value from filtered = ", ceoe);

    if (formData.capexOpex === "CapitalExpenditure") {
      return ledgerHeadList.filter(
        (item) => item.category === "Capital Expense"
      );
    } else {
      return ledgerHeadList.filter(
        (item) => item.category === "Operating Expense"
      );
    }
  }, [ledgerHeadList, formData.capexOpex]);

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  useEffect(() => {
    fetchLedgerHeadData();
  }, []);

  return (
    <>
      <div
        className="RightFlotingFormContainerArea"
        style={state.isExpanded ? fullScreenStyle : {}}
      >
        <div className="flottingContainer">
          <div className="flotHeaderPanel">
            <div class="flotHedCon">{t("paymentAgainstReimbursement")}</div>
            <div class="flotActionBtnGrp">
              <div class="flotActBtn closeBtnD10">
                <Tooltip
                  arrow
                  title={
                    state.isExpanded ? t("exitFullScreen") : t("enterFullScreen")
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      dispatch({ type: "expandOrShrinkWindow" });
                    }}
                  >
                    {state.isExpanded ? <CloseFullscreen /> : <OpenInFull />}
                    {/* <Expand /> */}
                  </IconButton>
                </Tooltip>
              </div>
              <div class="flotActBtn closeBtnD10">
                <IconButton onClick={handleClose}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          {formData && (
            <div className="flotContain">
              <div className="elementFormContainer previewSpace ">
                <div className="indentFormtable">
                  <div className="indtableResponsive">
                    <table>
                      <thead>
                        <tr>
                          <th className="w200">{t("indentNo")}</th>
                          <th>{t("purpose")}</th>
                          <th>{t("scheduleDate")}</th>
                          <th className="indntQunt">{t("createdBy")}</th>
                          <th className="widthMin120">{t("approvedBy")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <td>
                          <div className="textOnly">{formData.expenseNo}</div>
                        </td>
                        <td>
                          <div className="textOnly">{formData.purpose}</div>
                        </td>
                        <td>
                          <div className="textOnly">
                            {formData.scheduledDate}
                          </div>
                        </td>
                        <td>
                          <div className="textOnly">
                            {formData.createdByName}
                          </div>
                        </td>
                        <td>
                          <div className="textOnly">
                            {formData.approvedByName}
                          </div>
                        </td>
                      </tbody>
                      <tfoot></tfoot>
                    </table>
                  </div>

                  {formData.expenseType !== "DA/TA" && (
                    <div className="indtableResponsive">
                      <table>
                        <thead>
                          {/* <tr>
                            <th>Description</th>
                            <th>Reason</th>
                            <th>Budget Head</th>
                            <th>Invoice/ Receipt No</th>
                            <th>Invoice Date </th>
                            <th>TaxAmount</th>
                            <th>Total Amount</th>
                            <th>Documents</th>
                          </tr> */}
                           <tr>
                    <th>{t("description")}</th>
                    <th>{t("reason")}</th>
                    <th>{t("budgetHead")}</th>
                    <th>{t("invoiceReceiptNo")}</th>
                    <th>{t("invoiceDate")}</th>
                    <th>{t("taxAmount")}</th>
                    <th>{t("totalAmount")}</th>
                    <th>{t("documents")}</th>
                  </tr>
                        </thead>
                        <tbody>
                          {formData &&
                            formData.serviceGoodsReim &&
                            formData.serviceGoodsReim.map((item) => {
                              return (
                                <>
                                  <tr>
                                    <td>{item.description}</td>
                                    <td>{item.reason}</td>
                                    <td>{item.budgetHeadName}</td>
                                    <td>{item.invoceRcptNo}</td>
                                    <td>{item.invoceRcptDt}</td>
                                    <td>{item.taxAmount}</td>
                                    <td style={{ textAlign: "right" }}>
                                      {item.totalAmount}
                                    </td>
                                    <td>
                                      {item.fileName}
                                      {item.fileName &&
                                        item.fileName !== "" && (
                                          <IconButton
                                            onClick={() => {
                                              downloadFileFromUrl(
                                                item.fileDataLink,
                                                item.fileName
                                              );
                                            }}
                                          >
                                            <Download />
                                          </IconButton>
                                        )}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={6}>{t("total")}</td>
                            <td style={{ textAlign: "right" }}>
                              {formData.estimatedCost}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  )}
                  {formData.expenseType === "DA/TA" && (
                    <>
                      <div className="formElementGroup flex-wrap">
                        <div className="formElement width13">
                          <FormControl className="formControl">
                            <InputLabel
                              id="setCompletionDate"
                              className="setCompletionDate"
                            >
                              {t("travelStartDate")}
                            </InputLabel>
                            <ReactDatePicker
                              showIcon
                              disabled
                              labelId="setCompletionDate"
                              className="formDatePicker"
                              maxDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                              selected={DateUtils.convertToDate(
                                formData.daTaTravelDateStart
                              )}
                              // onChange={(date) => setStartDate(date)}
                            />
                          </FormControl>
                        </div>
                        <div className="formElement width13">
                          <FormControl className="formControl">
                            <InputLabel
                              id="setCompletionDate"
                              className="setCompletionDate"
                            >
                               {t("travelEndDate")}
                            </InputLabel>
                            <ReactDatePicker
                              showIcon
                              disabled
                              labelId="setCompletionDate"
                              className="formDatePicker"
                              maxDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                              selected={DateUtils.convertToDate(
                                formData.daTaTravelDateEnd
                              )}
                              // onChange={(date) => setEndDate(date)}
                            />
                          </FormControl>
                        </div>
                        <div className="formElement width13">
                          <FormControl className="formControl">
                            <InputLabel id="selectLedgerHead">
                            {t("budgetHead")}
                            </InputLabel>
                            <Select
                              disabled
                              className="formInputField"
                              variant="outlined"
                              labelId="selectLedgerHead"
                              id="select-LedgerHead"
                              label={t("budgetHead")}
                              value={formData.budgetHeadId}
                            >
                              {filteredBudgetHeadList.length > 0 &&
                                filteredBudgetHeadList.map(
                                  (itemName, index) => (
                                    <MenuItem
                                      value={itemName.id}
                                      key={itemName.head + "_" + index}
                                    >
                                      {itemName.head}
                                    </MenuItem>
                                  )
                                )}
                            </Select>
                          </FormControl>
                        </div>
                      </div>

                      <div className="formElementGroup flex-wrap">
                        <div className="formElement width13">
                          <FormControl className="formControl">
                            <TextField
                              disabled
                              label={t("transportCost")}
                              name="numberformat"
                              id="Transport_Cost"
                              InputProps={{
                                inputComponent: NumericFormatCustom,
                              }}
                              variant="outlined"
                              defaultValue={0}
                              className="formTextFieldArea"
                              value={formData.daTaTransportCosts}
                            />
                          </FormControl>
                        </div>
                        <div className="formElement width13">
                          <FormControl className="formControl">
                            <TextField
                              disabled
                              label={t("accommodationCost")}
                              name="numberformat"
                              id="Accomodation_Cost"
                              InputProps={{
                                inputComponent: NumericFormatCustom,
                              }}
                              defaultValue={0}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={formData.daTaAccommodationCosts}
                            />
                          </FormControl>
                        </div>

                        <div className="formElement width13">
                          <FormControl className="formControl">
                            <TextField
                              disabled
                              label={t("mealCost")}
                              name="numberformat"
                              id="Meal_Cost"
                              InputProps={{
                                inputComponent: NumericFormatCustom,
                              }}
                              defaultValue={0}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={formData.daTaMealCosts}
                            />
                          </FormControl>
                        </div>

                        <div className="formElement width13">
                          <FormControl className="formControl">
                            <TextField
                              disabled
                              label={t("otherExpenses")}
                              name="numberformat"
                              id="Other_Expenses"
                              InputProps={{
                                inputComponent: NumericFormatCustom,
                              }}
                              defaultValue={0}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={formData.daTaOtherExpensesAmt}
                            />
                          </FormControl>
                        </div>

                        <div className="formElement width13">
                          <FormControl className="formControl">
                            <TextField
                              disabled
                              label={t("totalExpenses")}
                              name="numberformat"
                              id="total_Expenses"
                              InputProps={{
                                inputComponent: NumericFormatCustom,
                              }}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={formData.daTaTotalCost}
                            />
                          </FormControl>
                        </div>
                        <div className="formElement width13">
                          <FormControl className="formControl">
                            <TextField
                              disabled
                              label={t("advanceReceived")}
                              name="numberformat"
                              id="total_Expenses"
                              InputProps={{
                                inputComponent: NumericFormatCustom,
                              }}
                              defaultValue={0}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={formData.daTaAdvanceReceived}
                            />
                          </FormControl>
                        </div>
                      </div>

                      <div className="formElementGroup">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <FormGroup className="formRadioGroup">
                              <FormControlLabel
                                className="formRadioField"
                                control={
                                  <Checkbox
                                    disabled
                                    checked={
                                      formData.claimOnActalRate === "Y"
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                label={t("claimedOnActualBasis")}
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <FormGroup className="formRadioGroup">
                              <FormControlLabel
                                className="formRadioField"
                                control={
                                  <Checkbox
                                    disabled
                                    checked={
                                      formData.claimOnActalBasis === "Y"
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                label={t("claimedOnActualRate")}
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="formElement ">
                    <FormControl className="formControl">
                      <TextField
                        className="descptionTextFild"
                        fullWidth
                        required
                        id="outlined-basic"
                        label={t("remark")}
                        variant="outlined"
                        multiline
                        rows={4}
                        minRows={4}
                        value={description}
                        onChange={handleDescriptionChange}
                      />
                    </FormControl>
                  </div>

                  <div className="formElementGroup">
                    <div className="formElement width13">
                      <FormControl className="formControl">
                        <TextField
                          fullWidth
                          required
                          className="descptionTextFild"
                          id="outlined-basic"
                          label={t("referenceNumber")}
                          variant="outlined"
                          value={referenceNo}
                          size="small"
                          onChange={(e) => {
                            setReferenceNo(e.target.value);
                          }}
                        />
                      </FormControl>
                    </div>

                    <div className="formBtnElement">
                      <Button
                        className={`comBtnOutline  ${
                          selectedMethodId !== 0 ? "activeStatus" : ""
                        }`}
                        onClick={() => {
                          setShowPaymentMehtod(true);
                        }}
                      >
                         {t("paymentMethod")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="flotFooterPanel">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={handleSubmit(submitRequest)}
              >
                {t("approveRequest")}
              </Button>
              <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
              {t("cancel")}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {showPaymentMehtod && (
        <VendorPaymentMethods
          closeModal={() => {
            setShowPaymentMehtod(false);
          }}
          selectedMethodId={selectedMethodId}
          setSelectedMethodId={setSelectedMethodId}
          vendorUserId={formData.createdById}
        />
      )}
    </>
  );
};
