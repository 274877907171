import { Box, Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import TaskService from "../../../../../services/TaskService";
import dayjs from "dayjs";
import { useContext } from "react";
import { taskTabContext } from "../../TaskTabContainer";
import { useTranslation } from "react-i18next";

function MeetingModal({ handleClose, assigneeList, taskId }) {
  const { t } = useTranslation();
  const [meetingList, seetMeetingList] = useState([]);
  const { setShowConvenientMeetingView, setSelectedUsers } =
    useContext(taskTabContext);

  useEffect(() => {
    // console.log("creatorUserIdForSupport", creatorUserIdForSupport);
    TaskService.getEventsofTask(taskId).then((response) => {
      console.log("event task =", response.data);
      seetMeetingList(response.data);
    });
  }, []);

  const openWhiteBoard = (eventData) => {
    console.log();
  };

  return (
    <>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">{t("meeting_list")}</div>

          <div className="elementFormContainer">
            <div>
              {meetingList &&
                meetingList.length > 0 &&
                meetingList.map((meeting) => {
                  return (
                    <>
                      <div>
                        {/* <span>
                          {`Meeting at ${dayjs(meeting.start).format(
                            "DD-MM-YYYY hh:mm A"
                          )}`}
                        </span> */}
                         <span>
                         {t('meetingAt', { date: dayjs(meeting.start).format("DD-MM-YYYY hh:mm A") })}
                         </span>
                      </div>
                    </>
                  );
                })}
            </div>
            <div className="formElement">
              <Button
                variant="outlined"
                onClick={(e) => {
                  setShowConvenientMeetingView(true);
                  setSelectedUsers(assigneeList);
                }}
              >
                {t("create_new_meeting")}
              </Button>
            </div>
          </div>

          <div className="modActionBtnGrp">
            {/* <Button className="dfultPrimaryBtn">Submit</Button> */}
            <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
            {t("cancel")}
            </Button>
          </div>
        </div>
      </Box>
    </>
  );
}

export default MeetingModal;
