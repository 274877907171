import React from "react";
import ChatConversation from "../../../../chat/view/ChatConversation";
import { useEffect } from "react";
import { useState } from "react";
import { chatSocket } from "../../../../../socket";

let unreadMessageCount = 0;
export default function RightSideChatContainer({
  selectedData,
  setSelectedData,
  closeFromDashboard,
}) {
  const [newMessageObj, setNewMessageObj] = useState();
  useEffect(() => {
    const handleReceiveChat = (msg) => {
      console.log("chatsocket value =", msg);
      if (selectedData.chatId === msg.chatId) {
        unreadMessageCount = unreadMessageCount + 1;
      }
      if (selectedData.lastMessageId > 0) {
        setNewMessageObj(msg);
        // fetchMessageData();
      }
    };

    chatSocket.on("receiveChat", handleReceiveChat);

    return () => {
      console.log("cleaning up receiveChat mesage");
      chatSocket.off("receiveChat", handleReceiveChat);
    };
  }, [chatSocket, selectedData.lastMessageId]); // Include any other dependencies as needed

  useEffect(() => {
    console.log("selected chat data", selectedData);
    setSelectedData({
      ...selectedData,
      chatUserId: selectedData.chatMessageSentByUserId,
    });
    unreadMessageCount = selectedData.unreadChatCount;
  }, []);

  return (
    <div className="dashboardChatContainer">
      <ChatConversation
        selectedContact={selectedData}
        setSelectedContact={setSelectedData}
        refreshContacts={() => {}}
        onlineUserList={[]}
        newMessageObj={newMessageObj}
        setNewMessageObj={setNewMessageObj}
        deleteMessageObj={null}
        setDeleteMessageObj={() => {}}
        closeFromDashboard={closeFromDashboard}
        unreadMessageCount={unreadMessageCount}
      />
    </div>
  );
}
