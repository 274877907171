import { Box, Button, FormControl, TextField } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import DashboardServices from "../../../../services/DashboardServices";
import { getLoginUserDetails } from "../../../../utils/Utils";
import { useDispatch } from "react-redux";
import { clearSelectedTask } from "../../../../redux/reducers/rightSideTaskPanelSlice";

const ReopenModal = ({ taskId, handleClose, refreshData }) => {
  const [reopenTitle, setReopenTitle] = useState();
  const loginUserDetail = getLoginUserDetails();
  const dispatch = useDispatch();
  return (
    <>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">Add Reopen Remark Here</div>

          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  className="modelTextFild"
                  label="Provide Reopen Remark"
                  variant="outlined"
                  multiline
                  rows={4}
                  maxRows={7}
                  value={reopenTitle}
                  onChange={(e) => {
                    setReopenTitle(e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </div>

          <div className="modActionBtnGrp">
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                if (reopenTitle === "") {
                  toast.error("Please Provide Reopen Remark");
                  return;
                }

                DashboardServices.openPostponedTask(
                  taskId,
                  loginUserDetail.userId,
                  reopenTitle
                ).then((response) => {
                  if (response.data && response.data.returnValue === "1") {
                    toast.success(response.data.message);
                    setReopenTitle("");
                    dispatch(clearSelectedTask());
                    refreshData();
                  } else {
                    toast.error(response.data.message);
                  }
                });
              }}
            >
              Submit
            </Button>
            <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      </Box>
    </>
  );
};

export default ReopenModal;
