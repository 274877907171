import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

export const FloatTextField = ({ value, onChange, max, min, ...props }) => {
  const [localValue, setLocalValue] = useState(value || "");

  const floatingRegEx = /^[+-]?(\d+(\.\d*)?|\.\d+)?$/;

  useEffect(() => {
    if (checkValue(value)) {
      setLocalValue(value);
    }
  }, [value]);

  const checkValue = (value) => {
    return floatingRegEx.test(value);
  };

  const handleChange = (event) => {
    let newValue = event.target.value;

    if (checkValue(newValue)) {
      const floatValue = parseFloat(newValue);
      if (max != null && floatValue > max) {
        return;
      }
      if (min != null && floatValue < min) {
        return;
      }

      if (newValue.includes(".")) {
        // If the value contains a decimal point, don't parse it to float
        setLocalValue(newValue);
        onChange(newValue);
      } else {
        // Remove leading zeros for integer parts
        newValue = newValue.replace(/^0+(\d)/, "$1");
        setLocalValue(newValue);
        onChange(newValue);
      }
    }
  };

  return (
    <TextField
      value={localValue}
      type="text"
      {...props}
      onChange={handleChange}
    />
  );
};
