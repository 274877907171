import React from "react";

export default function UAC() {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4.88889 5.81778V16.1944H9.87556L10.6089 15.0822C12.0267 12.8944 14.5811 11.9167 16.4633 11.9167C17.1111 11.9167 17.6611 12.0267 18.0767 12.2222C19.1889 12.7233 19.4944 13.6644 19.5556 14.3489C19.6533 15.73 18.9444 17.1478 17.7222 17.9789C16.6222 18.7367 13.9822 19.5556 10.8411 19.5556C8.70222 19.5556 5.63444 19.1767 2.59111 17.4778C2.94556 14.4833 3.66667 9.63111 4.88889 5.81778ZM4.88889 0C1.22222 4.99889 0 18.7489 0 18.7489C3.54444 21.1567 7.43111 22 10.8411 22C14.4956 22 17.5878 21.0344 19.1156 19.9956C22.7822 17.5022 23.1489 11.8678 19.1156 9.99778C18.3333 9.64333 17.4289 9.47222 16.4633 9.47222C13.6522 9.47222 10.3889 10.9511 8.55556 13.75H7.33333V4.99889H9.77778L11 1.22222L4.88889 0Z" />
      </svg>
    </>
  );
}
