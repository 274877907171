import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

class FeedbackService {
  betaFeedback(userId, reqDto) {
    return http.post(`/feedback/betaFeedback/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }
}

export default new FeedbackService();
