import {
  Add,
  DeleteOutlineOutlined,
  EditOutlined,
  SaveOutlined,
} from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export default function ListAgeGroupModel({ handleCloseListAgeGroupModel }) {
  const { t } = useTranslation();
  return (
    <>
      <Box
        className="ModelBox ListAgeGroupModel"
        sx={{ boxShadow: 24, p: 4, zIndex: 10 }}
      >
        <div className="addMoreAgendaModel">
          <div className="modelTitle">
            {t("list_of_age_group")}
            <Tooltip title={t("add_new_age_group")}>
              <IconButton className="listAgegroup">
                <Add />
              </IconButton>
            </Tooltip>
          </div>

          <div className="elementFormContainer">
            <ul className="listOfAgeGrp">
              <li className="ageListInd">
                <div className="ageListInfo">
                  <span>{t("new_born")}</span>: <span>{t("up_to_3_months_old")}</span>
                </div>
                <div className="updateListBtn">
                  <IconButton>
                    <EditOutlined />
                  </IconButton>
                  <IconButton>
                    <SaveOutlined />
                  </IconButton>
                  <IconButton>
                    <DeleteOutlineOutlined />
                  </IconButton>
                </div>
              </li>
              <li className="ageListInd">
                <div className="ageListInfo">
                  <span>{t("infant")}</span>: <span>{t("three_12_months_old")}</span>
                </div>
                <div className="updateListBtn">
                  <IconButton>
                    <EditOutlined />
                  </IconButton>
                  <IconButton>
                    <SaveOutlined />
                  </IconButton>
                  <IconButton>
                    <DeleteOutlineOutlined />
                  </IconButton>
                </div>
              </li>
            </ul>
          </div>

          <div className="modActionBtnGrp">
            <Button className="dfultPrimaryBtn">{t("submit")}</Button>
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={handleCloseListAgeGroupModel}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Box>
    </>
  );
}
