import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ConfirmDialog({
  open,
  title,
  message,
  onCancel,
  onOk,
  children,
}) {
  const { t } = useTranslation();
  return (
    <>
      <Dialog open={open}>
        <DialogTitle className="confirmDlgTitle">
          <p>{title}</p>
        </DialogTitle>

        <DialogContent className="confirmDlgContent">
          <p>{message}</p>
        </DialogContent>

        <DialogContent className="confirmDlgChildren">
          <p>{children}</p>
        </DialogContent>

        <DialogActions>
          <Button onClick={onCancel} className="dfultDarkSecondaryBtn">
            {t("cancel")}
          </Button>
          <Button onClick={onOk} className="dfultPrimaryBtn">
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
