import { StarRateTwoTone } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedDataId: null,
};

const rightSideTaskPanelSlide = createSlice({
  name: "taskPanel",
  initialState,
  reducers: {
    setSelectedTask: (state, action) => {
      console.log("data = ", action.payload);
      state.selectedDataId = action.payload;
    },
    clearSelectedTask: (state) => {
      state.selectedDataId = null;
    },
  },
});

export default rightSideTaskPanelSlide;
export const { setSelectedTask, clearSelectedTask } =
  rightSideTaskPanelSlide.actions;
