import React, { useEffect, useState } from "react";
import {
  jitsiDomain,
  jitsiHostName,
} from "../../../../../../constants/url_provider";
import { generateJWTToken } from "../../../../../../redux/reducers/VideoConferenceSlice";
import { useDispatch } from "react-redux";
import { getLoginUserDetails } from "../../../../../../utils/Utils";
import { toast } from "react-toastify";
import DashboardServices from "../../../../../../services/DashboardServices";
import { meetingSocket } from "../../../../../../socket";
import {
  addParticipantToMeetingRoom,
  removeParticipantFromMeetingRoom,
} from "../../../../../../services/VideoConferenceService";
import { CircularProgress } from "@mui/material";
import WebinarService from "../../../../../../services/WebinarService";
import DateUtils from "../../../../../../utils/DateUtils";
import { generateJWTTokenForVideo } from "../../../../../../services/MeetingService";
import { useTranslation } from "react-i18next";

const JitsiVideoConference = ({
  roomNumber,
  setJitsi,
  leaveMeetingRoom,
  selEvent,
  videoConferenceJoinedJitsi,
}) => {
  const loginUserDetail = getLoginUserDetails();
  const jitsiContainerId = roomNumber;
  const [jitsi, setJitsiInstance] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const loadJitsiScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = `${jitsiDomain}/external_api.js`;
      script.async = true;

      script.onload = resolve;
      script.onerror = reject;

      document.body.appendChild(script);
    });
  };

  const showRejectJoinReqByAdminError = (_jitsi) => {
    _jitsi.addListener("errorOccurred", (obj) => {
      console.log("errorOccurred", obj);
      if (
        _jitsi &&
        obj &&
        obj.error &&
        obj.error.name === "conference.connectionError.accessDenied"
      ) {
        _jitsi.executeCommand("hangup");
        toast.error(t("your_join_meeting_request_was_rejected_by_the_moderator"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        leaveMeetingRoom();
      }
    });
  };

  const clearRooms = () => {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith("M~")) {
        const value = localStorage.getItem(key);
        if (!DateUtils.isToday(new Date(value))) {
          localStorage.removeItem(key);
          // Adjust the index as an item has been removed
          i--;
        }
      }
    }
  };

  const videoConferenceJoinedListener = (_jitsi) => {
    _jitsi.addListener("videoConferenceJoined", (obj) => {
      console.log("videoConferenceJoined", obj);
      localStorage.setItem("M~" + roomNumber, new Date());
      clearRooms();
      setJitsiInstance(_jitsi);
      setJitsi(_jitsi);
      videoConferenceJoinedJitsi(_jitsi);
      meetingSocket.emit("joinMeetingRoom", { roomId: roomNumber });

      try {
        let reqDto = {
          meetingId: roomNumber,
          participantId: loginUserDetail.userId,
          participantName: loginUserDetail.userName,
          jitsiParticipantId: obj.id,
        };
        addParticipantToMeetingRoom(reqDto).then((response) => {
          console.log("addParticipantToMeetingRoom", response.data);
        });
      } catch (err) {
        console.log(err);
      }

      try {
        if (selEvent?.eventType === "Consultation") {
          return;
        }
        _jitsi.getRoomsInfo().then(async (roomInfo) => {
          console.log(
            "videoConferenceJoined",
            roomInfo.rooms[0].participants.length
          );
          if (roomInfo.rooms[0].participants.length === 1) {
            console.log(selEvent);
            if (selEvent?.eventEndTime) {
              const end = new Date(selEvent.eventEndTime);
              const curr = new Date();
              console.log("getEventDetailsByMeetingId", curr - end);
              if (curr - end > 0) {
                console.log(
                  "getEventDetailsByMeetingId Meeting ended",
                  curr - end
                );
                // setMeetingEndedDtls(response.data);
                setTimeout(() => {
                  _jitsi.executeCommand("hangup");
                  leaveMeetingRoom();
                  toast.error(
                    t("meeting_has_ended_and_no_one_is_in_the_meeting"),
                    {
                      position: toast.POSITION.TOP_RIGHT,
                    }
                  );
                }, 2000);
              }
            }
          }
        });
      } catch (err) {
        console.log("videoConferenceJoined err", err);
      }
    });
  };

  const updateProfileImage = (_jitsi) => {
    DashboardServices.getUserProfileImageLink(
      loginUserDetail.userId,
      loginUserDetail.orgId
    ).then((response) => {
      _jitsi.executeCommand(
        "avatarUrl",
        selEvent.patientImageUrl ? selEvent.patientImageUrl : response.data
      );
    });
  };

  const initializeJitsi = async () => {
    if (!window.JitsiMeetExternalAPI) {
      await loadJitsiScript();
    }

    if (window.JitsiMeetExternalAPI) {
      generateJWTTokenForVideo(roomNumber, selEvent).then((response2) => {
        console.log("generateJWTTokenForVideo-->>>", response2);
        const options = {
          roomName: roomNumber,
          width: "100%",
          height: "100%",
          parentNode: document.getElementById(jitsiContainerId),
          jwt: response2,
          userInfo: {
            displayName: selEvent.patientName
              ? selEvent.patientName
              : loginUserDetail.userName,
            email: loginUserDetail.userEmailId,
          },
          configOverwrite: {
            startWithAudioMuted: true,
            startWithVideoMuted: true,
          },
        };
        const _jitsi = new window.JitsiMeetExternalAPI(
          `${jitsiHostName}`,
          options
        );

        try {
          showRejectJoinReqByAdminError(_jitsi);
          videoConferenceJoinedListener(_jitsi);
        } catch (err) {
          console.log(err);
        }

        try {
          updateProfileImage(_jitsi);
        } catch (err) {
          console.log(err);
        }

        setLoading(false);
      });
      // dispatch(generateJWTToken(roomNumber))
      //   .unwrap()
      //   .then((jwtToken) => {
      //     const options = {
      //       roomName: roomNumber,
      //       width: "100%",
      //       height: "100%",
      //       parentNode: document.getElementById(jitsiContainerId),
      //       jwt: jwtToken,
      //       userInfo: {
      //         displayName: selEvent.patientName
      //           ? selEvent.patientName
      //           : loginUserDetail.userName,
      //         email: loginUserDetail.userEmailId,
      //       },
      //       configOverwrite: {
      //         startWithAudioMuted: true,
      //         startWithVideoMuted: true,
      //       },
      //     };
      //     const _jitsi = new window.JitsiMeetExternalAPI(
      //       `${jitsiHostName}`,
      //       options
      //     );

      //     try {
      //       showRejectJoinReqByAdminError(_jitsi);
      //       videoConferenceJoinedListener(_jitsi);
      //     } catch (err) {
      //       console.log(err);
      //     }

      //     try {
      //       updateProfileImage(_jitsi);
      //     } catch (err) {
      //       console.log(err);
      //     }

      //     setLoading(false);
      //   });
    } else {
      console.error("JitsiMeetExternalAPI not available");
    }
  };
  useEffect(() => {
    setLoading(true);

    if (selEvent?.eventType === "Webinar") {
      WebinarService.getWebinarChat(roomNumber)
        .then((response) => {
          if (response && response.data && response.data.length > 0) {
            if (
              response.data.some(
                (webChatDto) =>
                  webChatDto.userName === "System generated message"
              )
            ) {
              leaveMeetingRoom();
              toast.error(t("the_webinar_has_been_closed_by_the_convenor"), {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else {
              initializeJitsi();
            }
          } else {
            initializeJitsi();
          }
        })
        .catch((err) => {
          initializeJitsi();
        });
    } else {
      initializeJitsi();
    }

    return () => {
      try {
        let reqDto = {
          meetingId: roomNumber,
          participantId: loginUserDetail.userId,
        };
        removeParticipantFromMeetingRoom(reqDto).then((response) => {
          console.log("removeParticipantFromMeetingRoom", response.data);
        });
      } catch (err) {
        console.log(err);
      }
      if (jitsi) {
        jitsi.dispose();
      }
    };
  }, [roomNumber]);

  return (
    <div id={jitsiContainerId} className="jitsi-loader-main-container">
      {loading && (
        <div className="jitsi-loader">
          <CircularProgress />
          <div>{t("joining_call_please_wait")}</div>
        </div>
      )}
    </div>
  );
};

export default JitsiVideoConference;
