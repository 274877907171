import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getPositionsOfOrganization } from "../../../../../services/AdminService";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { NumericFormatCustom } from "./NumericFormatCustom";
import { toast } from "react-toastify";
import { Trash } from "react-bootstrap-icons";
import { Edit } from "@mui/icons-material";
import { getNumFromStr } from "../../../taskUtil";

export const ManpowerIncoming = ({ state, dispatch, budgetHeadList }) => {
  const loginUserDetail = getLoginUserDetails();
  const [positionList, setPositionList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const [allTimeFormData, setAllTimeFormData] = useState({
    position: "",
    appoinmentNature: "",
    budgetHead: "",
    jdKra: "",
    noOfPosition: 0,
    maxCtc: 0,
    editMode: true,
  });

  const calculateRowTotal = (index) => {
    const { maxCtc, noOfPosition } = state.formDataCopy.manPowers[index];
    const value = maxCtc * noOfPosition;
    return value ? value : 0;
  };

  useEffect(() => {
    getPositionsOfOrganization(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        setPositionList(response.data);
      }
    });
  }, []);

  const handleSaveAllTimeData = (data) => {
    let isValid = true;
    if (data.position === "") {
      toast.error("Please Select Position");
      isValid = false;
    }
    if (data.appoinmentNature === "") {
      toast.error("Please Select Appointment Nature");
      isValid = false;
    }
    if (data.budgetHead === "") {
      toast.error("Please Select Budget Head");
      isValid = false;
    }

    if (data.jdKra === "") {
      toast.error("Please Provide JD-KRA");
      isValid = false;
    }
    if (data.noOfPosition === 0) {
      toast.error("Number Of Position Cannot be 0");
      isValid = false;
    }
    if (data.maxCtc === 0) {
      toast.error("Max CTC Can't Be 0");
      isValid = false;
    }
    if (!isValid) {
      return isValid;
    }

    const tempPosition = positionList.find(
      (item) => item.positionId === data.position
    );

    const budgetingHeadObject = budgetHeadList.find(
      (item) => item.id === data.budgetHead
    );

    const temp = {
      positionId: data.position,
      appointmentNature: data.appoinmentNature,
      noOfPosition: data.noOfPosition,
      maxCtc: data.maxCtc,
      estimatedValue: canculateTempTotalCTC(),
      jdKra: data.jdKra,
      budgetHeadId: data.budgetHead.id,
      positionName: tempPosition && tempPosition.position,
      budgetHeadName: data.budgetHead.head,
      expenseDtlId: 0,
      editMode: false,
    };
    dispatch({ type: "addItemToManpower", payload: temp });
    return isValid;
  };

  const getPositionNameFromId = (posId) => {
    if (!positionList || (positionList && positionList.length === 0)) {
      return "";
    }
    const position = positionList.find((pos) => pos.positionId === posId);
    if (position) {
      return position.position;
    } else {
      return "";
    }
  };

  const calculateTotalCtc = (index) => {
    const { maxCtc, noOfPosition } = state.formDataCopy.manPowers[index];
    const totalVal = parseInt(maxCtc) * parseInt(noOfPosition);
    if (isNaN(totalVal)) {
      return 0;
    } else {
      return totalVal;
    }
  };

  const initializeAllTimeForeData = () => {
    setAllTimeFormData({
      position: "",
      appoinmentNature: "",
      budgetHead: "",
      jdKra: "",
      noOfPosition: 0,
      maxCtc: 0,
      editMode: true,
    });
  };

  const canculateTempTotalCTC = () => {
    const { noOfPosition, maxCtc } = allTimeFormData;
    return noOfPosition * maxCtc;
  };

  const calculateTotalValue = () => {
    let totalVal = 0;
    for (let i = 0; i < state.formDataCopy.manPowers.length; i++) {
      const { maxCtc, noOfPosition } = state.formDataCopy.manPowers[i];
      totalVal += parseInt(maxCtc) * parseInt(noOfPosition);
    }
    return totalVal ? totalVal : 0;
  };

  return (
    <>
      <div className="indentFormtable">
        <div className="indtableResponsive">
          <table>
            <thead>
              <tr>
                <th className="widthMin120">Position</th>
                <th className="widthMin120">Appoint Nature</th>
                <th className="widthMin120">Budget Head</th>
                <th className="widthMin120">JD/KRA</th>
                <th className="widthMin120">Number Of Position</th>
                <th className="widthMin120">Max CTC</th>
                <th className="widthMin120">Total CTC</th>
                <th></th>
                {/* <th className="mw80">
              <div className="iconBtnGroup">
                <Tooltip arrow title="Add new Item">
                  <IconButton onClick={handleClickAddRow}>
                    <Add />
                  </IconButton>
                </Tooltip>
              </div>
            </th> */}
              </tr>
            </thead>
            <tbody>
              {state.formDataCopy.manPowers.length > 0 &&
                state.formDataCopy.manPowers.map((item, index) => {
                  if (item.editMode === true) {
                    return (
                      <tr>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="selectItems">Position</InputLabel>
                              <Select
                                className="formInputField"
                                variant="outlined"
                                labelId="selectItems"
                                id="select-Items"
                                value={
                                  state.formDataCopy.manPowers[index].positionId
                                }
                                label="Position"
                                // onChange={(e) => {
                                //   const temp = [
                                //     ...state.formDataCopy.manPowers,
                                //   ];
                                //   temp[index].position = e.target.value;
                                //   setManpowerList(temp);
                                // }}
                              >
                                x
                                {positionList &&
                                  positionList.map((position) => (
                                    <MenuItem value={position.positionId}>
                                      {position.position}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="selectItems">
                                Appointment Nature
                              </InputLabel>
                              <Select
                                className="formInputField"
                                variant="outlined"
                                labelId="selectItems"
                                id="select-Items"
                                value={item.appointmentNature}
                                label="Appoinment Nature"
                                onChange={(e) => {
                                  const temp = [
                                    ...state.formDataCopy.manPowers,
                                  ];
                                  temp[index].appointmentNature =
                                    e.target.value;
                                  dispatch({
                                    type: "updateManPowers",
                                    payload: temp,
                                  });
                                }}
                              >
                                <MenuItem value={"payroll"}>Payroll</MenuItem>
                                <MenuItem value={"retainer"}>Retainer</MenuItem>
                                <MenuItem value={"fullTime"}>
                                  Full time
                                </MenuItem>
                                <MenuItem value={"contractual"}>
                                  Contractual
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="selectLedgerHead">
                                Budget Head
                              </InputLabel>
                              <Select
                                className="formInputField"
                                variant="outlined"
                                labelId="selectLedgerHead"
                                id="select-LedgerHead"
                                label="Budget Head"
                                disabled={!item.editMode}
                                value={item.budgetHeadId}
                                onChange={(e) => {
                                  const temp = [
                                    ...state.formDataCopy.manPowers,
                                  ];
                                  const selectedBudgetHead =
                                    budgetHeadList.find(
                                      (head) => head.id === e.target.value
                                    );
                                  temp[index].budgetHeadId = e.target.value;
                                  temp[index].budgetHeadName =
                                    selectedBudgetHead &&
                                    selectedBudgetHead.head;
                                  dispatch({
                                    type: "updateManPowers",
                                    payload: temp,
                                  });
                                }}
                              >
                                {budgetHeadList.length > 0 &&
                                  budgetHeadList.map((itemName, index) => (
                                    <MenuItem
                                      value={itemName.id}
                                      key={itemName.head + "_" + index}
                                    >
                                      {itemName.head}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="JD/KRA"
                                variant="outlined"
                                className="formTextFieldArea"
                                fullWidth
                                size="small"
                                autoComplete="off"
                                value={item.jdKra}
                                onChange={(e) => {
                                  const temp = [
                                    ...state.formDataCopy.manPowers,
                                  ];
                                  temp[index].jdKra = e.target.value;
                                  dispatch({
                                    type: "updateManPowers",
                                    payload: temp,
                                  });
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="Number Of Position"
                                variant="outlined"
                                className="formTextFieldArea"
                                autoComplete="off"
                                fullWidth
                                size="small"
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                }}
                                value={item.noOfPosition}
                                onChange={(e) => {
                                  const temp = [
                                    ...state.formDataCopy.manPowers,
                                  ];
                                  const tempValue = e.target.value;
                                  temp[index].noOfPosition = tempValue;
                                  temp[index].estimatedValue =
                                    getNumFromStr(tempValue) *
                                    getNumFromStr(temp[index].maxCtc);
                                  dispatch({
                                    type: "updateManPowers",
                                    payload: temp,
                                  });
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="Max CTC"
                                variant="outlined"
                                className="formTextFieldArea"
                                autoComplete="off"
                                fullWidth
                                size="small"
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                }}
                                value={item.maxCtc}
                                onChange={(e) => {
                                  const temp = [
                                    ...state.formDataCopy.manPowers,
                                  ];
                                  const tempValue = e.target.value;
                                  temp[index].maxCtc = tempValue;
                                  temp[index].estimatedValue =
                                    getNumFromStr(tempValue) *
                                    getNumFromStr(temp[index].noOfPosition);
                                  dispatch({
                                    type: "updateManPowers",
                                    payload: temp,
                                  });
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>

                        <td>{calculateRowTotal(index)}</td>

                        <td>
                          <div className="iconBtnGroup">
                            {item.editMode === true && (
                              <Button
                                className="saveBtn"
                                onClick={(e) => {
                                  setIsEditing(false);
                                  dispatch({
                                    type: "editManpowerIndex",
                                    payload: index,
                                  });
                                }}
                              >
                                Save
                              </Button>
                            )}
                            {/* {item.editMode === false && (
                              <Tooltip title={"Edit Item"} placement="left">
                                <Button
                                  className={
                                    item.editMode === false &&
                                    isEditing &&
                                    `deactivatedEditButton`
                                  }
                                  disabled={
                                    item.editMode === false && isEditing
                                  }
                                  onClick={() => {
                                    setIsEditing(true);
                                    manpowerList[index].editMode = true;
                                    setManpowerList([...manpowerList]);
                                  }}
                                >
                                  <Edit />
                                </Button>
                              </Tooltip>
                            )} */}
                            <IconButton
                              onClick={() => {
                                if (item.editMode === true) {
                                  setIsEditing(false);
                                }
                                dispatch({
                                  type: "removeManPowersRowByIndex",
                                  payload: index,
                                });
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <td>
                          <div className="textOnly">
                            {getPositionNameFromId(item.positionId)}
                          </div>
                        </td>
                        <td>
                          <div className="textOnly">
                            {item.appointmentNature}
                          </div>
                        </td>
                        <td>
                          <div className="textOnly">{item.budgetHeadName}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.jdKra}</div>
                        </td>

                        <td>
                          <div className="textOnly">{item.noOfPosition}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.maxCtc}</div>
                        </td>
                        <td>{item.estimatedValue}</td>
                        <td>
                          <div className="iconBtnGroup">
                            {item.editMode === false && (
                              <Tooltip title={"Edit Item"} placement="left">
                                <IconButton
                                  className={
                                    item.editMode === false &&
                                    isEditing &&
                                    `deactivatedEditButton`
                                  }
                                  disabled={
                                    item.editMode === false && isEditing
                                  }
                                  onClick={() => {
                                    setIsEditing(true);

                                    dispatch({
                                      type: "editManpowerIndex",
                                      payload: index,
                                    });
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            )}
                            <IconButton
                              onClick={() => {
                                if (item.editMode === true) {
                                  setIsEditing(false);
                                }
                                dispatch({
                                  type: "removeManPowersRowByIndex",
                                  payload: index,
                                });
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                })}

              <tr>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectItems">Position</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectItems"
                        id="select-Items"
                        value={allTimeFormData.position}
                        label="Position"
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          temp.position = e.target.value;
                          setAllTimeFormData(temp);
                        }}
                      >
                        {positionList &&
                          positionList.map((position) => (
                            <MenuItem value={position.positionId}>
                              {position.position}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectItems">
                        Appointment Nature
                      </InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectItems"
                        id="select-Items"
                        value={allTimeFormData.appoinmentNature}
                        label="Appoinment Nature"
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          temp.appoinmentNature = e.target.value;
                          setAllTimeFormData(temp);
                        }}
                      >
                        <MenuItem value={"payroll"}>Payroll</MenuItem>
                        <MenuItem value={"retainer"}> Retainer </MenuItem>
                        <MenuItem value={"fullTime"}>Full time</MenuItem>
                        <MenuItem value={"contractual"}>Contractual</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectLedgerHead">Budget Head</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectLedgerHead"
                        id="select-LedgerHead"
                        label="Budget Head"
                        value={allTimeFormData.budgetHead}
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          temp.budgetHead = e.target.value;
                          setAllTimeFormData(temp);
                        }}
                      >
                        {budgetHeadList.length > 0 &&
                          budgetHeadList.map((itemName, index) => (
                            <MenuItem
                              value={itemName}
                              key={itemName.head + "_" + index}
                            >
                              {itemName.head}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label="JD/KRA"
                        variant="outlined"
                        className="formTextFieldArea"
                        fullWidth
                        size="small"
                        autoComplete="off"
                        value={allTimeFormData.jdKra}
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          temp.jdKra = e.target.value;
                          setAllTimeFormData(temp);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label="Number Of Position"
                        variant="outlined"
                        className="formTextFieldArea"
                        autoComplete="off"
                        fullWidth
                        size="small"
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                        value={allTimeFormData.noOfPosition}
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          temp.noOfPosition = e.target.value;
                          setAllTimeFormData(temp);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label="Max CTC"
                        variant="outlined"
                        className="formTextFieldArea"
                        autoComplete="off"
                        fullWidth
                        size="small"
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                        value={allTimeFormData.maxCtc}
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          temp.maxCtc = e.target.value;
                          setAllTimeFormData(temp);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>{canculateTempTotalCTC()}</td>

                <td>
                  <div className="iconBtnGroup">
                    {allTimeFormData.editMode === true && (
                      <Button
                        className="saveBtn"
                        onClick={(e) => {
                          const temp = { ...allTimeFormData, editMode: false };
                          const tempBool = handleSaveAllTimeData(temp);
                          tempBool && initializeAllTimeForeData();
                        }}
                      >
                        Save
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={"6"}>
                  <div className="textOnly tvText">Total Value</div>
                </td>
                <td>
                  <div className="textOnly fw600">{calculateTotalValue()}</div>
                </td>
                <td colSpan="1"></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};
