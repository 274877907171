import { Add, Edit } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Trash } from "react-bootstrap-icons";
import { jwtDecode } from "jwt-decode";
import {
  addBudgetHead,
  getBudgetHeads,
  deactivateBudgetHead,
} from "../../../../services/AdminService";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  addOperatingExpListItem,
  deleteOperatingExpListItem,
  setOperatingExpList,
  setCapitalExpList,
  addCapitalExpListItem,
  deleteCapitalExpListItem,
} from "../../../../redux/reducers/operatingExpListSlice";
import { useTranslation } from "react-i18next";



export default function DefineExpenditure() {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  console.log("userDetails", userDetails);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const allExp=useSelector((state) => state.operatingExpList);
  // const operatingExpNewList = allExp.expList


  // const CapitalExpNewList = allExp.capList

  // console.log("operatingExpNewList", operatingExpNewList);

  // console.log("CapitalExpNewList", CapitalExpNewList)

  const capExpen = [
    { label: "Land" },
    { label: "Building" },
    { label: "Building Equipment" },
  ];

  const [operatingExp, setOperatingExp] = useState([]);
  const [opertingExpValue, setOperatingExpValue] = useState([]);
  const [operatingExpString, setOperatingExpString] = useState("");
  const [operatingExpList, setOperatingExpList] = useState([]);
  const [operatingExpData, setOperatingExpData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);

  const [downLoading, setDownLoading] = useState(true);

  const [capitalExp, setCapitalExp] = useState([]);
  const [capitalExpValue, setCapitalExpValue] = useState([]);
  const [capitalExpString, setCapitalExpString] = useState("");

  const handleCapitalText = (e , newValue) => {
    console.log("handleCapitalText", newValue);
    setCapitalExpString(newValue);
  };

  const handleCapitalExpSelect = (newValue) => {
    console.log('handleCapitalExpSelect', newValue);
    setCapitalExp(newValue);
    // setCapitalExpValue((prevValues) =>
    //   prevValues.filter((value) => value !== selectedValue)
    // );
  };

  const handleAddCapitalExpenditure = () => {
    setLoading(true);
    console.log("handleAddCapitalExpenditure");


    if (capitalExpString.trim() === "") {
      toast.error(t("please_enter_capital_expenditure_item"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (capitalExpValue.some(item => item.head === capitalExpString)) {
      toast.error(t("duplicate_capital_expenditure_item_please_add_a_new_item"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const reqDto = [
      {
        category: "Capital Expense",
        head: capitalExpString,
        id: 0,
      },
    ];

    console.log("reqDto", reqDto);
    // return ;

    addBudgetHead(userDetails.orgId, userDetails.userId, reqDto).then(
      (response) => {
        console.log("addBudgetHead", response.data);
        setLoading(false);
        if (response.data.returnValue === "1") {
          toast.success(t("capital_expenditure_added_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchBudgetHeads();
          setCapitalExpString("");
          setCapitalExp([]);
        }
      }
    );
  };

  console.log('capitalExpString', capitalExpString);
  console.log('capitalExp', capitalExp);

  // const handleAddCapitalExpListItem = (item) => {
  //   console.log("handleAddCapitalExpListItem", item);
  //   dispatch(addCapitalExpListItem(item));
  //   setCapitalExp([]);
  // };

  // const handleDeleteCapitalExpListItem = (item) => {
  //   console.log('handleDeleteCapitalExpListItem', item)
  //   dispatch(deleteCapitalExpListItem(item));
  // };


 

  // const handleAddOperatingExpListItem = (item) => {
  //   console.log("handleAddOperatingExpListItem", item);
  //   dispatch(addOperatingExpListItem(item));
  // };

  // const handleDeleteOperatingExpListItem = (item) => {
  //   console.log('item', item)
  //   dispatch(deleteOperatingExpListItem(item));
  // };

  // const handlecapitalExp = () =>{
  //   setCapitalExp([]);
  // }

  // const addExpenditureList = () => {
  //   const selectedExpenses = operatingExpData.filter(
  //     (item) => operatingExp.includes(item.head)
  //   );

  //   // Check for duplicates based on id
  //   const uniqueSelectedExpenses = selectedExpenses.filter((expense) => {
  //     return !operatingExpList.some((item) => item.id === expense.id);
  //   });

  //   // Update operatingExpList with unique values
  //   setOperatingExpList((prevList) => [...prevList, ...uniqueSelectedExpenses]);
  //   console.log('selectedExpenses', uniqueSelectedExpenses);

  //   setOperatingExp([]);
  // };

  ////////////////////////////////////////////////////////////////////////////////////////////////

  const handleOperatingText = (e , newValue) => {
    console.log("handleOperatingText", newValue);
    setOperatingExpString(newValue);
  };

  const handleOperatingExpSelect = (newValue) => {
    setOperatingExp(newValue);
    // setOperatingExpValue((prevValues) =>
      // prevValues.filter((value) => value !== selectedValue)
    // );
  };


  const handleAddOperatingExpenditure = () => {
    setLoad(true);
    console.log("handleAddOperatingExpenditure");

    if (operatingExpString.trim() === "") {
      toast.error(t("please_enter_operating_expenditure_item"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoad(false);
      return;
    };

    if (opertingExpValue.some(item => item.head === operatingExpString)) {
      toast.error(t("duplicate_operating_expenditure_item_please_add_a_new_item"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoad(false);
      return;
    };
    

    const reqDto = [
      {
        category: "Operating Expense",
        head: operatingExpString,
        id: 0,
      },
    ];

    console.log("reqDto", reqDto);

    // return;

    addBudgetHead(userDetails.orgId, userDetails.userId, reqDto).then(
      (response) => {
        console.log("addBudgetHead", response.data);
        setLoad(false);
        if (response.data.returnValue === "1") {
          toast.success(t("operating_expenditure_added_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchBudgetHeads();
          setOperatingExpString("");
          setOperatingExp([]);
        }
      }
    );
  };

  console.log('operatingExpString', operatingExpString);

  // useEffect(() => {
  //   getBudgetHeads(userDetails.orgId).then((response) => {
  //     console.log("getbudget", response.data);
  //     setOperatingExpValue(response.data || []);
  //   });
  // }, [userDetails.orgId]);

  useEffect(() => {
    fetchBudgetHeads();
  }, [userDetails.orgId, dispatch]);

  // const fetchBudgetHeads = () => {
  //   getBudgetHeads(userDetails.orgId)
  //     .then((response) => {
  //       console.log("getbudget", response.data);
  //       setOperatingExpValue(response.data || []);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching budget heads:", error);
  //     });
  // };

  const fetchBudgetHeads = () => {
    getBudgetHeads(userDetails.orgId)
      .then((response) => {
        console.log("getbudget", response.data);
        const operatingExpenses = response.data.filter(
          (item) => item.category === "Operating Expense"
        );
        setOperatingExpValue(operatingExpenses);
        setOperatingExpData(operatingExpenses);

        const capitalExpenses = response.data.filter(
          (item) => item.category === "Capital Expense"
        );
        setCapitalExpValue(capitalExpenses);
        setDownLoading(false)
      })
      .catch((error) => {
        console.error("Error fetching budget heads:", error);
      });
  };



  const deleteExpenditure = (item) => {
    console.log("item", item);

    deactivateBudgetHead(userDetails.userId, item).then((response) => {
      console.log("data deleted", response.data);
      if (response.data.returnValue === "1") {
        toast.success(t("budget_head_deactivated_successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        fetchBudgetHeads();
      }
    });
  };

  // const addExpenditureList = () => {
  //   if (operatingExp.length > 0) {
  //     setOperatingExpList((prevList) => [...prevList, ...operatingExp]);
  //   }
  //   setOperatingExp([]);
  // };

  // const addExpenditureList = () => {
  //   // if (operatingExp.length > 0) {

  //     const selectedExpenses = operatingExpData.filter(
  //       (item) => operatingExp.includes(item.head)
  //       // (item) => item.head.includes(operatingExp)
  //     );

  //     setOperatingExpList((prevList) => [...prevList, ...selectedExpenses]);
  //     console.log('selectedExpenses', selectedExpenses)
  //   // }
  //   setOperatingExp([]);
  // };

  // const addExpenditureList = () => {
  //   const selectedExpenses = operatingExpData.filter((item) =>
  //     operatingExp.includes(item.head)
  //   );

  //   // Check for duplicates based on id
  //   const uniqueSelectedExpenses = selectedExpenses.filter((expense) => {
  //     return !operatingExpList.some((item) => item.id === expense.id);
  //   });

  //   // Update operatingExpList with unique values
  //   setOperatingExpList((prevList) => [...prevList, ...uniqueSelectedExpenses]);
  //   console.log("selectedExpenses", uniqueSelectedExpenses);

  //   setOperatingExp([]);
  // };


//   const deleteExpenditure = (item) => {
//     console.log("item", item);
//     console.log("opertingExpValue", opertingExpValue);

//     const matchingBudgetHead = opertingExpValue.find(
//       (budgetHead) => budgetHead.head === item
//     );

//     console.log("matchingBudgetHead", matchingBudgetHead);
//     if (matchingBudgetHead) {
//       deactivateBudgetHead(userDetails.userId, matchingBudgetHead).then(
//         (response) => {
//           console.log("data deleted", response.data);
//           if (response.data.returnValue === "1") {
//             toast.success(response.data.message, {
//               position: toast.POSITION.TOP_RIGHT,
//             });
//             setOperatingExpList((prevList) => {
//               const updatedList = prevList.filter(
//                 (expItem) => expItem.head !== item
//               );
//               console.log("Updated operatingExpList:", updatedList);
//               return updatedList;
//             });

//             fetchBudgetHeads();
//           }
//         }
//       );
//     } else {
//       console.log("Matching budget head not found");
//     }
// };

console.log('capitalExpValue', capitalExpValue)


  return (
    <>
      <div className="fullContainArea">
        <div className="cuContainArea">
          <div className="expenditureContainer">
            <div className="expenElement">
              <div className="expElemhead">{t("capital_expenditure")}</div>
              <div className="expContain">
                <div className="elementFormContainer">
                  <div className="formElementGroupWthBtn">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <Autocomplete
                        //  noOptionsText="Please type to search"
                          multiple
                          className="formAutocompleteField"
                          variant="outlined"
                          disablePortal
                          freeSolo
                          id="combo-box-demo"
                          // options={
                          //   Array.isArray(capitalExpValue)
                          //     ? capitalExpValue.map((item) => item.head)
                          //     : []
                          // }
                          options={[]}
                          value={capitalExp}
                          inputValue={capitalExpString}
                          onInputChange={handleCapitalText}
                          onChange={handleCapitalExpSelect}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="formAutoComInputField autocompFildPlaceholder"
                              placeholder={t("enter_expenditure_items")}
                              required
                              // value={capitalExpString}
                              // onChange={handleCapitalText}
                            />
                          )}
                          // filterOptions={(options, { inputValue }) => {
                          //   const filteredOptions = options.filter(
                          //     (option) => !capitalExp.includes(option)
                          //   );
                          //   return filteredOptions;
                          // }}
                          filterOptions={(options, { inputValue }) => {
                            if (!inputValue) {
                              return [];
                            }
                            return options.filter((option) =>
                              option.toLowerCase().includes(inputValue.toLowerCase())
                            );
                          }}
                          // getOptionDisabled={(option) => capitalExpValue.includes(option)}
                          getOptionDisabled={(option) =>
                            capitalExpValue.some((item) => item.head === option)
                          }
                        />
                      </FormControl>
                    </div>

                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <Button
                        startIcon={<Add />}
                        className="dfultPrimaryBtn"
                        onClick={() => handleAddCapitalExpenditure()}
                        // onClick={() => {
                        //   if (capitalExp.length > 0) {
                        //     handleAddCapitalExpListItem(capitalExp);
                        //   } else {
                        //     handleAddCapitalExpenditure();
                        //   } 
                        // }}
                        disabled={loading}
                      >
                        {t("add")}
                      </Button>
                    )}
                  </div>

                  {downLoading ? (
                    <div className="center-progress" style={{ height: "65vh" }}>
                      <CircularProgress
                        sx={{ marginTop: "100px", marginLeft: "150px" }}
                      />
                    </div>
                  ) :
                  capitalExpValue.length === 0 ? (
                    <div class="noDataCard ">
                      <span>{t("oops")}</span>{t("no_capital_expenditure_added_yet")}
                      <br />
                      <span className="sallMsg">
                       {t("select_and_add_capital_expenditure")}
                      </span>
                    </div>
                  ) : (
                    <div class="elementListGrp">
                      <div class="elementHeding">{t("ce_items_added")}</div>
                      <ul class="elmListInfo custlist">
                        {capitalExpValue.map((item, index) => (
                          <li key={index}>
                            <span className="rolsNm">{item.head}</span>
                            <IconButton
                              className="rmvBtn"
                              onClick={() => {deleteExpenditure(item)}}
                              // onClick={()=>handleDeleteCapitalExpListItem(item)}
                            >
                              <Trash />
                            </IconButton>
                          </li>
                        ))}
                        {/* <li>
                        <span className="rolsNm">Land </span>
                        <IconButton className="rmvBtn">
                          <Trash />
                        </IconButton>
                      </li> */}
                        {/* <li>
                        <span className="rolsNm">Building</span>
                        <IconButton className="rmvBtn">
                          <Trash />
                        </IconButton>
                      </li> */}
                        {/* <li>
                        <span className="rolsNm">Building Equipment</span>
                        <IconButton className="rmvBtn">
                          <Trash />
                        </IconButton>
                      </li> */}
                        {/* <li>
                        <span className="rolsNm">Furniture & Furnishing</span>
                        <IconButton className="rmvBtn">
                          <Trash />
                        </IconButton>
                      </li> */}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="expenElement">
              <div className="expElemhead">{t("operating_expenditure")}</div>
              <div className="expContain">
                <div className="elementFormContainer">
                  <div className="formElementGroupWthBtn">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <Autocomplete
                          // noOptionsText="Please type to search or to Add"
                          id="combo-box-demo"
                          multiple
                          className="formAutocompleteField"
                          variant="outlined"
                          freeSolo
                          disablePortal
                          // options={
                          //   Array.isArray(opertingExpValue)
                          //     ? opertingExpValue.map((item) => item.head)
                          //     : []
                          // }
                          options={[]}
                          value={operatingExp}
                          inputValue={operatingExpString}
                          onInputChange={handleOperatingText}
                          onChange={handleOperatingExpSelect}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="formAutoComInputField autocompFildPlaceholder"
                              placeholder={t("enter_expenditure_items")}
                              required
                              // value={operatingExpString}
                              // onChange={(e) => handleOperatingText(e)}
                            />
                          )}
                          // inputValue={operatingExpString}
                          // onInputChange={(e) => handleOperatingText(e)}
                          // value={operatingExp}
                          // onChange={(event, value) =>
                          //   handleOperatingExpSelect(value)
                          // }
                          // filterOptions={(options, { inputValue }) => {
                          //   const filteredOptions = options.filter(
                          //     (option) => !operatingExp.includes(option)
                          //   );
                          //   return filteredOptions;
                          // }}
                          filterOptions={(options, { inputValue }) => {
                            // Filter the options based on the input value
                            if (!inputValue) {
                              return [];
                            }
                            return options.filter((option) =>
                              option.toLowerCase().includes(inputValue.toLowerCase())
                            );
                          }}
                          // getOptionDisabled={(option) => opertingExpValue.includes(option)}
                          getOptionDisabled={(option) =>
                            opertingExpValue.some((item) => item.head === option)
                          }
                        />
                      </FormControl>
                    </div>
                    {load ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <Button
                        startIcon={<Add />}
                        className="dfultPrimaryBtn"
                        // onClick={() => {
                        //   console.log('operatingExp', operatingExp)
                        //   if (operatingExp.length > 0) {
                        //     addExpenditureList();
                        //     handleAddOperatingExpListItem(operatingExp);
                        //   } else {
                        //     handleAddOperatingExpenditure();
                        //   } 
                        // }}
                        onClick={()=>handleAddOperatingExpenditure()}
                        
                      >
                        {t("add")}
                      </Button>
                    )}
                  </div>
                  

                  {downLoading ? (
                    <div className="center-progress" style={{ height: "65vh" }}>
                      <CircularProgress
                        sx={{ marginTop: "100px", marginLeft: "150px" }}
                      />
                    </div>
                  ) :
                  opertingExpValue.length === 0 ? (
                    <div class="noDataCard ">
                      <span>{t("oops")}</span>{t("no_operating_expenditure_added_yet")}
                      <br />
                      <span className="sallMsg">
                      {t("select_and_add_operating_expenditure")}
                      </span>
                    </div>
                  ) : (
                    <div class="elementListGrp">
                      <div class="elementHeding">{t("oe_items_added")}</div>
                      <ul class="elmListInfo custlist">

                         {opertingExpValue.map((item, index) => (
                          <li key={index}>
                            <span className="rolsNm">{item.head}</span>
                            <IconButton
                              className="rmvBtn"
                              onClick={() => {
                                deleteExpenditure(item) ; 
                                
                                // handleDeleteOperatingExpListItem(item)
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </li>
                        ))}

                      {/* {operatingExpNewList.map((item, index) => (
  <div key={index} className="elementListGrp">
    <ul className="elmListInfo custlist" style={{ listStyleType: 'disc' }}>
      {subArray.map((item, subIndex) => (
        <li key={subIndex}>
          <span className="rolsNm">{item}</span>
          <IconButton
            className="rmvBtn"
            onClick={() => {
              // deleteExpenditure(item); 
               handleDeleteOperatingExpListItem(item); }}
          >
            <Trash />
          </IconButton>
        </li>
      ))}
    </ul>
  </div>
))} */}


                        {/* {operatingExpNewList.map((subArray, index) => (
                          <div key={index} className="elementListGrp">
                            <ul className="elmListInfo custlist">
                              {subArray.map((item, subIndex) => (
                                <li key={subIndex}>
                                  {index === 0 && (
                                    <span className="rolsNm">{`${item}`}</span>
                                  )}
                                  {index !== 0 && (
                                    <span className="rolsNm">{`${item}`}</span>
                                  )}

                                  <IconButton
                                    className="rmvBtn"
                                    onClick={() => {
                                      deleteExpenditure(item);
                                      handleDeleteOperatingExpListItem(item);
                                    }}
                                  >
                                    <Trash />
                                  </IconButton>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))} */}




                        {/* {operatingExpNewList[0].map((item, index) => (
                          <li key={index}>
                            <span className="rolsNm">{item}</span>
                            <IconButton
                              className="rmvBtn"
                              onClick={() => {deleteExpenditure(item) ; 
                                handleDeleteOperatingExpListItem(item)}}
                            >
                              <Trash />
                            </IconButton>
                          </li>
                        ))} */}
                        {/* <li>
                        <span className="rolsNm">
                          HR Expenses: a Salary b. Incentives c. TA/DA d.
                          Employee Welfare Expenses
                        </span>
                        <IconButton className="rmvBtn">
                          <Trash />
                        </IconButton>
                      </li> */}
                        {/* <li>
                        <span className="rolsNm">
                          Office Expenses: a. Rent b. Utilities c. Printing &
                          Stationary d. Other office expenses
                        </span>
                        <IconButton className="rmvBtn">
                          <Trash />
                        </IconButton>
                      </li> */}
                        {/* <li>
                        <span className="rolsNm">
                          Stocks & Inventory (COGS) (each time a contract is
                          added, the COGS value of stocks to be utilized will
                          get added here)
                        </span>
                        <IconButton className="rmvBtn">
                          <Trash />
                        </IconButton>
                      </li> */}
                        {/* <li>
                        <span className="rolsNm">
                          Legal & Statutory (non-HR) Expenses: a. Statutory
                          Expenses b. Legal Fees
                        </span>
                        <IconButton className="rmvBtn">
                          <Trash />
                        </IconButton>
                      </li> */}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
