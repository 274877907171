import { Box, IconButton } from "@mui/material";
import React from "react";
import { XLg } from "react-bootstrap-icons";

export const CustomModal = ({ children, ...props }) => {
  return (
    <div className="modelContainer" style={props.style}>
      <Box className={`modelContain ModelBox animated ${props.className}`}>
        <IconButton
          className="closeModelIoc"
          onClick={(e) => {
            props.closeModal();
          }}
        >
          <XLg />
        </IconButton>

        <div className="fwModelContainArea">
          <div className="modHeader">
            <div className="modHeaderText">{props.modalTitle} </div>
          </div>
          {children}
        </div>
      </Box>
    </div>
  );
};
