import EmailsView from "../../../../email/view/EmailsView";

export default function RightSideMailContainer({
  selectedData,
  setSelectedData,
  closeFromDashboard,
  setOpenRightMail,
}) {
  // console.log("close from Dashboard = ", closeFromDashboard);
  return (
    <>
      <div className="dashboardMailContainer">
        <div className="dashMailPannel">
          <div className="dashMailContain">
            <div className="dashMailHeaderPnl"></div>
            <EmailsView mode={"right"} close={closeFromDashboard} />
          </div>
        </div>
      </div>
    </>
  );
}
