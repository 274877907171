import React from "react";
import FilterCard from "../cards/FilterCard";
import MessageCard from "../cards/MessageCard";
import NoDataFoundCard from "../cards/NoDataFoundCard";
import LoaderSmall from "../../../../../utils/LoaderSmall";

export default function MessageMenuComponent({
  dataList,
  selectedData,
  onClick,
  loading,
}) {
  return (
    <>
      <div className="mnuCompoContainer">
        {/* <FilterCard /> */}
        <div className="mnuCompoIncomingInstanse">
          {loading && <LoaderSmall />}
          {dataList && dataList.length > 0 ? (
            <>
              {dataList.map((item) => (
                <MessageCard
                  data={item}
                  active={selectedData && selectedData.id === item.id}
                  onClick={onClick}
                />
              ))}
            </>
          ) : (
            <>
              <NoDataFoundCard />
            </>
          )}
        </div>
      </div>
    </>
  );
}
