import axios from "axios";
import http from "../config/http-common";
import { socketEndPoint } from "../constants/url_provider";

class EmailService {
  constructor() {
    this.mailEndPoint = `${socketEndPoint}/api/mail`;
  }

  getNewRefreshToken(userId) {
    console.log("in get new refresh token");
    return axios.get(`${this.mailEndPoint}/getNewRefreshToken/${userId}`);
  }
}

export default new EmailService();
