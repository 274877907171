import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import "../../../css/BiometricMarkAttendanceFace.css";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import DashboardServices from "../../../../../services/DashboardServices";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";
import { FaceLivenessAwsAmplify } from "../../../../profile/view/FaceLiveness";
import { XLg } from "react-bootstrap-icons";
import LinearWithValueLabel from "../../../../../utils/LinearProgressWithLabel";
import AttendanceService from "../../../../../services/AttendanceService";
import { useTranslation } from "react-i18next";

const BiometricMarkAttendanceFace = ({
  open,
  onClose,
  faceId,
  setFaceId,
  setShowRegFace,
  latitude,
  longitude,
}) => {
  const loginUserDetails = getLoginUserDetails();
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const [progMessage, setProgMessage] = useState("");
  const [livenessPassed, setLivenessPassed] = useState(false);

  const streamRef = useRef(null);
  const isCameraOpen = useRef(false);

  useEffect(() => {
    setLivenessPassed(false);
    return () => {
      closeCamera();
    };
  }, [open]);

  const closeCamera = () => {
    if (isCameraOpen.current) {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
      isCameraOpen.current = false;
    }
  };

  const markAttendance = async (capturedImage) => {
    console.log("capturedImage", capturedImage);

    setProgress(15);
    setLivenessPassed(true);
    let facesList = [];
    facesList.push(capturedImage);
    let reqDto = {
      userFaces: facesList,
    };

    setProgMessage("Face liveness test passed....");
    const responseCheckImage = await DashboardServices.checkImage(
      loginUserDetails.userId,
      loginUserDetails.orgId,
      reqDto
    );

    console.log("responseCheckImage", responseCheckImage.data);
    setProgMessage(
      "Checking facial biometrics and marking attendance, Please wait...."
    );

    if (responseCheckImage.data.returnValue === "1") {
      setProgress(50);

      let reqDto = {
        attendanceImage: capturedImage,
        attendanceTime: new Date().toISOString(),
        latitude: latitude,
        longitude: longitude,
        userAgent: navigator.userAgent,
        isMobile: "N",
        faceId: faceId,
      };

      const responseMarkAttendance = await AttendanceService.markAttendance(
        loginUserDetails.userId,
        loginUserDetails.orgId,
        reqDto
      );

      console.log("responseCheckColForImg", responseMarkAttendance.data);
      // return;
      if (responseMarkAttendance.data.returnValue === "1") {
        setProgress(100);
        setProgMessage(responseMarkAttendance.data.message);

        setTimeout(() => {
          toast.success(responseMarkAttendance.data.message);
          onClose();
        }, 1000);
      } else {
        toast.error(responseMarkAttendance.data.message);
        onClose();
        // setProgress(66);
        // setProgMessage(
        //   "Facial biometric uniqueness confirmed, registering face now..."
        // );
        // const responseAddFaceToCol =
        //   await DashboardServices.addFaceToCollection(
        //     loginUserDetails.userId,
        //     loginUserDetails.orgId,
        //     reqDto
        //   );
        // if (responseAddFaceToCol.data.returnValue === "1") {
        //   setProgress(100);
        //   setProgMessage("Facial Biometrics added to account successfully");
        //   setTimeout(() => {
        //     toast.success("Facial Biometrics added to account successfully");
        //     setFaceId(responseAddFaceToCol.data.message);
        //     onClose();
        //   }, 1000);
        //   return;
        // } else {
        //   toast.error("Something went wrong");
        //   onClose();
        // }
        // console.log("responseCheckImage", responseAddFaceToCol.data);
      }
    } else {
      toast.error(responseCheckImage.data.message);
      onClose();
    }

    // setProgress(0);
  };

  const submitImage = async (capturedImage) => {
    console.log("capturedImage", capturedImage);
    setProgress(20);
    setLivenessPassed(true);
    let facesList = [];
    facesList.push(capturedImage);
    let reqDto = {
      userFaces: facesList,
    };
    setProgMessage("Face liveness test passed....");
    const responseCheckImage = await DashboardServices.checkImage(
      loginUserDetails.userId,
      loginUserDetails.orgId,
      reqDto
    );
    console.log("responseCheckImage", responseCheckImage.data);
    setProgMessage("Checking facial biometrics, Please wait....");
    if (responseCheckImage.data.returnValue === "1") {
      setProgress(33);
      const responseCheckColForImg =
        await DashboardServices.checkCollectionForImage(
          loginUserDetails.userId,
          loginUserDetails.orgId,
          reqDto
        );
      console.log("responseCheckColForImg", responseCheckColForImg.data);
      if (responseCheckColForImg.data.returnValue === "1") {
        // if (markAttendance) {
        //   console.log("markAttendance", responseCheckColForImg.data);
        // } else {
        //   toast.error(
        //     "These biometric are associated with another account please continue with that account."
        //   );
        //   onClose();
        // }
        let facialDataUserId = responseCheckColForImg.data.status.split("_")[2];
        console.log("facialDataUserId", facialDataUserId);
        if (facialDataUserId === loginUserDetails.userId) {
          let reqDto = {
            attendanceImage: capturedImage,
            attendanceTime: new Date(),
            latitude: 0,
            longitude: 0,
            userAgent: navigator.userAgent,
            isMobile: "string",
            isWorkFromHome: "string",
          };
        }
      } else if (responseCheckColForImg.data.returnValue === "-1") {
        toast.error(t("something_went_wrong"));
      } else {
        setProgress(66);
        setProgMessage(
          "Facial biometric uniqueness confirmed, registering face now..."
        );
        const responseAddFaceToCol =
          await DashboardServices.addFaceToCollection(
            loginUserDetails.userId,
            loginUserDetails.orgId,
            reqDto
          );
        if (responseAddFaceToCol.data.returnValue === "1") {
          setProgress(100);
          setProgMessage("Facial Biometrics added to account successfully");
          setTimeout(() => {
            toast.success(t("facial_biometrics_added_to_account_successfully"));
            setFaceId(responseAddFaceToCol.data.message);
            onClose();
          }, 1000);
          return;
        } else {
          toast.error(t("something_went_wrong"));
          onClose();
        }
        console.log("responseCheckImage", responseAddFaceToCol.data);
      }
    } else {
      toast.error(responseCheckImage.data.message);
      onClose();
    }

    // setProgress(0);
  };

  const confirm = useConfirm();

  const closeFaceLivenessModal = () => {
    if (faceId != null) {
      onClose();
    } else {
      confirm({ description: "Are you sure!" })
        .then(() => {
          onClose();
        })
        .catch(() => {
          console.log("Face liveness test cancelled");
        });
    }
  };

  return (
    <Dialog open={open} maxWidth="sm">
      <DialogContent className="dialogContentFaceLiveness">
        {!faceId
          ? t("please_register_your_facial_biometrics_to_mark_attendance")
          : t("please_provide_your_facial_biometrics_to_mark_attendance")}
        <br />
        {!livenessPassed && (
          <div
            role="button"
            style={{ cursor: "pointer" }}
            className="closeDilog"
            onClick={closeFaceLivenessModal}
          >
            <XLg />
          </div>
        )}
        {!livenessPassed && (
          <>
            <FaceLivenessAwsAmplify
              onDone={(data) => {
                console.log(data);
                if (data.returnValue === "1") {
                  markAttendance(data.image);
                } else {
                  onClose();
                }
              }}
              onClose={onClose}
              onRetry={() => {
                onClose();
                setTimeout(() => {
                  setShowRegFace(true);
                }, 1000);
              }}
            />
          </>
        )}
        {livenessPassed && progress > 0 && (
          <>
            <p
              style={{
                fontSize: "14px",
                margin: "5px",
              }}
            >
              {progMessage}
            </p>
            <LinearWithValueLabel prog={progress} />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default BiometricMarkAttendanceFace;
