import { IconButton } from "@mui/material";
import React, { useRef } from "react";
import DashboardServices from "../../../../../services/DashboardServices";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { Play } from "react-bootstrap-icons";

export const TaskAudio = ({ data, setShowAudioModal }) => {
  const loginUserDetail = getLoginUserDetails();
  const audioUrl = useRef();

  return (
    <>
      {data && data.audioNoteFileName && (
        <div className="taskElementGrp">
          <div className="tskElemntInfo">
            <div className="playAudioNotes">
              <IconButton
                variant="outlined"
                onClick={(e) => {
                  DashboardServices.generatePreSignedUrlS3Object(
                    data.audioNoteFileName,
                    loginUserDetail.orgId
                  ).then((response) => {
                    audioUrl.current = response.data;
                    setShowAudioModal(true);
                  });
                }}
              >
                <Play />
              </IconButton>
              <span>Play Audio Message</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
