import React from "react";
import TaskTemplates from "./TaskTemplates";
import TaskUploadFile from "./TaskUploadFile";

export default function RightFlotingContainer({
  recommendedTemplateList,
  tempOfOrgList,
  addedTemplateList,
  setAddedTemplateList,
  setRecommendedTemplateList,
  closeTemplate,
  mode,
}) {
  return (
    <>
      <div className="RightFlotingContainerArea">
        <TaskTemplates
          recommendedTemplateList={recommendedTemplateList}
          setRecommendedTemplateList={setRecommendedTemplateList}
          tempOfOrgList={tempOfOrgList}
          addedTemplateList={addedTemplateList}
          setAddedTemplateList={setAddedTemplateList}
          closeTemplate={closeTemplate}
          mode={mode}
        />
        {/* <TaskUploadFile
          recommendedTemplateList={recommendedTemplateList}
          setRecommendedTemplateList={setRecommendedTemplateList}
          tempOfOrgList={tempOfOrgList}
          addedTemplateList={addedTemplateList}
          setAddedTemplateList={setAddedTemplateList}
          closeTemplate={closeTemplate}
          mode={mode}
        /> */}
      </div>
    </>
  );
}
