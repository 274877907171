import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Modal,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { toast } from "react-toastify";

// Import React Table
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GoogleContacts from "react-google-contacts";
import DashboardServices from "../../../../services/DashboardServices";
import { getLoginUserDetails } from "../../../../utils/Utils";
import { Trash, PatchCheck, PersonFillAdd } from "react-bootstrap-icons";
import "../../css/contacts.css";
import { Send } from "@mui/icons-material";

class Contacts extends React.Component {
  userDetails = getLoginUserDetails();

  constructor() {
    super();
    this.state = {
      data: [],
      contactDtls: null,
      loading: false,
      showMobAddContacts: false,
    };
    this.gapiInited = false;
    this.gisInited = false;
    this.tokenClient = null;

    this.initializeGapiClient = this.initializeGapiClient.bind(this);
    this.gapiLoaded = this.gapiLoaded.bind(this);
    this.gisLoaded = this.gisLoaded.bind(this);
    this.maybeEnableButtons = this.maybeEnableButtons.bind(this);
    this.listConnectionNames = this.listConnectionNames.bind(this);
    this.handleAuthClick = this.handleAuthClick.bind(this);
    this.responseCallback = this.responseCallback.bind(this);
  }

  toggleMobaddcontct = () => {
    this.setState((prevState) => ({
      showMobAddContacts: !prevState.showMobAddContacts,
    }));
  };

  componentDidMount() {
    DashboardServices.getContacts(this.userDetails.userId).then((response) => {
      console.log(response.data);
      if (response.data) {
        this.setState({
          data: response.data,
        });
      } else {
        this.setState({
          data: [],
        });
      }
    });

    document.getElementById("authorize_button").style.visibility = "hidden";
    const script1 = document.createElement("script");
    script1.async = true;
    script1.defer = true;
    script1.src = "https://apis.google.com/js/api.js";
    script1.onload = this.gapiLoaded;

    const script2 = document.createElement("script");
    script2.async = true;
    script2.defer = true;
    script2.src = "https://accounts.google.com/gsi/client";
    script2.onload = this.gisLoaded;

    document.body.appendChild(script1);
    document.body.appendChild(script2);
  }

  gapiLoaded() {
    window.gapi.load("client", this.initializeGapiClient);
  }

  gisLoaded() {
    this.tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id:
        "791849580758-l63om9okcfi3q6dnk8nk14avkll33i2p.apps.googleusercontent.com",
      scope: "https://www.googleapis.com/auth/contacts.readonly",
      callback: "",
    });
    this.gisInited = true;
    this.maybeEnableButtons();
  }

  initializeGapiClient() {
    window.gapi.client
      .init({
        apiKey: "AIzaSyDGoQ1iMyXQXpMH-uK-d3N_ttXA3DWlOCU",
        discoveryDocs: [
          "https://people.googleapis.com/$discovery/rest?version=v1",
        ],
      })
      .then(() => {
        window.gapi.client.load("people", "v1");
        this.gapiInited = true;
        this.maybeEnableButtons();
      });
  }

  maybeEnableButtons() {
    if (this.gapiInited && this.gisInited) {
      document.getElementById("authorize_button").style.visibility = "visible";
    }
  }

  async listConnectionNames() {
    let response;
    try {
      response = await window.gapi.client.people.otherContacts.list({
        readMask: "names,emailAddresses",
      });
    } catch (err) {
      console.log(err.message);
      return;
    }

    const connections = response.result.otherContacts;
    console.log("connections", connections);

    if (connections) {
      const ContactsValue = connections.map((person) => ({
        title: person.names?.[0]?.displayName || "No name found",
        email: person.emailAddresses?.[0]?.value || "No email found",
      }));
      console.log("ContactsValue", ContactsValue);
      this.responseCallback(ContactsValue);
    } else {
      console.log("No connections found.");
    }
  }

  handleAuthClick() {
    this.tokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        throw resp;
      }
      document.getElementById("authorize_button").innerText =
        "Import Google Contacts";
      await this.listConnectionNames();
    };

    if (window.gapi.client.getToken() === null) {
      this.tokenClient.requestAccessToken({ prompt: "consent" });
    } else {
      this.tokenClient.requestAccessToken({ prompt: "" });
    }
  }

  setPersonDtls = ({ original }) => {
    this.setState({
      personDtls: original,
    });
  };

  // responseCallback = (response) => {
  //   console.log(response);
  //   if (response && response.length > 0) {
  //     let reqDto = [];
  //     response.forEach((contact) => {
  //       console.log(contact);
  //       let contactDto = {
  //         contactEmailId: contact.email,
  //         contactName: contact.title,
  //       };
  //       reqDto.push(contactDto);
  //     });
  //     DashboardService.addContacts(
  //       reqDto,
  //       this.userDetails.userId
  //     ).then((response) => {
  //       console.log(response.data);
  //       if (response.data.returnValue === "1") {
  //         DashboardService.getContacts(this.userDetails.userId).then(
  //           (response) => {
  //             console.log(response.data);
  //             this.setState({
  //               data: response.data,
  //             });
  //             toast.success("Google Contacts imported Successfully", {
  //               position: toast.POSITION.TOP_RIGHT,
  //             });
  //           }
  //         );
  //       } else {
  //         toast.error("Please try again", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       }
  //     });
  //   }
  // };

  // responseCallbackFailure = (response) => {
  //   console.log(response);
  // };

  responseCallback = (response) => {
    console.log(response);
    toast.success("Importing contacts from google, please do not close", {
      position: toast.POSITION.TOP_RIGHT,
    });
    if (response && response.length > 0) {
      let reqDto = [];
      response.forEach((contact) => {
        console.log(contact);
        let contactDto = {
          contactEmailId: contact.email,
          contactName: contact.title,
        };
        reqDto.push(contactDto);
      });
      DashboardServices.addContacts(reqDto, this.userDetails.userId).then(
        (response) => {
          console.log(response.data);
          if (
            response.data.returnValue === "1" ||
            response.data.returnValue === "2"
          ) {
            DashboardServices.getContacts(this.userDetails.userId).then(
              (response) => {
                console.log(response.data);
                this.setState({
                  data: response.data,
                });
                toast.success("Google Contacts imported Successfully", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            );
          } else {
            toast.error("Please try again", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    }
  };

  responseCallbackFailure = (response) => {
    console.log(response);
  };

  isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  onSubmitContactAdd = (data) => {
    console.log(data);
    let isFormValid = true;
    let emailId = data.contactEmailId.toLowerCase().trim();
    if (data.contactName === "") {
      this.props.setError("contactName", {
        type: "custom",
        message: "Field required",
      });
      isFormValid = false;
    }

    if (emailId === "") {
      this.props.setError("contactEmailId", {
        type: "custom",
        message: "Field required",
      });
      isFormValid = false;
    }
    if (!this.isValidEmail(emailId)) {
      this.props.setError("contactEmailId", {
        type: "custom",
        message: "Invalid Mail Id",
      });
      isFormValid = false;
    }

    if (emailId === this.userDetails.userEmailId) {
      this.props.setError("contactEmailId", {
        type: "custom",
        message: "Same as current login id",
      });
      isFormValid = false;
    }

    if (!isFormValid) {
      this.setState({ loading: false });
      return;
    }

    this.setState({ loading: true });

    let reqDto = [];
    let contactDto = {
      contactEmailId: emailId,
      contactName: data.contactName,
    };
    reqDto.push(contactDto);
    console.log(reqDto);
    // return;

    DashboardServices.addContacts(reqDto, this.userDetails.userId).then(
      (response) => {
        console.log("addContacts", response.data);
        if (response.data.returnValue === "1") {
          this.props.reset({
            contactEmailId: "",
            contactName: "",
          });
          DashboardServices.getContacts(this.userDetails.userId).then(
            async (response) => {
              console.log("addContacts", response.data);
              let contactToSendInvite = null;
              // this.setData(response.data);
              response.data.forEach((contact) => {
                if (
                  contact.contactEmailId.toLowerCase().trim() ===
                  data.contactEmailId.toLowerCase().trim()
                ) {
                  contactToSendInvite = contact;
                }
              });
              await this.sendInvite(contactToSendInvite);
              this.setState({
                data: response.data,
              });
              this.setState({ loading: false });
              // toast.success("Contact added successfully", {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
            }
          );
        } else if (response.data.returnValue === "2") {
          toast.info("Contact exists already", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ loading: false });
        } else {
          toast.error("Please try again", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ loading: false });
        }
      }
    );
  };

  sendInvite = async (data) => {
    console.log("sendInvite", data);
    await DashboardServices.sendInvite(data, this.userDetails.userId).then(
      (response) => {
        console.log(response.data);

        DashboardServices.getContacts(this.userDetails.userId).then(
          (response) => {
            console.log(response.data);
            // this.setData(response.data);
            this.setState({
              data: response.data,
            });
            toast.success("Invite sent successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        );
      }
    );
  };

  deleteContact = (data) => {
    console.log("deleteContact", data);
    // return;
    DashboardServices.deleteContacts(this.userDetails.userId, data.id).then(
      (response) => {
        console.log(response.data);
        DashboardServices.getContacts(this.userDetails.userId).then(
          (response) => {
            console.log(response.data);
            this.setState({
              data: response.data,
            });
            toast.success("Contact deleted", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        );
      }
    );
  };

  render() {
    const { data } = this.state;
    console.log(data);
    const buttonStyle = {
      visibility: "visible",
      color: "#00adef",
      borderRadius: "4px",
      border: "1px solid #00adef",
      height: "40px",
      cursor: "pointer",
    };
    if (true) {
      return (
        <div className="contacts">
          <Box component={"form"}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="addContactGroup">
                  <div className="addContactElementGrp hideMobile">
                    <div className="formElement with240">
                      <FormControl className="formControl">
                        <TextField
                          error={this.props.errors.contactName}
                          helperText={
                            this.props.errors.contactName &&
                            this.props.errors.contactName.message
                          }
                          size="small"
                          {...this.props.register("contactName")}
                          className="modelTextFild"
                          label="Contact Name"
                          required
                          variant="outlined"
                          multiline
                          fullWidth
                        />
                      </FormControl>
                    </div>
                    <div className="formElement with240">
                      <FormControl className="formControl">
                        <TextField
                          error={this.props.errors.contactEmailId}
                          helperText={
                            this.props.errors.contactEmailId &&
                            this.props.errors.contactEmailId.message
                          }
                          size="small"
                          {...this.props.register("contactEmailId")}
                          className="modelTextFild"
                          label="Contact Email Id"
                          variant="outlined"
                          multiline
                          required
                          fullWidth
                          inputProps={{ style: { textTransform: "lowercase" } }}
                        />
                      </FormControl>
                    </div>

                    <div className="addContElementBtn">
                      <Button
                        startIcon={<Send />}
                        className="dfultPrimaryBtn primaryBtn contactsBtn"
                        onClick={this.props.handleSubmit(
                          this.onSubmitContactAdd
                        )}
                        disabled={this.state.loading}
                      >
                        {this.state.loading && "Please Wait..."}
                        {!this.state.loading && "Send Invite"}
                      </Button>
                    </div>
                  </div>
                  <div className="syncGoogleCont">
                    <Button
                      className="AddContactMobBtn"
                      startIcon={<PersonFillAdd />}
                      onClick={this.toggleMobaddcontct}
                    >
                      Add Contact
                    </Button>
                    <GoogleContacts
                      clientId="806760841521-j7f6o10atmhib5da5q498pbkcoprtrgl.apps.googleusercontent.com"
                      buttonText="Import"
                      onSuccess={this.responseCallback}
                      onFailure={this.responseCallbackFailure}
                      className="googleContactsGrid"
                    />
                    {/* <button id="authorize_button" onClick={this.handleAuthClick}  style={buttonStyle} type="button">
                    Import Google Contacts
                  </button> */}
                  </div>
                </div>
                {this.state.showMobAddContacts && (
                  <>
                    <div className="addContactElementGrp">
                      <div className="formElement with240">
                        <FormControl className="formControl">
                          <TextField
                            error={this.props.errors.contactName}
                            helperText={
                              this.props.errors.contactName &&
                              this.props.errors.contactName.message
                            }
                            size="small"
                            {...this.props.register("contactName")}
                            className="modelTextFild"
                            label="Contact Name"
                            required
                            variant="outlined"
                            multiline
                            fullWidth
                          />
                        </FormControl>
                      </div>
                      <div className="formElement with240">
                        <FormControl className="formControl">
                          <TextField
                            error={this.props.errors.contactEmailId}
                            helperText={
                              this.props.errors.contactEmailId &&
                              this.props.errors.contactEmailId.message
                            }
                            size="small"
                            {...this.props.register("contactEmailId")}
                            className="modelTextFild"
                            label="Contact Email Id"
                            variant="outlined"
                            multiline
                            required
                            fullWidth
                            inputProps={{
                              style: { textTransform: "lowercase" },
                            }}
                          />
                        </FormControl>
                      </div>

                      <div className="addContElementBtn">
                        <Button
                          startIcon={<Send />}
                          className="dfultPrimaryBtn primaryBtn contactsBtn"
                          onClick={this.props.handleSubmit(
                            this.onSubmitContactAdd
                          )}
                          disabled={this.state.loading}
                        >
                          {this.state.loading && "Please Wait..."}
                          {!this.state.loading && "Send Invite"}
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </Grid>

              {/* <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  error={this.props.errors.contactName}
                  helperText={
                    this.props.errors.contactName &&
                    this.props.errors.contactName.message
                  }
                  size="small"
                  {...this.props.register("contactName")}
                  label="Contact Name*"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  error={this.props.errors.contactEmailId}
                  helperText={
                    this.props.errors.contactEmailId &&
                    this.props.errors.contactEmailId.message
                  }
                  size="small"
                  {...this.props.register("contactEmailId")}
                  label="Contact Email Id*"
                  fullWidth
                  inputProps={{ style: { textTransform: "lowercase" } }}
                />
              </Grid> 
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Button
                  className="primaryBtn contactsBtn"
                  onClick={this.props.handleSubmit(this.onSubmitContactAdd)}
                  disabled={this.state.loading}
                >
                  {this.state.loading && "Please Wait..."}
                  {!this.state.loading && "Send Invite"}
                </Button>
              </Grid> 
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <GoogleContacts
                  clientId="791849580758-02itp5peod9g01s5bapsku3621stqc6r.apps.googleusercontent.com"
                  buttonText="Import"
                  onSuccess={this.responseCallback}
                  onFailure={this.responseCallbackFailure}
                  className="googleContactsGrid"
                />
              </Grid> */}
            </Grid>
          </Box>
          <ReactTable
            data={data}
            columns={[
              {
                id: "contactName",
                Header: "Contact Name",
                accessor: (row) => `${row.contactName}`,
                filterMethod: (filter, row) =>
                  row._original.contactName
                    .toUpperCase()
                    .startsWith(filter.value.toUpperCase()),
              },
              {
                id: "contactEmailId",
                Header: "Email Id",
                accessor: (row) => `${row.contactEmailId}`,
                filterMethod: (filter, row) =>
                  row._original.contactEmailId
                    .toUpperCase()
                    .startsWith(filter.value.toUpperCase()),
              },

              {
                Header: "Added/Not Added",
                id: "contactEmailId2",
                accessor: (row) =>
                  `${row.inviteStatus === "P" ? "Not Added" : "Added"}`,
                Cell: (props) => (
                  <Tooltip
                    title={
                      props.original.inviteStatus === "P" &&
                      props.original.inviteLink != null
                        ? `Last Invite (${new Date(props.original.inviteDate)})`
                        : ""
                    }
                  >
                    <>
                      {props.original.inviteStatus === "P" && "Not Added"}
                      {props.original.inviteStatus === "A" && (
                        <>
                          <span className="addedRTG">
                            Added <PatchCheck />
                          </span>
                        </>
                      )}
                      {props.original.inviteStatus === "P" && (
                        <Button
                          startIcon={<Send />}
                          className="primaryBtn inviteBtn"
                          onClick={() => {
                            console.log("Header-->", props);
                            this.sendInvite(props.original);
                          }}
                          sx={{
                            cursor:
                              props.original.inviteStatus === "P"
                                ? "pointer"
                                : "default",
                          }}
                        >
                          {props.original.inviteLink == null && "Send Invite"}
                          {props.original.inviteLink != null &&
                            `Send Invite Again`}
                        </Button>
                      )}
                    </>
                  </Tooltip>
                ), // Custom cell components!
                filterMethod: (filter, row) => {
                  console.log(filter.value);
                  if (filter.value.toUpperCase().startsWith("A")) {
                    return row._original.inviteStatus !== "P";
                  } else if (filter.value.toUpperCase().startsWith("N")) {
                    return row._original.inviteStatus === "P";
                  }
                },

                // sortType: (a, b) => {
                //   // console.log(a, b);
                //   if (a < b) {
                //     return -1;
                //   }
                //   if (a > b) {
                //     return 1;
                //   }
                //   return 0;
                // },
              },
              {
                id: "deleteIcon",
                Header: "Delete",
                width: 100,
                filterable: false,
                sortable: false,
                Cell: (props) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <IconButton
                        className="trashIconBtn"
                        onClick={() => {
                          this.deleteContact(props.original);
                        }}
                      >
                        <Trash />
                      </IconButton>
                    </div>
                  </>
                ),
              },
            ]}
            defaultSorted={[
              {
                id: "id",
                desc: false,
              },
            ]}
            filterable={true}
            // defaultFiltered={[
            //     {
            //     "id": "firstName",
            //     "value": "acc"
            //     }]}
            onFilteredChange={(filtered) => this.setState({ filtered })}
            defaultPageSize={5}
            className="-striped -highlight"
          />
          <br />
          {/* {this.state.personDtls != null && (
            <Modal
              open={this.state.personDtls != null}
              onClose={() => {
                this.setState({ personDtls: null });
              }}
              aria-labelledby="modal-modal-title-all-persons"
              aria-describedby="modal-modal-description-all-persons"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "50%",
                  //height:'80vh',
                  transform: "translate(-50%,  -50%)",
                  bgcolor: "background.paper",
                  border: "1px solid #000",
                  borderRadius: "5px",
                  boxShadow: 24,
                  p: 2,
                }}
              >
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  mb={2}
                >
                  {this.state.personDtls.firstName}{" "}
                  {this.state.personDtls.middleName}{" "}
                  {this.state.personDtls.lastName}
                </Typography>

                {<PersonDtls personDetails={this.state.personDtls} />}
              </Box>
            </Modal>
          )} */}
        </div>
      );
    } else {
      return <Skeleton />;
    }
  }
}

export default Contacts;
