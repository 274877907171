import {
  ArrowBackIosNew,
  ArrowForwardIos,
  Audiotrack,
  Cancel,
  DescriptionOutlined,
  Pause,
  VideoCall,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import { CheckCircle, Plus, Refresh, Send } from "iconoir-react";
import React, { useEffect, useRef, useState } from "react";
import { People, Play, XLg } from "react-bootstrap-icons";
import DashboardServices from "../../../../services/DashboardServices";
import { toast } from "react-toastify";
import { convertFileToBase64_Split } from "../../../../utils/FileUtils";
import {
  clearSelectedTask,
  setSelectedTask,
} from "../../../../redux/reducers/rightSideTaskPanelSlice";
import ForwardTaskModal from "./ForwardTaskModal";
import LinkTaskModal from "./LinkTaskModal";
import DisagreeTask from "./DisagreeTask";
import RightFlotingContainer from "./RightFlotingContainer";
import SignOff from "./SignOff";
import { getFormattedDate, getLoginUserDetails } from "../../../../utils/Utils";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import ResumeModal from "./ResumeModal";
import AssigneeModal from "./AssigneeModal";
import TemplateList from "./TemplateList";
import ReopenModal from "./ReopenModal";
import CloseTask from "./CloseTask";
import FileList from "./FileList";
import RightTaskFileUpload from "./RightTaskFileUpload";
import { useTimeProgressor } from "./hooks/useTimerProgressor";
import { useTranslation } from "react-i18next";

const TaskReopenPostponed = ({ data, refreshData, removeFromStore }) => {
  const { t } = useTranslation();
  console.log("************ postpone task *************");
  const [showFileModal, setShowFileUploadModal] = useState(false);

  const dispatch = useDispatch();
  const loginUserDetail = getLoginUserDetails();
  const fileInputRef = useRef(null);

  const dateRemaning = useTimeProgressor(data.createdAt, data.completionTime);

  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);

  const [showAddressConcern, setShowAddressConcern] = useState(false);
  const [addressConcernRemark, setAddressConcernRemark] = useState("");

  const [assigneeList, setAssigneeList] = useState([]);
  const [newAssigneeList, setNewAssigneeList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [reassignUser, setReassignUser] = useState(null);

  const [showForwardTask, setShowForwardTask] = useState(false);
  const [forwardReason, setForwardReason] = useState("");

  const [showCreateLinkTask, setShowCreateLinkTask] = useState(false);
  const [linkTaskDescription, setLinkTaskDescription] = useState("");
  const [showLinkFileUpload, setShowFileUploadDialog] = useState(false);
  const [linkDocuments, setLinkDocuments] = useState([]);

  const [tempOfOrgList, setTemplateOfOrgList] = useState([]);
  const [addedTemplateList, setAddedTemplateList] = useState([]);
  const [recommendedTemplateList, setRecommendedTemplateList] = useState([]);
  const [showAddTemplate, setShowAddTemplate] = useState(false);

  const [anchorUl, setAnchorUl] = React.useState(null);
  const openUl = Boolean(anchorUl);
  const ulId = openUl ? "simple-popover" : undefined;
  const fileDocuments = useRef(null);
  const commentInputRef = useRef();
  const [showAssigneeList, setShowAssigneeList] = useState(false);

  const [showDisagreeModal, setShowDisAgreeModal] = useState(false);

  const [showReassignTask, setShowReassignTask] = useState(false);

  const numberList = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const [tempUpdatePercent, setTempUpdatePercent] = useState(0);

  const [reassignReason, setReassignReason] = useState("");
  const [showResumeModal, setShowResumeModal] = useState(false);
  const [showAudioModal, setShowAudioModal] = useState(false);
  const audioUrl = useRef();
  const [showReopenModal, setShowReopenModal] = useState(false);
  const [showCancelTask, setShowCancelTask] = useState(false);
  const [showCloseTask, setShowCloseTask] = useState(false);

  const handleCloseUl = () => {
    setAnchorUl(null);
  };

  const hasPassed = () => {
    console.log("date =", data.postponedTill);

    const parts = data.postponedTill.split("-");
    const dateObject = new Date(parts[2], parts[1] - 1, parts[0]);
    const targetDate = dayjs(dateObject);
    const currentDate = dayjs();
    const hasPassed =
      targetDate.isBefore(currentDate) || targetDate.isSame(currentDate);
    return hasPassed;
  };

  const handleClickUl = (event) => {
    setAnchorUl(event.currentTarget);
  };

  const handleAddnewFile = () => {
    fileInputRef.current.click();
  };

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  const fileElementRef = useRef(null);
  const [filearrowDisable, setFilearrowDisable] = useState(true);
  const filehandleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setFilearrowDisable(true);
      } else {
        setFilearrowDisable(false);
      }
    }, speed);
  };

  const [fileDivWidth, setFileDivWidth] = useState(0);

  useEffect(() => {
    if (fileElementRef.current) {
      setFileDivWidth(fileElementRef.current.offsetWidth);
    }
  }, [fileElementRef]);

  const [tempDivWidth, setTempDivWidth] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  const handleAddTemplate = async () => {
    const value = await DashboardServices.getAllTemplatesOfOrganization(
      loginUserDetail.orgId
    );
    setTemplateOfOrgList(value.data);

    const response = await DashboardServices.getTemplatesOfSubActivity(
      data.activityId,
      0
    );
    let notaddedTemplateList = [];
    if (response.data != null && response.data.length > 0) {
      response.data.forEach((template) => {
        let isTemplateAdded = false;
        addedTemplateList.forEach((addedTemplate) => {
          if (template.templateId === addedTemplate.templateId) {
            isTemplateAdded = true;
          }
        });
        if (!isTemplateAdded) {
          notaddedTemplateList.push(template);
        }
      });
      setRecommendedTemplateList(notaddedTemplateList);
    }
    console.log("hello");
    setShowAddTemplate(true);
  };

  const handleSubmitReassignTask = (e) => {
    console.log("user id=", reassignUser);
    const reqdto = {
      taskDetailId: data.taskId,
      toUserId: reassignUser.userId,
      reassignReason: reassignReason,
      progress: 0,
    };

    DashboardServices.reassignTask(loginUserDetail.userId, reqdto).then(
      (response) => {
        if (response.data.returnValue === "1") {
          toast.success(t("task_successfully_reassigned"));
          dispatch(clearSelectedTask());
          refreshData();
        } else {
          toast.error(t("failed_to_reassign_the_task_please_try_again"));
        }
      }
    );
  };

  const sendComment = (e) => {
    if (!data) {
      toast.error(t("something_went_wrong"));
      return;
    }
    const comment = commentInputRef.current.value;
    const reqDto = {
      taskDetailId: data.taskId,
      progress: data.progress,
      comments: comment,
      referenceId: 0,
      commentsType: null,
    };
    console.log("reqDto = ", reqDto);
    const commentsAt = new Date().toISOString();
    // return;
    if (comment.trim() !== "") {
      DashboardServices.sendTaskComments(loginUserDetail.userId, reqDto).then(
        (response) => {
          console.log("out response data= ", response);
          if (response.data == 1) {
            const tempData = { ...data };
            tempData.comments = [
              ...tempData.comments,
              {
                comments: comment,
                commentsType: null,
                commentsByUserName: loginUserDetail.userName,
                commentsByUserProfileUrl: loginUserDetail.userProfileImage,
                progress: data.progress,
                commentsAt: commentsAt,
              },
            ];
            dispatch(setSelectedTask(tempData));
            commentInputRef.current.value = "";
          } else {
            toast.error(t("something_went_wrong"));
          }
        }
      );
    }
  };

  const getFilteredUser = () => {
    const taskCreator = data.assignByUserId;

    let filteredData = userList.filter((item) => {
      return (
        item.userId !== loginUserDetail.userId || item.userId !== taskCreator
      );
    });
    console.log("filtered data = ", assigneeList);

    return filteredData;
  };

  const clearAllModals = () => {
    setShowResumeModal(false);
    setShowReopenModal(false);
    setShowCancelTask(false);
    setShowAssigneeList(false);
  };

  const getFilteredData = () => {
    const taskCreator = data.assignByUserId;

    let filteredData = userList.filter((item) => {
      return (
        item.userId !== loginUserDetail.userId || item.userId !== taskCreator
      );
    });
    console.log("filtered data = ", assigneeList);

    filteredData = filteredData.filter(
      (item) => !assigneeList.some((assignee) => assignee.id === item.userId)
    );
    return filteredData;
  };

  const handleChangeFile = async (fileList) => {
    const reqDto = fileList.map((file) => ({
      id: 0,
      taskDtlId: data.taskId,
      fileName: file.fileName,
      fileDataLink: file.fileDataLink,
      uploadedBy: loginUserDetail.userId,
      fileDisplayName: file.fileName,
    }));

    DashboardServices.uploadDocuments(
      loginUserDetail.orgId,
      loginUserDetail.userId,
      data.taskId,
      reqDto
    ).then((response) => {
      console.log("document sent is ", response.data);
      if (response.data) {
        const tempData = { ...data };
        if (tempData.documents === null) {
          tempData.documents = [...response.data];
        } else {
          tempData.documents = [...data.documents, ...response.data];
        }
        dispatch(setSelectedTask(tempData));
      }
    });
    console.log("file document = ", fileDocuments.current);
  };

  const handleSubmitTransferTask = () => {
    if (forwardReason.trim() === "") {
      toast.error("Please Enter Transfer Reason", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (setSelectedUsers.length === 0) {
      toast.error("Please Select User", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else {
      const reqDto = {
        taskDetailId: data.taskId,
        fromUserId: loginUserDetail.userId,
        toUserId: selectedUsers.userId,
        transferReason: forwardReason,
      };
      console.log("submit ", reqDto);
      // setLoading(true);
      DashboardServices.transferTask(reqDto).then((response) => {
        if (response.data) {
          if (response.data.returnValue === "1") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setForwardReason("");
            setUserList(null);
            setShowForwardTask(false);
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    }
  };

  const handleSubmitCreateLinkTask = () => {
    let validTask = true;

    if (linkTaskDescription === "") {
      toast.error("Please Provide Task Description ");
      validTask = false;
    }
    if (selectedUsers.length === 0) {
      toast.error("Please Select Users");
      validTask = false;
    }
    if (validTask === false) {
      return;
    }
    console.log("link value = ", data);

    const submitDto = {
      taskMasterId: parseInt(data.activityId),
      subTask: data.activityId,
      scheduleCompletionTime: "2024-01-30T14:30:00.136Z",
      taskDescription: linkTaskDescription,
      priority: data.priority,
      assigness: selectedUsers && selectedUsers.map((user) => user.userId),
      documents: linkDocuments,
      previousTaskId: parseInt(data.taskId),
      contractId: null,
      formId: "",
      formDataTransId: "",
    };

    try {
      DashboardServices.createLinkTask(
        loginUserDetail.userId,
        loginUserDetail.orgId,
        submitDto
      ).then((response) => {
        // console.log(response.data);
        if (response.data) {
          if (response.data.returnValue == "1") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            // setLinkTask("");
            setLinkTaskDescription("");
            setLinkDocuments([]);
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
      // handelCloseOpenCreateSubTask();
    }
  };

  useEffect((e) => {
    DashboardServices.getAssigneesOfTask(data.taskId).then((response) => {
      setAssigneeList(response.data);
    });

    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        console.log("all contract  = ", response.data);
        setUserList(response.data);
      }
    );
  }, []);

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskControlBtnGrp">
              {!hasPassed(data.postponedTill) && (
                <div className="taskVDContBtn">
                  <Button
                    disabled
                    variant="contained"
                    startIcon={<Pause />}
                    // onClick={(e) => {
                    //   clearAllModals();
                    //   setShowResumeModal(true);
                    // }}
                  >
                     {t('resumes_on_postpone', { postponedTill: data.postponedTill })}
                  </Button>
                </div>
              )}
              {hasPassed(data.postponedTill) && (
                <>
                  <div className="taskVDContBtn">
                    <Button
                      variant="contained"
                      startIcon={<Pause />}
                      onClick={(e) => {
                        clearAllModals();
                        setShowReopenModal(true);
                      }}
                    >
                      {t("re_open")}
                    </Button>
                  </div>
                  <div className="taskVDContBtn">
                    <Button
                      variant="contained"
                      startIcon={<Cancel />}
                      onClick={(e) => {
                        clearAllModals();
                        
                        setShowCancelTask(true);
                      }}
                    >
                      {t("cancel")}
                    </Button>
                  </div>
                </>
              )}
              <div className="taskVDContBtn"></div>
            </div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn">
                <IconButton
                  aria-label="User"
                  onClick={(e) => {
                    // setOpenUserList(true);
                    // handleClickUl(e);
                    clearAllModals();
                    setShowAssigneeList(true);
                  }}
                >
                  <Tooltip title={t("user")} arrow>
                    <People />
                  </Tooltip>
                </IconButton>
                <Popover
                  id={ulId}
                  open={openUl}
                  anchorEl={anchorUl}
                  onClose={handleCloseUl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <ul>
                    {assigneeList &&
                      assigneeList.map((assignee) => <li>{assignee.name}</li>)}
                  </ul>
                  {/* <Typography sx={{ p: 2 }}>
                      The content of the Popover.
                    </Typography> */}
                </Popover>
              </div>

              <div className="taskActBtn closeBtnD10">
                <IconButton
                  aria-label="close"
                  onClick={(e) => {
                    dispatch(clearSelectedTask());
                  }}
                >
                  <Tooltip title={t("close")} arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                <div className="taskTitleContrct">
                  {/* <div className="taskID">PT0023</div> */}
                  <div className="taskTitleNM">{data && data.taskName}</div>
                </div>
                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("description")}</div>
                  <div className="tskElemntInfo">
                    {data && data.taskDescription}
                  </div>
                </div>

                {data && data.audioNoteFileName && (
                  <div className="taskElementGrp">
                    <div className="tskElemntInfo">
                      <div className="playAudioNotes">
                        <IconButton
                          variant="outlined"
                          onClick={(e) => {
                            DashboardServices.generatePreSignedUrlS3Object(
                              data.audioNoteFileName,
                              loginUserDetail.orgId
                            ).then((response) => {
                              audioUrl.current = response.data;
                              setShowAudioModal(true);
                            });
                          }}
                        >
                          <Play />
                        </IconButton>
                        <span>{t("play_audio_message")}</span>
                      </div>
                    </div>
                  </div>
                )}
                {data &&
                  data.contractName &&
                  data.contractName.trim() !== "" && (
                    <div className="taskElementGrp">
                      <div className="tskElemHeding">{t("contract")}</div>
                      <div className="taskContractNm">
                        {data && data.contractName}
                      </div>
                    </div>
                  )}

                {/* <div className="taskElementGrp">
                    <div className="tskElemHeding">Status</div>
                    <div className="tskTimeline">
                      <div className="tskTimBar">
                        <div className="progress">
                          <div
                            // ps50 ps80 ps100
                            className="progress-done ps50"
                            style={{ width: `${progressWidth}%`, opacity: 1 }}
                          >
                            <span className="pwCount">{progressWidth}%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("timeline")}</div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        <div
                          // up50 up80 up100
                          className={`progress-done ${
                            data && dateRemaning < 50
                              ? "up50"
                              : dateRemaning < 80
                              ? "up80"
                              : "up100"
                          }`}
                          style={{ width: `${dateRemaning}%`, opacity: 1 }}
                        ></div>
                      </div>
                    </div>
                    <div className="tskDtSE">
                      <div className="tskDTStart">
                        {getFormattedDate(data.createdAt)}
                      </div>
                      <div className="tskDTEnd">
                        {getFormattedDate(data.completionTime)}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Templates start */}

                {/* <div className="taskElementGrp">
                    <div className="tskElemHeding">Template</div>
  
                    <div class="elementFileListedGrp">
                      <div className="horaizonScroll">
                        <IconButton
                          className="goPrevousBtn"
                          onClick={() => {
                            handleHorizantalScroll(
                              elementRef.current,
                              25,
                              200,
                              -10
                            );
                          }}
                          disabled={arrowDisable}
                        >
                          <ArrowBackIosNew />
                        </IconButton>
                        <div class="elemntFilsgrp" ref={elementRef}>
                          {data &&
                            data.templates.map((template) => (
                              <div className="elemntFile">
                                <Tooltip
                                  title={template.fileDisplayName}
                                  arrow
                                  className="elmFileTolTip"
                                >
                                  <span className="elemntFilIoc">
                                    <DescriptionOutlined />
                                  </span>
                                  <span className="elemntFilNm">
                                    {template.fileDisplayName}
                                  </span>
                                </Tooltip>
                              </div>
                            ))}
                        </div>
                        {tempDivWidth > 270 && (
                          <IconButton
                            className="goNextBtn"
                            onClick={() => {
                              handleHorizantalScroll(
                                elementRef.current,
                                25,
                                200,
                                10
                              );
                            }}
                          >
                            <ArrowForwardIos />
                          </IconButton>
                        )}
                      </div>
                      <div class="elmntAddMrFiles">
                        <Button
                          className="elemntMrFileBtn"
                          variant="outlined"
                          onClick={handleAddTemplate}
                        >
                          <Plus />
                        </Button>
                      </div>
                    </div>
                  </div> */}

                {/* Templates end */}
                <TemplateList
                  data={data}
                  tempDivWidth={tempDivWidth}
                  handleAddTemplate={handleAddTemplate}
                />

                {/* files start */}

                {/* <div className="taskElementGrp">
                    <div className="tskElemHeding">Files</div>
  
                    <div class="elementFileListedGrp">
                      <div className="horaizonScroll">
                        <IconButton
                          className="goPrevousBtn"
                          onClick={() => {
                            filehandleHorizantalScroll(
                              fileElementRef.current,
                              25,
                              200,
                              -10
                            );
                          }}
                          disabled={filearrowDisable}
                        >
                          <ArrowBackIosNew />
                        </IconButton>
  
                        <div class="elemntFilsgrp" ref={fileElementRef}>
                          {data &&
                            data.documents &&
                            data.documents.map((doc) => (
                              <div className="elemntFile">
                                <Tooltip
                                  title={doc.fileDisplayName}
                                  arrow
                                  className="elmFileTolTip"
                                >
                                  <span className="elemntFilIoc">
                                    <DescriptionOutlined />
                                  </span>
                                  <span className="elemntFilNm">
                                    {doc.fileDisplayName}
                                  </span>
                                </Tooltip>
                              </div>
                            ))}
                        </div>
                        {fileDivWidth > 370 && (
                          <IconButton
                            className="goNextBtn"
                            onClick={() => {
                              filehandleHorizantalScroll(
                                fileElementRef.current,
                                25,
                                200,
                                10
                              );
                            }}
                          >
                            <ArrowForwardIos />
                          </IconButton>
                        )}
                      </div>
                      <div class="elmntAddMrFiles">
                        <Button
                          className="elemntMrFileBtn"
                          variant="outlined"
                          onClick={handleAddnewFile}
                        >
                          <Plus />
                        </Button>
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleChangeFile}
                          style={{ display: "none" }}
                        ></input>
                      </div>
                    </div>
                  </div> */}
                <FileList
                  data={data}
                  tempDivWidth={tempDivWidth}
                  handleChangeFile={handleChangeFile}
                  setShowFileUploadModal={setShowFileUploadModal}
                />

                {/* files end */}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("priority")}</div>
                  <div className="tskPrioArea">
                    {/* statUrgent, statNormal, statImportant  used these three for diffrent priority */}
                    <div
                      class={`tskPriot  ${
                        data.priority === 0
                          ? "statNormal"
                          : data.priority === 1
                          ? "statImportant"
                          : "statUrgent"
                      }`}
                    >
                      <div class="priStat">
                        {data.priority === 0
                          ? t("regular")
                          : data.priority === 1
                          ? t("important")
                          : t("critical")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("activity")}</div>
                    <div className="tskElemntInfo">
                      {data && data.activityName}
                    </div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  {/* <div className="taskElementGrp">
                      <div className="tskElemHeding">Assigned to</div>
                      <div className="tskElemntInfo">
                        {data && data.assignByUserName}
                      </div>
                    </div> */}
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("postpone_by")}</div>
                    <div className="tskElemntInfo">
                      {data && data.approveRejectConcernedPostponedByUserName}
                    </div>
                  </div>
                </div>
                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("postpone_till")}</div>
                    <div className="tskElemntInfo">
                      {data && data.postponedTill}
                    </div>
                  </div>
                </div>
                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("postpone_reason")}</div>
                    <div className="tskElemntInfo">
                      {data && data.postponedReason}
                    </div>
                  </div>
                </div>

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("last_updated")}</div>
                  <div className="tskElemntInfo">
                    {data &&
                      data.comments &&
                      data.comments.length &&
                      `${
                        data.comments[data.comments.length - 1]
                          .commentsByUserName
                      } - ${getFormattedDate(
                        data.comments[data.comments.length - 1].commentsAt
                      )}`}
                  </div>
                </div>

                {/* <div className="taskElementGrp">
                    <div className="tskElemHeding">Description</div>
                    <div className="tskElemntInfo">
                      Installing Adobe Creative Suite on my machine is crucial for
                      maximizing efficiency and ensuring seamless collaboration
                      within our creative projects.
                    </div>
                  </div> */}

                <div className="taskUpCommentGrp">
                  {data &&
                    data.comments.length > 0 &&
                    data.comments.map((comment) => (
                      <div className="taskUserComment">
                        <div className="tskUderImgDT">
                          <div className="tskUseComImg">
                            <img
                              src={comment.commentsByUserProfileUrl}
                              alt=""
                            />
                          </div>
                          <div className="tskUsrCNM">
                            <div className="tskUCNM">
                              {comment.commentsByUserName}
                            </div>
                            <div className="tskUCDT">
                              {comment && getFormattedDate(comment.commentsAt)}
                            </div>
                          </div>
                        </div>
                        <div className="taskUPComnt">{comment.comments}</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="taskFooterPanel">
              <div className="tskInputGrp">
                <input
                  ref={commentInputRef}
                  type="text"
                  className="tskComntInput"
                  placeholder="Update comment here"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      console.log("Enter key pressed");
                      sendComment(event);
                    }
                  }}
                />
                <IconButton className="tskComtSndBtn" onClick={sendComment}>
                  <Send />
                </IconButton>
              </div>
            </div> */}
        </div>
        {showAddressConcern && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("address_concern")}</div>

              <div className="modInputFild">
                <TextField
                  id="outlined-basic"
                  label={t("concern_remark")}
                  variant="outlined"
                  value={addressConcernRemark}
                  onChange={(e) => setAddressConcernRemark(e.target.value)}
                />
              </div>

              {/* <div className="formElement">
                  <FormControl className="formControl">
                    <Autocomplete
                      id="adduser-select"
                      className="formAutocompleteField"
                      disableClearable
                      variant="outlined"
                      options={getFilteredData()}
                      getOptionLabel={(option) => option.userName}
                      getOptionSelected={(option) => {
                        console.log("option is ", option);
                        return option.userId;
                      }}
                      value={selectedUsers}
                      onChange={(e, newValue) => {
                        console.log("new value = ", newValue);
                        setSelectedUsers(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="formAutoComInputField"
                          placeholder="Search User"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div> */}

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={(e) => {
                    if (addressConcernRemark.trim() === "") {
                      toast.error("Please provide your concern response");
                      return;
                    }

                    // const reqDto = {
                    //   comments: addressConcernRemark,
                    //   taskDetailId: data.taskId,
                    //   // progress: data.progress,
                    //   orgId: loginUserDetail.orgId,
                    //   previousAssigness:
                    //     assigneeList && assigneeList.length > 0
                    //       ? assigneeList.map((item) => item.id)
                    //       : [],
                    //   currentAssigness:
                    //     selectedUsers.length > 0
                    //       ? selectedUsers
                    //       : assigneeList.map((item) => item.id),
                    // };

                    DashboardServices.addressConcernOfTask(
                      data.taskId,
                      loginUserDetail.userId,
                      addressConcernRemark
                    ).then((response) => {
                      if (response.data && response.data.returnValue === "1") {
                        toast.success(response.data.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                        setShowAddressConcern(false);
                        dispatch(clearSelectedTask());
                      } else {
                        toast.error(response.data.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      }
                      setAddressConcernRemark("");
                      refreshData();
                      // setSelectedUsers([]);
                    });
                  }}
                >
                  {t("submit")}
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowAddressConcern(false);
                    setAddressConcernRemark("");
                    setSelectedUsers([]);
                  }}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        )}

        {/* {showForwardTask && (
            <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
              <div className="addMoreAgendaModel">
                <div className="modelTitle">Forward Task</div>
                <div
                  className="modInputFild"
                  style={{ border: "1px solid black" }}
                >
                  <TextField
                    sx={{ color: "balck !important" }}
                    label="Forward Remark"
                    variant="outlined"
                    multiline
                    rows={4}
                    maxRows={7}
                    value={forwardReason}
                    onChange={(e) => setForwardReason(e.target.value)}
                  />
                </div>
  
                <div className="formElement">
                  <FormControl className="formControl">
                    <Autocomplete
                      id="adduser-select"
                      disableClearable
                      className="formAutocompleteField"
                      variant="outlined"
                      options={getFilteredUser()}
                      getOptionLabel={(option) => option.userName}
                      getOptionSelected={(option) => {
                        console.log("option is ", option);
                        return option.userId;
                      }}
                      onChange={(e, newValue) => {
                        console.log("new value = ", newValue);
                        setSelectedUsers(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="formAutoComInputField"
                          placeholder="Search User"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>
  
                <div className="modActionBtnGrp">
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={(e) => {
                      handleSubmitTransferTask();
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={() => {
                      setShowForwardTask(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Box>
          )} */}
        {showForwardTask && (
          <ForwardTaskModal
            data={data}
            userList={userList}
            refreshData={refreshData}
            handleClose={() => {
              setShowForwardTask(false);
            }}
          />
        )}

        {showReassignTask && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("reassing_task")}</div>

              <div
                className="modInputFild"
                style={{ padding: "20px 10px 10px 0px" }}
              >
                {t("select_your_progress_of_task")}
              </div>
              <div className="workUpdateBtnGrp">
                {numberList &&
                  numberList.map((number) => (
                    <Button
                      className={`${
                        tempUpdatePercent === number ? "activeStatus" : ""
                      }`}
                      variant="contained"
                      // disabled={number <= updatePercentValue}
                      onClick={(e) => {
                        setTempUpdatePercent(number);
                        console.log("");
                      }}
                    >
                      {number}
                    </Button>
                  ))}
              </div>

              <div
                className="modInputFild"
                style={{ border: "1px solid black" }}
              >
                <TextField
                  sx={{ color: "balck !important" }}
                  label={t("description")}
                  variant="outlined"
                  multiline
                  rows={4}
                  maxRows={7}
                  value={reassignReason}
                  onChange={(e) => setReassignReason(e.target.value)}
                />
              </div>

              <div className="modInputFile">
                <Autocomplete
                  id="adduser-select"
                  disableClearable
                  className="formAutocompleteField"
                  variant="outlined"
                  options={userList}
                  getOptionLabel={(option) => option.userName}
                  getOptionSelected={(option) => {
                    console.log("option is ", option);
                    return option.userId;
                  }}
                  onChange={(e, newValue) => {
                    console.log("new value = ", newValue);
                    setReassignUser(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "black" }}
                      {...params}
                      placeholder={t("search_user")}
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                />
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={(e) => {
                    handleSubmitReassignTask();
                  }}
                >
                  {t("submit")}
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowReassignTask(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        )}

        {showResumeModal && (
          <ResumeModal
            data={data}
            refreshData={refreshData}
            handleClose={(e) => setShowResumeModal(false)}
          />
        )}

        {showCreateLinkTask && (
          <LinkTaskModal
            userList={userList}
            data={data}
            handleClose={(e) => setShowCreateLinkTask(false)}
            refreshData={refreshData}
            removeFromStore={removeFromStore}
          />
        )}

        {showDisagreeModal && (
          <DisagreeTask
            assignByUserId={data.assignByUserId}
            userList={userList}
            onClose={() => {
              setShowDisAgreeModal(false);
            }}
            refreshData={refreshData}
            taskDetailId={data.taskId}
          />
        )}

        {showAssigneeList && (
          <AssigneeModal
            assigneeList={assigneeList}
            handleClose={(e) => setShowAssigneeList(false)}
          />
        )}

        {showAudioModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("audio_message")}</div>
              <div className="modActionBtnGrp">
                <audio controls>
                  <source src={`${audioUrl.current}`} />
                </audio>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  {t("ok")}
                </Button>
                {/* <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={() => {
                      setShowAudioModal(false);
                    }}
                  >
                    Ok
                  </Button> */}
              </div>
            </div>
          </Box>
        )}

        {showReopenModal && (
          <ReopenModal
            taskId={data.taskId}
            handleClose={(e) => {
              setShowReopenModal(false);
            }}
            refreshData={refreshData}
          />
        )}

        {showCloseTask && (
          <CloseTask
            taskId={data.taskId}
            refreshData={refreshData}
            handleClose={(e) => setShowCloseTask(false)}
            removeFromStore={removeFromStore}
          />
        )}
      </div>
      {showAddTemplate && (
        <RightFlotingContainer
          recommendedTemplateList={recommendedTemplateList}
          setRecommendedTemplateList={setRecommendedTemplateList}
          tempOfOrgList={tempOfOrgList}
          addedTemplateList={addedTemplateList}
          setAddedTemplateList={setAddedTemplateList}
          closeTemplate={() => {
            setShowAddTemplate(false);
            if (addedTemplateList && addedTemplateList.length > 0) {
              const temp = [...addedTemplateList];
              temp.forEach((template) => {
                template.taskDetailId = data.taskId;
              });
              DashboardServices.addTaskTemplate(
                loginUserDetail.userId,
                addedTemplateList,
                "OLD"
              ).then((response) => {
                console.log("addTaskTemplate", response.data);
                const tempData = { ...data };
                if (tempData.templates) {
                  tempData.templates = [
                    ...tempData.templates,
                    ...addedTemplateList,
                  ];
                } else {
                  tempData.templates = [...addedTemplateList];
                }
                dispatch(setSelectedTask(tempData));
              });
            }
          }}
          mode={"old"}
        />
      )}

      {showFileModal && (
        <div className="RightFlotingContainerArea">
          <RightTaskFileUpload
            documents={data.documents}
            onClose={(e) => setShowFileUploadModal(false)}
            uploadFile={(data) => {
              handleChangeFile(data);
            }}
          />
        </div>
      )}
    </>
  );
};

export default TaskReopenPostponed;
