import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

class WebinarService {
  getWebinarChat(roomId) {
    return http.get(`/webinar/getWebinarChat/${roomId}`, {
      headers: authHeader(),
    });
  }

  addWebinarChat(reqDto) {
    return http.post(`/webinar/addWebinarChat`, reqDto, {
      headers: authHeader(),
    });
  }
}
export default new WebinarService();
