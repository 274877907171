import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

class PaymentService {
  getEditionsWithPricingList(orgId) {
    return http.get(
      `accountbilling/getEditionsWithPricingListAndFeatures/${orgId}`,
      {
        headers: authHeader(),
      }
    );
  }

  createPaymentIntent(userId, orgId, reqDto) {
    return http.post(
      `/payment/stripe/createPaymentIntent/${userId}/${orgId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  capturePayment(orgId, userId, reqDto) {
    return http.post(
      `/accountbilling/capturePayment/${orgId}/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  addSubscription(editionId, orgId, userId) {
    return http.put(
      `/accountbilling/addSubscription/${editionId}/${orgId}/${userId}`,
      {
        headers: authHeader(),
      }
    );
  }

  checkIfEditionPurchased(userId) {
    return http.get(`/accountbilling/checkIfEditionPurchased/${userId}`, {
      headers: authHeader(),
    });
  }

  validateCoupon(couponCode) {
    return http.get(`/accountbilling/validateCoupon/${couponCode}`, {
      headers: authHeader(),
    });
  }

  couponUsed(reqDto, userId) {
    return http.post(`/accountbilling/couponUsed/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }
}
export default new PaymentService();
