import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  taskTabList: [],
  taskRightModalData: null,
  taskLinks: [],
};

const taskListSlice = createSlice({
  initialState,
  name: "taskList",
  reducers: {
    setTaskTabList: (state, action) => {
      state.taskTabList = action.payload;
    },
    setTaskModalData: (state, action) => {
      state.taskRightModalData = action.payload;
    },
    pushLinkTask: (state, action) => {
      state.taskLinks.push(action.payload);
    },
    clearTaskModalData: (state) => {
      state.taskRightModalData = null;
      state.taskLinks = [];
    },
    moveToPreviousTask: (state) => {
      const list = [...state.taskLinks];

      const current = list.pop();
      state.taskRightModalData = current;

      console.log("list =", list);
      state.taskLinks = list;
    },
  },
});

export default taskListSlice;
export const {
  setTaskTabList,
  setTaskModalData,
  clearTaskModalData,
  pushLinkTask,
  moveToPreviousTask,
} = taskListSlice.actions;
