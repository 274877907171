import { Add, AttachFile } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import { useRef, useState, useEffect, useContext } from "react";
import { Download, Trash, XLg } from "react-bootstrap-icons";
import {
  getActivityList,
  uploadTemplate,
  getActiveTemplatesOfOrganization,
  deactivateTemplate,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import {
  MAX_FILE_SIZE_TEMPLATE,
  VALID_FILE_EXTENSION_TEMPLATE,
} from "../../../../constants/const_values";
import { toast } from "react-toastify";
import { convertFileToBase64_Split } from "../../../../utils/FileUtils";
// import { downloadFileFromUrl } from "../../../../utils/FileUtils";

import { v4 as uuidv4 } from "uuid";
import DocumentTemplateViewer from "../../../task/view/component/DocumentTemplateViewer";
import { AddTabContext } from "../../../dashboard/view/DashboardPage";
import { useTranslation } from "react-i18next";

// import useFileUpload from "react-use-file-upload";

export default function CreateActivityTemplate({
  handelCloseActivityTemplate,
  refreshActivityTemplateData,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();

  const { addTab } = useContext(AddTabContext);

  const [selectedActivity, setSelectedActivity] = useState({
    name: "",
    id: null,
  });

  const [uploadedFiles, setUploadedFiles] = useState([]);
  //////////////////////////////////////////////////////////////////

  const [activeTemplateDataOfOrg, setActiveTemplateDataOfOrg] = useState([]);

  useEffect(() => {
    fetchActiveTemplateData();
  }, [userDetails.orgId]);

  const fetchActiveTemplateData = () => {
    getActiveTemplatesOfOrganization(userDetails.orgId).then((response) => {
      console.log("getActiveTemplatesOfOrganisation", response.data);
      setActiveTemplateDataOfOrg(response.data);
    });
  };

  useEffect(() => {
    if (selectedActivity.name !== "") {
      const filteredTemplates = activeTemplateDataOfOrg.filter(
        (template) => template.activity === selectedActivity.name
      );

      const files = filteredTemplates.map((template) => {
        // Create a new File object with dummy data
        return new File(["dummy content"], template.fileDisplayName, {
          type: "text/plain",
          lastModified: new Date(),
        });
      });

      setUploadedFiles(files);
    }
  }, [selectedActivity]);

  console.log("uploadedFiles", uploadedFiles);

  /////////////////////////////////////////////////////////////////

  const [activityListData, setActivityListData] = useState([]);

  const [selectedTaskMasterId, setSelectedTaskMasterId] = useState(0);

  const [selectedFileObj, setSelectedFileObj] = useState({});

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getActivityList(userDetails.orgId).then((response) => {
      console.log("getActivityList", response.data);
      setActivityListData(response.data);
    });
  }, []);

  const fileUploadRef = useRef(null);

  // const handleFileUpload = async (e) =>{
  //   console.log(e);
  //   if (!e.target.files) {
  //     return;
  //   }
  //   const files = Array.from(e.target.files);
  //   console.log("handleFileUpload", files);

  //   const validFiles = [];

  //   for (let i = 0; i < files.length; i++) {
  //     if (files[i].size > 1024 * 1024 * MAX_FILE_SIZE_TEMPLATE) {
  //       toast.warning(
  //         `'${files[i].name}' needs to be less than ${MAX_FILE_SIZE_TEMPLATE}MB`,
  //         {
  //           position: toast.POSITION.TOP_RIGHT,
  //         }
  //       );
  //     } else {
  //       validFiles.push(files[i]);
  //       try {
  //         const fileData = await convertFileToBase64_Split(files[i]);
  //         setSelectedFileObj({ [files[i].name]: fileData });
  //       } catch (error) {
  //         console.error("Error converting file to base64:", error);
  //       }
  //     }
  //   }

  // setUploadedFiles(validFiles);
  // };

  const handleFileUpload = async (e) => {
    const files = e.target.files;
    console.log("handleFileUpload", files);

    const validFiles = [];
    const promises = [];

    for (const file of files) {
      if (file.size > 1024 * 1024 * MAX_FILE_SIZE_TEMPLATE) {
        // toast.warning(
        //   `'${file.name}' needs to be less than ${MAX_FILE_SIZE_TEMPLATE}MB`,
        //   {
        //     position: toast.POSITION.TOP_RIGHT,
        //   }
        // );
        toast.warning(
          t("file_size_warning", {
            fileName: file.name,
            maxFileSize: MAX_FILE_SIZE_TEMPLATE,
          }),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else {
        validFiles.push(file);
        promises.push(
          convertFileToBase64_Split(file)
            .then((fileData) => {
              setSelectedFileObj((prevSelectedFileObj) => ({
                ...prevSelectedFileObj,
                [file.name]: fileData,
              }));
            })
            .catch((error) => {
              console.error("Error converting file to base64:", error);
            })
        );
      }
    }

    await Promise.all(promises);

    setUploadedFiles([...uploadedFiles, ...validFiles]);
  };

  const handleDragEnter = (e) => {
    console.log("handleDragEnter");
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = (e) => {
    console.log("handleDragOver");
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e) => {
    console.log("handleDrop");
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;
    console.log("dragged files", files);

    const validFiles = [];
    const promises = [];

    for (const file of files) {
      if (file.size > 1024 * 1024 * MAX_FILE_SIZE_TEMPLATE) {
        // toast.warning(
        //   `'${file.name}' needs to be less than ${MAX_FILE_SIZE_TEMPLATE}MB`,
        //   {
        //     position: toast.POSITION.TOP_RIGHT,
        //   }
        // );
        toast.warning(
          t("file_size_warning", {
            fileName: file.name,
            maxFileSize: MAX_FILE_SIZE_TEMPLATE,
          }),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else if (
        !VALID_FILE_EXTENSION_TEMPLATE.includes(
          file.name.split(".").pop().toLowerCase()
        )
      ) {
        // toast.warning(`Invalid File Type of '${file.name}'`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        toast.warning(
          t("invalid_file_type_warning", { fileName: file.name }),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else {
        validFiles.push(file);
        promises.push(
          convertFileToBase64_Split(file)
            .then((fileData) => {
              setSelectedFileObj((prevSelectedFileObj) => ({
                ...prevSelectedFileObj,
                [file.name]: fileData,
              }));
            })
            .catch((error) => {
              console.error("Error converting file to base64:", error);
            })
        );
      }
    }

    await Promise.all(promises);

    setUploadedFiles([...uploadedFiles, ...validFiles]);
  };

  const top100Films = [
    { label: "AGM" },
    { label: "Board Meeting" },
    { label: "Appointment" },
  ];

  const finalsubmit = () => {
    setLoading(true);
    console.log("finalsubmit");

    if (selectedTaskMasterId == 0) {
      toast.error(t("please_select_work") , {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (Object.keys(selectedFileObj).length === 0) {
      toast.error(t("Please_upload_at_least_one_file"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const reqDto = {
      orgId: userDetails.orgId,
      taskMasterId: selectedTaskMasterId,
      fileNameKeyFieDataValue: selectedFileObj,
    };
    console.log("reqDto", reqDto);

    uploadTemplate(userDetails.userId, reqDto).then((response) => {
      if (response.data.returnValue == "1") {
        toast.success(t("template_uploaded_successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        refreshActivityTemplateData();
        setSelectedTaskMasterId(0);
        setSelectedFileObj({});
        setUploadedFiles([]);
        setSelectedActivity({
          name: "",
          id: null,
        });
      } else {
        toast.error(t("something_went_wrong"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setLoading(false);
    });
  };

  // const handleFileDownload = async (fileData) => {
  //   console.log("handleFileDownload", fileData);

  //   const fileName = fileData.name;
  //   console.log("fileName", fileName);

  //   const matchingTemplate = activeTemplateDataOfOrg.find(
  //     (template) => template.fileDisplayName === fileName
  //   );

  //   if (matchingTemplate) {
  //     const fileLink = matchingTemplate.fileLink;
  //     console.log("File Link:", fileLink);
  //     downloadFileFromUrl(fileLink, fileName);
  //   } else {
  //     console.log("No matching template found.");
  //   }
  // };

  // const handleDeleteFile = (fileData , index) => {
  //   console.log('handleFileDownload', fileData);

  //   console.log('selectedActivity', selectedActivity)

  //   const fileName = fileData.name;
  //   console.log('fileName', fileName);

  //   const matchingTemplate = activeTemplateDataOfOrg.find(template => template.fileDisplayName === fileName);

  //   if (matchingTemplate) {
  //     const templateId = matchingTemplate.templateId;
  //     console.log('File templateId:', templateId);
  //     return;
  //     deactivateTemplate(userDetails.userId, templateId).then(
  //             (response) => {
  //               console.log("data deleted", response.data);
  //               if (response.data.returnValue === "1") {
  //                 toast.success(response.data.message, {
  //                   position: toast.POSITION.TOP_RIGHT,
  //                 });
  //                 fetchActiveTemplateData();
  //                 // fetchTemplateData();
  //                 // setOpenModal(false);
  //               } else {
  //                 toast.error(response.data.message, {
  //                   position: toast.POSITION.TOP_RIGHT,
  //                 });
  //               }
  //             }
  //           );
  //   } else {
  //   const updatedFiles = [...uploadedFiles];
  //   updatedFiles.splice(index, 1);
  //   setUploadedFiles(updatedFiles);
  //   }
  // };

  const handleDeleteFile = (fileData, index) => {
    console.log("handleFileDownload", fileData);
    console.log("selectedActivity", selectedActivity);

    const fileName = fileData.name;
    console.log("fileName", fileName);

    const matchingTemplate = activeTemplateDataOfOrg.find(
      (template) =>
        template.fileDisplayName === fileName &&
        template.activity === selectedActivity.name
    );

    if (matchingTemplate) {
      const templateId = matchingTemplate.templateId;
      console.log("File templateId:", templateId);

      deactivateTemplate(userDetails.userId, templateId)
        .then((response) => {
          console.log("data deleted", response.data);
          if (response.data.returnValue === "1") {
            toast.success(t("template_deleted_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            refreshActivityTemplateData();
            fetchActiveTemplateData();
            const updatedFiles = [...uploadedFiles];
            updatedFiles.splice(index, 1);
            setUploadedFiles(updatedFiles);
            // fetchTemplateData();
            // setOpenModal(false);
          } else {
            toast.error(t("something_went_wrong"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          console.error("Error deactivating template:", error);
        });
    } else {
      const updatedFiles = [...uploadedFiles];
      updatedFiles.splice(index, 1);
      setUploadedFiles(updatedFiles);
    }
  };

  const handleViewFile = (file) => {
    const matchingTemplate = activeTemplateDataOfOrg.find(
      (template) =>
        template.fileDisplayName === file.name &&
        template.activity === selectedActivity.name
    );

    if (matchingTemplate) {
      console.log("Matching template found:", matchingTemplate);
      const tabKey = uuidv4();
      addTab({
        key: tabKey,
        title: `File_${matchingTemplate.fileDisplayName}`,
        content: (
          <DocumentTemplateViewer file={matchingTemplate} tabKey={tabKey} />
        ),
        isDraggable: true,
      });
    } else {
      console.log(
        "No matching template found for file:",
        file.name,
        "and activity:",
        selectedActivity.name
      );
    }

    console.log("userDetails:", userDetails);
  };

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">{t("add_activity_template")}</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseActivityTemplate()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (
          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <Autocomplete
                  disablePortal
                  id="selectActivity"
                  className="formAutocompleteField"
                  variant="outlined"
                  options={activityListData}
                  getOptionLabel={(option) => option.name}
                  value={selectedActivity}
                  // onChange={(e, data) => {
                  //   setSelectedTaskMasterId(parseInt(data.id));
                  //   setSelectedActivity(data);
                  // }}
                  onChange={(e, data) => {
                    if (data && data.id) {
                      setSelectedTaskMasterId(parseInt(data.id));
                      setSelectedActivity(data);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="formAutoComInputField autocompFildPlaceholder"
                      {...params}
                      label={t("select_activity")}
                    />
                  )}
                />
              </FormControl>
            </div>

            {/* on darg file upload start */}
            <div
              // className="flotingAreaContainer"
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e)}
              style={{
                border: "2px solid #545454",
                borderRadius: "12px",
                padding: "10px",
                color: "#ffffff",
                width: "-webkit-fill-available",
              }}
            >
              <h1
                style={{
                  margin: "0px",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                {t("upload_files")}
              </h1>

              {/* <p>Please use the form to your right to upload any file(s) of your choosing.</p> */}

              <div className="form-container">
                {/* Display the files to be uploaded */}
                <div //css={ListCSS}
                >
                  {/* <p>{`**Maximum File Size Allowed ${MAX_FILE_SIZE_TEMPLATE}MB, Extensions Allowed ${VALID_FILE_EXTENSION_TEMPLATE.map(
                    (item) => ` ${item}`
                  )}`}</p> */}
                   <p>
                  {t("file_restrictions", {
                 maxFileSize: MAX_FILE_SIZE_TEMPLATE,
                 validFileTypes: VALID_FILE_EXTENSION_TEMPLATE.join(", "),
                 })}
                </p>
                  <ul>
                    {/* {filesList.map((name, index) => (
                        <li key={name.fileName}>
                          <span>{name.fileName}</span>

                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              const temp = [...filesList];
                              temp.splice(index, 1);
                              setFilesList(temp);
                            }}
                          >
                            &nbsp;&nbsp;
                            <font style={{ color: "red" }}>&#x2715;</font>
                          </span>
                        </li>
                      ))} */}
                    {/* {console.log("document in ul =", documents)} */}
                  </ul>
                </div>

                {/* Provide a drop zone and an alternative button inside it to upload files. */}
                <div>
                  <p
                    style={{
                      display: "block",
                      position: "relative",
                      opacity: "0.6",
                      margin: "10px",
                      textAlign: "center",
                      fontStyle: "italic",
                    }}
                  >
                    {t("drag_and_drop_files_here")}
                  </p>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => fileUploadRef.current.click()}
                    sx={{ marginBottom: "10px" }}
                    style={{
                      backgroundColor: "#707070",
                      borderRadius: "20px",
                      width: "100%",
                    }}
                  >
                    {t("select_files")}
                  </Button>

                  {/* Hide the crappy looking default HTML input */}
                  <input
                    ref={fileUploadRef}
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    accept=".doc, .docx, .docm, .ppt, .pptx, .pptm, .txt, .pdf, .xls, .xlsx"
                    onChange={(e) => {
                      handleFileUpload(e);
                      fileUploadRef.current.value = null;
                    }}
                  />
                </div>
              </div>
            </div>

            {/* on drag file upload end */}

            <div class="elementListGrp">
              <div class="elementHeding">{t("template_added")}</div>
              <ul class="elmListInfo">
                {uploadedFiles.map((file, index) => (
                  <li key={index}>
                    <span
                      className="rolsNm"
                      onClick={() => handleViewFile(file)}
                    >
                      {file.name}
                    </span>

                    {/* <IconButton className="downlBtn"  onClick={() => {
                      handleFileDownload(file)
                  }}>
                      <Download />
                    </IconButton> */}
                    <IconButton
                      className="rmvBtn"
                      onClick={() => handleDeleteFile(file, index)}
                    >
                      <Trash />
                    </IconButton>
                  </li>
                ))}
              </ul>
            </div>

            {/* <div className="elementListGrp">
              <div className="elementHeding">Template Added</div>
              <ul className="elmListInfo">
                {uploadedFiles.map((file, index) => {
                  const matchingTemplate = activeTemplateDataOfOrg.find(
                    (template) =>
                      template.fileDisplayName === file.name &&
                      template.activity === selectedActivity.name
                  );

                  return (
                    <li key={index}>
                      <span className="rolsNm">{file.name}</span>
                      {matchingTemplate ? (
                        <IconButton
                          className="downlBtn"
                          onClick={() => handleFileDownload(file)}
                        >
                          <Download />
                        </IconButton>
                      ) : null}
                      <IconButton
                        className="rmvBtn"
                        onClick={() => handleDeleteFile(file, index)}
                      >
                        <Trash />
                      </IconButton>
                    </li>
                  );
                })}
              </ul>
            </div> */}
          </div>
        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button
              className="dfultPrimaryBtn"
              onClick={() => finalsubmit()}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("save")}</>
              )}
            </Button>
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseActivityTemplate()}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
