import authHeader from "../config/auth-header-config";
import http from "../config/http-common";
import { getLoginUserDetails } from "../utils/Utils";
import {
  getUserRoleBasedJWTToken,
  getUserRoleBasedJWTTokenGuest,
  getUserRoleBasedJWTTokenNew,
} from "./VideoConferenceService";

export const getMeetingAgenda = (eventId) => {
  return http.get(`calendar/getMeetingAgendas/${eventId}`, {
    headers: authHeader(),
  });
};

export const getEventParticipants = (eventId) => {
  return http.get(`calendar/getParticipantsOfEvent/${eventId}`, {
    headers: authHeader(),
  });
};

export const getPrivateNotes = (userId, eventId) => {
  return http.get(`calendar/getPrivateNotes/${userId}/${eventId}`, {
    headers: authHeader(),
  });
};

export const removeParticipantOfEvent = (
  eventParticipantId,
  userId,
  eventType,
  applicableForAllRecurringEvents
) => {
  return http.put(
    `calendar/removeParticipantOfEvent/${eventParticipantId}/${userId}/${eventType}/${applicableForAllRecurringEvents}`,
    {
      headers: authHeader(),
    }
  );
};

export const deletePrivateNotes = (userId, eventNoteId) => {
  return http.put(`calendar/deletePrivateNotes/${userId}/${eventNoteId}`, {
    headers: authHeader(),
  });
};

export const addPrivateNotes = (userId, eventId, reqDto) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.post(
    `calendar/addPrivateNotes/${userId}/${eventId}`,
    reqDto,
    config,
    {
      headers: authHeader(),
    }
  );
};

export const getChairpersonOrConvenerList = (eventId, type) => {
  return http.get(`calendar/getChairpersonOrConvenerList/${eventId}/${type}`, {
    headers: authHeader(),
  });
};

export const addParticipants = (
  eventId,
  userId,
  eventType,
  appForAllRecEvents,
  reqDto
) => {
  return http.put(
    `calendar/addParticipants/${eventId}/${userId}/${eventType}/${appForAllRecEvents}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};

export const changeConvenerChairperson = (eventId, userId, reqDto) => {
  return http.put(
    `calendar/changeConvenerChairperson/${eventId}/${userId}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};

export const rescheduleEvent = (userId, reqDto) => {
  return http.put(`calendar/rescheduleEvent/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const cancelEvent = (
  eventId,
  userId,
  applicableForAllRecurringEvents,
  reqStr
) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.put(
    `calendar/cancelEvent/${eventId}/${userId}/${applicableForAllRecurringEvents}`,
    reqStr,
    config,
    {
      headers: authHeader(),
    }
  );
};

export const updateMyEventAvailability = (userId, reqDto) => {
  return http.put(`calendar/updateMyEventAvailability/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getMyEventAvailability = (eventId, userId) => {
  return http.get(`calendar/getMyEventAvailability/${eventId}/${userId}`, {
    headers: authHeader(),
  });
};

export const getEventDetailsByMeetingId = (meetingId) => {
  return http.get(`meeting/getEventDetailsByMeetingId/${meetingId}`, {
    headers: authHeader(),
  });
};

export const sendMeetingInviteFromOrgInternalUser = (
  meetingId,
  userId,
  reqDto
) => {
  return http.post(
    `meeting/sendMeetingInviteFromOrgInternalUser/${meetingId}/${userId}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};

export const getMeetingRecordingLink = (meetingId) => {
  return http.get(`meeting/getMeetingRecordingLink/${meetingId}`, {
    headers: authHeader(),
  });
};

export const chatAI = (reqDto) => {
  return http.put(`openai/chat`, reqDto, {
    headers: authHeader(),
  });
};

export const generateJWTTokenForVideo = async (roomNumber, eventData) => {
  console.log("generateJWTTokenForVideo - data ", roomNumber, eventData);
  const loginuserDetails = getLoginUserDetails();
  let isAllowed = localStorage.getItem(`M~${roomNumber}`) !== null;
  isAllowed = false;

  if (eventData?.eventType === "Consultation") {
    const reqDto = {
      personName: eventData.patientName
        ? eventData.patientName + "_consultation"
        : loginuserDetails.userName + "_consultation",
    };
    const res = await getUserRoleBasedJWTTokenGuest(roomNumber, reqDto);
    console.log("getUserRoleBasedJWTTokenGuest", res);
    return res.data;
  }

  if (isAllowed) {
    const reqDto = {
      personName: loginuserDetails.userName,
    };
    const res = await getUserRoleBasedJWTTokenNew(
      roomNumber,
      loginuserDetails.userId,
      reqDto,
      "Y",
      loginuserDetails.userType === "GUEST" ? "Y" : "N"
    );
    return res.data;
  } else {
    if (loginuserDetails.userType === "GUEST") {
      const reqDto = {
        personName: loginuserDetails.userName,
      };
      const res = await getUserRoleBasedJWTTokenGuest(roomNumber, reqDto);
      return res.data;
    } else {
      const res = await getUserRoleBasedJWTToken(
        roomNumber,
        loginuserDetails.userId
      );
      return res.data;
    }
  }
};
