import {
  CheckCircle,
  DownloadForOffline,
  Translate,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  IconButton,
  TextField,
  Avatar,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { XLg } from "react-bootstrap-icons";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import {
  getBcp47LanguageCodeNameOfUser,
  getBcp47CodeName,
  addUpdateUserLanguage,
} from "../../../../../../services/VideoConferenceService";

const SelectSpokenLanguage = ({
  handleMenuCloseButton,
  roomNumber,
  source,
}) => {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();
  const [spokenLanguages, setSpokenLanguages] = useState([]);
  const [selectedSpokenLanguages, setSelectedSpokenLanguages] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [showSpokenLanguages, setShowSpokenLanguages] = useState(false);

  const fetchSpokenLanguages = async () => {
    let spokenLanguagesTemp = [];
    let spokenLanguagesCode = [];
    await getBcp47LanguageCodeNameOfUser(userDetails.userId).then(
      (response) => {
        if (response && response.data && response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].selected = true;
            spokenLanguagesTemp.push(response.data[i]);
            spokenLanguagesCode.push(response.data[i].id);
          }
        }
      }
    );
    await getBcp47CodeName().then((response) => {
      console.log("spokenLanguagesTemp2", response.data);
      if (response && response.data && response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          if (!spokenLanguagesCode.includes(response.data[i].id)) {
            response.data[i].selected = false;
            spokenLanguagesTemp.push(response.data[i]);
          }
        }
      }
    });
    console.log("spokenLanguagesTemp2", spokenLanguagesTemp);
    console.log(
      "spokenLanguagesTemp2",
      spokenLanguagesTemp.filter((langDto) => langDto.selected)
    );
    setSpokenLanguages(spokenLanguagesTemp);
    setSelectedSpokenLanguages(
      spokenLanguagesTemp.filter((langDto) => langDto.selected)
    );
  };

  const handleChangeSelectLanguage = (langDto) => {
    console.log("handleChangeSelectLanguage", langDto);
    console.log("handleChangeSelectLanguage1", selectedSpokenLanguages);

    if (langDto.selected) {
      if (selectedSpokenLanguages.length === 1) {
        toast.error(t("atleast_one_language_is_mandatory"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      let selSpokenLangTemp = [];
      langDto.selected = false;
      selectedSpokenLanguages.forEach((langDtoLoop) => {
        if (langDto.id !== langDtoLoop.id) {
          selSpokenLangTemp.push(langDtoLoop);
        }
      });
      setSelectedSpokenLanguages(selSpokenLangTemp);
    } else {
      if (selectedSpokenLanguages.length === 3) {
        toast.error(t("maximum_three_languages_can_be_selected"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      let selSpokenLangTemp = selectedSpokenLanguages;
      langDto.selected = true;
      selSpokenLangTemp.push(langDto);
      setSelectedSpokenLanguages(selSpokenLangTemp);
    }
    setRefresh(refresh + 1);
    console.log("handleChangeSelectLanguage2", spokenLanguages);
  };

  const handleSubmitSelLanguages = () => {
    console.log("handleSubmitSelLanguages", selectedSpokenLanguages);
    // return;
    if (selectedSpokenLanguages.length === 0) {
      toast.error(t("please_select_atleast_one_language"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    addUpdateUserLanguage(
      selectedSpokenLanguages.map((langDto) => langDto.id),
      userDetails.userId
    ).then((response) => {
      if (response && response.data.returnValue === "1") {
        if (source === "MeetingTabRightPanel") {
          toast.success(t("spoken_language_updated_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.success(t("joining_meeting_please_wait"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        setShowSpokenLanguages(true);
      }
    });
  };

  useEffect(() => {
    fetchSpokenLanguages();
  }, [roomNumber]);

  const [searchText, setSearchText] = useState(null);

  return (
    <>
      <div className="meetRightHeader">
        <div className="metRtTitle"> {t("select_spoken_language")} </div>
        <div className="metRtAction">
          <IconButton
            aria-label="Close"
            onClick={() => handleMenuCloseButton()}
          >
            <XLg />
          </IconButton>
        </div>
      </div>

      {/* Heading */}

      <div className="MeetingTranscriptionContainer">
        <div className="Meeting_Transcription">
          <div className="transcripHeader">
            {/* <Autocomplete
              className="transcripAutoComplete"
              disablePortal
              id="transcripAutoComplete"
              // options={languages}
              renderInput={(params) => (
                <TextField
                  {...params}
                  hiddenLabel
                  placeholder="Select Translate Language"
                />
              )}
            /> */}
            <input
              type="text"
              className="tranLangSrchInput"
              id="transcripAutoComplete"
              placeholder={t("select_translate_language")}
              onChange={(event) => {
                console.log(event.target.value);
                setSearchText(event.target.value);
              }}
            />
            {/* <TextField
              className="transcripAutoComplete"
              id="transcripAutoComplete"
              placeholder="Select Translate Language"
              onChange={(event) => {
                console.log(event.target.value);
                setSearchText(event.target.value);
              }}
            /> */}

            {/* <Button className="transAllBtn" startIcon={<Translate />}>
              Translate All
            </Button> */}
          </div>

          <div className="MeetingSelectLangContainer">
            <div className="meetLanguagesListContain">
              <div className="meetlangListGrp">
                {spokenLanguages
                  .filter((languageDto) =>
                    searchText
                      ? languageDto.name
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                      : true
                  )
                  .map((languageDto) => (
                    <div
                      key={languageDto.id}
                      className={
                        languageDto.selected
                          ? "meetLangIndBtn meetLangSelected"
                          : "meetLangIndBtn"
                      }
                      onClick={() => {
                        handleChangeSelectLanguage(languageDto);
                      }}
                    >
                      <div className="selectedLang">
                        <CheckCircle />
                      </div>
                      <span>{languageDto.name}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {/* <div className="transcriptionContainArea">
            <div className="spokenLangDiv spknLangScrollbar">
              {spokenLanguages
                .filter((languageDto) =>
                  searchText
                    ? languageDto.name
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    : true
                )
                .map((languageDto) => (
                  <Avatar
                    key={languageDto.id}
                    className={
                      languageDto.selected
                        ? "meetLangIndBtn meetLangSelected"
                        : "meetLangIndBtn"
                    }
                    variant="square"
                    onClick={() => {
                      handleChangeSelectLanguage(languageDto);
                    }}
                  >
                    <span>{languageDto.name}</span>
                  </Avatar>
                ))}
            </div>
          </div> */}
        </div>
      </div>

      {/* body */}

      <div className="MeetRightFooter">
        <div className="meetRightFootBtnGrp">
          <Button
            className="downloadTranscriptionBtn"
            // startIcon={<DownloadForOffline />}
            onClick={() => handleSubmitSelLanguages()}
          >
            {t("submit")}
          </Button>
          {/* <Button className="saveMeetRightBtn"> Save </Button> */}
          <Button
            className="cancleTransBtn"
            variant="outlined"
            onClick={() => handleMenuCloseButton()}
          >
            {t("close")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SelectSpokenLanguage;
