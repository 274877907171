import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

export const FileCursor = ({ children }) => {
  const fileElementRef = useRef(null);
  const [filearrowDisable, setFilearrowDisable] = useState(true);
  const filehandleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setFilearrowDisable(true);
      } else {
        setFilearrowDisable(false);
      }
    }, speed);
  };

  const [fileDivWidth, setFileDivWidth] = useState(0);

  useEffect(() => {
    if (fileElementRef.current) {
      setFileDivWidth(fileElementRef.current.offsetWidth);
    }
  }, [fileElementRef]);

  return (
    <>
      <div className="horaizonScroll">
        <IconButton
          className="goPrevousBtn"
          onClick={() => {
            filehandleHorizantalScroll(fileElementRef.current, 25, 200, -10);
          }}
          disabled={filearrowDisable}
        >
          <ArrowBackIosNew />
        </IconButton>

        <div class="elemntFilsgrp" ref={fileElementRef}>
          {children}
        </div>

        {fileDivWidth > 370 && (
          <IconButton
            className="goNextBtn"
            onClick={() => {
              filehandleHorizantalScroll(fileElementRef.current, 25, 200, 10);
            }}
          >
            <ArrowForwardIos />
          </IconButton>
        )}
      </div>
    </>
  );
};
