import {
  AccountTreeRounded,
  Add,
  ArrowBack,
  BiotechRounded,
  Edit,
  Search,
} from "@mui/icons-material";
import { Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import DashboardServices from "../../../../../services/DashboardServices";
import { useTranslation } from "react-i18next";

export default function ViewInvestigationAdmin({
  handleCloseViewInvestigationAdmin,
  closeAll,
  handelOpenAddInvestigationAdmin,
  handleOpenUnitInvestigationAdmin,
  handelOpenAddInvestigationParameter,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();
  const [investigationAdminData, setInvestigationAdminData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterInvestigationAdminData, setFilterInvestigationAdminData] =
    useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchViewInvestigationAdminData();
  }, []);

  const fetchViewInvestigationAdminData = () => {
    DashboardServices.getTestListConductedAtUnit(userDetails.orgId)
      .then((response) => {
        console.log("response", response.data);
        setInvestigationAdminData(response.data);
        setFilterInvestigationAdminData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = filterInvestigationAdminData.filter(
      (investigation) =>
        investigation.testName &&
        investigation.testName.toLowerCase().includes(query.toLowerCase())
    );

    setFilterInvestigationAdminData(filteredData);
  };

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton
            onClick={() => {
              handleCloseViewInvestigationAdmin();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("investigation")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="cuhederTxt">Departmment List</div> */}
              <div className="searchTasklist">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder={t("search_test_name")}
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              {/* <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    handelOpenAddInvestigationAdmin();
                  }}
                >
                  New Test
                </Button>
              </div> */}
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : investigationAdminData.length === 0 ? (
                <div class="noDataCard ">
                  {/* <span>{t("oops")}</span>{t("no_department_added_yet")} */}
                  <br />
                  <span className="sallMsg">
                  {t("no_investication_admin_data_found")}
                  </span>
                </div>
              ) : (
                <div className="TableContainer">
                  <table className="taskListTable">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th>{t("test_group")}</th>
                        <th>{t("test_name")}</th>
                        <th>{t("gender")}</th>
                        {/* <th>Location</th> */}
                        {/* <th>View</th> */}
                        {/* <th>Technique</th> */}
                        <th className="width100">{t("action")}</th>
                      </tr>
                    </thead>
                    <tbody className="scrolableTableBody">
                      {filterInvestigationAdminData.map((test, index) => (
                        <tr key={index}>
                          <td>
                            <div className="tskLstTaskNM">
                              {test.testGroup || "-"}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {test.testName || "-"}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {test.gender === "C"
                                ? t("both")
                                : test.gender === "M"
                                ? t("male")
                                : t("female")}
                            </div>
                          </td>
                          {/* <td>
                        <div className="tskLstTaskNM">{test.location || "-"}</div>
                      </td> */}
                          {/* <td>
                        <div className="tskLstTaskNM">{test.view || "-"}</div>
                      </td> */}
                          {/* <td>
                        <div className="tskLstTaskNM">{test.technique || "-"}</div>
                      </td> */}
                          <td>
                            <div className="tblActionBtnGrp">
                              {/* <Tooltip arrow title="Edit">
                                <IconButton>
                                  <Edit />
                                </IconButton>
                              </Tooltip> */}
                              {/* <Tooltip arrow title="Parameters ">
                                <IconButton
                                  onClick={() =>
                                    handelOpenAddInvestigationParameter()
                                  }
                                >
                                  <BiotechRounded />
                                </IconButton>
                              </Tooltip> */}
                              <Tooltip arrow title={t("link_units")}>
                                <IconButton
                                  onClick={() =>
                                    handleOpenUnitInvestigationAdmin(test)
                                  }
                                >
                                  <AccountTreeRounded />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>

                    {/* <tbody className="scrolableTableBody">
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">Hematology</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Lipid Profile</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Male, Female, Others</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">blood</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">blood</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">blood</div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <Tooltip arrow title="Edit">
                            <IconButton>
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Parameters ">
                            <IconButton>
                              <BiotechRounded />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Link Units">
                            <IconButton>
                              <AccountTreeRounded />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">Genetic Screening</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Pregnancy</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Female</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">-</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">-</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">-</div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <Tooltip arrow title="Edit">
                            <IconButton>
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Parameters ">
                            <IconButton>
                              <BiotechRounded />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Link Units">
                            <IconButton>
                              <AccountTreeRounded />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  </tbody> */}
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
