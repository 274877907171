import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import React, { useState } from "react";
import { XLg } from "react-bootstrap-icons";
import couponIoc from "../../../images/couponIcon.png";
import AppUtils from "../../../../../utils/AppUtils";
import { useForm } from "react-hook-form";
import PaymentAlert from "./PaymentAlert";
import { toast } from "react-toastify";
import PaymentService from "../../../../../services/PaymentService";
import { useEffect } from "react";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";

export default function PaymentModel({
  showPaymentModel,
  handlepaymentModelClose,
  handelOrgOpenModel,
  setActiveStep
}) {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [couponApplied, setCouponApplied] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const loginUserDetail = getLoginUserDetails();
  const { register, handleSubmit, setValue } = useForm();
  const [creditCardNumber, setCreditCardNumber] = useState("");
  const formatCreditCardNumber = (inputValue) => {
    // Remove non-numeric characters
    const numericValue = inputValue.replace(/\D/g, "");
    // Add space every 4 digits
    const formattedValue = numericValue.replace(/(\d{4})(?=\d)/g, "$1 ");
    // Update the state
    setCreditCardNumber(formattedValue);
  };
  const [currency, setCurrency] = useState("USD");
  const [firstTimeActivationPrice, setFirstTimeActivationPrice] = useState(1);
  const [editionId, setEditionId] = useState(2);
  const [message, setMessage] = useState(null);

  const [ccv, setCCV] = useState("");
  const [validCouponDtl, setValidCouponDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");
  const subscriptionAmount = 1;
  const [discountedValue, setDiscountedValue] = useState(null);

  const paymentElementOptions = {
    layout: "tabs",
  };

  useEffect(() => {
    if (!validCouponDtl) {
      setDiscountedValue(subscriptionAmount);
    } else {
      const temp = calculateAmount(subscriptionAmount, validCouponDtl);
      setDiscountedValue(temp);
    }
  }, [subscriptionAmount, validCouponDtl]);

  const formatExpiryDate = (inputValue) => {
    const numericValue = inputValue.replace(/\D/g, "");
    const formattedValue = numericValue.replace(/(\d{2})(?=\d)/, "$1/");
    setExpiryDate(formattedValue);
  };

  // useEffect(() => {

  // }, [validCouponDtl]);

  const formatCCV = (inputValue) => {
    const numericValue = inputValue.replace(/\D/g, "");

    const formattedValue = numericValue.slice(0, 3);

    setCCV(formattedValue);
  };

  const [showCountry, setCountry] = React.useState("");

  const handleChangeSelectCountry = (event) => {
    setCountry(event.target.value);
  };

  const calculateAmount = (amountToPay, couponDto) => {
    let amount = parseFloat(amountToPay);

    if (couponDto) {
      try {
        let couponPercent = parseFloat(couponDto.percentage);
        let finalAmount = AppUtils.subtractPercentFromNumber(
          amount,
          couponPercent
        );
        setDiscountedValue(finalAmount);
        return finalAmount;
      } catch (err) {
        console.log(err);
        setDiscountedValue(amount);
        return amount;
      }
    }
    setDiscountedValue(amount);
    return amount;
  };

  const subscribeWithoutPayment = (edition) => {
    PaymentService.addSubscription(
      editionId,
      loginUserDetail.orgId,
      loginUserDetail.userId
    ).then((response) => {
      console.log("addSubscription", response.data);

      let reqDto = {
        couponCode: validCouponDtl.couponCode,
        amountBeforeDiscount:
          edition.currency + " " + edition.firstTimeActivationPrice,
        amountAfterDiscount: edition.currency + " 0",
        transId: "",
        transType: "ORGANIZATION_ONBOARD",
      };
      PaymentService.couponUsed(reqDto, loginUserDetail.userId).then(
        (response) => {
          console.log(response.data);
          toast.success(t("subscribed_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      );
      setDiscountedValue(null);
      setValue("couponCode", "");
      setValidCouponDetail(null);
      handelOrgOpenModel();
      setActiveStep(1);
    });
  };

  const validateExpiryDate = (formattedExpiryDate) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() % 100; // Extract last two digits of the current year
    const currentMonth = currentDate.getMonth() + 1; // Month is zero-based, so add 1

    const [enteredMonth, enteredYear] = formattedExpiryDate
      .split("/")
      .map(Number);

    if (
      isNaN(enteredMonth) ||
      isNaN(enteredYear) ||
      enteredMonth < 1 ||
      enteredMonth > 12 ||
      enteredYear < currentYear ||
      (enteredYear === currentYear && enteredMonth < currentMonth)
    ) {
      // Invalid expiry date
      return false;
    }

    // Valid expiry date
    return true;
  };

  const onSubmitCouponCode = (data) => {
    try {
      console.log("onSubmitCouponCode", data);
      // setLoading(true);

      let isFormValid = true;
      let couponCode = data.couponCode.trim().toUpperCase();
      if (couponCode === "") {
        // setError("couponCode", {
        //   type: "custom",
        //   message: "Please enter coupon code",
        // });
        // toast.error("Please enter coupon code", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        // <PaymentAlert message={"Please enter coupon code"} />;
        setValidCouponDetail(null);
        isFormValid = false;
      }

      if (!isFormValid) {
        setLoading(false);
        return;
      }

      PaymentService.validateCoupon(couponCode).then((response) => {
        console.log(response.data);
        if (response && response.data) {
          if (response.data.returnValue === "1") {
            toast.success(t("coupon_validated_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
            // setCouponCodeApplied(couponCode);
            let coupon = {
              percentage: response.data.status,
              couponCode: couponCode,
            };
            setValidCouponDetail(coupon);
            calculateAmount(subscriptionAmount, coupon);
            setCouponApplied(true);
            // props.setCoupon(coupon);
            // props.closeDialog();
            return;
          } else {
            toast.error(t("fail_to_validate_coupon"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
            return;
          }
        } else {
          toast.error(t("something_went_wrong"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const makePayment = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    const response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        //return_url: "http://localhost:8080/ahlanapi/payment/stripe/returnUrl",
      },
      redirect: "if_required",
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    console.log("stripe response", response);
    if (response.error) {
      setMessage(response.error.message);
    } else {
      setMessage(`Payment Succeeded`);
      toast.success(t("payment_successful"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      handelOrgOpenModel();
      setActiveStep(1);

      let reqDto = {
        paymentStatus: response.paymentIntent.status,
        invoiceId: 0,
        paymentMethodId: response.paymentIntent.payment_method,
        amount: discountedValue
          ? discountedValue
          : response.paymentIntent.amount / 100,
        paymentIntentId: response.paymentIntent.id,
        paymentResponseObject: JSON.stringify(response.paymentIntent),
      };

      PaymentService.capturePayment(
        loginUserDetail.orgId,
        loginUserDetail.userId,
        reqDto
      ).then((response) => {
        console.log("capturePayment", response.data);

        PaymentService.addSubscription(
          editionId,
          loginUserDetail.orgId,
          loginUserDetail.userId
        ).then((response) => {
          console.log("addSubscription", response.data);
          try {
            if (validCouponDtl) {
              let reqDto = {
                couponCode: validCouponDtl.couponCode,
                amountBeforeDiscount: currency + " " + firstTimeActivationPrice,
                amountAfterDiscount: currency + " 0",
                transId: "",
                transType: "ORGANIZATION_ONBOARD",
              };
              PaymentService.couponUsed(reqDto, loginUserDetail.userId).then(
                (response) => {
                  console.log(response.data);
                }
              );
            }
          } catch (err) {
            console.log(err);
          }
          // props.handleNext();
        });
      });
    }
    // if (error.type === "card_error" || error.type === "validation_error") {
    //   setMessage(error.message);
    // } else {
    //   setMessage("An unexpected error occurred.");
    // }

    setLoading(false);
  };

  const handleRemoveCoupon = () => {
    // Reset coupon-related state variables
    setValidCouponDetail(null);
    setDiscountedValue(null);
    setCouponApplied(false);
    setValue("couponCode", "");
    // Additional reset logic if needed
  };

  return (
    <>
      <div className="modelContainer" open={showPaymentModel}>
        <Box className="modelContain animated">
          <IconButton
            className="closeModelIoc"
            onClick={handlepaymentModelClose}
          >
            <XLg />
          </IconButton>
          <div className="payCardOrCopunContainer">
            <div className="paymentCardInfo">
              {/* {validCouponDtl && (
                <div>{`Coupon ${validCouponDtl.couponCode} with ${validCouponDtl.percentage} % discount has been applied sucessfully `}</div>
              )} */}
               {validCouponDtl && (
                <div>{t("couponApplied", { couponCode: validCouponDtl.couponCode, discountPercentage: validCouponDtl.percentage })}</div>
              )}
              {/* <div>{`Pay $${discountedValue}`}</div> */}
              <div>{t("pay_amount", { amount: discountedValue })}</div>

              {discountedValue != 0 && (
                <>
                  {/* <div className="inputArea">
                    <label htmlFor="creditCard">Card Number</label>
                    <input
                      className="inputFrmControl"
                      type="text"
                      id="creditCard"
                      value={creditCardNumber}
                      maxLength="19"
                      placeholder="ex: 1234 1234 1234 1234"
                      onChange={(e) => formatCreditCardNumber(e.target.value)}
                    />
                  </div>

                  <div className="inputAreaGroup">
                    <div className="inputArea">
                      <label htmlFor="CCExpiryDt">Expiration</label>
                      <input
                        className="inputFrmControl"
                        type="text"
                        id="CCExpiryDt"
                        value={expiryDate}
                        maxLength="5"
                        placeholder="MM/YY"
                        onChange={(e) => {
                          // validateDate(e.target.value)
                          console.log("date =", e.target.value);
                          formatExpiryDate(e.target.value);
                        }}
                      />
                    </div>
                    <div className="inputArea">
                      <label htmlFor="CCVNO">CCV</label>
                      <input
                        className="inputFrmControl"
                        type="text"
                        id="CCVNO"
                        value={ccv}
                        maxLength="3"
                        placeholder="CCV"
                        onChange={(e) => formatCCV(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="inputArea">
                    <label htmlFor="ccCountry">Country</label>
                    <Select
                      labelId="ccCountry"
                      id="ccCountry"
                      className="inputFrmControl custSelect"
                      value={showCountry}
                      onChange={handleChangeSelectCountry}
                      displayEmpty
                    >
                      <MenuItem value="" selected>
                        <em>Select Country</em>
                      </MenuItem>
                      <MenuItem value="in">India</MenuItem>
                      <MenuItem value="usa">USA</MenuItem>
                      <MenuItem value="uae">UAE</MenuItem>
                    </Select>
                  </div> */}

                  <PaymentElement
                    id="payment-element"
                    className="paymentStripe"
                    options={paymentElementOptions}
                  />
                  <Button className="makePamentBtn" onClick={makePayment}>
                    {loading && <CircularProgress />}

                    {!loading && t("makePayment", { amount: discountedValue })}
                  </Button>

                  <div className="opDivider">
                    <span className="leftLine"></span>
                    <span>OR</span>
                    <span className="rightLine"></span>
                  </div>
                </>
              )}
              {discountedValue === 0 && (
                <>
                  {/* {console.log("discounted value=", discountedValue)} */}
                  <Button
                    className="makePamentBtn"
                    onClick={subscribeWithoutPayment}
                  >{t("activateAccount")}</Button>
                </>
              )}
            </div>

            <div className="payCouponInfo">
              <div className="couponheading">
                <img src={couponIoc} alt="" />
                <span className="coupHedTxt">{t("upgradeUsingCoupon")}</span>
              </div>
              <div className="couponInputGroup">
                <div className="inputArea">
                  <input
                    className="inputFrmControl couponcode"
                    type="text"
                    id="couponCode"
                    maxLength="19"
                    placeholder={t("enterCouponCode")}
                    {...register("couponCode")}
                    disabled={couponApplied}
                  />
                </div>
                {/* <Button
                  className="submitCouponBtn"
                  onClick={handleSubmit(onSubmitCouponCode)}
                >
                  SUBMIT
                </Button> */}
                <Button
                  className={
                    couponApplied ? "removeCouponBtn" : "submitCouponBtn"
                  }
                  onClick={
                    couponApplied
                      ? handleRemoveCoupon
                      : handleSubmit(onSubmitCouponCode)
                  }
                >
                  {couponApplied ? t("remove_in_caps") : t("submit_in_caps")}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
}
