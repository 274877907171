import { Add, Edit, Refresh } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { getPositionsOfOrganization } from "../../../../../services/AdminService";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { NumericFormatCustom } from "./NumericFormatCustom";
import { Trash } from "react-bootstrap-icons";

function ManpowerForm({ manpowerList, setManpowerList, budgetHeadList }) {
  const loginUserDetail = getLoginUserDetails();
  const [positionList, setPositionList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [refreshCount, setRefreshCount] = useState(1);
  const [purposeList, setPurposeList] = useState();

  const [allTimeFormData, setAllTimeFormData] = useState({
    position: "",
    appoinmentNature: "",
    budgetHead: "",
    jdKra: "",
    units: 0,
    maxCtc: 0,
    editMode: true,
  });

  useEffect(() => {
    getPositionsOfOrganization(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        setPositionList(response.data);
      }
    });
  }, []);

  const canculateTempTotalCTC = () => {
    const { units, maxCtc } = allTimeFormData;
    return units * maxCtc;
  };

  const handleSaveAllTimeData = (data) => {
    let isValid = true;
    if (data.position === "") {
      toast.error("Please Select Position");
      isValid = false;
    }
    if (data.appoinmentNature === "") {
      toast.error("Please Select Appointment Nature");
      isValid = false;
    }
    if (data.budgetHead === "") {
      toast.error("Please Select Budget Head");
      isValid = false;
    }

    if (data.jdKra === "") {
      toast.error("Please Provide JD-KRA");
      isValid = false;
    }
    if (data.units === 0) {
      toast.error("Units Cannot be 0");
      isValid = false;
    }
    if (data.maxCtc === 0) {
      toast.error("Max CTC Can't Be 0");
      isValid = false;
    }
    if (!isValid) {
      return isValid;
    }
    setManpowerList((prev) => [...prev, data]);
    setRefreshCount((prev) => prev + 1);
    return isValid;
  };

  const calculateTotalValue = () => {
    let totalVal = 0;
    for (let i = 0; i < manpowerList.length; i++) {
      const { maxCtc, units } = manpowerList[i];
      totalVal += parseInt(maxCtc) * parseInt(units);
    }
    return totalVal ? totalVal : 0;
  };

  const calculateTotalCtc = (index) => {
    const { maxCtc, units } = manpowerList[index];
    const totalVal = parseInt(maxCtc) * parseInt(units);
    if (isNaN(totalVal)) {
      return 0;
    } else {
      return totalVal;
    }
  };

  const handleSaveItem = (index) => {
    const temp = [...manpowerList];
    const data = temp[index];
    let isValid = true;
    if (data.position === "") {
      toast.error("Please Select Position");
      isValid = false;
    }
    if (data.appoinmentNature === "") {
      toast.error("Please Select Appointment Nature");
      isValid = false;
    }

    if (data.budgetHead === "") {
      toast.error("Please Select Budget Head");
      isValid = false;
    }

    if (data.jdKra === "") {
      toast.error("Please Provide JD-KRA");
      isValid = false;
    }
    if (data.units === 0) {
      toast.error("Units Cannot be 0");
      isValid = false;
    }
    if (data.maxCtc === 0) {
      toast.error("Max CTC Can't Be 0");
      isValid = false;
    }
    if (!isValid) {
      return isValid;
    }
    temp[index].editMode = false;
    setManpowerList(temp);
    setIsEditing(false);
  };

  const initializeAllTimeForeData = () => {
    setAllTimeFormData({
      position: "",
      appoinmentNature: "",
      budgetHead: "",
      jdKra: "",
      units: 0,
      maxCtc: 0,
      editMode: true,
    });
  };

  const handleClickRemoveRow = (row) => {
    const filterData = manpowerList.filter(
      (obj) => JSON.stringify(obj) !== JSON.stringify(row)
    );
    setManpowerList(filterData);
  };
  const handleClickAddRow = () => {
    const newObj = {
      department: null,
      designation: null,
      appoinmentNature: "",
      jdKra: "",
      position: "",
      units: 0,
      maxCtc: 0,
      isEditing: true,
    };
    setManpowerList([...manpowerList, newObj]);
    setIsEditing(true);
  };

  return (
    <>
      <div className="indentFormtable">
        <div className="indtableResponsive">
          <table>
            <thead>
              <tr>
                <th className="widthMin120">Position</th>
                <th className="widthMin120">Appoint Nature</th>
                <th className="widthMin120">Budget Head</th>
                <th className="widthMin120">JD/KRA</th>
                <th className="widthMin120">Number Of Position</th>
                <th className="widthMin120">Max CTC</th>
                <th className="widthMin120">Total CTC</th>
                <th></th>
                {/* <th className="mw80">
                  <div className="iconBtnGroup">
                    <Tooltip arrow title="Add new Item">
                      <IconButton onClick={handleClickAddRow}>
                        <Add />
                      </IconButton>
                    </Tooltip>
                  </div>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {manpowerList.length > 0 &&
                manpowerList.map((item, index) => {
                  if (item.editMode === true) {
                    return (
                      <tr>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="selectItems">Position</InputLabel>
                              <Select
                                className="formInputField"
                                variant="outlined"
                                labelId="selectItems"
                                id="select-Items"
                                value={manpowerList[index].position}
                                label="Position"
                                onChange={(e) => {
                                  const temp = [...manpowerList];
                                  temp[index].position = e.target.value;
                                  setManpowerList(temp);
                                }}
                              >
                                {positionList &&
                                  positionList.map((position) => (
                                    <MenuItem value={position}>
                                      {position.position}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="selectItems">
                                Appointment Nature
                              </InputLabel>
                              <Select
                                className="formInputField"
                                variant="outlined"
                                labelId="selectItems"
                                id="select-Items"
                                value={manpowerList[index].appoinmentNature}
                                label="Appoinment Nature"
                                onChange={(e) => {
                                  const temp = [...manpowerList];
                                  temp[index].appoinmentNature = e.target.value;
                                  setManpowerList(temp);
                                }}
                              >
                                <MenuItem value={"payroll"}>Payroll</MenuItem>
                                <MenuItem value={"retainer"}>
                                  {" "}
                                  Retainer{" "}
                                </MenuItem>
                                <MenuItem value={"fullTime"}>
                                  Full time
                                </MenuItem>
                                <MenuItem value={"contractual"}>
                                  Contractual
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="selectLedgerHead">
                                Budget Head
                              </InputLabel>
                              <Select
                                className="formInputField"
                                variant="outlined"
                                labelId="selectLedgerHead"
                                id="select-LedgerHead"
                                label="Budget Head"
                                disabled={!item.editMode}
                                value={item.budgetHead}
                                onChange={(e) => {
                                  const temp = [...manpowerList];
                                  temp[index].budgetHead = e.target.value;
                                  setManpowerList(temp);
                                }}
                              >
                                {budgetHeadList.length > 0 &&
                                  budgetHeadList.map((itemName, index) => (
                                    <MenuItem
                                      value={itemName}
                                      key={itemName.head + "_" + index}
                                    >
                                      {itemName.head}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="JD/KRA"
                                variant="outlined"
                                className="formTextFieldArea"
                                fullWidth
                                size="small"
                                autoComplete="off"
                                value={manpowerList[index].jdKra}
                                onChange={(e) => {
                                  const temp = [...manpowerList];
                                  temp[index].jdKra = e.target.value;
                                  setManpowerList(temp);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="Units"
                                variant="outlined"
                                className="formTextFieldArea"
                                autoComplete="off"
                                fullWidth
                                size="small"
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                }}
                                value={manpowerList[index].units}
                                onChange={(e) => {
                                  const temp = [...manpowerList];
                                  temp[index].units = e.target.value;
                                  setManpowerList(temp);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="Max CTC"
                                variant="outlined"
                                className="formTextFieldArea"
                                autoComplete="off"
                                fullWidth
                                size="small"
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                }}
                                value={manpowerList[index].maxCtc}
                                onChange={(e) => {
                                  const temp = [...manpowerList];
                                  temp[index].maxCtc = e.target.value;
                                  setManpowerList(temp);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>

                        <td>{calculateTotalCtc(index)}</td>

                        <td>
                          <div className="iconBtnGroup">
                            {item.editMode === true && (
                              <Button
                                className="saveBtn"
                                onClick={(e) => handleSaveItem(index)}
                              >
                                Save
                              </Button>
                            )}
                            {item.editMode === false && !isEditing && (
                              <Tooltip title={"Edit Item"} placement="left">
                                <IconButton
                                  className={
                                    item.editMode === false &&
                                    isEditing &&
                                    `deactivatedEditButton`
                                  }
                                  disabled={
                                    item.editMode === false && isEditing
                                  }
                                  onClick={() => {
                                    setIsEditing(true);
                                    manpowerList[index].editMode = true;
                                    setManpowerList([...manpowerList]);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            )}
                            <IconButton
                              onClick={() => {
                                if (item.editMode === true) {
                                  setIsEditing(false);
                                }
                                handleClickRemoveRow(item);
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <td>
                          <div className="textOnly">
                            {item.position.position}
                          </div>
                        </td>
                        <td>
                          <div className="textOnly">
                            {item.appoinmentNature}
                          </div>
                        </td>
                        <td>
                          <div className="textOnly">{item.budgetHead.head}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.jdKra}</div>
                        </td>

                        <td>
                          <div className="textOnly">{item.units}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.maxCtc}</div>
                        </td>
                        <td>{calculateTotalCtc(index)}</td>
                        <td>
                          {item.editMode === false && !isEditing && (
                            <Tooltip title={"Edit Item"} placement="left">
                              <IconButton
                                className={
                                  item.editMode === false &&
                                  isEditing &&
                                  `deactivatedEditButton`
                                }
                                disabled={item.editMode === false && isEditing}
                                onClick={() => {
                                  setIsEditing(true);
                                  manpowerList[index].editMode = true;
                                  setManpowerList([...manpowerList]);
                                }}
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          )}
                          <IconButton
                            onClick={() => {
                              if (item.editMode === true) {
                                setIsEditing(false);
                              }
                              handleClickRemoveRow(item);
                            }}
                          >
                            <Trash />
                          </IconButton>
                        </td>
                      </tr>
                    );
                  }
                })}

              <tr>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectItems">Position</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectItems"
                        id="select-Items"
                        value={allTimeFormData.position}
                        label="Position"
                        onChange={(e) => {
                          
                          const temp = { ...allTimeFormData };
                          temp.position = e.target.value;
                          setAllTimeFormData(temp);
                        }}
                      >
                        {positionList &&
                          positionList.map((position) => (
                            <MenuItem value={position}>
                              {position.position}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectItems">
                        Appointment Nature
                      </InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectItems"
                        id="select-Items"
                        value={allTimeFormData.appoinmentNature}
                        label="Appoinment Nature"
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          temp.appoinmentNature = e.target.value;
                          setAllTimeFormData(temp);
                        }}
                      >
                        <MenuItem value={"payroll"}>Payroll</MenuItem>
                        <MenuItem value={"retainer"}> Retainer </MenuItem>
                        <MenuItem value={"fullTime"}>Full time</MenuItem>
                        <MenuItem value={"contractual"}>Contractual</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectLedgerHead">Budget Head</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectLedgerHead"
                        id="select-LedgerHead"
                        label="Budget Head"
                        value={allTimeFormData.budgetHead}
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          temp.budgetHead = e.target.value;
                          setAllTimeFormData(temp);
                        }}
                      >
                        {budgetHeadList.length > 0 &&
                          budgetHeadList.map((itemName, index) => (
                            <MenuItem
                              value={itemName}
                              key={itemName.head + "_" + index}
                            >
                              {itemName.head}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label="JD/KRA"
                        variant="outlined"
                        className="formTextFieldArea"
                        fullWidth
                        size="small"
                        autoComplete="off"
                        value={allTimeFormData.jdKra}
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          temp.jdKra = e.target.value;
                          setAllTimeFormData(temp);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label="Units"
                        variant="outlined"
                        className="formTextFieldArea"
                        autoComplete="off"
                        fullWidth
                        size="small"
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                        value={allTimeFormData.units}
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          temp.units = e.target.value;
                          setAllTimeFormData(temp);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label="Max CTC"
                        variant="outlined"
                        className="formTextFieldArea"
                        autoComplete="off"
                        fullWidth
                        size="small"
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                        value={allTimeFormData.maxCtc}
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          temp.maxCtc = e.target.value;
                          setAllTimeFormData(temp);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>{canculateTempTotalCTC()}</td>

                <td>
                  <div className="iconBtnGroup">
                    {allTimeFormData.editMode === true && (
                      <Tooltip title={"Refresh row data"}>
                        <IconButton
                          onClick={(e) => {
                            initializeAllTimeForeData();
                          }}
                        >
                          <Refresh />
                        </IconButton>
                      </Tooltip>
                    )}
                    {allTimeFormData.editMode === true && (
                      <Button
                        className="saveBtn"
                        onClick={(e) => {
                          const temp = { ...allTimeFormData, editMode: false };
                          const tempBool = handleSaveAllTimeData(temp);
                          tempBool && initializeAllTimeForeData();
                        }}
                      >
                        Save
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={"6"}>
                  <div className="textOnly tvText">Total Value</div>
                </td>
                <td>
                  <div className="textOnly fw600">
                    {refreshCount && calculateTotalValue()}
                  </div>
                </td>
                <td colSpan="1"></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
}

export default ManpowerForm;
