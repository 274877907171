import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  IconButton,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { XLg } from "react-bootstrap-icons";
import DashboardServices from "../../../../../services/DashboardServices";
import { getHealthDto } from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import EXP from "../../../../dashboard/image/EXP";
import {
  EmailOutlined,
  ExpandMoreRounded,
  LocalPhoneOutlined,
  MonetizationOnOutlined,
  TranslateRounded,
} from "@mui/icons-material";
import zhPatientService from "../../../../../services/ZhPatientService";
import { useTranslation } from "react-i18next";
import TestResultInput from "../../../../scheduler/view/component/meetings/view/consultation/TestResultInput";
import { ConfirmProvider } from "material-ui-confirm";
import PatientMeetingsTabContainer from "../../../../scheduler/view/component/meetings/view/PatientMeetingsTabContainer";
import { AddTabContext } from "../../../../dashboard/view/DashboardPage";
import { toast } from "react-toastify";
import { CustomModal } from "../../../../task/view/component/Modals/CustomModal";

export const AddTestData = ({ handelCloseAddTestData, consultationData }) => {
  const { addTab, updateTab } = useContext(AddTabContext);

  const { t } = useTranslation();
  const loginUserDetail = getLoginUserDetails();
  const [unitAllDoctorList, setUnitAllDoctorList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [patient, setPatient] = useState(null);
  const [testGiven, setTestGiven] = useState();
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [extraData, setExtraData] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const getAvailableDoctorsofUnit = () => {
    const diseasetring = localStorage.getItem("unitDoctorList");
    if (diseasetring) {
      setUnitAllDoctorList(JSON.parse(diseasetring));
      return;
    }

    DashboardServices.getAvailableDoctorsofUnit(
      loginUserDetail.signleUnitId
    ).then((response) => {
      if (response.data) {
        setUnitAllDoctorList(response.data);
        localStorage.setItem("unitDoctorList", JSON.stringify(response.data));
      }
    });
  };

  useEffect(() => {
    getAvailableDoctorsofUnit();
    console.log("consultation Data", consultationData);
  }, []);

  useEffect(() => {
    if (consultationData) {
      const { patientAge, patientName, patientGender, patientProfileUrl } =
        consultationData;
      setPatient({
        patientAge,
        patientName,
        patientGender,
        patientProfileUrl,
      });

      zhPatientService
        .getTestGivenByVisitId(
          consultationData.visitId,
          consultationData.unitId
        )
        .then((response) => {
          if (response.data) {
            const uniqueTests = Array.from(
              new Set(response.data.map((item) => item.testId))
            ).map((testId) => {
              return response.data.find((item) => item.testId === testId);
            });

            setTestGiven(uniqueTests);
          }
        });

      const itemData = {
        queuePatientName: consultationData.patientName,
        // queueAttempsCount: consultationData.queueAttempsCount,
        queuePatientAge: consultationData.patientAge,
        // queueAddTime: consultationData.queueAddTime,
        queuePatientGender: consultationData.patientGender,
        queuePatientProfileUrl: consultationData.patientProfileUrl,
        queueConsultationType: consultationData.consultationType,
        queueVitals: consultationData.vitals,
        // queueVitals: "",
        queueComplaints: consultationData.complaints,
        queueReferredBy: consultationData.referredBy,
        queueClinicName: consultationData.clinicName,
        queueClinicContactNo: consultationData.clinicContactNo,
        queuePatientType: consultationData.patientType,
        queueVisitId: consultationData.visitId,
        queuePatientId: consultationData.patientId,
        queuePatientUnitId: consultationData.unitId,
        queueRoomId: consultationData.roomId,
        // reqValue: response.data,
        isDoctor: loginUserDetail.zhUserType === "DOCTOR" ? true : false,
      };
      setExtraData(itemData);
    }
  }, [consultationData]);

  useEffect(() => {
    const tempDoctor = unitAllDoctorList?.find((doctor) => {
      return doctor.doctorId === consultationData.doctorId;
    });
    setSelectedDoctor(tempDoctor);
  }, [unitAllDoctorList]);

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("add_test_data")}</div>
            <div className="rfcHActionBtnGrp">
              {/* <Button
                startIcon={<HeadsetMicRounded />}
                className="raiseSupportBtn"
              >
                Raise support request
              </Button> */}
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddTestData()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer">
                <div className="tskElemHeding ">{t("doctor_info")}</div>
                <div className="doctorDetails bookedSlot">
                  <div className="doctorInfoWthImg">
                    <div className="docImage">
                      <img
                        src={selectedDoctor?.doctorProfileImageUrl}
                        alt={t("doctor_image")}
                      />
                    </div>
                    <div className="doctorFullInfo">
                      <div className="doctorNmSpc">
                        <div className="docName">
                          {selectedDoctor?.doctorName}
                        </div>
                        <div className="docSpclty">
                          {` ( ${selectedDoctor?.specializationName || ""} )`}
                        </div>
                      </div>
                      <div className="docExpFeeLan">
                        <div className="docEFL">
                          <div className="eflicon fillnone">
                            <EXP />
                          </div>
                          <div className="eflText">
                            {selectedDoctor?.experience}
                          </div>
                        </div>

                        <div className="docEFL">
                          <div className="eflicon">
                            <MonetizationOnOutlined />
                          </div>
                          <div className="eflText">
                            {selectedDoctor?.fees}
                            <span> {selectedDoctor?.feesCurrency}</span>
                          </div>
                        </div>
                        <div className="docEFL">
                          <div className="eflicon">
                            <TranslateRounded />
                          </div>
                          <div className="eflText">
                            {selectedDoctor?.language?.join(", ")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tskElemHeding ">{t("patient_info")}</div>

                <div className="filterPatlistInd appoinBookPatList">
                  <div className="filPatFullinfo">
                    <div className="filPatImg">
                      <img
                        src={patient?.patientProfileUrl}
                        alt={t("patient_image")}
                      />
                    </div>
                    <div className="filtPatDtl">
                      <div className="filtPatNm">
                        {patient?.patientName}{" "}
                        <span>{`( ${patient?.patientGender}, ${patient?.patientAge} )`}</span>
                      </div>

                      {/* <div className="docExpFeeLan">
                        <div className="docEFL">
                          <div className="eflicon ">
                            <LocalPhoneOutlined />
                          </div>
                          <div className="eflText">
                            {`${patient?.patientPhCountryCode} ${patient?.patientContactNumber}`}
                          </div>
                        </div>
                        <div className="docEFL">
                          <div className="eflicon ">
                            <EmailOutlined />
                          </div>
                          <div className="eflText">
                            {patient?.patientEmailId}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

                <Accordion
                  className="docInputElmntAcod"
                  expanded={accordionExpanded}
                  onChange={() => {
                    setAccordionExpanded((prev) => !prev);
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreRounded />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="acodHeader"
                  >
                    <div className="tskElemHeding">
                      {t("possible_investigations")}:
                      {/* {!accordionExpanded && <span>{testsString}</span>} */}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="complaintList ">
                      <ul>
                        {testGiven?.map((item, index) => (
                          <li key={index}>
                            <div className="complstDtlGrp">
                              <div className="complstDtl">
                                <div className="compntName">
                                  {item.testName}
                                </div>
                                <div className="compntName BrandMed">
                                  {item.location}
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="bb"></div>
                  </AccordionDetails>
                </Accordion>

                <TestResultInput
                  data={{ investigation: testGiven }}
                  extraData={extraData}
                />
              </div>
            </div>
          </div>
          <div className="rfContFooter">
            <div className="doctorSelectWthSubBtn">
              <div className="formBtnElement">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={async () => {
                    const value = await zhPatientService.connectDoctorAfterTest(
                      consultationData.visitId
                    );
                    // if(value){
                    //   toast.
                    // }

                    const itemData = {
                      queuePatientName: consultationData.patientName,
                      // queueAttempsCount: data.queueAttempsCount,
                      queuePatientAge: consultationData.patientAge,
                      // queueAddTime: data.queueAddTime,
                      queuePatientGender: consultationData.patientGender,
                      queuePatientProfileUrl:
                        consultationData.patientProfileUrl,
                      queueConsultationType: "OPD",
                      // queueVitals: [],
                      queueVitals: "",
                      queueComplaints: "",
                      queueReferredBy: consultationData.referredBy || "",
                      queueClinicName: consultationData.clinicName || "",
                      queueClinicContactNo:
                        consultationData.clinicContactNo || "",
                      queuePatientType: "New",
                      queueVisitId: consultationData.visitId,
                      queuePatientId: consultationData.patientId,
                      queuePatientUnitId: loginUserDetail.signleUnitId,
                      queueRoomId: consultationData.roomId,
                      reqValue: {},
                      isDoctor:
                        loginUserDetail.zhUserType === "DOCTOR" ? true : false,
                      noFeedback: true,
                    };

                    const eventDto = {
                      eventTitle: `${itemData.queueVisitId}) ${itemData.queuePatientName}`,
                      eventDescription: `Consultation with ${itemData.queuePatientName}`,
                      eventStartTime: new Date().toString(),
                      eventEndTime: new Date(
                        Date.now() + 20 * 60 * 1000
                      ).toString(),
                      eventId: itemData.queueVisitId,
                      eventType: "Consultation",
                      eventLength: 20,
                      eventLink: itemData.queueRoomId,
                    };

                    addTab({
                      key: eventDto.eventId,
                      title: eventDto.eventTitle,
                      content: (
                        <ConfirmProvider>
                          <PatientMeetingsTabContainer
                            eventData={eventDto}
                            tabKey={eventDto.eventId}
                            extraData={itemData}
                          />
                        </ConfirmProvider>
                      ),
                      isDraggable: true,
                      type: "Meeting",
                    });
                  }}
                >
                  {t("connect_to_doctor")}
                </Button>

                {/* <Button className="dfultDarkSecondaryBtn" onClick={() => {}}>
                  Cancel
                </Button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showConfirmation && (
        <CustomModal
          modalTitle={t("do_you_want_to_go_to_go_to_consultation")}
          // style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowConfirmation(false);
          }}
          className={"width90"}
        >
          <div></div>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button className="dfultDarkSecondaryBtn" onClick={() => {}}>
              {t("continue")}
            </Button>
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                setShowConfirmation(false);
              }}
            >
              {t("cancel")}
            </Button>
          </div>
        </CustomModal>
      )}
    </>
  );
};
