import { AttachFile, CloseFullscreen, OpenInFull } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useReducer, useState } from "react";
import { XLg } from "react-bootstrap-icons";
import { useForm } from "react-hook-form";
import TaskService from "../../../../../services/TaskService";
import dayjs from "dayjs";
import ReactDatePicker from "react-datepicker";
import { PageEdit, PageStar } from "iconoir-react";
import { useRef } from "react";
import { toast } from "react-toastify";
import {
  MAX_FILE_SIZE_ADD_PO_INVOICE,
  VALID_FILE_EXTENSION_ADD_PO_INVOICE,
} from "../../../../../constants/const_values";
import { convertFileToBase64_Split } from "../../../../../utils/FileUtils";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import DashboardServices from "../../../../../services/DashboardServices";
import { CustomModal } from "../Modals/CustomModal";
import DateUtils from "../../../../../utils/DateUtils";
import IntegerTextField from "../../../../../common/IntegerTextField";
import { FloatTextField } from "../../../../../common/FloatTextField";
import { useTranslation } from "react-i18next";

export const GrnForm = ({
  formDataTrans,
  taskId,
  taskNature,
  formData,
  handleClose,
}) => {
  const fullScreenStyle = {
    width: "100%",
  };
  const { t } = useTranslation();
  const loginUserDetail = getLoginUserDetails();
  const { register, reset, handleSubmit } = useForm();

  const initialState = { isExpanded: false };
  const reducer = (state, action) => {
    switch (action.type) {
      case "expandOrShrinkWindow":
        return { ...state, isExpanded: !state.isExpanded };
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [responseHeader, setResponseHeader] = useState({
    poNo: "",
    indentNo: "",
    indentId: 0,
    vendorUserId: "",
    vendorName: "",
  });

  const [itemsDetailList, setItemsDetailList] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [invoiceFileDetails, setInvoiceFileDetails] = useState({
    fileName: "",
    fileData: "",
  });
  const [taxRefreshCount, setTaxRefreshCount] = useState(1);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [showItemDetailDialog, setShowItemDetailDialog] = useState(false);
  const [showItemTaxDialog, setShowItemTaxDialog] = useState(false);
  const [tmpGrnDetails, setTmpGrnDetails] = useState({
    goodQty: 0,
    missingShortLandedUnits: 0,
    brokenTornLeakingContainers: 0,
    damagedUnits: 0,
    emptyAndLightUnits: 0,
    totalDamagedMissing: 0,
  });
  const [refreshCount, setRefreshCount] = useState(1);
  const [orgItemDataList, setOrgItemDataList] = useState([]);
  const [showOrgItemDialog, setShowOrgItemDialog] = useState(false);
  const [totalDisRefresh, setTotalDisRefresh] = useState(1);
  const [totalDiscount, setTotalDiscount] = useState({
    disPc: 0,
    disAmt: 0,
  });
  const [rcptAmt, setRcptAmt] = useState(null);

  const inputFileRef = useRef(null);

  const handleChangeInvoiceFile = async (e) => {
    const file = e.target.files[0];
    if (file.size > 1024 * 1024 * MAX_FILE_SIZE_ADD_PO_INVOICE) {
      toast.error(`Invalid File Size`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      !VALID_FILE_EXTENSION_ADD_PO_INVOICE.includes(
        file.name.split(".").pop().toLowerCase()
      )
    ) {
      toast.error(`Invalid File Type`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.info(`File Attached`, {
        position: toast.POSITION.TOP_RIGHT,
      });

      setInvoiceFileDetails({
        fileName: file.name,
        fileData: await convertFileToBase64_Split(file),
      });
    }

    inputFileRef.current.value = null;
  };

  const calculateTotalDamagedMissing = () => {
    const value =
      tmpGrnDetails.missingShortLandedUnits +
      tmpGrnDetails.brokenTornLeakingContainers +
      tmpGrnDetails.damagedUnits +
      tmpGrnDetails.emptyAndLightUnits;

    tmpGrnDetails.totalDamagedMissing = value;

    return value ? value : 0;
  };

  const handleChangeDiscount = (val, source, item) => {
    const price = item.rcptQty * item.rate;
    if (source === "disPc") {
      item.disPc = parseInt(val);
      item.disAmt = parseFloat(price / 100) * val;
    }

    if (source === "disAmt") {
      const percent = (100 * parseFloat(val)) / parseFloat(price);
      item.disAmt = parseInt(val);
      item.disPc = percent;
    }

    item.sgstPc = 0;
    item.cgstPc = 0;
    item.igstPc = 0;
    item.taxPc = 0;
    item.sgstAmt = 0;
    item.cgstAmt = 0;
    item.igstAmt = 0;
    item.taxAmt = 0;
    item.taxClicked = false;
    setRefreshCount(refreshCount + 1);
  };

  const [tmpTaxInfo, setTmpTaxInfo] = useState({
    sgstPc: 0,
    cgstPc: 0,
    igstPc: 0,
    taxPc: 0,
    sgstAmt: 0,
    cgstAmt: 0,
    igstAmt: 0,
    taxAmt: 0,
  });

  const handleSelectNotAvailableItem = (item) => {
    setSelectedItem(item);
    TaskService.getItems(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        setOrgItemDataList(response.data);
        setShowOrgItemDialog(true);
      }
    });
  };

  useEffect(() => {
    if (formData) {
      TaskService.getPoInfoBySystemPoNo(formDataTrans).then((response) => {
        if (response.data) {
          const { poNo, indentNo, indentId, vendorUserId, vendorName, items } =
            response.data;
          setResponseHeader({
            poNo,
            indentNo,
            indentId,
            vendorUserId,
            vendorName,
          });

          let tempList = [];
          for (let i = 0; i < items.length; i++) {
            const item = items[i];
            tempList.push({
              poQty: item.poQty,
              rcptQty: item.poQty,
              dlWbQty: item.poQty,
              goodQty: item.poQty,
              missingShortLandedUnits: 0,
              brokenTornLeakingContainers: 0,
              damagedUnits: 0,
              emptyAndLightUnits: 0,
              totalDamagedMissing: 0,

              purchaseDtlId: item.purchaseDtlId,
              itemId: item.itemId,
              itemName: item.itemName,
              itemDescription: item.itemDescription,
              rate: item.rate,
              value: item.value,

              sgstPc: item.sgstPc,
              cgstPc: item.cgstPc,
              igstPc: item.igstPc,
              taxPc: item.taxPc,
              sgstAmt: item.sgstAmt,
              cgstAmt: item.cgstAmt,
              igstAmt: item.igstAmt,
              taxAmt: item.taxAmt,
              unitCost: item.unitCost,
              disPc: 0,
              disAmt: 0,

              uom: item.uom,
              batchNo: "",
              expiryDate: dayjs(new Date()).format("DD-MM-YYYY"),
              mrp: 0,
              purpose: item.purpose,

              // tmp values
              tmpExpiryDate: new Date(),
              grnClicked: false,
              taxClicked: false,
            });
          }
          setItemsDetailList(tempList);
        }
      });
    }
  }, []);

  const handleChangeGstPercent = (percent, key) => {
    const totalAmount =
      selectedItem.rcptQty * selectedItem.rate - selectedItem.disAmt;
    const taxAmt = (parseFloat(totalAmount) / 100) * parseInt(percent);

    if (key === "sgstPc") {
      tmpTaxInfo["sgstPc"] = percent;
      tmpTaxInfo["sgstAmt"] = taxAmt;
      tmpTaxInfo["cgstPc"] = percent;
      tmpTaxInfo["cgstAmt"] = taxAmt;
      tmpTaxInfo["igstPc"] = 0;
      tmpTaxInfo["igstAmt"] = 0;
      tmpTaxInfo["taxPc"] = percent * 2;
      tmpTaxInfo["taxAmt"] = taxAmt * 2;
    }

    if (key === "igstPc") {
      tmpTaxInfo["sgstPc"] = 0;
      tmpTaxInfo["sgstAmt"] = 0;
      tmpTaxInfo["cgstPc"] = 0;
      tmpTaxInfo["cgstAmt"] = 0;
      tmpTaxInfo["igstPc"] = percent;
      tmpTaxInfo["igstAmt"] = taxAmt;
      tmpTaxInfo["taxPc"] = percent;
      tmpTaxInfo["taxAmt"] = taxAmt;
    }

    if (key === "taxPc") {
      tmpTaxInfo["sgstPc"] = 0;
      tmpTaxInfo["sgstAmt"] = 0;
      tmpTaxInfo["cgstPc"] = 0;
      tmpTaxInfo["cgstAmt"] = 0;
      tmpTaxInfo["igstPc"] = 0;
      tmpTaxInfo["igstAmt"] = 0;
      tmpTaxInfo["taxPc"] = percent;
      tmpTaxInfo["taxAmt"] = taxAmt;
    }
    setTaxRefreshCount(taxRefreshCount + 1);
  };

  const handleChangeGstAmount = (amount, key) => {
    const totalAmount =
      selectedItem.rcptQty * selectedItem.rate - selectedItem.disAmt;
    const percent = (100 * parseFloat(amount)) / parseFloat(totalAmount);

    if (key === "sgstAmt") {
      tmpTaxInfo["sgstPc"] = percent;
      tmpTaxInfo["sgstAmt"] = amount;
      tmpTaxInfo["cgstPc"] = percent;
      tmpTaxInfo["cgstAmt"] = amount;
      tmpTaxInfo["igstPc"] = 0;
      tmpTaxInfo["igstAmt"] = 0;
      tmpTaxInfo["taxPc"] = percent * 2;
      tmpTaxInfo["taxAmt"] = amount * 2;
    }

    if (key === "igstAmt") {
      tmpTaxInfo["sgstPc"] = 0;
      tmpTaxInfo["sgstAmt"] = 0;
      tmpTaxInfo["cgstPc"] = 0;
      tmpTaxInfo["cgstAmt"] = 0;
      tmpTaxInfo["igstPc"] = percent;
      tmpTaxInfo["igstAmt"] = amount;
      tmpTaxInfo["taxPc"] = percent;
      tmpTaxInfo["taxAmt"] = amount;
    }

    if (key === "taxAmt") {
      tmpTaxInfo["sgstPc"] = 0;
      tmpTaxInfo["sgstAmt"] = 0;
      tmpTaxInfo["cgstPc"] = 0;
      tmpTaxInfo["cgstAmt"] = 0;
      tmpTaxInfo["igstPc"] = 0;
      tmpTaxInfo["igstAmt"] = 0;
      tmpTaxInfo["taxPc"] = percent;
      tmpTaxInfo["taxAmt"] = amount;
    }
    setTaxRefreshCount(taxRefreshCount + 1);
  };

  const handleChangeTotalDisCount = (value, source) => {
    const totalAmount = calculateTotalInvoiceAmt();
    if (source === "disPc") {
      const disAmt = (parseFloat(totalAmount) / 100) * parseInt(value);
      setTotalDiscount({ disPc: value, disAmt });
      setRcptAmt(totalAmount - disAmt);
    }

    if (source === "disAmt") {
      const disPc = (100 * parseFloat(value)) / parseFloat(totalAmount);
      setTotalDiscount({ disPc, disAmt: value });
      setRcptAmt(totalAmount - value);
    }

    setTotalDisRefresh(totalDisRefresh + 1);
  };

  const handleSubmitGrnData = (data) => {
    const {
      invoiceNo,
      invoiceAmt,
      dnWbNo,
      driver,
      origin,
      trailerNo,
      transportCo,
      truckNo,
    } = data;

    if (invoiceNo === "") {
      toast.error(`Please Enter Invoice Number`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (invoiceAmt === "") {
      toast.error(`Please Enter Invoice Amount`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      DateUtils.getDifferenceInMinutes(
        new Date(new Date().setHours(0, 0, 0, 0)),
        invoiceDate
      ) > 0
    ) {
      toast.error(`Please Select Correct Invoice Date`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      invoiceFileDetails.fileName === "" &&
      invoiceFileDetails.fileData === ""
    ) {
      toast.error(`Please Upload Invoice`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    // else if (dnWbNo === "") {
    //   toast.error(`Please Enter DN / WB Number`, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
    //  else if (transportCo === "") {
    //   toast.error(`Please Enter Transport Co.`, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
    //  else if (truckNo === "") {
    //   toast.error(`Please Enter Truck Number`, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
    //  else if (origin === "") {
    //   toast.error(`Please Enter Origin`, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
    // else if (driver === "") {
    //   toast.error(`Please Enter Driver`, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
    // else if (trailerNo === "") {
    //   toast.error(`Please Enter Trailer Number`, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
    else {
      let tmpItemsDetailList = [];

      for (let i = 0; i < itemsDetailList.length; i++) {
        const item = itemsDetailList[i];
        if (item.itemId === 0) {
          toast.error(
            `Please Select Item From Your Organization on Row No. ${i + 1}`,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          break;
        } else if (item.rcptQty <= 0) {
          toast.error(`Please Select Rcpt Qty on Row No. ${i + 1}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        }
        // else if (item.batchNo === "") {
        //   toast.error(`Please Enter Batch No. on Row No. ${i + 1}`, {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        //   break;
        // }
        // else if (item.mrp <= 0) {
        //   toast.error(`Please Enter  MRP on Row No. ${i + 1}`, {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        //   break;
        // }
        else if (
          DateUtils.getDifferenceInMinutes(
            new Date(new Date().setHours(0, 0, 0, 0)),
            item.tmpExpiryDate
          ) < 0
        ) {
          toast.error(`Please Select Correct Date on Row No. ${i + 1}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        } else {
          let tmpItem = {
            ...item,
            ...{
              value: item.rcptQty * item.rate,
              unitCost: item.rate + item.taxAmt - item.disAmt,
            },
          };
          tmpItemsDetailList.push(tmpItem);
        }
      }

      if (itemsDetailList.length === tmpItemsDetailList.length) {
        const reqDto = {
          orgId: loginUserDetail.orgId,
          taskDetailId: taskId,
          poNo: responseHeader.poNo,
          invoiceNo,
          invoiceDt: dayjs(invoiceDate).format("DD-MM-YYYY"),
          vendorUserId: responseHeader.vendorUserId,
          systemPoNo: parseInt(formDataTrans),
          dnWbNo,
          transportCo,
          truckNo,
          origin,
          driver,
          trailerNo,
          invoiceFileName: invoiceFileDetails.fileName,
          invoiceFileData: invoiceFileDetails.fileData,
          invoiceAmt: invoiceAmt ? parseFloat(invoiceAmt) : 0,
          rcptAmt: rcptAmt ? rcptAmt : calculateTotalInvoiceAmt(),
          indentNo: responseHeader.indentNo,
          indentId: responseHeader.indentId,
          items: tmpItemsDetailList,
        };

        console.log("postGrnEntry reqDto", reqDto);
        // console.log("postGrnEntry reqDto", JSON.stringify(reqDto));

        // return;
        TaskService.postGrnEntry(loginUserDetail.userId, reqDto).then(
          (response) => {
            if (response.data) {
              console.log(response.data);
              if (response.data.returnValue === "1") {
                toast.success(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                // handelSuccessSubmitData();
              } else {
                toast.error(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
              // setLoading(false);
            }
          }
        );
      }
    }
  };

  const calculateTotalUnitQty = () => {
    let value = 0;
    for (let i = 0; i < itemsDetailList.length; i++) {
      const { dlWbQty } = itemsDetailList[i];
      value = value + dlWbQty;
    }
    return value ? value : 0;
  };

  const calculateTotalRcptQty = () => {
    let value = 0;
    for (let i = 0; i < itemsDetailList.length; i++) {
      const { rcptQty } = itemsDetailList[i];
      value = value + rcptQty;
    }
    return value ? value : 0;
  };

  const calculateTotalDiscountAmount = () => {
    let value = 0;
    for (let i = 0; i < itemsDetailList.length; i++) {
      const { disAmt } = itemsDetailList[i];
      value = value + disAmt;
    }
    return value ? value : 0;
  };

  const calculateTotalTax = () => {
    let value = 0;
    for (let i = 0; i < itemsDetailList.length; i++) {
      const { taxAmt } = itemsDetailList[i];
      value = value + taxAmt;
    }
    return value ? value : 0;
  };

  const calculateTotalInvoiceAmt = () => {
    let value = 0;
    for (let i = 0; i < itemsDetailList.length; i++) {
      const { rcptQty, rate, disAmt, taxAmt } = itemsDetailList[i];
      value = value + rcptQty * rate + taxAmt - disAmt;
    }
    return value ? value : 0;
  };

  return (
    <>
      <div
        className="RightFlotingFormContainerArea"
        style={state.isExpanded ? fullScreenStyle : {}}
      >
        <div className="flottingContainer">
          <div className="flotHeaderPanel">
            <div class="flotHedCon">GRN</div>
            <div class="flotActionBtnGrp">
              <div class="flotActBtn closeBtnD10">
                <Tooltip
                  arrow
                  title={
                    state.isExpanded ? t("exit_full_screen") : t("enter_full_screen")
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      dispatch({ type: "expandOrShrinkWindow" });
                    }}
                  >
                    {state.isExpanded ? <CloseFullscreen /> : <OpenInFull />}
                    {/* <Expand /> */}
                  </IconButton>
                </Tooltip>
              </div>
              <div class="flotActBtn closeBtnD10">
                <IconButton onClick={handleClose}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="flotContain">
            <div className="elementFormContainer  ">
              <div className="formElementGroup flex-wrap">
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      className="formTextFieldArea"
                      size="small"
                      label={t("po_number")}
                      value={responseHeader.poNo}
                      aria-readonly={"true"}
                    />
                  </FormControl>
                </div>

                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      className="formTextFieldArea"
                      size="small"
                      label={t("invoice_number")}
                      required
                      {...register("invoiceNo")}
                    />
                  </FormControl>
                </div>

                <div className="formElement width13">
                  <FormControl className="formControl">
                    <InputLabel
                      id="setCompletionDate"
                      className="setCompletionDate"
                    >
                     {t("invoice_date")}
                    </InputLabel>
                    <ReactDatePicker
                      showIcon
                      labelId="setCompletionDate"
                      className="formDatePicker"
                      selected={invoiceDate}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        console.log("new date = ", date);
                        setInvoiceDate(date);
                      }}
                    />
                  </FormControl>
                </div>

                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      className="formTextFieldArea"
                      size="small"
                      required
                      label={t("invoice_amount")}
                      type="number"
                      step={1.0}
                      inputProps={{ min: 0 }}
                      {...register("invoiceAmt")}
                    />
                  </FormControl>
                </div>

                <div className={`formBtnElement `}>
                  <Button
                    variant="outlined"
                    startIcon={<AttachFile />}
                    className={`comBtnOutline  ${
                      invoiceFileDetails.fileName !== "" ? "activeStatus" : ""
                    }`}
                    onClick={() => inputFileRef.current.click()}
                  >
                    {t("attach_file")}
                  </Button>
                  <input
                    type="file"
                    hidden
                    ref={inputFileRef}
                    onChange={handleChangeInvoiceFile}
                  />
                </div>
              </div>

              <div class="tskElemHeding mt10">{t("grn_info")}</div>
              <div className="formElementGroup flex-wrap">
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      className="formTextFieldArea"
                      size="small"
                      label={t("dn_wb_no")}
                      // required
                      {...register("dnWbNo")}
                    />
                  </FormControl>
                </div>

                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      className="formTextFieldArea"
                      size="small"
                      label={t("transport_co")}
                      // required
                      {...register("transportCo")}
                    />
                  </FormControl>
                </div>

                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      className="formTextFieldArea"
                      size="small"
                      label={t("truck_no")}
                      // required
                      {...register("truckNo")}
                    />
                  </FormControl>
                </div>
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      className="formTextFieldArea"
                      size="small"
                      label={t("origin")}
                      // required
                      {...register("origin")}
                    />
                  </FormControl>
                </div>
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      className="formTextFieldArea"
                      size="small"
                      label={t("driver")}
                      // required
                      {...register("driver")}
                    />
                  </FormControl>
                </div>
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      className="formTextFieldArea"
                      size="small"
                      label={t("trailer_no")}
                      // required
                      {...register("trailerNo")}
                    />
                  </FormControl>
                </div>

                <div className="indentFormtable">
                  <div className="indtableResponsive">
                    <table>
                      <thead>
                        {/* <tr>
                          <th>Item Name</th>
                          <th>Item Description</th>
                          <th>DN / WB Unit QTY </th>
                          <th>GRN Details</th>
                          <th>RCPT QTY</th>
                          <th>Discount %</th>
                          <th>Discount Amount</th>
                          <th>Tax</th>
                          <th>UOM</th>
                          <th>Batch No.</th>
                          <th>MRP </th>
                          <th>Exp Date</th>
                        </tr> */}
                         <tr>
          <th>{t('itemName')}</th>
          <th>{t('itemDescription')}</th>
          <th>{t('dnWbUnitQty')}</th>
          <th>{t('grnDetails')}</th>
          <th>{t('rcptQty')}</th>
          <th>{t('discountPercent')}</th>
          <th>{t('discountAmount')}</th>
          <th>{t('tax')}</th>
          <th>{t('uom')}</th>
          <th>{t('batchNo')}</th>
          <th>{t('mrp')}</th>
          <th>{t('expDate')}</th>
        </tr>
                      </thead>
                      <tbody>
                        {itemsDetailList.length > 0 &&
                          itemsDetailList.map((item, index) => {
                            return (
                              <tr>
                                <td>
                                  {/* {item.itemName} */}
                                  {item.itemId !== 0 ? (
                                    item.itemName
                                  ) : (
                                    <p>
                                      {item.itemName}
                                      <br />
                                      <span style={{ fontSize: "10px" }}>
                                        {" "}
                                        {t('notAvailable')} {" "}
                                        <u
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleSelectNotAvailableItem(item)
                                          }
                                        >
                                          {t("select")}
                                        </u>{" "}
                                        {t("from_organization")}
                                      </span>
                                    </p>
                                  )}
                                </td>

                                <td>{item.itemDescription}</td>

                                <td style={{ maxWidth: "50px" }}>
                                  <TextField
                                    className="formTextFieldArea"
                                    size="small"
                                    type="number"
                                    step={1.0}
                                    inputProps={{ min: 0 }}
                                    value={refreshCount > 0 && item.dlWbQty}
                                    onChange={(e) => {
                                      item.dlWbQty = parseInt(e.target.value);
                                      setRefreshCount(refreshCount + 1);
                                    }}
                                  />
                                </td>

                                <td textAlign={"center"}>
                                  <PageStar
                                    className={
                                      item.grnClicked ? `selectedIcon` : ``
                                    }
                                    cursor={"pointer"}
                                    onClick={() => {
                                      setSelectedItem(item);
                                      setShowItemDetailDialog(true);
                                      setTmpGrnDetails({
                                        goodQty: item.goodQty,
                                        missingShortLandedUnits:
                                          item.missingShortLandedUnits,
                                        brokenTornLeakingContainers:
                                          item.brokenTornLeakingContainers,
                                        damagedUnits: item.damagedUnits,
                                        emptyAndLightUnits:
                                          item.emptyAndLightUnits,
                                        totalDamagedMissing:
                                          item.totalDamagedMissing,
                                      });
                                    }}
                                  />
                                </td>

                                <td>
                                  <TextField
                                    className="formTextFieldArea"
                                    style={{ minWidth: "120px" }}
                                    size="small"
                                    type="number"
                                    step={1.0}
                                    inputProps={{ min: 0, max: item.goodQty }}
                                    value={refreshCount > 0 && item.rcptQty}
                                    onChange={(e) => {
                                      item.rcptQty = parseInt(e.target.value);
                                      setRefreshCount(refreshCount + 1);
                                    }}
                                  />
                                </td>

                                <td style={{ maxWidth: "100px" }}>
                                  <TextField
                                    className="formTextFieldArea"
                                    size="small"
                                    type="number"
                                    step={1.0}
                                    inputProps={{ min: 0 }}
                                    value={refreshCount > 0 && item.disPc}
                                    onChange={(e) => {
                                      handleChangeDiscount(
                                        e.target.value,
                                        "disPc",
                                        item
                                      );
                                    }}
                                  />
                                </td>

                                <td style={{ maxWidth: "100px" }}>
                                  <TextField
                                    className="formTextFieldArea"
                                    size="small"
                                    type="number"
                                    step={1.0}
                                    inputProps={{ min: 0 }}
                                    value={refreshCount > 0 && item.disAmt}
                                    onChange={(e) => {
                                      handleChangeDiscount(
                                        e.target.value,
                                        "disAmt",
                                        item
                                      );
                                    }}
                                  />
                                </td>

                                <td textAlign={"center"}>
                                  <Tooltip
                                    title={taxRefreshCount > 0 && item.taxAmt}
                                  >
                                    <PageEdit
                                      className={
                                        item.taxClicked ? `selectedIcon` : ``
                                      }
                                      cursor={"pointer"}
                                      onClick={() => {
                                        setSelectedItem(item);
                                        setTmpTaxInfo({
                                          sgstPc: item.sgstPc,
                                          cgstPc: item.cgstPc,
                                          igstPc: item.igstPc,
                                          taxPc: item.taxPc,
                                          sgstAmt: item.sgstAmt,
                                          cgstAmt: item.cgstAmt,
                                          igstAmt: item.igstAmt,
                                          taxAmt: item.taxAmt,
                                        });
                                        setShowItemTaxDialog(true);
                                      }}
                                    />
                                  </Tooltip>
                                </td>

                                <td>{item.uom}</td>

                                <td>
                                  <TextField
                                    className="formTextFieldArea"
                                    size="small"
                                    value={refreshCount > 0 && item.batchNo}
                                    onChange={(e) => {
                                      item.batchNo = e.target.value;
                                      setRefreshCount(refreshCount + 1);
                                    }}
                                  />
                                </td>

                                <td>
                                  <TextField
                                    className="formTextFieldArea"
                                    size="small"
                                    type="number"
                                    step={1.0}
                                    inputProps={{ min: 0 }}
                                    value={refreshCount > 0 && item.mrp}
                                    onChange={(e) => {
                                      item.mrp = parseInt(e.target.value);
                                      setRefreshCount(refreshCount + 1);
                                    }}
                                  />
                                </td>

                                <td>
                                  <div className="formElement">
                                    <FormControl className="formControl">
                                      <InputLabel
                                        id="setCompletionDate"
                                        className="setCompletionDate"
                                      >
                                        {t("invoiceDate")}
                                      </InputLabel>
                                      <ReactDatePicker
                                        showIcon
                                        labelId="setCompletionDate"
                                        className="formDatePicker"
                                        selected={item.tmpExpiryDate}
                                        maxDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={(date) => {
                                          console.log("new date = ", date);
                                          item.tmpExpiryDate = date;
                                          item.expiryDate =
                                            dayjs(date).format("DD-MM-YYYY");
                                        }}
                                      />
                                    </FormControl>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={2}>{t("total")}</td>
                          <td>{refreshCount > 0 && calculateTotalUnitQty()}</td>
                          <td></td>
                          <td>{refreshCount > 0 && calculateTotalRcptQty()}</td>
                          <td></td>
                          <td>
                            {refreshCount > 0 && calculateTotalDiscountAmount()}
                          </td>
                          <td>
                            {taxRefreshCount > 0 &&
                              refreshCount > 0 &&
                              calculateTotalTax()}
                          </td>
                          <td colSpan={4}></td>
                        </tr>
                        <tr>
                          <td colSpan={6}>{t("rcpt_amount")}: </td>
                          <td colSpan={5}>
                            {taxRefreshCount > 0 &&
                              refreshCount > 0 &&
                              calculateTotalInvoiceAmt()}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>

              <div class="tskElemHeding mt10">{t("discount")}</div>
              <div className="formElementGroup flex-wrap">
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      className="formTextFieldArea"
                      size="small"
                      label={t("rcpt_amount")}
                      value={calculateTotalInvoiceAmt()}
                    />
                  </FormControl>
                </div>

                <div className="formElement width13">
                  <FormControl className="formControl">
                    <FloatTextField
                      className="formTextFieldArea"
                      size="small"
                      label={t("discountPercent")}
                      inputProps={{ min: 0 }}
                      value={totalDisRefresh > 0 && totalDiscount.disPc}
                      onChange={(data) => {
                        handleChangeTotalDisCount(data, "disPc");
                      }}
                    />
                  </FormControl>
                </div>

                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      className="formTextFieldArea"
                      size="small"
                      label={t("discountAmount")}
                      type="number"
                      step={1.0}
                      inputProps={{ min: 0 }}
                      value={totalDisRefresh > 0 && totalDiscount.disAmt}
                      onChange={(e) => {
                        handleChangeTotalDisCount(e.target.value, "disAmt");
                      }}
                    />
                  </FormControl>
                </div>
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      className="formTextFieldArea"
                      size="small"
                      label={t("rcpt_amount_after_discount")}
                      value={rcptAmt ? rcptAmt : calculateTotalInvoiceAmt()}
                    />
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
          <div className="flotFooterPanel">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={handleSubmit(handleSubmitGrnData)}
              >
                {t("submit")}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {showItemDetailDialog && (
        <CustomModal
          modalTitle={t("compare_quotation")}
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowItemDetailDialog(false);
          }}
          className={"width90vwModel"}
        >
          <div className="modBody">
            <table className="hrActivityGroupTable">
              <thead>
                {/* <tr>
                  <th>Good Condition</th>
                  <th>Missing / Short-Landed Units</th>
                  <th>Broken, Torn or Leaking Containers</th>
                  <th>Damaged Units (wet, crushed, etc.)</th>
                  <th>Empty and Light Units</th>
                  <th>Total Damaged & Missing</th>
                </tr> */}
                 <tr>
      <th>{t("goodCondition")}</th>
      <th>{t("missingShortLandedUnits")}</th>
      <th>{t("brokenTornLeakingContainers")}</th>
      <th>{t("damagedUnits")}</th>
      <th>{t("emptyAndLightUnits")}</th>
      <th>{t("totalDamagedMissing")}</th>
    </tr>
              </thead>

              <tbody>
                <th>
                  <TextField
                    size="small"
                    type="number"
                    step={1.0}
                    inputProps={{ min: 0 }}
                    value={refreshCount > 0 && tmpGrnDetails.goodQty}
                    onChange={(e) => {
                      setTmpGrnDetails({
                        ...tmpGrnDetails,
                        ...{ goodQty: parseInt(e.target.value) },
                      });
                      //   setRefreshCount(refreshCount + 1);
                    }}
                  />
                </th>

                <th>
                  <TextField
                    size="small"
                    type="number"
                    step={1.0}
                    inputProps={{ min: 0 }}
                    value={
                      refreshCount > 0 && tmpGrnDetails.missingShortLandedUnits
                    }
                    onChange={(e) => {
                      setTmpGrnDetails({
                        ...tmpGrnDetails,
                        ...{
                          missingShortLandedUnits: parseInt(e.target.value),
                        },
                      });
                      setRefreshCount(refreshCount + 1);
                    }}
                  />
                </th>

                <th>
                  <TextField
                    size="small"
                    type="number"
                    step={1.0}
                    inputProps={{ min: 0 }}
                    value={
                      refreshCount > 0 &&
                      tmpGrnDetails.brokenTornLeakingContainers
                    }
                    onChange={(e) => {
                      setTmpGrnDetails({
                        ...tmpGrnDetails,
                        ...{
                          brokenTornLeakingContainers: parseInt(e.target.value),
                        },
                      });
                      setRefreshCount(refreshCount + 1);
                    }}
                  />
                </th>

                <th>
                  <TextField
                    size="small"
                    type="number"
                    step={1.0}
                    inputProps={{ min: 0 }}
                    value={refreshCount > 0 && tmpGrnDetails.damagedUnits}
                    onChange={(e) => {
                      setTmpGrnDetails({
                        ...tmpGrnDetails,
                        ...{ damagedUnits: parseInt(e.target.value) },
                      });
                      setRefreshCount(refreshCount + 1);
                    }}
                  />
                </th>

                <th>
                  <TextField
                    size="small"
                    type="number"
                    step={1.0}
                    inputProps={{ min: 0 }}
                    value={refreshCount > 0 && tmpGrnDetails.emptyAndLightUnits}
                    onChange={(e) => {
                      setTmpGrnDetails({
                        ...tmpGrnDetails,
                        ...{ emptyAndLightUnits: parseInt(e.target.value) },
                      });
                      setRefreshCount(refreshCount + 1);
                    }}
                  />
                </th>

                <th>{refreshCount > 0 && calculateTotalDamagedMissing()}</th>
              </tbody>
            </table>
          </div>
        </CustomModal>
      )}

      {showItemTaxDialog && (
        <CustomModal
          modalTitle={t("compare_quotation")}
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowItemDetailDialog(false);
          }}
          className={"width90vwModel"}
        >
          <div className="modBody">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  type="number"
                  step={1.0}
                  inputProps={{ min: 0 }}
                  size="small"
                  label={t("sgst_percentage")}
                  value={taxRefreshCount > 0 && tmpTaxInfo.sgstPc}
                  onChange={(e) =>
                    handleChangeGstPercent(e.target.value, "sgstPc")
                  }
                />
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  type="number"
                  step={1.0}
                  inputProps={{ min: 0 }}
                  size="small"
                  label={t("sgst_amount")}
                  value={taxRefreshCount > 0 && tmpTaxInfo.sgstAmt}
                  onChange={(e) =>
                    handleChangeGstAmount(e.target.value, "sgstAmt")
                  }
                />
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  type="number"
                  step={1.0}
                  inputProps={{ min: 0 }}
                  size="small"
                  label={t("cgst_percentage")}
                  value={taxRefreshCount > 0 && tmpTaxInfo.cgstPc}
                />
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  type="number"
                  step={1.0}
                  inputProps={{ min: 0 }}
                  size="small"
                  label={t("cgst_amount")}
                  value={taxRefreshCount > 0 && tmpTaxInfo.cgstAmt}
                />
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  type="number"
                  step={1.0}
                  inputProps={{ min: 0 }}
                  size="small"
                  label={t("igst_percentage")}
                  value={taxRefreshCount > 0 && tmpTaxInfo.igstPc}
                  onChange={(e) =>
                    handleChangeGstPercent(e.target.value, "igstPc")
                  }
                />
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  type="number"
                  step={1.0}
                  inputProps={{ min: 0 }}
                  size="small"
                  label={t("igst_amount")}
                  value={taxRefreshCount > 0 && tmpTaxInfo.igstAmt}
                  onChange={(e) =>
                    handleChangeGstAmount(e.target.value, "igstAmt")
                  }
                />
              </FormControl>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};
