import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

export default function SetESI() {
  const [deductionCycle, setDeductionCycle] = useState("0");
  const selectdeductionCycle = (event) => {
    setDeductionCycle(event.target.value);
  };
  return (
    <>
      <div className="payrollContainArea">
        <div className="editPayrollElement">
          <div className="elementFormContainer">
            <div className="tskElemHeding">EPF Details</div>
            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    required
                    label="EPF Number"
                    variant="outlined"
                    className="formTextFieldArea"
                    value="MT/KOL/1245845/655"
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="selectdeductionCycle">
                    Deduction Cycle
                  </InputLabel>
                  <Select
                    className="formInputField"
                    variant="outlined"
                    labelId="selectdeductionCycle"
                    id="deductionCycle-select"
                    value={deductionCycle}
                    label="Deduction Cycle"
                    onChange={selectdeductionCycle}
                  >
                    <MenuItem value={"0"}> Monthly </MenuItem>
                    <MenuItem value={"1"}> Quarterly </MenuItem>
                    <MenuItem value={"2"}> Half-yearly </MenuItem>
                    <MenuItem value={"3"}> Annually </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="tskElemHeding">Employee's Contribution</div>
            <div className="formElementGroup frmWithOnlyText">
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    required
                    label="Employee's Contribution"
                    variant="outlined"
                    className="formTextFieldArea"
                    value="0.75%"
                  />
                </FormControl>
              </div>
              <div className="OnlyText">of Gross Pay</div>
            </div>

            <div className="tskElemHeding">Employer's Contribution</div>
            <div className="formElementGroup frmWithOnlyText">
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    required
                    label="Employer's Contribution"
                    variant="outlined"
                    className="formTextFieldArea"
                    value="3.25%"
                  />
                </FormControl>
              </div>
              <div className="OnlyText">of Gross Pay</div>
            </div>

            <div className="formElement ">
              <FormControl className="formControl">
                <FormGroup className="formRadioGroup">
                  <FormControlLabel
                    className="formRadioField"
                    control={<Checkbox checked />}
                    label="Include employer's contribution in the CTC"
                  />
                </FormGroup>
              </FormControl>
            </div>
            <div className="fromNotes ">
              <strong>Note:</strong> ESI deduction will be made only if the
              employee's monthly salaryis less than or equalmto 21,000. If the
              employee gets a salary revision which increases their monthly
              salary above 21,000. they would have to continue making ESI
              contributions till the end of the contribution period in which the
              salary wad revised (April - September or October - March).
            </div>
            <div className="bbsolid"></div>
            <div className="formBtnElement">
              <Button className="dfultPrimaryBtn ">Save</Button>
              <Button className="dfultDarkSecondaryBtn">Cancel</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
