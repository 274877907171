import { DoNotDisturb } from "@mui/icons-material";
import { FormControlLabel, Switch } from "@mui/material";
import React, { useState } from "react";

export default function SetLWF() {
  const [isEnabled, setIsEnabled] = useState(true);
  const handleEnableDisable = (event) => {
    setIsEnabled(event.target.checked);
  };
  return (
    <>
      <div className="payrollContainArea">
        <div className="editPayrollElement width850">
          <div className="elementFormContainer">
            <div className="tskElemHeding">EPF Details</div>
            <div className="OnlyText">
              This tax is levied on an employee's income by the State
              Government. tax slabs differ in each state.
            </div>
            <div className="PTContainerGrp">
              <div className="PTElementInd">
                <div className="PTHeader">
                  <div className="PTTitle">Puducherry</div>
                </div>
                <div className="PTEItemWithNoPT">
                  <span className="PTEItmFCNoPT">
                    <DoNotDisturb />
                  </span>
                  <span className="PTEItmSCNoPT">
                    Labour Welfare Fund is not applicable for Puducherry.
                  </span>
                </div>
              </div>
              <div className="PTElementInd">
                <div className="PTHeader">
                  <div className="PTTitle">Tamil Nadu</div>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC">Employee's Contribution</span>
                  <span className="PTEItmSC">₹10.00</span>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC">Employer's Contribution</span>
                  <span className="PTEItmSC">₹20.00</span>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC">Deduction Cycle</span>
                  <span className="PTEItmSC">Yearly</span>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC"> Status</span>
                  <span className="PTEItmSC ">
                    <div className="accessControlBtn tblActionBtnGrp">
                      <FormControlLabel
                        className="couponStatusSwitch"
                        control={
                          <Switch
                            checked={isEnabled}
                            onChange={handleEnableDisable}
                            color="primary"
                          />
                        }
                        label={isEnabled ? "Enable" : "Disable"}
                      />
                    </div>
                  </span>
                </div>
              </div>
              <div className="PTElementInd">
                <div className="PTHeader">
                  <div className="PTTitle">Maharashtra</div>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC">Employee's Contribution</span>
                  <span className="PTEItmSC">₹12.00</span>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC">Employer's Contribution</span>
                  <span className="PTEItmSC">₹20.00</span>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC">Deduction Cycle</span>
                  <span className="PTEItmSC">Yearly</span>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC"> Status</span>
                  <span className="PTEItmSC">
                    <div className="accessControlBtn tblActionBtnGrp">
                      <FormControlLabel
                        className="couponStatusSwitch"
                        control={
                          <Switch
                            checked={isEnabled}
                            onChange={handleEnableDisable}
                            color="primary"
                          />
                        }
                        label={isEnabled ? "Enable" : "Disable"}
                      />
                    </div>
                  </span>
                </div>
              </div>
              <div className="PTElementInd">
                <div className="PTHeader">
                  <div className="PTTitle">Haryana</div>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC">Employee's Contribution</span>
                  <span className="PTEItmSC">₹12.00</span>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC">Employer's Contribution</span>
                  <span className="PTEItmSC">
                    0.20% of Gross Pay
                    <span className="smallText">(Max Limit: ₹25.00)</span>
                  </span>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC">2 * Employee's Contribution</span>
                  <span className="PTEItmSC">Monthly</span>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC"> Status</span>
                  <span className="PTEItmSC">
                    <div className="accessControlBtn tblActionBtnGrp">
                      <FormControlLabel
                        className="couponStatusSwitch"
                        control={
                          <Switch
                            checked={isEnabled}
                            onChange={handleEnableDisable}
                            color="primary"
                          />
                        }
                        label={isEnabled ? "Enable" : "Disable"}
                      />
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
