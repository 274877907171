import { Add, ArrowBack, Search, Visibility } from "@mui/icons-material";
import { Button, CircularProgress, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Download, Trash } from "react-bootstrap-icons";
import { getBudget } from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

export default function ViewBudget({
  handelCloseViewBudget,
  handelOpenAddBudget,
  refreshBudget,
  handleOpenViewSelectedBudget,
  closeAll
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();

  const [budgetData, setBudgetData] = useState([]);

  const [filteredBudgetData, setFilteredBudgetData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBudgetData();
  }, [refreshBudget]);

  const fetchBudgetData = () => {
    getBudget(userDetails.orgId)
      .then((response) => {
        console.log("getBudget", response.data);
        setBudgetData(response.data);
        setFilteredBudgetData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = budgetData.filter(
      (budget) =>
        budget.budgetName &&
        budget.budgetName.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredBudgetData(filteredData);
  };

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton onClick={() => {handelCloseViewBudget(); closeAll(); }}>
            <ArrowBack />
          </IconButton>
          {t("budget")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              <div className="searchTasklist">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder={t("search_budget")}
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => handelOpenAddBudget()}
                >
                  {t("budget")}
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
            {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : budgetData.length === 0 ? (
                <div class="noDataCard ">
                  <span>{t("oops")}</span>{t("no_budget_define_yet")}
                  <br />
                  <span className="sallMsg">
                  {t("click_add_budget_button_to_create_new_budget")}
                  </span>
                </div>
              ) : (

              <div className="TableContainer">
                <table className="taskListTable">
                  <thead className="taskListtableHeader">
                    <tr>
                      <th className="width100">{t("sl_no")}</th>
                      <th>{t("budget_name")}</th>
                      <th>{t("budget_for")}</th>
                      <th>{t("unit_name")}</th>
                      <th>{t("start_date")}</th>
                      <th>{t("end_date")}</th>
                      <th>{t("budget_amount")}</th>
                      <th className="width100"></th>
                    </tr>
                  </thead>
                  {/* <tbody className="scrolableTableBody">
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">1.</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">C</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Unit</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">New Budget</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          18-03-2024 &nbsp; 5:30PM
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          18-03-2024 &nbsp; 5:30PM
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">2000</div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <IconButton>
                            <Visibility />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody> */}
                  <tbody className="scrolableTableBody">
                    {filteredBudgetData.map((budget, index) => (
                      <tr key={index}>
                        <td>
                          <div className="tskLstTaskNM">{index + 1}.</div>
                        </td>
                        <td>
                          <div className="tskLstTaskNM">
                            {budget.budgetName}
                          </div>
                        </td>
                        <td>
                          <div className="tskLstTaskNM">
                            {budget.budgetFor === "C" ? t("consolidated") : t("unit")}
                          </div>
                        </td>
                        <td>
                          <div className="tskLstTaskNM">{budget.unitName === "" ? t("N_A") : budget.unitName}</div>
                        </td>
                        <td>
                          <div className="tskLstTaskNM">{budget.startDate}</div>
                        </td>
                        <td>
                          <div className="tskLstTaskNM">{budget.endDate}</div>
                        </td>
                        <td>
                          <div className="tskLstTaskNM">
                            {budget.budgetAmout}
                          </div>
                        </td>
                        <td>
                          <div className="tblActionBtnGrp">
                            <IconButton onClick={()=>handleOpenViewSelectedBudget(budget)}>
                              <Visibility />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              )}

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
