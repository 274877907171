import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  RadialLinearScale,
} from "chart.js";
import { Bar, Radar } from "react-chartjs-2";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { ArrowRepeat } from "react-bootstrap-icons";
import annotationPlugin from "chartjs-plugin-annotation";
import DashboardServices from "../../../../../services/DashboardServices";
import { jwtDecode } from "jwt-decode";
import { socketEndPoint } from "../../../../../constants/url_provider";
import axios from "axios";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateUtils from "../../../../../utils/DateUtils";
import { useTranslation } from "react-i18next";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  RadialLinearScale,
  annotationPlugin
);

export default function CardManagementDoctor() {
  const { t } = useTranslation();
  const userDetails = jwtDecode(localStorage.getItem("token"));

  const [doctorkData, setDoctorData] = useState({});

  // const [filterBy, setFilterBy] = useState("Patient");

  // const handleChangefilterop = (event) => {
  //   setFilterBy(event.target.value);
  // };

  const [primaryPosdate, setPrimaryPosdate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );

  const handleprimaryPosdate = (newDate) => {
    setPrimaryPosdate(newDate);
  };

  const [secondaryPosdate, setSecondaryPosdate] = useState(new Date());
  const handleSecondaryPosdate = (newDate) => {
    setSecondaryPosdate(newDate);
  };

  const getMaxDate = (date1) => {
    const date2 = new Date();
    if (!date1) {
      return date2;
    }
    const tempDate = date1 < date2 ? date1 : date2;
    return tempDate;
  };

  useEffect(() => {
    fetchFeedbackData();
  }, [primaryPosdate, secondaryPosdate]);

  const fetchFeedbackData = () => {
    const reqDto = {
      unitId: userDetails.signleUnitId,
      orgId: userDetails.orgId,
      userId: userDetails.userId,
      periodDailyMnthly: "",
      dataLimit: 0,
      feedbackFromPatientDoctor: "",
      fromDate: DateUtils.getDateInDDMMYYYY(primaryPosdate),
      toDate: DateUtils.getDateInDDMMYYYY(secondaryPosdate),
    };

    console.log("reqDto", reqDto);

    DashboardServices.refreshManagementDoctor(reqDto).then((response) => {
      console.log("response", response.data);
      setDoctorData(response.data);
      fetchOnlineDoctors();
    });
  };

  // const barLineData = {
  //   labels: [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ],
  //   datasets: [
  //     {
  //       label: "Reapt",
  //       type: "line",
  //       data: [150, 110, 130, 150, 120, 120, 180, 110, 160, 140, 120, 110],
  //       borderColor: "rgba(106, 251, 154, 1)",
  //       backgroundColor: "rgba(0, 154, 51, 1)",
  //       fill: false,
  //       tension: 0.3,
  //     },
  //     {
  //       label: "Consultation",
  //       backgroundColor: "rgba(153, 153, 153, 1)",
  //       borderColor: "rgba(206,206,206,1)",
  //       borderWidth: 1,
  //       borderRadius: 4,
  //       hoverBackgroundColor: "rgba(153, 153, 153,0.4)",
  //       hoverBorderColor: "rgba(95,104,103,1)",
  //       data: [250, 210, 300, 350, 260, 200, 280, 310, 260, 240, 340, 280],
  //     },
  //   ],
  // };

  // const barLineOptions = {
  //   responsive: true,
  //   plugins: {
  //     tooltip: {
  //       callbacks: {
  //         afterLabel: function (tooltipItem) {
  //           const avgTime = [15, 20, 18, 12];
  //           return `Avg. Time: ${avgTime[tooltipItem.dataIndex]} min`;
  //         },
  //       },
  //     },
  //     // Annotation for Average Consultation Time
  //     datalabels: {
  //       display: true,
  //       color: "black",
  //       font: {
  //         weight: "bold",
  //       },
  //       formatter: (value, context) => {
  //         const avgTime = [15, 20, 18, 12]; // Average consultation times
  //         return `Avg: ${avgTime[context.dataIndex]} min`;
  //       },
  //       anchor: "end",
  //       align: "top",
  //     },
  //     scales: {
  //       x: {
  //         stacked: true,
  //       },
  //       y: {
  //         stacked: true,
  //         beginAtZero: true,
  //         title: {
  //           display: true,
  //           text: "Number of Consultations",
  //         },
  //       },
  //     },

  //     legend: {
  //       display: false,
  //       position: "bottom",
  //       labels: {
  //         boxWidth: 16,
  //         padding: 10,
  //       },
  //     },
  //     title: {
  //       display: false,
  //       text: "",
  //     },
  //   },
  //   barThickness: 10,
  //   maxBarThickness: 20,
  //   minBarLength: 2,
  // };

  const mdSpecWiseConsult = doctorkData.mdSpecWiseConsult || [];

  const specialization =
    mdSpecWiseConsult.map((entry) => entry.specialization) || [];

  const totalConsultation =
    mdSpecWiseConsult.map((entry) => entry.totalConsultation) || [];

  const doctorConsulted =
    mdSpecWiseConsult.map((entry) => entry.doctorConsulted) || [];

  // const avgConsultTimeInSec = mdSpecWiseConsult.map(
  //   (entry) => entry.avgConsultTimeInSec/60
  // ) ||  [];

  const avgConsultTimeInSec = mdSpecWiseConsult.map((entry) =>
    parseFloat((entry.avgConsultTimeInSec / 60).toFixed())
  );

  const data = {
    labels: specialization,
    datasets: [
      {
        label: t("total_consultations"),
        data: totalConsultation,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        barThickness: 20,
      },
      {
        label: t("doctors_consulted"),
        data: doctorConsulted,
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        barThickness: 20,
      },
      // {
      //   label: "Avg Consult Time(mins)",
      //   data: avgConsultTimeInSec,
      //   backgroundColor: "rgba(255, 206, 86, 0.6)",
      //   borderColor: "rgba(255, 206, 86, 1)",
      //   borderWidth: 1,
      //   barThickness: 16,
      // }
    ],
  };

  // Options for stacked bars and annotations
  // const options = {
  //   responsive: true,
  //   plugins: {
  //     datalabels: {
  //       display: false,
  //     },
  //     legend: {
  //       display: false,
  //       position: "bottom",
  //       labels: {
  //         boxWidth: 10,
  //         color: "#d2d2d2",
  //         font: {
  //           size: 12,
  //           weight: "light",
  //         },
  //       },
  //     },

  //     // annotation: {
  //     //   annotations: [
  //     //     {
  //     //       type: "label",
  //     //       xValue: "Cardiology",
  //     //       yValue: 100,
  //     //       backgroundColor: "rgba(255, 159, 64, 0.8)",
  //     //       content: ["10min"],
  //     //       position: "top",
  //     //       yAdjust: -20,
  //     //     },
  //     //     {
  //     //       type: "label",
  //     //       xValue: "Dermatology",
  //     //       yValue: 80,
  //     //       backgroundColor: "rgba(255, 159, 64, 0.8)",
  //     //       content: ["15min"],
  //     //       position: "top",
  //     //       yAdjust: -20,
  //     //     },
  //     //     {
  //     //       type: "label",
  //     //       xValue: "Neurology",
  //     //       yValue: 60,
  //     //       backgroundColor: "rgba(255, 159, 64, 0.8)",
  //     //       content: ["10min"],
  //     //       position: "top",
  //     //       yAdjust: -20,
  //     //     },
  //     //     {
  //     //       type: "label",
  //     //       xValue: "Pediatrics",
  //     //       yValue: 90,
  //     //       backgroundColor: "rgba(255, 159, 64, 0.8)",
  //     //       content: ["12min"],
  //     //       position: "top",
  //     //       yAdjust: -20,
  //     //     },
  //     //     {
  //     //       type: "label",
  //     //       xValue: "Oncology",
  //     //       yValue: 50,
  //     //       backgroundColor: "rgba(255, 159, 64, 0.8)",
  //     //       content: ["14min"],
  //     //       position: "top",
  //     //       yAdjust: -20,
  //     //     },
  //     //   ],
  //     // },
  //   },
  //   scales: {
  //     x: {
  //       stacked: true,
  //     },
  //     y: {
  //       stacked: true,
  //     },
  //   },
  // };

  //   const options = {
  //   responsive: true,
  //   plugins: {
  //     datalabels: {
  //       display: false,
  //     },
  //     legend: {
  //       display: false,
  //       position: "bottom",
  //       labels: {
  //         boxWidth: 10,
  //         color: "#d2d2d2",
  //         font: {
  //           size: 12,
  //           weight: "light",
  //         },
  //       },
  //     },
  //     annotation: {
  //       annotations: [
  //         ...avgConsultTimeInSec.map((avgTime, index) => ({
  //           type: "label",
  //           xValue: specialization[index], // Specialization name
  //           yValue: avgTime,
  //           backgroundColor: "rgba(255, 159, 64, 0.8)",
  //           content: [`Avg: ${avgTime} min`],
  //           position: "top",
  //           yAdjust: -10, // Adjust position of the annotation
  //         })),
  //       ],
  //     },
  //   },
  //   scales: {
  //     x: {
  //       stacked: true,
  //     },
  //     y: {
  //       stacked: true,
  //     },
  //   },
  // };

  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 10,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
      annotation: {
        annotations: [
          ...avgConsultTimeInSec.map((avgTime, index) => ({
            type: "label",
            xValue: specialization[index],
            yValue: totalConsultation[index] + doctorConsulted[index],
            backgroundColor: "rgba(255, 159, 64, 0.0)",
            color: "rgba(0,0,0,1)",
            content: [`${avgTime}M`],
            position: "bottom",
            yAdjust: 10,
            width: 10,
            rotation: -90,
            font: {
              size: 10,
              weight: "bold",
            },
          })),
        ],
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          color: "#545454",
        },
      },
      y: {
        stacked: true,
        grid: {
          color: "#545454",
        },
      },
    },
  };

  // Bar Chart Data

  const [onlineDoctorList, setOnlineDoctorList] = useState([]);
  const [specializationData, setSpecializationData] = useState([]);

  useEffect(() => {
    fetchOnlineDoctors();
  }, [doctorkData]);

  const fetchOnlineDoctors = async () => {
    try {
      const response = await axios.get(
        `${socketEndPoint}/api/health/getOnlineDoctor`
      );

      console.log("response online doctor", response);

      // if (!response.ok) {
      //   throw new Error(`HTTP error! status: ${response.status}`);
      // }

      const data = response.data;
      const onlineDoctorIds = Array.isArray(data) ? data : [];
      setOnlineDoctorList(onlineDoctorIds);
      processSpecializationCounts(onlineDoctorIds);
    } catch (error) {
      console.error("Error fetching online doctors:", error);
    }
  };

  const processSpecializationCounts = (onlineDoctorIds) => {
    console.log("onlineDoctorIds", onlineDoctorIds);
    console.log(
      "doctorkData.mdDocSpecConsulted ",
      doctorkData.mdDocSpecConsulted
    );
    const mdDocSpecConsulted = doctorkData.mdDocSpecConsulted || [];
    const specializationWithCount = new Map();

    mdDocSpecConsulted.forEach((data) => {
      const mapData = specializationWithCount.get(data.specialization) || {
        specializationCount: 0,
        consultedCount: 0,
        onlineCount: 0,
      };

      console.log(
        `Doctor ID: ${data.doctorId}, Online Doctor IDs: ${onlineDoctorIds}`
      );

      mapData.specializationCount += 1;
      mapData.consultedCount += data.consulted === "Y" ? 1 : 0;
      mapData.onlineCount += onlineDoctorIds.includes(data.doctorId) ? 1 : 0;

      specializationWithCount.set(data.specialization, mapData);
    });

    const chartData = Array.from(
      specializationWithCount,
      ([specialization, counts]) => ({
        specialization,
        ...counts,
      })
    );

    setSpecializationData(chartData);
  };

  console.log("specializationData", specializationData);

  const dataRadar = {
    labels: specializationData.map((item) => item.specialization),
    datasets: [
      {
        label: t("doctor_statistics"),
        data: specializationData.map((item) => item.specializationCount),
        backgroundColor: "rgba(34, 202, 236, 0.6)",
        borderColor: "rgba(34, 202, 236, 1)",
        borderWidth: 2,
        pointBackgroundColor: "rgba(34, 202, 236, 1)",
      },
      {
        label: t("consulted_doctors"),
        data: specializationData.map((item) => item.consultedCount),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
        pointBackgroundColor: "rgba(255, 99, 132, 1)",
      },
      {
        label: t("online_doctors"),
        data: specializationData.map((item) => item.onlineCount),
        backgroundColor: "rgba(0, 165, 13, 0.6)",
        borderColor: "rgba(0, 165, 13, 1)",
        borderWidth: 2,
        pointBackgroundColor: "rgba(0, 165, 13, 1)",
      },
    ],
  };

  // const dataRadar = {
  //   labels: [
  //     "Running",
  //     "Swimming",
  //     "Cycling",
  //     "Weight Lifting",
  //     "Yoga",
  //     "Dancing",
  //   ],
  //   datasets: [
  //     {
  //       label: "Athlete A",
  //       data: [13, 15, 18, 11, 10, 12],
  //       backgroundColor: "rgba(34, 202, 236, 0.2)",
  //       borderColor: "rgba(34, 202, 236, 1)",
  //       borderWidth: 2,
  //       pointBackgroundColor: "rgba(34, 202, 236, 1)",
  //     },
  //     {
  //       label: "Athlete B",
  //       data: [18, 14, 10, 19, 16, 17],
  //       backgroundColor: "rgba(255, 99, 132, 0.2)",
  //       borderColor: "rgba(255, 99, 132, 1)",
  //       borderWidth: 2,
  //       pointBackgroundColor: "rgba(255, 99, 132, 1)",
  //     },
  //   ],
  // };

  const optionsRadar = {
    scales: {
      r: {
        angleLines: {
          display: true,
        },
        grid: {
          circular: true,
          display: true,
          color: "#545454",
        },
        ticks: {
          showLabelBackdrop: false,
          beginAtZero: true,
        },
        angleLines: {
          color: "#545454",
        },
      },
    },
    plugins: {
      legend: { display: false, position: "top" },
    },
  };

  const [filterBy, setFilterBy] = useState("ltPatient");

  const handleChangefilterop = (event) => {
    setFilterBy(event.target.value);
  };

  return (
    <>
      <div className="anDashIndCard manageWidth50">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
              {t("doctors")}
              <IconButton
                className={`refreshIcon`}
                onClick={() => fetchFeedbackData()}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount cardFrom ">
              {/* <FormControl size="small" className="formControl w110 ">
                <Select
                  className="formInputField"
                  labelId="filterBy"
                  id="filterBy-select"
                  value={filterBy}
                  label=""
                  onChange={handleChangefilterop}
                >
                  <MenuItem value={"ltPatient"}>RN / Patient</MenuItem>
                  <MenuItem value={"doctor"}>Doctor</MenuItem>
                </Select>
              </FormControl> */}
              <div className="formElement maxw140">
                <FormControl size="small" className="formControl w110 ">
                  <InputLabel id="primPosition" className="setCompletionDate">
                    {t("from_date")}
                  </InputLabel>
                  <ReactDatePicker
                    toggleCalendarOnIconClick
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showMonthDropdown
                    showIcon
                    labelId="primPosition"
                    className="formDatePicker"
                    selected={primaryPosdate}
                    // minDate={new Date()}
                    maxDate={primaryPosdate}
                    // maxDate={new Date()}
                    // minDate={primaryPosdate}
                    // maxDate={getMaxDate(
                    //   primaryPosdate
                    //     ? new Date(
                    //         primaryPosdate.getFullYear(),
                    //         primaryPosdate.getMonth() + 3,
                    //         primaryPosdate.getDate()
                    //       )
                    //     : null
                    // )}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      handleprimaryPosdate(date);
                    }}
                  />
                </FormControl>
              </div>
              <div className="formElement maxw140">
                <FormControl size="small" className="formControl w110 ">
                  <InputLabel id="primPosition" className="setCompletionDate">
                    {t("to_date")}
                  </InputLabel>
                  <ReactDatePicker
                    toggleCalendarOnIconClick
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showMonthDropdown
                    showIcon
                    labelId="primPosition"
                    className="formDatePicker"
                    selected={secondaryPosdate}
                    // minDate={new Date()}
                    minDate={primaryPosdate}
                    maxDate={new Date()}
                    // maxDate={getMaxDate(
                    //   primaryPosdate
                    //     ? new Date(
                    //         primaryPosdate.getFullYear(),
                    //         primaryPosdate.getMonth() + 9,
                    //         primaryPosdate.getDate()
                    //       )
                    //     : null
                    // )}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      handleSecondaryPosdate(date);
                    }}
                  />
                </FormControl>
              </div>
            </div>
          </div>

          <div className="andCrdGraf align-item-center">
            <div className=" anDLftGrf width50per">
              <div className="centTxtShow">
                <Radar
                  className="hw100"
                  type="radar"
                  data={dataRadar}
                  options={optionsRadar}
                />
              </div>
            </div>
            <div className="anDRightGrf width50per ">
              <Bar className="hw100" data={data} options={options} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
