import { React, useState, useEffect } from "react";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { Line, Pie } from "react-chartjs-2";
import { Button, IconButton } from "@mui/material";
import { ArrowRepeat, Eye } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export default function CardInvestigationAdmin({
  handleOpenViewInvestigationAdmin,
  chartDataOfItem,
  fetchDashBoardData
}) {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );
  const { t } = useTranslation();

  const data = {
    labels: ["Female ", "Male "],
    datasets: [
      {
        label: "Patient",
        data: [96, 47],
        backgroundColor: ["rgba(255, 0, 188,1)", "rgba(0, 120, 215, 1)"],
        borderColor: ["rgba(255, 0, 188, .6)", "rgba(0, 120, 215, .6)"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: "80%",
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  const barLineData = {
    // labels: [
    //   "Lipid panel",
    //   "Liver function test",
    //   "Vitamin D",
    //   "Thyroid panel",
    //   "Vitamin B12 levels",
    //   "Electroencephalogram",
    //   "Hearing test",
    //   "MRI scan",
    //   "Ultrasound",
    //   "PET scan",
    // ],
    labels : chartDataOfItem?.todayUnitWiseTestCount?.length > 0 ? chartDataOfItem.todayUnitWiseTestCount.map((item) => item.label) : [] ,
    datasets: [
      {
        label: "Test Count",
        // data: [32, 10, 23, 15, 42, 13, 20, 11, 9, 24],
        data: chartDataOfItem?.todayUnitWiseTestCount?.length > 0 
        ? chartDataOfItem.todayUnitWiseTestCount.map((item) => item.count) 
        : [],

        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const barLineOoptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  return (
    <>
      <div className="anDashIndCard">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">{t("investigation_admin")}
            <IconButton
            className={`refreshIcon`}
            onClick={fetchDashBoardData}
            >
            <ArrowRepeat />
            </IconButton>
            </div>
            <div className="andCrdUnitCount">
              <span title="Attrition">
                {/* This Month: <span className="fbold"> 215421</span> */}
              </span>

              <span title="Tenure">
                {/* This week: <span className="fbold">12154</span> */}
              </span>
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf">
              <div className="centTxtShow">
                {/* <Pie
                  className="hw100"
                  type="doughnut"
                  data={data}
                  options={options}
                /> */}
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  {/* <span className="highDataCount">143</span> */}
                </div>
              </div>
            </div>
            <div className="anDRightGrf">
              <Line
                className="hw100"
                type="line"
                data={barLineData}
                options={barLineOoptions}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>

          <div className="andCrdFooter">
            <Button
              className="dfultPrimaryBtn"
              startIcon={<Eye />}
              onClick={() => handleOpenViewInvestigationAdmin()}
            >
              {t("view")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
