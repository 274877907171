import React, { useEffect } from "react";
import { CustomModal } from "../Modals/CustomModal";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { Grid } from "@mui/material";
import DateUtils from "../../../../../utils/DateUtils";
import { useTranslation } from "react-i18next";

export const PoPreviewDialog = ({ data, effectiveDate, closeDialog, mode }) => {
  const { t } = useTranslation();
  const tokenDetails = getLoginUserDetails();
  return (
    <CustomModal
      modalTitle={t("po_preview")}
      style={{ zIndex: 10001 }}
      closeModal={() => {
        closeDialog();
      }}
      className={"width90vwModel"}
    >
      <div className="modBody">
        <div className="invoHeader">
          {mode !== "vendor" && (
            <>
              <div className="ivhLogo">
                <div className="ivhLogoImg">
                  <img
                    src={tokenDetails.orgLogo && tokenDetails.orgLogo}
                    alt="logo"
                    title="logo"
                  />
                </div>
                <div className="inhOrgName">
                  {tokenDetails.orgName && tokenDetails.orgName}
                  {/* <span>Minion</span> Technologies Pvt. Ltd. */}
                </div>
              </div>
            </>
          )}
          <div className="invhTitle">
            {mode === "vendor" ? t("quotation") : t("purchase_order")}
          </div>
        </div>

        {data && (
          <>
            <Grid item xs={12} sm={12} md={4} lg={4} p={1}>
              <div className="indentDetail">
                <h3>{t("indent_details")}</h3>
                <ul>
                  <li>
                    <span>{t("indent_no")}: </span>
                    {data.indentNo}
                  </li>
                  <li>
                    <span>{t("indent_date")}: </span>
                    {data.indentDt}
                  </li>
                  {mode !== "vendor" && (
                    <>
                      <li>
                        <span>{t("p_o_no")}: </span>
                        {t("not_yet_generated")}
                      </li>
                      <li>
                        <span>{t("p_o_date")}: </span>
                        {DateUtils.getDateInDDMMYYYY(new Date())}
                      </li>
                    </>
                  )}
                  {effectiveDate && (
                    <li>
                      <span>{t("effective_date")} : </span> {effectiveDate}
                    </li>
                  )}
                </ul>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} p={1}>
              &nbsp;
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} p={1}>
              <div className="venderDetail">
                {mode !== "vendor" && (
                  <>
                    <h3>{t("order_to")}</h3>
                    <ul>
                      <li>
                        <div className="vendContain">
                          <strong>{data.vendorName}</strong>
                        </div>
                      </li>
                      <li>
                        <div className="vendContain">
                          {data.vendorContactNo}
                        </div>
                      </li>
                      <li>
                        <div className="vendContain">{data.vendorMailId}</div>
                      </li>
                      <li>
                        <div className="vendContain">{data.vendorAddress}</div>
                      </li>
                      {/* <li>
                        <div className="vendContain">GSTIN: #INDT00023443</div>
                      </li> */}
                    </ul>
                  </>
                )}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} p={1}>
              <div className="respoTable">
                <table className="invoTable">
                  <thead>
                    <tr>
                      <th>{t("bill_to")}</th>
                      <th>{t("ship_to")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <div className="venderDetail">
                          <ul>
                            {data.billToName && (
                              <li>
                                <div className="vendContain">
                                  <strong>{data.billToName}</strong>
                                </div>
                              </li>
                            )}

                            {data.billToContactNo && (
                              <li>
                                <div className="vendContain">
                                  {data.billToContactNo}
                                </div>
                              </li>
                            )}

                            {data.billToEmailId && (
                              <li>
                                <div className="vendContain">
                                  {data.billToEmailId}
                                </div>
                              </li>
                            )}

                            {data.billToAddress && (
                              <li>
                                <div className="vendContain">
                                  <div className="addLine1">
                                    {data.billToAddress}
                                    {/* 1104,Tower-B, AF 8, Action Area 1B,
                                      Newtown, */}
                                  </div>
                                  {/* <div className="addLine1">
                                      Kolkata, West Bengal 700156.
                                    </div> */}
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      </td>

                      <td>
                        <div className="venderDetail">
                          <ul>
                            {data.shipToName && (
                              <li>
                                <div className="vendContain">
                                  <strong>{data.shipToName}</strong>
                                </div>
                              </li>
                            )}

                            {data.shipToContactNo && (
                              <li>
                                <div className="vendContain">
                                  {data.shipToContactNo}
                                </div>
                              </li>
                            )}

                            {data.shipToContactNo && (
                              <li>
                                <div className="vendContain">
                                  {data.shipToEmailId}
                                </div>
                              </li>
                            )}

                            {data.shipToAddress && (
                              <li>
                                <div className="vendContain">
                                  <div className="addLine1">
                                    {data.shipToAddress}
                                  </div>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} p={1}>
              <div className="respoTable">
                <table className="invoTable">
                  <thead>
                    <tr>
                      <th>{t("sl")}</th>
                      <th>{t("items")}</th>
                      <th>{t("description")}</th>
                      <th>{t("qty")}</th>
                      <th>{t("unit_price")}</th>
                      {tokenDetails.orgCountryCode === "356" ? (
                        <>
                          <th>{t("cgst")}</th>
                          <th>{t("sgst")}</th>
                          <th>{t("igst")}</th>
                        </>
                      ) : (
                        <th>{t("taxss")}</th>
                      )}
                      <th>{t("gst_total")}</th>
                      <th>{t("line_total")}</th>
                    </tr>
                  </thead>

                  <tbody className="invoTbody">
                    {data.items &&
                      data.items.length > 0 &&
                      data.items.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>#{index + 1}</td>
                            <td>{item.itemName}</td>
                            <td>{item.itemDescription}</td>
                            <td className="tableIntVal">{item.poQty}</td>
                            <td className="tableIntVal">{item.unitPrice}</td>
                            {tokenDetails.orgCountryCode === "356" ? (
                              <>
                                <td className="tableIntVal">{item.cgstPc}</td>
                                <td className="tableIntVal">{item.sgstPc}</td>
                                <td className="tableIntVal">{item.igstPc}</td>
                              </>
                            ) : (
                              <td className="tableIntVal">{item.taxPc}</td>
                            )}
                            <td className="tableIntVal">{item.taxAmount}</td>
                            <td className="tableIntVal">{item.lineTotal}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td
                        colSpan={tokenDetails.orgCountryCode === "356" ? 7 : 5}
                        rowSpan={data.itemCharges.length + 3}
                      ></td>
                      <td colSpan={2} className="leftalign bgSubHead">
                      {t("sub_total")}
                      </td>
                      <td colSpan={1} className="rightalign bgSubHead">
                        {data.subTotal}
                      </td>
                    </tr>
                    {data.itemCharges.map((item) => {
                      return (
                        <>
                          <tr>
                            <td colSpan={2} className="leftalign bgSubHead">
                              {item.chargesLabel}
                            </td>
                            <td colSpan={1} className="rightalign bgSubHead">
                              {item.chargesAmount}
                            </td>
                          </tr>
                        </>
                      );
                    })}

                    <tr>
                      <td colSpan={2} className="leftalign bgFootHead">
                        Total
                      </td>
                      <td colSpan={1} className="rightalign bgFootHead">
                        {data.total}
                      </td>
                    </tr>
                    {/* <tr>
                          <td colSpan={2} className="leftalign bgFootHeadlight">Pay in Advance </td>
                          <td colSpan={1} className="rightalign bgFootHeadlight">
                            50%
                          </td>
                        </tr> */}
                  </tfoot>
                </table>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className="t_cDetail">
                <h3>{t("terms_and_conditions")}:</h3>
                <ul>
                  {data.termsConditions &&
                    data.termsConditions.length > 0 &&
                    data.termsConditions.map((item) => (
                      <li>
                        {item.termsConditionsHeading &&
                          `${item.termsConditionsHeading} -`}
                        {item.termsConditions}
                      </li>
                    ))}
                </ul>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}></Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className="venBankingDetail">
                {data.paymentMode === "B" && (
                  <>
                    <h3>{t("payment_mode")} : {t("internet_banking")}</h3>
                    <ul>
                      <li>
                        <span>{t("beneficiary_name")}: </span>
                        {data.accountHolderName}
                      </li>
                      <li>
                        <span>{t("account_number")}: </span>
                        {data.accountNo}
                      </li>
                      <li>
                        <span>{t("bank_name")}: </span>
                        {data.bankName}
                      </li>
                      <li>
                        <span>{t("address")}: </span>
                        {data.bankBranch}
                      </li>
                      {data.ifscCd !== "" && (
                        <li>
                          <span>{t("ifsc_code")}: </span>
                          {data.ifscCd}
                        </li>
                      )}
                      {data.rtgsCd !== "" && (
                        <li>
                          <span>{t("rtgs_code")}: </span>
                          {data.rtgsCd}
                        </li>
                      )}
                      {data.swiftCd !== "" && (
                        <li>
                          <span>{t("swift_code")}: </span>
                          {data.swiftCd}
                        </li>
                      )}
                    </ul>
                  </>
                )}

                {data.paymentMode === "U" && (
                  <>
                    <h3>{t("payment_mode")} : {t("upi")}</h3>
                    <ul>
                      <li>
                        <span>{t("upi_id")}: </span>
                        {data.upiId}
                      </li>
                    </ul>
                  </>
                )}

                {data.paymentMode === "C" && (
                  <>
                    <h3>{t("payment_mode")} : {t("cheque")}</h3>
                    <ul>
                      <li>
                        <span>{t("beneficiary_name")}: </span>
                        {data.payeeNameInCheque}
                      </li>
                    </ul>
                  </>
                )}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="indentNoteDetail">
                {mode !== "vendor" && (
                  <>
                    <h3>{t("notes")}</h3>
                    <ul>
                      <li>
                        <span>
                        {t("please_refer_to_the_po_no_in_all_your_correspondence")}
                        </span>
                      </li>

                      <li>
                        <span>
                        {t("please_send_correspondence_regarding_this_purchase_order_to")} :
                        </span>
                      </li>
                    </ul>
                  </>
                )}

                <h3>{t("authorized_person")}</h3>
                <ul>
                  <li>
                    <span>{t("name")}: </span>
                    {data.authorisedPersonName}
                  </li>

                  <li>
                    <span>{t("phone")}: </span>
                    {data.authorisedPersonContactNo}
                  </li>

                  <li>
                    <span>{t("email")}: </span>
                    {data.authorisedPersonMailId}
                  </li>
                </ul>
              </div>
            </Grid>
          </>
        )}
      </div>
    </CustomModal>
  );
};
