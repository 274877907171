import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import TreeViewPlugin from "./plugins/TreeViewPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import "./zoyelRTE.css";
import { Suspense, useEffect, useRef, useState } from "react";
import { $generateHtmlFromNodes } from "@lexical/html";
import { Button } from "@mui/material";
import OnChangePlugin from "./plugins/OnChangePlugin";
import { ImageNode } from "./nodes/ImageNode";
import ContextMenuPlugin from "./plugins/ContextMenuPlugin";
import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin";
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  EditorState,
  RootNode,
} from "lexical";

const initialConfig = {
  namespace: "MyEditor",
  // Add theme, onError and other configuration if needed
};

function Placeholder({ placeholder }) {
  return <div className="editor-placeholder">{placeholder}</div>;
}

const editorConfig = {
  // The editor theme
  theme: ExampleTheme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
    ImageNode,
  ],
};

const initialState = {
  root: {
    children: [
      {
        children: [
          {
            detail: 0,
            format: 0,
            mode: "normal",
            style: "",
            text: "sjj sdjfl sjdl",
            type: "text",
            version: 1,
          },
          {
            detail: 0,
            format: 0,
            mode: "normal",
            style: "background-color: #dcf7be;",
            text: " lsjd lslj ksf ",
            type: "text",
            version: 1,
          },
        ],
        direction: "ltr",
        format: "right",
        indent: 0,
        type: "paragraph",
        version: 1,
      },
      {
        children: [],
        direction: null,
        format: "",
        indent: 0,
        type: "paragraph",
        version: 1,
      },
      {
        children: [],
        direction: null,
        format: "",
        indent: 0,
        type: "paragraph",
        version: 1,
      },
      {
        children: [
          {
            detail: 0,
            format: 0,
            mode: "normal",
            style: "",
            text: "Hello man",
            type: "text",
            version: 1,
          },
        ],
        direction: "ltr",
        format: "",
        indent: 0,
        type: "heading",
        version: 1,
        tag: "h1",
      },
    ],
    direction: "ltr",
    format: "",
    indent: 0,
    type: "root",
    version: 1,
  },
};

// function ExportToHtml() {
//   const [editor] = useLexicalComposerContext();
//   const handleClick = () => {
//     editor.update(() => {
//       const editorState = editor.getEditorState();
//       console.log("jsonString", editorState.toJSON());
//       const htmlString = $generateHtmlFromNodes(editor);
//       console.log("htmlString", htmlString);
//     });
//   };
//   return (
//     <>
//       <Button onClick={() => handleClick()}>Submit</Button>
//     </>
//   );
// }

export function MyLexicalPlugin({ jsonStr, mode, textString }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const str = `<p class="editor-paragraph" dir="ltr" style="text-align: right;"><span>sjj sdjfl sjdl</span><span style="background-color: rgb(220, 247, 190);"> lsjd lslj ksf </span></p><p class="editor-paragraph" dir="ltr"><br></p><p class="editor-paragraph" dir="ltr"><br></p><h1 class="editor-heading-h1" dir="ltr"><span>Hello mana</span></h1>`;

    if (mode === "json" && jsonStr) {
      editor.update(() => {
        const root = $getRoot();
        const rootNode = RootNode.clone();
        const parser = new DOMParser(str);
        const parsedHtml = parser.parseFromString(str, "text/html");
        root.updateDOM(rootNode, parsedHtml);
      });
    }
    if (mode === "text" && textString) {
      editor.update(() => {
        const root = $getRoot();
        root.clear();
        const itemArray = textString.split("\n");
        itemArray.forEach((item) => {
          const paragraphNode = $createParagraphNode();
          const textNode = $createTextNode(item);
          paragraphNode.append(textNode);
          root.append(paragraphNode);
        });
      });
    }
  }, []);
}

export default function ZoyelRTERnd(props) {
  const [editorState, setEditorState] = useState();
  // const [editor] = useLexicalComposerContext();

  // const initialConfig = {
  //   namespace: 'MyEditor',
  //   theme,
  //   onError,
  // };

  useEffect(() => {
    // setEditorState({
    //   ...editorConfig,
    //   editorState: JSON.stringify(initialState),
    // });
    console.log("props=", props.rteRawJson);
    if (props.rteRawJson) {
      console.log("editor state found");
      setEditorState({ ...editorConfig, editorState: props.rteRawJson });
    } else {
      setEditorState({ ...editorConfig });
    }
  }, []);
  function fetchHtmlAndJSON(jsonStr, htmlStr) {
    console.log("fetchHtmlAndJSON jsonStr", jsonStr);
    // console.log("fetchHtmlAndJSON htmlStr", htmlStr);
    props.setRteRawJson(JSON.stringify(jsonStr));

    props.setRteHtml(htmlStr);
  }

  function fetchText(text) {
    // console.log("fetchText jsonStr", text);
    props.setRteText(text);
  }

  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);

  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  return (
    <>
      {console.log("editor state=", editorState)}
      {editorState && (
        <>
          <LexicalComposer initialConfig={editorState}>
            <div className="editor-container">
              <ToolbarPlugin
                setImageList={props.setImageList}
                imageList={props.imageList}
              />
              <div className="editor-inner custom-scrollbar">
                <RichTextPlugin
                  contentEditable={
                    <div className="editor-scroller">
                      <div className="editor-input" ref={onRef}>
                        <ContentEditable />
                      </div>
                    </div>
                  }
                  placeholder={<Placeholder placeholder={props.placeholder} />}
                  ErrorBoundary={LexicalErrorBoundary}
                />
                <MyLexicalPlugin
                  jsonStr={JSON.stringify(initialState)}
                  textString={props.rteText}
                  mode={"text"}
                />

                <HistoryPlugin />
                {/* <TreeViewPlugin /> */}
                <AutoFocusPlugin />
                <CodeHighlightPlugin />
                <ListPlugin />
                <LinkPlugin />
                <AutoLinkPlugin />
                <ListMaxIndentLevelPlugin maxDepth={7} />
                <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                <OnChangePlugin
                  fetchHtmlAndJSON={fetchHtmlAndJSON}
                  fetchText={fetchText}
                />
                <ContextMenuPlugin />

                <FloatingTextFormatToolbarPlugin
                  anchorElem={floatingAnchorElem}
                />
                {/* <ExportToHtml /> */}
              </div>
            </div>
          </LexicalComposer>
        </>
      )}
    </>
  );
}
