const ExcalidrawApp = (props) => {
  //const hash = AppUtils.getStringOfLength(22);
  let strHash = props.roomNumber + "hxzjbbzevvscqghruynter";
  // let roomNumber = props.roomNumber + "" + props.roomNumber + "Aa";
  // let hash = props.roomNumber + "" + props.roomNumber + "" + "Absc";
  let roomNumber = strHash.substring(0, 20);
  let hash = strHash.substring(0, 22);
  let whiteboardUrl =
    `https://v.zoyel.one/whiteboard/#room=` + roomNumber + "," + hash;
  return (
    <iframe
      title="whiteboard"
      name="whiteboard_zoyel"
      src={whiteboardUrl}
      //   width="100%"
      //   height="100%"
      style={{
        height: `${props.newTab ? "80vh" : "68vh"}`,
        width: `${props.newTab ? "96vw" : "48vw"}`,
        padding: `${props.newTab ? "25px" : "0px"}`,
        border: `${props.newTab ? "0px solid" : "0px solid"}`,
      }}
    ></iframe>
  );
};

export default ExcalidrawApp;
