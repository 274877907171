import {
  Cancel,
  CancelRounded,
  CheckCircle,
  Close,
  DescriptionOutlined,
  FileCopyOutlined,
  NoteAltOutlined,
  QuestionAnswer,
  VideoCall,
} from "@mui/icons-material";
import { Box, Button, IconButton, Popover, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import userImg1 from "../images/2.jpg";
import userImg2 from "../images/3.jpg";
import userImg3 from "../images/4.jpg";
import userImg4 from "../images/5.jpg";
import { Play, Sticky, Trash, XLg } from "react-bootstrap-icons";
import {
  apiEndPoint,
  socketEndPoint,
} from "../../../../../../constants/url_provider";
import {
  copyToClipboard,
  getLoginUserDetails,
} from "../../../../../../utils/Utils";
import {
  addPrivateNotes,
  deletePrivateNotes,
  getChairpersonOrConvenerList,
  getEventParticipants,
  getMeetingAgenda,
  getMeetingRecordingLink,
  getPrivateNotes,
} from "../../../../../../services/MeetingService";
import {
  OneFingerSelectHandGesture,
  Plus,
  SpockHandGesture,
} from "iconoir-react";
import { toast } from "react-toastify";
import {
  downloadFile,
  generatePreSignedUrlS3Object,
  getEventAudioNote,
  getSubtitlesOfMeeting,
} from "../../../../../../services/VideoConferenceService";
import DateUtils from "../../../../../../utils/DateUtils";
import { meetingSocket } from "../../../../../../socket";
import { v4 as uuidv4, v4 } from "uuid";
import { ConfirmProvider } from "material-ui-confirm";
import MeetingsTabContainer from "./MeetingsTabContainer";
import { AddTabContext } from "../../../../../dashboard/view/DashboardPage";
import axios from "axios";
import { NO_PROFILE_IMAGE } from "../../../../../../constants/const_string";
import { useRef } from "react";
import TranscriptionDetailsViewOnly from "./TranscriptionDetailsViewOnly";
import {getAllFilesUploaded} from "../../../../../../services/VideoConferenceService";
import { useTranslation } from "react-i18next";

export default function MeetingsView({
  selectedEvent,
  setSelectedEvent,
  setEventData,
  showTopOptions,
  canJoinCall,
}) {
  const { addTab } = useContext(AddTabContext);
  const { t } = useTranslation();
  const loginUser = getLoginUserDetails();
  const [meetingAgenda, setMeetingAgenda] = useState([]);
  const [meetingSpecialResolution, setMeetingSpecialResolution] = useState([]);
  const [meetingOtherDocuments, setMeetingOtherDocuments] = useState([]);
  const [meetingParticipants, setMeetingParticipants] = useState([]);
  const [userPrivateNotes, setUserPrivateNotes] = useState([]);

  const [audioLink, setAudioLink] = useState();
  const [audioFileName, setAudioFileName] = useState();
  const audioUrl = useRef();
  const [showAudioModal, setShowAudioModal] = useState(false);

  const printEventDate = () => {
    return DateUtils.printEventDate(
      new Date(selectedEvent.eventStartTime),
      new Date(selectedEvent.eventEndTime)
    );

    const userLocale = navigator.language || navigator.userLanguage;
    const startDate = new Date(selectedEvent.eventStartTime);
    const endDate = new Date(selectedEvent.eventEndTime);
    if (
      startDate.getFullYear() === endDate.getFullYear() &&
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getDate() === endDate.getDate()
    ) {
      const fullDayName = startDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      const formattedDate = startDate
        .toLocaleDateString("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-");
      const formattedStartTime = startDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      const formattedEndTime = endDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      return `${fullDayName}, ${formattedDate} | ${formattedStartTime} – ${formattedEndTime}`;
    } else {
      const fullStartDayName = startDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      const formattedStartDate = startDate
        .toLocaleDateString("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-");
      const formattedStartTime = startDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      const fullEndDayName = endDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      const formattedEndDate = endDate
        .toLocaleDateString("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-");
      const formattedEndTime = endDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      return `${fullStartDayName}, ${formattedStartDate}, ${formattedStartTime} |  ${formattedEndTime}, ${formattedEndDate}, ${fullEndDayName} `;
    }
  };

  const getEventAudioNotes = () => {
    getEventAudioNote(selectedEvent.eventId).then((response) => {
      console.log(response.data);
      setAudioFileName(response.data.field1);

      if (response.data.field1 && response.data.field2) {
        generatePreSignedUrlS3Object(
          response.data.field1,
          response.data.field2
        ).then((response) => {
          console.log("response = ", response);
          audioUrl.current = response.data;
          setAudioLink(response.data);
        });
      }
    });
  };

  const [refresh, setRefresh] = useState(0);
  useEffect(() => {
    meetingSocket.on("updateDashboard", () => {
      setRefresh(refresh + 1);
    });
  }, [meetingSocket]);

  const [recordingLink, setRecordingLink] = useState(null);
  const [meetingSubtitlesPresent, setMeetingSubtitlesPresent] = useState(false);

  const [isConvenor, setIsConvenor] = React.useState(false);

  useEffect(() => {
    console.log("selectedEvent Meeting VIew", selectedEvent);

    if (selectedEvent.eventSource !== "google") {
      getMeetingAgenda(selectedEvent.eventId).then((response) => {
        for (let index = 0; index < response.data.length; index++) {
          const agendaDto = response.data[index];
          if (agendaDto.noticeBlock === "Agenda") {
            setMeetingAgenda((prevList) => [...prevList, agendaDto]);
          } else if (agendaDto.noticeBlock === "Other") {
            setMeetingOtherDocuments((prevList) => [...prevList, agendaDto]);
          } else if (agendaDto.noticeBlock === "Resolution") {
            setMeetingSpecialResolution((prevList) => [...prevList, agendaDto]);
          }
        }
      });

      getEventAudioNotes();
    }

    if (selectedEvent.eventSource !== "google") {
      getEventParticipants(selectedEvent.eventId).then((response) => {
        let sortedData = [];
        if (response.data.length > 1) {
          sortedData = response.data.sort(
            (a, b) => (b.convener === "Y") - (a.convener === "Y")
          );
        } else {
          sortedData = response.data;
        }
        console.log("sortedData-->>>", sortedData);
        setMeetingParticipants(sortedData);
      });
    } else {
      console.log("source", selectedEvent.eventSourceData);
      if (selectedEvent.eventSourceData.attendees) {
        let newList = selectedEvent.eventSourceData.attendees.map((obj) => ({
          userName: obj.email,
          convener: obj.organizer ? "Y" : "N",
          responseStatus: obj.responseStatus,
          profileImage: NO_PROFILE_IMAGE,
        }));
        setMeetingParticipants(newList);
      }
      // axios
      //   .get(
      //     `${socketEndPoint}/api/gcal/getEventParticipants/${loginUser.userId}/${selectedEvent.eventId}`
      //   )
      //   .then((response) => {
      //     console.log("respEventParticipants", response.data);
      //   });
      // setMeetingParticipants(respEventParticipants);
    }
    if (selectedEvent.eventSource !== "google") {
      getPrivateNotes(loginUser.userId, selectedEvent.eventId).then(
        (response) => {
          setUserPrivateNotes(response.data);
        }
      );

      getMeetingRecordingLink(selectedEvent.eventLink).then((response) => {
        console.log(response.data);
        if (response.data.returnValue === "1") {
          setRecordingLink(response.data.message);
        }
      });

      getSubtitlesOfMeeting(selectedEvent.eventLink).then((response) => {
        console.log(response.data);
        if (response.data && response.data.length > 0) {
          setMeetingSubtitlesPresent(true);
        }
      });

      getChairpersonOrConvenerList(selectedEvent.eventId, "convener").then(
        (response) => {
          console.log(" -->>>", response.data);
          if (response.data && response.data.length > 0) {
            if (response.data[0].userId === loginUser.userId) {
              setIsConvenor(true);
            } else {
              setIsConvenor(false);
            }
          }
        }
      );
    }
  }, [selectedEvent, showTopOptions, refresh]);

  const addPNRef = useRef(null);
  const cancelPNRef = useRef(null);
  const inputPNRef = useRef(null);
  const [pvtNote, setPvtNote] = useState(null);

  const [anchorElPN, setAnchorElPN] = React.useState(null);

  const handleClickPN = (event) => {
    setAnchorElPN(event.currentTarget);
  };

  const handleClosePN = () => {
    setAnchorElPN(null);
  };

  const openPN = Boolean(anchorElPN);
  const idPN = openPN ? "add-pvtNotePopOver" : undefined;

  const onClickDelPvtNote = (event, eventNoteDto) => {
    console.log("onClickDelPvtNote-->>", event, eventNoteDto);
    event.target.disabled = true;
    if (eventNoteDto.eventNoteId) {
      deletePrivateNotes(loginUser.userId, eventNoteDto.eventNoteId).then(
        (response) => {
          console.log(response.data);
          const updatedList = userPrivateNotes.filter(
            (userPrivateNoteDto) =>
              userPrivateNoteDto.eventNoteId !== eventNoteDto.eventNoteId
          );
          setUserPrivateNotes(updatedList);
        }
      );
    }
  };

  const onClickAddPvtNote = () => {
    console.log(pvtNote);
    if (pvtNote === null || pvtNote.trim() === "") {
      console.log("pvtNote");
      inputPNRef.current.style.borderColor = "red";
      inputPNRef.current.placeholder = t("please_add_note");
      return;
    }
    addPNRef.current.disabled = true;
    addPrivateNotes(loginUser.userId, selectedEvent.eventId, pvtNote).then(
      (response) => {
        console.log(response.data);
        getPrivateNotes(loginUser.userId, selectedEvent.eventId).then(
          (response) => {
            setUserPrivateNotes(response.data);
            handleClosePN();
            setPvtNote(null);
          }
        );
      }
    );
  };

  const [fileList, setFileList] = useState([]);

  useEffect(()=>{
    getAllFilesUploaded(selectedEvent.eventLink)
    .then((response)=>{
      console.log('getFiles Details' , response.data);
      setFileList(response.data)
    })
  },[selectedEvent.eventLink])

  const handleDownloadFile = (fileName, folderName, fileType) => {
    toast.info(t("downloading_file_please_wait"), {
      position: toast.POSITION.TOP_RIGHT,
    });
    window.open(
      apiEndPoint + `/appTest/downloadFileTest/${fileName}/${folderName}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <>
      <div className="meetingsRedOnlyView">
        <div className="meetROVContainer">
          <div className="meetTitle_Time">
            <div className="meetTitle">{selectedEvent?.eventTitle}</div>
            <div className="meetDTime">
              <span>{selectedEvent && printEventDate()}</span>
            </div>
          </div>
          <div className="meetLnkBtnGrp">
            {canJoinCall && selectedEvent.eventSource !== "google" && (
              <div className="metJonBtn">
                <Button
                  startIcon={<VideoCall viewBox="0 5 24 14" />}
                  className="joinMetBtn"
                  onClick={() => {
                    console.log("setData", selectedEvent);
                    var minutesDifference =
                      (new Date(selectedEvent.eventStartTime) - new Date()) /
                      (1000 * 60);
                    if (minutesDifference > 5) {
                      const minutesToSubtract = 5;
                      const millisecondsToSubtract =
                        minutesToSubtract * 60 * 1000;
                      toast.error(
                        t("this_meeting") +
                          selectedEvent.eventTitle +
                          t("opens_5_minutes_before_the_start_time_please_join_after") +
                          DateUtils.getDateInDDMMYYYY(
                            new Date(
                              new Date(selectedEvent.eventStartTime).getTime() -
                                millisecondsToSubtract
                            )
                          ) +
                          " " +
                          DateUtils.printAMPM(
                            new Date(
                              new Date(selectedEvent.eventStartTime).getTime() -
                                millisecondsToSubtract
                            )
                          ),
                        {
                          position: toast.POSITION.TOP_RIGHT,
                        }
                      );
                      return;
                    }
                    // setEventData(selectedEvent);
                    const key = uuidv4();
                    addTab({
                      key: key,
                      title: `${selectedEvent.eventTitle}`,
                      content: (
                        <ConfirmProvider>
                          <MeetingsTabContainer
                            eventData={selectedEvent}
                            tabKey={key}
                          />
                        </ConfirmProvider>
                      ),
                      isDraggable: true,
                      type: "Meeting",
                    });
                  }}
                >
                  Join Call
                </Button>
              </div>
            )}
            {canJoinCall && (
              <div className="metCopyLnk">
                {selectedEvent.eventSource !== "google" ? (
                  <>
                    <div className="lnkTxt">
                      {`${apiEndPoint}/join/${selectedEvent?.eventLink}`}
                    </div>
                    <div className="lnkCpyBtn">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        className="copyLink"
                        // onClick={copyToClipboard(
                        //   apiEndPoint + "/join/" + selectedEvent?.eventLink
                        // )}
                        onClick={() =>
                          navigator.clipboard.writeText(
                            apiEndPoint + "/join/" + selectedEvent?.eventLink
                          )
                        }
                      >
                        <FileCopyOutlined />
                      </IconButton>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="lnkTxt">
                      {`${selectedEvent?.eventLink}`}
                    </div>
                    <div className="lnkCpyBtn">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        className="copyLink"
                        onClick={() =>
                          navigator.clipboard.writeText(
                            selectedEvent?.eventLink
                          )
                        }
                      >
                        <FileCopyOutlined />
                      </IconButton>
                    </div>
                  </>
                )}
              </div>
            )}
            {isConvenor &&
              recordingLink != null &&
              recordingLink.split("~").map((data, index) => {
                return (
                  <div className="metCopyLnk">
                    <div className="lnkTxt">
                      {t("recording")}:{" "}
                      <a href={data} rel="noreferrer" target="_blank">
                        {t("click_to_view_recording")} {index === 0 ? "" : index}
                      </a>
                    </div>
                  </div>
                );
              })}
            {isConvenor && meetingSubtitlesPresent && (
              <div
                className="metCopyLnk"
                onClick={() => {
                  const key = v4();
                  addTab({
                    key: key,
                    title: t("transcript")+` : ${selectedEvent.eventTitle}`,
                    content: (
                      <TranscriptionDetailsViewOnly
                        handleMenuCloseButton={() => {}}
                        roomNumber={selectedEvent.eventLink}
                        selEvent={selectedEvent}
                        tabKey={key}
                      />
                    ),
                    isDraggable: true,
                    type: "Transcription",
                  });
                }}
              >
                <div className="lnkTxt">
                  {t("transcription")}: <span class="transView">{t("click_to_view")}</span>{" "}
                </div>
              </div>
            )}
            {selectedEvent.eventType === "Webinar" && (
              <div className="metCopyLnk">
                <div className="lnkTxt">
                  {"https://" +
                    window.location.host +
                    "/webinar/" +
                    selectedEvent.eventWebinarSlug}
                </div>
                <div className="lnkCpyBtn">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    className="copyLink"
                    // onClick={copyToClipboard(
                    //   "https://" +
                    //     window.location.host +
                    //     "/webinar/" +
                    //     selectedEvent.eventWebinarSlug
                    // )}
                    onClick={() =>
                      navigator.clipboard.writeText(
                        "https://" +
                          window.location.host +
                          "/webinar/" +
                          selectedEvent.eventWebinarSlug
                      )
                    }
                  >
                    <FileCopyOutlined />
                  </IconButton>
                </div>
              </div>
            )}
            {!canJoinCall &&
              (selectedEvent.eventSource !== "google" ? (
                <div className="metCopyLnk">
                  <div className="lnkTxt">{t("room_number")+` : ${selectedEvent?.eventLink}`}</div>
                </div>
              ) : (
                <>
                  <div className="lnkTxt">{`${selectedEvent?.eventLink}`}</div>
                  <div className="lnkCpyBtn">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      className="copyLink"
                      // onClick={copyToClipboard(selectedEvent?.eventLink)}
                      onClick={() =>
                        navigator.clipboard.writeText(selectedEvent?.eventLink)
                      }
                    >
                      <FileCopyOutlined />
                    </IconButton>
                  </div>
                </>
              ))}
          </div>

          {meetingAgenda.length > 0 && (
            <div class="meetAgendaElement">
              <div className="metAgendaElementTitle">
                <span>{t("agenda")}</span>
              </div>
              {meetingAgenda.map((agendaDto, index) => {
                return (
                  <>
                    <div class="metAgendaElementDtil">
                      <span>{agendaDto.noticeSubject}</span>
                      <span>
                        <>
                          {agendaDto.polling === "Y" && (
                            <OneFingerSelectHandGesture />
                          )}
                          {agendaDto.showOfHand === "Y" && <SpockHandGesture />}
                        </>
                      </span>
                    </div>
                    <div class="metAgendaElementDtil">
                      <span style={{ fontSize: "12px" }}>
                        {agendaDto.noticeDetail}
                      </span>
                    </div>
                    <div className="metAgndFilsgrp">
                      {agendaDto.fileLinks.map((agendaFiles) => {
                        return (
                          <div
                            className="metAgndFil"
                            onClick={() => {
                              downloadFile(
                                agendaFiles.fileName,
                                loginUser.orgId
                              ).then((response) => {
                                window.open(
                                  apiEndPoint +
                                    `/appTest/downloadFileTest/${agendaFiles.fileName}/${loginUser.orgId}`,
                                  "_blank",
                                  "noopener,noreferrer"
                                );
                              });
                            }}
                          >
                            <Tooltip
                              title={`${agendaFiles.fileDisplayName}`}
                              arrow
                              className="metFileTolTip"
                            >
                              <span className="metAgenFilIoc">
                                <DescriptionOutlined />
                              </span>
                              <span className="metAgenFilNm">{`${agendaFiles.fileDisplayName}`}</span>
                            </Tooltip>
                          </div>
                        );
                      })}
                    </div>
                  </>
                );
              })}
            </div>
          )}

          {meetingOtherDocuments.length > 0 && (
            <div class="meetAgendaElement">
              <div className="metAgendaElementTitle">
                <span>{t("other_documents")}</span>
              </div>
              {meetingOtherDocuments.map((agendaDto, index) => {
                return (
                  <>
                    <div class="metAgendaElementDtil">
                      <span>{agendaDto.noticeSubject}</span>
                      <span>
                        <>
                          {agendaDto.polling === "Y" && (
                            <OneFingerSelectHandGesture />
                          )}
                          {agendaDto.showOfHand === "Y" && <SpockHandGesture />}
                        </>
                      </span>
                    </div>
                    <div class="metAgendaElementDtil">
                      <span style={{ fontSize: "12px" }}>
                        {agendaDto.noticeDetail}
                      </span>
                    </div>
                    <div className="metAgndFilsgrp">
                      {agendaDto.fileLinks.map((agendaFiles) => {
                        return (
                          <div
                            className="metAgndFil"
                            onClick={() => {
                              downloadFile(
                                agendaFiles.fileName,
                                loginUser.orgId
                              ).then((response) => {
                                window.open(
                                  apiEndPoint +
                                    `/appTest/downloadFileTest/${agendaFiles.fileName}/${loginUser.orgId}`,
                                  "_blank",
                                  "noopener,noreferrer"
                                );
                              });
                            }}
                          >
                            <Tooltip
                              title={`${agendaFiles.fileDisplayName}`}
                              arrow
                              className="metFileTolTip"
                            >
                              <span className="metAgenFilIoc">
                                <DescriptionOutlined />
                              </span>
                              <span className="metAgenFilNm">{`${agendaFiles.fileDisplayName}`}</span>
                            </Tooltip>
                          </div>
                        );
                      })}
                    </div>
                  </>
                );
              })}
            </div>
          )}

          {meetingSpecialResolution.length > 0 && (
            <div class="meetAgendaElement">
              <div className="metAgendaElementTitle">
                <span>{t("special_resolution")}</span>
              </div>
              {meetingSpecialResolution.map((agendaDto, index) => {
                return (
                  <>
                    <div class="metAgendaElementDtil">
                      <span>{agendaDto.noticeSubject}</span>
                      <span>
                        <>
                          {agendaDto.polling === "Y" && (
                            <OneFingerSelectHandGesture />
                          )}
                          {agendaDto.showOfHand === "Y" && <SpockHandGesture />}
                        </>
                      </span>
                    </div>
                    <div class="metAgendaElementDtil">
                      <span style={{ fontSize: "12px" }}>
                        {agendaDto.noticeDetail}
                      </span>
                    </div>
                    <div className="metAgndFilsgrp">
                      {agendaDto.fileLinks.map((agendaFiles) => {
                        return (
                          <div className="metAgndFil">
                            <Tooltip
                              title={`${agendaFiles.fileDisplayName}`}
                              arrow
                              className="metFileTolTip"
                            >
                              <span className="metAgenFilIoc">
                                <DescriptionOutlined />
                              </span>
                              <span className="metAgenFilNm">{`${agendaFiles.fileDisplayName}`}</span>
                            </Tooltip>
                          </div>
                        );
                      })}
                    </div>
                  </>
                );
              })}
            </div>
          )}

          <div class="meetDtlElement">
            <div class="metDtlElementTitle">
              <span>{t("description")}</span>
            </div>
            <div class="metDtlElementDtil">
              <span>{selectedEvent.eventDescription}</span>
            </div>
          </div>

          {audioFileName && audioFileName.length > 0 && audioLink && (
            <div class="meetDtlElement">
              <div className="playAudioNotes">
                <IconButton
                  variant="outlined"
                  onClick={(e) => {
                    setShowAudioModal(true);
                  }}
                >
                  <Play />
                </IconButton>
                <span>{t("play_audio_message")}</span>
              </div>
            </div>
          )}

          <div class="meetDtlElement">
            <div class="metDtlElementTitle">
              <span>{t("location")}</span>
            </div>
            <div class="metDtlElementDtil">
              <span>{selectedEvent.eventLocation}</span>
            </div>
          </div>

          {fileList && fileList.length > 0 && (
            <div className="meetDtlElement" >
              <div className="metDtlElementTitle">
                Meeting Files 
              </div>
              <ul className="metDtlElementDtil">
                {fileList.map((file, index) => (
                  <li key={file.field1} style={{ marginLeft: '-20px' }}>
                    <span
                      style={{
                        cursor: "pointer",
                        color: "White",
                        textDecoration: "underline",
                      }}
                      onClick={() =>
                        handleDownloadFile(
                          file.field1,
                          file.field3,
                          file.field4
                        )
                      }
                    >
                      {file.field1}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="meetGustDtlElement">
            <div className="metGustDtlElementTitle">
              <span>{t("guests")}</span>
            </div>
            <div className="metGustLstGrp">
              {meetingParticipants.map((participantDto) => {
                return (
                  <>
                    <div className={`metGustLstInd gustAsConvenor`}>
                      <div className="metGstImg">
                        <img src={participantDto.profileImage} alt="" />
                        {/* {selectedEvent.eventSource === "google" &&
                          participantDto.responseStatus === "accepted" && (
                            <span className="metConVerd">
                              <CheckCircle />
                            </span>
                          )}
                        {selectedEvent.eventSource === "google" &&
                          participantDto.responseStatus === "declined" && (
                            <span className="metConRej">
                              <Close />
                            </span>
                          )}
                        {selectedEvent.eventSource === "google" &&
                          participantDto.responseStatus === "needsAction" && (
                            <span className="metConNS">
                              <CheckCircle />
                            </span>
                          )} */}
                        {participantDto.availability === "Y" && (
                          <span className="metConVerd" title={t("available")}>
                            <CheckCircle />
                          </span>
                        )}
                        {participantDto.availability === "N" && (
                          <span className="metConRej" title={t("unavailable")}>
                            <CancelRounded />
                          </span>
                        )}
                        {participantDto.availability !== "N" &&
                          participantDto.availability !== "Y" && (
                            <span className="metConNS">
                              <CheckCircle />
                            </span>
                          )}
                      </div>
                      <div className="metGstName">
                        {participantDto.userName}
                        {participantDto.convener === "Y" && (
                          <span className="metConvenor">({t("convenor")})</span>
                        )}
                        {participantDto.chairperson === "Y" && (
                          <span className="metConvenor">({t("chairperson")})</span>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}

              {/* <div className="metGustLstInd gustAsConvenor">
                <div className="metGstImg">
                  <img src={userImg1} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Aiyasha Hasan<span className="metConvenor">(Convenor)</span>
                </div>
              </div>

             

              <div className="metGustLstInd">
                <div className="metGstImg">
                  <img src={userImg3} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Prashant Saha<span className="metConvenor">(Convenor)</span>
                </div>
              </div>

              <div className="metGustLstInd">
                <div className="metGstImg">
                  <img src={userImg4} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Sk. Aftabuddin<span className="metConvenor">(Convenor)</span>
                </div>
              </div> */}
            </div>
          </div>

          {/* <div className="meetAddNoteBTn">
            <Button startIcon={<Sticky />}>Add Notes</Button>
          </div> */}
          {selectedEvent.eventSource !== "google" && (
            <div class="meetEdAgendaElement">
              <div class="metAgendaElementTitle">
                <span>{t("private_notes")}</span>
              </div>
              <div class="metAgendaElementEditedGrp">
                {userPrivateNotes.map((userPvtNoteDto) => {
                  return (
                    <>
                      {" "}
                      <div className="metAgendaListed">
                        <div className="metAgendaName">
                          <Tooltip title={`${userPvtNoteDto.notes}`} arrow>
                            <span
                              className="metAgendaNM"
                              style={{ maxWidth: "unset" }}
                            >
                              {userPvtNoteDto.notes}
                            </span>
                          </Tooltip>
                        </div>
                        <div className="metAgnAddMrFiles">
                          <Button
                            className="metAgendaMrFileBtn"
                            variant="outlined"
                            onClick={(event) =>
                              onClickDelPvtNote(event, userPvtNoteDto)
                            }
                          >
                            <Trash className="trashIcon" />
                          </Button>
                        </div>
                      </div>
                    </>
                  );
                })}
                <Button variant="outlined" onClick={handleClickPN}>
                  {t("add_private_notes")}
                </Button>
                <Popover
                  id={idPN}
                  open={openPN}
                  anchorEl={anchorElPN}
                  onClose={handleClosePN}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div style={{ padding: "20px" }}>
                    <div className="modelTitle" style={{ padding: "5px" }}>
                      {t("add_private_notes")}
                    </div>
                    <div
                      className="modInputFild"
                      style={{ padding: "10px 0px 10px 0px" }}
                    >
                      <input
                        value={pvtNote}
                        onChange={(event) => setPvtNote(event.target.value)}
                        type="text"
                        placeholder={t("type_here")}
                        ref={inputPNRef}
                      />
                    </div>

                    <div className="modActionBtnGrp">
                      <Button
                        className="dfultPrimaryBtn"
                        onClick={() => onClickAddPvtNote()}
                        ref={addPNRef}
                      >
                       {t("add")}
                      </Button>
                      <Button
                        className="dfultDarkSecondaryBtn"
                        onClick={handleClosePN}
                        ref={cancelPNRef}
                      >
                        {t("cancel")}
                      </Button>
                    </div>
                  </div>
                </Popover>
                {/* {userPrivateNotes.length === 0 && (
                  <div className="metAgendaListed">
                    <div className="metAgendaName">
                      <div class="metAgendaElementDtil">No Private Notes</div>
                    </div>
                  </div>
                )} */}

                {/* <div className="metAgendaListed">
                <div className="metAgendaName">
                  <Tooltip
                    title="Lorem ipsum dolor sit amet.. Lorem ipsum dolor sit amet.."
                    arrow
                  >
                    <span className="metAgendaNM">
                      Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet..
                    </span>
                  </Tooltip>
                </div>
                <div className="metAgndFilsgrp">
                  <div className="metAgndFil">
                    <Tooltip
                      title="Requisition-form.doc"
                      arrow
                      className="metFileTolTip"
                    >
                      <span className="metAgenFilIoc">
                        <DescriptionOutlined />
                      </span>
                      <span className="metAgenFilNm">Requisition-form.doc</span>
                    </Tooltip>
                  </div>
                  <div className="metAgndFil">
                    <Tooltip
                      title="Finance-doc.doc"
                      arrow
                      className="metFileTolTip"
                    >
                      <span className="metAgenFilIoc">
                        <DescriptionOutlined />
                      </span>
                      <span className="metAgenFilNm">Finance-doc.doc</span>
                    </Tooltip>
                  </div>
                </div>
                <div className="metAgnAddMrFiles">
                  <Button className="metAgendaMrFileBtn" variant="outlined">
                    <Plus />
                  </Button>
                </div>
              </div>
              <div className="metAgendaListed">
                <div className="metAgendaName">
                  <Tooltip
                    title="Lorem ipsum dolor sit amet.. Lorem ipsum dolor sit amet.."
                    arrow
                  >
                    <span className="metAgendaNM">
                      Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet..
                    </span>
                  </Tooltip>
                </div>
                <div className="metAgndFilsgrp">
                  <div className="metAgndFil">
                    <Tooltip
                      title="Requisition-form.doc"
                      arrow
                      className="metFileTolTip"
                    >
                      <span className="metAgenFilIoc">
                        <DescriptionOutlined />
                      </span>
                      <span className="metAgenFilNm">Requisition-form.doc</span>
                    </Tooltip>
                  </div>
                </div>
                <div className="metAgnAddMrFiles">
                  <Button className="metAgendaMrFileBtn" variant="outlined">
                    <Plus />
                  </Button>
                </div>
              </div> */}
              </div>
              {/* <div className="metAddMRAgendaBtn">
              <Button variant="outlined" onClick={handelOpenAgenda}>
                Add Agenda
              </Button>
              <Button variant="outlined" onClick={handleOpen}>
                Add Agenda
              </Button>
            </div> */}
            </div>
          )}

          {/* <div className="meetMOMBtn">
            <Button startIcon={<NoteAltOutlined />}>
              Minutes of the Meeting
            </Button>
          </div> */}
        </div>
      </div>

      {showAudioModal && (
        <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
          <div className="addMoreAgendaModel">
            <div className="modelTitle">{t("audio_message")}</div>
            <div className="modActionBtnGrp">
              <audio controls>
                <source src={`${audioUrl.current}`} />
              </audio>
            </div>

            <div className="modActionBtnGrp">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  setShowAudioModal(false);
                }}
              >
                {t("canvel")}
              </Button>
            </div>
          </div>
        </Box>
      )}
    </>
  );
}
