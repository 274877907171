import { Tooltip } from "@mui/material";
import React from "react";
import { useEffect } from "react";

export const ManPowerPreview = ({ manpowerList }) => {
  useEffect(() => {
    console.log("manpower data =", manpowerList);
  }, []);

  const calculateTotalCtc = (index) => {
    const { maxCtc, units } = manpowerList[index];
    const totalVal = parseInt(maxCtc) * parseInt(units);
    if (isNaN(totalVal)) {
      return 0;
    } else {
      return totalVal;
    }
  };

  const calculateTotalValue = () => {
    let totalVal = 0;
    for (let i = 0; i < manpowerList.length; i++) {
      const { maxCtc, units } = manpowerList[i];
      totalVal += parseInt(maxCtc) * parseInt(units);
    }
    return totalVal ? totalVal : 0;
  };

  return (
    <>
      <div className="indentFormtable">
        <div className="indtableResponsive">
          <table>
            <thead>
              <tr>
                <th className="indntQunt">Position</th>
                <th className="indntQunt">Appoinment Nature</th>
                <th className="indntQunt">Budget Head</th>
                <th className="indntQunt">JD/KRA</th>
                <th className="indntQunt">Number of Position</th>
                <th className="indntQunt">Maximum CTC</th>
                <th className="indntQunt">Total CTC</th>
              </tr>
            </thead>
            <tbody>
              {manpowerList &&
                manpowerList.map((item, index) => {
                  return (
                    <tr>
                      <td>
                        <div className="textOnly">{item.position.position}</div>
                      </td>
                      <td>
                        <div className="textOnly">{item.appoinmentNature}</div>
                      </td>
                      <td>
                        <div className="textOnly">{item.budgetHead.head}</div>
                      </td>
                      <td>
                        <div className="textOnly">{item.jdKra}</div>
                      </td>

                      <td>
                        <div className="textOnly">{item.units}</div>
                      </td>
                      <td>
                        <div className="textOnly">{item.maxCtc}</div>
                      </td>
                      <td>
                        <div className="textOnly">
                          {calculateTotalCtc(index)}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={"6"}>
                  <div className="textOnly tvText">Total Value</div>
                </td>
                <td>
                  <div className="textOnly fw600">{calculateTotalValue()}</div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};
