import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { ConfirmProvider, useConfirm } from "material-ui-confirm";
// import { FullScreen, useFullScreenHandle } from "react-full-screen";
import JitsiVideoConference from "./JitsiVideoConference";
import {
  createInstantMeeting,
  getEventDetailsByMeetingId,
  downloadFile,
} from "../../../../../../services/VideoConferenceService";
import { useForm } from "react-hook-form";
import { jwtDecode } from "jwt-decode";
import {
  AttachFile,
  BackHand,
  CallEnd,
  Chat,
  Description,
  DesignServices,
  FolderShared,
  GridViewOutlined,
  Groups,
  Language,
  LiveTvOutlined,
  Medication,
  MeetingRoom,
  Mic,
  MicOff,
  MoreVert,
  PersonAdd,
  Queue,
  ScreenShare,
  Speaker,
  SpeakerGroup,
  StopScreenShare,
  Videocam,
  VideocamOff,
  WallpaperOutlined,
} from "@mui/icons-material";
import { CcCircleFill, XLg } from "react-bootstrap-icons";
import MeetingTabRightPanel from "./MeetingTabRightPanel";
import { useNavigate } from "react-router-dom";
import ShareOptions from "./ShareOptions";
import { toast } from "react-toastify";
import DateUtils from "../../../../../../utils/DateUtils";
import AppUtils from "../../../../../../utils/AppUtils";
import {
  Camera,
  Check,
  OneFingerSelectHandGesture,
  PageStar,
  SpockHandGesture,
  VideoCamera,
} from "iconoir-react";
import { getMeetingAgendas } from "../../../../../../services/VideoConferenceService";
import WebinarService from "../../../../../../services/WebinarService";
import BottomOptionsTimer from "./BottomOptionsTimer";
import {
  centerSocket,
  doctorSocket,
  meetingSocket,
} from "../../../../../../socket";
import { getChairpersonOrConvenerList } from "../../../../../../services/MeetingService";
import { apiEndPoint } from "../../../../../../constants/url_provider";
import { AddTabContext } from "../../../../../dashboard/view/DashboardPage";
import { v4 } from "uuid";
import DrFeedback from "../../../../../analyticsDashboard/view/component/healthcare/DrFeedback";
import MeetingsTabContainer from "./MeetingsTabContainer";
import DashboardServices from "../../../../../../services/DashboardServices";
import zhPatientService from "../../../../../../services/ZhPatientService";
import dayjs from "dayjs";
import { getLoginUserDetails } from "../../../../../../utils/Utils";
import { useLogRxHandler } from "./consultation/useLogRxHandler";
import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../../../task/view/component/Modals/CustomModal";

export default function PatientMeetingsTabContainer(props) {
  const { t } = useTranslation();
  let loginUserDetail = getLoginUserDetails();
  const { addTab, updateTab, tabs } = useContext(AddTabContext);
  const [roomId, setRoomId] = useState(
    props.eventData ? props.eventData.eventLink : null
  );
  const [showSwitchModal, setShowSwitchModal] = useState(false);
  const [jitsi, setJitsi] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [selEvent, setSelEvent] = useState(props.eventData);
  const [jitsiObject, setJitsiObject] = useState(null);
  const [roomId2, setRoomId2] = useState(null);
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { handleFullscreen } = useContext(AddTabContext);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [prescriptionData, setPrescriptionData] = useState({});
  const [tempRxData, setTempRxData] = useState(null);

  const patientCanceledSelf = useRef(true);
  const rxData = useLogRxHandler(tempRxData, props.extraData);

  const getReferStringFromType = (refer) => {
    switch (refer) {
      case "higherFacility":
        return "Advise higher facility";

      case "immediateHospitalization":
        return "Advise immediate hospitalization";

      case "otherSpeciality":
        return "Refer to other speciality";

      case "medicoLegal":
        return "Refer to medico legal";

      default:
        return "";
    }
  };

  const prepareRxData = (data) => {
    let extraData = props.extraData;
    let procedures = [];
    let medicines = [];
    let dieseases = [];
    let lifeStyles = [];
    let diets = [];
    let paramsToMonitor = [];
    let tests = [];
    let vitals = [];
    let foodAllergies = [];
    let signs = [];
    let symptoms = [];
    let riskfactors = [];
    let medicineAllergies = [];
    let comorbidities = [];
    let pastProcedures = [];
    let pastDieseases = [];
    let possibleConditions = [];
    let possibleInvestigations = [];
    let hereditaryDiseases = [];
    let phyExams = [];

    if (data.procedure) {
      procedures = data.procedure.map((item, index) => {
        return {
          procedureName: item.procedure.procedureName,
          procedureId: item.procedure.locallyAdded ? 0 : item.procedure.id,
          planDate: dayjs(item.date).format("DD-MM-YYYY"),
          slNo: index + 1,
        };
      });
    }

    if (data.medication) {
      medicines = data.medication.map((item, index) => {
        let brandName = "";
        let brandId = 0;
        if (item.brandName) {
          brandName = item.brandName.brandName;
          brandId = item.brandName.id;
        }
        return {
          genericId: item?.genericName?.locallyAdded
            ? 0
            : item?.genericName?.id,
          brandId: brandId,
          genericName: item?.genericName?.generic,
          brandName: brandName,
          dosages: item?.dosage?.dosage,
          dosagesUnit: item?.dosage?.dosageUnit,
          route: item?.root?.frequency || "",
          durationLabel: item?.duration?.durationName,
          durationDays: item?.duration?.days,
          timing: item.timing?.join(","),
          note: item.note,
          adq: 0,
          preExisting: "",
          slNo: index + 1,
          frequency: item?.frequency?.frequency || "",
        };
      });
    }

    if (data.diagonesis) {
      dieseases = data.diagonesis.map((item, index) => {
        const diseaseId = item.diseaseObj.locallyAdded ? 0 : item.diseaseObj.id;
        return {
          diseaseName: item.diseaseObj.diseaseName,
          diseaseId,
          nature: item.status,
          snowmedCt: item.diseaseObj.snowmedCt,
          icd11: item.diseaseObj.icd11code,
          slNo: index + 1,
        };
      });
    }

    if (data.lifeStyle) {
      lifeStyles = data.lifeStyle.map((item, index) => {
        const lifeStyleId = item.locallyAdded ? 0 : item.id;
        return {
          lifeStyleName: item.lifeStyle,
          lifeStyleId: lifeStyleId,
          slNo: index + 1,
        };
      });
    }

    if (data.diet) {
      diets = data.diet.map((item, index) => {
        let dietId = item.locallyAdded ? 0 : item.id;
        return {
          dietName: item.diet,
          dietId,
          slNo: index + 1,
        };
      });
    }

    if (data.vitals) {
      vitals = data.vitals.map((item) => {
        return {
          vitalName: item.vitalName,
          unit: item.unit,
          vitalValue: item.vitalValue,
          recodedTime: item.recodedTime,
          isNormal: item.isNormal,
          vitalCode: item.vitalCode,
        };
      });
    }

    if (data.investigation) {
      tests = data.investigation.map((item) => {
        return {
          testId: item.testId,
          testName: item.testName,
          location: item.location,
          view: item.view,
          technique: item.technique,
        };
      });
    }

    if (data.itemToMonitor) {
      paramsToMonitor = data.itemToMonitor;
    }

    if (data.allergicFood) {
      foodAllergies = data.allergicFood
        .filter((item) => !item.previouslyAdded)
        .map((item) => {
          return item.foodName;
        });
    }

    if (data.singObj) {
      signs = data.singObj.map((item, index) => {
        let signId = item?.sign?.locallyAdded ? 0 : item?.sign?.id;
        return {
          signName: item?.sign?.signName,
          signId,
          signNote: item.userNote,
          slNo: index + 1,
        };
      });
    }

    if (data.symptoms) {
      symptoms = data.symptoms.map((item, index) => {
        let symptomId = item.selectedSymptom.locallyAdded
          ? 0
          : item.selectedSymptom.id;
        return {
          symptomName: item?.selectedSymptom?.symptomName,
          symptomId,
          severity: item?.severity,
          since: item?.since,
          slNo: index + 1,
        };
      });
    }

    if (data.riskFactor) {
      riskfactors = data.riskFactor.map((item, index) => {
        let riskFactorId = item.riskFactor.locallyAdded
          ? 0
          : item.riskFactor.id;
        return {
          since: item.since,
          riskFactorId,
          riskFactorName: item.riskFactor.riskFactor,
          slNo: index,
        };
      });
    }

    if (data.allergicDrug) {
      medicineAllergies = data.allergicDrug
        ?.filter((item) => !(item.previouslyAdded === true))
        ?.map((item, index) => {
          let type = "Generic";
          let genericBrandId = 0;
          let genericBrandName = "";
          if (item.brand) {
            type = "Brand";
            genericBrandId = item.brand.id;
            genericBrandName = item.brand.brandName;
          } else {
            genericBrandId = item.genericItem.locallyAdded
              ? 0
              : item.genericItem.id;
            genericBrandName = item.genericItem.generic;
          }
          return {
            type: type,
            genericBrandId: genericBrandId,
            genericBrandName: genericBrandName,
          };
        });
    }

    if (data?.comorbidity) {
      comorbidities = data?.comorbidity?.map((item, index) => {
        return {
          slNo: index + 1,
          diseaseName: item.disease.comorbiditiesDiseasesName,
          since: item.since,
        };
      });
    }

    if (data?.prevSurgery) {
      pastProcedures = data?.prevSurgery
        ?.filter((item) => !item?.surgery?.previouslyAdded)
        ?.map((item) => {
          return {
            procedureName: item.surgery.procedureName,
            procedureDate: item.date,
          };
        });
    }

    if (data?.pastIllness) {
      pastDieseases = data?.pastIllness
        ?.filter((item) => !item.illness?.previouslyAdded)
        ?.map((item) => {
          return {
            diseaseName: item.illness.diseaseName,
            diagnosisDate: dayjs(item.date).format("DD-MM-YYYY"),
          };
        });
    }

    if (data?.possibleDiagonesis) {
      possibleConditions = data?.possibleDiagonesis?.map((item) => {
        return {
          diseaseName: item.diseaseName,
          diseaseId: item.locallyAdded ? 0 : item.id,
        };
      });
    }

    if (data?.investigation) {
      possibleInvestigations = data?.investigation?.map((item) => {
        return {
          testId: item.locallyAdded ? 0 : item.testId,
          testName: item.testName,
          location: item.location,
          view: item.view,
          technique: item.technique,
        };
      });
    }

    if (data?.familyHistory) {
      hereditaryDiseases = data?.familyHistory
        ?.filter((item) => !item.previouslyAdded)
        ?.map((item) => {
          return {
            diseaseName: item.disease.diseaseName,
            relationship: item.relationShip,
          };
        });
    }

    if (data?.physicalExamList) {
      phyExams = data?.physicalExamList?.map((item, index) => {
        return {
          slNo: index,
          phyExamId: item.issue.phyExamId,
          phyExamImmediateParId: item.issue.phyExamParId,
          phyExamFreeText: item.issue.phyExamName,
        };
      });
    }

    const reqDto = {
      visitId: extraData.queueVisitId,
      doctorUserId: loginUserDetail.userId,
      reviewDate: data?.reviewDate
        ? dayjs(data.reviewDate).format("DD-MM-YYYY")
        : null,
      referralNote: getReferStringFromType(data?.referType) || "",
      doctorNote: data.doctorNote,
      patientId: extraData.queuePatientId,
      procedures,
      medicines,
      dieseases,
      lifeStyles,
      diets,
      paramsToMonitor,
      vitals,
      tests,
      foodAllergies,
      signs,
      symptoms,
      riskfactors,
      medicineAllergies,
      comorbidities,
      pastProcedures,
      pastDieseases,
      possibleConditions,
      possibleInvestigations,
      hereditaryDiseases,
      phyExams,
      bmiValue: data.bmiValue || "",
      bmiType: data.bmiType || "",
      refferedToHospital:
        data.referType === "immediateHospitalization" ||
        data.referType === "higherFacility"
          ? data.hospital?.hospitalName
          : null || null,
      refferedToDoctor:
        data.referType === "otherSpeciality" ? data.doctorName : null || null,
      refferedToSpecialist:
        data.referType === "otherSpeciality"
          ? data?.referDoctorSpeciality?.specializationName
          : null || null,
    };
    // console.log("reqDto= ", reqDto);
    return reqDto;
  };

  const handleCloseSwithchModal = () => {
    setShowSwitchModal(false);
    closeMeeting(false);
  };

  const updatePrescriptionData = (data) => {
    // toast.success("successfully added data");
    console.log("data =", data);
    setPrescriptionData((prev) => {
      // let reqDto={}
      const temp = { ...prev, ...data, pvId: props.extraData?.queueVisitId };
      let reqDto = prepareRxData(temp);
      zhPatientService.logRX(reqDto);
      doctorSocket.emit("prescriptionUpdateByDr", {
        pvId: props.extraData?.queueVisitId,
        data: temp,
      });

      return temp;
    });
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitSuccessful },
    setError,
  } = useForm();

  // const joinRoom = () => {};

  const [loadingRoom, setLoadingRoom] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRxData = (data) => {
    console.log("Rx Data =", data);
    const { symptoms } = data;
    setTempRxData(data);

    // let tempSymptom = symptoms?.map((item) => {
    //   return {
    //     selectedSymptom: {
    //       id: item.symptomId,
    //       symptomName: item.symptomName,
    //       gender: "C",
    //     },
    //     severity: item.severity,
    //     since: item.since,
    //   };
    // });

    // setPrescriptionData((prev) => {
    //   // let reqDto={}
    //   const temp = { symptoms: tempSymptom };
    //   return temp;
    // });
  };
  const fetchRxData = () => {
    // getRxData
    if (props?.extraData?.queueVisitId) {
      zhPatientService
        .getRX(props?.extraData?.queueVisitId)
        .then((response) => {
          if (response.data) {
            handleRxData(response.data);
          }
        });
    }
  };

  useEffect(() => {
    if (rxData) {
      console.log("rxData =", rxData);
      setPrescriptionData(rxData);
    }
  }, [rxData]);

  useEffect(() => {
    let intervalId = null;
    fetchRxData();

    // if (!props?.extraData?.isDoctor) {
    //   intervalId = setInterval(() => {
    //     fetchRxData();
    //   }, 2000);
    // }

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    if (props.eventData && props.tabKey) {
      updateTab({ data: props.eventData.eventLink }, props.tabKey);
    }

    if (props.joinRoomId) {
      getEventDetailsByMeetingId(props.joinRoomId).then((response) => {
        console.log("joinVideoRoom", response, props.joinRoomId, roomId);
        const eventDto = {
          eventTitle: response.data.field1,
          eventDescription: response.data.field2,
          eventStartTime: response.data.field3,
          eventEndTime: response.data.field4,
          eventId: response.data.field5,
          eventType: response.data.field6,
          eventLength: parseInt(response.data.field7),
          eventLink: props.joinRoomId,
        };
        setRoomId(props.joinRoomId);
        setSelEvent(eventDto);
        updateTab({ data: props.joinRoomId }, props.tabKey);
      });
    }
    if (props.startInstantMeeting) {
      startInstantMeeting();
    }
    return () => {
      // toast.error("Patient disconnected");
      //
      if (props?.extraData?.queueVisitId && !props?.extraData?.isDoctor) {
        centerSocket.emit("patientDisconnectFromVideo", {
          pvId: props?.extraData?.queueVisitId,
        });

        centerSocket.emit("requestRefreshDoctorQueue", {
          doctorId: props?.extraData?.doctorId,
        });

        // if (patientCanceledSelf.current) {
        //   DashboardServices.patientBack(
        //     props?.extraData?.queueVisitId,
        //     userDetails.userId,
        //     ""
        //   );
        // }
      }
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  const handlePatientSwitch = (data) => {
    if (data?.pvId === props?.extraData?.queueVisitId) {
      setShowSwitchModal(true);
    }
  };
  const handlePatientCancel = (data) => {
    toast.error(t("doctor_canceled_meeting"));
    if (data?.pvId === props?.extraData?.queueVisitId) {
      patientCanceledSelf.current = true;
      closeMeeting(true);
    }
  };

  const handleUpdatePrescription = (info) => {
    const { data } = info;
    const { getVitalsFromDevice, pvId } = data;
    if (pvId !== props?.extraData?.queueVisitId) {
      return;
    }
    console.log("data  =", data);
    if (getVitalsFromDevice) {
      toast.error(t("please_get_vitals"));
    }
    // const {reviewDate}=data
    setPrescriptionData(data);
  };

  const handleConsultationDone = (info) => {
    console.log(":data=", info);
    // if (info?.pvId === props?.extraData?.queueVisitId) {
    //   toast.info(t("consultation_ended"));
    // }
    // return;

    if (info?.pvId === props?.extraData?.queueVisitId) {
      const fromValue = props.extraData?.isDoctor ? "Doctor" : "Patient";
      toast.info(t("consultation_ended"));

      closeMeeting(true, fromValue);
    }
  };

  useEffect(() => {
    if (centerSocket) {
      console.log("socket tab=", tabs);
      centerSocket.on("switchPatientFromVideoByDr", handlePatientSwitch);
      centerSocket.on("cancelPatientFromVideoByDr", handlePatientCancel);
      centerSocket.on("prescriptionUpdateToCenter", handleUpdatePrescription);
      centerSocket.on("consultationDone", handleConsultationDone);
    }
    return () => {
      centerSocket.off("switchPatientFromVideoByDr", handlePatientSwitch);
      centerSocket.off("cancelPatientFromVideoByDr", handlePatientCancel);
      centerSocket.off("prescriptionUpdateToCenter", handleUpdatePrescription);
      centerSocket.off("consultationDone", handleConsultationDone);
    };
  }, [centerSocket, tabs]);

  const handleFullScreenChange = () => {
    if (document.fullscreenElement) {
      setIsFullscreen(true);
    } else {
      setIsFullscreen(false);
    }
  };

  const joinRoom = async (data) => {
    setLoadingRoom(true);
    let roomIdTemp = roomId2;
    // if (data) {
    //   roomIdTemp = data;
    // }

    if (roomIdTemp === "" || roomIdTemp === "") {
      toast.error(t("please_enter_room_id"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoadingRoom(false);
      return;
    }

    const response = await getEventDetailsByMeetingId(roomIdTemp);
    console.log("joinVideoRoom", response);

    if (!response.data && response.data === "") {
      toast.error(
        t(
          "meeting_with_provided_room_number_does_not_exists_please_check_once"
        ),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoadingRoom(false);
      return;
    }

    const eventDto = {
      eventTitle: response.data.field1,
      eventDescription: response.data.field2,
      eventStartTime: response.data.field3,
      eventEndTime: response.data.field4,
      eventId: response.data.field5,
      eventType: response.data.field6,
      eventLength: parseInt(response.data.field7),
      eventLink: roomIdTemp,
    };

    const start = new Date(response.data.field3);
    const curr = new Date();
    const diff = start - curr;
    if (diff > 1000 * 60 * 5) {
      const minutesToSubtract = 5;
      const millisecondsToSubtract = minutesToSubtract * 60 * 1000;
      toast.error(
        t(
          "this_meeting_opens_5_minutes_before_the_start_time_please_join_after"
        ) +
          DateUtils.getDateInDDMMYYYY(
            new Date(start.getTime() - millisecondsToSubtract)
          ) +
          " " +
          DateUtils.printAMPM(
            new Date(start.getTime() - millisecondsToSubtract)
          ),
        DateUtils.getDateInDDMMYYYY(
          new Date(start.getTime() - millisecondsToSubtract)
        ) +
          " " +
          DateUtils.printAMPM(
            new Date(start.getTime() - millisecondsToSubtract)
          ),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoadingRoom(false);
      return;
    }

    if (!AppUtils.isNumeric(roomIdTemp)) {
      toast.error(t("invalid_room_number_room_number_can_have_only_digits"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoadingRoom(false);
      return;
    }
    if (roomIdTemp === "" || roomIdTemp === "") {
      toast.error(t("please_enter_room_id"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoadingRoom(false);
    } else {
      setRoomId(roomIdTemp);
      // props.changeRoomId(roomIdTemp);
      // props.setSelEvent(eventDto);
      // localStorage.setItem("video-room-id", roomIdTemp);
      setSelEvent(eventDto);
      let tempSubList = [];
      const jsonString = JSON.stringify(tempSubList);
      localStorage.setItem("meetingSubs", jsonString);
      setLoadingRoom(false);
    }
  };

  const startInstantMeeting = async () => {
    toast.info(t("starting_instant_meeting_please_wait"));
    setLoading(true);
    try {
      const response = await createInstantMeeting(userDetails.userId);

      const response2 = await getEventDetailsByMeetingId(response.data.link);
      console.log("joinVideoRoom", response2);
      const eventDto = {
        eventTitle: response2.data.field1,
        eventDescription: response2.data.field2,
        eventStartTime: response2.data.field3,
        eventEndTime: response2.data.field4,
        eventId: response2.data.field5,
        eventType: response2.data.field6,
        eventLength: parseInt(response2.data.field7),
        eventLink: response.data.link,
      };

      console.log("createInstantMeeting-->", response);

      setRoomId(response.data.link);
      updateTab(
        { data: response.data.link, title: "Instant Meeting" },
        props.tabKey
      );
      // setIsHost(true);
      setEventId(response.data.eventId);
      console.log("eventDto78977988", eventDto);
      setSelEvent(eventDto);
      // props.changeRoomId(response.data.link);

      const tempSubList = [];
      const jsonString = JSON.stringify(tempSubList);
      localStorage.setItem("meetingSubs", jsonString);

      // props.clearSelEvents(null);
      // props.handelTaskSubmitData();
      props.refreshData();
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  // open meeting menu start
  const [openMeetingMenu, setOpenMeetingMenu] = React.useState(false);
  const open = Boolean(openMeetingMenu);
  // open meeting menu end

  const [gridItemSize, setGridItemSize] = useState(12);

  const handleMenuClick = (size) => {
    setGridItemSize(size);
    setOpenMeetingMenu(false);
  };

  const handleMenuCloseButton = () => {
    setGridItemSize(12);
    setvalue(0);
  };

  const [value, setvalue] = useState(0);

  ///////////////////////////////
  ////////

  // const [playSound, { stop }] = useSound(mySound);
  // const [showFiles, setShowFiles] = React.useState(false);
  // const handleFile = useFullScreenHandle();
  const confirm = useConfirm();
  let navigate = useNavigate();

  const [showPrivateNote, setShowPrivateNote] = React.useState(false);

  const [showWebinarChatCount, setShowWebinarChatCount] = React.useState(0);
  React.useState(false);

  const [anchorAudioOptions, setAnchorAudioOptions] = React.useState(null);
  const openAudioOptions = Boolean(anchorAudioOptions);
  const handleClickAudioOptions = (event) => {
    setAnchorAudioOptions(event.currentTarget);
    refreshDevices();
  };
  const handleCloseAudioOptions = () => {
    setAnchorAudioOptions(null);
  };

  const [anchorVideoOptions, setAnchorVideoOptions] = React.useState(null);
  const openVideoOptions = Boolean(anchorVideoOptions);
  const handleClickVideoOptions = (event) => {
    setAnchorVideoOptions(event.currentTarget);
  };
  const handleCloseVideoOptions = () => {
    setAnchorVideoOptions(null);
  };

  const endCallRef = React.useRef(null);

  const handleClickEndMeeting = (event) => {
    confirm({ description: "End meeting for all!" })
      .then(() => {
        // MeetingService.endMeeting(props.roomNumber).then(() => {
        //   console.log("Meeting Ended Successfully");
        // });
        // props.leaveMeetingRoom();

        if (props.openRoom) {
          let path = `../`;
          navigate(path);
        } else {
          props.leaveMeetingRoom();
        }
        props.setMeetingEndType(null);
      })
      .catch(() => {
        console.log("End meeting cancelled");
        props.setMeetingEndType(null);
      });
  };

  const handleClickLeaveMeeting = (event) => {
    confirm({ description: "Leave Meeting!" })
      .then(() => {
        if (props.openRoom) {
          let path = `../`;
          navigate(path);
        } else {
          props.leaveMeetingRoom();
        }
        props.setMeetingEndType(null);
      })
      .catch(() => {
        console.log("Leave meeting cancelled");
        props.setMeetingEndType(null);
      });
  };

  const [invitePerModal, setInvitePerModal] = React.useState(false);

  const [meetingAgendasList, setMeetingAgendasList] = React.useState([]);
  const [showAgendaModal, setShowAgendaModal] = React.useState(false);
  const [streamStarted, setStreamStarted] = React.useState(false);
  const [showEndCallDialog, setShowEndCallDialog] = React.useState(false);

  useEffect(() => {
    if (selEvent) {
      getMeetingAgendas(selEvent.eventId).then((response) => {
        setMeetingAgendasList(response.data);
      });
    }
  }, [selEvent]);

  const [blinkFileUpload, setBlinkFileUpload] = React.useState(0);

  const [renderVideo, setRenderVideo] = React.useState(false);
  const [renderAudio, setRenderAudio] = React.useState(false);
  const [renderShareScreen, setRenderShareScreen] = React.useState(false);
  const [chatUnreadCount, setChatUnreadCount] = React.useState(0);
  // const [renderTileView, setRenderTileView] = React.useState(false);

  const [audioInput, setAudioInput] = React.useState([]);
  const [audioOutput, setAudioOutput] = React.useState([]);
  const [videoOutput, setVideoOutput] = React.useState([]);
  const [selAudioInput, setSelAudioInput] = React.useState(null);
  const [selAudioOutput, setSelAudioOutput] = React.useState(null);
  const [selVideoOutput, setSelVideoOutput] = React.useState(null);
  React.useEffect(() => {
    console.log("screenSharingStatusChanged useEffect", renderVideo);
    if (jitsiObject) {
      if (
        !jitsiObject._events.chatUpdated ||
        jitsiObject._events.chatUpdated.length === 0
      ) {
        jitsiObject.addListener("chatUpdated", (obj) => {
          console.log("chatUpdated 2", obj);
          if (!obj.open) {
            setChatUnreadCount(obj.unreadCount);
          } else {
            setChatUnreadCount(0);
          }
        });
      }
      const deviceListChanged = (obj) => {
        console.log("deviceListChanged", obj);
        let devices = obj.devices;
        try {
          setAudioInput(devices.audioInput);
        } catch (err) {
          console.log(err);
        }
        try {
          setAudioOutput(devices.audioOutput);
        } catch (err) {
          console.log(err);
        }
        try {
          setVideoOutput(devices.videoInput);
        } catch (err) {
          console.log(err);
        }
      };
      jitsiObject.addListener(
        "screenSharingStatusChanged",
        shareScreenListener
      );
      jitsiObject.addListener("videoMuteStatusChanged", videoMuteStatusChanged);
      jitsiObject.addListener("audioMuteStatusChanged", audioMuteStatusChanged);

      // jitsiObject.addListener("videoConferenceJoined", videoConferenceJoined);

      jitsiObject.addListener("deviceListChanged", deviceListChanged);

      // jitsiObject.addListener(
      //   "notificationTriggered",
      //   notificationTriggeredJitsi
      // );

      // jitsiObject.addListener(
      //   "nonParticipantMessageReceived",
      //   notificationTriggeredJitsi
      // );

      // jitsiObject.addListener(
      //   "endpointTextMessageReceived",
      //   notificationTriggeredJitsi
      // );

      // jitsiObject.addListener("log", notificationTriggeredJitsi);

      // jitsiObject.addListener("outgoingMessage", notificationTriggeredJitsi);

      jitsiObject.getAvailableDevices().then((devices) => {
        console.log("devices avai-->", devices);
        try {
          setAudioInput(devices.audioInput);
        } catch (err) {
          console.log(err);
        }
        try {
          setAudioOutput(devices.audioOutput);
        } catch (err) {
          console.log(err);
        }
        try {
          setVideoOutput(devices.videoInput);
        } catch (err) {
          console.log(err);
        }
      });
      jitsiObject.getCurrentDevices().then((devices) => {
        console.log("devices curr-->", devices);
        try {
          setSelAudioInput(devices.audioInput.deviceId);
        } catch (err) {
          console.log(err);
        }
        try {
          setSelAudioOutput(devices.audioOutput.deviceId);
        } catch (err) {
          console.log(err);
        }
        try {
          setSelVideoOutput(devices.videoInput.deviceId);
        } catch (err) {
          console.log(err);
        }
      });

      if (props.extraData) {
        if (!props?.extraData?.isDoctor) {
          centerSocket.emit("patientConnectInVideo", {
            pvId: props?.extraData?.queueVisitId,
          });
          centerSocket.emit("requestRefreshDoctorQueue", {
            doctorId: props?.extraData?.doctorId,
          });
        }
        // else{
        //Add What happend when doctor connects
        // }
      }
    }
  }, [jitsiObject]);

  // let promiseTest = jitsiObject.getContentSharingParticipants();

  const shareScreenListener = (obj) => {
    console.log(obj);
    if (obj.on) {
      jitsiObject.executeCommand("showNotification", {
        title: "Screen sharing on",
        description: "",
      });
      setRenderShareScreen(true);
    } else {
      jitsiObject.executeCommand("showNotification", {
        title: "Screen sharing off",
        description: "",
      });
      setRenderShareScreen(false);
    }
  };

  const videoMuteStatusChanged = (obj) => {
    console.log(obj);
    if (obj.muted) {
      setRenderVideo(false);
    } else {
      setRenderVideo(true);
      jitsiObject.getCurrentDevices().then((devices) => {
        try {
          console.log(
            "devices refreshDevices curr-->",
            devices.videoInput.deviceId
          );

          setSelVideoOutput(devices.videoInput.deviceId);
        } catch (err) {
          console.log(err);
        }
      });
    }
  };

  const [intervalId, setIntervalId] = useState(null);
  const videoConferenceJoinedJitsi = (obj) => {
    console.log("audioMuteStatusChanged videoConferenceJoined", obj);
    obj.isAudioMuted().then((muted) => {
      console.log(
        "audioMuteStatusChanged videoConferenceJoined 2",
        muted,
        renderAudio
      );
      if (muted) {
        console.log(
          "audioMuteStatusChanged videoConferenceJoined 3",
          obj._myUserID
        );
        obj.executeCommand("showNotification", {
          title: "Please unmute to speak",
          description: "",
        });
        setRenderAudio(false);
      } else {
        setRenderAudio(true);
      }
    });

    const intervalId = setInterval(() => {
      // console.log("audioMuteStatusChanged interval", obj);
      obj.isAudioMuted().then((muted) => {
        // console.log("audioMuteStatusChanged interval 2", muted, renderAudio);
        if (muted) {
          setRenderAudio(false);
        } else {
          setRenderAudio(true);
        }
      });
    }, 1000);
    setIntervalId(intervalId);
  };

  // const jitsiObjectRef = useRef(jitsiObject);
  const [isWhiteBoardOpened, setIsWhiteBoardOpened] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(0);
  useEffect(() => {
    if (jitsiObject) {
      meetingSocket.on(
        "openWhiteBoardRec",
        ({ userName, userId, isWhiteBoardOpenedTemp }) => {
          console.log(
            "openWhiteBoardRec",
            userName,
            userId,
            jitsiObject,
            isWhiteBoardOpenedTemp
          );
          setIsWhiteBoardOpened(true);

          if (!isWhiteBoardOpenedTemp) {
            jitsiObject.executeCommand("showNotification", {
              title: `${userName} has opened the whiteboard`,
              // title: t('whiteboardOpenedMessage', { userName }),
              description: "",
            });
          }
        }
      );

      if (props.extraData) {
        setOpenMeetingMenu(false);
        handleMenuClick(6);
        setvalue(9);
      }
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [jitsiObject]);

  useEffect(() => {
    meetingSocket.on("onFileShareRecHighlight", ({ userName }) => {
      console.log("onFileShareRecHighlight", userName);
      toast.info(`${userName} has uploaded a new file, please check...`);
      // toast.info(t('fileUploadMessage', { userName }))
      setIsFileUploaded(isFileUploaded + 1);
      handleMenuClick(8);
      setvalue(5);
    });

    meetingSocket.on(
      "updateWebinarChantMessageCount",
      function (message, sentUserDtls) {
        console.log("receiveChatMessage", showWebinarChatCount);
        if (value !== 8) {
          setShowWebinarChatCount(showWebinarChatCount + 1);
        } else {
          setShowWebinarChatCount(0);
        }
      }
    );

    meetingSocket.on("endWebinarAck", function (userName) {
      console.log("endWebinarAck", userName);
      toast.info("The webinar has ended");
      setSelEvent(null);
      setRoomId(null);
      handleMenuCloseButton();
      // socket.emit("my other event", { my: "data" });
    });

    return () => {
      meetingSocket.off("endWebinarAck");
      meetingSocket.off("updateWebinarChantMessageCount");
      meetingSocket.off("onFileShareRecHighlight");
    };
  }, []);

  const audioMuteStatusChanged = (obj) => {
    console.log("audioMuteStatusChanged 1", obj);
    if (obj.muted) {
      setRenderAudio(false);
    } else {
      setRenderAudio(true);
    }
  };

  const refreshDevices = () => {
    console.log("refreshDevices called");
    if (jitsiObject) {
      jitsiObject.getAvailableDevices().then((devices) => {
        console.log("devices refreshDevices avai-->", devices);
        try {
          setAudioInput(devices.audioInput);
        } catch (err) {
          console.log(err);
        }
        try {
          setAudioOutput(devices.audioOutput);
        } catch (err) {
          console.log(err);
        }
        try {
          setVideoOutput(devices.videoInput);
        } catch (err) {
          console.log(err);
        }
      });
      jitsiObject.getCurrentDevices().then((devices) => {
        console.log("devices refreshDevices curr-->", devices);

        try {
          setSelAudioInput(devices.audioInput.deviceId);
        } catch (err) {
          console.log(err);
        }
        try {
          setSelAudioOutput(devices.audioOutput.deviceId);
        } catch (err) {
          console.log(err);
        }
        try {
          console.log(
            "devices refreshDevices curr-->",
            devices.videoInput.deviceId
          );
          setSelVideoOutput(devices.videoInput.deviceId);
        } catch (err) {
          console.log(err);
        }
      });
    }
  };

  React.useEffect(() => {
    // attach the event listener
    console.log("Bottom Options", props.keyPressed);

    if (props.keyPressed === "!") {
      if (jitsiObject) {
        jitsiObject.isAudioMuted().then((muted) => {
          console.log("audioMuteStatusChanged Mic", muted, renderAudio);
          if (!muted) {
            setRenderAudio(false);
            jitsiObject.executeCommand("toggleAudio");
            jitsiObject.executeCommand("showNotification", {
              title: "You have been muted",
              description: "",
            });
          } else {
            setRenderAudio(true);
            jitsiObject.executeCommand("toggleAudio");
            jitsiObject.executeCommand("showNotification", {
              title: "You have been unmuted",
              description: "",
            });
          }
        });
      }
    }

    if (props.keyPressed === "@") {
      if (jitsiObject) {
        jitsiObject.isVideoMuted().then((muted) => {
          console.log("VideoMuteStatusChanged Mic", muted, renderVideo);
          if (!muted) {
            setRenderVideo(false);
            jitsiObject.executeCommand("toggleVideo");
            jitsiObject.executeCommand("showNotification", {
              title: "Video switched off",
              description: "",
            });
          } else {
            setRenderVideo(true);
            jitsiObject.executeCommand("toggleVideo");
            jitsiObject.executeCommand("showNotification", {
              title: "Video switched on",
              description: "",
            });
          }
        });
      }
    }

    if (props.keyPressed === "#") {
      if (jitsiObject) {
        jitsiObject.executeCommand("toggleShareScreen");
      }
    }

    if (props.keyPressed === "$") {
      props.openWhiteBoard();
    }

    if (props.keyPressed === "%") {
      props.openEtherpad();
    }

    if (props.keyPressed === "^") {
      // setShowFiles(true);
      setBlinkFileUpload(0);
    }

    if (props.keyPressed === "&") {
      // handleClick();
      endCallRef.current.click();
    }

    if (props.keyPressed === "*") {
      if (jitsiObject) {
        jitsiObject.executeCommand("toggleRaiseHand");
      }
    }

    if (props.keyPressed === "(") {
      if (jitsiObject) {
        jitsiObject.executeCommand("toggleTileView");
      }
    }
    if (props.keyPressed === ")") {
      if (jitsiObject) {
        jitsiObject.executeCommand("toggleVirtualBackgroundDialog");
      }
    }
    if (props.keyPressed === "_" && props.selEvent && props.selEvent !== null) {
      setShowPrivateNote(true);
    }
    if (props.keyPressed === "+" && !props.openRoom) {
      setInvitePerModal(true);
    }

    //setShowAgendaModal(true)

    // remove the event listener
    // return () => {
    //   document.removeEventListener("keydown", keyPressed);
    // };
  }, [props.keyPressed]);

  const usersListEndRef = React.useRef(null);

  React.useEffect(() => {
    console.log("props.meetingEndType", props.meetingEndType);
    if (props.meetingEndType) {
      // endCallRef.current.click();
      if (props.meetingEndType === "END") {
        handleClickEndMeeting();
      } else {
        handleClickLeaveMeeting();
      }
    }
    //
  }, [props.meetingEndType]);

  const [sendingInvite, setSendingInvite] = React.useState(false);
  const [showBusyUserCallDialog, setShowBusyUserCallDialog] = useState(false);
  const [busyUserList, setBusyUserList] = useState([]);
  const [sameMeetingUser, setSameMeetingUser] = useState([]);

  const showMessageBusyUser = () => {};

  const [activeDrags, setActiveDrags] = useState(0);
  const handleDrag = {
    onStart: () => setActiveDrags(activeDrags + 1),
    onStop: () => setActiveDrags(activeDrags - 1),
  };
  const [boundsByDirection, setBBD] = useState(false);

  //////////////////////////////

  const muteSpeaker = () => {
    jitsiObject.setAudioOutputDevice("deviceLabel", null);
  };

  const divRef = useRef(null);

  // useEffect(() => {
  //   toast.error("changing document state");
  //   setIsFullscreen(document.fullscreenElement ? false : true);
  // }, [document.fullscreenElement]);

  const [isConvenor, setIsConvenor] = React.useState(false);

  useEffect(() => {
    if (selEvent && selEvent.eventId) {
      getChairpersonOrConvenerList(selEvent?.eventId, "convener").then(
        (response) => {
          console.log(" -->>>", response.data);
          if (response.data && response.data.length > 0) {
            if (response.data[0].userId === userDetails.userId) {
              setIsConvenor(true);
            } else {
              setIsConvenor(false);
            }
          }
        }
      );
    }
  }, [selEvent]);

  const handelDownloadFileFromUrl = (files) => {
    files.forEach((file) => {
      // var frame = document.createElement("iframe");
      // frame.src = file.fileLink;
      // frame.name = file.fileName;
      // frame["download"] = 1;
      // document.body.appendChild(frame);
      downloadFile(file.fileName, userDetails.orgId).then((response) => {
        window.open(
          apiEndPoint +
            `/appTest/downloadFileTest/${file.fileName}/${userDetails.orgId}`,
          "_blank",
          "noopener,noreferrer"
        );
      });
    });
  };

  const closeMeeting = (showFeedback = false, feedbackFrom = "Patient") => {
    setSelEvent(null);
    setRoomId(null);
    handleMenuCloseButton();
    if (showFeedback) {
      const key = v4();
      addTab({
        key: key,
        // key: props?.tabKey,
        title: "Feedback",
        content: (
          <DrFeedback
            feedbackFrom={feedbackFrom}
            visitId={props.extraData?.queueVisitId}
            tabKey={key}
            data={props.extraData}
          />
        ),
        isDraggable: true,
        type: "New",
        replaceTabKey: props.tabKey,
      });
    }
  };

  return (
    <Box
      // className="meetingsTabContainerArea"
      sx={{ bgcolor: "dark.pageBg" }}
      className={
        isFullscreen
          ? "meetingsTabContainerArea fullscreen-meeting"
          : "meetingsTabContainerArea"
      }
    >
      <Grid
        container
        spacing={0}
        className="meetingsTabContainArea"
        ref={divRef}
      >
        <Grid item xs={gridItemSize} className="metConfFullWidth">
          <div className="meetingsShowAreaContain">
            {roomId ? (
              <div className="fullscreenmeeting">
                <ConfirmProvider>
                  {/* <FullScreen handle={handleVideoFullScreen}> */}
                  <JitsiVideoConference
                    openRoom={true}
                    roomNumber={roomId}
                    userName={userDetails.userName}
                    setJitsi={(jitsi) => {
                      setJitsiObject(jitsi);
                      setJitsi(jitsi);
                      // if (props.eventData) {
                      //   props.eventData._jitsiObject = jitsi;
                      //   props.eventData._setRenderAudio = setRenderAudio;
                      //   props.eventData._renderAudio = renderAudio;
                      // }
                    }}
                    selEvent={selEvent}
                    leaveMeetingRoom={() => {
                      setSelEvent(null);
                      setRoomId(null);
                      handleMenuCloseButton();
                      addTab({
                        key: v4(),
                        title: "Meeting Room",
                        content: (
                          <ConfirmProvider>
                            <MeetingsTabContainer />
                          </ConfirmProvider>
                        ),
                        isDraggable: true,
                        type: "New",
                        replaceTabKey: props.tabKey,
                      });
                    }}
                    videoConferenceJoinedJitsi={videoConferenceJoinedJitsi}
                  />
                  {/* </FullScreen> */}
                </ConfirmProvider>
                {jitsiObject && (
                  <div className="meetingControlBtnGroup">
                    <div className="meetingTimer">
                      {selEvent && (
                        <BottomOptionsTimer
                          meetingTimer={40}
                          eventData={selEvent}
                        />
                      )}
                    </div>

                    {/* <div
                      className={`meetContBtn ${
                        renderAudio ? "metBtnActive" : ""
                      }`}
                    >
                      <Tooltip title={t("mic")} arrow>
                        {renderAudio && (
                          <IconButton
                            aria-label="mic"
                            size="large"
                            onClick={() => {
                              if (jitsiObject) {
                                jitsiObject.isAudioMuted().then((muted) => {
                                  console.log(
                                    "audioMuteStatusChanged Mic",
                                    muted,
                                    renderAudio
                                  );
                                  if (!muted) {
                                    setRenderAudio(false);
                                    jitsiObject.executeCommand("toggleAudio");
                                  }
                                });
                              }
                            }}
                          >
                            <Mic />
                          </IconButton>
                        )}
                        {!renderAudio && (
                          <IconButton
                            aria-label="mic"
                            size="large"
                            onClick={async () => {
                              console.log(
                                "audioMuteStatusChanged Mic",
                                jitsiObject
                              );
                              if (jitsiObject) {
                                jitsiObject
                                  .isAudioMuted()
                                  .then(async (muted) => {
                                    console.log(
                                      "audioMuteStatusChanged Mic",
                                      muted,
                                      renderAudio
                                    );
                                    if (muted) {
                                      setRenderAudio(true);
                                      await jitsiObject.executeCommand(
                                        "toggleAudio"
                                      );
                                      refreshDevices();
                                    }
                                  });
                              }
                            }}
                          >
                            <MicOff
                            />
                          </IconButton>
                        )}
                      </Tooltip>
                    </div> */}

                    {/* <div
                      className={`meetContBtn ${
                        renderVideo ? "metBtnActive" : ""
                      }`}
                    >
                      <Tooltip title={t("video")} arrow>
                        {renderVideo && (
                          <IconButton
                            aria-label="Videocam"
                            size="large"
                            onClick={async () => {
                              if (jitsiObject) {
                                jitsiObject.executeCommand("toggleVideo");
                              }
                            }}
                          >
                            <Videocam />
                          </IconButton>
                        )}
                        {!renderVideo && (
                          <IconButton
                            aria-label="Videocam"
                            size="large"
                            onClick={async () => {
                              if (jitsiObject) {
                                await jitsiObject.executeCommand("toggleVideo");
                                refreshDevices();
                              }
                            }}
                          >
                            <VideocamOff />
                          </IconButton>
                        )}
                      </Tooltip>
                    </div> */}

                    <div
                      className={`meetContBtn ${
                        renderShareScreen ? "metBtnActive" : ""
                      }`}
                    >
                      <Tooltip title={t("share_screen")} arrow>
                        <IconButton aria-label="ScreenShare" size="large">
                          {/* <ScreenShare /> */}
                          {/* <StopScreenShare /> */}
                          {!renderShareScreen && (
                            // <Tooltip title={"Share Screen"}>
                            <ScreenShare
                              onClick={() => {
                                if (jitsiObject) {
                                  jitsiObject.executeCommand(
                                    "toggleShareScreen"
                                  );
                                }
                                // setIsFullscreen(false);
                              }}
                            />
                            // </Tooltip>
                          )}
                          {renderShareScreen && (
                            // <Tooltip title={"Share Screen"}>
                            <StopScreenShare
                              onClick={() => {
                                if (jitsiObject) {
                                  jitsiObject.executeCommand(
                                    "toggleShareScreen"
                                  );
                                }
                              }}
                            />
                            // </Tooltip>
                          )}
                        </IconButton>
                      </Tooltip>
                    </div>
                    {/* <div className="meetContBtn">
                    <Tooltip title="Note" arrow>
                      <IconButton aria-label="PageEdit" size="large">
                        <NoteAlt />
                      </IconButton>
                    </Tooltip>
                  </div> */}
                    <div
                      className={`meetContBtn ${
                        value === 7 ? "metBtnActive" : ""
                      }`}
                    >
                      <Tooltip title={t("white_board")} arrow>
                        <IconButton
                          onClick={() => {
                            // props.openWhiteBoard(selEvent);
                            if (value === 7) {
                              handleMenuCloseButton();
                            } else {
                              setOpenMeetingMenu(false);
                              handleMenuClick(6);
                              setvalue(7);
                            }
                          }}
                          aria-label="DesignServices"
                          size="large"
                        >
                          <DesignServices />
                        </IconButton>
                      </Tooltip>
                    </div>

                    {selEvent &&
                      selEvent !== null &&
                      meetingAgendasList !== null &&
                      meetingAgendasList.length > 0 && (
                        <div
                          className={`meetContBtn ${
                            showAgendaModal ? "metBtnActive" : ""
                          }`}
                        >
                          <Tooltip title={t("meeting_agenda")} arrow>
                            <IconButton
                              onClick={() => {
                                setShowAgendaModal(true);
                              }}
                              aria-label="DesignServices"
                              size="large"
                            >
                              <PageStar />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    {/* <div
                    style={{ display: "none !important" }}
                    className={`meetContBtn ${false ? "metBtnActive" : ""}`}
                  >
                    <Tooltip title="Take screenshot" arrow>
                      <IconButton
                        onClick={() => {
                          toggleFullscreen();
                        }}
                        aria-label="DesignServices"
                        size="large"
                      >
                        <Screenshot />
                      </IconButton>
                    </Tooltip>
                  </div> */}

                    <div
                      className={`meetContBtn ${false ? "metBtnActive" : ""}`}
                    >
                      <Tooltip title={t("meeting_chat")} arrow>
                        {selEvent?.eventType !== "Webinar" && (
                          <IconButton
                            aria-label="Chat"
                            size="large"
                            onClick={() => {
                              jitsiObject.executeCommand("toggleChat");
                            }}
                          >
                            <Badge
                              badgeContent={chatUnreadCount}
                              color="primary"
                            >
                              <Chat />
                            </Badge>
                          </IconButton>
                        )}
                        {selEvent?.eventType === "Webinar" && (
                          <IconButton
                            aria-label="Chat"
                            size="large"
                            onClick={() => {
                              handleMenuClick(6);
                              setvalue(8);
                              setShowWebinarChatCount(0);
                              // WebinarService.getWebinarChat(roomId).then(
                              //   (response) => {
                              //     setWebinarChatData(response.data);
                              //     // setShowWebinarChat(true);
                              //     setvalue(8);
                              //   }
                              // );
                            }}
                          >
                            <Badge
                              color="primary"
                              // variant="dot"
                              // invisible={showWebinarChatCount <= 0}
                              badgeContent={
                                value === 8 ? 0 : showWebinarChatCount
                              }
                            >
                              <Chat />
                            </Badge>
                          </IconButton>
                          // <Button
                          //   className={"meetingMenuBtn"}
                          //   sx={{ padding: "25px" }}
                          //   onClick={() => {
                          //     // jitsiObject.executeCommand("toggleChat");
                          //     // WebinarService.getWebinarChat(
                          //     //   props.roomNumber
                          //     // ).then((response) => {
                          //     //   setWebinarChatData(response.data);
                          //     //   setShowWebinarChat(true);
                          //     // });
                          //   }}
                          // >
                          //   <Tooltip title={"Chat"}>
                          //     {/* <Badge badgeContent={chatUnreadCount} color="primary"> */}
                          //     <Badge
                          //       color="primary"
                          //       variant="dot"
                          //       invisible={showWebinarChatCount <= 0}
                          //     >
                          //       <ChatBubble />
                          //     </Badge>

                          //     {/* </Badge> */}
                          //   </Tooltip>

                          // </Button>
                        )}
                      </Tooltip>
                    </div>

                    <div className="meetContBtn">
                      <ShareOptions roomNumber={roomId} />
                    </div>

                    {/* <div
                      className={`meetContBtn ${false ? "metBtnActive" : ""}`}
                    >
                      <Tooltip title="Full Screen" arrow>
                        <IconButton
                          onClick={() => {
                            if (jitsiObject) {
                              handleFullscreen();
                            }
                          }}
                          aria-label="BackHand"
                          size="large"
                        >
                          {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
                    
                        </IconButton>
                      </Tooltip>
                    </div> */}

                    <div
                      className={`meetContBtn ${false ? "metBtnActive" : ""}`}
                    >
                      <Tooltip title={t("raise_hand")} arrow>
                        <IconButton
                          onClick={() => {
                            if (jitsiObject) {
                              jitsiObject.executeCommand("toggleRaiseHand");
                              // setRaiseHandOpened(true);
                            }
                          }}
                          aria-label="BackHand"
                          size="large"
                        >
                          <BackHand />
                        </IconButton>
                      </Tooltip>
                    </div>
                    {userDetails.userType !== "GUEST" && (
                      <div className="meetContBtn">
                        <Tooltip title={t("invite_user")} arrow>
                          <IconButton
                            onClick={() => {
                              setOpenMeetingMenu(false);
                              handleMenuClick(8);
                              setvalue(4);
                            }}
                            aria-label="BackHand"
                            size="large"
                          >
                            <PersonAdd />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                    {/* <MenuItem
                    onClick={() => {
                      setOpenMeetingMenu(false);
                      handleMenuClick(8);
                      setvalue(4);
                    }}
                  >
                    <div className="meetMrMnuBtn">
                      <PersonAdd /> <span>Invite User</span>
                    </div>
                  </MenuItem> */}
                    {selEvent?.eventType === "Webinar" &&
                      isConvenor &&
                      !streamStarted && (
                        <div className="meetContBtn">
                          <Tooltip title={t("start_live_stream")} arrow>
                            <IconButton
                              onClick={() => {
                                console.log(selEvent.eventWebinarStreamKey);
                                setStreamStarted(true);
                                jitsiObject.executeCommand("startRecording", {
                                  mode: "stream",
                                  youtubeStreamKey:
                                    selEvent.eventWebinarStreamKey,
                                });
                              }}
                            >
                              <LiveTvOutlined />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    {selEvent?.eventType === "Webinar" && streamStarted && (
                      <div
                        className="meetContBtn"
                        style={{ color: "red !important" }}
                      >
                        <Tooltip title={t("stop_live_stream")} arrow>
                          <IconButton
                            onClick={() => {
                              jitsiObject.executeCommand(
                                "stopRecording",
                                "stream"
                              );
                              setStreamStarted(false);
                            }}
                          >
                            <LiveTvOutlined />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                    {props.extraData && (
                      <div
                        className={`meetContBtn ${
                          value === 9 ? "metBtnActive" : ""
                        }`}
                      >
                        <Tooltip title={t("medication")} arrow>
                          <IconButton
                            onClick={() => {
                              setOpenMeetingMenu(false);
                              handleMenuClick(6);
                              setvalue(9);
                            }}
                            aria-label="BackHand"
                            size="large"
                          >
                            <Medication />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                    <div className="meetContBtn callEnd">
                      <Tooltip title={t("leave_call")} arrow>
                        <IconButton
                          onClick={() => {
                            if (selEvent?.eventType === "Webinar") {
                              setShowEndCallDialog(true);
                            } else {
                              confirm({ description: "Leave meeting!" })
                                .then(() => {
                                  if (props.extraData) {
                                    closeMeeting(
                                      !props.extraData.noFeedback,
                                      props?.extraData?.isDoctor
                                        ? "Doctor"
                                        : "Patient"
                                    );
                                    return;
                                  }
                                  console.log(props);
                                  setSelEvent(null);
                                  setRoomId(null);
                                  handleMenuCloseButton();
                                  addTab({
                                    key: v4(),
                                    title: "Meeting Room",
                                    content: (
                                      <ConfirmProvider>
                                        <MeetingsTabContainer />
                                      </ConfirmProvider>
                                    ),
                                    isDraggable: true,
                                    type: "New",
                                    replaceTabKey: props.tabKey,
                                  });
                                })
                                .catch(() => {
                                  console.log("Leave meeting");
                                });
                            }
                          }}
                          aria-label="callEnd"
                          size="large"
                        >
                          <CallEnd />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className="meetContBtn ml5">
                      <Tooltip title={t("more")} arrow>
                        <IconButton
                          aria-label="More"
                          size="large"
                          onClick={(event) =>
                            setOpenMeetingMenu(event.currentTarget)
                          }
                          aria-controls={
                            open ? "moreMenuOptionView" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                          <MoreVert />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <Menu
                      className="moreMenuOptionView"
                      id="moreMenuOptionView"
                      anchorEl={openMeetingMenu}
                      open={Boolean(openMeetingMenu)}
                      onClose={() => setOpenMeetingMenu(false)}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          setOpenMeetingMenu(false);
                          handleMenuClick(8);
                          setvalue(0);
                        }}
                      >
                        {/* <Button startIcon={<CcCircleFill />}>
                        Transcription Details
                      </Button> */}
                        <div className="meetMrMnuBtn">
                          <CcCircleFill />{" "}
                          <span>{t("transcription_details")}</span>
                        </div>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setOpenMeetingMenu(false);
                          handleMenuClick(8);
                          setvalue(1);
                        }}
                      >
                        {/* <Button startIcon={<Language />}>
                        Select Spoken Languages
                      </Button> */}
                        <div className="meetMrMnuBtn">
                          <Language />{" "}
                          <span>{t("select_spoken_languages")}</span>
                        </div>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setOpenMeetingMenu(false);
                          handleMenuClick(8);
                          setvalue(2);
                        }}
                      >
                        {/* <Button startIcon={<Groups />}>Participants</Button> */}
                        <div className="meetMrMnuBtn">
                          <Groups /> <span>{t("participants")}</span>
                        </div>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setOpenMeetingMenu(false);
                          handleMenuClick(8);
                          setvalue(3);
                        }}
                      >
                        {/* <Button startIcon={<Description />}>Meeting Notes</Button> */}
                        <div className="meetMrMnuBtn">
                          <Description /> <span>{t("meeting_notes")}</span>
                        </div>
                      </MenuItem>
                      {/* <MenuItem
                        onClick={(event) => {
                          handleClickAudioOptions(event);
                          setOpenMeetingMenu(false);
                          // handleMenuClick(8);
                          // setvalue(3);
                        }}
                      >
                        <div className="meetMrMnuBtn">
                          <SpeakerGroup /> <span>{t("audio_devices")}</span>
                        </div>
                      </MenuItem> */}

                      <Menu
                        className="audioOptions"
                        Private
                        Notes
                        id="audio-options"
                        anchorEl={anchorAudioOptions}
                        open={openAudioOptions}
                        onClose={handleCloseAudioOptions}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <Paper
                          sx={{
                            p: 2,
                            margin: "auto",
                            maxWidth: 300,
                            flexGrow: 1,
                          }}
                          elevation={0}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            // columnSpacing={"5px"}
                            columns={16}
                          >
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                              <Typography>
                                <Mic />
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={13} lg={13}>
                              <Typography>Microphones</Typography>
                              <br></br>
                            </Grid>
                            {audioInput &&
                              audioInput.map((device) => {
                                return (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={3}
                                      lg={3}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <Typography>
                                        {selAudioInput &&
                                          device &&
                                          device.deviceId === selAudioInput && (
                                            <Check />
                                          )}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={13}
                                      lg={13}
                                      sx={{ cursor: "pointer" }}
                                      mb={1}
                                      onClick={() => {
                                        jitsiObject.setAudioInputDevice(
                                          device.label,
                                          device.deviceId
                                        );
                                        setSelAudioInput(device.deviceId);
                                      }}
                                    >
                                      <Typography>{device.label}</Typography>
                                    </Grid>
                                  </>
                                );
                              })}
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                              <hr></hr>
                            </Grid>
                            <Grid item xs={12} sm={12} md={13} lg={13}>
                              <hr></hr>
                            </Grid>

                            <Grid item xs={12} sm={12} md={3} lg={3}>
                              <Typography>
                                <Speaker />
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={13} lg={13}>
                              <Typography>{t("speakers")}</Typography>
                              <br></br>
                            </Grid>
                            {audioOutput &&
                              audioOutput.map((device) => {
                                return (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={3}
                                      lg={3}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <Typography>
                                        {device &&
                                          selAudioOutput &&
                                          device.deviceId ===
                                            selAudioOutput && <Check />}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={13}
                                      lg={13}
                                      sx={{ cursor: "pointer" }}
                                      mb={1}
                                      onClick={() => {
                                        console.log(
                                          "setAudioOutputDevice-->",
                                          device
                                        );
                                        jitsiObject.setAudioOutputDevice(
                                          device.label,
                                          device.deviceId
                                        );
                                        setSelAudioOutput(device.deviceId);
                                      }}
                                    >
                                      <Typography>{device.label}</Typography>
                                    </Grid>
                                  </>
                                );
                              })}
                          </Grid>
                        </Paper>
                        {/* <List>
            <ListItem>
              <Speaker /> Speakers
            </ListItem>
            {audioOutput.map((device) => {
              return <ListItemButton>{device.label}</ListItemButton>;
            })}
          </List> */}
                      </Menu>

                      {/* <MenuItem
                        onClick={(event) => {
                          handleClickVideoOptions(event);
                          setOpenMeetingMenu(false);
                          // handleMenuClick(8);
                          // setvalue(3);
                        }}
                      >
                        <div className="meetMrMnuBtn">
                          <Camera /> <span>{t("video_devices")}</span>
                        </div>
                      </MenuItem> */}

                      <Menu
                        className="videoOptions"
                        id="video-options"
                        anchorEl={anchorVideoOptions}
                        open={openVideoOptions}
                        onClose={handleCloseVideoOptions}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <Paper
                          sx={{
                            p: 2,
                            margin: "auto",
                            maxWidth: 300,
                            flexGrow: 1,
                          }}
                          elevation={0}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            // columnSpacing={"5px"}
                            columns={16}
                          >
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                              <Typography>
                                <VideoCamera />
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={13} lg={13}>
                              <Typography>{t("video_camera")}</Typography>
                              <br></br>
                            </Grid>
                            {videoOutput &&
                              videoOutput.map((device) => {
                                return (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={3}
                                      lg={3}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <Typography>
                                        {device &&
                                          selVideoOutput &&
                                          device.deviceId ===
                                            selVideoOutput && <Check />}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={13}
                                      lg={13}
                                      sx={{ cursor: "pointer" }}
                                      mb={1}
                                      onClick={() => {
                                        jitsiObject.setVideoInputDevice(
                                          device.label,
                                          device.deviceId
                                        );
                                        setSelVideoOutput(device.deviceId);
                                      }}
                                    >
                                      <Typography>{device.label}</Typography>
                                    </Grid>
                                  </>
                                );
                              })}
                          </Grid>
                        </Paper>
                      </Menu>

                      <MenuItem
                        // onClick={() => {
                        //   setOpenMeetingMenu(false);
                        //   handleMenuClick(8);
                        //   setvalue(3);
                        // }}
                        onClick={() => {
                          if (jitsiObject) {
                            jitsiObject.executeCommand("toggleTileView");
                          }
                          setOpenMeetingMenu(false);
                          // handleMenuClick(8);
                        }}
                      >
                        {/* <Button startIcon={<Description />}>Meeting Notes</Button> */}
                        <div className="meetMrMnuBtn">
                          <GridViewOutlined /> <span>{t("grid_view")}</span>
                        </div>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          if (jitsiObject) {
                            jitsiObject.executeCommand(
                              "toggleVirtualBackgroundDialog"
                            );
                          }
                          setOpenMeetingMenu(false);
                        }}
                      >
                        {/* <Button startIcon={<Description />}>Meeting Notes</Button> */}
                        <div className="meetMrMnuBtn">
                          <WallpaperOutlined />{" "}
                          <span>{t("virtual_background")}</span>
                        </div>
                      </MenuItem>
                      {/* <MenuItem
                      onClick={() => {
                        setOpenMeetingMenu(false);
                        handleMenuClick(8);
                        setvalue(4);
                      }}
                    >
                      <div className="meetMrMnuBtn">
                        <PersonAdd /> <span>Invite User</span>
                      </div>
                    </MenuItem> */}
                      <MenuItem
                        onClick={() => {
                          setOpenMeetingMenu(false);
                          handleMenuClick(8);
                          setvalue(5);
                          setIsFileUploaded(0);
                        }}
                      >
                        {/* <Button startIcon={<FolderShared />}>File Share</Button> */}

                        <div className="meetMrMnuBtn">
                          <Badge
                            variant="dot"
                            color="primary"
                            invisible={isFileUploaded === 0}
                          >
                            {" "}
                            <FolderShared />
                          </Badge>{" "}
                          <span>{t("file_share")}</span>
                        </div>
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          setOpenMeetingMenu(false);
                          handleMenuClick(8);
                          setvalue(6);
                        }}
                      >
                        {/* <Button startIcon={<MeetingRoom />}>
                        Breakout Rooms
                      </Button> */}
                        <div className="meetMrMnuBtn">
                          <MeetingRoom /> <span>{t("breakout_rooms")}</span>
                        </div>
                      </MenuItem>
                    </Menu>
                  </div>
                )}
              </div>
            ) : (
              <div className="joinMeetInstanceRoomid">
                <div className="joinMeetInstanceRoomidContainer">
                  {!loading && (
                    <div className="joinMetWthRmIDGrp">
                      <div className="inputRumID">
                        <input
                          type="text"
                          placeholder={t("enter_room_number")}
                          className="joinMetRmIdInputBox"
                          value={roomId2}
                          onChange={(e) => {
                            const val = e.target.value;
                            const regx = /\D/g;
                            e.target.value = val.replace(regx, "");
                            setRoomId2(val.replace(regx, ""));
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              console.log("onKeyDown join:", event.key);
                              joinRoom(null);
                            }
                          }}
                        />
                      </div>
                      <div className="joinRumIDBtn">
                        {!loadingRoom && (
                          <Button
                            className="joinBtnRoomNo"
                            variant="contained"
                            onClick={() => {
                              joinRoom(null);
                            }}
                          >
                            {t("join")}
                          </Button>
                        )}
                        {loadingRoom && (
                          <Button variant="contained">
                            <CircularProgress
                              size={20}
                              sx={{
                                color: (theme) => theme.palette.grey[100],
                              }}
                            />
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                  {!loadingRoom && (
                    <>
                      {!loading && <div className="or">or</div>}
                      <div className="joinMeetingBtnGroup">
                        {!loading && (
                          <Tooltip
                            title={
                              roomId2 !== "" && roomId2 != null
                                ? t(
                                    "you_have_entered_a_room_number_please_use_join"
                                  )
                                : ""
                            }
                          >
                            <Button
                              className="joinInsMetBtn"
                              startIcon={<Videocam />}
                              onClick={() =>
                                handleSubmit(startInstantMeeting)()
                              }
                              disabled={roomId2 !== "" && roomId2 != null}
                            >
                              {/* {"Start Instant Meeting"} */}
                              {t("start_instant_meeting")}
                            </Button>
                          </Tooltip>
                        )}
                        {loading && (
                          <Button
                            className="joinInsMetBtn"
                            onClick={() => handleSubmit(startInstantMeeting)()}
                          >
                            <CircularProgress
                              size={20}
                              sx={{
                                color: (theme) => theme.palette.grey[100],
                              }}
                            />
                          </Button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </Grid>
        {gridItemSize === 8 && (
          <Grid item xs={4} className="fullWidth100">
            <div className="meetingTabRightPanel">
              <MeetingTabRightPanel
                handleMenuCloseButton={handleMenuCloseButton}
                value={value}
                selEvent={selEvent}
                eventId={eventId}
                roomNumber={roomId}
                jitsiObject={jitsiObject}
                extraData={props.extraData}
                closeMeeting={closeMeeting}
                prescriptionData={prescriptionData}
                updatePrescriptionData={updatePrescriptionData}
              />
            </div>
          </Grid>
        )}
        {gridItemSize === 6 && (
          <Grid item xs={6} className="fullWidth100">
            <div className="meetingTabRightPanel">
              <MeetingTabRightPanel
                handleMenuCloseButton={handleMenuCloseButton}
                value={value}
                selEvent={selEvent}
                eventId={eventId}
                roomNumber={roomId}
                jitsiObject={jitsiObject}
                isWhiteBoardOpened={isWhiteBoardOpened}
                setIsWhiteBoardOpened={setIsWhiteBoardOpened}
                extraData={props.extraData}
                closeMeeting={closeMeeting}
                prescriptionData={prescriptionData}
                updatePrescriptionData={updatePrescriptionData}
              />
            </div>
          </Grid>
        )}
      </Grid>
      {showAgendaModal && (
        <Modal
          open={showAgendaModal}
          onClose={() => setShowAgendaModal(false)}
          aria-labelledby="modal-modal-invite-person"
          aria-describedby="modal-modal-invite-person"
          className="customModal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              border: "1px solid #8d8d8d",
              borderRadius: "5px",
              boxShadow: 24,
              width: "50%",
              maxHeight: "90vh",
              overflow: "auto",
              p: 2,
            }}
            className="responsiveModalAdmin"
          >
            <div style={{ marginBottom: "20px" }}>
              <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
                <label>{t("meeting_agendas")}</label>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <table className="hrActivityGroupTable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{t("subject")}</th>
                      <th>{t("details")}</th>
                      <th>{t("p_s")}</th>
                      <th>{t("files")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {meetingAgendasList !== null &&
                    meetingAgendasList.length > 0 ? (
                      meetingAgendasList.map((agenda, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{agenda.noticeSubject}</td>
                          <td>{agenda.noticeDetail}</td>
                          <td>
                            {agenda.polling === "Y" && (
                              <Tooltip
                                title={t("polling")}
                                arrow
                                placement="right"
                              >
                                <OneFingerSelectHandGesture className="attachIcoSelected" />
                              </Tooltip>
                            )}
                            {agenda.showOfHand === "Y" && (
                              <Tooltip
                                title={t("show_of_hand")}
                                arrow
                                placement="right"
                              >
                                <SpockHandGesture className="attachIcoSelected" />
                              </Tooltip>
                            )}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {agenda.fileLinks.length > 0 && (
                              <>
                                <Tooltip
                                  title={
                                    <ul>
                                      {agenda.fileLinks.map(
                                        (file, fileIndex) => (
                                          <li key={fileIndex}>
                                            {file.fileName}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  }
                                >
                                  <AttachFile
                                    className="attachIcoSelected"
                                    onClick={() =>
                                      handelDownloadFileFromUrl(
                                        agenda.fileLinks
                                      )
                                    }
                                  />
                                </Tooltip>
                              </>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5">{t("no_agenda_available")}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => setShowAgendaModal(false)}
              sx={{ float: "right" }}
            >
              {t("close")}
            </Button>
          </Box>
        </Modal>
      )}

      {showEndCallDialog && (
        <Modal
          open={showEndCallDialog}
          onClose={() => setShowEndCallDialog(false)}
          aria-labelledby="modal-modal-invite-person"
          aria-describedby="modal-modal-invite-person"
          className="customModal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              border: "1px solid #8d8d8d",
              borderRadius: "5px",
              boxShadow: 24,
              width: "30%",
              maxHeight: "90vh",
              overflow: "auto",
              p: 2,
            }}
            className="responsiveModalAdmin"
          >
            <IconButton
              disabled={loading}
              className="closeWebinarLeaveModelBtn"
              onClick={() => setShowEndCallDialog(false)}
            >
              <XLg />
            </IconButton>
            <div style={{ padding: "5px" }}>
              <div
                className="modelTitle"
                style={{ padding: "5px 0px 20px 0px" }}
              >
                <span>
                  {isConvenor ? t("leave_end_webinar") : t("leave_webinar")}
                </span>
              </div>
            </div>

            <div className="webinarLeaveBtnGrp">
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => setShowEndCallDialog(false)}
              >
                {t("close")}
              </Button>
              {isConvenor && (
                <Button
                  className="endForAll"
                  onClick={() => {
                    confirm({
                      description: t(
                        "end_the_webinar_for_all_users_the_webinar_will_be_closed_and_you_will_not_be_able_to_start_it_again"
                      ),
                    })
                      .then(() => {
                        setSelEvent(null);
                        setRoomId(null);
                        handleMenuCloseButton();
                        jitsiObject.executeCommand("stopRecording", "stream");
                        setStreamStarted(false);
                        meetingSocket.emit("endWebinar", {
                          userName: userDetails.userName,
                          roomNumber: roomId,
                        });
                        setShowEndCallDialog(false);

                        let htmlOtgMsg =
                          '<div class="message-wrapper outgoing-msg">' +
                          '<div class="message">' +
                          "<span>" +
                          "The webinar has ended, you will be able to view the webinar and see the previous messages only" +
                          "</span>" +
                          '<div class="messageMeta">' +
                          "<span>" +
                          "System generated message" +
                          "</span> &nbsp" +
                          '<span class="chatDT">' +
                          DateUtils.getDateInDDMMYYYYHH12MM(new Date()) +
                          "</span>" +
                          "</div>" +
                          "</div>" +
                          "</div>";
                        // document.getElementById("nav-chat").append(htmlOtgMsg);

                        let reqDto = {
                          userId: userDetails.userId,
                          userName: "System generated message",
                          chatText:
                            "The webinar has ended, you will be able to view the recorder webinar in the webinar link and see the previous messages only",
                          roomId: roomId,
                          activeFlag: "Y",
                        };

                        WebinarService.addWebinarChat(reqDto).then(
                          (response) => {
                            console.log(
                              "addWebinarChat response",
                              response.data
                            );
                          }
                        );

                        // navTabRef.current.innerHTML += htmlOtgMsg;
                        meetingSocket.emit("sendChatMessage", {
                          roomId: roomId,
                          message:
                            "The webinar has ended, you will be able to view the recorded webinar in the webinar link and see the previous messages only",
                          userDetails: {
                            userEmailId: userDetails.userEmailId,
                            userName: "System generated message",
                          },
                        });

                        addTab({
                          key: v4(),
                          title: "Meeting Room",
                          content: (
                            <ConfirmProvider>
                              <MeetingsTabContainer />
                            </ConfirmProvider>
                          ),
                          isDraggable: true,
                          type: "New",
                          replaceTabKey: props.tabKey,
                        });
                      })
                      .catch(() => {
                        console.log("End webinar cancelled");
                        // setShowEndCallDialog(false);
                      });
                  }}
                >
                  {t("end_webinar_for_all")}
                </Button>
              )}
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  setSelEvent(null);
                  setRoomId(null);
                  handleMenuCloseButton();
                  setShowEndCallDialog(false);
                  addTab({
                    key: v4(),
                    title: "Meeting Room",
                    content: (
                      <ConfirmProvider>
                        <MeetingsTabContainer />
                      </ConfirmProvider>
                    ),
                    isDraggable: true,
                    type: "New",
                    replaceTabKey: props.tabKey,
                  });
                }}
              >
                {t("leave_webinar")}
              </Button>
            </div>
          </Box>
        </Modal>
      )}

      {showSwitchModal && (
        <CustomModal
          modalTitle={"Doctor has switched your consultation."}
          closeModal={() => {
            setShowSwitchModal(false);
          }}
          // className={"width90"}
        >
          <div className="addNewformGrp"></div>
          <div className="addNewformGrp">
            <Button
              className="dfultPrimaryBtn "
              onClick={handleCloseSwithchModal}
            >
              {t("ok")}
            </Button>
          </div>
        </CustomModal>
      )}
    </Box>
  );
}
