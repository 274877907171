import { AccountTree, Add } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Modal,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import { getUnits, deactivateUnit } from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function DefineUnits({
  handleOpenMenuButton,
  handleOpenUnitTreeView,
  refreshUnit,
  handleOpenUpdateUnits,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();
  const [unitsData, setUnitData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUnitData();
  }, [refreshUnit]);

  const fetchUnitData = () => {
    getUnits(userDetails.orgId)
      .then((response) => {
        console.log("getUnits", response.data);
        const sortedUnits = response.data.sort((a, b) => a.unitId - b.unitId);
        setUnitData(sortedUnits);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  };

  // const handleDelete = (unitId) =>{
  //   console.log('handleDelete', unitId)
  //   deactivateUnit(unitId , userDetails.userId)
  //   .then((response)=>{
  //     console.log('data deleted', response.data);
  //     if (response.data.returnValue === "1") {
  //       toast.success(response.data.message , {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       fetchUnitData();
  //     }
  //   })
  //   }

  const [openModal, setOpenModal] = useState(false);

  const [unitToDelete, setUnitToDelete] = useState(null);

  const handleDelete = (unitId) => {
    console.log("handleDelete", unitId);
    setUnitToDelete(unitId);
    setOpenModal(true);
  };

  const confirmDelete = () => {
    if (unitToDelete) {
      deactivateUnit(unitToDelete, userDetails.userId).then((response) => {
        console.log("data deleted", response.data);
        if (response.data.returnValue === "1") {
          toast.success(t("unit_deactivated_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchUnitData();
          setOpenModal(false); // Close the modal after deletion
        }
      });
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className="createUnitsArea">
        <div className="cuHeaderGrp">
          <div className="cuhederTxt">{t("unit_list")}</div>
          <div className="addUnitsBtn">
            <Button
              startIcon={<AccountTree />}
              className="dfultDarkSecondaryBtn"
              onClick={() => handleOpenUnitTreeView()}
            >
              {t("unit_tree")}
            </Button>
            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => handleOpenMenuButton()}
            >
              {t("add_units")}
            </Button>
          </div>
        </div>
        <div className="cuContainArea">
          {loading ? (
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          ) : unitsData.length === 0 ? (
            <div class="noDataCard ">
              <span>{t("oops")}</span>{t("no_units_define_yet")}
              <br />
              <span className="sallMsg">
              {t("click_add_units_button_to_create_new_units")}
              </span>
            </div>
          ) : (
            <div className="cuCardList">
              {unitsData.map((unit, index) => (
                <Card key={index} className="cardContent">
                  <CardContent>
                    <div className="CUcardTitle">{unit.unitName}</div>

                    {/* <div className="CUCardLocation">
                      {unit.organizationAddressDto.buildingNameNo +
                        ", " +
                        unit.organizationAddressDto.area +
                        ", " +
                        unit.organizationAddressDto.city +
                        ", " +
                        unit.organizationAddressDto.stateName +
                        ", " +
                        unit.organizationAddressDto.pinCd}
                    </div> */}

                    <div className="CUCardLocation">
                      {`${
                        unit.organizationAddressDto.buildingNameNo
                          ? unit.organizationAddressDto.buildingNameNo + ", "
                          : ""
                      }
                      ${
                        unit.organizationAddressDto.area
                          ? unit.organizationAddressDto.area + ", "
                          : ""
                      }${
                        unit.organizationAddressDto.city
                          ? unit.organizationAddressDto.city + ", "
                          : ""
                      }${
                        unit.organizationAddressDto.stateName
                          ? unit.organizationAddressDto.stateName + " - "
                          : ""
                      }${
                        unit.organizationAddressDto.pinCd
                          ? unit.organizationAddressDto.pinCd + " "
                          : ""
                      }`}
                    </div>
                    {unit.timezone && (
                      <div className="cuCheckItem">
                        {t("timezone")}: <span>{unit.timezone}</span>
                      </div>
                    )}
                    
                    {unit.contactNo && (
                    <div className="cuCheckItem">
                    {t("contact_number")}:{" "}
                    <span>{unit.organizationAddressDto.callingCd}-{unit.contactNo}</span>
                    </div>
                    )}

                    <div className="cuCheckItem">
                    {t("cost_center")}:{" "}
                      <span>{unit.costCenter === "Y" ? t("yes") : t("no")}</span>
                    </div>

                    <div className="cuCheckItem">
                    {t("profit_center")}:{" "}
                      <span>{unit.profitCenter === "Y" ? t("yes") : t("no")}</span>
                    </div>

                    <div className="cuCheckItem">
                    {t("balance_sheet_maintain_by_own")}:{" "}
                      <span>{unit.ownBalanceSheet === "Y" ? t("yes") : t("no")}</span>
                    </div>

                    <div className="cuCheckItem">
                    {t("statutory_compliances")}:{" "}
                      <span>
                        {unit.statutoryCompliances === "Y" ? t("yes") : t("no")}
                      </span>
                    </div>

                    <div className="cuCheckItem">
                    {t("local_tax_applicable")}:{" "}
                      <span>{unit.localTax === "Y" ? t("yes") : t("no")}</span>
                    </div>

                    <div className="cuCheckItem">
                    {t("default_currency")}:{" "}
                      <span>
                        {unit.currency && unit.currency.toUpperCase()}
                      </span>
                    </div>

                    {unit.pan && unit.pan.trim() !== "" && (
                      <div className="cuCheckItem">
                        {t("pan")}: <span>{unit.pan}</span>
                      </div>
                    )}
                    {unit.tan && unit.tan.trim() !== "" && (
                      <div className="cuCheckItem">
                        {t("tan")}: <span>{unit.tan}</span>
                      </div>
                    )}
                    {unit.epfNo && unit.epfNo.trim() !== "" && (
                      <div className="cuCheckItem">
                        {t("epf_no")}: <span>{unit.epfNo}</span>
                      </div>
                    )}
                    {unit.gstin && unit.gstin.trim() !== "" && (
                      <div className="cuCheckItem">
                        {t("gst_no")} : <span>{unit.gstin}</span>
                      </div>
                    )}
                  </CardContent>
                  <CardActions>
                    <div className="taskVDContBtn">
                      <Button
                        variant="contained"
                        startIcon={<Trash />}
                        onClick={() => handleDelete(unit.unitId)}
                      >
                        {t("delete_unit")}
                      </Button>
                    </div>
                    <div className="taskVDContBtn">
                      <Button
                        variant="outlined"
                        startIcon={<PencilSquare />}
                        onClick={() => handleOpenUpdateUnits(unit)}
                      >
                        {t("edit_unit")}
                      </Button>
                    </div>
                  </CardActions>
                </Card>
              ))}
            </div>
          )}

          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                // width: "250px",
                // height: "250px",
                maxWidth: "600px",
                minHeight: "150px",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <h2 id="modal-modal-title">{t("do_you_want_to_delete_this_unit")} ?</h2>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  // variant="contained"
                  // color="secondary"
                  className="dfultPrimaryBtn"
                  onClick={confirmDelete}
                >
                 {t("yes")}
                </Button>
                <Button
                  // variant="contained"
                  className="dfultDarkSecondaryBtn"
                  onClick={handleCloseModal}
                >
                  {t("no")}
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}
