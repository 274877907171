import React, { useState } from "react";
import "../../../css/payroll.css";
import SetESI from "./SetESI";
import SetEPF from "./SetEPF";
import SetPT from "./SetPT";
import SetLWF from "./SetLWF";
import SetSalaryComponent from "./SetSalaryComponent";
import SetSalaryTemplate from "./SetSalaryTemplate";

export default function DefinePayroll({
  handelOpenASC,
  handelOpenSalaryTemplate,
}) {
  const [activeMenu, setActiveMenu] = useState("Statutory Compliance");
  const [activeSubMenu, setActiveSubMenu] = useState(
    "Employee's Provident Fund"
  );

  const renderContent = () => {
    switch (activeSubMenu) {
      case "Employee's Provident Fund":
        return <SetEPF />;
      case "Employee's State Insurance":
        return <SetESI />;
      case "Professional Tax":
        return <SetPT />;
      case "Labour Welfare Fund":
        return <SetLWF />;
      case "Salary Components":
        return <SetSalaryComponent handelOpenASC={handelOpenASC} />;
      case "Salary Templates":
        return (
          <SetSalaryTemplate
            handelOpenSalaryTemplate={handelOpenSalaryTemplate}
          />
        );
      default:
        return <SetEPF />;
    }
  };

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
    if (menu === "Statutory Compliance") {
      setActiveSubMenu("Employee's Provident Fund");
    } else {
      setActiveSubMenu(menu);
    }
  };

  return (
    <>
      <div className="fullContainArea">
        <div className="cuContainArea">
          <div className="payrollcontainer">
            <div className="payrollsidebar">
              <div className="payrollheader">Setup Payroll</div>
              <ul className="payrollmenu">
                <li
                  className={`payrollmenu-item ${
                    activeMenu === "Statutory Compliance" ? "activeMenu" : ""
                  }`}
                >
                  <div
                    className="prText prHeading"
                    onClick={() => handleMenuClick("Statutory Compliance")}
                  >
                    Statutory Compliance
                  </div>
                  {activeMenu === "Statutory Compliance" && (
                    <ul className="payrollsubmenu">
                      <li
                        className={`payrollmenu-item ${
                          activeSubMenu === "Employee's Provident Fund"
                            ? "activeItem"
                            : ""
                        }`}
                      >
                        <div
                          className="prText"
                          onClick={() =>
                            setActiveSubMenu("Employee's Provident Fund")
                          }
                        >
                          EPF
                        </div>
                      </li>
                      <li
                        className={`payrollmenu-item ${
                          activeSubMenu === "Employee's State Insurance"
                            ? "activeItem"
                            : ""
                        }`}
                      >
                        <div
                          className="prText"
                          onClick={() =>
                            setActiveSubMenu("Employee's State Insurance")
                          }
                        >
                          ESI
                        </div>
                      </li>
                      <li
                        className={`payrollmenu-item ${
                          activeSubMenu === "Professional Tax"
                            ? "activeItem"
                            : ""
                        }`}
                      >
                        <div
                          className="prText"
                          onClick={() => setActiveSubMenu("Professional Tax")}
                        >
                          Professional Tax
                        </div>
                      </li>
                      <li
                        className={`payrollmenu-item ${
                          activeSubMenu === "Labour Welfare Fund"
                            ? "activeItem"
                            : ""
                        }`}
                      >
                        <div
                          className="prText"
                          onClick={() =>
                            setActiveSubMenu("Labour Welfare Fund")
                          }
                        >
                          Labour Welfare Fund
                        </div>
                      </li>
                    </ul>
                  )}
                </li>
                <li
                  className={`payrollmenu-item ${
                    activeMenu === "Salary Components" ? "activeMenu" : ""
                  }`}
                >
                  <div
                    className="prText prHeading"
                    onClick={() => handleMenuClick("Salary Components")}
                  >
                    Salary Components
                  </div>
                </li>
                <li
                  className={`payrollmenu-item ${
                    activeMenu === "Salary Templates" ? "activeMenu" : ""
                  }`}
                >
                  <div
                    className="prText prHeading"
                    onClick={() => handleMenuClick("Salary Templates")}
                  >
                    Salary Templates
                  </div>
                </li>
              </ul>
            </div>
            <div className="payrollcontent">
              <div className="payrollheader">
                {activeMenu === "Statutory Compliance" ? (
                  <>
                    Statutory Compliance: <span>{activeSubMenu}</span>
                  </>
                ) : (
                  activeMenu
                )}
              </div>
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
