import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import EmailUtil from "../../features/email/view/EmailUtil";

const initialState = {
  loading: false,
  access_token: null,
  messages: [],
  threadMessages: [],
  error: "",
};

function extractNameAndEmail(inputString) {
  console.log("sender name =", inputString);
  // Using a regular expression to match the pattern "Name <email@example.com>"
  const match = inputString.match(/^(.*) <(.+)>$/);

  if (match) {
    const name = match[1].trim();
    const email = match[2].trim();
    return { name, email };
  } else {
    // If the input does not match the expected pattern, return null or handle it accordingly
    return null;
  }
}

const modifyMessageCall = (messageId, labelsToAdd, labelsToRemove) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await window.gapi.client.gmail.users.messages.modify({
        userId: "me",
        id: messageId,
        addLabelIds: labelsToAdd,
        removeLabelIds: labelsToRemove,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

const modifyMsgLabels = createAsyncThunk(
  "mail/modifyMsgLabels",
  async ({ messageId, labelsToAdd, labelsToRemove, hideMessage }) => {
    const response = await modifyMessageCall(
      messageId,
      labelsToAdd,
      labelsToRemove,
      hideMessage
    );

    const modifiedRes = { ...response, hideMessage };

    console.log("modified response from muntaj = ", modifiedRes);
    return modifiedRes;
  }
);

// const modifyMessageLabelsInThread

const deleteMessage = createAsyncThunk(
  "mail/deleteMessage",
  async (messageId) => {
    const response = await window.gapi.client.gmail.users.messages.delete({
      userId: "me",
      id: messageId,
    });
    console.log("response = ", response);
    if (response.result === false) return { messageId };
  }
);

const moveMessageToTrash = createAsyncThunk(
  "mail/moveMessageToTrash",
  async (messageId) => {
    const response = await window.gapi.client.gmail.users.messages.trash({
      userId: "me",
      id: messageId,
    });
    console.log("response = ", response);
    return response;
  }
);

const fetchMsgBody = createAsyncThunk("mail/fetchMessage", async (messages) => {
  // console.log("fetching message");
  const allMessageList = await Promise.all(
    messages.map(
      (message) =>
        new Promise(async (resolve, reject) => {
          const messageId = message.id;
          try {
            const response = await window.gapi.client.gmail.users.messages.get({
              userId: "me",
              id: messageId,
            });

            const message = response.result;
            const threadId = message.threadId;
            console.log("response=", message);

            const subjectHeader = message.payload.headers.find(
              (header) => header.name === "Subject"
            );
            const subject = subjectHeader ? subjectHeader.value : "No subject";

            // Extracting sender and receiver email addresses
            let sender = message.payload.headers.find(
              (header) => header.name === "From"
            ).value;
            const senderDetails = extractNameAndEmail(sender);
            let senderEmail = "";
            let senderName = "";
            if (senderDetails) {
              senderEmail = senderDetails.email;
              senderName = senderDetails.name;
            } else {
              senderEmail = sender;
            }
            //   const { senderName, senderEmail } = sender;

            let receiver = message.payload.headers.find(
              (header) => header.name === "To"
            );
            receiver = receiver ? receiver.value : null;

            // Extracting sending date
            const sendingDate = message.payload.headers.find(
              (header) => header.name === "Date"
            ).value;

            // Extracting attachments
            const attachments = message.payload.parts
              ? message.payload.parts
                  .filter((part) => part.filename)
                  .map((part) => ({
                    filename: part.filename,
                    mimeType: part.mimeType,
                    body: part.body,
                  }))
              : null;

            // Extracting message body
            const body = message.snippet; // You can use message.payload.body.data for the base64-encoded content
            const labels = message.labelIds;

            // Returning an object with extracted information
            resolve({
              messageId,
              threadId,
              senderName,
              senderEmail,
              receiver,
              sendingDate,
              attachments,
              body,
              labels,
              subject,
            });
          } catch (error) {
            console.log("error of  " + messageId);
            console.log(error);
          }
        })
    )
  );
  console.log("allMessages are ", allMessageList);
  return allMessageList;
});

const fetchThreadList = createAsyncThunk(
  "mail/fetchThreadList",
  async (threadId) => {
    try {
      const query = "NOT label:TRASH";
      const response = await window.gapi.client.gmail.users.threads.get({
        userId: "me",
        id: threadId,
        labelIds: "include",
        q: query,
      });

      // Process the response here
      console.log(" response ", response);

      // Access messages from the response
      let messages = response.result.messages;
      // messages = messages.filter((message) => {
      //   const shouldFilter = message.labelIds.includes("TRASH");
      //   return !shouldFilter;
      // });

      let newValue = await Promise.all(
        messages.map(async (message, index) => {
          return new Promise(async (resolve, reject) => {
            let messageId = message.payload.headers.find(
              (header) =>
                header.name === "Message-ID" || header.name === "Message-Id"
            );
            if (messageId) {
              messageId = messageId.value;
            }

            let reference = message.payload.headers.find(
              (header) => header.name === "References"
            );
            if (reference) {
              reference = reference.value;
            }
            const sender = message.payload.headers.find(
              (header) => header.name === "From"
            ).value;
            const receivers = message.payload.headers
              .filter((header) => header.name === "To")
              .map((header) => header.value);
            const cc = message.payload.headers
              .filter((header) => header.name === "Cc")
              .map((header) => header.value);
            const bcc = message.payload.headers
              .filter((header) => header.name === "Bcc")
              .map((header) => header.value);

            let subject = message.payload.headers.find(
              (header) => header.name === "Subject"
            );
            if (subject) {
              subject = subject.value;
            }
            // cosole.log("message = ", message);
            const dateString = message.payload.headers.find(
              (header) => header.name === "Date"
            ).value;
            const date = message.internalDate;
            // const date = message.payload.headers.find(
            //   (header) => header.name === "Date"
            // ).value;

            const snippet = message.snippet;

            // const fullMessage =
            //   await window.gapi.client.gmail.users.messages.get({
            //     userId: "me",
            //     id: message.id,
            //   });

            // console.log("message is =", fullMessage);
            const lableIds = message.labelIds;
            const payload = message.payload;
            let body = null;
            let parseMailData = null;
            if (payload.mimeType === "text/plain") {
              body = payload.body.data;
            }
            if (payload.mimeType === "text/html") {
              parseMailData = payload.body.data;
            } else if (
              payload.mimeType === "multipart/alternative" ||
              payload.mimeType === "multipart/mixed" ||
              payload.mimeType === "multipart/related"
            ) {
              payload.parts &&
                payload.parts.forEach((item) => {
                  console.log("mime types are = ", item.mimeType);
                });
              let text = payload.parts.find(
                (obj) => obj.mimeType === "text/plain"
              );
              let html = payload.parts.find(
                (obj) => obj.mimeType === "text/html"
              );
              const multipart = payload.parts.find(
                (obj) =>
                  obj.mimeType === "multipart/alternative" ||
                  obj.mimeType === "multipart/related" ||
                  obj.mimeType === "multipart/mixed"
              );
              if (multipart) {
                text = multipart.parts.find(
                  (obj) => obj.mimeType === "text/plain"
                );
                html = multipart.parts.find(
                  (obj) => obj.mimeType === "text/html"
                );
              }
              if (text) {
                body = text.body.data;
              }
              if (html) {
                parseMailData = html.body.data;
              }

              // if (payload.parts[0].parts) {
              //   if (payload.parts[0].parts[1])
              //     body = payload.parts[0].parts[1].body.data;
              //   parseMailData =
              //     payload.parts[0].parts[0].body.data;
              //   // console.log("body= ", body);
              // } else {
              //   // console.log(`${message.id} body in else= `, body);
              //   body = payload.parts[1].body.data;
              //   parseMailData = payload.parts[0].body.data;
              // }
            }
            // console.log("string to be decoded=", body);
            if (body) {
              body = body && body.replace(/-/g, "+").replace(/_/g, "/");
              // body = decodeQuotedPrintable(body);

              body = decodeURIComponent(escape(atob(body)));
              // body = body.replace(/â¯/g, "");
            }
            let parsedMail = [];
            let history = null;
            if (parseMailData) {
              let decodedMsg = parseMailData
                .replace(/-/g, "+")
                .replace(/_/g, "/");
              // console.log("decoded message =", decodedMsg);
              decodedMsg = decodeURIComponent(escape(atob(decodedMsg)));
              parsedMail = parseEmailThread(parseMailData, index);
              const parser = new DOMParser();
              const doc = parser.parseFromString(decodedMsg, "text/html");
              let historyDom = doc.querySelector(".gmail_quote");
              if (historyDom) {
                history = historyDom.outerHTML;
                historyDom.parentNode.removeChild(historyDom);
              }

              body = doc.documentElement.outerHTML;
            }
            // if (
            //   payload.mimeType === "multipart/alternative" ||
            //   payload.mimeType === "multipart/mixed" ||
            //   payload.mimeType === "multipart/related"
            // ) {
            //   parsedMail = parseEmailThread(parseMailData, index);
            //   const parser = new DOMParser();
            //   const doc = parser.parseFromString(body, "text/html");
            //   let historyDom = doc.querySelector(".gmail_quote");
            //   if (historyDom) {
            //     history = historyDom.outerHTML;
            //     historyDom.parentNode.removeChild(historyDom);
            //   }

            //   body = doc.documentElement.outerHTML;
            // }
            // console.log("parsed email=", parsedMail);

            let attachmentList = [];

            if (payload && payload.parts) {
              for (const part of payload.parts) {
                if (part.filename && part.body && part.body.attachmentId) {
                  attachmentList.push({
                    attachmentName: part.filename,
                    attachmentId: part.body.attachmentId,
                    mimeType: part.mimeType,
                  });
                }
              }
            }
            // if (payload && payload.parts) {
            //   for (const part of payload.parts) {
            //     if (part.filename && part.body && part.body.attachmentId) {
            //       const attachmentId = part.body.attachmentId;
            //       const filename = part.filename;

            //       const attachmentResponse =
            //         await window.gapi.client.gmail.users.messages.attachments.get(
            //           {
            //             userId: "me",
            //             messageId: message.id,
            //             id: attachmentId,
            //           }
            //         );
            //       const attachmentData = attachmentResponse.result.data;
            //       const attachmentBlob = await saveAttachmentToFile(
            //         attachmentData,
            //         filename
            //       );
            //       console.log("attachment blob=", attachmentBlob);
            //       attachmentList = [...attachmentList, attachmentBlob];
            //     }
            //   }
            // }

            resolve({
              id: message.id,
              threadId,
              messageId,
              lableIds,
              date,
              dateString,
              sender,
              receivers,
              cc,
              bcc,
              subject,
              snippet,
              body,
              history,
              attachmentList,
              parsedMail,
              reference,
            });
          });
        })
      );
      // console.log("newValue = ", newValue);

      // const sortedArray = newValue
      //   .slice()
      //   .sort((a, b) => a.index - b.index)
      //   .map((item) => ({ ...item.data }));

      console.log("return value = ", newValue);
      return newValue;
    } catch (error) {
      // Handle errors here
      toast.error("something Wrong hapened ");
      console.error(error);
      throw error; // Propagate the error further
    }
  }
);

const parseEmailThread = (emailThread, index) => {
  // if (index === 3) {
  // }
  let decodedMsg = emailThread.replace(/-/g, "+").replace(/_/g, "/");
  // console.log("decoded message =", decodedMsg);
  decodedMsg = decodeURIComponent(escape(atob(decodedMsg)));

  const messages = [];
  const lines = decodedMsg.split("\n");

  let currentMessage = null;
  let firstMessage = "";
  let historyMsg = "";
  let isFirstMessage = true;

  for (const line of lines) {
    // console.log("line=", line);
    if (isFirstMessage) {
      if (line.startsWith("On")) {
        isFirstMessage = false;
        historyMsg = historyMsg + "\n" + line;
      } else {
        // Accumulate message content
        firstMessage = firstMessage + "\n" + line;
      }
    } else {
      historyMsg = historyMsg + "\n" + line;
    }
  }
  // console.log("currentMsg=", firstMessage);
  // console.log("history=", historyMsg);

  return { msg: firstMessage, historyMsg: historyMsg };
};

const saveAttachmentToFile = async (attachmentData, filename) => {
  try {
    const temp = attachmentData.replace(/-/g, "+").replace(/_/g, "/");
    const decodedData = atob(temp);

    const uint8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uint8Array[i] = decodedData.charCodeAt(i);
    }

    const blob = new Blob([uint8Array]);
    return blob;

    // const link = document.createElement("a");
    // link.href = window.URL.createObjectURL(blob);
    // link.download = filename;
    // link.click();
  } catch (error) {
    console.error(error);
  }
};

const mailSlice = createSlice({
  name: "mail",
  initialState,
  reducers: {
    clearMessages: (state, action) => {
      console.log("in clear messages");
      state.messages = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMsgBody.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMsgBody.fulfilled, (state, action) => {
        console.log("fetch response = ", action.payload);
        state.loading = false;
        state.messages = action.payload;
        state.error = "";
      })
      .addCase(fetchMsgBody.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(fetchThreadList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchThreadList.fulfilled, (state, action) => {
        console.log("thread messages are ", action.payload);
        state.loading = false;
        state.threadMessages = action.payload;
        state.error = "";
      })
      .addCase(fetchThreadList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(moveMessageToTrash.fulfilled, (state, action) => {
        console.log("deleting message", action.payload);
        let messages = state.messages;
        const id = action.payload.result.id;
        state.messages = messages.filter((item) => item.messageId !== id);
        toast.success("Message Successfully Moved to Trash");
      })
      .addCase(moveMessageToTrash.rejected, (state, action) => {
        toast.error("Something Went Wrong. Try Later.");
      });

    builder
      .addCase(deleteMessage.fulfilled, (state, action) => {
        console.log("deleting message", action.payload);
        let messages = state.messages;
        const id = action.payload.messageId;
        state.messages = messages.filter((item) => item.messageId !== id);
        toast.success("Message Deleted Permanently");
      })
      .addCase(deleteMessage.rejected, (state, action) => {
        toast.error("Something Went Wrong. Try Later.");
      });

    builder.addCase(modifyMsgLabels.fulfilled, (state, action) => {
      const messageId = action.payload.result.id;
      const labels = action.payload.result.labelIds;
      console.log("labels = ", labels);
      console.log("messageid= ", messageId);
      const messages = [...state.messages];
      const threadMessages = state.threadMessages;
      messages.forEach((message) => {
        if (message.messageId === messageId) {
          // console.log("messageId=");
          message.labels = labels;
        }
      });

      threadMessages.forEach((message) => {
        if (message.id === messageId) {
          message.lableIds = labels;
        }
      });
      state.messages = [...messages];
      state.threadMessages = [...threadMessages];
      if (!action.payload.hideMessage) {
        toast.success("labels updated successfully");
      }
    });
  },
});

export default mailSlice;
export {
  fetchMsgBody,
  fetchThreadList,
  modifyMsgLabels,
  moveMessageToTrash,
  deleteMessage,
};
export const { clearMessages } = mailSlice.actions;
