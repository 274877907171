import React from "react";
import SupportTicketDetailsRightSide from "./SupportTicketDetailsRightSide";

export default function RightSideSupportTicketViewDetails({
  setShowSupportTicketDetailsRightSide,
  supportTicketData,
  refreshData,
}) {
  return (
    <>
      <div className="RightFlotingContainerArea">
        <SupportTicketDetailsRightSide
          setShowSupportTicketDetailsRightSide={
            setShowSupportTicketDetailsRightSide
          }
          supportTicketData={supportTicketData}
          refreshData={refreshData}
        />
      </div>
    </>
  );
}
