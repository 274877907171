import React from "react";
import TaskCard from "../cards/TaskCard";
import FilterCard from "../cards/FilterCard";
import NoDataFoundCard from "../cards/NoDataFoundCard";
import { useSelector } from "react-redux";
import LoaderSmall from "../../../../../utils/LoaderSmall";

export default function TaskMenuComponent({ onClick, loading }) {
  const taskList = useSelector((state) => state.task.taskList);
  return (
    <>
      <div className="mnuCompoContainer">
        {/* <FilterCard /> */}
        <div className="mnuCompoIncomingInstanse">
          {loading && <LoaderSmall />}
          {taskList &&
            taskList.map((task) => <TaskCard task={task} onClick={onClick} />)}
          {!taskList ||
            (taskList && taskList.length === 0 && <NoDataFoundCard />)}
        </div>
      </div>
    </>
  );
}
