import {
  CheckCircle,
  EventRepeat,
  FileCopyOutlined,
  VideoCall,
} from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import React, { useContext } from "react";
import userImg from "../../../image/6.png";
import { areSamePeriod, copyToClipboard } from "../../../../../utils/Utils";
import { apiEndPoint } from "../../../../../constants/url_provider";
import { toast } from "react-toastify";
import DateUtils from "../../../../../utils/DateUtils";
import MeetingsTabContainer from "../../../../scheduler/view/component/meetings/view/MeetingsTabContainer";
import { ConfirmProvider } from "material-ui-confirm";
import { v4 as uuidv4 } from "uuid";
import { AddTabContext } from "../../DashboardPage";
import { NO_PROFILE_IMAGE } from "../../../../../constants/const_string";
import { useTranslation } from "react-i18next";

export default function MeetingCard({ data, active, onClick, setEventData }) {
  const { t } = useTranslation();
  const { addTab } = useContext(AddTabContext);
  const printEventDate = () => {
    return DateUtils.printEventDate(
      new Date(data.eventStartTime),
      new Date(data.eventEndTime)
    );

    const startDate = new Date(data.eventStartTime);
    const endDate = new Date(data.eventEndTime);
    if (
      startDate.getFullYear() === endDate.getFullYear() &&
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getDate() === endDate.getDate()
    ) {
      const fullDayName = startDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      const formattedDate = startDate
        .toLocaleDateString("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-");
      const formattedStartTime = startDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      const formattedEndTime = endDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      return `${fullDayName}, ${formattedDate} | ${formattedStartTime} – ${formattedEndTime}`;
    } else {
      const fullStartDayName = startDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      const formattedStartDate = startDate
        .toLocaleDateString("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-");
      const formattedStartTime = startDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      const fullEndDayName = endDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      const formattedEndDate = endDate
        .toLocaleDateString("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-");
      const formattedEndTime = endDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      return `${fullStartDayName}, ${formattedStartDate}, ${formattedStartTime} |  ${formattedEndTime}, ${formattedEndDate}, ${fullEndDayName} `;
    }
  };

  const handleClick = () => {
    onClick(data);
  };

  return (
    <>
      <div className="meetingCardContainer" onClick={handleClick}>
        <div className="contMxWidthLim">
          <div className="meetInOutIoc">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="14"
              viewBox="0 0 22 14"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.9146 0V5.16803L22 0.225806V13.8871L16.9146 8.69355V14H0V0H16.9146ZM8.88132 3.83871H7.48434V6.06281H5.30653V7.47865H7.48434V9.7136H8.88132V7.47865H11.0591V6.06281H8.88132V3.83871Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="meetInfoDtl">
            <div className="meetHead">
              <div className="meetTitle">
                <span>{data?.eventTitle} </span>
              </div>
              <div className="meetDT">
                <span>{data && printEventDate()}</span>
              </div>
            </div>

            <div className="meetDescrp">
              <div className="metDesTitle">
                <span>Description</span>
              </div>
              <div className="metDesDtil">
                <span>
                  {data?.eventDescription}, {data?.eventType}
                </span>
              </div>
            </div>

            {data?.eventLink && (
              <div className="meetLnkBtnGrp">
                <div className="metJonBtn">
                  <Button
                    startIcon={<VideoCall viewBox="0 5 24 14" />}
                    className="joinMetBtn"
                    onClick={() => {
                      console.log("setData", data);
                      var minutesDifference =
                        (new Date(data.eventStartTime) - new Date()) /
                        (1000 * 60);
                      if (minutesDifference > 5) {
                        const minutesToSubtract = 5;
                        const millisecondsToSubtract =
                          minutesToSubtract * 60 * 1000;
                        toast.error(
                          t("this_meeting") +
                            data.eventTitle +
                            t("opens_5_minutes_before_the_start_time_please_join_after") +
                            DateUtils.getDateInDDMMYYYY(
                              new Date(
                                new Date(data.eventStartTime).getTime() -
                                  millisecondsToSubtract
                              )
                            ) +
                            " " +
                            DateUtils.printAMPM(
                              new Date(
                                new Date(data.eventStartTime).getTime() -
                                  millisecondsToSubtract
                              )
                            ),
                          {
                            position: toast.POSITION.TOP_RIGHT,
                          }
                        );
                        return;
                      }
                      // setEventData(data);
                      // alert(1);
                      const key = uuidv4();
                      addTab({
                        key: key,
                        title: `${data.eventTitle}`,
                        content: (
                          <ConfirmProvider>
                            <MeetingsTabContainer
                              eventData={data}
                              tabKey={key}
                            />
                          </ConfirmProvider>
                        ),
                        isDraggable: true,
                        type: "Meeting",
                      });
                    }}
                  >
                    {t("join_call")}
                  </Button>
                </div>
                <div className="metCopyLnk">
                  <div className="lnkTxt">
                    {/* https://api.zoyel.one/z1/join/9223377 */}
                    {`${apiEndPoint}/join/${data?.eventLink}`}
                  </div>
                  <div className="lnkCpyBtn">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() =>
                        navigator.clipboard.writeText(
                          apiEndPoint + "/join/" + data?.eventLink
                        )
                      }
                    >
                      <FileCopyOutlined />
                    </IconButton>
                  </div>
                </div>
              </div>
            )}

            <div className="meetConvDtl">
              <div className="metConvImg">
                <img
                  src={
                    data?.eventConvenorProfileUrl
                      ? data?.eventConvenorProfileUrl
                      : NO_PROFILE_IMAGE
                  }
                  alt="userProfile"
                />
                <div className="vUchk">
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <circle cx="7.5" cy="7.5" r="6.5" fill="#D9D9D9" />
                    <path
                      d="M7.5 0C6.01664 0 4.56659 0.439867 3.33323 1.26398C2.09986 2.08809 1.13856 3.25943 0.570907 4.62987C0.00324963 6.00032 -0.145275 7.50832 0.144114 8.96317C0.433503 10.418 1.14781 11.7544 2.1967 12.8033C3.2456 13.8522 4.58197 14.5665 6.03683 14.8559C7.49168 15.1453 8.99968 14.9967 10.3701 14.4291C11.7406 13.8614 12.9119 12.9001 13.736 11.6668C14.5601 10.4334 15 8.98336 15 7.5C15 6.51508 14.806 5.53981 14.4291 4.62987C14.0522 3.71993 13.4997 2.89314 12.8033 2.1967C12.1069 1.50026 11.2801 0.947814 10.3701 0.570903C9.46019 0.193993 8.48492 0 7.5 0ZM11.757 6.17775L7.257 10.3027C7.11453 10.4334 6.92712 10.504 6.73386 10.4998C6.5406 10.4956 6.35643 10.4169 6.21975 10.2802L3.96975 8.03025C3.89812 7.96106 3.84098 7.8783 3.80168 7.7868C3.76237 7.6953 3.74168 7.59688 3.74081 7.4973C3.73995 7.39771 3.75893 7.29895 3.79664 7.20678C3.83435 7.11461 3.89004 7.03087 3.96046 6.96045C4.03088 6.89003 4.11461 6.83434 4.20679 6.79663C4.29896 6.75892 4.39772 6.73994 4.4973 6.74081C4.59689 6.74167 4.6953 6.76236 4.78681 6.80167C4.87831 6.84098 4.96107 6.89812 5.03025 6.96975L6.7725 8.712L10.743 5.07225C10.8896 4.93778 11.0836 4.86706 11.2824 4.87564C11.4811 4.88422 11.6683 4.9714 11.8028 5.118C11.9372 5.2646 12.0079 5.45861 11.9994 5.65735C11.9908 5.85609 11.9036 6.04328 11.757 6.17775Z"
                      fill="#00AF1C"
                    />
                  </svg> */}
                </div>
              </div>
              <div className="metConvNmD">
                <div className="metConvName">
                  {data?.eventConvenorName}{" "}
                  <span className="nmSpan">({t("convenor")})</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
