import { Button, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { XLg } from "react-bootstrap-icons";
import DashboardServices from "../../../../../services/DashboardServices";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import isAssignedByMe from "../../../taskUtil";

function RightSideMultipleUpdate({
  selectedTasks,
  handleClose,
  updateTaskList,
}) {
  const numberList = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const [updatePercentList, setUpdatePercentList] = useState([]);
  const [currentPercentList, setCurrentPercentList] = useState([]);
  const loginUserDetail = getLoginUserDetails();

  useEffect(() => {
    // selectedTasks.
    console.log("selected tasks=", selectedTasks);
    const currentPercents = selectedTasks.map((task) => task.progress);
    setUpdatePercentList(currentPercents);
    setCurrentPercentList(currentPercents);
  }, [selectedTasks]);

  const handleUpdateTasks = async () => {
    const result = await Promise.all(
      selectedTasks.map((task, index) => {
        return new Promise((resolve, reject) => {
          DashboardServices.updateProgressOfTask(
            task.taskId,
            loginUserDetail.userId,
            updatePercentList[index]
          ).then((response) => {
            if (response.data !== "0") {
              const value = { ...selectedTasks[index] };
              value.progress = updatePercentList[index];
              resolve(value);
            } else {
              resolve({ ...selectedTasks[index] });
            }
          });
        });
      })
    );

    updateTaskList(result);
    console.log("resulved value =", result);
  };

  return (
    <>
      <div className="RightFlotingContainerArea">
        <div className="taskViewPannel">
          <div className="taskViewContainer">
            <div className="taskHeaderPanel">
              <div className="taskTitleContrct">
                <div className="taskTitleNM">{`Update ${selectedTasks.length} Tasks `}</div>
              </div>
              <div className="taskControlBtnGrp"></div>

              <div className="taskActionBtnGrp">
                <div className="taskActBtn closeBtnD10">
                  <IconButton aria-label="close" onClick={handleClose}>
                    <Tooltip title="Close" arrow>
                      <XLg />
                    </Tooltip>
                  </IconButton>
                </div>
              </div>
            </div>

            <div className="taskContain">
              <div className="taskContainDetail">
                <div className="taskContDtlContainer">
                  {selectedTasks &&
                    selectedTasks.length > 0 &&
                    selectedTasks.map((task, index) => {
                      return (
                        <>
                          <div className="multipleUpdateTaskCard">
                            <div className="taskTitleContrct">
                              <div className="taskHeadingNM">
                                {task.taskName}
                              </div>
                            </div>
                            <div className=" commonUpdat">
                              <div className="workUpdateBtnGrp ">
                                {numberList &&
                                  numberList.map((number) => (
                                    <Button
                                      className={`${
                                        updatePercentList[index] === number
                                          ? "activeStatus"
                                          : ""
                                      }`}
                                      variant="contained"
                                      disabled={
                                        number <= currentPercentList[index]
                                      }
                                      onClick={(e) => {
                                        const temp = [...updatePercentList];
                                        temp[index] = number;
                                        setUpdatePercentList(temp);
                                        console.log("temp value =", temp);
                                      }}
                                    >
                                      {number}
                                    </Button>
                                  ))}
                              </div>

                              <div className="tskCretdByElmnt">
                                <span className="assuTag">
                                  Created By:&nbsp;
                                </span>
                                <span className="assuName">
                                  {task && isAssignedByMe(task.createdByUserId)
                                    ? "Me"
                                    : task.createdByUserName}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="taskFooterPanel">
              <div className="formBtnElement">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={(e) => {
                    handleUpdateTasks();
                    handleClose();
                  }}
                >
                  Update
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={(e) => handleClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RightSideMultipleUpdate;
