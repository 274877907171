import React from "react";

export default function NoDataFoundCard() {
  return (
    <div className="noDataCard">
      <span>Oops!!</span>
      No data found !!
    </div>
  );
}
