import { Button, CircularProgress, IconButton, Slider } from "@mui/material";
import React, { useState, useEffect } from "react";
import { HddFill, XLg } from "react-bootstrap-icons";
import { jwtDecode } from "jwt-decode";
import {upgradeStorage} from "../../../../services/AdminService" ;
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function UpdateStorage({
  handleCloseUpdateOrgStorage,
  selectedUpdateStorage,
  refreshStorageData
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();

 
  const [loading, setLoading] = useState(false);

  console.log("selectedUpdateStorage", selectedUpdateStorage);

  const [addedStorage, setAddedStorage] = useState(null);

  function valuetext(value) {
    return `${value} GB`;
  }

  function valueLabelFormat(value) {
    setAddedStorage(value);
    return `${value} GB`;
  };

  const addUserStorage = () => {
    setLoading(true);

    if (addedStorage < 1) {
      toast.error(t("minimum_twenty_five_gb_is_needed_for_storage_upgrade"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    let reqDto = {
      storage: addedStorage,
      userId: selectedUpdateStorage.userData.userId,
    };
    upgradeStorage(
      reqDto,
      userDetails.userId,
      userDetails.orgId
    ).then((response) => {
      console.log("upgradeStorage", response.data);
      refreshStorageData();
      setLoading(false);
      if (response.data.message === "Storage Upgraded successfully!") {
        // toast.success(`${selectedUpdateStorage.userData.userName} Storage Upgraded successfully!`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // })
        toast.success(
          t("storage_upgraded_successfully", { userName: selectedUpdateStorage.userData.userName }),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );        
        // setAddedStorage(null);
        handleCloseUpdateOrgStorage();
      }
    });
  };




  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">
            {/* Add Org. Storage */}
            {selectedUpdateStorage.userData.userName}
            </div>
          <IconButton
            className="CloseBtn"
            onClick={() => handleCloseUpdateOrgStorage()}
          >
            <XLg />
          </IconButton>
        </div>

        <div className="elementFormContainer">
          <div className="curntStorCont">
            <div className="CurntStrData">
              <span className="curntStrgTxtlight">
              {t("current")}<br />
              {t("storage")}
              </span>
              <span className="curntStrgBold">
                {selectedUpdateStorage.userData.storage} GB
              </span>
            </div>

            <div className="CurntStrData">
              <span className="curntStrgTxtlight">
              {t("upgraded")}<br />
              {t("storage")}
              </span>
              <span className="curntStrgBold textWhie">
                {selectedUpdateStorage.userData.storage + addedStorage} GB
              </span>
            </div>

            <div className="curntDaImg">
              <span>
                <HddFill />
              </span>
              <span className="cuStText">Zoyel {t("drive")}</span>
            </div>
          </div>

          <div class="tskElemHeding mt10">{t("add_more_storage")}</div>
          <div className="formElement mt10">
            <Slider
              className="stroageSlider"
              // defaultValue={25}
              getAriaValueText={valuetext}
              defaultValue={1}
              step={1}
              min={1}
              max={
                parseFloat(selectedUpdateStorage.TotalStorageData) - parseFloat(selectedUpdateStorage.totalUsedStorage)
              }
              valueLabelFormat={valueLabelFormat}
              aria-label="Default"
              valueLabelDisplay="on"
            />
          </div>

          <div className="stroageTable">
            {/* <div className="strgItmResponsive">
              <table>
                <thead>
                  <tr>
                    <th className="taCenter">Item Name</th>
                    <th className="taRight">Unit Price ($)</th>
                    <th className="taRight">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="heit150 taCenter">
                        New Storage added &nbsp;<span> {addedStorage} GB</span>
                      </div>
                    </td>
                    <td>
                      <div className="heit150 taRight">
                        x <span>$0.025</span>
                      </div>
                    </td>
                    <td>
                      <div className="heit150 taRight">
                        <span>
                          $
                          {Math.round(
                            (0.025 * parseFloat(addedStorage) +
                              Number.EPSILON) *
                              100
                          ) / 100}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot className="crtStorgFoot">
                  <tr>
                    <td colSpan="2">
                      <div className="taRight">
                        <strong>Total</strong>
                      </div>
                    </td>
                    <td>
                      <div className="taRight">
                        <strong>
                          $
                          {Math.round(
                            (0.025 * parseFloat(addedStorage) +
                              Number.EPSILON) *
                              100
                          ) / 100}
                        </strong>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div> */}
          </div>
          {/* end stroage Table end */}
        </div>

        <div className="elementFooter">
          <div className="formBtnElement">
            {/* <Button className="dfultPrimaryBtn" onClick={()=>addUserStorage()}>Upgrade Now</Button> */}
            <Button
              className="dfultPrimaryBtn"
              onClick={()=>addUserStorage()}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("upgrade_now")}</>
              )}
            </Button>
            {!loading && (
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handleCloseUpdateOrgStorage()}
            >
              {t("cancel")}
            </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
