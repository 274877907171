import React, { useEffect, useState } from "react";
import "../../css/selectTemplate.css";
import { Badge, Box, Button, Grid, IconButton, Tooltip } from "@mui/material";
import {
  Check,
  CheckCircle,
  Edit,
  Search,
  Tune,
  Visibility,
} from "@mui/icons-material";
import WebsiteTemplateService from "../../../../services/WebsiteTemplateService";
import { getOrgDetailsByOrgId } from "../../../../services/AdminService";
import { getLoginUserDetails } from "../../../../utils/Utils";
import { toast } from "react-toastify";
import { Pen } from "react-bootstrap-icons";
import { useConfirm } from "material-ui-confirm";
import AppUtils from "../../../../utils/AppUtils";
import { useTranslation } from "react-i18next";

export default function SelectTemplate({ onboardingData, admin }) {
  const { t } = useTranslation();
  const [templates, selectTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [loading, setLoading] = useState(false);
  const loginUserDetail = getLoginUserDetails();
  const confirm = useConfirm();
  useEffect(() => {
    WebsiteTemplateService.getAllWebsiteTemplates().then((response) => {
      console.log(response);
      selectTemplates(response.data);
    });

    getOrgDetailsByOrgId(onboardingData.orgId).then((response) => {
      console.log(response.data);
      setSelectedTemplateId(response.data.templateId);
    });
  }, []);

  const changeWebsiteTemplate = (templateDto) => {
    const id = toast.loading(
      t("changing_website_template_please_wait_and_do_not_close_the_page")
    );
    setLoading(true);
    let reqDtoSubmit = {
      templateName: templateDto.templateName,
      userId: loginUserDetail.userId,
      orgId: onboardingData.orgId,
      templateId: templateDto.templateId,
      subdomain: onboardingData.subdomain,
    };
    console.log(reqDtoSubmit);

    // setLoading(false);
    // return;
    WebsiteTemplateService.changeWebsiteTemplate(reqDtoSubmit).then(
      (response) => {
        console.log(response.data);
        if (response.data.returnValue === "1") {
          // toast.info(response.data.message);
          toast.update(id, {
            render: t("website_template_change_successfully"),
            type: "success",
            isLoading: false,
            autoClose: true,
          });
          setLoading(false);
          setSelectedTemplateId(templateDto.templateId);
        } else {
          // toast.error(response.data.message);
          toast.update(id, {
            render: t("fail_to_change_website_template"),
            type: "error",
            isLoading: false,
            autoClose: true,
          });
          setLoading(false);
        }
      }
    );
  };

  return (
    <>
      <Box
        className="selectTemplateContainerArea"
        sx={{ bgcolor: "dark.pageBg" }}
      >
        <Grid container spacing={0} className="selectTemplateContainArea">
          <Grid
            item
            xs={12}
            sx={{
              color: "dark.contrastText",
              // maxHeight: "400px !important",
              // overflowY: "auto !important",
            }}
          >
            <div className="selectTemplateContainer">
              <div className="selectTemplateHeadArea">
                <div className="stHedText">{t("select_template")}</div>
                <div className="stSubText">
                  {/* {!admin
                    ? "You can select a template that suits your communication style best, this can be modified later via your Admin dashboard"
                    : "Select to change your website template, once changed all website data will be lost"} */}
                    {admin
        ? t("template_message_for_admin") 
        : t("template_message_for_non_admin")}
                </div>
              </div>
              {/* <div className="selecttempFilterSearch">
                <div className="filterIcon">
                  <IconButton>
                    <Tune />
                  </IconButton>
                </div>
                <div className="searchIconWithBtn">
                  <input type="text" placeholder="Search Themes" />
                  <IconButton>
                    <Search />
                  </IconButton>
                </div>
              </div> */}
              <div className="selectTemplateDisplay">
                <div className="indTemplateCardGroup">
                  {templates.map((templateDto) => {
                    return (
                      <>
                        <div className="indTemplateCard">
                          <div className="templateImage">
                            {!AppUtils.isPersonal(loginUserDetail) &&
                              templateDto.templateId === selectedTemplateId && (
                                <Tooltip title={t("edit_website")}>
                                  <IconButton className="editWebsiteSelTemp">
                                    <Edit
                                      sx={{
                                        cursor: "pointer",
                                        color: "white !important",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        let url =
                                          window.location.protocol +
                                          "//" +
                                          window.location.host +
                                          "/builder";

                                        window.open(url, "_blank");
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            <img src={templateDto.templateDesktopView} alt="" />
                          </div>
                          {/* <div className="templateNMTg">
                            <div className="templateName">Purple Matrix</div>
                            <div className="templateTags">
                              <span className="temptags">Clean</span>
                              <span className="temptags">Corporate</span>
                              <span className="temptags">Purple</span>
                              <span className="temptags">Responsive</span>
                            </div>
                          </div> */}
                          <div className="templateActBtnGrp">
                            {templateDto.templateId === selectedTemplateId ? (
                              <Badge
                                badgeContent={
                                  <Check
                                    sx={{
                                      color: "white",
                                      backgroundColor: "#ff9900 !important",
                                      borderRadius: "20px",
                                      padding: "3px",
                                      fontSize: "15px",
                                    }}
                                  />
                                }
                              >
                                <Button className="tempActBtn" disabled>
                                  {t("selected")}
                                </Button>
                              </Badge>
                            ) : (
                              <Button
                                className="tempActBtn"
                                onClick={() => {
                                  if (admin) {
                                    confirm({
                                      description:
                                      t("confirm_website_change"),
                                    })
                                      .then(() => {
                                        changeWebsiteTemplate(templateDto);
                                      })
                                      .catch(() => {
                                        console.log("website change cancelled");
                                      });
                                  } else {
                                    changeWebsiteTemplate(templateDto);
                                  }
                                }}
                                disabled={loading}
                                style={{ cursor: "pointer" }}
                              >
                                {t("select_button")}
                              </Button>
                            )}

                            <Button
                              className="tempActBtnOutLine"
                              startIcon={<Visibility />}
                              onClick={() => {
                                window.open(templateDto.demoUrl, "_blank");
                              }}
                            >
                              {t("demo")}
                            </Button>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
