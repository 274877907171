import {
  ArrowDropDownOutlined,
  Bookmark,
  BookmarkBorder,
  Close,
  ReplyAllOutlined,
  ReplyOutlined,
  ShortcutOutlined,
  StarBorder,
  StarRate,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  IconButton,
  Tooltip,
  styled,
  tooltipClasses,
} from "@mui/material";
import {
  ArrowEmailForward,
  ArrowLeft,
  MoreHoriz,
  MoreVert,
  PrintingPage,
  Reply,
} from "iconoir-react";
import React, { useEffect, useRef, useState } from "react";
import EmailAttachFiles from "./EmailAttachFiles";
import EmailMultipleThreads from "./EmailMultipleThreads";
import EmailReply from "./EmailReply";
import { useDispatch, useSelector } from "react-redux";
import userImage from "../assets/image/userimage.png";
import EmailUtil from "./EmailUtil";
import { modifyMsgLabels } from "../../../redux/reducers/mailSlice";
import { XLg } from "react-bootstrap-icons";
import CoustomTooltip from "../../../common/CustomTooltip";
export default function EmailsView({ close, mode }) {
  const label = { inputProps: { "aria-label": "Starred" } };
  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : true);
  };
  const [showEmlThred, setShowEmlThred] = useState(false);
  const toggleEmlThred = () => {
    setShowEmlThred(!showEmlThred);
  };

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedThreadId, setSelectedThreadId] = useState(null);
  const [selectedReceiverId, setSelectedReceiverId] = useState(null);
  const [selectedMailId, setSelectedMessageId] = useState(null);
  const [htmlMsg, setHtmlMsg] = useState("");
  const [textMsg, setTextMsg] = useState("");

  const [selectedCcReceiver, setSelectedCcReceiver] = useState([]);
  const modeRef = useRef("");

  const threadMessages = useSelector((state) => state.mail.threadMessages);
  const dispatch = useDispatch();
  const threadSubject = threadMessages[0] ? threadMessages[0].subject : "";

  const handleReplySingle = (e, message) => {
    setSelectedUserId("me");
    modeRef.current = "reply";

    let receiveremail = [];
    receiveremail = [extractEmail(message.sender)];

    const myEmailAddress = localStorage.getItem("current_mailId");
    if (receiveremail[0] === myEmailAddress) {
      receiveremail = [];
      message.receivers.map((mail) => {
        const email = extractEmail(mail);
        if (email) {
          receiveremail = [...receiveremail, email];
        }
      });
    }

    console.log("receiver email address= ", receiveremail);

    setSelectedReceiverId(receiveremail);
    setSelectedMessageId(message.messageId);
    setSelectedThreadId(message.threadId);
    handleMlrplyOpnCls(e);
  };

  const handleReplyMultiple = (e, message) => {
    setSelectedUserId("me");
    modeRef.current = "reply";

    const myEmailAddress = localStorage.getItem("current_mailId");
    let receiveremail = [];
    const sender = message.sender;
    receiveremail = [extractEmail(sender)];
    let ccRecipient = [];

    const { userName, email } =
      EmailUtil.extractNameAndEmailFromNameEmail(sender);
    if (email === myEmailAddress) {
      receiveremail = [];
      message.receivers.forEach((mail) => {
        const email = extractEmail(mail);
        if (email) {
          receiveremail = [...receiveremail, email];
        }
      });
      message.cc.forEach((mail) => {
        const email = extractEmail(mail);
        if (email) {
          ccRecipient = [...ccRecipient, email];
        }
      });

      console.log("recipient email addresses are = ", receiveremail);
    } else {
      receiveremail = [];
      receiveremail = [...receiveremail, email];
      const receiverArray = message.receivers[0].split(",");
      const ccArray =
        message.cc && message.cc.length > 0 && message.cc[0].split(",");
      receiverArray.forEach((mail) => {
        const emailId = extractEmail(mail);
        if (emailId !== myEmailAddress) {
          ccRecipient = [...ccRecipient, emailId];
        }
      });
      ccArray &&
        ccArray.forEach((mail) => {
          const emailId = extractEmail(mail);
          if (emailId !== myEmailAddress) {
            ccRecipient = [...ccRecipient, emailId];
          }
        });
    }
    setSelectedCcReceiver(ccRecipient);

    console.log("receiver email address= ", receiveremail);

    setSelectedReceiverId(receiveremail);
    setSelectedMessageId(message.messageId);
    setSelectedThreadId(message.threadId);
    handleMlrplyOpnCls(e);
  };

  const handleForwardButtonClick = (event, index) => {
    modeRef.current = "forward";
    setSelectedUserId("me");
    console.log("forward button is clicked");
    const threadMsg = threadMessages[index];
    const sender = threadMsg.sender;
    const { userName, email } =
      EmailUtil.extractNameAndEmailFromNameEmail(sender);
    const currentMailId = localStorage.getItem("current_mailId");
    let recipient = [];
    let ccRecipient = [];
    if (email === currentMailId) {
      recipient = threadMsg.receivers;
      ccRecipient = threadMsg.cc;
    }
    recipient = threadMsg.receivers;
    ccRecipient = threadMsg.cc;
    const toRecipient = recipient.join(",");
    ccRecipient = ccRecipient.join(",");

    const body = threadMsg.body;
    const parser = new DOMParser();
    const doc = parser.parseFromString(body, "text/html");
    const lastMessage = doc.querySelector("body").innerHTML;

    const dateString = threadMsg.dateString;
    // const ToString=
    const forwardStr = `<div class="gmail_quote">
      <div dir="ltr" class="gmail_attr">---------- Forwarded message ---------
        <br>From: ${
          userName
            ? `<strong class="gmail_sendername" dir="auto">${userName}</strong>`
            : ""
        }<span dir="auto">&lt;${email}&gt;
        </span><br>Date: ${EmailUtil.formatDate(dateString)}
        <br>Subject: ${threadMsg.subject}
        <br>To: ${toRecipient}
        <br>Cc: ${ccRecipient}
        <br>
      </div>
      <br>
      <br>
      ${lastMessage}
      ${threadMsg.history !== null ? threadMsg.history : ""}
    </div>`;

    const forwardTextString = `
    ---------- Forwarded message ---------
    From: ${userName ? userName : ""}<${email}>
    Date: ${EmailUtil.formatDate(dateString)}
    Subject: ${threadMsg.subject}
    To: ${toRecipient}
    Cc: ${ccRecipient}
    ${lastMessage}
    `;
    setHtmlMsg(forwardStr);
    setTextMsg(forwardTextString);
    console.log("forward string = ", forwardTextString);
    setSelectedReceiverId([]);
    setSelectedMessageId(threadMsg.messageId);
    setSelectedThreadId(threadMsg.threadId);
    handleMlrplyOpnCls(event);
  };

  const replyToMessage = async (threadId, originalMessageId, replyBody) => {
    try {
      const replyMessage = {
        threadId: threadId,
        labelIds: ["INBOX"],
        raw: btoa(
          `From: your-email@gmail.com\r\nIn-Reply-To: <${originalMessageId}>\r\n\r\n${replyBody}`
        ),
      };

      // Send the reply using gapi.client.gmail.users.messages.send
      const response = await window.gapi.client.gmail.users.messages.send({
        userId: "me",
        resource: replyMessage,
      });

      console.log("Reply sent:", response);
    } catch (error) {
      console.error("Error replying to message:", error);
    }
  };

  const extractEmail = (mailUserNameString) => {
    console.log("email value =", mailUserNameString);
    if (mailUserNameString.trim() === "") {
      console.log("empty string ");
      return null;
    }
    let emailId = null;
    const emailRegex = /<([^>]+)>/;

    let pattern = /(?:([^<]+)\s*<)?([^>]+)>?/;

    // Use the exec method to find the first match in the input string
    let match = pattern.exec(mailUserNameString);

    // Extract username and email from the match
    let username = match && match[1] ? match[1].trim() : null;
    let email = match && match[2] ? match[2].trim() : null;

    // console.log("email= ", email);
    // console.log("userName =  ", username);

    // const matches = mailUserNameString.match(emailRegex);

    // if (matches && matches.length >= 2) {
    //   emailId = matches[1];
    // } else {
    //   console.log("not a valid email -user string");
    // }
    return email;
  };

  const getFormattedTime = (timeString) => {
    const parsedDate = new Date(timeString);

    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZoneName: "short",
      // timeZone: "Asia/Kolkata",
    }).format(parsedDate);
    return formattedDate;
  };

  // const threadId = 'your_thread_id_here';
  // const originalMessageId = 'original_message_id_here';
  // const replyBody = 'Your reply content here.';

  // replyToMessage(threadId, originalMessageId, replyBody);

  useEffect(() => {
    console.log("starting messages");
    if (threadMessages && threadMessages.length > 0) {
      setExpanded(`panel${threadMessages.length - 1}`);
    }
  }, [threadMessages]);

  const [isMlrplybtnGrpVisible, setMlrplybtnGrpVisible] = useState(true);
  const [isMlrlpyCntnrVisible, setMlrlpyCntnrVisible] = useState(false);

  const handleMlrplyOpnCls = () => {
    setMlrplybtnGrpVisible(false);
    setMlrlpyCntnrVisible(true);
  };

  // const getPersonImag=(emailId)=>{

  // }
  const getSenderName = (senderString) => {
    if (!senderString) {
      return "";
    }
    const senderInfoArray = senderString.split("<");
    const senderName = senderInfoArray[0];
    if (senderName.trim() === "") {
      console.log("in senderAddress");
      const senderEmail = senderInfoArray[1].split(">");
      return senderEmail[0];
    }
    return senderName;
  };

  return (
    <>
      <div className="emailViewContainer">
        <div className="viewEmailHeader">
          {threadMessages && threadMessages.length > 0 && (
            <>
              <div className="backToEmailList">
                <div className="actionBack">
                  {mode !== "right" && (
                    <IconButton
                      aria-label="Back"
                      size="small"
                      onClick={() => {
                        close();
                      }}
                    >
                      <ArrowLeft fontSize="inherit" />
                    </IconButton>
                  )}
                  {console.log("mode = ", mode)}
                  {mode === "right" && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        close();
                      }}
                    >
                      <XLg fontSize="inherit" />
                    </IconButton>
                  )}
                </div>
              </div>

              <div className="emailTitle">
                <div className="emailSubject">{threadMessages[0].subject}</div>
                <div className="emailmarker">
                  <Checkbox
                    size="small"
                    {...label}
                    icon={<BookmarkBorder />}
                    checkedIcon={<Bookmark />}
                  />
                </div>
              </div>
              <div className="emailAction">
                {/* <div className="actionForward">
                  <IconButton aria-label="Forward All" size="small">
                    <ArrowEmailForward fontSize="inherit" />
                  </IconButton>
                </div>
                <div className="actionPrint">
                  <IconButton aria-label="Print All" size="small">
                    <PrintingPage fontSize="inherit" />
                  </IconButton>
                </div> */}
              </div>
            </>
          )}
        </div>

        <div className="emailViewArea">
          {threadMessages &&
            threadMessages.length > 0 &&
            threadMessages.map((message, index) => {
              return (
                <Accordion
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                  className="showMlDetlCntnr"
                >
                  <AccordionSummary className="showMlSummry">
                    <div className="mlBsInfoArea">
                      <div className="mlbiImg">
                        <div className="mlbiimgPosition">
                          <img
                            src={userImage}
                            className="senderImg"
                            alt="sender img"
                          />
                        </div>
                      </div>
                      <div className="mlbiSenderInfo">
                        <div className="mlbistr">
                          <div className="sndml sndnm">
                            <span className="nm">
                              {getSenderName(message.sender)}
                            </span>
                          </div>
                          <div className="sndtd ">
                            <div className="snddt">
                              <span className="dt">
                                {getFormattedTime(message.dateString)}
                              </span>
                            </div>
                            <div className="sndact">
                              <span
                                className="stared"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  console.log("star clicked");
                                }}
                              >
                                <Checkbox
                                  {...label}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onChange={(e) => {
                                    let labelsToInclude = [];
                                    let labelsToExclude = [];
                                    if (message.lableIds.includes("STARRED")) {
                                      labelsToExclude = ["STARRED"];
                                    } else {
                                      labelsToInclude = ["STARRED"];
                                    }
                                    dispatch(
                                      modifyMsgLabels({
                                        messageId: message.id,
                                        labelsToAdd: labelsToInclude,
                                        labelsToRemove: labelsToExclude,
                                        hideMessage: false,
                                      })
                                    );
                                  }}
                                  icon={<StarBorder fontSize="small" />}
                                  checkedIcon={<StarRate fontSize="small" />}
                                  className="staredCheck"
                                  checked={message.lableIds.includes("STARRED")}
                                />
                              </span>

                              <span
                                className="replay"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  console.log("reply button clicked");
                                  setHtmlMsg("");
                                  handleReplySingle(e, message);
                                }}
                              >
                                <IconButton
                                  aria-label="Replay All"
                                  size="small"
                                >
                                  <Reply fontSize="small" />
                                </IconButton>
                              </span>
                              <span className="more">
                                <IconButton aria-label="more" size="small">
                                  <MoreVert fontSize="small" />
                                </IconButton>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="mlrecIdlst">
                          <span>to</span>
                          {message.receivers.length > 1 ? (
                            <span>{`${extractEmail(message.receivers[0])} and ${
                              message.receivers.length - 1
                            } other`}</span>
                          ) : (
                            <span>{`${extractEmail(
                              message.receivers[0]
                            )} `}</span>
                          )}

                          <span>
                            <CoustomTooltip
                              title={
                                <>
                                  <p>
                                    <b>From:</b>
                                    {`${message.sender}`}
                                    <br />
                                  </p>
                                  <p>
                                    <b>To:</b>
                                    {`${message.receivers.join(",")}`}
                                    <br />
                                  </p>
                                  {message.cc && message.cc.length > 0 && (
                                    <p>
                                      <b>Cc:</b>
                                      {`${message.cc.join(",")}`}
                                      <br />
                                    </p>
                                  )}
                                </>
                              }
                            >
                              <IconButton aria-label="more" size="small">
                                <ArrowDropDownOutlined fontSize="inherit" />
                              </IconButton>
                            </CoustomTooltip>
                          </span>
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="mlinsdDtlwa">
                      <div
                        className="mailBodyText"
                        dangerouslySetInnerHTML={{ __html: message.body }}
                      ></div>
                      {/* when multiple replay are present  start*/}

                      {message.history && (
                        <div className="mlshmlt">
                          <IconButton
                            aria-label="view More"
                            size="small"
                            onClick={toggleEmlThred}
                          >
                            <MoreHoriz fontSize="small" />
                          </IconButton>
                        </div>
                      )}

                      {showEmlThred && (
                        <div
                          className="mlmltThread"
                          dangerouslySetInnerHTML={{ __html: message.history }}
                        >
                          {/* <EmailMultipleThreads /> */}
                        </div>
                      )}

                      {/* when multiple replay are present  start*/}

                      {message.attachmentList &&
                        message.attachmentList.length > 0 && (
                          <div className="mailAttatch">
                            <div className="mlatchHead">{`${
                              message.attachmentList.length
                            } ${
                              message.attachmentList.length == 1
                                ? "Attachment"
                                : "Attachments"
                            }`}</div>
                            <div className="mlatchFiles">
                              {message.attachmentList &&
                                message.attachmentList.length > 0 &&
                                message.attachmentList.map((attachment) => (
                                  <EmailAttachFiles
                                    messageId={message.messageId}
                                    name={attachment.attachmentName}
                                    attachmentId={attachment.attachmentId}
                                    mimeType={attachment.mimeType}
                                  />
                                ))}
                            </div>
                          </div>
                        )}
                      {isMlrplybtnGrpVisible && (
                        <div className="emailReplayArea">
                          <Button
                            variant="outlined"
                            startIcon={<ReplyOutlined />}
                            className="emlrlpyBtn"
                            onClick={(e) => {
                              setHtmlMsg("");
                              handleReplySingle(e, message);
                            }}
                          >
                            Reply
                          </Button>

                          <Button
                            variant="outlined"
                            startIcon={<ReplyAllOutlined />}
                            className="emlrlpyBtn"
                            onClick={(e) => {
                              handleReplyMultiple(e, message);
                            }}
                          >
                            Reply to all
                          </Button>
                          <Button
                            variant="outlined"
                            startIcon={<ShortcutOutlined />}
                            className="emlrlpyBtn"
                            onClick={(e) => {
                              handleForwardButtonClick(e, index);
                            }}
                          >
                            Forward
                          </Button>
                        </div>
                      )}
                    </div>
                  </AccordionDetails>

                  {isMlrlpyCntnrVisible && (
                    <EmailReply
                      closeReplayMail={() => {
                        setMlrlpyCntnrVisible(false);
                        setMlrplybtnGrpVisible(true);
                      }}
                      originalMailId={selectedMailId}
                      threadId={selectedThreadId}
                      replyTo={selectedReceiverId}
                      ccTo={selectedCcReceiver}
                      userId={selectedUserId}
                      message={message}
                      subject={threadSubject}
                      htmlMsg={htmlMsg}
                      textMsg={textMsg}
                      mode={modeRef.current}
                    />
                  )}
                </Accordion>
              );
            })}
        </div>
      </div>
    </>
  );
}
