import React, { useEffect } from "react";
import { ViewCompoundItem } from "./ViewCompoundItem";

export const ViewCompound = (compoundList) => {
  useEffect(() => {
    console.log("CompoundList=", compoundList);
  }, [compoundList]);
  return (
    <div className="CompoundDtlgrp">
      <ul className="compoundGrpUl">
        {compoundList &&
          compoundList?.compoundList?.map((citm) => {
            return (
              <li className="compoundGrpList">
                <ViewCompoundItem compoundItem={citm} compoundType={""} />
              </li>
            );
          })}
      </ul>
    </div>
  );
};
