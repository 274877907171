import React, { useRef, useState } from "react";
import axios from "axios";
import useFileUpload from "react-use-file-upload";
import { XLg } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { apiEndPoint } from "../../../../../../constants/url_provider";
import {
  Autocomplete,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  getAllFilesUploaded,
  downloadFile,
} from "../../../../../../services/VideoConferenceService";
import {
  CHAT_DOCUMENT_EXTENSION,
  CHAT_PHOTO_EXTENSION,
  CHAT_VIDEO_EXTENSION,
  VALID_FILE_EXTENSION_MEETING,
} from "../../../../../../constants/const_values";
import { Info } from "@mui/icons-material";
import { meetingSocket } from "../../../../../../socket";
import { getLoginUserDetails } from "../../../../../../utils/Utils";
import { useTranslation } from "react-i18next";

const MAX_FILE_SIZE_MB = 5;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

const FileShare = ({ handleMenuCloseButton, jitsiObject, roomNumber }) => {
  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();

  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const loginUser = getLoginUserDetails();
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (fileNames.length === 0) {
      toast.error(t("please_select_a_file_to_upload"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (totalSizeInBytes > MAX_FILE_SIZE_BYTES) {
      toast.error(t("file_size_exceeds_the_limit"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let participantsEmailIds = [];
    try {
      let participantsInfo = jitsiObject.getParticipantsInfo();
      participantsEmailIds = participantsInfo.map(({ email }) => email);
    } catch (err) {
      console.log(err);
    }

    console.log("participantsEmailIds", participantsEmailIds);

    const formData = createFormData();
    formData.append("roomId", roomNumber);
    setLoading(true);
    try {
      const response = await axios.post(
        apiEndPoint + "/meeting/filesUpload",
        formData,
        {
          "content-type": "multipart/form-data",
        }
      );
      console.log(response);
      getAllFilesUploaded(roomNumber).then((response) => {
        console.log(response);
        setFileList(response.data);
        clearAllFiles();
        toast.success(t("files_uploaded_successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        meetingSocket.emit("onFileShare", {
          userName: loginUser.userName,
          roomNumber: roomNumber,
        });
      });
    } catch (error) {
      console.error("Failed to submit files.", error);
      setLoading(false);
      setLoading(false);
    }
  };

  const [fileList, setFileList] = React.useState([]);
  const [uploading, setUploading] = React.useState(false);

  React.useEffect(() => {
    getAllFilesUploaded(roomNumber).then((response) => {
      console.log(response);
      setFileList(response.data);
    });
    meetingSocket.on("onFileShareRec", ({ userName }) => {
      console.log("onFileShareRec", userName);
      // toast.info(`${userName} has uploaded a new file, please check...`);
      getAllFilesUploaded(roomNumber).then((response) => {
        console.log(response);
        setFileList(response.data);
      });
    });

    // return () => {
    //   meetingSocket.off('onFileShareRec');
    // };
  }, []);

  const handleFileChange = (e) => {
    console.log("handleFileChange", e.target.files);
    const { files } = e.target || {}; // Null check added here

    if (!files) {
      return; // Exit early if files is undefined
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileSize = file.size;
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      if (fileSize > MAX_FILE_SIZE_BYTES) {
        // toast.error(`File '${fileName}' exceeds the size limit (5MB)`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        toast.error(t('fileSizeError', { fileName }), {
          position: toast.POSITION.TOP_RIGHT,
        });
        continue;
      }

      if (
        !CHAT_DOCUMENT_EXTENSION.includes(fileExtension) &&
        !CHAT_PHOTO_EXTENSION.includes(fileExtension) &&
        !CHAT_VIDEO_EXTENSION.includes(fileExtension)
      ) {
        // toast.error(`File '${fileName}' has an invalid extension`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        toast.error(t('file_invalid_extension', { fileName }), {
          position: toast.POSITION.TOP_RIGHT,
        });
        continue;
      }

      setFiles(e, "a");
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleDragDropEvent(e);

    const droppedFiles = e.dataTransfer.files;

    for (let i = 0; i < droppedFiles.length; i++) {
      const file = droppedFiles[i];
      const fileSize = file.size;
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      if (fileSize > MAX_FILE_SIZE_BYTES) {
        // toast.error(`File '${fileName}' exceeds the size limit (5MB)`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
  
        toast.error(t('fileSizeError', { fileName }), {
          position: toast.POSITION.TOP_RIGHT,
        });
        continue;
      }

      if (!VALID_FILE_EXTENSION_MEETING.includes(fileExtension)) {
        // toast.error(`File '${fileName}' has an invalid extension`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        toast.error(t('file_invalid_extension', { fileName }), {
          position: toast.POSITION.TOP_RIGHT,
        });
        continue;
      }

      setFiles(e, "a");
    }
  };

  // const handleDrop = (e) => {
  //   console.log("handleDrop" , e);
  //   e.preventDefault();
  //   console.log("File dropped!");
  //   handleDragDropEvent(e);
  //   handleFileChange(e);
  // };

  const handleDownloadFile = (fileName, folderName, fileType) => {
    toast.info(t("downloading_file_please_wait"), {
      position: toast.POSITION.TOP_RIGHT,
    });
    window.open(
      apiEndPoint + `/appTest/downloadFileTest/${fileName}/${folderName}`,
      "_blank",
      "noopener,noreferrer"
    );
    // downloadFile(fileName, folderName).then((response) => {
    //   console.log("file Upload", response.data);
    //   window.open(
    //     apiEndPoint + `/appTest/downloadFileTest/${fileName}/${folderName}`,
    //     "_blank",
    //     "noopener,noreferrer"
    //   );
    // });
  };

  return (
    <>
      <div className="meetRightHeader">
        <div className="metRtTitle"> {t("file_share")} </div>
        <div className="metRtAction">
          <IconButton
            aria-label="Close"
            onClick={() => handleMenuCloseButton()}
          >
            <XLg />
          </IconButton>
        </div>
      </div>

      {/* Heading */}

      <div className="MeetingTranscriptionContainer">
        <div className="Meeting_Transcription">
          <div className="transcripHeader"></div>
          <div className="transcriptionContainArea">
            <div
              onDragEnter={handleDragDropEvent}
              onDragOver={handleDragDropEvent}
              onDragLeave={handleDragDropEvent}
              onDrop={handleDrop}
              style={{
                border: "1px solid #d1d1d1",
                borderRadius: "12px",
                padding: "10px",
                position: "relative",
                width: "calc(100% - 22px)",
                height: "auto",
              }}
            >
              <div className="meetingfileUpload">
                <h1
                  style={{
                    margin: "0px",
                    fontSize: "14px",
                    textAlign: "center",
                    color: "#545454",
                  }}
                >
                  {t("upload_files")}
                </h1>
                <div className="drivInfo">
                  <Tooltip
                    title={
                      <>
                        <b>
                          <em>{t("information")}</em>
                        </b>
                        <ul className="htmlTooltipUl">
                          <li>
                            <em>
                              <strong>{t("max_file_size")} :</strong>
                            </em>{" "}
                            5 MB
                          </li>
                          <li>
                            <em>
                              <strong>{t("allowed_file_types")}:</strong>
                            </em>{" "}
                            .svg , .tif , .tiff , .bmp , .ico , .doc , .docx ,
                            .ppt , .pptx , .txt , .pdf , .xls , .xlsx, .csv ,
                            .zip , .rar , .tar , .gzip , .pps , .ppsx , .png,
                            .jpeg, .jpg, .gif, .mp4, .mkv
                          </li>
                        </ul>
                      </>
                    }
                  >
                    <IconButton className="drivInfoBtn">
                      <Info />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <div className="form-container">
                <div>
                  <ul>
                    {fileNames.map((name) => (
                      <li key={name}>
                        <span>{name}</span>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => removeFile(name)}
                        >
                          &nbsp;&nbsp;
                          <font style={{ color: "red" }}>&#x2715;</font>
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <p
                    style={{
                      display: "block",
                      position: "relative",
                      opacity: "0.6",
                      margin: "10px",
                      textAlign: "center",
                      fontStyle: "italic",
                    }}
                  >
                    {t("drag_and_drop_files_here")}
                  </p>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => inputRef.current.click()}
                    sx={{ marginBottom: "10px" }}
                    style={{
                      backgroundColor: "#979797",
                      borderRadius: "20px",
                      width: "100%",
                    }}
                  >
                    {t("select_files_to_upload")}
                  </Button>
                  <input
                    ref={inputRef}
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>
          </div>
          {fileList && fileList.length > 0 && (
            <div className="elementListGrp" style={{ marginBottom: "100px" }}>
              <div className="elementHeading" style={{ color: "White" }}>
              {t("files_added")}
              </div>
              <ul className="elmListInfo">
                {fileList.map((file, index) => (
                  <li key={file.field1}>
                    <span
                      style={{
                        cursor: "pointer",
                        color: "White",
                        textDecoration: "underline",
                      }}
                      onClick={() =>
                        handleDownloadFile(
                          file.field1,
                          file.field3,
                          file.field4
                        )
                      }
                    >
                      {file.field1}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* body */}

      <div className="MeetRightFooter">
        <div className="meetRightFootBtnGrp">
          {!loading && (
            <Button className="downloadTranscriptionBtn" onClick={handleSubmit}>
              {t("submit")}
            </Button>
          )}
          {loading && (
            <Button className="dfultPrimaryBtn shareFileMeetingLoader">
              <CircularProgress />
            </Button>
          )}
          <Button
            className="cancleTransBtn"
            variant="outlined"
            onClick={() => handleMenuCloseButton()}
          >
            {t("close")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default FileShare;
