import { AssignmentLateOutlined, ListAlt } from "@mui/icons-material";
import { IconButton, Popover, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Play, Send, XLg } from "react-bootstrap-icons";
import DashboardServices from "../../../../services/DashboardServices";
import TemplateList from "./TemplateList";
import FileList from "./FileList";
import {
  clearSelectedTask,
  setSelectedTask,
} from "../../../../redux/reducers/rightSideTaskPanelSlice";
import isAssignedByMe from "../../taskUtil";
import { getFormattedDate, getLoginUserDetails } from "../../../../utils/Utils";
import { TaskMainButtons } from "./TaskComponent/TaskMainButtons";
import { useDispatch } from "react-redux";
import { TaskTimeProgress } from "./TaskComponent/TaskTimeProgress";
import { TaskStatusProgress } from "./TaskComponent/TaskStatusProgress";
import { toast } from "react-toastify";
import { TaskSubButtons } from "./TaskComponent/TaskSubButtons";
import TaskService from "../../../../services/TaskService";
import { TaskAudio } from "./TaskComponent/TaskAudio";
import { AudioModal } from "./Modals/AudioModal";
import PmsApprovalForm from "./Forms/PmsApprovalForm";
import { PurchaseFormRenderer } from "./Forms/PurchaseFormRenderer";
import { IncomingFormPreview } from "./Forms/IncomingFormPreview";
import RightTaskFileUpload from "./RightTaskFileUpload";
import RightFlotingContainer from "./RightFlotingContainer";

export const TaskRenderer = ({
  data,
  assigneeList,
  refreshData,
  removeFromStore,
}) => {
  const dispatch = useDispatch();
  const loginUserDetail = getLoginUserDetails();
  const audioUrl = useRef();
  const scrollRef = useRef();
  const fileDocuments = useRef(null);

  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [recommendedTemplateList, setRecommendedTemplateList] = useState([]);
  const [addedTemplateList, setAddedTemplateList] = useState([]);
  const [tempOfOrgList, setTemplateOfOrgList] = useState([]);
  const [showConcernModal, setShowConcernModal] = useState(false);

  const [showFileModal, setShowFileUploadModal] = useState(false);
  const commentInputRef = useRef();
  const [formData, setFormData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showAudioModal, setShowAudioModal] = useState(false);

  const sendComment = () => {
    if (!data) {
      toast.error("something went wrong ");
      return;
    }
    const comment = commentInputRef.current.value;
    const reqDto = {
      taskDetailId: data.taskId,
      progress: data.progress,
      comments: comment,
      referenceId: 0,
      commentsType: null,
    };
    console.log("reqDto = ", reqDto);
    const commentsAt = new Date().toISOString();
    // return;
    if (comment.trim() !== "") {
      DashboardServices.sendTaskComments(loginUserDetail.userId, reqDto).then(
        (response) => {
          console.log("out response data= ", response);
          if (response.data == 1) {
            const tempData = { ...data };
            tempData.comments = [
              ...tempData.comments,
              {
                comments: comment,
                commentsType: null,
                commentsByUserName: loginUserDetail.userName,
                commentsByUserProfileUrl: loginUserDetail.userProfileImage,
                progress: data.progress,
                commentsAt: commentsAt,
              },
            ];

            dispatch(setSelectedTask(tempData));
            commentInputRef.current.value = "";
            const timeoutValue = setTimeout(() => {
              scrollRef.current.scrollIntoView({ behavior: "smooth" });
              clearTimeout(timeoutValue);
            }, 500);
          } else {
            toast.error("something went wrong");
          }
        }
      );
    }
  };

  const handleClickFormButton = () => {
    if (data.taskNature === "PMS_APPROVAL_REQUEST") {
      setShowForm(true);
      return;
    }

    if (data.taskNature === "INTERVIEW_FEEDBACK") {
      TaskService.getProspectiveEmployeeById(data.formDataTransId).then(
        (response) => {
          if (response.data) {
            setFormData(response.data);
            setShowForm(true);
          }
        }
      );
    } else {
      TaskService.getExpenseApprovalRequest(data.formDataTransId).then(
        (response) => {
          if (response.data) {
            setFormData(response.data);
            setShowForm(true);
          }
        }
      );
    }
  };

  const handleChangeFile = async (fileList) => {
    const reqDto = fileList.map((file) => ({
      id: 0,
      taskDtlId: data.taskId,
      fileName: file.fileName,
      fileDataLink: file.fileDataLink,
      uploadedBy: loginUserDetail.userId,
      fileDisplayName: file.fileName,
    }));

    console.log("reqDto = ", reqDto);

    DashboardServices.uploadDocuments(
      loginUserDetail.orgId,
      loginUserDetail.userId,
      data.taskId,
      reqDto
    ).then((response) => {
      console.log("document sent is ", response.data);
      if (response.data) {
        const tempData = { ...data };
        if (tempData.documents === null) {
          tempData.documents = [...response.data];
        } else {
          tempData.documents = [...data.documents, ...response.data];
        }
        dispatch(setSelectedTask(tempData));
      }
    });
    console.log("file document = ", fileDocuments.current);
  };

  const handleAddTemplate = async () => {
    const value = await DashboardServices.getAllTemplatesOfOrganization(
      loginUserDetail.orgId
    );
    setTemplateOfOrgList(value.data);

    const response = await DashboardServices.getTemplatesOfSubActivity(
      data.activityId,
      0
    );
    let notaddedTemplateList = [];
    let addedTemplateList = [];
    const updatedData = { ...data };
    if (response.data != null && response.data.length > 0) {
      response.data.forEach((template) => {
        let isTemplateAdded = false;
        addedTemplateList.forEach((addedTemplate) => {
          if (template.templateId === addedTemplate.templateId) {
            isTemplateAdded = true;
          }
        });
        if (!isTemplateAdded) {
          notaddedTemplateList.push(template);
        } else {
          addedTemplateList.push(template);
        }
      });
      setRecommendedTemplateList(notaddedTemplateList);
      if (updatedData.template && updatedData.templates.length > 0) {
        updatedData.templates = [...data.templates, ...addedTemplateList];
      } else {
        updatedData.templates = [...addedTemplateList];
      }
      dispatch(setSelectedTask(updatedData));
    }
    // const tempAddedTemplate = data.templates.filter(
    //   (template) => template.taskTemplateId !== 0
    // );
    // setAddedTemplateList(tempAddedTemplate);
    setShowAddTemplate(true);
  };

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskControlBtnGrp">
              {/* <TaskMainButtons
              itemList={[]}
              data={data}
              //   refreshData={refreshData}
              //   clearAllModals={clearAllModals}
              //   setProgressWidth={setProgressWidth}
            /> */}
            </div>

            <div className="taskActionBtnGrp">
              {/* <TaskSubButtons itemList={["Delegate"]} /> */}

              <div className="taskActBtn">
                {/* <IconButton
                aria-label="Forward"
                onClick={() => {
                  clearAllModals();
                  setShowForwardTask(true);
                }}
              >
                <Tooltip title="Delegate" arrow>
                  <ShortcutOutlined />
                </Tooltip>
              </IconButton> */}
              </div>

              <div className="taskActBtn closeBtnD10">
                <IconButton
                  aria-label="close"
                  onClick={(e) => {
                    dispatch(clearSelectedTask());
                  }}
                >
                  <Tooltip title="Close" arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                <div className="taskTitleContrct">
                  <div className="taskTitleNM">{data.taskName}</div>
                </div>

                {data && data.taskDescription && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Description</div>
                    <div className="tskElemntInfo">{data.taskDescription}</div>
                  </div>
                )}

                <TaskAudio data={data} setShowAudioModal={setShowAudioModal} />

                {data && data.contractName && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Contract</div>
                    <div className="taskContractNm">{data.contractName}</div>
                  </div>
                )}

                <TaskStatusProgress progressWidth={data.progress} />

                <TaskTimeProgress data={data} />

                <TemplateList
                  data={data}
                  handleAddTemplate={handleAddTemplate}
                />

                <FileList
                  data={data}
                  handleChangeFile={handleChangeFile}
                  setShowFileUploadModal={setShowFileUploadModal}
                />

                {data &&
                  data.formDataTransId &&
                  data.formDataTransId !== "" &&
                  data.formDataTransId !== "0" && (
                    <div className="taskElementGrp">
                      <div className="tskElemHeding">Form Data</div>

                      <IconButton
                        className="elemntMrFileBtn"
                        variant="outlined"
                        onClick={handleClickFormButton}
                      >
                        <ListAlt />
                      </IconButton>
                    </div>
                  )}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">Priority</div>
                  <div className="tskPrioArea">
                    {/* statUrgent, statNormal, statImportant  used these three for diffrent priority */}
                    <div
                      class={`tskPriot  ${
                        data.priority === 0
                          ? "statNormal"
                          : data.priority === 1
                          ? "statImportant"
                          : "statUrgent"
                      }`}
                    >
                      <div class={`priStat `}>
                        {data.priority === 0
                          ? "Regular"
                          : data.priority === 1
                          ? "Important"
                          : "Critical"}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Activity</div>
                    <div className="tskElemntInfo">{data.activityName}</div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Assigned By</div>
                    <div className="tskElemntInfo">
                      {data && isAssignedByMe(data.assignByUserId)
                        ? "Me"
                        : data.assignByUserName}
                    </div>
                  </div>
                </div>

                {data && data.comments && data.comments.length && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Last updated</div>
                    <div className="tskElemntInfo">
                      {`${
                        data.comments[data.comments.length - 1]
                          .commentsByUserName
                      } - ${getFormattedDate(
                        data.comments[data.comments.length - 1].commentsAt
                      )}`}
                    </div>
                  </div>
                )}

                <div className="taskUpCommentGrp">
                  {data &&
                    data.comments.length > 0 &&
                    data.comments.map((comment) => (
                      <div className="taskUserComment">
                        <div className="tskUderImgDT">
                          <div className="tskUseComImg">
                            <img
                              src={comment.commentsByUserProfileUrl}
                              alt=""
                            />
                          </div>
                          <div className="tskUsrCNM">
                            <div className="tskUCNM">
                              {comment.commentsByUserName}
                            </div>
                            <div className="tskUCDT">
                              {comment && getFormattedDate(comment.commentsAt)}
                            </div>
                          </div>
                        </div>
                        <div className="taskUPComnt">{comment.comments}</div>
                      </div>
                    ))}
                </div>

                <div ref={scrollRef}></div>
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="tskInputGrp">
              <input
                ref={commentInputRef}
                type="text"
                className="tskComntInput"
                placeholder="Update comment here"
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    console.log("Enter key pressed");
                    sendComment(event);
                  }
                }}
              />
              <IconButton className="tskComtSndBtn" onClick={sendComment}>
                <Send />
              </IconButton>
            </div>
          </div>
        </div>
        {showAudioModal && (
          <AudioModal
            audioUrl={audioUrl}
            closeModal={() => {
              setShowAudioModal(false);
            }}
          />
        )}
      </div>

      {showAddTemplate && (
        <RightFlotingContainer
          recommendedTemplateList={recommendedTemplateList}
          setRecommendedTemplateList={setRecommendedTemplateList}
          tempOfOrgList={tempOfOrgList}
          addedTemplateList={addedTemplateList}
          setAddedTemplateList={setAddedTemplateList}
          closeTemplate={() => {
            setShowAddTemplate(false);
            if (addedTemplateList && addedTemplateList.length > 0) {
              const temp = [...addedTemplateList];
              temp.forEach((template) => {
                template.taskDetailId = data.taskId;
              });
              DashboardServices.addTaskTemplate(
                loginUserDetail.userId,
                addedTemplateList,
                "OLD"
              ).then((response) => {
                console.log("addTaskTemplate", response.data);
                const tempData = { ...data };
                if (tempData.templates) {
                  tempData.templates = [
                    ...tempData.templates,
                    ...addedTemplateList,
                  ];
                } else {
                  tempData.templates = [...addedTemplateList];
                }
                dispatch(setSelectedTask(tempData));
              });
            }
          }}
          mode={"old"}
        />
      )}

      {showFileModal && (
        <div className="RightFlotingContainerArea">
          <RightTaskFileUpload
            documents={data.documents}
            onClose={() => setShowFileUploadModal(false)}
            uploadFile={(data) => {
              handleChangeFile(data);
            }}
          />
        </div>
      )}

      {showForm && data.taskNature === "EXPENSE_APPROVAL_REQUEST" && (
        <IncomingFormPreview
          taskId={data.taskId}
          taskNature={data.taskNature}
          taskCreatedBy={data.assignByUserId}
          formData={formData}
          handleClose={() => {
            setShowForm(false);
          }}
          handleSubmitValue={() => {
            setShowForm();
            dispatch(clearSelectedTask());
            refreshData();
          }}
        />
      )}

      {showForm && data.taskNature === "PO_APPROVAL_REQUEST" && (
        <PurchaseFormRenderer
          data={data}
          taskId={data.taskId}
          taskNature={data.taskNature}
          formData={formData}
          handleClose={() => {
            setShowForm(false);
          }}
          handleSubmitPO={(date) => {
            if (date) {
              // let reqDto = {
              //   taskDetailId: data.taskId,
              //   orgId: loginUserDetail.orgId,
              //   documents: [],

              //   taskCreator: "N",
              //   taskForwordToUserIdAfterApproval: "",
              //   additionalInfo: {
              //     "Effective Date": date,
              //   },
              //   progress: data.progress,
              //   concern: data.concern,
              //   comments: data.comments,
              //   submitButtonLabel: data.submitButtonLabel,
              //   partialSubmitChecked: data.progress === 100 ? "N" : "Y",
              //   previousAssigness: [],
              //   currentAssigness: [],
              // };

              const reqDto = {
                taskDetailId: data.taskId,
                taskForwordToUserIdAfterApproval: "",
                approvalRmks: "Approving Purchase Request",
              };

              console.log("reqdto =", reqDto);
              // return;
              DashboardServices.approveTask(
                loginUserDetail.userId,
                reqDto
              ).then((response) => {
                // handleTaskSubmission(response);
                if (response && response.data) {
                  toast.success(response.data.message);
                  dispatch(clearSelectedTask());
                  refreshData();
                } else {
                  toast.error(response.data.message);
                }
              });
            }
          }}
        />
      )}

      {showForm && data.taskNature === "PMS_APPROVAL_REQUEST" && (
        <PmsApprovalForm
          data={data}
          handleClose={() => setShowForm(false)}
          refreshData={() => {
            refreshData();
            dispatch(clearSelectedTask());
          }}
        />
      )}
    </>
  );
};
