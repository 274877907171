import {
  Button,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import { XLg } from "react-bootstrap-icons";
import isAssignedByMe from "../../../taskUtil";
import { useState } from "react";
import { useEffect } from "react";
import DashboardServices from "../../../../../services/DashboardServices";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { toast } from "react-toastify";

function RightSideSignOff({ selectedTasks, handleClose, refreshData }) {
  const [signOffComment, setSignoffComment] = useState([]);
  const loginUserDetail = getLoginUserDetails();

  useEffect(() => {
    setSignoffComment(selectedTasks.map((task) => ""));
  }, []);

  const signOffTask = async () => {
    const res = await Promise.all(
      selectedTasks.map((task, index) => {
        return new Promise((resolve, reject) => {
          // const reqDto = {
          //   postponedReason: dele[index].reason,
          //   postponedTill: dele[index].postponeTill,
          // };

          let reqDto = {
            taskDetailId: task.taskId,
            comments: signOffComment[index],
            createNextTask: "N",
          };
          DashboardServices.signOffTask(loginUserDetail.userId, reqDto).then(
            (response) => {
              if (response.data && response.data.returnValue === "1") {
                resolve("ok");
                handleClose();
              } else {
                resolve(null);
                toast.error(`Could Not Sign Off Task ${task.taskName}`);
              }
            }
          );
        });
      })
    );
    refreshData();
  };

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskTitleContrct">
              <div className="taskTitleNM">{`Sign Off ${selectedTasks.length} Tasks `}</div>
            </div>
            <div className="taskControlBtnGrp"></div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn closeBtnD10">
                <IconButton aria-label="close" onClick={handleClose}>
                  <Tooltip title="Close" arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>

          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                {selectedTasks &&
                  selectedTasks.length > 0 &&
                  selectedTasks.map((task, index) => {
                    return (
                      <>
                        <div className="multipleUpdateTaskCard">
                          <div className="taskTitleContrct">
                            <div className="taskHeadingNM">{task.taskName}</div>
                          </div>
                          <div className="commonUpdate">
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  // sx={{ color: "white" }}
                                  label="Sign Off Remark*"
                                  variant="outlined"
                                  className="descptionTextFild"
                                  multiline
                                  rows={3}
                                  maxRows={7}
                                  value={signOffComment[index]}
                                  // value={reason}
                                  onChange={(e) => {
                                    const temp = [...signOffComment];
                                    temp[index] = e.target.value;
                                    setSignoffComment(temp);
                                  }}
                                />
                              </FormControl>
                            </div>
                            {/* <div className="taskElementGrp">
                            <div className="tskElemHeding">Created By</div>
                            <div className="tskElemntInfo">
                              {task && isAssignedByMe(task.createdByUserId)
                                ? "Me"
                                : task.createdByUserName}
                            </div>
                          </div> */}
                            <div
                              className="tskCretdByElmnt"
                              style={{ color: "#fff" }}
                            >
                              <span className="assuTag">Created By :</span>
                              <span className="assuName">
                                {task && isAssignedByMe(task.createdByUserId)
                                  ? "Me"
                                  : task.createdByUserName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={(e) => {
                  signOffTask();
                }}
              >
                Sign Off
              </Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={(e) => handleClose()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RightSideSignOff;
