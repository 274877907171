import { getLoginUserDetails } from "../../utils/Utils";

export default function isAssignedByMe(userId) {
  const loginUserDetails = getLoginUserDetails();
  if (loginUserDetails.userId === userId) {
    return true;
  }
  return false;
}

export const getNumFromStr = (numString) => {
  if (typeof numString === "number") {
    return numString;
  }
  const stringList = numString.split(",");
  let num = "";
  stringList.forEach((element) => {
    num += element;
  });
  return parseInt(num);
};
