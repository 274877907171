import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Attachment } from "iconoir-react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import FormGroup from "@mui/material/FormGroup";
import CloudUpload from "@mui/icons-material/CloudUpload";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import {
  MAX_FILE_SIZE_TASK,
  VALID_FILE_EXTENSION_TASK,
} from "../constants/const_values";
import { convertFileToBase64_Split } from "../utils/FileUtils";
import { downloadFileFromUrl } from "../utils/Utils";
import { apiEndPoint, collaboraDomain } from "../constants/url_provider";
import { useTranslation } from "react-i18next";

function FileUpload({
  openFileUploadDialog,
  uploadedFiles,
  downloadFileList,
  showUploadSection,
  handelCloseFileUploadDialog,
  submitFileUpload,
  maxFileToUpload,
}) {
  const [showDropRegion, setShowDropRegion] = useState(false);
  const { t } = useTranslation();
  const [filesList, setFilesList] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [showViewFileDialog, setShowViewFileDialog] = useState({
    status: false,
    fileId: "",
  });

  let fileIframeUrl = `${collaboraDomain}/browser/abd9205/cool.html?WOPISrc=${apiEndPoint}/wopi/files/${showViewFileDialog.fileId}`;

  useEffect(() => {
    uploadedFiles && setFilesList(uploadedFiles);
  }, [openFileUploadDialog, uploadedFiles]);

  const handelFileUpload = async (event) => {
    const files = event.target.files;
    const tempFileList = filesList;

    for (var i = 0; i < files.length; i++) {
      const file = files[i];

      if (
        !VALID_FILE_EXTENSION_TASK.includes(
          file.name.split(".").pop().toLowerCase()
        )
      ) {
        // toast.warning(`Invalid File Type Of '${file.name}'`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        toast.warning(t('file_upload_invalid_file_type', { fileName: file.name }), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (file.size > 1024 * 1024 * MAX_FILE_SIZE_TASK) {
        // toast.warning(
        //   `'${file.name}' file need to be less then ${MAX_FILE_SIZE_TASK}MB`,
        //   {
        //     position: toast.POSITION.TOP_RIGHT,
        //   }
        // );
        toast.warning(t('file_upload_size_exceeded', { fileName: file.name, maxSize: MAX_FILE_SIZE_TASK }), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (filesList.find(({ fileName }) => fileName === file.name)) {
        // toast.warning(`'${file.name}' Already Uploaded`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        toast.warning(t('file_upload_already_uploaded', { fileName: file.name }), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (
        downloadFileList.length > 0 &&
        downloadFileList.find((obj) => obj.fileDisplayName === file.name)
      ) {
        // toast.warning(`'${file.name}' Already Uploaded`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        toast.warning(t('file_upload_already_uploaded', { fileName: file.name }), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const dto = {
          fileName: file.name,
          fileDataLink: await convertFileToBase64_Split(files[i]),
        };
        tempFileList.push(dto);
      }
    }

    console.log("handelFileUpload", tempFileList);
    setFilesList(tempFileList);
    setFileSize(tempFileList.length);
  };

  const handelRemoveUploadFile = (fileName) => {
    setFilesList(
      filesList.filter((fil) => {
        return fil.fileName !== fileName;
      })
    );
  };

  const showDragRegion = (e) => {
    e.preventDefault();
    setShowDropRegion(true);
  };
  const hideDragRegion = (e) => {
    e.preventDefault();
    setShowDropRegion(false);
  };

  const handleFileDrop = async (e) => {
    e.preventDefault();
    setShowDropRegion(false);
    console.log("Dropped files:", e.dataTransfer.files);
    const files = Array.from(e.dataTransfer.files);

    const tempFileList = filesList;

    for (var i = 0; i < files.length; i++) {
      const file = files[i];

      if (
        !VALID_FILE_EXTENSION_TASK.includes(
          file.name.split(".").pop().toLowerCase()
        )
      ) {
        // toast.warning(`Invalid File Type Of '${file.name}'`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        toast.warning(t('file_upload_invalid_file_type', { fileName: file.name }), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (file.size > 1024 * 1024 * MAX_FILE_SIZE_TASK) {
        // toast.warning(
        //   `'${file.name}' file need to be less then ${MAX_FILE_SIZE_TASK}MB`,
        //   {
        //     position: toast.POSITION.TOP_RIGHT,
        //   }
        // );
        toast.warning(t('file_upload_size_exceeded', { fileName: file.name, maxSize: MAX_FILE_SIZE_TASK }), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (filesList.find(({ fileName }) => fileName === file.name)) {
        // toast.warning(`'${file.name}' Already Uploaded`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        toast.warning(t('file_upload_already_uploaded', { fileName: file.name }), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (
        downloadFileList.length > 0 &&
        downloadFileList.find((obj) => obj.fileDisplayName === file.name)
      ) {
        // toast.warning(`'${file.name}' Already Uploaded`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        toast.warning(t('file_upload_already_uploaded', { fileName: file.name }), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const dto = {
          fileName: file.name,
          fileDataLink: await convertFileToBase64_Split(files[i]),
        };
        tempFileList.push(dto);
      }
    }

    console.log("handelFileUpload", tempFileList);
    setFilesList(tempFileList);
    setFileSize(tempFileList.length);
  };

  return (
    <>
      <Dialog open={openFileUploadDialog} onClose={handelCloseFileUploadDialog}>
        <DialogTitle>{t("manage_your_files")}</DialogTitle>
        <DialogContent
          sx={{ height: "60vh", width: "60vw" }}
          onDragEnter={showDragRegion}
          onDragExit={hideDragRegion}
          onDragOver={showDragRegion}
          onDragLeave={hideDragRegion}
          onDrop={(e) => {
            e.preventDefault();
            setShowDropRegion(false);
          }}
        >
          <div className="uploadDoc">
            {showUploadSection && (
              <>
                <div className="">
                  <FormGroup>
                    <input
                      id="coustFileInput"
                      hidden
                      multiple
                      onChange={handelFileUpload}
                      type="file"
                    />
                    <Tooltip
                      title={
                        <>
                          <ul>
                            {/* <li>{`Maximum File Size Allowed ${MAX_FILE_SIZE_TASK}MB`}</li>
                            <li>{`Extension Allowed ${VALID_FILE_EXTENSION_TASK.map(
                              (ex) => ` ${ex}`
                            )}`}</li> */}
                              <li>{t('file_upload_maximum_file_size', { size: MAX_FILE_SIZE_TASK })}</li>
            <li>
              {t('file_upload_extension_allowed', {
                extensions: VALID_FILE_EXTENSION_TASK.join(', '),
              })}
            </li>
                          </ul>
                        </>
                      }
                    >
                      <label for="coustFileInput" className="coustFileInputBtn">
                        <CloudUpload />
                        {t("upload_document")}
                      </label>
                    </Tooltip>
                  </FormGroup>
                </div>

                {showDropRegion && (
                  <div
                    style={{
                      width: "100%",
                      padding: "75px",
                      textAlign: "center",
                      border: "2px dotted black",
                      borderRadius: "10px",
                    }}
                    onDrop={handleFileDrop}
                  >
                    {t("drop_here_to_upload_files")}
                  </div>
                )}
                <List>
                  {fileSize >= 0 && (
                    <>
                      <h4 className="upldDocHead">{t("documents_uploaded")}</h4>
                      {filesList.map((file) => {
                        return (
                          <>
                            <ListItem
                              className="upldDocList"
                              secondaryAction={
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() => {
                                    handelRemoveUploadFile(file.fileName);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              }
                            >
                              <ListItemAvatar>
                                <Avatar>
                                  <Attachment />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary={file.fileName} />
                            </ListItem>
                          </>
                        );
                      })}
                    </>
                  )}
                </List>
              </>
            )}

            <List>
              {downloadFileList && downloadFileList.length > 0 && (
                <>
                  <h4 className="upldedDocHead">{t("already_uploaded_document")}</h4>
                  {downloadFileList.map((file) => {
                    return (
                      <ListItem
                        className="upldedDocList"
                        secondaryAction={
                          <>
                            <IconButton edge="end" aria-label="delete">
                              <VisibilityIcon
                                onClick={() => {
                                  setShowViewFileDialog({
                                    status: true,
                                    fileId: file.fileName,
                                  });
                                }}
                              />
                            </IconButton>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => {
                                downloadFileFromUrl(
                                  file.fileDataLink,
                                  file.fileDisplayName
                                );
                              }}
                            >
                              <CloudDownloadIcon />
                            </IconButton>
                          </>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <Attachment />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={file.fileDisplayName} />
                      </ListItem>
                    );
                  })}
                </>
              )}
            </List>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (maxFileToUpload) {
                if (filesList.length > maxFileToUpload) {
                  // toast.error(
                  //   `You can Upload Maximum ${maxFileToUpload} files`
                  // );
                  toast.error(t('file_upload_max_file_upload_error', { maxFiles: maxFileToUpload }));
                  return;
                }
              }
              submitFileUpload(filesList);
              setFilesList([]);
              handelCloseFileUploadDialog();
            }}
            autoFocus
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>

      {/*  show files dialog */}
      <Dialog
        open={showViewFileDialog.status}
        onClose={() => {
          setShowViewFileDialog({ status: false, fileId: "" });
        }}
      >
        <div className="adFullscreen">
          <DialogTitle>{t("file_details")}</DialogTitle>
          <DialogContent>
            <div className="uploadDoc">
              <div style={{ width: "-webkit-fill-available" }}>
                <iframe
                  style={{
                    minHeight: "80vh",
                    maxHeight: "85vh",
                  }}
                  src={fileIframeUrl}
                  width="100%"
                  height="100vh"
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowViewFileDialog({
                  status: false,
                  fileId: "",
                });
              }}
              autoFocus
            >
              {t("close")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}

export default FileUpload;
