import { Box, Button, IconButton } from "@mui/material";
import React from "react";
import { XLg } from "react-bootstrap-icons";

export default function SalaryHeadAdd({
  handelCloseSalaryHeadModel,
  showAddSalaryHeadModel,
}) {
  return (
    <>
      <div className="modelContainer" open={showAddSalaryHeadModel}>
        <Box className="modelContain animated fullwidthModel">
          <IconButton
            className="closeModelIoc"
            onClick={handelCloseSalaryHeadModel}
          >
            <XLg />
          </IconButton>

          <div className="fwModelContainArea">
            <div className="fwModelHeading">Add Saray Group / Head</div>

            <div className="modActionBtnGrp actionsCreateOrg">
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={handelCloseSalaryHeadModel}
              >
                Cancel
              </Button>
              <Button
                className="dfultPrimaryBtn"
                // onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
}
