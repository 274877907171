import { PeopleRounded, Verified } from "@mui/icons-material";
import { Xmark } from "iconoir-react";
import React, { useEffect, useState } from "react";
import { MIN_USER_IN_CHAT_GROUP } from "../../../../constants/const_values";
import { getChatAllContacts } from "../../../../services/ChatService";
import { getLoginUserDetails } from "../../../../utils/Utils";
import { useTranslation } from "react-i18next";

export default function AddGroupUser({
  selectedUserList,
  setSelectedUserList,
}) {
  const loginUser = getLoginUserDetails();
  const { t } = useTranslation();
  const [userContactList, setUserContactList] = useState([]);
  const [filterUserContactList, setFilterUserContactList] = useState([]);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    if (loginUser != null) {
      fetchData();
    }
  }, []);

  // fetch data from api start
  const fetchData = async () => {
    const { userId, orgId } = loginUser;
    await getChatAllContacts(userId, orgId).then((response) => {
      if (response.data != null) {
        if (response.data.length > 0) {
          let tempList = [];
          for (let index = 0; index < response.data.length; index++) {
            const element = response.data[index];
            if (!selectedUserList.find((x) => x.userId === element.userId)) {
              tempList.push(element);
            }
          }
          const sortList = _sort(tempList);
          setUserContactList(sortList);
          setFilterUserContactList(sortList);
        }
      }
    });
  };
  // fetch data from api end

  // handle user add to selected list start
  const handleClickSelectUser = (user) => {
    setSelectedUserList([...selectedUserList, user]);
    setFilterUserContactList(filterUserContactList.filter((x) => x !== user));
    setUserContactList(userContactList.filter((x) => x !== user));
  };
  // handle user add to selected list end

  // handle user remove from selected list start
  const removeUserFromSelected = (user) => {
    setSelectedUserList(selectedUserList.filter((x) => x !== user));
    setFilterUserContactList(_sort([...filterUserContactList, user]));
    setUserContactList([...userContactList, user]);
  };
  // handle user remove to selected list start

  // sort data by alphabetically start
  const _sort = (dataList) => {
    return dataList.sort((a, b) => {
      const x = a.userName.toUpperCase();
      const y = b.userName.toUpperCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };
  // sort data by alphabetically end

  // search by name start
  useEffect(() => {
    if (searchKey !== "") {
      setFilterUserContactList(
        userContactList.filter((x) =>
          x.userName.toLowerCase().includes(searchKey.toLowerCase())
        )
      );
    } else {
      setFilterUserContactList(userContactList);
    }
  }, [searchKey]);
  // search by name end

  return (
    <>
      <div className="selectedPartList">
        <div className="slctedChtUserListGrp">
          {selectedUserList.length > 0 ? (
            <>
              {selectedUserList.map((user) => (
                <div className="slctedChtUser">
                  <div className="scuProImg">
                    <img alt={t("profile_photo")} src={user.profileUrl} />
                  </div>
                  <div className="scuNm">{user.userName}</div>
                  <div
                    className="scuRmv"
                    role="button"
                    onClick={() => removeUserFromSelected(user)}
                  >
                    <Xmark />
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="slctChtUserNone">
              {/* Select user to add Group (Minimum {MIN_USER_IN_CHAT_GROUP}) */}
              {t("select_user_to_add_group", { minUsers: MIN_USER_IN_CHAT_GROUP })}
            </div>
          )}
        </div>
      </div>

      {/* search user input start */}
      <div className="chtsrchUsr">
        <div className="srchUsrIpG">
          <div className="searchUser">
            <div className="searchIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19.6 21L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16C7.68333 16 6.146 15.3707 4.888 14.112C3.63 12.8533 3.00067 11.316 3 9.5C3 7.68333 3.62933 6.146 4.888 4.888C6.14667 3.63 7.684 3.00067 9.5 3C11.3167 3 12.8543 3.62933 14.113 4.888C15.3717 6.14667 16.0007 7.684 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L21 19.6L19.6 21ZM9.5 14C10.75 14 11.8127 13.5627 12.688 12.688C13.5633 11.8133 14.0007 10.7507 14 9.5C14 8.25 13.5627 7.18767 12.688 6.313C11.8133 5.43833 10.7507 5.00067 9.5 5C8.25 5 7.18767 5.43767 6.313 6.313C5.43833 7.18833 5.00067 8.25067 5 9.5C5 10.75 5.43767 11.8127 6.313 12.688C7.18833 13.5633 8.25067 14.0007 9.5 14Z"
                  fill="#A8A8A8"
                />
              </svg>
            </div>

            <input
              type="text"
              className="serchInput"
              placeholder={t("search_name_to_add_contact")}
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
            />
          </div>
        </div>
      </div>
      {/* search user input end */}

      <div className="createGroupCont">
        <div className="contactListGroup">
          {filterUserContactList.length > 0 &&
            filterUserContactList.map((user) => (
              <div
                className="chatContactList"
                onClick={() => handleClickSelectUser(user)}
              >
                <div className="contUserImg">
                  <img alt={t("profile_photo")} src={user.profileUrl} />
                  {/* <span className="usrActStatus"></span> */}
                </div>

                <div className="userDtlWlMsg">
                  <div className="usrNmDT">
                    <div className="usrNmIn">
                      {user.userName}
                      {user?.userType === "I" && <Verified />}
                      {user?.userType === "G" && <PeopleRounded />}
                    </div>
                  </div>

                  <div className="usrLstMsgDtStus">
                    <div className="lstMsgShw">{user.userDtl}</div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
