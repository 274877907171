import { PhotoCamera } from "@mui/icons-material";
import { TextField } from "@mui/material";
import React, { useRef } from "react";
import { toast } from "react-toastify";
import { MAX_FILE_SIZE_CHAT } from "../../../../constants/const_values";
import {
  convertFileToBase64,
  getLoginUserDetails,
} from "../../../../utils/Utils";
import { useTranslation } from "react-i18next";

export default function CreateGroupInfo({
  groupName,
  setGroupName,
  groupIcon,
  setGroupIcon,
}) {
  const loginUser = getLoginUserDetails();
  const { t } = useTranslation();
  const fileInputRef = useRef(null);

  // handle change files start
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file.size > 1024 * 1024 * MAX_FILE_SIZE_CHAT) {
      // toast.error(`Please upload a file less than ${MAX_FILE_SIZE_CHAT}MB.`);
      toast.error(t("file_size_error", { maxFileSize: MAX_FILE_SIZE_CHAT }));
    } else {
      setGroupIcon({
        fileName: file.name,
        fileData: await convertFileToBase64(file),
      });
    }

    fileInputRef.current.value = null;
  };
  // handle change files end

  return (
    <div className="cNwGrpImgNmContener">
      <div className="cNwGrpImgNmArea">
        <div className="cNwGrpImgContain">
          {/* group icon or image update start */}
          <div className="cNwGrpImgArea" role="button">
            <div className="cNwGrpAddGrpIco" role="button">
              <div className="cNwGrpSvg">
                {/* <Group /> */}
                <img
                  alt=""
                  src={
                    groupIcon.fileDate !== ""
                      ? `data:image/png;base64,${groupIcon.fileData}`
                      : loginUser?.orgLogo
                  }
                />
              </div>
              <span className="ipChngGrpImg">
                <label
                  role="button"
                  for="inputChangeGroupImg"
                  className="labelInpImgChng"
                >
                  <PhotoCamera />
                  <span className="imgchngHint">{t("add_group_icon")}</span>
                </label>
                <input
                  ref={fileInputRef}
                  className="inputChangeGroupImg"
                  id="inputChangeGroupImg"
                  type="file"
                  accept="image/gif,image/jpeg,image/jpg,image/png"
                  onChange={handleFileChange}
                />
              </span>
            </div>
          </div>
          {/* group icon or image update end */}
        </div>

        {/* group name input start */}
        <div className="cNwGrpNmContain">
          <div className="chatGroupNmInput">
            <TextField
              id="chtGrpNmTextFild"
              label={t("group_name")}
              required
              variant="standard"
              className="chtGrpNmTextFild"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
          </div>
        </div>
        {/* group name input end */}
      </div>
    </div>
  );
}
