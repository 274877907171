import React, { useRef, useState } from "react";
import "./css/privacy-policy.css";
import Logo from "./assets/tc.png";
import FooterLP from "../features/landingPage/view/component/FooterLP";
import NavBarLP from "../features/landingPage/view/component/NavBarLP";
import LoginRegister from "../features/landingPage/view/component/LoginRegister";

export default function PrivacyPolicy() {
  const [showLogReg, setShowLogReg] = useState(false);

  const handelCloseLogRegContain = () => {
    setShowLogReg(false);
  };

  const handelOpenLogRegContain = () => {
    setShowLogReg(true);
  };

  const scrollableNavRef = useRef(null);
  return (
    <>
      <div className="lp-full-container" ref={scrollableNavRef}>
        <NavBarLP
          handelOpenLogRegContain={handelOpenLogRegContain}
          scrollableNavRef={scrollableNavRef}
        />
        {showLogReg && (
          <LoginRegister handelCloseLogRegContain={handelCloseLogRegContain} />
        )}
        <>
          {" "}
          {/* <nav class="navbar navbar-expand-lg mb-4" id="indexnav"> */}
          {/* <div class="container">
          <a class="navbar-brand" href="index.html">
            <img src="img/dfs.png" class="logo2" alt="displayImage0" />
          </a>
        </div> */}
          {/* </nav> */}
          <div class="tcmaindiv">
            <div class="tcleft">
              <div class="tcimagearea">
                <img src={Logo} class="trmcndimage" alt="displayImage" />
              </div>
            </div>

            <div class="tcright">
              <div class="tctextarea">
                <div class="tcmainheading">
                  <h1 class="tcheading">
                    PRIVACY <span class="cndtn">POLICY</span>
                  </h1>
                </div>

                <div class="tctext">
                  <p class="tcsubtext">
                    Privacy Policy of the Company and all its subsidiaries and
                    assets including those in different countries. The Company
                    delivers cloud-based software services to its clients on
                    subscription service basis. It needs access to data to
                    enable delivery of services and also improve its services.
                    Every transaction will entail transferring in the case of a
                    person accessing services, his/her personal data to enable
                    service delivery, responsible for transferring payments or
                    reimbursements and in compliance with law, their credentials
                    and personal data to the service recipient to enable the
                    delivery of services and also to payment processing, better
                    customer care etc. The Company also uses automated
                    algorithms to improve service delivery through zoyel.one and
                    its other subsidiaries. The software and data used is only
                    used to attempt a better service delivery.
                  </p>
                  <p class="tcsubtext">
                    This Privacy Policy describes Our policies and procedures on
                    the collection, use, storage, processing, transfer, and
                    disclosure of Your Personal Data when You use the Service
                    and tells You about Your privacy rights and how the law
                    protects You
                  </p>
                  <p class="tcsubtext">
                    We use Your Personal data to provide and improve the
                    Service. By using the Service, you agree to the collection
                    and use of information in accordance with this Privacy
                    Policy.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="tcsubtextarea">
            <div class="subheadingtc">
              <h2 class="tcsubheading">INTERPRETATION AND DEFINITIONS</h2>
            </div>

            <div class="subheadingtc">
              <h3 class="tcsubline">INTERPRETATION</h3>
              <p class="tcabout">
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </p>
            </div>

            <div class="subheadingtc">
              <h3 class="tcsubline">DEFINITIONS</h3>
              <p class="tcabout">For the purposes of this Privacy Policy:</p>

              <div class="tclinetextarea">
                <ul class="tcul">
                  <li>
                    <p class="tcabout">
                      <strong>Account</strong> means a unique account created
                      for You to access our Service or parts of our Service.
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong>Affiliate</strong> means an entity that controls,
                      is controlled by or is under common control with a party,
                      where "control" means ownership of 50% or more of the
                      shares, equity interest or other securities entitled to
                      vote for election of directors or other managing
                      authority.
                    </p>
                  </li>

                  <li>
                    <p class="tcabout">
                      <strong>Application</strong> means the software program /
                      app / webapp provided by the Company downloaded by You on
                      any electronic device, named Zoyel.one and on url
                      beginning with
                      <a
                        href="https://www.zoyel.one"
                        class="zoyellink"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.zoyel.one
                      </a>
                      Company Company (referred to as either "the Company",
                      "We", "Us" or "Our" in this Agreement) refers to Zoyel
                      Technologies LLC 112-028, Bena Complex C, Dubai UAE
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong>Cookies</strong> are small files that are placed
                      on Your computer, mobile device or any other device by a
                      website, containing the details of Your browsing history
                      on that website among its many uses.
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong> Device</strong> means any device that can access
                      the Service such as a computer, a cell phone or a digital
                      tablet.
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong> Persona Data</strong> means any information that
                      relates to a natural person which, either directly or
                      indirectly, is capable of identifying such person and for
                      the purpose of this Privacy Policy, refers to the Personal
                      Data identified herein under the head - Type of Data
                      Collected.
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong> Service</strong> refers to the Application or the
                      Website or both.
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong> Service Provider</strong> means any natural or
                      legal person who processes the data on behalf of the
                      Company. It refers to third-party companies or individuals
                      employed by the Company to facilitate the Service, to
                      provide the Service on behalf of the Company, to perform
                      services related to the Service or to assist the Company
                      in analysing how the Service is used.
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong> Third-party Social Media Service</strong> refers
                      to any website or any social network website through which
                      a User can log in or create an account to use the Service.
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong>Usage Data</strong> refers to data collected
                      automatically, either generated by the use of the Service
                      or from the Service infrastructure itself (for example,
                      the duration of a page visit).
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong> Website</strong> refers to zoyel.one, accessible
                      from
                      <a
                        href="https://www.zoyel.one"
                        class="zoyellink"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.zoyel.one.
                      </a>
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong>You</strong> mean the individual accessing or
                      using the Service, or the company, or other legal entity
                      on behalf of which such individual is accessing or using
                      the Service, as applicable.
                    </p>
                  </li>
                </ul>
              </div>

              <p class="tcabout">
                This Privacy Policy is an electronic record under applicable
                laws and the rules made thereunder. This Privacy Policy does not
                require any physical, electronic, or digital signature by the
                Company.
              </p>
            </div>

            <div class="subtcheading">
              <h2 class="tcsubheading">
                COLLECTING AND USING YOUR PERSONAL DATA
              </h2>
              <h2 class="tcsecondsubhdng">TYPES OF DATA COLLECTED</h2>
              <h3 class="tcsublinetxt">PERSONAL DATAD</h3>
              <div class="tclinetextarea">
                <p class="tcabout">
                  While using Our Service, We may ask You to provide Us with
                  certain personally identifiable information that can be used
                  to contact or identify You including but not limited to:
                </p>

                <ul class="tcul">
                  <li>
                    <p class="tcabout">Email address</p>
                  </li>
                  <li>
                    <p class="tcabout">First name and last name</p>
                  </li>
                  <li>
                    <p class="tcabout">Gender</p>
                  </li>
                  <li>
                    <p class="tcabout">Age</p>
                  </li>
                  <li>
                    <p class="tcabout">Phone number</p>
                  </li>
                  <li>
                    <p class="tcabout">Location</p>
                  </li>
                  <li>
                    <p class="tcabout">Device</p>
                  </li>
                  <li>
                    <p class="tcabout">
                      Address, State, Province, ZIP/Postal code, City, country
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      Bank account information in order to pay for products
                      and/or services within the Service
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">Usage Data</p>
                  </li>
                  <li>
                    <p class="tcabout">
                      Any detail relating to the aforesaid as provided to the
                      Company for availing the Services
                    </p>
                  </li>
                </ul>
                <p class="tcabout">
                  It is hereby clarified that any information that is freely
                  available or accessible in public domain or furnished under
                  the applicable laws for the time being in force shall not be
                  regarded as “personally identifiable information”
                </p>
                <p class="tcabout">
                  It is hereby clarified that any information that is freely
                  available or accessible in public domain or furnished under
                  the applicable laws for the time being in force shall not be
                  regarded as “personally identifiable information”
                </p>
                <div class="tclinetextarea">
                  <p class="tcabout">
                    While using Our Service, We may ask You to provide Us with
                    certain personally identifiable information that can be used
                    to contact or identify You including but not limited to:
                  </p>

                  <ul class="tcul">
                    <li>
                      <p class="tcabout">Date of birth</p>
                    </li>
                    <li>
                      <p class="tcabout">
                        Passport or National ID card or other form of ID
                      </p>
                    </li>
                    <li>
                      <p class="tcabout">Bank card statement</p>
                    </li>
                    <li>
                      <p class="tcabout">
                        Other information linking You to an address
                      </p>
                    </li>
                  </ul>
                  <p class="tcabout">
                    You always have the option to not provide the Personal Data
                    or information to Us sought to be collected from You by
                    choosing to not use a particular Service(s) or feature being
                    provided by Us, which requires You to provide such
                    information. However, any refusal to provide certain
                    Personal Data or information could impact provision of
                    services and restrict your access thereto.
                  </p>
                </div>
              </div>
            </div>

            <div class="subtcheading">
              <h3 class="tcsublinetxt">USAGE DATA</h3>
              <div class="tclinetextarea">
                <p class="tcabout">
                  Usage Data is collected automatically when using the Service.
                </p>
                <p class="tcabout">
                  Usage Data may include information such as Your Device's
                  Internet Protocol address (e.g., IP address), browser type,
                  browser version, the pages of our Service that You visit, the
                  time and date of Your visit, the time spent on those pages,
                  unique device identifiers and other diagnostic data.
                </p>
                <p class="tcabout">
                  When You access the Service by or through a mobile device, We
                  may collect certain information automatically, including, but
                  not limited to, the type of mobile device You use.
                </p>
                <p class="tcabout">
                  Your mobile device unique ID, the IP address of Your mobile
                  device, Your mobile operating system, the type of mobile
                  Internet browser You use, unique device identifiers and other
                  diagnostic data.
                </p>
                <p class="tcabout">
                  We may also collect information that Your browser sends
                  whenever You visit our Service or when You access the Service
                  by or through a mobile device.
                </p>
              </div>
            </div>

            <div class="subtcheading">
              <h3 class="tcsublinetxt">
                INFORMATION COLLECTED WHILE USING THE APPLICATION
              </h3>
              <div class="tclinetextarea">
                <p class="tcabout">
                  While using Our Application, in order to provide features of
                  Our Application, We may collect, with Your prior permission:
                </p>
                <ul class="tcul">
                  <li>
                    <p class="tcabout">Information regarding Your location</p>
                  </li>
                  <li>
                    <p class="tcabout">
                      Pictures, videos and other information from Your or
                      accessing Device's camera and photo library
                    </p>
                  </li>
                </ul>
                <p class="tcabout">
                  We use this information to provide features of Our Service,
                  and to improve and customize Our Service. The information may
                  be uploaded to the Company's servers and/or a Service
                  Provider's server or it may be simply stored on Your device.
                </p>

                <p class="tcabout">
                  You can enable or disable access to this information at any
                  time, through Your Device settings.
                </p>
              </div>
            </div>

            <div class="subtcheading">
              <h3 class="tcsublinetxt">TRACKING TECHNOLOGIES AND COOKIES</h3>
              <div class="tclinetextarea">
                <p class="tcabout">
                  We use Cookies and similar tracking technologies to track the
                  activity on Our Service and store certain information.
                  Tracking technologies used are beacons, tags, and scripts to
                  collect and track information and to improve and analyse Our
                  Service. The technologies We use may include:
                </p>
                <ul class="tcul">
                  <li>
                    <p class="tcabout">
                      <strong>Cookies or Browser Cookies:</strong> A cookie is a
                      small file placed on Your Device. You can instruct Your
                      browser to refuse all Cookies or to indicate when a Cookie
                      is being sent. However, if You do not accept Cookies, You
                      may not be able to use some parts of Our Service. Unless
                      You have adjusted Your browser setting so that it will
                      refuse Cookies, our Service may use Cookies.
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong> Flash Cookies:</strong> Certain features of our
                      Service may use local stored objects (or Flash Cookies) to
                      collect and store information about Your preferences or
                      Your activity on our Service. Flash Cookies are not
                      managed by the same browser settings as those used for
                      Browser Cookies. For more information on how You can
                      delete Flash Cookies, please read "Where can I change the
                      settings for disabling, or deleting local shared objects?"
                      available
                      <a href="#s" class="zoyellink" target="_blank"></a>here.
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong> Web Beacons: </strong>Certain sections of our
                      Service and our emails may contain small electronic files
                      known as web beacons (also referred to as clear gifs,
                      pixel tags, and single-pixel gifs) that permit the
                      Company, for example, to count users who have visited
                      those pages or opened an email and for other related
                      website statistics (for example, recording the popularity
                      of a certain section and verifying system and server
                      integrity).
                    </p>
                  </li>
                </ul>

                <p class="tcabout">
                  Cookies can be "Persistent" or "Session" Cookies. Persistent
                  Cookies remain on Your personal computer or mobile device when
                  You go offline, while Session Cookies are deleted as soon as
                  You close Your web browser. Learn more about cookies: What Are
                  Cookies?.
                </p>

                <p class="tcabout">
                  We use both Session and Persistent Cookies for the purposes
                  set out below:
                </p>
                <ul class="tcul">
                  <li>
                    <p class="tcaboutt">
                      <strong>Necessary / Essential Cookies:</strong>
                    </p>
                    <p class="tcaboutt">Type: Session Cookies</p>
                    <p class="tcaboutt">Administered by: Us</p>
                    <p class="tcaboutt">
                      Purpose: These Cookies are essential to provide You with
                      services available through the Website and to enable You
                      to use some of its features. They help to authenticate
                      users and prevent fraudulent use of user accounts. Without
                      these Cookies, the services that You have asked for cannot
                      be provided, and We only use these Cookies to provide You
                      with those services.
                    </p>
                  </li>
                  <li>
                    <p class="tcaboutt">
                      <strong>
                        Cookies Policy / Notice Acceptance Cookies:
                      </strong>
                    </p>
                    <p class="tcaboutt">Type: Persistent Cookies</p>
                    <p class="tcaboutt">Administered by: Us</p>
                    <p class="tcaboutt">
                      Purpose: These Cookies identify if users have accepted the
                      use of cookies on the Website.
                    </p>
                  </li>
                  <li>
                    <p class="tcaboutt">
                      <strong>Functionality Cookies:</strong>
                    </p>
                    <p class="tcaboutt">Type: Persistent Cookies</p>
                    <p class="tcaboutt">Administered by: Us</p>
                    <p class="tcaboutt">
                      Purpose: These Cookies allow us to remember choices You
                      make when You use the Website, such as remembering Your
                      login details or language preference. The purpose of these
                      Cookies is to provide You with a more personal experience
                      and to avoid You having to re-enter Your preferences every
                      time You use the Website.
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="subtcheading">
              <h3 class="tcsublinetxt">USE OF YOUR PERSONAL DATA</h3>
              <div class="tclinetextarea">
                <p class="tcabout">
                  The Company may use Personal Data for the following purposes:
                </p>

                <ul class="tcul">
                  <li>
                    <p class="tcabout">
                      <strong>To provide and maintain our Service:</strong>{" "}
                      including to monitor the usage of our Service.
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong>To manage Your Account:</strong>to manage Your
                      registration as a user of the Service. The Personal Data
                      You provide can give You access to different
                      functionalities of the Service that are available to You
                      as a registered user.
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong>For the performance of a contract:</strong> the
                      development, compliance and undertaking of the purchase
                      contract for the products, items or services You have
                      purchased or of any other contract with Us through the
                      Service.
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong>To contact You:</strong> To contact You by email,
                      telephone calls, SMS, or other equivalent forms of
                      electronic communication, such as a mobile application's
                      push notifications regarding updates or informative
                      communications related to the functionalities, products or
                      contracted services, including the security updates, when
                      necessary or reasonable for their implementation.
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong>To provide You:</strong> with news, special offers
                      and general information about other goods, services and
                      events which We offer that are similar to those that You
                      have already purchased or enquired about unless You have
                      opted not to receive such information.
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong>To manage Your requests:</strong> To attend and
                      manage Your requests to Us.
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong>For business transfers:</strong> We may use Your
                      information to evaluate or conduct a merger, divestiture,
                      restructuring, reorganization, dissolution, or other sale
                      or transfer of some or all of Our assets, whether as a
                      going concern or as part of bankruptcy, liquidation, or
                      similar proceeding, in which Personal Data held by Us
                      about our Service users is among the assets transferred.
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong>For Research &amp; Development:</strong> We may
                      use Your information for development of better services
                      through Cohort studies, AI, Machine Learning, Analytics to
                      provide continuous improvement of algorithms, to ensure
                      better delivery.
                    </p>
                  </li>
                  <li>
                    <p class="tcabout">
                      <strong>For other purposes:</strong> We may use Your
                      information for other purposes, such as data analysis,
                      audit, identifying usage trends, determining the
                      effectiveness of our promotional campaigns and to evaluate
                      and improve our Service, products, services, marketing and
                      Your experience.
                    </p>
                  </li>
                </ul>

                <p class="tcabout">
                  We may share Your Personal Data in the following situations:
                </p>
                <ul class="tcul">
                  <li>
                    <p class="tcabout">
                      <strong>
                        With Service Providers / Recipients of Service:
                      </strong>
                      We may share Your Personal Data with Service Providers /
                      Service Recipients to monitor and analyse the use of our /
                      Your Service, for payment processing, to contact You and
                      to enable you to provide / receive services. All agencies
                      or persons involved in the coordination of care may be
                      provided access to data.
                    </p>
                  </li>

                  <li>
                    <p class="tcabout">
                      <strong>With Government Authorities:</strong> where
                      required by law to comply with the country of origin or
                      jurisdiction to meet with
                    </p>
                  </li>

                  <li>
                    <p class="tcabout">
                      <strong> For business transfers:</strong> We may share or
                      transfer Your Personal Data in connection with, or during
                      negotiations of, any merger, sale of Company assets,
                      financing, or acquisition of all or a portion of Our
                      business to another company.
                    </p>
                  </li>

                  <li>
                    <p class="tcabout">
                      <strong>With Affiliates:</strong>We may share Your
                      information with Our Affiliates, in which case We will
                      require those Affiliates to honour this Privacy Policy.
                      Affiliates may include Our parent company and any other
                      subsidiaries, joint venture partners or other companies
                      that We control or that are under common control with Us.
                    </p>
                  </li>

                  <li>
                    <p class="tcabout">
                      <strong>With business partners:</strong> We may share Your
                      information with Our business partners to offer You
                      certain products, services or promotions.
                    </p>
                  </li>

                  <li>
                    <p class="tcabout">
                      <strong>With other users:</strong> when You share Personal
                      Data or otherwise interact in the public areas with other
                      users, such information may be viewed by all users and may
                      be publicly distributed outside. If You interact with
                      other users or register through a Third-Party Social Media
                      Service, Your contacts on the Third-Party Social Media
                      Service may see Your name, profile, pictures and
                      description of Your activity. Similarly, other users will
                      be able to view descriptions of Your activity, communicate
                      with You and view Your profile.
                    </p>
                  </li>

                  <li>
                    <p class="tcabout">
                      <strong>With Your consent:</strong> We may disclose Your
                      Personal Data for any other purpose with Your consent.
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="subtcheading">
              <h3 class="tcsublinetxt">RETENTION OF YOUR PERSONAL DATA</h3>
              <div class="tclinetextarea">
                <p class="tcabout">
                  The Company will retain Your Personal Data only for as long as
                  is necessary for the purposes set out in this Privacy Policy
                  or to meet requirements of law in the relevant jurisdiction.
                  We will retain and use Your Personal Data to the extent
                  necessary to comply with our legal obligations (for example,
                  if We are required to retain Your data to comply with
                  applicable laws), resolve disputes, and enforce our legal
                  agreements and policies.
                </p>

                <p class="tcabout">
                  The Company will also retain Usage Data for internal analysis
                  purposes. Usage Data is generally retained for a shorter
                  period of time, except when this data is used to strengthen
                  the security or to improve the functionality of Our Service,
                  or We are legally obligated to retain this data for longer
                  time periods.
                </p>
              </div>
            </div>

            <div class="subtcheading">
              <h3 class="tcsublinetxt">TRANSFER OF YOUR PERSONAL DATA</h3>
              <div class="tclinetextarea">
                <p class="tcabout">
                  Your information, including Personal Data, is processed at the
                  Company's operating offices and in any other places where the
                  parties involved in the processing are located. It means that
                  this information may be transferred to - and maintained on -
                  computers located outside of Your state, province, country or
                  other governmental jurisdiction where the data protection laws
                  may differ than those from Your jurisdiction.
                </p>

                <p class="tcabout">
                  Your consent to this Privacy Policy followed by Your
                  submission of such information represents Your agreement to
                  that transfer.
                </p>

                <p class="tcabout">
                  The Company will take all steps reasonably necessary to ensure
                  that Your data is treated securely and in accordance with this
                  Privacy Policy and no transfer of Your Personal Data will take
                  place to an organization or a country unless there are
                  adequate controls in place including the security of Your data
                  and other Personal Data.
                </p>
              </div>
            </div>

            <div class="subtcheading">
              <h3 class="tcsublinetxt">DISCLOSURE OF YOUR PERSONAL DATA</h3>
              <h5 class="semitcabout">Business Transactions</h5>
              <div class="tclinetextarea">
                <p class="tcabout">
                  If the Company is involved in a merger, acquisition or asset
                  sale, Your Personal Data may be transferred. We will provide
                  notice before Your Personal Data is transferred and becomes
                  subject to a different Privacy Policy.
                </p>
              </div>

              <h5 class="semitcabout">Law enforcement</h5>
              <div class="tclinetextarea">
                <p class="tcabout">
                  Under certain circumstances, the Company may be required to
                  disclose Your Personal Data if required to do so by law or in
                  response to valid requests by public authorities (e.g., a
                  court or a government agency).
                </p>
              </div>

              <h5 class="semitcabout">Other legal requirements</h5>
              <div class="tclinetextarea">
                <p class="tcabout">
                  The Company may disclose Your Personal Data in the good faith
                  belief that such action is necessary to:
                </p>
              </div>

              <ul class="tcul">
                <li>
                  <p class="tcabout">Comply with a legal obligation</p>
                </li>
                <li>
                  <p class="tcabout">
                    Protect and defend the rights or property of the Company
                  </p>
                </li>
                <li>
                  <p class="tcabout">
                    Prevent or investigate possible wrongdoing in connection
                    with the Service
                  </p>
                </li>
                <li>
                  <p class="tcabout">
                    Protect the personal safety of Users of the Service or the
                    public
                  </p>
                </li>
                <li>
                  <p class="tcabout">Protect against legal liability</p>
                </li>
              </ul>
            </div>

            <div class="subtcheading">
              <h3 class="tcsublinetxt">SECURITY OF YOUR PERSONAL DATA</h3>
              <div class="tclinetextarea">
                <p class="tcabout">
                  The Company has reasonable security measures and safeguards in
                  place to protect Your privacy and Personal Data from loss,
                  misuse, unauthorized access, disclosure, destruction, and
                  alteration, in compliance with applicable laws. The security
                  of Your Personal Data is important to Us, but remember that no
                  method of transmission over the Internet, or method of
                  electronic storage is 100% secure. While We strive to use
                  commercially acceptable means to protect Your Personal Data,
                  We cannot guarantee its absolute security however ensure or
                  guarantee or warrant its absolute security. It is further
                  clarified that You have and so long as You access the Services
                  (directly or indirectly), the obligation to ensure that You
                  shall at all times, take adequate physical, managerial, and
                  technical safeguards, at Your end, to preserve the integrity
                  and security of Your data which shall include and not be
                  limited to Your Personal Data.
                </p>
              </div>
            </div>
            <div class="subtcheading">
              <h2 class="tcsubheading">
                DETAILED INFORMATION ON THE PROCESSING OF YOUR PERSONAL DATA
              </h2>
              <div class="tclinetextarea">
                <p class="tcabout">
                  Service Providers have access to Your Personal Data only to
                  perform their tasks on Our behalf and are obligated not to
                  disclose or use it for any other purpose.
                </p>
              </div>
            </div>

            <div class="subtcheading">
              <h3 class="tcsublinetxt">PAYMENTS</h3>
              <div class="tclinetextarea">
                <p class="tcabout">
                  We may provide paid products and/or services within the
                  Service. In that case, We may use third-party services for
                  payment processing (e.g., payment processors).
                </p>

                <p class="tcabout">
                  We will not store or collect Your payment card details. That
                  information is provided directly by You to Our third-party
                  payment processors whose use of Your Personal Data is governed
                  by their Privacy Policy. These payment processors adhere to
                  the standards set by PCI-DSS as managed by the PCI Security
                  Standards Council, which is a joint effort of brands like
                  Visa, Mastercard, American Express and Discover. PCI-DSS
                  requirements help ensure the secure handling of payment
                  information.
                </p>

                <ul class="tcul">
                  <li>
                    <p class="tcabout">
                      <strong>Stripe</strong> Their Privacy Policy can be viewed
                      <a href="#s" class="zoyellink" target="_blank">
                        here.
                      </a>
                    </p>
                  </li>
                </ul>
                <p class="tcabout">
                  When You use Our Service to pay for a product and/or service
                  via bank transfer, We may ask You to provide information to
                  facilitate this transaction and to verify Your identity.
                </p>

                <p class="tcabout">
                  When payment information is being transmitted for availing the
                  Services, it will be protected by encryption technology. By
                  making payments for any Services on Our platform, You
                  expressly consent to the sharing of Your information with
                  third party service providers, including payment gateways, to
                  process payments and manage Your payment-related information.
                  Hence, the Company cannot guarantee that transmissions of Your
                  payment-related information or Personal Data will always be
                  secure or that unauthorized third parties will never be able
                  to defeat the security measures taken by the Company or the
                  Company's third-party service providers. The Company assumes
                  no liability or responsibility for disclosure of Your
                  information due to errors in transmission, unauthorized
                  third-party access, or other causes beyond its control. You
                  play an important role in keeping Your Personal Data secure.
                  You shall not share Your Personal Data or other security
                  information for Your account with anyone. The Company has
                  undertaken reasonable measures to protect Your rights of
                  privacy with respect to Your access of Our Services. However,
                  We shall not be liable for any unauthorized or unlawful
                  disclosures of Your Personal Data made by any third parties
                  who are not subject to Our control.
                </p>
              </div>
            </div>

            <div class="subtcheading">
              <h3 class="tcsublinetxt">OTHER SERVICE PARTNERS</h3>
              <div class="tclinetextarea">
                <p class="tcabout">
                  Other service partners which may have access to some or part
                  of your data to enable services in certain circumstances and
                  these may include the following. Please review their privacy
                  policies.
                </p>

                <p class="tcabout">
                  <a
                    href="https://aws.amazon.com/privacy"
                    class="zoyellink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://aws.amazon.com/privacy
                  </a>
                </p>

                <p class="tcabout">
                  <a
                    href="https://in.godaddy.com/legal/agreements/privacy-policy"
                    class="zoyellink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://in.godaddy.com/legal/agreements/privacy-policy
                  </a>
                </p>
              </div>
            </div>

            <div class="subtcheading">
              <h2 class="tcsubheading">LINKS TO OTHER WEBSITES</h2>
              <div class="tclinetextarea">
                <p class="tcabout">
                  Our Service may contain links to other websites that are not
                  operated by Us. If You click on a third party link, You will
                  be directed to that third party's site. We strongly advise You
                  to review the Privacy Policy of every site You visit.
                </p>

                <p class="tcabout">
                  We have no control over and assume no responsibility for the
                  content, privacy policies or practices of any third party
                  sites or services.
                </p>
              </div>
            </div>

            <div class="subtcheading">
              <h2 class="tcsubheading">
                YOUR CONSENT AND CHANGES TO THIS PRIVACY POLICY
              </h2>
              <div class="tclinetextarea">
                <p class="tcabout">
                  <strong>Your acknowledgement:</strong> All information
                  disclosed by You shall be deemed to be disclosed willingly and
                  without any coercion. No liability pertaining to the
                  authenticity/genuineness/misrepresentation/fraud/negligence of
                  the information disclosed by You shall lie on the Company nor
                  will the Company be in any way responsible to verify any
                  information obtained from You.
                </p>

                <p class="tcabout">
                  <strong>Changes to Our Privacy Policy:</strong> We may update
                  Our Privacy Policy from time to time. We will notify You of
                  any changes by posting the new Privacy Policy on this page.
                </p>

                <p class="tcabout">
                  We will let You know via email and/or a prominent notice on
                  Our Service, prior to the change becoming effective and update
                  the "Last updated" date at the top of this Privacy Policy.
                </p>

                <p class="tcabout">
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page. Your acceptance of the amended
                  Privacy Policy including by way of Your continuous access to
                  the Services shall signify Your consent to such changes and
                  agreement to be legally bound by the same.
                </p>
              </div>
            </div>

            <div class="subtcheading">
              <h2 class="tcsubheading">CONTACT US</h2>
              <div class="tclinetextarea">
                <p class="tcabout">
                  Our Company would like to make sure You are fully aware of all
                  of your data protection rights. Every user is entitled to the
                  following:
                </p>

                <p class="tcabout">
                  The right to access - You have the right to request Our
                  Company for separate copies of your personal data. We may
                  charge you a small fee for this service.
                </p>

                <p class="tcabout">
                  The right to rectification - You have the right to request
                  that Our Company correct any information You believe is
                  inaccurate. You also have the right to request Our Company to
                  complete the information you believe is incomplete.
                </p>

                <p class="tcabout">
                  The right to erasure - You have the right to request that Our
                  Company erase your personal data, under certain conditions.
                </p>

                <p class="tcabout">
                  The right to restrict processing - You have the right to
                  request that Our Company restrict the processing of your
                  personal data, under certain conditions.
                </p>
                <p class="tcabout">
                  The right to object to processing - You have the right to
                  object to Our Company's processing of Your personal data,
                  under certain conditions.
                </p>
                <p class="tcabout">
                  The right to data portability - You have the right to request
                  that Our Company transfer the data that We have collected to
                  another organization, or directly to you, under certain
                  conditions.
                </p>
                <p class="tcabout">
                  The right to withdraw consent - You may choose to withdraw
                  Your consent in writing subject to this Privacy Policy at any
                  point of time. In case You do not provide Your consent or
                  later withdraw Your consent, We request You not to access and
                  use the Services and We also reserve the right to not provide
                  You any Services. In such a scenario, the Company may delete
                  Your information (personal or otherwise) or de-identify it so
                  that it is anonymous and not attributable to You.
                </p>

                <ul class="tcul">
                  <li>
                    <p class="tcabout">
                      If You make a request, We have one month to respond to
                      You. If You would like to exercise any of these rights,
                      please contact us at our email: noreply@zoyel.one
                    </p>
                  </li>
                </ul>

                <p class="tcabout">
                  If You have any (i) any questions about this Privacy Policy
                  and/or (ii) any grievance with respect to the Services, You
                  can contact us:
                </p>

                <ul class="tcul">
                  <li>
                    <p class="tcabout">By email: noreply@zoyel.one</p>
                  </li>
                  <li>
                    <p class="tcabout">
                      By Mail: To <strong>customer care</strong>, Zoyel
                      Technologies LLC 112-028, Bena Complex C, Dubai UAE, If
                      there is a failure to get response or a resolution, You
                      may seek arbitration under applicable laws
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
        <FooterLP />
      </div>
    </>
  );
}
