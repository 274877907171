const EmailUtil = {
  extractNameAndEmailFromNameEmail: (nameEmailString) => {
    let emailId = null;
    const emailRegex = /<([^>]+)>/;

    let pattern = /(?:([^<]+)\s*<)?([^>]+)>?/;

    // Use the exec method to find the first match in the input string
    let match = pattern.exec(nameEmailString);

    // Extract userName and email from the match
    let userName = match && match[1] ? match[1].trim() : null;
    let email = match && match[2] ? match[2].trim() : null;

    // console.log("email= ", email);
    // console.log("userName =  ", userName);

    return { userName, email };
  },

  formatDate: (dateString) => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZoneName: "short",
    };

    const formattedTime = new Date(dateString).toLocaleString("en-US", options);
    return formattedTime;
  },

  formatDateDDMMYY: (inputDateString) => {
    const inputDate = new Date(inputDateString);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return inputDate.toLocaleDateString("en-US", options);
  },

  formatTimeHHMMAMPM: (inputDateString) => {
    const inputDate = new Date(inputDateString);
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return inputDate.toLocaleTimeString("en-US", options);
  },

  generateRandomBoundary: () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const boundaryLength = 30; // Adjust the length as needed
    let boundary = "";

    for (let i = 0; i < boundaryLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      boundary += characters.charAt(randomIndex);
    }

    return `boundary_${boundary}`;
  },

  getEmailDataFromEmailBody: (emailResponse) => {
    // console.log("getting email data from body ", emailResponse);

    const message = emailResponse.result;
    const messageId = message.id;
    const threadId = message.threadId;
    // console.log("emailResponse=", message);

    const subjectHeader = message.payload.headers.find(
      (header) => header.name === "Subject"
    );
    const subject = subjectHeader ? subjectHeader.value : "No subject";

    // Extracting sender and receiver email addresses
    let sender = message.payload.headers.find(
      (header) => header.name === "From"
    ).value;
    const senderDetails = EmailUtil.extractNameAndEmailFromNameEmail(sender);
    let senderEmail = "";
    let senderName = "";
    senderEmail = senderDetails.email ? senderDetails.email : sender;
    senderName = senderDetails.userName ? senderDetails.userName : "";
    //     if (senderDetails.email) {
    //   senderEmail = senderDetails.email;
    // }else if(senderDetails.name){
    //   senderName = senderDetails.name;

    // } else {
    //   senderEmail = sender;
    // }
    //   const { senderName, senderEmail } = sender;

    let receiver = message.payload.headers.find(
      (header) => header.name === "To"
    );
    receiver = receiver ? receiver.value : null;

    // Extracting sending date
    const sendingDate = message.payload.headers.find(
      (header) => header.name === "Date"
    ).value;

    // Extracting attachments
    let attachments = message.payload.parts
      ? message.payload.parts
          .filter((part) => part.filename)
          .map((part) => ({
            filename: part.filename,
            mimeType: part.mimeType,
            body: part.body,
          }))
      : null;

    // Extracting message body
    let snippet = message.snippet; // You can use message.payload.body.data for the base64-encoded content
    const decodedText = document.createElement("div");
    decodedText.innerHTML = snippet;
    snippet = decodedText.innerText;

    const labelIds = message.labelIds;

    return {
      messageId,
      threadId,
      senderName,
      senderEmail,
      receiver,
      sendingDate,
      attachments,
      snippet,
      labelIds,
      subject,
    };
  },

  modifyMessageCall: (messageId, labelsToAdd, labelsToRemove) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await window.gapi.client.gmail.users.messages.modify({
          userId: "me",
          id: messageId,
          addLabelIds: labelsToAdd,
          removeLabelIds: labelsToRemove,
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
};

export default EmailUtil;
