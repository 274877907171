import React, { useEffect, useRef, useState } from "react";
import { RouteGuard } from "../../../config/router";
import Tabs from "./Tabs";
import TopNavbar from "./TopNavbar";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { mailSocket, meetingSocket } from "../../../socket";
import { getLoginUserDetails } from "../../../utils/Utils";
import { jwtDecode } from "jwt-decode";
import { mailTopicName, socketEndPoint } from "../../../constants/url_provider";
import axios from "axios";
import {
  getUnreadMessages,
  handleHistory,
  updateHistory,
} from "../../../redux/reducers/dashboardMailSlice";
import { useDispatch } from "react-redux";
import EventAlert from "../../../common/EventAlert";
import { v4 as uuidv4 } from "uuid";
import HomeContainer from "./component/HomeContainer";
import ChatContainer from "../../chat/view/ChatContainer";
import EmailContainerWrapper from "../../email/view/EmailContainerWrapper";
import { ConfirmProvider } from "material-ui-confirm";
import MeetingsTabContainer from "../../scheduler/view/component/meetings/view/MeetingsTabContainer";
import DriveContainer from "../../drive/view/DriveContainer";
import SchedulerContainer from "../../scheduler/view/SchedulerContainer";
import Admin from "../../admin/view/Admin";
import TaskTabContainer from "../../task/view/TaskTabContainer";
import { toast } from "react-toastify";
import WhiteBoard from "../../scheduler/view/component/meetings/view/WhiteBoard";
import { createContext } from "react";
import BottomMenu from "../../../common/BottomMenu";
import SelectTemplate from "../../organization/view/component/SelectTemplate";
import SchRightMeetingDtlsContainer from "../../scheduler/view/SchRightMeetingDtlsContainer";
import CallInvite from "../../../common/CallInvite";
// import mySound from "../assets/media/bell2.wav";
import useSound from "use-sound";
import AnalyticsDashboard from "../../analyticsDashboard/view/AnalyticsDashboard";
// import ZoeyBot from "../../../common/ZoeyBot";
import { useLocation } from "react-router-dom";
import { AddTabContext } from "./DashboardPage";

// export const AddTabContext = createContext();

export default function DashboardPageGuest() {
  const loginUserDetail = getLoginUserDetails();
  // const [playSound, { stop }] = useSound(mySound);
  const [gapiInited, setGapiInited] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  const loginUser = getLoginUserDetails();

  const dispatch = useDispatch();
  // const [historyId, setHistoryId] = useState(null);
  const historyId = useRef(null);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEventWB, setSelectedEventWB] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [eventAlertSelData, setEventAlertSelData] = useState(null);
  const [callInviteData, setCallInviteData] = useState(null);

  // const [tabs, setTabs] = useState([
  //   ...[
  //     {
  //       key: uuidv4(),
  //       title: "Home",
  //       content: (
  //         <HomeContainer
  //           setEventData={(eventData) => addMeetingTab(eventData)}
  //         />
  //       ),
  //       isDraggable: false,
  //     },
  //     {
  //       key: uuidv4(),
  //       title: "Chat",
  //       content: <ChatContainer />,
  //       isDraggable: true,
  //     },
  //     {
  //       key: uuidv4(),
  //       title: "Email",
  //       content: <EmailContainerWrapper />,
  //       isDraggable: true,
  //     },
  //     {
  //       key: uuidv4(),
  //       title: "Meeting Room",
  //       content: (
  //         <ConfirmProvider>
  //           <MeetingsTabContainer
  //             openWhiteBoard={(eventData) => {
  //               openWhiteBoard(eventData);
  //             }}
  //           />
  //         </ConfirmProvider>
  //       ),
  //       isDraggable: true,
  //     },
  //     {
  //       key: uuidv4(),
  //       title: "Drive",
  //       content: <DriveContainer />,
  //       isDraggable: true,
  //     },

  //     {
  //       key: uuidv4(),
  //       title: "Scheduler",
  //       content: (
  //         <SchedulerContainer
  //           setEventData={(eventData) => addMeetingTab(eventData)}
  //         />
  //       ),
  //       isDraggable: true,
  //     },
  //     { key: uuidv4(), title: "Admin", content: <Admin />, isDraggable: true },
  //     {
  //       key: uuidv4(),
  //       title: "Select Template",
  //       content: <SelectTemplate />,
  //       isDraggable: true,
  //     },
  //   ],
  //   ...(loginUserDetail.orgId === "ORG000000000000"
  //     ? []
  //     : [
  //         {
  //           key: uuidv4(),
  //           title: "Task",
  //           content: <TaskTabContainer />,
  //           isDraggable: true,
  //         },
  //       ]),
  // ]);

  const [tabs, setTabs] = useState([]);

  const changeTabContent = (index, tabObject) => {
    const selTab = { ...tabs[index] };
    selTab.content = tabObject.content;
    selTab.title = tabObject.title;
    selTab.type = null;
    const updatedList = [...tabs];
    updatedList[index] = selTab;
    setTabs(updatedList);
  };

  const addTab = (tabObject) => {
    console.log("addTab", tabObject);
    if (tabObject.type === "New") {
      changeTabContent(tabObject.tabIndex, tabObject);
    } else {
      const presentIndex = tabs.findIndex((tab) => tab.key === tabObject.key);
      if (presentIndex !== -1) {
        setActiveTab(presentIndex);
      } else {
        const tempTab = [...tabs];
        tempTab.push(tabObject);
        setTabs(tempTab);
        setActiveTab(tabs.length);
      }
    }
  };

  const addMeetingTab = (eventData) => {
    let newTabs = tabs.filter((tabDto) => tabDto.title !== "MeetingDemo");
    console.log("newTabs", newTabs);
    console.log("addMeetingTab", eventData);
    let dto = {
      title: "Meeting " + eventData.eventLink,
      content: (
        <ConfirmProvider>
          <MeetingsTabContainer
            eventData={eventData}
            openWhiteBoard={(eventData) => {
              openWhiteBoard(eventData);
            }}
          />
        </ConfirmProvider>
      ),
      isDraggable: true,
    };
    newTabs.push(dto);
    setTabs(newTabs);
    setSelectedEvent(eventData);
    toast.info(`Joining ${eventData.eventTitle}, please wait`);
  };

  const openWhiteBoard = (eventData) => {
    let newTabs = tabs.filter((tabDto) => tabDto.title !== "Whiteboard");
    console.log("newTabs", newTabs);
    console.log("openWhiteBoard", eventData);
    let dto = {
      key: uuidv4(),
      title: "Whiteboard",
      content: <WhiteBoard eventData={eventData} />,
      isDraggable: true,
    };
    newTabs.push(dto);
    setTabs(newTabs);
    setSelectedEventWB(eventData);
    toast.info(`Opening whiteboard, please wait`);
  };

  const gapiLoaded = () => {
    window.gapi.load("client", initializeGapiClient);
  };

  const initializeGapiClient = async () => {
    console.log("initialize value =");
    await window.gapi.client.init({
      // apiKey: API_KEY,
      discoveryDocs: [
        "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest",
        "https://www.googleapis.com/discovery/v1/apis/people/v1/rest",
      ],
    });

    // await window.gapi.auth.setToken({
    //   access_token: accessToken,
    // });

    setGapiInited(true);
    // listLabels();
    // getEmailList(["INBOX", "CATEGORY_PERSONAL"], "-in:draft -in:sent");
  };

  const hitApi = async () => {
    console.log("hitting api");
    try {
      const response = await axios.get(
        `${socketEndPoint}/api/mail/authenticate/${loginUser.userId}`
      );
      if (response.data.url) {
        // window.open(response.data.url, "_blank");
      } else {
        handleAccessTokenData(response.data);
      }
    } catch (error) {
      console.log("error happened", error);
    }
  };

  const authenticate = async () => {
    const expiry_date = localStorage.getItem("expiry_date");
    const access_token = localStorage.getItem("access_token");
    const timestamp = parseInt(expiry_date, 10);
    const currentTimestamp = Date.now();
    const timeDifference = timestamp - currentTimestamp;
    if (timeDifference > 2 * 60 * 1000) {
      setAccessToken(access_token);
      setTimeout(() => {
        hitApi();
      }, timeDifference - 2 * 60 * 1000);
      console.log("");
    } else {
      hitApi();
    }

    if (expiry_date) {
      console.log("typeof expiry date = ", typeof expiry_date);
    }
  };

  const handleAccessTokenData = async (data) => {
    const tempData = data;
    console.log("jwt token data ", tempData.id_token);
    const decodedData = jwtDecode(tempData.id_token);
    const { email, name } = decodedData;
    // setAccessToken(tempData.access_token);
    localStorage.setItem("current_mailId", email);
    localStorage.setItem("email_user_name", name);
    localStorage.setItem("access_token", tempData.access_token);
    localStorage.setItem("expiry_date", tempData.expiry_date);
  };

  const updateTab = (updateData, tabKey) => {
    // const temp = [...tabState];
    // const tabIndex = temp.findIndex((item) => item.key === tabKey);
    // if (tabIndex >= 0) {
    //   temp[tabIndex] = { ...temp[tabIndex], ...updateData };
    // }
    // setTabState(temp);
  };

  const { state } = useLocation();
  useEffect(() => {
    console.log("state-->>", state);
    if (state) {
      console.log("state-->>", state.eventData);
      const tabKey = uuidv4();
      addTab({
        key: tabKey,
        title: `${state.eventData.eventTitle}`,
        content: (
          <ConfirmProvider>
            <MeetingsTabContainer eventData={state.eventData} tabKey={tabKey} />
          </ConfirmProvider>
        ),
        isDraggable: true,
        type: "Meeting",
      });
    }
  }, []);

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.async = true;
  //   script.defer = true;
  //   script.src = "https://apis.google.com/js/api.js";
  //   script.onload = gapiLoaded;

  //   document.body.appendChild(script);
  //   const expiry_date = localStorage.getItem("expiry_date");
  //   const access_token = localStorage.getItem("access_token");
  //   if (access_token) {
  //     authenticate();
  //     const addUserInServer = async () => {
  //       const email = localStorage.getItem("current_mailId");
  //       const response = await axios.get(
  //         `${socketEndPoint}/api/mail/mapUserEmail/${loginUser.userId}/${email}`
  //       );
  //       console.log("user sync value=", response);
  //     };
  //     addUserInServer();
  //   } else {
  //     const checkAuthentication = async () => {
  //       const response = await axios.get(
  //         `${socketEndPoint}/api/mail/checkAuthentication/${loginUser.userId}`
  //       );
  //       if (response.data.returnStatus === 0) {
  //       } else if (response.data.url) {
  //         // window.open(response.data.url, "_blank");
  //       } else {
  //         handleAccessTokenData(response.data);
  //       }
  //     };
  //     checkAuthentication();
  //   }
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (mailSocket) {
  //     mailSocket.emit("joinMailUser", { userId: loginUser.userId });
  //     mailSocket.on("receiveTokenFromServer", async (tokenObj) => {
  //       console.log("receiveTokenFromServer", tokenObj);
  //       try {
  //         handleAccessTokenData(tokenObj);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     });
  //     mailSocket.on("historyList", async (historyList) => {
  //       console.log("received history ids  = ", historyList);
  //       if (Array.isArray(historyList.historyId)) {
  //         dispatch(handleHistory(historyId.current, historyList.historyId[0]));
  //       } else {
  //         dispatch(handleHistory(historyId.current, historyList.historyId));
  //       }
  //     });
  //   }
  // }, [mailSocket]);

  // useEffect(() => {
  //   // console.log(" gapi inited = ", gapiInited, " access Token = ", accessToken);
  //   const setTokenInGapi = async () => {
  //     if (gapiInited && accessToken) {
  //       await window.gapi.auth.setToken({
  //         access_token: accessToken,
  //       });
  //       // listLabels();
  //       dispatch(getUnreadMessages());
  //       // dispatch(clearUnreadMessages());
  //       // const req1 = await window.gapi.client.people.people.get({
  //       //   resourceName: "people/me",
  //       //   personFields: "names,emailAddresses,photos",
  //       // });

  //       // if (req1.result) {
  //       //   const displayName = req1.result.names[0].displayName;
  //       //   localStorage.setItem("email_user_name", displayName);
  //       // }

  //       const request = await window.gapi.client.gmail.users.watch({
  //         userId: "me",
  //         resource: {
  //           labelIds: ["INBOX", "CATEGORY_PERSONAL"], // Set the label IDs to watch for new messages in the inbox
  //           labelFilterAction: "include",
  //           topicName: mailTopicName,
  //         },
  //       });

  //       console.log("user initial history= ", request);
  //       historyId.current = request.result.historyId;
  //       dispatch(updateHistory(request.result.historyId));
  //     }
  //   };
  //   setTokenInGapi();
  // }, [accessToken, gapiInited]);

  // useEffect(() => {
  //   meetingSocket.on("callInviteReceived", async (data) => {
  //     console.log("callInviteReceived", data);
  //     setCallInviteData(data);
  //     playSound();
  //     // setSelectedData(null);
  //   });
  // }, [meetingSocket]);
  return (
    <>
      <AddTabContext.Provider value={{ addTab, updateTab }}>
        <DndProvider backend={HTML5Backend}>
          <RouteGuard url={"dashboard"} />
          <TopNavbar state={state} />
          <Tabs
            tabs={tabs}
            setTabs={setTabs}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            selectedEventWB={selectedEventWB}
            setSelectedEventWB={setSelectedEventWB}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            loginMode={"GUEST"}
            state={state}
          />
        </DndProvider>
      </AddTabContext.Provider>
    </>
  );
}
