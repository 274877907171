import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

class SupportService {
  createSupportTicket(reqDto, userId) {
    return http.post(`/support/createSupportTicket/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getSupportTicketsOfUnit(unitId, fromDate, toDate) {
    return http.get(
      `/support/getSupportTicketsOfUnit/${unitId}/${fromDate}/${toDate}`,
      {
        headers: authHeader(),
      }
    );
  }

  getSupportTicketDetailsByTicketRowId(unitId) {
    return http.get(`/support/getSupportTicketDetailsByTicketRowId/${unitId}`, {
      headers: authHeader(),
    });
  }

  getSupportTicketTaskComments(taskId) {
    return http.get(`/support/getSupportTicketTaskComments/${taskId}`, {
      headers: authHeader(),
    });
  }

  getLastTicketUpdateByUserDetails(taskId) {
    return http.get(`/support/getLastTicketUpdateByUserDetails/${taskId}`, {
      headers: authHeader(),
    });
  }

  sendSupportTaskComments(reqDto, userId, ticketRowId) {
    return http.put(
      `/support/sendSupportTaskComments/${userId}/${ticketRowId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  closeSupportTicket(reqDto, userId) {
    return http.post(`/support/closeSupportTicket/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SupportService();
