import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import EmojiPicker from "emoji-picker-react";
import {
  ArrowEmailForward,
  Attachment,
  Microphone,
  MicrophoneSpeaking,
  Plus,
  Send,
  Xmark,
} from "iconoir-react";
import {
  deleteMessage,
  getChatMessages,
  getGroupChatUsers,
  readMessage,
  sendMessage,
} from "../../../services/ChatService";
import {
  chatFileTypeByFileExtension,
  convertBlobToBase64,
  convertFileToBase64,
  dateIsLessThanToday,
  downloadFileFromData,
  getDateInAMPM,
  getDateInDDMMYYAMPM,
  getLoginUserDetails,
} from "../../../utils/Utils";
import ConversationHeader from "./component/ConversationHeader";

import {
  Close,
  KeyboardArrowDown,
  SentimentVerySatisfied,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import CoustomTooltip from "../../../common/CustomTooltip";
import { MESSAGE_DELETED } from "../../../constants/const_string";
import {
  CHAT_DOCUMENT_EXTENSION,
  CHAT_PHOTO_EXTENSION,
  CHAT_VIDEO_EXTENSION,
  MAX_CHAR_LENGTH_UPLOAD_FILE,
  MAX_FILE_SIZE_CHAT,
} from "../../../constants/const_values";
import { chatSocket } from "../../../socket";
import LoaderSmall from "../../../utils/LoaderSmall";
import noPriview from "../images/avatar/noPriview-docs.png";
import ChatGroupDetails from "./component/ChatGroupDetails";
import ForwardUserList from "./component/ForwardUserList";
import ReactLinkify from "react-linkify";
import { useTranslation } from "react-i18next";

export default function ChatConversation({
  selectedContact,
  setSelectedContact,
  refreshContacts,
  onlineUserList,
  newMessageObj,
  setNewMessageObj,
  deleteMessageObj,
  setDeleteMessageObj,
  closeFromDashboard,
  unreadMessageCount,
}) {
  const VALID_FILE_EXTENSION_CHAT = [
    ...CHAT_PHOTO_EXTENSION,
    ...CHAT_VIDEO_EXTENSION,
    ...CHAT_DOCUMENT_EXTENSION,
  ];
  const loginUser = getLoginUserDetails();
  const { t } = useTranslation();

  const lastChatPositionRef = useRef(null);
  const firstChatPositionRef = useRef(null);
  const fileInputRef = useRef(null);
  const externalMessageInputRef = useRef();
  const fileUploadMessageInputRef = useRef();
  const currentSelectedContact = useRef(null);

  const [inputText, setInputText] = useState("");
  const [showEmojiInput, setShowEmojiInput] = useState(false);

  const [showFileEmojiInput, setShowFileEmojiInput] = useState(false);
  const [emojiInputText, setEmojiInputText] = useState("");

  const [uploadedFileList, setUploadedFileList] = useState([]);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [selectedUploadFile, setSelectedUploadFile] = useState(null);
  const [refreshCount, setRefreshCount] = useState(1);

  const [messageList, setMessageList] = useState([]);

  const [groupChatUserList, setGroupChatUserList] = useState([]);
  const [moreDataLoading, setMoreDataLoading] = useState(false);
  const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);

  const [audioStream, setAudioStream] = useState(null);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);

  const [showGroupInfo, setShowGroupInfo] = useState(false);

  const [chatMenuAnchor, setChatMenuAnchor] = useState(null);
  const [isForward, setIsForward] = useState(false);
  const [selectedMessageList, setSelectedMessageList] = useState([]);
  const [showForwardUser, setShowForwardUser] = useState(false);
  const [replyObject, setReplyObject] = useState({});

  const [dataLoading, setDataLoading] = useState(false);
  const [scrollAtBottom, setScrollAtBottom] = useState(false);

  const [showInputs, setShowInputs] = useState(true);

  const [deleteGroupUserData, setDeleteGroupUserData] = useState(null);
  const [fileAvailableData, setFileAvailableData] = useState(null);

  // fetch initial data start
  useEffect(() => {
    _initial();
    return () => {
      setNewMessageObj(null);
      setDeleteMessageObj(null);
      setMessageList([]);
      setRefreshCount((r) => r + 1);
      setShowInputs(true);
    };
  }, [selectedContact]);
  // fetch initial data end

  const _initial = async () => {
    if (selectedContact != null) {
      const { chatId, unreadChatCount } = selectedContact;

      setIsAllDataLoaded(false);
      setIsForward(false);
      setShowForwardUser(false);
      setReplyObject({});
      setScrollAtBottom(false);

      let joinTime = null;
      let leaveTime = null;

      if (selectedContact.chatType === "G") {
        await getGroupChatUsers(chatId).then((response) => {
          if (response.data != null) {
            let tempList = [];
            let showInputs = false;

            for (let i = 0; i < response.data.length; i++) {
              const user = response.data[i];
              if (user.activeFlag == "Y") {
                tempList.push(user);
                if (user.userId === loginUser.userId) {
                  showInputs = true;
                }
              }
              if (user.userId === loginUser.userId && user.joinedTime) {
                joinTime = user.joinedTime;
              }
              if (user.userId === loginUser.userId && user.deactiveTime) {
                leaveTime = user.deactiveTime;
              }
            }

            setGroupChatUserList(tempList);
            setShowInputs(showInputs);
          }
        });
      }

      if (
        selectedContact.lastMessageId > 0 &&
        selectedContact.chatId !== currentSelectedContact.current
      ) {
        setMessageList([]);
        setDataLoading(true);
        setTimeout(() => {}, 2000);
        await getChatMessages(
          loginUser.userId,
          chatId,
          unreadChatCount,
          0
        ).then((response) => {
          setTimeout(() => setDataLoading(false), 1000);
          if (response.data != null) {
            let tempList = [];
            if (selectedContact.chatType == "G") {
              for (let i = 0; i < response.data.length; i++) {
                if (
                  isBetween(
                    new Date(response.data[i].sentTime),
                    new Date(joinTime),
                    leaveTime != null ? new Date(leaveTime) : new Date()
                  )
                ) {
                  tempList.push(response.data[i]);
                }
              }
            } else {
              tempList = response.data;
            }
            setMessageList(tempList);
            setTimeout(() => _scrollToBottom(), 100);
          }
        });
      }

      readMessage(chatId, loginUser.userId).then((res) => {
        if (res.data != null) console.log("message read successfully");
      });

      setSelectedMessageList([]);
      setShowGroupInfo(false);
      currentSelectedContact.current = selectedContact.chatId;
    }
  };

  // socket handler start
  useEffect(() => {
    chatSocket.on("responseDeleteGroupUser", (d) => setDeleteGroupUserData(d));
    chatSocket.on("refreshFileAvailable", (d) => setFileAvailableData(d));

    return () => {
      chatSocket.off("responseDeleteGroupUser");
      chatSocket.off("refreshFileAvailable");
    };
  }, [chatSocket]);
  // socket handler end

  // on receive new message start
  useEffect(() => {
    if (newMessageObj != null && selectedContact != null) {
      if (newMessageObj.chatId === selectedContact.chatId) {
        setMessageList((prev) => [...prev, newMessageObj]);
        setRefreshCount((r) => r + 1);
        setTimeout(() => _scrollToBottom(), 100);
        setTimeout(() => _scrollContainerBottom(), 100);
        //  read message if the user is selected and the message from the selected user
        readMessage(selectedContact.chatId, loginUser.userId).then((res) => {
          if (res.data != null) {
            // selectedContact = { ...selectedContact, unreadChatCount: 0 };
            refreshContacts();
          }
        });
      }
    }
  }, [newMessageObj]);
  // on receive new message end

  // on delete message start
  useEffect(() => {
    if (deleteMessageObj) {
      const index = messageList.findIndex(
        ({ messageId }) => messageId === deleteMessageObj.messageId
      );
      if (index != -1) {
        messageList[index].deleted = "Y";
        setRefreshCount((r) => r + 1);
      }
    }
  }, [deleteMessageObj]);
  // on delete message end

  // on delete user from a chat group start
  useEffect(() => {
    if (deleteGroupUserData) {
      if (selectedContact.chatId == deleteGroupUserData) {
        toast.info(t("you_have_been_removed_from_this_group"));
        setShowInputs(false);
        setDeleteGroupUserData(null);
      }
    }
  }, [deleteGroupUserData]);
  // on delete user from a chat group end

  // on refresh file url start
  useEffect(() => {
    if (fileAvailableData) {
      if (selectedContact.chatId == fileAvailableData.chatId) {
        const index = messageList.findIndex(
          (element) => element.messageId == fileAvailableData["messageId"]
        );
        if (index !== -1) {
          setTimeout(() => {
            messageList[index].fileAvailable = "Y";
            setRefreshCount((r) => r + 1);
          }, 1000);
          setFileAvailableData(null);
        }
      }
    }
  }, [fileAvailableData]);
  // on refresh file url end

  // handle file upload start
  const handleFileChange = async (files) => {
    let fileList = files;
    let tmpFileList = uploadedFileList;

    let replyAgainstId = 0;
    if (Object.keys(replyObject).length > 0) {
      replyAgainstId = replyObject.messageId;
    }

    if (fileList.length + uploadedFileList.length > 10) {
      toast.error(t("maximum_ten_files_can_be_uploaded_at_a_time"));
      const filesArray = Array.from(fileList);
      const canUploadNumber = 10 - uploadedFileList.length;

      const slicedFiles = filesArray.slice(0, canUploadNumber);

      const dataTransfer = new DataTransfer();
      for (const file of slicedFiles) {
        dataTransfer.items.add(file);
      }
      fileList = dataTransfer.files;
    }

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      let fileName = file.name;
      const fileExt = file.name.split(".").pop().toLowerCase();

      if (file.size > 1024 * 1024 * MAX_FILE_SIZE_CHAT) {
        // toast.error(`Please upload a file less than ${MAX_FILE_SIZE_CHAT}MB.`);
        toast.error(t("file_size_error", { maxFileSize: MAX_FILE_SIZE_CHAT }));
        continue;
      }
      if (!VALID_FILE_EXTENSION_CHAT.includes(fileExt)) {
        // toast.error(
        //   `Invalid file type. Valid file types are ${VALID_FILE_EXTENSION_CHAT.join(
        //     ","
        //   )}`
        // );
        const validFileTypes = VALID_FILE_EXTENSION_CHAT.join(", ");
        toast.error(t("invalid_file_type", { validFileTypes }));
        continue;
      }
      const { chatUserId } = selectedContact;
      if (fileName.length >= MAX_CHAR_LENGTH_UPLOAD_FILE) {
        fileName = `${fileName
          .substring(0, MAX_CHAR_LENGTH_UPLOAD_FILE - (fileExt.length + 1))
          .trim()}.${fileExt}`;
      }
      tmpFileList.push({
        id: Math.random().toString(16).slice(2),
        type: chatFileTypeByFileExtension(fileExt),
        content: "",
        fileName: fileName,
        fileData: await convertFileToBase64(file),
        sendToUserId: chatUserId,
        replyAgainstMessageId: replyAgainstId,
      });
    }

    fileInputRef.current.value = null;

    if (tmpFileList.length > 0) {
      setUploadedFileList(tmpFileList);
      setShowFilePreview(true);
      setSelectedUploadFile(tmpFileList[0]);
      setRefreshCount((r) => r + 1);
      const timer = setTimeout(() => {
        fileUploadMessageInputRef.current.focus();
        clearTimeout(timer);
      }, 500);
    }
  };
  // handle file upload end

  //handle file upload by drop start
  const handleFileDrop = (e) => {
    e.preventDefault();
    const fileList = e.dataTransfer.files;
    handleFileChange(fileList);
  };
  //handle file upload by drop end

  // handle remove file from list start
  const handleRemoveFile = (file) => {
    const tmpList = uploadedFileList.filter((x) => x != file);
    if (tmpList.length > 0) {
      setSelectedUploadFile(tmpList[0]);
      setUploadedFileList(tmpList);
      setRefreshCount((r) => r + 1);
    } else {
      setSelectedUploadFile(null);
      setUploadedFileList([]);
      setShowFilePreview(false);
    }
  };
  // handle remove file from list end

  // handle send message start
  const handleSendMessage = async () => {
    if (inputText !== "") {
      const { chatId, chatUserId } = selectedContact;
      const { userId, orgId, userName } = loginUser;

      let replyAgainstId = 0;
      let replyAgainstType = "";
      let replyAgainstContent = "";
      let replyAgainstFileUrl = "";

      if (Object.keys(replyObject).length > 0) {
        const { messageId, type, content, fileUrl } = replyObject;
        replyAgainstId = messageId;
        replyAgainstType = type;
        replyAgainstContent = content;
        replyAgainstFileUrl = fileUrl;
      }

      const reqDto = {
        type: "text",
        content: inputText,
        fileName: "",
        fileData: "",
        sendToUserId: chatUserId,
        replyAgainstMessageId: replyAgainstId,
      };

      await sendMessage(chatId, userId, orgId, reqDto).then((response) => {
        if (response.data != null) {
          const { messageId, chatId, fileUrl } = response.data;

          if (messageId > 0) {
            setInputText("");
            setShowEmojiInput(false);
            let messageObj = {
              ...reqDto,
              chatId,
              senderUserName: userName,
              senderUserId: userId,
              messageId: messageId,
              fileUrl: fileUrl,
              sentTime: new Date(),
              deleted: "N",
              replyAgainstId,
              replyAgainstType,
              replyAgainstContent,
              replyAgainstFileUrl,
            };
            if (selectedContact.chatType === "G") {
              messageObj = {
                ...messageObj,
                chatType: selectedContact.chatType,
                chatName: selectedContact.chatName,
              };
            }
            setMessageList((prev) => [...prev, messageObj]);
            _handleSendMessageToSocket(messageObj);
            setReplyObject({});
            refreshContacts();
            setTimeout(() => _scrollToBottom(), 100);
            selectedContact.unreadChatCount = 0;
            setRefreshCount((r) => r + 1);
          }
        }
      });
    } else {
      toast.error(t("please_type_message"));
    }
  };
  // handle send message end

  // handle comment on file start
  const handleCommentFile = (e) => {
    const val = e.target.value;
    const index = uploadedFileList.indexOf(selectedUploadFile);
    selectedUploadFile.content = val;
    uploadedFileList[index].content = val;
    setRefreshCount((r) => r + 1);
  };
  // handle comment on file end

  // handle submit file start
  const handleSubmitFile = async () => {
    const { chatId } = selectedContact;
    const { userId, orgId, userName } = loginUser;

    let replyAgainstId = 0;
    let replyAgainstType = "";
    let replyAgainstContent = "";
    let replyAgainstFileUrl = "";

    if (Object.keys(replyObject).length > 0) {
      const { messageId, type, content, fileUrl } = replyObject;
      replyAgainstId = messageId;
      replyAgainstType = type;
      replyAgainstContent = content;
      replyAgainstFileUrl = fileUrl;
    }

    let uploadCount = 0;

    // setFileUploading(true);

    for (let index = 0; index < uploadedFileList.length; index++) {
      const element = uploadedFileList[index];
      element.content = emojiInputText;
      await sendMessage(chatId, userId, orgId, element).then((response) => {
        if (response.data != null) {
          const { messageId, chatId, fileUrl } = response.data;
          if (messageId > 0) {
            uploadCount = uploadCount + 1;
            const messageObj = {
              ...element,
              chatId,
              senderUserName: userName,
              senderUserId: userId,
              messageId: messageId,
              sentTime: new Date(),
              fileUrl: fileUrl,
              deleted: "N",
              fileAvailable: "N",
              replyAgainstId,
              replyAgainstType,
              replyAgainstContent,
              replyAgainstFileUrl,
            };
            setMessageList((prev) => [...prev, messageObj]);
            _handleSendMessageToSocket(messageObj);
            setReplyObject({});
            refreshContacts();
            setTimeout(() => _scrollToBottom(), 100);
            selectedContact.unreadChatCount = 0;
            setRefreshCount((r) => r + 1);
          }
        }
      });
    }

    setEmojiInputText("");
    setShowFileEmojiInput(false);
    setShowFilePreview(false);
    setTimeout(() => _scrollToBottom(), 100);

    if (uploadCount === uploadedFileList.length) {
      setSelectedUploadFile(null);
      setUploadedFileList([]);
      refreshContacts();
      setReplyObject({});
    }
    // setFileUploading(false);
  };
  // handle submit file end

  // scroll to the last of the list start
  const _scrollToBottom = () => {
    // behavior === "auto" / "smooth"
    if (lastChatPositionRef.current) {
      lastChatPositionRef.current.scrollIntoView({
        behavior: "auto",
        block: "end",
      });
    }
  };
  // scroll to the last of the list end

  // handle scroll chat container start
  const handleScrollChatContainer = () => {
    const container = firstChatPositionRef.current;
    if (container != null) {
      const { scrollHeight, scrollTop, clientHeight } = container;
      const isAtTop = container.scrollTop === 0;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 1;

      if (isAtTop) {
        _scrollContainerTop();
      }

      if (isAtBottom) {
        _scrollContainerBottom();
      }
    }
  };
  // handle scroll chat container end

  // handle refresh data on scroll to top start
  const _scrollContainerTop = async () => {
    if (messageList && messageList.length > 0) {
      const messageId = messageList[0].messageId;
      const { chatId } = selectedContact;

      if (!isAllDataLoaded) {
        setMoreDataLoading(true);
        await getChatMessages(loginUser.userId, chatId, 0, messageId).then(
          (response) => {
            if (response.data != null) {
              if (response.data.length > 0) {
                const temp = [...response.data, ...messageList];
                setMessageList(temp);
              } else {
                setIsAllDataLoaded(true);
              }
            }
          }
        );
        setMoreDataLoading(false);
      }
    }
  };
  // handle refresh data on scroll to top end

  // handle read all unread on scroll to bottom start
  const _scrollContainerBottom = async () => {
    if (selectedContact.chatId > 0 && selectedContact.unreadChatCount > 0) {
      readMessage(selectedContact.chatId, loginUser.userId).then((res) => {
        if (res.data != null) {
          // selectedContact = { ...selectedContact, unreadChatCount: 0 };
          refreshContacts();
          if (!scrollAtBottom) {
            setSelectedContact(selectedContact);
          }
        }
      });
    }
    setScrollAtBottom(true);
  };
  // handle read all unread on scroll to bottom end

  // start recording start
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = async (e) => {
        if (e.data.size > 0) {
          const audioBlob = new Blob([e.data], {
            type: "audio/mp3",
          });
          if (audioBlob.size > 0) {
            const base64 = await convertBlobToBase64(audioBlob);
            _handleSubmitVoiceMessage(base64);
          } else {
            toast.error(t("no_voice_recorded"));
          }
        }
      };

      recorder.start();

      setRecording(true);
      setAudioStream(stream);
      setMediaRecorder(recorder);
    } catch (e) {
      console.error("Error to open", e);
    }
  };
  // start recording end

  // stop recording start
  const stopRecording = async () => {
    if (mediaRecorder && recording) {
      await mediaRecorder.stop();
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
      }
      setAudioStream(null);
      setRecording(false);
    }
  };
  // stop recording end

  // handle submit voice message start
  const _handleSubmitVoiceMessage = async (fileData) => {
    if (fileData !== "") {
      const { chatId, chatUserId } = selectedContact;
      const { userId, orgId } = loginUser;

      let replyAgainstId = 0;
      let replyAgainstType = "";
      let replyAgainstContent = "";
      let replyAgainstFileUrl = "";

      if (Object.keys(replyObject).length > 0) {
        const { messageId, type, content, fileUrl } = replyObject;
        replyAgainstId = messageId;
        replyAgainstType = type;
        replyAgainstContent = content;
        replyAgainstFileUrl = fileUrl;
      }

      const reqDto = {
        type: "audio",
        content: "",
        fileName: `${Math.random().toString(16).slice(2)}.mp3`,
        fileData: fileData,
        sendToUserId: chatUserId,
        replyAgainstMessageId: 0,
      };

      await sendMessage(chatId, userId, orgId, reqDto).then((response) => {
        if (response.data != null) {
          const { messageId, chatId, fileUrl } = response.data;
          if (messageId > 0) {
            const messageObj = {
              ...reqDto,
              chatId,
              senderUserId: userId,
              messageId: messageId,
              fileUrl: fileUrl,
              sentTime: new Date(),
              deleted: "N",
              replyAgainstId,
              replyAgainstType,
              replyAgainstContent,
              replyAgainstFileUrl,
            };
            setMessageList((prev) => [...prev, messageObj]);
            _handleSendMessageToSocket(messageObj);
            setReplyObject({});
            refreshContacts();
            setTimeout(() => _scrollToBottom(), 100);
            selectedContact.unreadChatCount = 0;
            setRefreshCount((r) => r + 1);
          }
        }
      });
    }
  };
  // handle submit voice message end

  //  handle check uncheck message id for forward start
  const handleCheckUncheckChat = (msg, checked) => {
    if (selectedMessageList.includes(msg)) {
      const temp = selectedMessageList.filter(
        (message) => message.messageId !== msg.messageId
      );
      setSelectedMessageList(temp);
    } else {
      setSelectedMessageList([...selectedMessageList, msg]);
    }
  };
  //  handle check uncheck message id for forward end

  // handle delete message start
  const handleDeleteMessage = async () => {
    const { messageId } = JSON.parse(chatMenuAnchor.id);
    await deleteMessage(messageId).then((response) => {
      if (response.data != null) {
        if (response.data > 0) {
          const index = messageList.findIndex(
            (val) => val.messageId === messageId
          );
          if (index !== -1) {
            messageList[index].content = MESSAGE_DELETED;
            messageList[index].deleted = "Y";
            setRefreshCount((r) => r + 1);
            refreshContacts();
            _handleDeleteMsgBySocket(messageId);
          }
          setChatMenuAnchor(null);
        }
      }
    });
  };
  // handle delete message end

  //  handle download file start
  const handleDownloadFile = () => {
    const { fileUrl, fileName } = JSON.parse(chatMenuAnchor.id);
    console.log(fileUrl, fileName);
    let orgFileName = "";
    if (fileName === "") {
      const urlName = fileUrl.split("/").pop();
      orgFileName = urlName;
    } else {
      orgFileName = fileName;
    }
    downloadFileFromData(
      fileUrl.split("/")[4],
      fileUrl.split("/")[3],
      orgFileName
    );
  };
  //  handle download file end

  // return user name by user id start
  const renderGroupUserName = (userId) => {
    if (groupChatUserList && groupChatUserList.length > 0) {
      const user = groupChatUserList.find((val) => val.userId === userId);
      if (user != null) {
        return user.userName;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  // return user name by user id end

  // handle send message to socket start
  const _handleSendMessageToSocket = (messageObj) => {
    let userList = [];

    if (selectedContact.chatType === "G") {
      for (let index = 0; index < groupChatUserList.length; index++) {
        const { activeFlag, userId } = groupChatUserList[index];
        if (activeFlag === "Y") {
          userList.push(userId);
        }
      }
    } else {
      userList.push(selectedContact.chatUserId);
    }

    chatSocket.emit("sendChat", messageObj, userList);
  };
  // handle send message to socket end

  // handle delete socket message start
  const _handleDeleteMsgBySocket = (messageId) => {
    const data = {
      chatId: selectedContact.chatId,
      messageId,
    };

    let userList = [];
    if (selectedContact.chatType === "G") {
      for (let index = 0; index < groupChatUserList.length; index++) {
        const { userId } = groupChatUserList[index];
        userList.push(userId);
      }
    } else {
      userList.push(selectedContact.chatUserId);
    }

    chatSocket.emit("requestDeleteChat", data, userList);
  };
  // handle delete socket message end

  // render message listing reply against start
  const renderReplyWhenListing = (msg) => {
    if (msg != null) {
      switch (msg.replyAgainstType) {
        case "text":
          return <div class="chtRplyMsgInfo">{msg.replyAgainstContent}</div>;

        case "photo":
          return (
            <div className="chtRplyImg">
              <img alt="" src={msg.replyAgainstFileUrl} />
            </div>
          );

        case "document":
          return (
            <div className="chtRplyImg">
              <img alt="" src={noPriview} />
            </div>
          );

        case "audio":
          return (
            <div className="chtRplyImg">
              <div className="msgMedia">
                <audio controls>
                  <source src={msg.replyAgainstFileUrl} type="audio/mp3" />
                </audio>
              </div>
            </div>
          );

        default:
          return "";
      }
    }
  };
  // render message listing reply against end

  const isBetween = (targetDate, startDate, endDate) => {
    const targetTime = new Date(targetDate).getTime();
    const startTime = new Date(startDate).getTime();
    const endTime = new Date(endDate).getTime();
    return targetTime >= startTime && targetTime <= endTime;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    handleFileChange(files);
  };

  return (
    <>
      <div className="chatConversationPannel">
        <div
          className={`chatConvoContainer ${
            showForwardUser || showGroupInfo ? `hideWhenUseInDashboard` : ``
          }`}
        >
          <ConversationHeader
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
            groupChatUserList={groupChatUserList}
            showGroupInfoIcon={showInputs}
            setShowGroupInfo={() => setShowGroupInfo(true)}
            online={onlineUserList.includes(selectedContact.chatUserId)}
            closeFromDashboard={closeFromDashboard}
          />

          {dataLoading && <LoaderSmall />}

          {/* conversation listing start */}
          {!showFilePreview && (
            <div className={`chatConvstionConArea`} 
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            >
              <div
                className="chatConvoMsg"
                ref={firstChatPositionRef}
                onScroll={handleScrollChatContainer}
              >
                <div className="topMtChtAra"></div>

                {messageList && messageList.length > 0 && (
                  <>
                    <ReactLinkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a target="blank" href={decoratedHref} key={key}>
                          {decoratedText}
                        </a>
                      )}
                    >
                      {moreDataLoading && (
                        <div className="topLoader">
                          <CircularProgress />
                        </div>
                      )}
                      {refreshCount > 0 &&
                        messageList.map((msg, index) => (
                          <>
                            <div
                              key={"msgID_" + msg.messageId}
                              className={`chtMsg ${
                                isForward
                                  ? `selectFrdMsg ${
                                      selectedMessageList.includes(msg)
                                        ? `seletedFrdMsg`
                                        : ``
                                    }`
                                  : ``
                              }`}
                            >
                              {isForward && msg.deleted !== "Y" && (
                                <div className="chtForwrdCheck">
                                  <Checkbox
                                    onClick={(e) => {
                                      handleCheckUncheckChat(
                                        msg,
                                        e.target.checked
                                      );
                                    }}
                                  />
                                </div>
                              )}

                              <div
                                className={`msg ${
                                  msg.senderUserId === loginUser.userId
                                    ? `msg-out`
                                    : `msg-in`
                                }
                            ${msg.deleted === "Y" ? `delMsg` : ``}
                            `}
                              >
                                {/* click open chat menu  start */}
                                {refreshCount > 0 && msg.deleted !== "Y" && (
                                  <div
                                    className="chtCovAct"
                                    id={JSON.stringify(msg)}
                                    onClick={(e) =>
                                      setChatMenuAnchor(e.currentTarget)
                                    }
                                  >
                                    <KeyboardArrowDown />
                                  </div>
                                )}
                                {/* click open reply menu end */}

                                {/* render user name in group chat start */}
                                {selectedContact.chatType === "G" &&
                                  msg.senderUserId !== loginUser.userId && (
                                    <div className="gMsgSndNm">
                                      {renderGroupUserName(msg.senderUserId)}
                                    </div>
                                  )}
                                {/* render user name in group chat end */}

                                {msg.deleted === "Y" ? (
                                  <div className="msgTxt">
                                    {" "}
                                    {MESSAGE_DELETED}
                                  </div>
                                ) : (
                                  <>
                                    {/*  reply message against start*/}
                                    {msg.replyAgainstId > 0 && (
                                      <div class="chtRplyMsgDtlGrp">
                                        <div class="chtRplyTxt">
                                          {renderReplyWhenListing(msg)}
                                        </div>
                                      </div>
                                    )}
                                    {/*  reply message against end*/}

                                    {/* render if chat is forwarded chat start */}
                                    {msg.forwarded === "Y" && (
                                      <div class="chtForwarded">{t("forwarded")}</div>
                                    )}

                                    {msg.type === "text" && (
                                      <div className="msgTxt">
                                        {msg.content}
                                      </div>
                                    )}

                                    {msg.type === "photo" && (
                                      <div className="msgMedia">
                                        <img
                                          src={
                                            msg.fileAvailable === "Y"
                                              ? msg.fileUrl
                                              : "https://ahlan-s3.s3.me-south-1.amazonaws.com/images/uploadingChatImage.png"
                                          }
                                          alt=""
                                        />
                                        <div className="msgMediaTxt">
                                          {msg.content}
                                        </div>
                                      </div>
                                    )}

                                    {msg.type === "document" && (
                                      <div className="msgMedia">
                                        <img
                                          src={
                                            msg.fileAvailable === "Y"
                                              ? noPriview
                                              : "https://ahlan-s3.s3.me-south-1.amazonaws.com/images/uploadingChatImage.png"
                                          }
                                          alt=""
                                        />
                                        <div className="msgMediaTxt">
                                          {msg.content}
                                        </div>
                                      </div>
                                    )}

                                    {msg.type === "audio" && (
                                      <>
                                        <div className="msgAudio">
                                          <audio
                                            src={msg.fileUrl}
                                            controls
                                            type="audio/mp3"
                                          />
                                          {/* <audio controls>
                                          <source
                                            src="https://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Kangaroo_MusiQue_-_The_Neverwritten_Role_Playing_Game.mp3"
                                            type="audio/mp3"
                                          />
                                        </audio> */}

                                          {/* <audio controls>
                                          <source
                                            src={msg.fileUrl}
                                            type="audio/mp3"
                                          />
                                        </audio> */}
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}

                                <div className="msgRDt">
                                  <div className="msgDT">
                                    {dateIsLessThanToday(msg.sentTime)
                                      ? getDateInDDMMYYAMPM(msg.sentTime)
                                      : getDateInAMPM(msg.sentTime)}
                                  </div>

                                  {/* <div className="msgRcpt">
                                <DoubleCheck />
                              </div> */}
                                </div>
                              </div>
                            </div>

                            {/* render at the end of last message start */}

                            {selectedContact.unreadChatCount > 0 ? (
                              <>
                                {messageList.length - unreadMessageCount ===
                                  index + 1 && (
                                  <div
                                    className="unread-count"
                                    // ref={lastChatPositionRef}
                                  >
                                    {t("unread")}
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {index + 1 === messageList.length && (
                                  <div
                                  // ref={lastChatPositionRef}
                                  />
                                )}
                              </>
                            )}
                            <div ref={lastChatPositionRef} />
                            {/* render at the end of last message end */}

                            {/* chat menu start */}
                            <Menu
                              className="chtCovActMenu"
                              anchorEl={chatMenuAnchor}
                              open={Boolean(chatMenuAnchor)}
                              onClose={() => setChatMenuAnchor(null)}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  setReplyObject(JSON.parse(chatMenuAnchor.id));
                                  setIsForward(false);
                                  setChatMenuAnchor(null);
                                }}
                              >
                                {t("reply")}
                              </MenuItem>

                              <MenuItem
                                onClick={() => {
                                  setIsForward(true);
                                  setChatMenuAnchor(null);
                                  setReplyObject({});
                                }}
                              >
                                {t("forward")}
                              </MenuItem>

                              {chatMenuAnchor != null &&
                                JSON.parse(chatMenuAnchor.id).deleted ===
                                  "N" && (
                                  <MenuItem
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        JSON.parse(chatMenuAnchor.id).content
                                      );
                                      setChatMenuAnchor(null);
                                    }}
                                  >
                                    {t("copy")}
                                  </MenuItem>
                                )}

                              {chatMenuAnchor != null &&
                                JSON.parse(chatMenuAnchor.id).senderUserId ===
                                  loginUser.userId && (
                                  <MenuItem onClick={handleDeleteMessage}>
                                    {t("delete")}
                                  </MenuItem>
                                )}

                              {chatMenuAnchor != null &&
                                JSON.parse(chatMenuAnchor.id).type !==
                                  "text" && (
                                  <MenuItem onClick={handleDownloadFile}>
                                    {t("download")}
                                  </MenuItem>
                                )}

                              {/* <MenuItem onClick={() => setChatMenuAnchor(null)}>
                                Reply Privately
                              </MenuItem> */}
                            </Menu>
                            {/* chat menu end */}
                          </>
                        ))}
                    </ReactLinkify>
                  </>
                )}

                {showEmojiInput && (
                  <div className="chatEmoji">
                    <EmojiPicker
                      className="chatEmojiPicker"
                      onEmojiClick={({ emoji }) => {
                        setInputText((prev) => `${prev}${emoji}`);
                        externalMessageInputRef.current.focus();
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {/* conversation listing end */}

          {/* chat input footer start */}
          {!showFilePreview && showInputs && (
            <>
              {!isForward ? (
                <div
                  className={`chatConvoFooter ${
                    Object.keys(replyObject).length > 0
                      ? `chatConvoRplyFoot`
                      : ``
                  } `}
                >
                  {Object.keys(replyObject).length > 0 && (
                    <div className="chtReplyInpt">
                      <div className="chtReplyInptGrp">
                        <div className="chtRplyMsgDtlGrp">
                          <div className="chtRplyTxt">
                            {selectedContact.chatType === "G" && (
                              <div className="chtRplyMsgUsrNm">
                                {renderGroupUserName(replyObject.senderUserId)}
                              </div>
                            )}

                            {replyObject.type === "audio" && (
                              <div className="msgMedia">
                                <audio controls>
                                  <source
                                    src={replyObject.fileUrl}
                                    type="audio/mp3"
                                  />
                                </audio>
                              </div>
                            )}

                            <div className="chtRplyMsgInfo">
                              {replyObject.content}
                            </div>
                          </div>

                          {replyObject.type === "photo" && (
                            <div className="chtRplyImg">
                              <img alt="Reply" src={replyObject.fileUrl} />
                            </div>
                          )}

                          {replyObject.type === "document" && (
                            <div className="chtRplyImg">
                              <img alt="Reply" src={noPriview} />
                            </div>
                          )}
                        </div>

                        <div className="chtRplyMsgDtlClsBtn">
                          <IconButton
                            aria-label="dismiss Replay"
                            onClick={() => setReplyObject({})}
                          >
                            <Close />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="chatRegularFoot">
                    {/* emoji picker button icon start */}
                    <Tooltip title={t("insert_emoji")}>
                      <div
                        className="chatInputEmoje"
                        onClick={(e) => {
                          setShowEmojiInput((r) => !r);
                        }}
                      >
                        <SentimentVerySatisfied />
                      </div>
                    </Tooltip>
                    {/* emoji picker button icon end */}

                    {/* add attachments button icon start */}
                    <CoustomTooltip
                      title={
                        <>
                          <div className="uploadedFilesTooltip">
                            <div>
                             {t("allowed_file_types")}:{" "}
                              <div>
                                {VALID_FILE_EXTENSION_CHAT.join(",") + "..."}
                              </div>
                            </div>
                            {/* <div>Maximum file size {MAX_FILE_SIZE_CHAT} MB</div> */}
                            <div>{t("maximum_file_size", { maxFileSize: MAX_FILE_SIZE_CHAT })}</div>
                          </div>
                        </>
                      }
                    >
                      <div
                        className="chatInputAttachment"
                        onClick={() => fileInputRef.current.click()}
                      >
                        <Attachment color="white" />
                      </div>
                    </CoustomTooltip>
                    {/* add attachments button icon end */}

                    {/* input box chat text start  */}
                    <div className="chatInputBox">
                      <input
                        type="text"
                        placeholder={t("type_your_message_here")}
                        value={inputText}
                        onChange={(e) => {
                          // setEmojiInputText(e.target.value);
                          setInputText(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key.toLowerCase() === "enter") {
                            e.preventDefault();
                            handleSendMessage();
                          }
                        }}
                        ref={externalMessageInputRef}
                      />
                    </div>
                    {/* input box chat text end  */}

                    {/* send icon start */}
                    <div className="chatInputAudio">
                      {inputText !== "" ? (
                        <Send
                          height={24}
                          width={24}
                          color="white"
                          onClick={handleSendMessage}
                        />
                      ) : (
                        <>
                          {recording ? (
                            <Tooltip title={t("click_to_stop")}>
                              <MicrophoneSpeaking
                                height={24}
                                width={24}
                                color="blue"
                                onClick={stopRecording}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title={t("click_to_start")}>
                              <Microphone
                                height={24}
                                width={24}
                                color="white"
                                onClick={startRecording}
                              />
                            </Tooltip>
                          )}
                        </>
                      )}
                    </div>
                    {/* send icon end */}
                  </div>
                </div>
              ) : (
                <div className={`chatConvoFooter`}>
                  {/* cancel forward start */}
                  <div className="clsFrwBtn">
                    <IconButton
                      aria-label="Forward"
                      onClick={() => {
                        setIsForward(false);
                        setShowForwardUser(false);
                        setSelectedMessageList([]);
                      }}
                    >
                      <Xmark />
                    </IconButton>
                  </div>
                  {/* cancel forward end */}

                  {/* selected chat count start */}
                  {/* <div className="frwSlctMsgCount">
                    <span>
                      {selectedMessageList && selectedMessageList.length > 0
                        ? `${selectedMessageList.length} Selected`
                        : ``}
                    </span>
                  </div> */}
                   <div className="frwSlctMsgCount">
                   <span>
                   {selectedMessageList && selectedMessageList.length > 0
                   ? t("selected_message_count", { count: selectedMessageList.length })
                   : ``}
                   </span>
                   </div>
                  {/* selected chat count end */}

                  {/* show user list button  start */}
                  <div className="msgFrwdBtn">
                    <IconButton
                      aria-label="Forward"
                      onClick={() => {
                        if (selectedMessageList.length > 0) {
                          setShowForwardUser(true);
                        }
                      }}
                    >
                      <ArrowEmailForward />
                    </IconButton>
                  </div>
                  {/* show user list button  end */}
                </div>
              )}
            </>
          )}
          {/* chat input footer end */}

          {/* file preview container start */}
          {showFilePreview && refreshCount > 0 && (
            <div className="chatAtchImgArea">
              <div className="chatAtchImgContainer">
                <div className="chtAtchImgContGroup">
                  {/* preview header start */}
                  <div className="imgUpDismis">
                    <div
                      className="imgDismisIoc"
                      role="button"
                      onClick={() => {
                        setShowFilePreview(false);
                        setUploadedFileList([]);
                      }}
                    >
                      <Xmark />
                    </div>
                    <div className="imgDisEdit">
                      {/*dont delete these div*/}
                    </div>
                    <div className="imgDisWdth">
                      {/*dont delete these div*/}
                    </div>
                  </div>
                  {/* preview header end */}

                  {/* preview center image start */}
                  <div className="imgUpBView">
                    {selectedUploadFile && (
                      <>
                        {selectedUploadFile.type === "photo" ? (
                          <div className="upImgBigView">
                            <img
                              alt="Preview Image"
                              src={`data:image/png;base64,${selectedUploadFile.fileData}`}
                            />
                          </div>
                        ) : (
                          <div className="noPriviewDoc">
                            <img
                              src={noPriview}
                              alt="Preview Image"
                              className="noPrevIco"
                            />
                            <div className="noPrevText">
                            {t("no_preview_available")}
                            </div>
                            <div className="noPrevFlNam">
                              {selectedUploadFile.fileName} <span></span>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {/* preview center image end */}

                  {/* preview add comment start */}
                  <div className="imgComment">
                    <Tooltip title="Insert Emoji">
                      <div
                        className="chatInputEmoje"
                        onClick={(e) => {
                          setShowFileEmojiInput((r) => !r);
                          // setShowEmojiInput((r) => !r);
                        }}
                      >
                        <SentimentVerySatisfied />
                      </div>
                    </Tooltip>
                    <div class="chatInputBox">
                      <input
                        type="text"
                        placeholder={t("type_your_message_here")}
                        value={emojiInputText}
                        onChange={(e) => {
                          // setInputText(e.target.value);
                          setEmojiInputText(e.target.value);
                          handleCommentFile(e);
                        }}
                        onKeyDown={(e) => {
                          if (e.key.toLowerCase() === "enter") {
                            e.preventDefault();
                            handleSubmitFile();
                          }
                        }}
                        ref={fileUploadMessageInputRef}
                      />
                    </div>
                  </div>
                  {/* preview add comment end */}
                </div>

                {/* preview footer start */}
                <div className="chatAtchImgActionGrp">
                  <div className="upldFlSmalPerview">
                    {uploadedFileList.map((file) => (
                      <>
                        <div className="upldFlImgPrev ">
                          <div
                            className={`atchImgPlcHld ${
                              selectedUploadFile.id === file.id &&
                              `atchImgPHActive`
                            }`}
                          >
                            {/* image remove button start */}
                            <div
                              className="remvImg"
                              role="button"
                              onClick={() => handleRemoveFile(file)}
                            >
                              <Xmark />
                            </div>
                            {/* image remove button end */}

                            <div
                              className="upledImgShow"
                              onClick={() => setSelectedUploadFile(file)}
                            >
                              <img
                                alt=""
                                src={
                                  file.type === "photo"
                                    ? `data:image/png;base64,${file.fileData}`
                                    : noPriview
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ))}

                    <Tooltip title="Add More Documents">
                      <Button
                        className="uplodMorImg"
                        onClick={() => fileInputRef.current.click()}
                      >
                        <Plus />
                      </Button>
                    </Tooltip>
                    <div className="dropToUupledImg" onDrop={handleFileDrop}>
                    {t("drop_files_here")}
                    </div>
                  </div>

                  <div className="uploadImgSendBtn">
                    <Tooltip title="Send Documents">
                      <div
                        className="imgSendBtn"
                        role="button"
                        onClick={() => handleSubmitFile()}
                      >
                        <Send />
                      </div>
                    </Tooltip>
                  </div>
                </div>
                {/* preview footer end */}

                {/* <div className="uploadedFiles">
                  <div>
                    Allowed File Types:{" "}
                    <div>{VALID_FILE_EXTENSION_CHAT.join(",") + "..."}</div>
                  </div>
                  <div>Maximum file size {MAX_FILE_SIZE_CHAT} MB</div>
                </div> */}
              </div>
              {showFileEmojiInput && (
                <div className="chatEmoji">
                  <EmojiPicker
                    className="chatEmojiPicker"
                    onEmojiClick={({ emoji }) => {
                      setEmojiInputText((prev) => `${prev}${emoji}`);
                      fileUploadMessageInputRef.current.focus();
                    }}
                  />
                </div>
              )}
            </div>
          )}
          {/* file preview container end */}

          {/* file input start */}
          <input
            ref={fileInputRef}
            type="file"
            multiple
            hidden
            onChange={(e) => {
              const files = e.target.files;
              handleFileChange(files);
            }}
          />
          {/* file input end */}
        </div>

        {showGroupInfo && (
          <ChatGroupDetails
            selectedContact={selectedContact}
            setShowGroupInfo={() => {
              setShowGroupInfo(false);
              refreshContacts();
            }}
            groupDetails={selectedContact}
          />
        )}

        {showForwardUser && (
          <ForwardUserList
            selectedMessageList={selectedMessageList}
            selectedChatId={selectedContact ? selectedContact.chatId : 0}
            onClickClose={() => setShowForwardUser(false)}
            onSuccess={() => {
              setSelectedMessageList([]);
              setIsForward(false);
              setShowForwardUser(false);
              refreshContacts();
            }}
          />
        )}
      </div>
    </>
  );
}
