import React from "react";
import { getDateInDDMMYYAMPM } from "../../../../../utils/Utils";

export default function MessageCard({ data, active, onClick }) {
  const handleClick = () => {
    const chatObj = {
      ...data,
      chatCreatedBy: "",
      chatCreatedTime: data?.createdTime,
      chatId: data?.chatId,
      chatName: data?.chatName,
      chatProfileUrl: data?.chatProfileUrl,
      chatType: data?.chatType,
      chatUnseenSentByMe: "N",
      chatUserId: null,
      lastMessageContent: data?.chatMessageContent,
      lastMessageDeleted: "N",
      lastMessageFileName: "",
      lastMessageId: data?.chatMessageId,
      lastMessageSentBy: "AHL000000000620",
      lastMessageTime: "",
      lastMessageType: data?.chatMessageType,
      lastSeenTime: null,
      unreadChatCount: data?.chatMessageUnreadCount,
    };
    onClick(chatObj);
  };

  return (
    <>
      <div
        className={`msgCardContainer ${active ? `activeContainer` : ``}`}
        onClick={handleClick}
      >
        <div className="contMxWidthLim">
          <div className="msgInOutIoc">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="24"
              viewBox="0 0 27 24"
              fill="none"
            >
              <path
                d="M22.95 0H4.05C1.81642 0 0 1.79595 0 4.00434V15.2745C0 17.4829 1.81642 19.2789 4.05 19.2789H9.7281L11.8375 23.0323C12.1777 23.6383 12.7993 24 13.5 24C14.2007 24 14.823 23.6383 15.1632 23.0323L17.2726 19.2789H22.95C25.1836 19.2789 27 17.4829 27 15.2745V4.00434C27 1.79595 25.1836 0 22.95 0ZM5.4 11.3456C4.28153 11.3456 3.375 10.4493 3.375 9.34346C3.375 8.23759 4.28153 7.34129 5.4 7.34129C6.51848 7.34129 7.425 8.23759 7.425 9.34346C7.425 10.4493 6.51848 11.3456 5.4 11.3456ZM13.5 11.3456C12.3815 11.3456 11.475 10.4493 11.475 9.34346C11.475 8.23759 12.3815 7.34129 13.5 7.34129C14.6185 7.34129 15.525 8.23759 15.525 9.34346C15.525 10.4493 14.6185 11.3456 13.5 11.3456ZM21.6 11.3456C20.4815 11.3456 19.575 10.4493 19.575 9.34346C19.575 8.23759 20.4815 7.34129 21.6 7.34129C22.7185 7.34129 23.625 8.23759 23.625 9.34346C23.625 10.4493 22.7185 11.3456 21.6 11.3456Z"
                fill="white"
              />
            </svg>
          </div>

          <div className="msgInfoDtl">
            <div className="msgSunderImg">
              <img src={data?.chatProfileUrl} alt="Profile User" />
            </div>

            <div className="msgSendDtl">
              <div className="msgSendNmGrp">
                <div className="senderName">{data?.chatName}</div>
              </div>

              <div className="sendTime">
                {getDateInDDMMYYAMPM(data?.createdTime)}
              </div>

              <div className="msgSendCommnt">{data?.chatMessageContent}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
