import { HeartPulse, Lungs, ThermometerHalf } from "react-bootstrap-icons";
import { getLoginUserDetails } from "../../../../../../../utils/Utils";
import BP from "../../../../../../dashboard/image/BP";
import SPO2 from "../../../../../../dashboard/image/SPO2";
import HeadC from "../../../../../../dashboard/image/HeadC";
import { HeightOutlined, MonitorWeightOutlined } from "@mui/icons-material";
import UAC from "../../../../../../dashboard/image/UAC";
import LMP from "../../../../../../dashboard/image/LMP";

export const MAX_SUGGESTED_RESULT = 30;

export const getFilteredList = (data, str, field) => {
  const searchString = "" + str;
  if (searchString === "" || !searchString) {
    return data?.slice(0, MAX_SUGGESTED_RESULT) || [];
  }
  const startsWithList = [];
  const containsList = [];

  data?.forEach((element) => {
    if (element[field].toLowerCase().startsWith(searchString.toLowerCase())) {
      startsWithList.push(element);
    } else if (
      element[field].toLowerCase().includes(searchString.toLowerCase())
    ) {
      containsList.push(element);
    }
  });
  return [...startsWithList, ...containsList];
};

export const getHealthDto = () => {
  const loginUserDetails = getLoginUserDetails();
  return {
    // orgId: "ORG000000000110",
    orgId: loginUserDetails.orgId,
    countryCode: loginUserDetails.orgCountryCode,
    gender: "",
  };
};

export const getVitalSvgAccordingToVitalType = (vitalType) => {
  if (vitalType === "BP") {
    return <BP />;
  } else if (vitalType === "HEART_RATE") {
    return <HeartPulse />;
  } else if (vitalType === "SPO2") {
    return <SPO2 />;
  } else if (vitalType === "TEMPERATURE") {
    return <ThermometerHalf />;
  } else if (vitalType === "RESPIRATION_RATE") {
    return <Lungs />;
  } else if (vitalType === "WEIGHT") {
    return <MonitorWeightOutlined />;
  } else if (vitalType === "HEIGHT") {
    return <HeightOutlined />;
  } else if (vitalType === "HEAD_CIRCUMFERENCE") {
    return <HeadC />;
  } else if (vitalType === "UPPER_ARM_CIRCUMFERENCE") {
    return <UAC />;
  } else if (vitalType === "LMP") {
    return <LMP />;
  }
};
