import { Add, Close, FormatListBulleted } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  responsiveFontSizes,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Bookmark, Trash, XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import DashboardServices from "../../../../../services/DashboardServices";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import { toast } from "react-toastify";
import AddCompound from "./AddCompound";
import ListAgeGroupModel from "./ListAgeGroupModel";
import { v4 as uuidv4 } from "uuid";
import { CompoundItem } from "./CompoundItem";
import { CompoundComponent } from "./CompoundComponent";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import zhPatientService from "../../../../../services/ZhPatientService";

export default function EditStp({ handelCloseEditStp, editStpData }) {
  const loginUserDetails = getLoginUserDetails();
  const { t } = useTranslation();
  const [selectDiseas, setSelectDiseas] = useState("");
  const [invAplyGender, setInvAplyGenders] = useState("");
  const [selectInvestigation, setSelectInvestigation] = useState("");
  const [listAgeGroup, setListAgeGroup] = useState(false);

  const [selectRoute, setSelectRoute] = useState("");
  const [selectAgeGroup, setSelectAgeGroup] = useState("");

  const [selectParameter, setSelectParameter] = useState("");
  const [selectLifestyle, setSelectLifestyle] = useState("");
  const [selectProcedure, setSelectProcedure] = useState("");
  const [diseaseList, setDiseaseList] = useState([]);
  const [investigationList, setInvestigationList] = useState([]);
  const [userDiseaseList, setUserDiseaseList] = useState([]);
  const [userInvestigationList, setUserInvestigationList] = useState([]);

  const [selectedInvestigation, setSelectedInvestigation] = useState(null);
  const [investigationSearchString, setInvestigtionSearchString] = useState("");

  const [selectedDisease, setSelectedDisease] = useState(null);
  const [diseaseInputString, setDiseaseInputString] = useState("");

  const [selectedLifeStyle, setSelectedLifeStyle] = useState(null);
  const [lifeStyleString, setLifeStyleString] = useState("");
  const [lifeStyleList, setLifeStyleList] = useState([]);
  const [recommandedLifeStyle, setRecomandedLifeStyle] = useState([]);

  const [monitorNames, setMonitorNames] = useState([]);
  const [parameterMonitor, setParameterMonitor] = useState([]);
  const [ageGroupList, setAgeGroupList] = useState(
    //   [
    //   { name: "For All", value: "" },
    //   { name: "New Born", value: "upto 3 month" },
    //   { name: "Infent", value: "3 - 12 months" },
    //   { name: "Toddler", value: "1 - 5 Years old" },
    //   { name: "Child", value: "6 - 12 Years old" },
    //   { name: "Teen", value: "13 - 19 Years old" },
    //   { name: "Adult", value: "20 - 39 Years old" },
    //   { name: "Mid Adult", value: "40 - 59 Years old" },
    //   { name: "Senior Adult", value: "60+ Years old" },
    // ]
    []
  );
  const [regime, setRegime] = useState("");
  const [medicationList, setMedicationList] = useState([]);
  const [showAddRegime, setShowAddRegime] = useState(false);
  const [currentStpIndex, setCurrentIndex] = useState(1);
  const [compoundList, setCompoundList] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [procedureString, setProcedureString] = useState("");
  const [procedureList, setProcedureList] = useState([]);
  const [selectedProcedureList, setSelectedProcedureList] = useState([]);
  const [procedureGender, setProcedureGender] = useState("");
  const [selectedParameter, setSelectedParameter] = useState(null);
  const [parameterString, setParameterString] = useState("");
  const [stpData, setSptData] = useState(null);

  const [upperAgeLimit, setUpperAgeLimit] = useState({
    year: "",
    month: "",
    day: "",
  });
  const [regimeCondition, setRegimeCondition] = useState("Y");

  const handleChangeRegimeCondition = (event) => {
    setRegimeCondition(event.target.value);
  };
  const handleCloseListAgeGroupModel = () => {
    setListAgeGroup(false);
  };

  //  const [isDoseDippentOnAge, setIsDoseDippentOnAge] = useState(false);

  // const handleDoseDippentOnAge = (event) => {
  //   setIsDoseDippentOnAge(event.target.checked);
  // };
  // const handleChangeUpperAgeLimit = (event) => {
  //   const { name, value } = event.target;
  //   setUpperAgeLimit((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  const initializeInvestigation = () => {
    setSelectedInvestigation(null);
    setInvestigtionSearchString("");
    setInvAplyGenders("");
  };

  const initializeLifeStyle = () => {
    setLifeStyleString("");
    setSelectedLifeStyle(null);
  };

  const getDiseases = () => {
    const diseasetring = localStorage.getItem("diseaseList");
    if (diseasetring) {
      setDiseaseList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getDiseases(reqDto).then((response) => {
      if (response.data) {
        setDiseaseList(response.data);
        localStorage.setItem("diseaseList", JSON.stringify(response.data));
      }
    });
  };
  const getLifeStyles = () => {
    const lifeStyleString = localStorage.getItem("lifeStyleList");
    if (lifeStyleString) {
      setLifeStyleList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = getHealthDto();
    DashboardServices.getLifeStyles(reqDto).then((response) => {
      if (response.data) {
        setLifeStyleList(response.data);
        localStorage.setItem("lifeStyleList", JSON.stringify(response.data));
      }
    });
  };

  const handleChangeparameterMonitor = (event) => {
    const {
      target: { value },
    } = event;
    setParameterMonitor(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const computeMonitors = () => {
    let investigationList = [];
    let vitalsList = [];
    if (userInvestigationList) {
      investigationList = userInvestigationList.map(
        (item) => item.investigation.testName
      );
    }
    const vitalNameMaps = {
      TEMPERATURE: "Temperature",
      BP: "Blood Pressure",
      HEART_RATE: "Heart Rate",
      RESPIRATION_RATE: "Respiration Rate",
      SPO2: "SPO2",
    };
    const vitalNames = [
      "TEMPERATURE",
      "BP",
      "HEART_RATE",
      "RESPIRATION_RATE",
      "SPO2",
    ];
    vitalsList = vitalNames.map((item) => vitalNameMaps[item]);

    setMonitorNames([...vitalsList, ...investigationList]);
  };

  const getProcedures = () => {
    const lifeStyleString = localStorage.getItem("procedureList");
    if (lifeStyleString) {
      setProcedureList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = getHealthDto();
    DashboardServices.getProcedures(reqDto).then((response) => {
      if (response.data) {
        setProcedureList(response.data);
        localStorage.setItem("procedureList", JSON.stringify(response.data));
      }
    });
  };

  useEffect(() => {
    computeMonitors();
  }, [userInvestigationList]);

  const getMedicalTestList = () => {
    const symptomString = localStorage.getItem("medicalTestList");
    if (symptomString) {
      setInvestigationList(JSON.parse(symptomString));
      return;
    }

    const reqDto = getHealthDto();

    DashboardServices.getMedicalTestList(reqDto).then((response) => {
      if (response.data) {
        setInvestigationList(response.data);
        localStorage.setItem("medicalTestList", JSON.stringify(response.data));
      }
    });
  };

  const addAndComponent = (medList, parentId, data) => {
    console.log("Hello how are you?");
    medList?.forEach((element, index) => {
      if (element.localId === parentId) {
        const temp = [...medList];
        temp[index].compound.push(data);
        temp[index].addNewCompound = false;
        setMedicationList(temp);
      }
    });
  };

  const deleteCompound = (medList, parentId, itemId) => {
    const recursiveHandler = (item, itemId) => {
      const temp = { ...item };
      if (temp.localId === itemId) {
        return [];
      } else {
        temp.compoundOrThen = "";
        const { thenList, orList } = temp;
        const thList = thenList
          ?.map((item) => {
            const tmp = recursiveHandler(item, itemId);
            return tmp;
          })
          ?.flat();
        const orlst = orList
          ?.map((item) => {
            const tmp = recursiveHandler(item, itemId);
            return tmp;
          })
          ?.flat();
        temp.thenList = thList;
        temp.orList = orlst;
      }
      return temp;
    };

    medList?.forEach((element, index) => {
      const { compound } = element;

      let temp = [...medList];

      if (element.localId === parentId) {
        const itemIndex = temp?.[index]?.compound?.findIndex((item) => {
          return item.localId === itemId;
        });
        if (itemIndex >= 0) {
          temp?.[index]?.compound?.splice(itemIndex, 1);
        }
        // setMedicationList(temp);
      } else {
        const compountList = compound
          ?.map((item) => {
            const hanlerValue = recursiveHandler(item, itemId);
            if (hanlerValue?.length === 0) {
              return null;
            }
            return hanlerValue;
          })
          ?.filter((item) => item);
        temp[index].compound = compountList.filter((item) => item !== null);
      }

      temp = temp.filter((item) => (item?.compound?.length > 0 ? true : false));

      setMedicationList(temp);
    });
  };

  const addThenCompound = (medList, itemId, prevIndex, data) => {
    const recursiveHandler = (item, itemId, prevIndex, data) => {
      const temp = { ...item };
      if (temp.localId === itemId) {
        const tempData = {
          ...data,
          index: currentStpIndex,
          prevIndex: prevIndex,
        };
        setCurrentIndex((prev) => prev + 1);
        if (temp.thenList) {
          temp.thenList.push(tempData);
        } else {
          temp.thenList = [tempData];
        }
        temp.compoundOrThen = "";
      } else {
        temp.compoundOrThen = "";
        const { thenList, orList } = temp;
        const thList = thenList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, prevIndex, data);
          return tmp;
        });

        const orlst = orList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, prevIndex, data);
          return tmp;
        });

        temp.thenList = thList;
        temp.orList = orlst;
      }
      return temp;
    };

    const medLst = medList?.map((element, index) => {
      const { compound } = element;
      const temp = { ...element };
      const comp = compound?.map((item, idx) => {
        const temp = { ...item };
        if (item.localId === itemId) {
          const tempData = {
            ...data,
            index: currentStpIndex,
            prevIndex: prevIndex,
          };
          setCurrentIndex((prev) => prev + 1);
          if (temp.thenList) {
            temp.thenList?.push(tempData);
          } else {
            temp.thenList = [tempData];
          }
          temp.compoundOrThen = "";
        } else {
          temp.compoundOrThen = "";
          const value = recursiveHandler(item, itemId, prevIndex, data);
          return value;
        }
        return temp;
      });
      temp.compound = comp;
      return temp;
    });
    setMedicationList(medLst);
  };

  const addOrCompound = (medList, itemId, prevIndex, data) => {
    const recursiveHandler = (item, itemId, prevIndex, data) => {
      const temp = { ...item };
      if (temp.localId === itemId) {
        const tempData = {
          ...data,
          index: currentStpIndex,
          prevIndex: prevIndex,
        };
        setCurrentIndex((prev) => prev + 1);
        if (temp.orList) {
          temp.orList.push(tempData);
        } else {
          temp.orList = [tempData];
        }
        temp.compoundOrThen = "";
      } else {
        temp.compoundOrThen = "";
        const { thenList, orList } = temp;
        const thList = thenList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, prevIndex, data);
          return tmp;
        });
        const orlst = orList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, prevIndex, data);
          return tmp;
        });
        temp.thenList = thList;
        temp.orList = orlst;
      }
      return temp;
    };

    const medLst = medList?.map((element, index) => {
      const { compound } = element;
      const temp = { ...element };
      const comp = compound?.map((item, idx) => {
        const temp = { ...item };
        if (item.localId === itemId) {
          const tempData = {
            ...data,
            index: currentStpIndex,
            prevIndex: prevIndex,
          };
          setCurrentIndex((prev) => prev + 1);
          if (temp.orList) {
            temp.orList?.push(tempData);
          } else {
            temp.orList = [tempData];
          }
          temp.compoundOrThen = "";
        } else {
          temp.compoundOrThen = "";
          const value = recursiveHandler(item, itemId, prevIndex, data);
          return value;
        }
        return temp;
      });
      temp.compound = comp;
      return temp;
    });
    setMedicationList(medLst);
  };

  const handleOrThenRadioButton = (medList, itemId, data) => {
    const recursiveHandler = (item, itemId, data) => {
      const temp = { ...item };
      if (temp.localId === itemId) {
        temp.compoundOrThen = data;
        // temp.compoundOrThen = false;
      } else {
        temp.compoundOrThen = "";
        const { thenList, orList } = temp;
        const thList = thenList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, data);
          return tmp;
        });

        const orlst = orList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, data);
          return tmp;
        });

        temp.thenList = thList;
        temp.orList = orlst;
      }
      return temp;
    };
    // const handlechangeItem = () => {};
    const medlst = medList?.map((element, index) => {
      const { compound } = element;
      const elmnt = { ...element };
      const cmpnd = compound?.map((item, idx) => {
        // if (item.localId === itemId) {
        //   item.compoundOrThen = data;
        // }
        const value = recursiveHandler(item, itemId, data);
        return value;
      });
      elmnt.compound = cmpnd;
      return elmnt;
    });
    setMedicationList(medlst);
  };

  const handleOpenAddNewCompound = (medList, parentId, data) => {
    medList?.forEach((element, index) => {
      if (element.localId === parentId) {
        const temp = [...medList];
        temp[index].addNewCompound = true;
        setMedicationList(temp);
      }
    });
  };

  const suggestedProcedure = useMemo(() => {
    const filteredList = getFilteredList(
      procedureList,
      procedureString,
      "procedureName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [procedureList, procedureString]);

  // const suggestedInvestigation = useMemo(() => {
  //   const userIdInvestigationList = userInvestigationList.map(
  //     (item) => item.testId
  //   );

  //   let filteredInvestigation = processedTestList.filter((disease) => {
  //     const alreadyTaken = userIdInvestigationList.some(
  //       (item) => item === disease.testId
  //     );
  //     return !alreadyTaken;
  //   });

  //   const filteredList = getFilteredList(
  //     filteredInvestigation,
  //     investigationSearchString,
  //     "testName"
  //   );
  //   return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  // }, [investigationSearchString, processedTestList, userInvestigationList]);

  const years = Array.from(new Array(100), (_, index) => index + 0); // Years from 1920 to 2020
  const months = Array.from(new Array(12), (_, index) => index + 1); // Months 1 to 12
  const days = Array.from(new Array(31), (_, index) => index + 1); // Days 1 to 31

  const handleChangselectAgeGroup = (event) => {
    setSelectAgeGroup(event.target.value);
  };
  const handleChangselectProcedure = (event) => {
    setSelectProcedure(event.target.value);
  };
  const handleChangselectLifestyle = (event) => {
    setSelectLifestyle(event.target.value);
  };
  const handleChangselectParameter = (event) => {
    setSelectParameter(event.target.value);
  };

  const handleChangeinvAplyGender = (event) => {
    setInvAplyGenders(event.target.value);
  };
  const handleChangeProcedureGender = (event) => {
    setProcedureGender(event.target.value);
  };

  const handleChangselectInvestigation = (event) => {
    setSelectInvestigation(event.target.value);
  };

  function buildTree(data) {
    const map = {}; // A map to store nodes by indexId
    const tree = []; // Array to hold the root nodes

    // Initialize nodes and store in map
    data.forEach((item) => {
      const compoundItem = compoundList.find(
        (cmpnd) => cmpnd.compoundId === item.compoundId
      );

      const form = {
        formId: item.formId,
        formName: item.formName,
      };

      const tmpValues = {
        route: {
          id: item.routeId,
          route: item.routeName,
        },
        dose: item.dosages,
        dosagesUnit: item.dosagesUnit,

        doseUnit: item.dosagesUnit,
        doseLimit: item.dosagesLimit,

        duration: {
          id: item.durationId,
          durationName: item.duration,
          days: item.duration,
        },
        frequency: {
          id: item.frequencyId,
          frequency: item.frequency,
          fixedDurationFlag: "Y",
        },
        weightMeasured: false,
        index: item.indexId,
        prevIndex: item.previousIndexId,
        operator: item.operator,
      };

      const tempNode = {
        localId: uuidv4(),
        compound: compoundItem,
        form,
        ...tmpValues,
      };

      map[item.indexId] = { ...tempNode };
    });

    // const selectedCompound=compoundList?.find(item=>item.)
    console.log("compoundList =", compoundList);

    // Construct tree structure
    data.forEach((item) => {
      const node = map[item.indexId];
      if (item.previousIndexId === 0) {
        // No parent, so it is a root node
        // tree.push(tempNode);
        tree.push(node);
      } else {
        // Find the parent node using previousIndexId
        const parent = map[item.previousIndexId];
        if (parent) {
          if (node.operator === "Then") {
            if (parent.thenList) {
              // parent.thenList.push(tempNode);
              parent.thenList.push(node);
            } else {
              // parent.thenList = [tempNode];
              parent.thenList = [node];
            }
          } else if (node.operator === "Or") {
            if (parent.orList) {
              // parent.orList.push(tempNode);
              parent.orList.push(node);
            } else {
              // parent.orList = [tempNode];
              parent.orList = [node];
            }
          }
        }
      }
    });

    return tree;
  }

  const handleCompoundData = (data) => {
    const availableRegimes = {};
    data.forEach((item) => {
      const { regimeName, ageGroup } = item;
      const key = `${regimeName}~${ageGroup}`;
      if (availableRegimes[key]) {
        availableRegimes[key].push(item);
      } else {
        availableRegimes[key] = [item];
      }
    });

    const bushes = {};
    let tmpMedList = [];
    const keys = Object.keys(availableRegimes);
    keys?.forEach((item) => {
      const tree = buildTree(availableRegimes[item]);
      bushes[item] = tree;
      const temp = item.split("~");
      const dto = {
        regime: temp[0],
        compound: [...tree],
        ageGroup: temp[1],
        addNewCompound: false,
      };
      tmpMedList.push(dto);
      console.log("Dto  =", dto);
    });
    setMedicationList(tmpMedList);
  };

  useEffect(() => {
    const { diseaseName } = editStpData;
    const diseaseSlices = diseaseName.split(" (");
    const tempDiseaseName = diseaseSlices[0];
    if (diseaseList) {
      const selectedDisease = diseaseList?.find(
        (item) => item.diseaseName === tempDiseaseName
      );

      // const selectedDisease = diseaseList?.find(
      //   (item) => item.id === stpData.diseaseId
      // );

      setSelectedDisease(selectedDisease);
    }
  }, [diseaseList]);

  useEffect(() => {
    if (investigationList && stpData) {
      const invList = stpData?.tests?.map((test) => {
        const testItem = investigationList?.find(
          (item) => item.testId === test.testId
        );
        if (testItem) {
          return {
            investigation: testItem,
            gender: test.gender,
          };
        } else {
          toast.error(
            t("could_not_find_investgation_of_test_id") + test.testId
          );
          return null;
        }
      });
      setUserInvestigationList(invList);
    }
  }, [investigationList, stpData]);

  useEffect(() => {
    if (procedureList && stpData) {
      const tempProcedure = stpData?.procedures?.map((procedure) => {
        const tempProcedure = procedureList.find(
          (item) => item.id === procedure.procedureId
        );

        const dto = {
          procedure: tempProcedure,
          gender: procedure.gender,
        };
        return dto;
      });

      setSelectedProcedureList(tempProcedure);
    }
  }, [procedureList, stpData]);

  const getGenderName = (genderCode) => {
    if (genderCode === "C") return "Common";
    else if (genderCode === "M") return "Male";
    else if (genderCode === "F") return "Female";
    else return "";
  };

  useEffect(() => {
    if (stpData && compoundList) {
      const { compounds } = stpData;
      if (compoundList) {
        handleCompoundData(compounds);
      }
    }
  }, [compoundList, stpData]);

  useEffect(() => {
    getDiseases();
    getMedicalTestList();
    getProcedures();
    getLifeStyles();
    const reqDto = {
      orgId: loginUserDetails.orgId,
      countryCode: loginUserDetails.orgCountryCode,
    };
    zhPatientService.getAgeForSTP(reqDto).then((response) => {
      setAgeGroupList(response.data);
    });
    zhPatientService.getCompounds(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        setCompoundList(response.data);
      }
    });
    console.log("stpData =", editStpData);

    zhPatientService.getStpbyId(editStpData?.stpId).then((response) => {
      if (response.data) {
        setSptData(response.data);
        const { lifeStyles, parameters, compounds } = response.data;
        setRecomandedLifeStyle(lifeStyles);
        setParameterMonitor(parameters);
        handleCompoundData(compounds);
      }
    });
  }, []);

  // const suggestedLifeStyleList = useMemo(() => {
  //   const userLifeStyleIdList = recommandedLifeStyle.map((item) => item.id);

  //   let filteredDietList = lifeStyleList.filter((lifeStyle) => {
  //     const alreadyTaken = userLifeStyleIdList.some(
  //       (item) => item === lifeStyle.id
  //     );
  //     return !alreadyTaken;
  //   });

  //   const filteredList = getFilteredList(
  //     filteredDietList,
  //     lifeStyleString,
  //     "lifeStyle"
  //   );
  //   return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  // }, [lifeStyleString, lifeStyleList, recommandedLifeStyle]);

  const suggestedDiseaseList = useMemo(() => {
    const userDiseaseIdList = userDiseaseList.map((item) => item.diseaseObj.id);

    const filteredDiseaseList = diseaseList.filter((disease) => {
      const alreadyTaken = userDiseaseIdList.some(
        (item) => item === disease.id
      );
      return !alreadyTaken;
    });

    const filteredList = getFilteredList(
      filteredDiseaseList,
      diseaseInputString,
      "diseaseName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [diseaseInputString, diseaseList, userDiseaseList]);

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">{t("edit_stp")}</div>
          <IconButton className="CloseBtn" onClick={() => handelCloseEditStp()}>
            <XLg />
          </IconButton>
        </div>

        <div className="elementFormContainer gap10">
          <div className="tskElemHeding mb-8">{t("disease_with_icd11")}</div>
          <div className="formElement ">
            <FormControl className="formControl">
              {/* <InputLabel id="demo-simple-select-label">
                Select Disease
              </InputLabel> */}
              {/* <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectDiseas}
                label="Select Disease"
                onChange={handleChangeSelectDiseas}
                className="formInputField"
                variant="outlined"
              >
                <MenuItem value="d1">Disease one</MenuItem>
                <MenuItem value="d2">Disease two</MenuItem>
              </Select> */}

              <Autocomplete
                freeSolo
                className="formAutocompleteField"
                variant="outlined"
                value={selectedDisease}
                options={suggestedDiseaseList}
                // inputValue={diseaseInputString}
                onChange={(e, newValue) => {
                  setSelectedDisease(newValue);
                }}
                // onInputChange={(e, value, reason) => {
                //   // if (e && e.target) {
                //   //   setDiseaseInputString(e.target.value);
                //   // }
                //   setDiseaseInputString(value);
                //   // else {
                //   //   setSymptomSearchString("");
                //   // }
                // }}
                getOptionLabel={(option) => option.diseaseName}
                renderOption={(props, item) => {
                  return (
                    <li {...props} key={item.id}>
                      {item.diseaseName}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="formAutoComInputField autocompFildPlaceholder"
                    label={t("disease_name")}
                    placeholder={t("disease_name")}
                    required
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          </div>

          {/* start Investigation */}
          <div className="tskElemHeding mb-8">{t("investigation")}</div>
          <div className="formElementGrp mb-8">
            <div className="formElement">
              <FormControl className="formControl">
                {/* <InputLabel id="selectInv">Investigation</InputLabel> */}
                {/* <Select
                  required
                  labelId="selectInv"
                  id="select_Inv"
                  value={selectInvestigation}
                  label="Investigation"
                  onChange={handleChangselectInvestigation}
                  className="formInputField"
                  variant="outlined"
                >
                  <MenuItem value="inv1">Investigation one</MenuItem>
                  <MenuItem value="inv2">Investigation two</MenuItem>
                </Select> */}

                <Autocomplete
                  // freeSolo
                  className="formAutocompleteField"
                  variant="outlined"
                  value={selectedInvestigation}
                  options={investigationList}
                  inputValue={investigationSearchString}
                  onChange={(e, newValue) => {
                    setSelectedInvestigation(newValue);
                    if (newValue) {
                      const { gender } = newValue;
                      setInvAplyGenders(gender);
                    }
                  }}
                  onInputChange={(e, value, reason) => {
                    setInvestigtionSearchString(value);
                  }}
                  getOptionLabel={(option) => option.testName}
                  renderOption={(props, item) => {
                    return (
                      <li
                        {...props}
                        key={item.id}
                        // className={
                        //   item.isAvailableInCenter ? "available" : ""
                        // }
                      >
                        {item.testName}
                        {/* {`(${item.gender})`} */}
                        {item.isAvailableInCenter && (
                          <Bookmark className="centAvlTest" />
                        )}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder={t("search_investigations")}
                      label={t("investigation")}
                      required
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className="formElement mxW-100">
              <FormControl className="formControl">
                <InputLabel id="demo-simple-select-label">{t("gender")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={invAplyGender}
                  label="Gender"
                  onChange={handleChangeinvAplyGender}
                  className="formInputField"
                  variant="outlined"
                >
                  <MenuItem value="M">{t("male")}</MenuItem>
                  <MenuItem value="F">{t("female")}</MenuItem>
                  <MenuItem value="C">{t("common")}</MenuItem>
                </Select>
              </FormControl>
            </div>

            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => {
                let isValid = true;
                if (!selectedInvestigation) {
                  toast.error(t("please_select_investigation"));
                  isValid = false;
                  // return
                }
                if (!invAplyGender) {
                  toast.error(t("please_select_gender"));
                  isValid = false;
                }
                if (!isValid) {
                  return;
                }

                const temp = {
                  investigation: selectedInvestigation,
                  gender: invAplyGender,
                };
                const tempIvn = [...userInvestigationList];
                tempIvn.push(temp);
                setUserInvestigationList(tempIvn);
                initializeInvestigation();
              }}
            >
               {t("add")}
            </Button>
          </div>
          <div className="invAddedListGrp">
            {userInvestigationList?.map((item, index) => {
              return (
                <div className="invAddedIndv">
                  <div className="invAddName">
                    {`${item?.investigation?.testName}${
                      item.gender
                        ? ` ( Gender: ${getGenderName(item.gender)} ) `
                        : ""
                    }`}
                  </div>
                  <IconButton
                    onClick={() => {
                      const temp = [...userInvestigationList];
                      temp.splice(index, 1);
                      setUserInvestigationList(temp);
                    }}
                  >
                    <Close />
                  </IconButton>
                </div>
              );
            })}

            {/* <div className="invAddedIndv">
              <div className="invAddName">
                Blood Glucose (Sugar) - Fasting (C)
              </div>
              <IconButton>
                <Close />
              </IconButton>
            </div> */}
          </div>
          {/* End Investigation*/}

          {/* start medication */}
          <div className="tskElemHeding mb-8">{t("medication")}</div>

          <div className="regimeWithComponent">
            {medicationList?.map((item, index) => {
              return (
                <ul className="regimeList">
                  <li>
                    <div className="regimeDtl">
                      <div className="regimeName">{item.regime}</div>
                      <Tooltip title="Remove Regime" arrow>
                        <div
                          className="rmvRegim"
                          onClick={() => {
                            const temp = [...medicationList];
                            temp.splice(index, 1);
                            setMedicationList(temp);
                          }}
                        >
                          {t("remove")}
                        </div>
                      </Tooltip>
                    </div>
                    <CompoundComponent
                      compoundList={compoundList}
                      compound={item}
                      parentId={item.localId}
                      updateCompound={(data) => {
                        const { type, payload } = data;
                        switch (type) {
                          case "addAnd":
                            addAndComponent(
                              medicationList,
                              payload.parentId,
                              payload.data
                            );
                            break;

                          case "changeCompoundType":
                            handleOrThenRadioButton(
                              medicationList,
                              payload.itemId,
                              payload.data
                            );
                            break;
                          case "addNewCompound":
                            handleOpenAddNewCompound(
                              medicationList,
                              payload.parentId,
                              payload.data
                            );
                            break;
                          case "deleteCompound":
                            deleteCompound(
                              medicationList,
                              payload.parentId,
                              payload.itemId
                            );
                            break;

                          case "addThen":
                            addThenCompound(
                              medicationList,
                              payload.itemId,
                              payload.prevIndex,
                              payload.data
                            );
                            break;
                          case "addOr":
                            addOrCompound(
                              medicationList,
                              payload.itemId,
                              payload.prevIndex,
                              payload.data
                            );
                            break;
                          default:
                            break;
                        }
                      }}
                    />
                  </li>
                </ul>
              );
            })}
            {!showAddRegime && (
              <div className="addAndCompound">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowAddRegime(true);
                  }}
                >
                  {t("new_regime")}
                </Button>
              </div>
            )}
          </div>

          {/* <div className="stpMedicationList">
            <ul>
              <li>
                <div className="regimeDtl">
                  <div className="regimeName">
                    Acute, Helicobacter Pylori Associated (H Pylori Eradication
                    Therapy - Triple Therapy [PPI AND AMOXICILLIN OR
                    METRONIDAZOLE])
                  </div>
                  <Tooltip title="Remove Regime" arrow>
                    <div className="rmvRegim">Remove</div>
                  </Tooltip>
                </div>
                <div className="medStpwthRmv">
                  <div className="medRecStpGrp">
                    <div className="medRecStpInd">
                      <span>Compound:</span>DEFERASIROX
                    </div>
                    <div className="medRecStpInd">
                      <span>Form:</span>Tablet
                    </div>
                    <div className="medRecStpInd">
                      <span>Upper Age:</span>50Y 6M 10D
                    </div>
                    <div className="medRecStpInd">
                      <span>Lower Age:</span>18Y 5M 30D
                    </div>
                    <div className="medRecStpInd">
                      <span>Min Dose:</span>1
                    </div>
                    <div className="medRecStpInd">
                      <span>Max Dose:</span>3
                    </div>
                    <div className="medRecStpInd">
                      <span>Dose unit:</span> mg/kg
                    </div>
                    <div className="medRecStpInd">
                      <span>Duration:</span>10 Days
                    </div>
                    <div className="medRecStpInd">
                      <span>Frequency:</span>BID
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <RadioGroup
                          className="formRadioGroup"
                          labelId="setTasktype"
                          name="regimeCondition"
                          value={regimeCondition}
                          onChange={handleChangeRegimeCondition}
                        >
                          <FormControlLabel
                            className="formRadioField"
                            value="and"
                            control={<Radio />}
                            label="And"
                          />
                          <FormControlLabel
                            className="formRadioField"
                            value="or"
                            control={<Radio />}
                            label="Or"
                          />
                          <FormControlLabel
                            className="formRadioField"
                            value="them"
                            control={<Radio />}
                            label="Then"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  <Tooltip title="Remove Compound" arrow>
                    <IconButton>
                      <Trash />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="medStpwthRmv">
                  <div className="medRecStpGrp">
                    <div className="medRecStpInd">
                      <span>Compound:</span>DEFERASIROX
                    </div>
                    <div className="medRecStpInd">
                      <span>Form:</span>Tablet
                    </div>
                    <div className="medRecStpInd">
                      <span>Upper Age:</span>50Y 6M 10D
                    </div>
                    <div className="medRecStpInd">
                      <span>Lower Age:</span>18Y 5M 30D
                    </div>
                    <div className="medRecStpInd">
                      <span>Min Dose:</span>1
                    </div>
                    <div className="medRecStpInd">
                      <span>Max Dose:</span>3
                    </div>
                    <div className="medRecStpInd">
                      <span>Dose unit:</span> mg/kg
                    </div>
                    <div className="medRecStpInd">
                      <span>Duration:</span>10 Days
                    </div>
                    <div className="medRecStpInd">
                      <span>Frequency:</span>BID
                    </div>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <RadioGroup
                          className="formRadioGroup"
                          labelId="setTasktype"
                          name="regimeCondition"
                          value={regimeCondition}
                          onChange={handleChangeRegimeCondition}
                        >
                          <FormControlLabel
                            className="formRadioField"
                            value="and"
                            control={<Radio />}
                            label="And"
                          />
                          <FormControlLabel
                            className="formRadioField"
                            value="or"
                            control={<Radio />}
                            label="Or"
                          />
                          <FormControlLabel
                            className="formRadioField"
                            value="them"
                            control={<Radio />}
                            label="Then"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  <Tooltip title="Remove Compound" arrow>
                    <IconButton>
                      <Trash />
                    </IconButton>
                  </Tooltip>
                </div>
              </li>
              <li>
                <div className="regimeDtl">
                  <div className="regimeName">
                    Presumptive, At Risk Of Stress Gastritis (Anti-Secretory
                    Agents: Preventive Therapy)
                  </div>
                  <Tooltip title="Remove Regime" arrow>
                    <div className="rmvRegim">Remove</div>
                  </Tooltip>
                </div>
                <div className="medStpwthRmv">
                  <div className="medRecStpGrp">
                    <div className="medRecStpInd">
                      <span>Compound:</span>DEFERASIROX
                    </div>
                    <div className="medRecStpInd">
                      <span>Form:</span>Tablet
                    </div>
                    <div className="medRecStpInd">
                      <span>Upper Age:</span>50Y 6M 10D
                    </div>
                    <div className="medRecStpInd">
                      <span>Lower Age:</span>18Y 5M 30D
                    </div>
                    <div className="medRecStpInd">
                      <span>Min Dose:</span>1
                    </div>
                    <div className="medRecStpInd">
                      <span>Max Dose:</span>3
                    </div>
                    <div className="medRecStpInd">
                      <span>Dose unit:</span> mg/kg
                    </div>
                    <div className="medRecStpInd">
                      <span>Duration:</span>10 Days
                    </div>
                    <div className="medRecStpInd">
                      <span>Frequency:</span>BID
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <RadioGroup
                          className="formRadioGroup"
                          labelId="setTasktype"
                          name="regimeCondition"
                          value={regimeCondition}
                          onChange={handleChangeRegimeCondition}
                        >
                          <FormControlLabel
                            className="formRadioField"
                            value="and"
                            control={<Radio />}
                            label="And"
                          />
                          <FormControlLabel
                            className="formRadioField"
                            value="or"
                            control={<Radio />}
                            label="Or"
                          />
                          <FormControlLabel
                            className="formRadioField"
                            value="them"
                            control={<Radio />}
                            label="Then"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  <Tooltip title="Remove Compound" arrow>
                    <IconButton>
                      <Trash />
                    </IconButton>
                  </Tooltip>
                </div>
              </li>
            </ul>
          </div> */}

          {showAddRegime && (
            <div className="medicationGroup">
              <div className="formElementGrp">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      // hiddenLabel
                      label={t("regime")}
                      required
                      autoComplete="off"
                      variant="outlined"
                      className="formTextFieldArea"
                      value={regime}
                      onChange={(e) => {
                        setRegime(e.target.value);
                      }}
                    />
                  </FormControl>
                </div>
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <InputLabel id="selectAgeGroup">{t("age_group")}</InputLabel>
                    <Select
                      required
                      labelId="selectAgeGroup"
                      id="select_AgeGroup"
                      value={selectAgeGroup}
                      label={t("age_group")}
                      onChange={handleChangselectAgeGroup}
                      className="formInputField"
                      variant="outlined"
                    >
                      {ageGroupList?.map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                      {/* <MenuItem value="newBorn">New Born (upto 3 month)</MenuItem>
                    <MenuItem value="infent">Infent (3 - 12 months)</MenuItem>
                    <MenuItem value="toddler">
                      Toddler (1 - 5 Years old)
                    </MenuItem>
                    <MenuItem value="child">Child (6 - 12 Years old)</MenuItem>
                    <MenuItem value="Teen">Teen (13 - 19 Years old)</MenuItem>
                    <MenuItem value="adult">Adult (20 - 39 Years old)</MenuItem>
                    <MenuItem value="midAdult">
                      Mid Adult (40 - 59 Years old)
                    </MenuItem>
                    <MenuItem value="seniorAdult">
                      Senior Adult (60+ Years old)
                    </MenuItem> */}
                    </Select>
                  </FormControl>
                </div>
                <Tooltip title={t("age_group_list")} arrow>
                  <IconButton
                    className="listAgegroup"
                    onClick={() => {
                      setListAgeGroup(true);
                    }}
                  >
                    <FormatListBulleted />
                  </IconButton>
                </Tooltip>
              </div>

              <AddCompound
                compoundList={compoundList}
                handleAddCompound={(data) => {
                  if (!regime.trim()) {
                    toast.error(t("please_add_regime_name"));
                    setRegime("");
                    return;
                  }
                  const temp = {
                    ...data,
                    index: currentStpIndex,
                    prevIndex: 0,
                  };
                  setCurrentIndex((prev) => prev + 1);
                  const dto = {
                    localId: uuidv4(),
                    regime,
                    compound: [temp],
                    ageGroup: selectAgeGroup,
                  };

                  setMedicationList((prev) => [...prev, dto]);
                  setShowAddRegime(false);
                  setRegime("");
                }}
              />
            </div>
          )}

          {/* end medication */}

          {/* Start Parameter to be Monitor */}
          <div className="tskElemHeding mb-8">{t("parameter_to_be_monitored")}</div>
          <div className="formElementGrp mb-8">
            <div className="formElement">
              <FormControl className="formControl">
                {/* <InputLabel id="selectparam">Select parameter</InputLabel>
                <Select
                  labelId="selectparam"
                  id="select_param"
                  multiple
                  value={parameterMonitor}
                  renderValue={(selected) => ""}
                  label="Select parameter"
                  onChange={handleChangeparameterMonitor}
                  className="formInputField"
                  variant="outlined"
                >
                  {monitorNames.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={parameterMonitor.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select> */}
                <Autocomplete
                  freeSolo
                  className="formAutocompleteField"
                  variant="outlined"
                  value={selectedParameter}
                  options={monitorNames}
                  inputValue={parameterString}
                  onChange={(e, newValue) => {
                    setSelectedParameter(newValue);
                  }}
                  onInputChange={(e, value) => {
                    setParameterString(value);
                  }}
                  // getOptionLabel={(option) => option.lifeStyle}
                  renderOption={(props, item) => {
                    return (
                      <li {...props} key={item}>
                        {item}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder={"Search lifestyle"}
                      label={t("parameter_to_monitor")}
                      required
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>

            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => {
                console.log("");
                const parameterItem = parameterString;
                if (!parameterString) {
                  toast.error(t("please_provide_parameter_name"));
                  return;
                }
                const temp = [...parameterMonitor];
                temp.push(parameterString);
                setParameterMonitor(temp);
                setParameterString("");
                setSelectedParameter(null);
              }}
            >
              {t("add")}
            </Button>
          </div>
          <div className="invAddedListGrp">
            {parameterMonitor?.map((item, index) => {
              return (
                <div className="invAddedIndv">
                  <div className="invAddName">{item}</div>
                  <IconButton
                    onClick={() => {
                      const temp = [...parameterMonitor];
                      temp.splice(index, 1);
                      setParameterMonitor(temp);
                    }}
                  >
                    <Close />
                  </IconButton>
                </div>
              );
            })}

            {/* <div className="invAddedIndv">
              <div className="invAddName">Respiration Rate</div>
              <IconButton>
                <Close />
              </IconButton>
            </div>
            <div className="invAddedIndv">
              <div className="invAddName">SPO2</div>
              <IconButton>
                <Close />
              </IconButton>
            </div> */}
          </div>
          {/* end Parameter to be Monitor */}

          {/* Start lifestyle */}
          <div className="tskElemHeding mb-8">{t("lifestyle_recommendations")}</div>
          <div className="formElementGrp mb-8">
            <div className="formElement">
              <FormControl className="formControl">
                {/* <InputLabel id="selectparam">Select Lifestyle</InputLabel> */}
                {/* <Select
                  labelId="selectparam"
                  id="select_Lifestyle"
                  value={selectLifestyle}
                  label="Select Lifestyle"
                  onChange={handleChangselectLifestyle}
                  className="formInputField"
                  variant="outlined"
                >
                  <MenuItem value="Lifestyle1">Lifestyle one</MenuItem>
                  <MenuItem value="Lifestyle2">Lifestyle two</MenuItem>
                </Select> */}
                <Autocomplete
                  freeSolo
                  className="formAutocompleteField"
                  variant="outlined"
                  value={selectedLifeStyle}
                  options={lifeStyleList}
                  inputValue={lifeStyleString}
                  onChange={(e, newValue) => {
                    setSelectedLifeStyle(newValue);
                  }}
                  onInputChange={(e, value) => {
                    setLifeStyleString(value);
                    // else {
                    //   setSymptomSearchString("");
                    // }
                  }}
                  getOptionLabel={(option) => option.lifeStyle}
                  renderOption={(props, item) => {
                    return (
                      <li {...props} key={item.id}>
                        {item.lifeStyle}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder={t("search_lifestyle")}
                      label={t("life_style_name")}
                      required
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>

            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => {
                if (!lifeStyleString || lifeStyleString === "") {
                  toast.error(t("please_provide_lifestyle"));
                  return;
                }
                let tempLifeStyle = selectedLifeStyle;

                setRecomandedLifeStyle((prev) => {
                  const temp = [...prev, tempLifeStyle];
                  return temp;
                });
                initializeLifeStyle();
              }}
            >
              {t("add")}
            </Button>
          </div>
          <div className="invAddedListGrp">
            {recommandedLifeStyle?.map((item, index) => {
              return (
                <div className="invAddedIndv">
                  <div className="invAddName">{item?.lifeStyle}</div>
                  <IconButton
                    onClick={() => {
                      const temp = [...recommandedLifeStyle];
                      temp.splice(index, 1);
                      setRecomandedLifeStyle(temp);
                    }}
                  >
                    <Close />
                  </IconButton>
                </div>
              );
            })}
          </div>
          {/* end lifestyle */}

          {/* start procedure */}
          <div className="tskElemHeding mb-8">{t("procedure")}</div>
          <div className="formElementGrp mb-8">
            <div className="formElement">
              <FormControl className="formControl">
                <Autocomplete
                  // freeSolo
                  className="formAutocompleteField"
                  variant="outlined"
                  value={selectedProcedure}
                  options={suggestedProcedure}
                  onChange={(e, newValue) => {
                    setSelectedProcedure(newValue);
                    setProcedureGender(newValue?.gender);
                  }}
                  inputValue={procedureString}
                  onInputChange={(e, value) => {
                    setProcedureString(value);
                  }}
                  getOptionLabel={(option) => option.procedureName}
                  renderOption={(props, item) => {
                    return (
                      <li {...props} key={item.id}>
                        {item.procedureName}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder={t("add_procedure")}
                      label={t("procedure")}
                      required
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className="formElement mxW-100">
              <FormControl className="formControl">
                <InputLabel id="demo-simple-select-label">{t("gender")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={procedureGender}
                  label="Gender"
                  onChange={handleChangeProcedureGender}
                  className="formInputField"
                  variant="outlined"
                >
                  <MenuItem value="M">{t("male")}</MenuItem>
                  <MenuItem value="F">{t("female")}</MenuItem>
                  <MenuItem value="C">{t("common")}</MenuItem>
                </Select>
              </FormControl>
            </div>

            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => {
                let tempProcedure = selectedProcedure;
                if (!tempProcedure) {
                  toast.error(t("please_provide_procedure_name"));
                  return;
                }

                setSelectedProcedureList((prev) => {
                  const temp = [
                    ...prev,
                    {
                      procedure: tempProcedure,
                      gender: procedureGender,
                    },
                  ];
                  return temp;
                });
                // initializeForm();
                setSelectedProcedure(null);
                setProcedureGender("");
                setProcedureString("");
              }}
            >
              {t("add")}
            </Button>
          </div>
          <div className="invAddedListGrp">
            {selectedProcedureList?.map((item, index) => (
              <>
                <div className="invAddedIndv">
                  <div className="invAddName">
                    {`${item?.procedure?.procedureName} ${
                      item.gender
                        ? ` ( Gender: ${getGenderName(item.gender)} ) `
                        : ""
                    }`}
                  </div>
                  <IconButton
                    onClick={() => {
                      const temp = [...selectedProcedureList];
                      temp.splice(index, 1);
                      setSelectedProcedureList(temp);
                    }}
                  >
                    <Close />
                  </IconButton>
                </div>
              </>
            ))}
          </div>
          {/* End procedure */}
        </div>

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button
              className="dfultPrimaryBtn"
              onClick={() => {
                const recursiveHandler = (
                  item,
                  regimeName,
                  ageGroup,
                  diseaseId,
                  operator
                ) => {
                  const compound = {
                    regimeName: regimeName,
                    ageGroup: ageGroup,
                    weightMeasured: item?.weightMeasured ? "Y" : "N",
                    diseaseId: diseaseId,
                    compoundId: item?.compound?.compoundId || 0,
                    formId: item?.form?.formId || 0,
                    routeId: item?.route?.id || 0,
                    dosages: item?.dose || "",
                    dosagesUnit: item?.doseUnit || "",
                    dosagesLimit: item?.doseLimit || "",
                    durationId: item?.duration?.id || 0,
                    frequencyId: item?.frequency?.id || 0,
                    operator: operator || "",
                    indexId: item.index,
                    previousIndexId: item.prevIndex,
                    formName: item?.form?.formName || "",
                    routeName: item?.route?.route || "",
                    duration: item?.duration?.durationName || "",
                    frequency: item?.frequency?.frequency,
                    compoundName: item?.compound?.compoundName || "",
                  };
                  let temp = [];
                  const value = item?.thenList
                    ?.map((item) => {
                      const tempValue = recursiveHandler(
                        item,
                        regimeName,
                        ageGroup,
                        diseaseId,
                        "Then"
                      );
                      return tempValue.flat();
                    })
                    ?.flat();

                  const value2 = item?.orList
                    ?.map((item) => {
                      const tempValue = recursiveHandler(
                        item,
                        regimeName,
                        ageGroup,
                        diseaseId,
                        "Or"
                      );
                      return tempValue.flat();
                    })
                    ?.flat();
                  if (value) {
                    temp = [...temp, ...value];
                  }
                  if (value2) {
                    temp = [...temp, ...value2];
                  }
                  if (temp?.length > 0) {
                    return [compound, ...temp];
                  } else {
                    return [compound];
                  }
                };
                // ;
                const compoundList = medicationList
                  ?.map((item) => {
                    const { compound } = item;
                    let tempCompounds = [];
                    compound?.forEach((comp) => {
                      const value = recursiveHandler(
                        comp,
                        item.regime,
                        item.ageGroup,
                        selectedDisease.id
                      );
                      tempCompounds = [...tempCompounds, ...value];
                      // return value;
                    });
                    return tempCompounds;
                  })
                  .flat();
                const reqDto = {
                  diseaseId: selectedDisease.id,
                  orgId: loginUserDetails.orgId,
                  lifeStyles: recommandedLifeStyle || [],
                  parameters: monitorNames || [],
                  tests:
                    userInvestigationList?.map((item) => ({
                      testId: item.investigation.testId,
                      testName: item.investigation.testName,
                      gender: item.gender,
                    })) || [],
                  procedures:
                    selectedProcedureList?.map((item) => {
                      return {
                        procedureId: item.procedure.id,
                        gender: item.gender,
                        procedureName: item.procedure.procedureName,
                      };
                    }) || [],
                  compounds: compoundList || [],
                  stpId: editStpData?.stpId,
                };
                console.log("reqDto =", reqDto);

                // return;

                zhPatientService
                  .submitStp(loginUserDetails.userId, reqDto)
                  .then((response) => {
                    if (response.data) {
                      toast.success(t("data_submitted_successfully"));
                    }
                  });
              }}
            >
              {t("save")}
            </Button>

            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handelCloseEditStp()}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </div>

      {listAgeGroup && (
        <>
          <ListAgeGroupModel
            handleCloseListAgeGroupModel={handleCloseListAgeGroupModel}
            handleClose={() => {
              setListAgeGroup(false);
            }}
          />
        </>
      )}
    </>
  );
}
