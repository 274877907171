import { Add, ArrowBack, Edit, Search, Visibility } from "@mui/icons-material";
import { Button, CircularProgress, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Download, Trash } from "react-bootstrap-icons";
import {
  deleteContract,
  getContractList,
  getCountryList,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { downloadFileFromUrl } from "../../../../utils/FileUtils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export default function ViewContract({
  handelCloseViewContract,
  handelOpenAddContract,
  refreshContract,
  closeAll,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [CountryList, setCountryList] = useState([]);

  const [contractData, setContractData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredContractData, setFilteredContractData] = useState([]);

  console.log("userDetails.orgId", userDetails.orgId);

  useEffect(() => {
    fetchContractData();
  }, [refreshContract]);

  const fetchContractData = () => {
    getContractList(userDetails.orgId)
      .then((response) => {
        console.log("getContractList", response.data);
        setContractData(response.data);
        setFilteredContractData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCountryList().then((response) => {
      console.log("getCountryList", response.data);
      setCountryList(response.data);
    });
  }, []);

  useEffect(() => {
    const filteredData = contractData.filter(
      (contract) =>
        contract.contractName &&
        contract.contractName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredContractData(filteredData);
  }, [contractData, searchQuery]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  const getCountryName = (countryCode) => {
    if (CountryList && countryCode) {
      const result = CountryList.find((val) => val.countryCode === countryCode);
      return result ? result.countryName : t("country_not_found");
    }
    return t("country_not_found");
  };

  const handleDownLoadFile = (contract) => {
    console.log("download");
    if (contract) {
      const fileLink = contract.fileDataLink;
      const fileNames = contract.fileName;
      downloadFileFromUrl(fileLink, fileNames);
    } else {
      console.log("No File found.");
    }
  };

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton
            onClick={() => {
              handelCloseViewContract();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("contract")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              <div className="searchTasklist">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder={t("search_contract")}
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => handelOpenAddContract()}
                >
                  {t("contract")}
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : contractData.length === 0 ? (
                <div class="noDataCard ">
                  <span>{t("oops")}</span>
                  {t("no_contract_added_yet")}
                  <br />
                  <span className="sallMsg">
                    {t("click_add_contract_button_to_create_new_contract")}
                  </span>
                </div>
              ) : (
                <div className="TableContainer">
                  <table className="taskListTable">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th className="width100">{t("sl_no")}</th>
                        <th>{t("contract_name")}</th>
                        <th>{t("contract_description")}</th>
                        <th>{t("contract_value")}</th>
                        <th>{t("currency")}</th>
                        <th>{t("phone_no")}</th>
                        {/* <th>{t("email_id")}</th>x */}
                        <th className="width100">{t("action")}</th>
                      </tr>
                    </thead>

                    <tbody className="scrolableTableBody">
                      {filteredContractData.map((contract, index) => (
                        <tr key={contract.id}>
                          <td>
                            <div className="tskLstTaskNM">{index + 1}</div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {contract.contractName}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {contract.contractDescription}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {contract.contractValue}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {contract.currency}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {contract.callingCode}-{contract.mobileNo}
                            </div>
                          </td>
                          {/* <td>
                            <div className="tskLstTaskNM">
                              {contract.emailId}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {contract.address}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {getCountryName(contract.countryCode)}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">{contract.taxId}</div>
                          </td> */}
                          <td>
                            <div className="tblActionBtnGrp">
                              <IconButton
                                onClick={() => handleDownLoadFile(contract)}
                              >
                                <Download />
                              </IconButton>

                              <IconButton
                                onClick={() => handelOpenAddContract(contract)}
                              >
                                <Edit />
                              </IconButton>

                              <IconButton
                                className="removeRowBtn"
                                onClick={() => {
                                  deleteContract(contract.id).then(
                                    (response) => {
                                      if (response.data) {
                                        toast.success(t("contract_deleted"));
                                        fetchContractData();
                                      } else {
                                        toast.error(
                                          t("fail_to_delete_contract")
                                        );
                                      }
                                    }
                                  );
                                }}
                              >
                                <Trash />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
