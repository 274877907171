import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import DashboardServices from "../../../../../services/DashboardServices";
import { jwtDecode } from "jwt-decode";
import { DragIndicator } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const UnitTestMapping = () => {
  const { t } = useTranslation();
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const [allActiveTests, setAllActiveTest] = useState([]);
  const [selectActiveTest, setselectActiveTest] = useState(null);
  const [selectActiveTestUnitData, setselectActiveTestUnitData] = useState(
    []
  );
  const [switchStates, setSwitchStates] = useState([]);
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [selectNoneCheck, setSelectNoneCheck] = useState(false);

  /////unit

  const [allActiveUnits, setAllActiveUnits] = useState([]);
  const [selectActiveUnit, setSelectActiveUnit] = useState(null);
  const [selectActiveUnitDoctorData, setSelectActiveUnitDoctorData] = useState(
    []
  );
  const [switchUnitStates, setSwitchUnitStates] = useState([]);
  const [selectAllUnitCheck, setSelectAllUnitCheck] = useState(false);
  const [selectNoneUnitCheck, setSelectNoneUnitCheck] = useState(false);

  useEffect(() => {
    fetchAllActiveUnits();
  }, []);

  useEffect(() => {
    fetchallActiveTests();
  }, []);

  const fetchallActiveTests = () => {
    DashboardServices.getTestListConductedAtUnit(userDetails.orgId).then(
      (response) => {
        console.log("all Active Doctor", response.data);
        setAllActiveTest(response.data);
      }
    );
  };

  const handleselectDoctorChange = (event, value) => {
    console.log("handleselectDoctorChange", value);
    setselectActiveTest(value);
  };

  useEffect(() => {
    fetchUnitsOfDoctor();
  }, [selectActiveTest]);

  const fetchUnitsOfDoctor = () => {
    if (!selectActiveTest) return;

    DashboardServices.getAllUnitsMappedWithTest(
      userDetails.orgId,
      selectActiveTest.testId
    ).then((response) => {
      console.log("Units of Selected Doctor", response.data);
      setselectActiveTestUnitData(response.data);
      const initialSwitchStates = response.data.map(
        (item) => item.activeFlag === "Y"
      );
      const allTrue = initialSwitchStates.every((state) => state === true);
      if (allTrue) {
        setSwitchStates(initialSwitchStates);
        setSelectAllCheck(true);
      } else {
        setSwitchStates(initialSwitchStates);
        setSelectAllCheck(false);
      }
    });
  };

  const handleChange = (index) => (event) => {
    const newState = [...switchStates];
    newState[index] = event.target.checked;
    setSwitchStates(newState);

    const allFalse = newState.every((state) => !state);
    setSelectAllCheck(allFalse);

    const allTrue = newState.every((state) => state);
    setSelectAllCheck(allTrue);

    const selectNone = newState.every((state) => {
      console.log("select none", state);
      return state === false;
    });
    console.log("selectNone", selectNone);
    setSelectNoneCheck(selectNone);
  };

  const handleSelectAll = (event) => {
    console.log("handleSelectAll triggered", event.target.checked);
    const val = event.target.checked;
    if (val) {
      setSwitchStates(new Array(switchStates.length).fill(true));
      setSelectAllCheck(true);
    } else {
      setSwitchStates(new Array(switchStates.length).fill(false));
      setSelectAllCheck(false);
    }
    console.log("switchStates after select all:", switchStates);
  };

  const finalSubmit = () => {
    if (
      !selectActiveTest ||
      !selectActiveTest.testId
    ) {
      toast.error("Please Select A Test", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const reqDto = selectActiveTestUnitData.map((item, index) => ({
      unitId: item.unitId,
      unitName: item.unitName,
      activeFlag: switchStates[index] ? "Y" : "N",
    }));

    console.log("reqDto", reqDto);
    const reqDtoFiltered = reqDto.filter((item) => item.activeFlag === "Y");

    console.log("reqDtoFiltered", reqDtoFiltered);

    // return;

    DashboardServices.submitUnitsOfTest(
      selectActiveTest.testId,
      reqDtoFiltered
    ).then((response) => {
      console.log("submit Data");
      if (response.data == 1) {
        toast.success("Test units submitted successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setSelectAllCheck(false);
      } else {
        toast.error("Somthing went Wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  ////////////////////////////////////////// UNIT ///////////////////////////////////////////////////////////


  const fetchAllActiveUnits = () => {
    DashboardServices.getOrganizationsActiveUnits(userDetails.orgId).then(
      (response) => {
        console.log("all Active Units", response.data);
        setAllActiveUnits(response.data);
      }
    );
  };

  console.log("allActiveUnits", allActiveUnits);

  const handleSelectUnitChange = (event, value) => {
    console.log("handleSelectUnitChange", value);
    setSelectActiveUnit(value);
  };

  useEffect(() => {
    fetchDoctorsOfUnit();
  }, [selectActiveUnit]);

  const fetchDoctorsOfUnit = () => {
    if (!selectActiveUnit) return;
    console.log("fetchDoctorsOfUnit start", new Date().getTime())
    DashboardServices.getAllTestMappedWithUnit(
      userDetails.orgId,
      selectActiveUnit.unitId
    ).then((response) => {
      console.log("fetchDoctorsOfUnit response 1", new Date().getTime())
      console.log("Doctors of Selected Unit", response.data);
      setSelectActiveUnitDoctorData(response.data);
      const initialSwitchStates = response.data.map(
        (item) => item.activeFlag === "Y"
      );
      
      const allTrue = initialSwitchStates.every((state) => state === true);
      if (allTrue) {
        setSwitchUnitStates(initialSwitchStates);
        setSelectAllUnitCheck(true);
      } else {
        setSwitchUnitStates(initialSwitchStates);
        setSelectAllUnitCheck(false);
      }
      console.log("fetchDoctorsOfUnit response 2", new Date().getTime())
    });
  };

  useEffect(() => {
    
    console.log("fetchDoctorsOfUnit response 3", new Date().getTime(), switchUnitStates.length)
  }, [switchUnitStates]);

  useEffect(() => {
    
    console.log("fetchDoctorsOfUnit response 4", new Date().getTime(), selectActiveUnitDoctorData.length)
  }, [selectActiveUnitDoctorData]);

  // console.log("selectActiveUnitDoctorData", selectActiveUnitDoctorData);

  const handleUnitChange = (index) => (event) => {
    const newState = [...switchUnitStates];
    newState[index] = event.target.checked;
    setSwitchUnitStates(newState);

    const allFalse = newState.every((state) => !state);
    setSelectAllUnitCheck(allFalse);

    const allTrue = newState.every((state) => state);
    setSelectAllUnitCheck(allTrue);

    const selectNone = newState.every((state) => state === false);
    setSelectNoneUnitCheck(selectNone);
  };

  const handleUnitSelectAll = (event) => {
    const val = event.target.checked;
    if (val) {
      setSwitchUnitStates(new Array(switchUnitStates.length).fill(true));
      setSelectAllUnitCheck(true);
    } else {
      setSwitchUnitStates(new Array(switchUnitStates.length).fill(false));
      setSelectAllUnitCheck(false);
    }
  };

  const finalUnitSubmit = () => {
    if (!selectActiveUnit || !selectActiveUnit.unitId) {
      toast.error("Please Select A Unit", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const reqDto = selectActiveUnitDoctorData.map((item, index) => ({
      testName : item.testName,
      testId : item.testId,
      activeFlag: switchUnitStates[index] ? "Y" : "N",
    }));

    const reqDtoFiltered = reqDto.filter((item) => item.activeFlag === "Y");

    console.log("reqDto Unit", reqDto);

    console.log("reqDtoFiltered Unit", reqDtoFiltered);


    DashboardServices.submitTestsOfUnits(
      selectActiveUnit.unitId,
      reqDtoFiltered
    ).then((response) => {
      if (response.data === 1) {
        toast.success("Test units submitted successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  return (
    <>
      <div className="fullContainArea">
        <div className="cuContainArea">
          <div className="expenditureContainer">
            <div className="expenElement">
              <div className="expElemhead">Test To Unit</div>
              <div className="expContain">
                <div className="elementFormContainer">
                  <div className="formElementGroupWthBtn">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <Autocomplete
                          noOptionsText="Please type to search"
                          className="formAutocompleteField"
                          variant="outlined"
                          disablePortal
                          freeSolo
                          id="combo-box-demo"
                          options={allActiveTests}
                          getOptionLabel={(option) => option.testName || ""}
                          value={selectActiveTest}
                          onChange={handleselectDoctorChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="formAutoComInputField autocompFildPlaceholder"
                              placeholder="Select Test"
                              required
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  </div>

                 
                  {selectActiveTestUnitData.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "80px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="tskElemHeding mt10"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        select All{" "}
                        <input
                          type="checkbox"
                          checked={selectAllCheck}
                          onChange={handleSelectAll}
                        />
                      </div>
                    </div>
                  )}

                  <ul className="accessControlList">
                    {selectActiveTestUnitData.map((value, index) => (
                      <li key={index}>
                        <div className="acsConLstInd">
                          <div className="accessName">
                            <span className="acnIoc">
                              <DragIndicator />
                            </span>
                            <span className="acnName">{value.unitName}</span>
                          </div>
                          <div className="accessControlBtn tblActionBtnGrp">
                            <FormControlLabel
                              className="couponStatusSwitch"
                              control={
                                <Switch
                                  checked={switchStates[index]}
                                  onChange={handleChange(index)}
                                  color="primary"
                                />
                              }
                              label={switchStates[index] ? "Enable" : "Disable"}
                            />
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="elementFooter">
                  <div className="formBtnElement">
                    <Button
                      className="dfultPrimaryBtn"
                      onClick={() => finalSubmit()}
                    >
                      save
                    </Button>

                    {/* <Button className="dfultDarkSecondaryBtn">cancel</Button> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="expenElement">
              <div className="expElemhead">Unit To Test</div>
              <div className="expContain">
                <div className="elementFormContainer">
                  <div className="formElementGroupWthBtn">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <Autocomplete
                          noOptionsText="Please type to search or to Add"
                          id="combo-box-demo"
                          className="formAutocompleteField"
                          variant="outlined"
                          freeSolo
                          disablePortal
                          options={allActiveUnits}
                          getOptionLabel={(option) => option.unitName || ""}
                          value={selectActiveUnit}
                          onChange={handleSelectUnitChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="formAutoComInputField autocompFildPlaceholder"
                              placeholder="Select Unit"
                              required
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  </div>

                  {selectActiveUnitDoctorData.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "80px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="tskElemHeding mt10"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        select All{" "}
                        <input
                          type="checkbox"
                          checked={selectAllUnitCheck}
                          onChange={handleUnitSelectAll}
                        />
                      </div>
                    </div>
                  )}

                  <ul className="accessControlList">
                    {selectActiveUnitDoctorData.map((value, index) => (
                      <li key={index}>
                        <div className="acsConLstInd">
                          <div className="accessName">
                            <span className="acnIoc">
                              <DragIndicator />
                            </span>
                            <span className="acnName">{value.testName}</span>
                          </div>
                          <div className="accessControlBtn tblActionBtnGrp">
                            <FormControlLabel
                              className="couponStatusSwitch"
                              control={
                                <Switch
                                  checked={switchUnitStates[index]}
                                  onChange={handleUnitChange(index)}
                                  color="primary"
                                />
                              }
                              label={
                                switchUnitStates[index] ? "Enable" : "Disable"
                              }
                            />
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="elementFooter">
                  <div className="formBtnElement">
                    <Button
                      className="dfultPrimaryBtn"
                      onClick={() => finalUnitSubmit()}
                    >
                      save
                    </Button>

                    {/* <Button className="dfultDarkSecondaryBtn">cancel</Button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnitTestMapping;
