import React from "react";

export default function EXP() {
  return (
    <>
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.35 7.91147C14.2613 7.91147 15.0001 7.1727 15.0001 6.2614C15.0001 5.35009 14.2613 4.61133 13.35 4.61133C12.4387 4.61133 11.7 5.35009 11.7 6.2614C11.7 7.1727 12.4387 7.91147 13.35 7.91147Z"
          stroke="#B2B2B2"
          stroke-width="0.9"
        />
        <path
          d="M13.3499 6.67269C13.5777 6.67269 13.7624 6.488 13.7624 6.26017C13.7624 6.03235 13.5777 5.84766 13.3499 5.84766C13.1221 5.84766 12.9374 6.03235 12.9374 6.26017C12.9374 6.488 13.1221 6.67269 13.3499 6.67269Z"
          fill="#B2B2B2"
        />
        <path
          d="M5.09961 9.97315C5.78309 9.97315 6.33716 9.41908 6.33716 8.7356C6.33716 8.05212 5.78309 7.49805 5.09961 7.49805C4.41613 7.49805 3.86206 8.05212 3.86206 8.7356C3.86206 9.41908 4.41613 9.97315 5.09961 9.97315Z"
          fill="#B2B2B2"
        />
        <path
          d="M7.57462 1.31055H8.39966C8.85508 1.31055 9.22634 1.67934 9.19747 2.13393C9.03164 4.74104 8.0647 8.73586 5.09952 8.73586C2.13517 8.73586 1.16741 4.74104 1.00158 2.13393C0.972702 1.67934 1.34397 1.31055 1.79939 1.31055H2.62442"
          stroke="#B2B2B2"
          stroke-width="0.9"
          stroke-linecap="round"
        />
        <path
          d="M13.3498 7.91211V9.56218C13.3498 10.6562 12.9152 11.7055 12.1416 12.4791C11.368 13.2527 10.3187 13.6873 9.22466 13.6873C8.1306 13.6873 7.08134 13.2527 6.30772 12.4791C5.5341 11.7055 5.09949 10.6562 5.09949 9.56218V8.73714"
          stroke="#B2B2B2"
          stroke-width="0.9"
          stroke-linecap="round"
        />
      </svg>
    </>
  );
}
