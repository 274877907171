import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import zoyelLogo from "../../images/BrandLogo.svg";
const ContactAddSuccess = (props) => {
  const [linkId, setLinkId] = React.useState(null);
  React.useEffect(() => {
    setTimeout(() => {
      let domainArray = window.location.host.split(".");
      let host = "";
      if (domainArray.length === 3) {
        host = domainArray[1] + "." + domainArray[2];
      } else {
        host = window.location.host;
      }
      window.location.replace(window.location.protocol + "//" + host);
    }, 3000);
  }, []);
  return (
    // <div className="home-container">
    //   <Typography sx={{ display: "flex", position: "absolute", top: "40%" }}>
    //     <img src="/animated_tick.gif" alt="success"></img>
    //   </Typography>
    //   <Typography sx={{ display: "flex", position: "absolute", top: "50%" }}>
    //     <h2>Contact Added Successfully</h2>
    //   </Typography>
    // </div>
    <Grid
      item
      xs={6}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: 10,
      }}
    >
      <Box
        component={Paper}
        elevation={6}
        p={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "50vh",
          backgroundColor: "#333333",
          color: "white",
        }}
      >
        {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <PersonIcon />
            </Avatar> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "20%",
            mb: 1,
          }}
          component="img"
          alt="logo"
          src={zoyelLogo}
        />
        {/* <Typography component="h1" variant="h5">
          <img src={greenTickAnimated} alt="success"></img>
        </Typography> */}
        <br></br>
        <Typography
          component="h1"
          variant="h5"
          sx={{ color: "white !important" }}
        >
          Contact Added Successfully, Redirecting to Zoyel One!!!
        </Typography>
      </Box>
    </Grid>
  );
};

export default ContactAddSuccess;
