// import React from "react";
// // Import React Table
// import ReactTable from "react-table-6";
// import "react-table-6/react-table.css";

// import "../css/drive.css";
// import { Box, Grid } from "@mui/material";

// export default function DriveContainer() {
//   return (
//     <>
//       <Box className="driveContainerArea" sx={{ bgcolor: "dark.pageBg" }}>
//         <Grid container spacing={0} className="driveContainArea">
//           <Grid item xs={12} sx={{ color: "dark.contrastText" }}>
//             <ReactTable></ReactTable>
//           </Grid>
//         </Grid>
//       </Box>
//     </>
//   );
// }
//////////////////////////////////////////////////////////////////

import React from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
  Popover,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import JSZip from "jszip";

// Import React Table
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import "../css/drive.css";
import { Xmark, Folder, GoogleDocs, ArrowDownLeft, Trash } from "iconoir-react";
// import DashboardService from "../../services/DashboardService";
// import { confirm } from "../utils/ConfirmComponent";
import { toast } from "react-toastify";

// import DriveService from "../../services/DriveService";
// import AppUtils from "../../utils/AppUtils";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import Confirmation from "../utils/Confirmation";
// import FileViewer from "./FileViewer";
// import jwt_decode from "jwt-decode";
// import { TailSpinLoader } from "../common-component/Loader";
import AppUtils from "../../../utils/AppUtils";
import {
  Add,
  Cancel,
  CreateNewFolder,
  Delete,
  Description,
  FileCopy,
  FileDownload,
  FileDownloadOutlined,
  Height,
  KeyboardBackspace,
  QuestionMark,
  Search,
  Share,
  ViewList,
  ViewModule,
  WbCloudy,
  Info,
  Close,
} from "@mui/icons-material";
import {
  Download,
  FileEarmarkArrowUp,
  FileEarmarkArrowUpFill,
  FileEarmarkPdfFill,
  FileEarmarkPpt,
  FileEarmarkPptFill,
  FileEarmarkSpreadsheet,
  FileEarmarkSpreadsheetFill,
  FileEarmarkWord,
  FileEarmarkWordFill,
  FolderFill,
  FolderPlus,
  Plus,
  TrashFill,
  FileEarmarkExcelFill,
  FileEarmarkCheckFill,
  FileEarmarkFill,
  FileEarmarkImageFill,
  FileZipFill,
  XLg,
} from "react-bootstrap-icons";

import {
  getFolders,
  getUserStorageDetails,
  getUploadedFilesOutsideFolder,
  uploadFileToDrive,
  getUploadedFilesInsideFolder,
  deleteFileDrive,
  downloadFileAWSV2,
  createFolder,
  getUploadedFiles,
  downloadFileDrive,
  downloadFileDriveFolder,
} from "../../../services/DriveService";

import { jwtDecode } from "jwt-decode";
import DocumentEditor from "../../../utils/DocumentEditor";
// import FileViewer from "./FileViewer";

import { collaboraDomain, apiEndPoint } from "../../../constants/url_provider";
import { ShareFileDrive } from "./ShareFileDrive";
import fileDownload from "js-file-download";
import { driveSocket } from "../../../socket";
import { DriveChild } from "./DriveChild";
import SwipeableEdgeDrawer from "./SwipeableEdgeDrawer";
import { connect } from "react-redux";
import { addFileToQueue } from "../../../redux/reducers/driveSlice";
import { FileMultiShare } from "./FileMultiShare";
import DriveDragDropFiles from "./DriveDragDropFiles";
import RightFlotingAdmin from "../../admin/view/component/RightFlotingAdmin";
import { withTranslation } from 'react-i18next';

class DriveContainer extends React.Component {
  userDetails = jwtDecode(localStorage.getItem("token"));

  constructor() {
    super();
    this.state = {
      numOfSharedUserToShow: 5,
      openSharedUserModal: false,
      data: [],
      fileNameList: [],
      fileNameListInsideFolder: [],
      // totalFileSize: 0,
      uploading: false,
      totalFiles: 0,
      totalStorage: 0,
      usedStorage: 0,
      fileToDelete: null,
      anchorElpop: {},
      openModal: false,
      openedFilesDetails: [],
      refreshData: false,
      loadingData: false,
      folderName: null,
      loading: false,
      folderToDelete: null,
      selectedFolder: null,
      selectedFolderData: [],
      showSharedUser: [],
      dragAndDropModal: false,
      droppedFiles: [],
      isDraggingOver: false,
      isBoxOpen: false,
      isMenuOpen: false,
      anchorEl: null,
      creatFileModel: false,
      searchQuery: "",
      originalData: [],
      tmpFileNameList: [],
      activeFile: null,
      createFileviewer: false,
      // loadingData: true,
      createSlidesModal: false,
      createWordModal: false,
      createSheetModal: false,
      slidesFileName: "",
      newFileName: "",
      newFiles: [],
      openedFilesDetails: [],
      FilesUploaded: null,
      FilesUploadedCount: 0,
      storagePercentage: 0,
      openShareModal: false,
      selectedFileToShare: null,
      FilesUploaded: [],
      selectedFileToOpen: null,
      fileWithStatus: [],
      selectedRows: [],
      isSelect: false,
      openMultipleFileShareModal: false,
      selectedFiles: [],
      openDeleteModal: false,
      selectedDeleteFiles: [],
      tState: null,
    };
    this.fileUploadRef = React.createRef(null);
    this.dropFileUploadRef = React.createRef(null);
    this.dropZoneRef = React.createRef();
  }

  // openFile = (fileDetails) => {
  //   console.log('open file 1')
  //   // console.log('tmpFileNameList', tmpFileNameList)
  //   console.log("Opening file:", fileDetails.fileName);
  //   if (this.props.sharedFileDetails) {
  //     //  this.props.openFile(this.state.sharedFileId);
  //     const index = this.state.openedFilesDetails.findIndex(
  //       (item) => item.fileId === this.props.sharedFileDetails.fileId
  //     );
  //     console.log(index);
  //     console.log(this.state.openedFilesDetails);
  //     if (index === -1) {
  //       if (this.state.openModal === false) {
  //         this.setState((prev) => ({
  //           ...prev,
  //           openModal: true,
  //         }));
  //       }
  //       this.setState((prev) => {
  //         console.log(prev);
  //         const newFile = [
  //           ...prev.openedFilesDetails,
  //           {
  //             fileId: this.props.sharedFileDetails.fileId,
  //             fileName: this.props.sharedFileDetails.fileName,
  //             fullScreen: true,
  //             sharedBy: this.props.sharedFileDetails.sharedBy,
  //             folderId: this.props.sharedFileDetails.folderId,
  //             folderPath: this.props.sharedFileDetails.folderPath,
  //           },
  //         ];
  //         console.log("newFile", newFile);
  //         return {
  //           ...prev,
  //           openedFilesDetails: [
  //             ...prev.openedFilesDetails,
  //             {
  //               fileId: this.props.sharedFileDetails.fileId,
  //               fileName: this.props.sharedFileDetails.fileName,
  //               fullScreen: true,
  //               sharedBy: this.props.sharedFileDetails.sharedBy,
  //               folderId: this.props.sharedFileDetails.folderId,
  //               folderPath: this.props.sharedFileDetails.folderPath,
  //             },
  //           ],
  //         };
  //       });
  //     }
  //   }
  // };

  getFileIcon = (fileType) => {
    switch (fileType) {
      case "pdf":
        return <FileEarmarkPdfFill />;
      case "pptx":
        return <FileEarmarkPptFill />;
      case "doc":
        return <FileEarmarkWordFill />;
      case "docx":
        return <FileEarmarkWordFill />;
      case "xlsx":
        return <FileEarmarkExcelFill />;
      case "jpg":
        return <FileEarmarkImageFill />;
      case "jpeg":
        return <FileEarmarkImageFill />;
      case "zip":
        return <FileZipFill />;
      default:
        return <FileEarmarkFill />;
    }
  };

  // handleFileClickByType = (fileDetails) => {
  //   console.log("fileDetails", fileDetails);
  //   const { name } = fileDetails;
  //   const fileExtension = name.split(".").pop().toLowerCase();

  //   switch (fileExtension) {
  //     case "pdf":
  //       this.handleFileClick(fileDetails);
  //       break;
  //     case "pptx":
  //       this.handleFileClick(fileDetails);
  //       break;
  //     case "doc":
  //       this.handleFileClick(fileDetails);
  //       break;
  //     case "xlsx":
  //       this.handleFileClick(fileDetails);
  //       break;
  //     default:
  //   }
  // };

  hasNotViewableExtension(str) {
    var validExtensions =
      /\.(zip|mp4|gif|ogg|wav|7z|arj|pkg|rar|rpm|tar|bin|dmg|iso|csv|dat|sql|tar|log|apk|bin|jar|py|mp3)$/i;

    if (
      [
        "zip",
        "mp3",
        "mp4",
        "gif",
        "ogg",
        "wav",
        "7z",
        "arj",
        "pkg",
        "rar",
        "rpm",
        "tar",
        "bin",
        "dmg",
        "iso",
        "csv",
        "dat",
        "sql",
        "tar",
        "log",
        "apk",
        "bin",
        "jar",
        "py",
      ].includes(str.toLowerCase())
    ) {
      return true;
    }

    return validExtensions.test(str);
  }

  handleFileClickByType = (fileDetails) => {
    console.log("fileDetails", fileDetails);
    const { name } = fileDetails;
    const fileExtension = name.split(".").pop().toLowerCase();

    if (this.hasNotViewableExtension(fileExtension)) {
      console.log("File has a non-viewable extension.");
      this.downloadFile(
        fileDetails.fileId,
        fileDetails.name,
        fileDetails.folderPath
      );
      return;
    }

    this.handleFileClick(fileDetails);
  };

  openFile = (fileDetails) => {
    console.log("openFile fileDetails:", fileDetails);

    if (fileDetails && fileDetails.fileName) {
      const index = this.state.openedFilesDetails.findIndex(
        (item) => item.fileId === fileDetails.fileId
      );

      console.log("index:", index);
      console.log("openedFilesDetails:", this.state.openedFilesDetails);

      if (index === -1) {
        if (!this.state.openModal) {
          this.setState((prev) => ({
            ...prev,
            openModal: true,
          }));
        }

        const tmpFileNameList = [...this.state.tmpFileNameList];

        if (fileDetails.fileName) {
          tmpFileNameList.push(fileDetails.fileName.toLowerCase());
        }

        this.setState((prev) => ({
          ...prev,
          tmpFileNameList: tmpFileNameList,
          openedFilesDetails: [
            ...prev.openedFilesDetails,
            {
              fileId: fileDetails.fileId,
              fileName: fileDetails.fileName,
              fullScreen: true,
              sharedBy: fileDetails.sharedBy,
              folderId: fileDetails.folderId,
              folderPath: fileDetails.folderPath,
            },
          ],
          activeFile: fileDetails,
        }));
      }
    }
  };

  componentDidMount() {
    this.fetchDriveData();
    this.openFile(this.props.sharedFileDetails);
    driveSocket.on("onFileUploadedRefresh", (data) => {
      console.log("onFileUploadedRefresh", data);
      this.fetchDriveData();
    });

    // driveSocket.on(
    //   "refreshOnShareRecGrant",
    //   ({ userName, fileName, permission }) => {
    //     console.log("refreshOnShareRecGrant", userName, fileName, permission);
    //     // this.fetchDriveData();
    //   }
    // );

    // driveSocket.on(
    //   "refreshOnShareRecRevoke",
    //   ({ userName, fileName, permission }) => {
    //     console.log("refreshOnShareRecGrant", userName, fileName, permission);
    //     // this.fetchDriveData();
    //   }
    // );
  }

  //   componentDidUpdate(prevProps) {
  //     console.log("component updated", this.props.filesUploadedCount);
  //     if (this.props.sharedFileDetails !== prevProps.sharedFileDetails) {
  //       // Perform the necessary updates in the component
  //       // You can call setState to trigger a re-render or perform any other actions here
  //       // For example, you might want to update the component's state:
  //       // this.setState({ someState: this.props.someProp });
  //       this.openFile();
  //     }
  //     if (this.props.refreshDataByFcm !== prevProps.refreshDataByFcm) {
  //       // Perform the necessary updates in the component
  //       // You can call setState to trigger a re-render or perform any other actions here
  //       // For example, you might want to update the component's state:
  //       // this.setState({ someState: this.props.someProp });
  //       this.fetchDriveData();
  //     }
  //     // if (this.props.filesUploadedCount !== this.state.totalFiles) {
  //     //   this.fetchDriveData();
  //     // }
  //     // this.fetchDriveData();
  //   }

  async fetchDriveData() {
    this.setState({ loadingData: true });
    const response = await getFolders(this.userDetails.userId);
    let folders = response.data;
    let totalSize = 0;

    await getUserStorageDetails(this.userDetails.userId).then((response) => {
      console.log(response.data);
      this.setState({ totalStorage: response.data.storage });
      totalSize = (response.data.usedStorage / 1024).toFixed(2);
      // totalSize = Math.round((totalSize + Number.EPSILON) * 100) / 100;
      console.log("totalSize", totalSize);
      this.setState({ usedStorage: totalSize });

      this.calculateStoragePercentage(
        response.data.storage * 1024 * 1024,
        response.data.usedStorage
      );
    });

    getUploadedFilesOutsideFolder(this.userDetails.userId).then((response) => {
      if (response && response.data) {
        // let totalSize = response.data.reduce(
        //   (a, curr) => a + curr.fileSize,
        //   0
        // );
        let tmpFileNameList = [];
        response.data.forEach((file) => {
          console.log("file--->", file);
          tmpFileNameList.push(file.fileName.toLowerCase());
        });
        // totalSize = this.state / 1024;
        // totalSize = Math.round((totalSize + Number.EPSILON) * 100) / 100;

        const fileDetailsList = response.data.map((file) => ({
          fileId: file.fileId,
          fileName: file.fileName,
          sharedBy: file.sharedBy,
          folderId: file.folderId,
          folderPath: file.folderPath,
        }));

        fileDetailsList.forEach((fileDetails) => {
          this.openFile(fileDetails);
        });

        let filesAndFolders = [];

        for (let index = 0; index < folders.length; index++) {
          const folderDto = folders[index];
          let tempDto = {
            isFolder: true,
            folderName: folderDto.folderName,
            folderId: folderDto.folderId,
            folderCreatedDate: folderDto.createdOn,
            folderFileCount: folderDto.fileCount,
          };
          filesAndFolders.push(tempDto);
          console.log("tempDto", tempDto);
        }

        const concatList = filesAndFolders.concat(response.data);
        console.log(concatList);
        // console.log("filesAndFolders", concatList);
        console.log("fileNameList", this.state.fileNameList);

        // return;
        this.setState({
          data: filesAndFolders.concat(response.data),
          originalData: filesAndFolders.concat(response.data),
          fileNameList: tmpFileNameList,
          // totalFileSize: totalSize,
          uploading: false,
          totalFiles: response.data.length,
        });
        // this.props.setFilesUploadedCount(response.data.length);
        this.setState({ loadingData: false });
      }
      this.setState({ loadingData: false });
    });
  }

  openFile(fileId) {
    this.props.openFile(fileId);
    console.log("open file 3");
  }

  handleClick = (event, fileId) => {
    console.log("Delete button clicked for file ID:", fileId);
    this.setState({
      anchorElpop: {
        ...this.state.anchorElpop,
        [fileId]: event.currentTarget,
      },
      fileIdToDelete: fileId,
    });
  };

  handleClose = () => {
    this.setState({
      anchorElpop: {},
      fileIdToDelete: null,
    });
  };

  async deleteFile(fileIdToDelete) {
    console.log(
      "fileNameListInsideFolder",
      this.state.fileNameListInsideFolder
    );
    this.setState({ loadingData: true });
    const fileId = fileIdToDelete;
    console.log("deletefile-fileId", fileId);

    try {
      await deleteFileDrive(
        fileId,
        this.userDetails.userId,
        this.userDetails.orgId
      );

      // Update fileNameListInsideFolder after deleting the file
      const updatedFileNameListInsideFolder =
        this.state.fileNameListInsideFolder.filter(
          (fileName) =>
            !this.state.selectedFolderData.find(
              (file) =>
                file.fileId === fileIdToDelete &&
                file.fileName.toLowerCase() === fileName.toLowerCase()
            )
        );

      this.setState((prevState) => ({
        FilesUploaded: prevState.FilesUploaded.filter(
          (file) => file.fileId !== fileId
        ),
        fileNameListInsideFolder: updatedFileNameListInsideFolder, // Update fileNameListInsideFolder state
      }));

      // Fetch updated drive data after deletion
      await this.fetchDriveData();

      this.setState((prevState) => ({
        data: prevState.data.filter((item) => item.fileId !== fileId),
        selectedFolderData: prevState.selectedFolderData.filter(
          (item) => item.fileId !== fileId
        ),
        fileIdToDelete: null,
      }));
      console.log("selectedFolderData", this.state.selectedFolderData);

      // toast.success("File deleted successfully", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
       toast.success(this.tState("file_deleted_successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error deleting file:", error);
      toast.error(this.tState("error_deleting_file_please_try_again"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      this.setState({ loadingData: false });
    }
  }

  handleFileUpload(e) {
    console.log("handleFileUpload", e, this.props.tabkey);

    const data = {
      module: "drive",
      key: this.props.tabkey,
      loading: true,
    };
    console.log("data of drive", data);

    localStorage.setItem("driveData", JSON.stringify(data));

    this.setState({ loadingData: true });
    const id = toast.loading(
      this.tState("uploading_file_please_wait_and_do_not_close_the_page")
    );

    const selectedFolderId = this.state.selectedFolder
      ? this.state.selectedFolder.folderId
      : null;

    console.log("selectedFolderId", selectedFolderId);
    console.log("this.state.selectedFolderData", this.state.selectedFolderData);

    const fileNames = this.state.selectedFolderData.map(
      (file) => file.fileName
    );
    this.setState({ fileNameListInsideFolder: fileNames });

    console.log("fileNames", fileNames);
    console.log(
      "fileNameListInsideFolder",
      this.state.fileNameListInsideFolder
    );

    // return;
    // toast.info(`Please wait while the File is getting Uploaded`, {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
    if (!e.target.files) {
      return;
    }

    let files = Array.from(e.target.files);
    console.log("uploaded files ", files);

    files = files.filter((item) => {
      const extention = item.name.split(".").pop();

      if (AppUtils.isValidExtention(extention)) {
        if (item.size > AppUtils.maxSizePerFile) {
          console.log("exceeding value");
          // toast.error(
          //   `Cannot upload file ${item.name}, exceeds maximum file size of 100 MB.`,
          //   {
          //     position: toast.POSITION.TOP_RIGHT,
          //   }
          // );
        ;
          toast.update(id, {
            render: this.tState('drive_fileSizeError', { fileName: item.name }),
            type: "error",
            isLoading: false,
            autoClose: true,
          });

          this.setState({ loadingData: false });
          return false;
        } else {
          this.setState({ loadingData: false });
          return true;
        }
      } else {
        // toast.error(
        //   `Cannot upload file ${item.name}, ${extention} is not supported, please check the supported extensions in the question mark(?) on the right`,
        //   {
        //     position: toast.POSITION.TOP_RIGHT,
        //   }
        // );
        toast.update(id, {
          // render: `Cannot upload file ${item.name}, ${extention} is not supported, please check the supported extensions in the question mark(?) on the right`,
          render: this.tState('drive_fileExtensionError', { 
            fileName: item.name, 
            extension: extention 
          }),
          type: "error",
          isLoading: false,
          autoClose: true,
        });
        this.setState({ loadingData: false });
        return false;
      }
    });

    let numberOfFiles = files.length;
    // console.log(files.length);

    let fileUploadedCount = 0;
    let filesSize = 0;

    console.log(files);

    for (let count = 0; count < files.length; count++) {
      filesSize += files[count].size;
    }

    console.log(filesSize);
    // console.log(this.state.totalFileSize * 1024 * 1024);
    // console.log(this.state.totalStorage * 1024 * 1024 * 1024);

    let freeSpace =
      this.state.totalStorage * 1024 * 1024 * 1024 -
      this.state.usedStorage * 1024 * 1024;
    // console.log(freeSpace);

    // if (filesSize > freeSpace) {
    //   toast.error(
    //     `Cannot upload file(s), Maximum drive storage of ${this.state.totalStorage} GB will be exceeded`,
    //     {
    //       position: toast.POSITION.TOP_RIGHT,
    //     }
    //   );
    //   return false;
    // }

    if (filesSize > freeSpace) {
      // toast.error(
      //   `Cannot upload file(s), Maximum drive storage of ${this.state.totalStorage} GB will be exceeded`,
      //   {
      //     position: toast.POSITION.TOP_RIGHT,
      //   }
      // );
      toast.update(id, {
        // render: `Cannot upload file(s), Maximum drive storage of ${this.state.totalStorage} GB will be exceeded`,
        render: this.tState('drive_maxStorageError', { storage: this.state.totalStorage }),
        type: "error",
        isLoading: false,
        autoClose: true,
      });
      return false;
    }

    console.log("fileNameList", this.state.fileNameList);

    console.log(
      "fileNameListInsideFolder",
      this.state.fileNameListInsideFolder
    );

    for (let count = 0; count < files.length; count++) {
      const formData = new FormData();
      if (
        selectedFolderId
          ? this.state.fileNameListInsideFolder.includes(
              files[count].name.toLowerCase()
            )
          : this.state.fileNameList.includes(files[count].name.toLowerCase())
      ) {
        // toast.warning(`${files[count].name} already present`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });

        toast.update(id, {
          // render: `${files[count].name} already present`,
          render: this.tState('drive_fileAlreadyPresent', { fileName: files[count].name }),
          type: "error",
          isLoading: false,
          autoClose: true,
        });
      }

      // const isFileAlreadyUploaded = files.some((file) =>
      //   this.state.FilesUploaded.some(
      //     (uploadedFile) =>
      //       uploadedFile.fileName.toLowerCase() === file.name.toLowerCase()
      //   )
      // );
      // if (isFileAlreadyUploaded) {
      //   toast.update(id, {
      //         render: "File already present. Please upload a different file.",
      //         type: "error",
      //         isLoading: false,
      //         autoClose: true,
      //       });
      //   return;
      // }
      else {
        formData.append("file", files[count]);
        formData.append("userId", this.userDetails.userId);
        formData.append("orgId", this.userDetails.orgId);
        formData.append(
          "folderId",
          this.state.selectedFolder ? this.state.selectedFolder.folderId : null
        );
        // this.setState({ uploading: true });

        console.log("formData for upload ", formData.get("file"));
        console.log("formData for upload ", formData);

        const temp = {
          fileName: files[count].name,
          folderName: this.state.selectedFolder
            ? this.state.selectedFolder.folderId
            : null,
          progress: 0,
          status: "uploading",
        };

        // const tempList = [...this.state.fileWithStatus];
        // tempList.push(temp);
        this.setState((prev) => ({
          ...prev,
          fileWithStatus: [...prev.fileWithStatus, temp],
        }));

        uploadFileToDrive(formData).then(
          (response) => {
            console.log(response.data);
            // fileUploadedCount++;
            // console.log(fileUploadedCount, numberOfFiles);
            const data = {
              module: "drive",
              key: this.props.tabkey,
              loading: false,
            };
            localStorage.setItem("driveData", JSON.stringify(data));
            console.log("uploadFileToDrive", response.data);
            // if (fileUploadedCount === numberOfFiles) {
            if (this.state.selectedFolder) {
              this.openFolder(this.state.selectedFolder);
              this.fetchDriveData();
            } else {
              this.fetchDriveData();
            }

            e.target.value = "";

            this.setState({ loadingData: false });

            toast.update(id, {
              render: this.tState("file_uploaded_successfully"),
              type: "success",
              isLoading: false,
              autoClose: true,
            });
            this.setState((prev) => {
              const temp = [...prev.fileWithStatus];
              temp.splice(count);
              // temp[count].status = "success";
              return { ...prev, fileWithStatus: temp };
            });

            // const data = {
            //   module: 'drive',
            //   key: this.props.tabkey,
            //   loading: false
            //  };
            //  localStorage.setItem('driveData', JSON.stringify(data));

            this.setState({
              anchorEl: null,
              isMenuOpen: false,
            });

            driveSocket.emit("onFileUploaded", {
              userId: this.userDetails.userId,
            });
          }
          // toast.success(`File(s) uploaded successfully`, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          // }
        );
      }
    }
    this.fileUploadRef.current.value = null;
  }

  createFolder() {
    console.log("createFolder", this.state.folderName);
    this.setState({ loading: true });
    if (!this.state.folderName || this.state.folderName.trim() === "") {
      toast.error(this.tState("please_enter_a_valid_folder_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }
    createFolder(this.state.folderName, this.userDetails.userId).then(
      (response) => {
        console.log(response.data);
        if (response && response.data && response.data.returnValue === "1") {
          this.setState({
            folderName: "",
            loading: false,
            creatFileModel: false,
          });
          toast.success(this.tState("folder_created_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.fetchDriveData();
        } else {
          toast.error(this.tState("fail_to_create_folder"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ loading: false });
        }
      }
    );
  }

  // async deleteFolder(fileId) {
  //   // if (await confirm("Are your sure?")) {
  //   await deleteFileDrive(
  //     fileId,
  //     this.userDetails.userId,
  //     this.userDetails.orgId
  //   ).then((response) => {
  //     this.fetchDriveData();
  //   });
  //   // } else {
  //   //   console.log("Delete Cancelled");
  //   // }
  // }

  // hasNotViewableExtension(str) {
  //   localStorage.setItem("hello", { sadwd: "dwdwd" });
  //   // Define a regular expression pattern to match the specified extensions
  //   // var validExtensions = /\.(tar|zip|rar|war|mp4|mp3)$/;

  //   var validExtensions =
  //     /\.(mp4|gif|ogg|wav|7z|arj|pkg|rar|rpm|tar|zip|bin|dmg|iso|csv|dat|sql|tar|log|apk|bin|jar|py|mp3)$/i;

  //   // Use the test() method to check if the string contains any of these extensions
  //   return validExtensions.test(str);
  // }

  // openFileMultiple(fileDto) {
  //   console.log("hasNotViewableExtension --->", fileDto);
  //   if (this.hasNotViewableExtension(fileDto.fileName)) {
  //     console.log("hasNotViewableExtension", fileDto);
  //     toast.info("Downloading file please wait", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     // MeetingService.downloadFile(fileDto.fileName, fileDto.folderPath).then(
  //     //   (response) => {
  //     //     window.open(
  //     //       apiEndPoint +
  //     //         `/appTest/downloadFileTest/${fileDto.fileName}/${fileDto.folderPath}`,
  //     //       "_blank",
  //     //       "noopener,noreferrer"
  //     //     );
  //     //   }
  //     // );
  //     let reqDto = {
  //       field1: fileDto.fileName,
  //       field2: fileDto.folderPath,
  //     };
  //     console.log("downloadFileAWSV2", reqDto);
  //     downloadFileAWSV2(reqDto).then((response) => {
  //       console.log(response.data);
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", fileDto.fileName); // Set the desired filename and extension
  //       document.body.appendChild(link);
  //       link.click();
  //       // window.open(response, "_blank", "noopener,noreferrer");
  //     });
  //   } else {
  //     const index = this.state.openedFilesDetails.findIndex(
  //       (item) => item.fileId === fileDto.fileId
  //     );
  //     // console.log(index);
  //     // console.log(this.state.openedFilesDetails);
  //     if (index === -1) {
  //       if (this.state.openModal === false) {
  //         this.setState((prev) => ({
  //           ...prev,
  //           openModal: true,
  //         }));
  //       }
  //       this.setState((prev) => {
  //         console.log(prev);
  //         const newFile = [
  //           ...prev.openedFilesDetails,
  //           {
  //             fileId: fileDto.fileId,
  //             fileName: fileDto.fileName,
  //             sharedBy: fileDto.sharedBy,
  //             folderId: fileDto.folderId,
  //             folderPath: fileDto.folderPath,
  //           },
  //         ];
  //         console.log("newFile", newFile);
  //         return {
  //           ...prev,
  //           openedFilesDetails: [
  //             ...prev.openedFilesDetails,
  //             {
  //               fileId: fileDto.fileId,
  //               fileName: fileDto.fileName,
  //               sharedBy: fileDto.sharedBy,
  //               folderId: fileDto.folderId,
  //               folderPath: fileDto.folderPath,
  //             },
  //           ],
  //         };
  //       });
  //     }
  //   }
  // }

  downloadFile = (fileId, fileName, folderPath, folderId) => {
    console.log("reqDto", fileId, fileName, folderPath);
    let orgId = folderPath.split("/")[1];

    if (folderId) {
      window.open(
        apiEndPoint +
          `/drive/downloadFileDriveFolder/${fileName}/${orgId}/${folderId}`,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      window.open(
        apiEndPoint + `/drive/downloadFileDrive/${fileName}/${orgId}`,
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  openFolder(folderDto) {
    console.log("openFolder", folderDto);
    this.setState({ loadingData: true });
    this.setState({ selectedFolder: folderDto });
    console.log("folderDto", folderDto);

    getUploadedFilesInsideFolder(folderDto.folderId).then((response) => {
      if (response && response.data) {
        let tmpFileNameList = [];
        response.data.forEach((file) => {
          console.log("file--->", file);
          tmpFileNameList.push(file.fileName.toLowerCase());
        });

        console.log("fileNameList", this.state.fileNameList);

        this.setState({
          selectedFolderData: response.data,
          fileNameListInsideFolder: tmpFileNameList,
          uploading: false,
        });
        console.log("selectedFolderData", this.state.selectedFolderData);
        // this.props.setFilesUploadedCount(response.data.length);
        this.setState({ loadingData: false });
        this.fetchDriveData();
      }
      this.setState({ loadingData: false });
    });
    // this.fetchDriveData();
  }

  //   removeFile = (indexToRemove) => {
  //     this.setState((prevState) => {
  //       const updatedFiles = prevState.droppedFiles.filter(
  //         (file, index) => index !== indexToRemove
  //       );
  //       return {
  //         droppedFiles: updatedFiles,
  //       };
  //     });
  //   };

  //   handleCancelModal = () => {
  //     this.setState((prev) => ({ ...prev, dragAndDropModal: false }));
  //     this.setState({
  //       droppedFiles: [],
  //     });
  //   };

  //   componentDidUpdate(prevProps, prevState) {
  //     if (
  //       this.state.droppedFiles.length === 0 &&
  //       prevState.droppedFiles.length > 0
  //     ) {
  //       this.setState({ dragAndDropModal: false });
  //     }
  //   }

  // onSubmitNewFile = async (fileName , fileType) => {
  //   console.log("onSubmitNewFile", fileName);
  //   this.setState({ uploading: true });

  //   if (fileName == null || fileName == null || fileName.trim() === "") {
  //     toast.error("Please enter a valid file name", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     this.setState({ uploading: false });
  //     return;
  //   }

  //   if (fileName.indexOf("\\") !== -1 || fileName.indexOf("/") !== -1) {
  //     toast.error(
  //       "Please enter a valid file name, special characters are not allowed",
  //       { position: toast.POSITION.TOP_RIGHT }
  //     );
  //     this.setState({ uploading: false });
  //     return;
  //   }

  //   let isFileNamePresent = false;
  //   let duplicateFileId = null;
  //   let filesUploadedTemp = [];

  //   await getUploadedFiles(this.userDetails.userId).then((response) => {
  //     filesUploadedTemp = response.data;
  //     this.setState({...this.state, FilesUploaded: filesUploadedTemp});
  //   });

  //   filesUploadedTemp.forEach((fileObj, index) => {
  //     const fileExt = fileName.substring(
  //       fileName.lastIndexOf(".") + 1,
  //       fileName.length
  //     );
  //     if (fileObj.fileName === fileName + "." + fileExt) {
  //       isFileNamePresent = true;
  //       duplicateFileId = fileObj.fileId;
  //     }
  //   });

  //   // If the file already exists, notify the user and return early
  //   if (isFileNamePresent) {
  //     toast.error("File already exists", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     return;
  //   }

  //   // Proceed with the file upload logic here...
  //   // Upload the new file to the drive
  //   this.uploadNewFile({ fileName , fileType}); // Pass the fileName as an object property
  // };

  // uploadNewFile = (data ) => {
  //   console.log(
  //     "uploadNewFile",
  //     data,
  //     this.userDetails.userId,
  //     this.userDetails.orgId
  //   );
  //   const formData = new FormData();
  //   // const fileExt = data.fileName.substring(
  //   //   data.fileName.lastIndexOf(".") + 1,
  //   //   data.fileName.length
  //   // );

  // //  pptx , doc , xlsx

  // //  const fileExt = 'pptx'

  //  const fileExt = data.fileName.substring(
  //   data.fileName.lastIndexOf(".") + 1,
  //   data.fileName.length
  // );

  //   formData.append("fileName", data.fileName + "." + fileExt);
  //   formData.append("userId", this.userDetails.userId);
  //   formData.append("orgId", this.userDetails.orgId);
  //   formData.append("folderId", null);

  //   uploadFileToDrive(formData).then((response) => {
  //     const newFiles = [
  //       ...this.state.openedFilesDetails,
  //       {
  //         fileId: response.data.message,
  //         fileName: data.fileName,
  //         sharedBy: null,
  //       },
  //     ];
  //     this.setState({openedFilesDetails : newFiles});

  //     // this.reset({
  //     //   fileName: "",
  //     // });

  //     getUploadedFiles(this.userDetails.userId).then((response) => {
  //       // this.setFilesUploaded(response.data);
  //       this.setState({ FilesUploaded: response.data });
  //       this.setState({FilesUploadedCount :response.data});
  //       this.setState({ uploading: false });
  //       toast.success("File Created Successfully", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     });
  //     this.fetchDriveData()
  //   });
  // };

  onSubmitNewFile = async (fileName, fileType) => {
    console.log(
      "onSubmitNewFile",
      fileName,
      fileType,
      this.state.selectedFolder?.folderId
    );
    // return;
    this.setState({ uploading: true });

    try {
      if (!fileName || fileName.trim() === "") {
        toast.error(this.tState("please_enter_a_valid_file_name"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
      if (specialCharsRegex.test(fileName)) {
        toast.error(
          this.tState("please_enter_a_valid_file_name_special_characters_are_not_allowed"),
          { position: toast.POSITION.TOP_RIGHT }
        );
        return;
      }

      const response = await getUploadedFiles(this.userDetails.userId);
      const filesUploadedTemp = response.data;
      console.log("response.data", response.data);
      console.log("response.data.length", response.data.length);
      this.setState({ ...this.state, FilesUploaded: filesUploadedTemp });

      // const fileExt = fileName.substring(
      //   fileName.lastIndexOf(".") + 1,
      //   fileName.length
      // );

      const fileExt = fileType;

      // const isFileNamePresent = filesUploadedTemp.some(
      //   (fileObj) => fileObj.fileName === fileName + "." + fileExt
      // );

      console.log("fileNameList", this.state.fileNameList);

      const isFileNamePresent = this.state.fileNameList.some(
        (fileObj) => fileObj === (fileName + "." + fileExt).toLowerCase()
      );

      const fileNames = this.state.selectedFolderData.map(
        (file) => file.fileName || []
      );
      this.setState({ fileNameListInsideFolder: fileNames });

      console.log("2", this.state.fileNameListInsideFolder);

      //  const isFileNamePresentInsideFolder = this.state.fileNameListInsideFolder.some((file)=>
      //  file === fileName + "." + fileExt)

      const isFileNamePresentInsideFolder = fileNames.some((file) => {
        console.log("file", file);
        console.log("fileName", fileName);
        console.log("fileExt", fileExt);
        return file === fileName + "." + fileExt;
      });

      console.log(
        "isFileNamePresentInsideFolder",
        isFileNamePresentInsideFolder
      );

      console.log("isFileNamePresent", isFileNamePresent);

      if (
        this.state.selectedFolder?.folderId
          ? isFileNamePresentInsideFolder
          : isFileNamePresent
      ) {
        toast.warning(
          // `${fileName}.${fileExt} Already Present. Please Create a File `,
          this.tState('drive_file_already_present', { fileName, fileExt }),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );

        return;
      }

      this.uploadNewFile({ fileName, fileType });
    } catch (error) {
      console.error("Error in file submission:", error.message);
      toast.error(this.tState("fail_to_upload_file"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      this.setState({ uploading: false });
    }
  };

  // onSubmitNewFile = async (fileName, fileType) => {
  //   console.log("onSubmitNewFile", fileName, fileType);
  //   this.setState({ uploading: true });

  //   if (fileName == null || fileName.trim() === "") {
  //     toast.error("Please enter a valid file name", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     this.setState({ uploading: false });
  //     return;
  //   }

  //   if (fileName.indexOf("\\") !== -1 || fileName.indexOf("/") !== -1) {
  //     toast.error(
  //       "Please enter a valid file name, special characters are not allowed",
  //       { position: toast.POSITION.TOP_RIGHT }
  //     );
  //     this.setState({ uploading: false });
  //     return;
  //   }

  //   let isFileNamePresent = false;
  //   let filesUploadedTemp = [];

  //   await getUploadedFiles(this.userDetails.userId).then((response) => {
  //     filesUploadedTemp = response.data;
  //     this.setState({ ...this.state, FilesUploaded: filesUploadedTemp });
  //   });

  //   filesUploadedTemp.forEach((fileObj, index) => {
  //     const fileExt = fileName.substring(
  //       fileName.lastIndexOf(".") + 1,
  //       fileName.length
  //     );
  //     if (fileObj.fileName === fileName + "." + fileExt) {
  //       isFileNamePresent = true;
  //     }
  //   });

  //   // If the file already exists, notify the user and return early
  //   if (isFileNamePresent) {
  //     toast.error("File already exists", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     return;
  //   }

  //   // Proceed with the file upload logic here...
  //   // Upload the new file to the drive based on the file type
  //   this.uploadNewFile({ fileName, fileType }); // Pass the fileType as an object property
  // };

  uploadNewFile = (data) => {
    console.log(
      "uploadNewFile",
      data,
      this.userDetails.userId,
      this.userDetails.orgId
    );
    const formData = new FormData();
    const fileExt = data.fileType;

    formData.append("fileName", data.fileName + "." + fileExt);
    formData.append("userId", this.userDetails.userId);
    formData.append("orgId", this.userDetails.orgId);
    formData.append(
      "folderId",
      this.state.selectedFolder ? this.state.selectedFolder.folderId : null
    );

    uploadFileToDrive(formData).then((response) => {
      const newFiles = [
        ...this.state.openedFilesDetails,
        {
          fileId: response.data.message,
          fileName: data.fileName,
          sharedBy: null,
        },
      ];
      this.setState({ openedFilesDetails: newFiles });

      getUploadedFiles(this.userDetails.userId).then((response) => {
        this.setState({ FilesUploaded: response.data });
        this.setState({ FilesUploadedCount: response.data.length });
        this.setState({ uploading: false });
        toast.success(this.tState("file_created_successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ createSlidesModal: false });
        this.setState({ createWordModal: false });
        this.setState({ createSheetModal: false });
      });
      if (this.state.selectedFolder) {
        this.openFolder(this.state.selectedFolder);
      } else {
        this.fetchDriveData();
      }
    });
  };

  // handleFileNameChange = (e) => {
  //   this.setState({ slidesFileName: e.target.value });
  // };

  handleSearchChange = async (e) => {
    const searchQuery = e.target.value.toLowerCase();

    // If the search query is empty, revert to the original data
    if (searchQuery === "") {
      this.setState({
        searchQuery,
        data: [...this.state.originalData],
      });
    } else {
      const filteredData = this.state.originalData.filter(
        (item) =>
          (item.name && item.name.toLowerCase().includes(searchQuery)) ||
          (item.fileName &&
            item.fileName.toLowerCase().includes(searchQuery)) ||
          (item.folderName &&
            item.folderName.toLowerCase().includes(searchQuery))
      );

      this.setState({
        searchQuery,
        data: [...filteredData],
      });
    }
  };

  // handleFileClick = (fileDetails) => {
  //   this.setState({ createFileviewer: true, activeFile: fileDetails });
  // };

  // handlePptxFileClick = (fileDetails) => {
  //   this.setState({ createFileviewer: true, activeFile: fileDetails });
  // };

  // openFileModal = (fileDetails) => {
  //   console.log("Open File Modal for file ID:", fileDetails.fileId);
  //   this.setState({
  //     createFileviewer: true,
  //     activeFile: fileDetails,
  //   });
  // };

  // handleCloseFileModal = () => {
  //   this.setState({
  //     createFileviewer: false,
  //     activeFile: null,
  //   });
  // };

  handleRowSelection(event, row) {
    console.log("row", row, "event", event.target.checked);
    console.log("selectedRows", this.state.selectedRows);
    if (this.state.fileWithStatus.length === 0) {
      const { selectedRows } = this.state;
      if (event.target.checked) {
        // Add row to selectedRows if checked
        this.setState({
          selectedRows: [...selectedRows, row],
        });
      } else {
        // Remove row from selectedRows if unchecked
        this.setState({
          selectedRows: selectedRows.filter(
            (selectedRow) => selectedRow.fileId !== row.fileId
          ),
        });
      }
      console.log("Selected rows:", this.state.selectedRows);
    } else {
      console.log("file");
    }
  }

  deleteSelectedFiles = (selectedRows) => {
    console.log("deleteSelectedFiles");
    this.setState({
      openDeleteModal: true,
      selectedDeleteFiles: selectedRows,
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
      selectedRows: [],
      selectedDeleteFiles: [],
    });
  };

  handleRemoveFile = (fileId) => {
    this.setState((prevState) => {
      const updatedFiles = prevState.selectedDeleteFiles.filter(
        (file) => file.fileId !== fileId
      );
      // Close the modal if no files are left
      if (updatedFiles.length === 0) {
        this.handleCloseDeleteModal();
      }
      return { selectedDeleteFiles: updatedFiles };
    });
  };

  handleDeleteSelected = async () => {
    console.log("handleDeleteSelected");
    const { selectedDeleteFiles } = this.state;
    let hasError = false;

    // Display loading toast message at the start
    const toastId = toast.loading(
      this.tState("deleting_files_please_wait_and_do_not_close_the_page"),
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    );

    // Set loading state to true
    this.setState({ loadingData: true });

    try {
      // Loop through each selected row and delete the file
      for (const selectedRow of selectedDeleteFiles) {
        const { fileId } = selectedRow;
        try {
          // Call the API to delete the file
          await deleteFileDrive(
            fileId,
            this.userDetails.userId,
            this.userDetails.orgId
          );

          // Update the state after deleting the file
          const updatedFilesUploaded = this.state.FilesUploaded.filter(
            (file) => file.fileId !== fileId
          );
          const updatedFileNameListInsideFolder =
            this.state.fileNameListInsideFolder.filter(
              (fileName) =>
                !this.state.selectedFolderData.find(
                  (file) =>
                    file.fileId === fileId &&
                    file.fileName.toLowerCase() === fileName.toLowerCase()
                )
            );

          // Update state after deletion
          this.setState((prevState) => ({
            FilesUploaded: updatedFilesUploaded,
            fileNameListInsideFolder: updatedFileNameListInsideFolder,
            data: prevState.data.filter((item) => item.fileId !== fileId),
            selectedFolderData: prevState.selectedFolderData.filter(
              (item) => item.fileId !== fileId
            ),
            fileIdToDelete: null,
          }));
        } catch (error) {
          console.error("Error deleting file:", error);
          hasError = true;
        }
      }

      // Update toast message after all deletions
      if (!hasError) {
        toast.update(toastId, {
          render: this.tState("all_selected_files_have_been_deleted_successfully"),
          type: toast.TYPE.SUCCESS,
          isLoading: false,
          autoClose: 5000,
        });
      } else {
        toast.update(toastId, {
          render: this.tState("some_files_could_not_be_deleted_please_try_again"),
          type: toast.TYPE.ERROR,
          isLoading: false,
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Error in handleDeleteSelected:", error);
      // Update toast message for general error
      toast.update(toastId, {
        render: this.tState("an_error_occurred_please_try_again"),
        type: toast.TYPE.ERROR,
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      // Set loading state to false
      this.setState({ loadingData: false });

      // Clear the selectedRows state
      this.handleCloseDeleteModal();
      // this.setState({ selectedRows: [] });

      // Refresh the data
      this.fetchDriveData();
    }
  };

  // handleDeleteSelected = async () => {
  //   console.log("handleDeleteSelected");
  //   const { selectedRows } = this.state;

  //   // Loop through each selected row and delete the file
  //   for (const selectedRow of selectedRows) {
  //     try {
  //       const { fileId } = selectedRow;

  //       // Set loading state to true
  //       this.setState({ loadingData: true });

  //       // Call the API to delete the file
  //       await deleteFileDrive(
  //         fileId,
  //         this.userDetails.userId,
  //         this.userDetails.orgId
  //       );

  //       // Update the state after deleting the file
  //       const updatedFilesUploaded = this.state.FilesUploaded.filter(
  //         (file) => file.fileId !== fileId
  //       );
  //       const updatedFileNameListInsideFolder =
  //         this.state.fileNameListInsideFolder.filter(
  //           (fileName) =>
  //             !this.state.selectedFolderData.find(
  //               (file) =>
  //                 file.fileId === fileId &&
  //                 file.fileName.toLowerCase() === fileName.toLowerCase()
  //             )
  //         );

  //       // Update state after deletion
  //       this.setState((prevState) => ({
  //         FilesUploaded: updatedFilesUploaded,
  //         fileNameListInsideFolder: updatedFileNameListInsideFolder,
  //         data: prevState.data.filter((item) => item.fileId !== fileId),
  //         selectedFolderData: prevState.selectedFolderData.filter(
  //           (item) => item.fileId !== fileId
  //         ),
  //         fileIdToDelete: null,
  //       }));

  //       // Show success toast message
  //       toast.success("File deleted successfully", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     } catch (error) {
  //       console.error("Error deleting file:", error);
  //       // Show error toast message
  //       toast.error("Error deleting file. Please try again.", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     } finally {
  //       // Set loading state to false
  //       this.setState({ loadingData: false });
  //     }
  //   }

  //   // Clear the selectedRows state
  //   this.setState({ selectedRows: [] });

  //   // Refresh the data
  //   this.fetchDriveData();
  // };

  getMimeType = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    const mimeTypes = {
      pdf: "application/pdf",
      xml: "application/xml",
      mp3: "audio/mpeg",
      ppt: "application/vnd.ms-powerpoint",
      pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      xls: "application/vnd.ms-excel",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      jpeg: "image/jpeg",
      jpg: "image/jpg",
      png: "image/png",
      mp4: "video/mp4",
      gif: "image/gif",
      svg: "image/svg+xml",
      tiff: "image/tiff",
      tif: "image/tiff",
      ogg: "audio/ogg",
      wav: "audio/wav",
      "7z": "application/x-7z-compressed",
      arj: "application/x-arj",
      pkg: "application/octet-stream",
      rar: "application/x-rar-compressed",
      rpm: "application/x-rpm",
      tar: "application/x-tar",
      zip: "application/zip",
      bin: "application/octet-stream",
      dmg: "application/x-apple-diskimage",
      iso: "application/x-iso9660-image",
      csv: "text/csv",
      dat: "application/octet-stream",
      sql: "application/sql",
      log: "text/plain",
      apk: "application/vnd.android.package-archive",
      jar: "application/java-archive",
      py: "text/x-python",
      bmp: "image/bmp",
      webp: "image/webp",
      asp: "text/asp",
      aspx: "text/aspx",
      odp: "application/vnd.oasis.opendocument.presentation",
      ods: "application/vnd.oasis.opendocument.spreadsheet",
      xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
      txt: "text/plain",
      odt: "application/vnd.oasis.opendocument.text",
      rtf: "application/rtf",
      tex: "application/x-tex",
      doc: "application/msword",
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    };
    return mimeTypes[extension] || "application/octet-stream";
  };

  handleDownloadSelected = async () => {
    const { selectedRows } = this.state;

    const zip = new JSZip();

    const toastId = toast.loading(
      this.tState("downloading_files_please_wait_and_do_not_close_the_page")
    );

    for (const row of selectedRows) {
      const { name, folderPath, folderId } = row;
      let orgId = folderPath.split("/")[1];
      try {
        const response =
          folderId && folderId !== 0
            ? await downloadFileDriveFolder(name, orgId, folderId)
            : await downloadFileDrive(name, orgId);

        //console.log("response", response);

        const mimeType = this.getMimeType(name);
        console.log("mimeType", mimeType);
        const fileBlob = new Blob([response.data], { type: mimeType });
        zip.file(name, fileBlob);
      } catch (error) {
        console.error("Error downloading file:", error);
        toast.update(toastId, {
          render: this.tState('error_downloading_file', { name }),
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      }
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      console.log(content);
      const url = window.URL.createObjectURL(content);
      const a = document.createElement("a");
      a.href = url;
      a.download = "download.zip";
      a.click();
      window.URL.revokeObjectURL(url);
      toast.update(toastId, {
        render: this.tState("files_download_successfully"),
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
    });
    this.setState({ selectedRows: [] });
  };

  // shareSelectedFiles = (selectedRows) => {
  //   this.setState({
  //     openMultipleFileShareModal: true,
  //     selectedFiles: selectedRows,
  //   });
  // };

  // handleCloseShareModal = () => {
  //     this.setState({
  //       openMultipleFileShareModal: false,
  //       selectedRows: [],
  //       selectedFiles: [],
  //     });
  //   };

  handleFileClick = (fileDetails) => {
    console.log("Opening file:", fileDetails);
    this.setState({
      selectedFileToOpen: fileDetails,
    });
  };

  calculateStoragePercentage = (totalStorage, usedStorage) => {
    let percentage = (usedStorage / totalStorage) * 100;
    percentage = Math.floor(percentage);
    // console.log("percentage", percentage);
    this.setState({ storagePercentage: percentage });
  };

  // open menu start

  toggleMenu(event) {
    // console.log(this.state.isMenuOpen);
    this.setState({
      anchorEl: !this.state.isMenuOpen ? event.currentTarget : null,
      isMenuOpen: !this.state.isMenuOpen,
    });
  }
  // open menu end
  shareFile(selectedFile) {
    // console.log("shareFile", selectedFile);
    this.setState({ openShareModal: true, selectedFileToShare: selectedFile });
    // this.state.selectedFileToShare(selectedFile);
  }

  ///////////////////////////////////////////////////drag and drop ///////

  handleDragEnter = (e) => {
    e.preventDefault();
    this.setState({ isDraggingOver: true });
    this.setState({ isBoxOpen: true });
  };

  handleDragLeave = (e) => {
    e.preventDefault();
    this.setState({ isDraggingOver: false });
    this.setState({ isBoxOpen: false });
  };

  handleDragOver = (e) => {
    e.preventDefault();
    this.setState({ isBoxOpen: true });
  };

  handleFileDrop = (e) => {
    e.preventDefault();
    this.setState({ isDraggingOver: false });

    // this.setState({ isBoxOpen: false });
    this.setState({ isBoxOpen: true });
    console.log("handleFileDrop");

    this.setState({
      dragAndDropModal: true,
    });
    console.log("Dropped files:", e.dataTransfer.files);
    const files = Array.from(e.dataTransfer.files);
    this.setState((prevState) => ({
      droppedFiles: [...prevState.droppedFiles, ...files],
    }));
    console.log("dragAndDropModal", this.state.dragAndDropModal);
  };

  handleDragAndDropUpload = (e) => {
    // this.setState({
    //   dragAndDropModal: false,
    // });

    // Show a loading toast notification.
    const id = toast.loading(
      this.tState("uploading_file_please_wait_and_do_not_close_the_page")
    );

    // Get the selected folder ID if available.
    const selectedFolderId = this.state.selectedFolder
      ? this.state.selectedFolder.folderId
      : null;
    console.log("selectedFolderId", selectedFolderId);
    console.log("this.state.selectedFolderData", this.state.selectedFolderData);

    // Get the list of file names inside the selected folder.
    const fileNames = this.state.selectedFolderData.map(
      (file) => file.fileName
    );
    this.setState({ fileNameListInsideFolder: fileNames });
    console.log("fileNames", fileNames);
    console.log(
      "fileNameListInsideFolder",
      this.state.fileNameListInsideFolder
    );

    let dropFiles = [];

    dropFiles = this.state.droppedFiles.filter((item) => {
      const extention = item.name.split(".").pop();

      if (AppUtils.isValidExtention(extention)) {
        if (item.size > AppUtils.maxSizePerFile) {
          console.log("exceeding value");
          toast.error(
            // `Cannot upload file ${item.name}, exceeds maximum file size of 100 MB.`,
            this.tState("drive_file_upload_exceeds_max_size", { fileName: item.name, maxSize: 100 }),
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          return false;
        } else {
          return true;
        }
      } else {
        toast.error(
          // `Cannot upload file ${item.name}, ${extention} is not supported, please check supported extensions at the bottom of drive`,
          this.tState("drive_file_extension_not_supported", { fileName: item.name, extension: extention }),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        return false;
      }
    });

    let numberOfFiles = dropFiles.length;
    // console.log(files.length);

    let fileUploadedCount = 0;
    let filesSize = 0;

    console.log(dropFiles);

    for (let count = 0; count < dropFiles.length; count++) {
      filesSize += dropFiles[count].size;
    }

    console.log(filesSize);
    // console.log(this.state.totalFileSize * 1024 * 1024);
    // console.log(this.state.totalStorage * 1024 * 1024 * 1024);

    let freeSpace =
      this.state.totalStorage * 1024 * 1024 * 1024 -
      this.state.usedStorage * 1024 * 1024;
    // console.log(freeSpace);

    if (filesSize > freeSpace) {
      toast.error(
        // `Cannot upload file(s), Maximum drive storage of ${this.state.totalStorage} GB will be exceeded`,
        this.tState("drive_max_drive_storage_exceeded", { totalStorage: this.state.totalStorage }),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return false;
    }

    // if (filesSize > freeSpace) {
    //   toast.error(
    //     `Cannot upload file(s), Maximum drive storage of ${this.state.totalStorage} GB will be exceeded`,
    //     {
    //       position: toast.POSITION.TOP_RIGHT,
    //     }
    //   );
    //   return false;
    // }

    for (let count = 0; count < dropFiles.length; count++) {
      const formData = new FormData();
      // if (
      //   this.state.fileNameList.includes(dropFiles[count].name.toLowerCase())
      // ) {
      //   toast.warning(`${dropFiles[count].name} already present`, {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      // }
      // Check if the file already exists.
      if (
        selectedFolderId
          ? this.state.fileNameListInsideFolder.includes(
              dropFiles[count].name.toLowerCase()
            )
          : this.state.fileNameList.includes(
              dropFiles[count].name.toLowerCase()
            )
      ) {
        toast.update(id, {
          // render: `${dropFiles[count].name} already present`,
          render: this.tState('drive_fileAlreadyPresent', { fileName: dropFiles[count].name }),
          type: "error",
          isLoading: false,
          autoClose: true,
        });
      } else {
        formData.append("file", dropFiles[count]);
        formData.append("userId", this.userDetails.userId);
        formData.append("orgId", this.userDetails.orgId);
        formData.append(
          "folderId",
          this.state.selectedFolder ? this.state.selectedFolder.folderId : null
        );
        this.setState({ uploading: true });

        uploadFileToDrive(formData).then((response) => {
          console.log("uploadFileToDrive ----", response.data);
          fileUploadedCount++;
          // console.log(fileUploadedCount, numberOfFiles);
          if (fileUploadedCount === numberOfFiles) {
            if (this.state.selectedFolder) {
              this.openFolder(this.state.selectedFolder);
            } else {
              this.fetchDriveData();
            }

            // e.target.value = "";
            // 
            toast.update(id, {
              render: this.tState("file_uploaded_successfully") || "" ,
              type: "success",
              isLoading: false,
              autoClose: true,
            });

            // toast.success(`File(s) uploaded successfully`, {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
            this.setState({ uploading: false });
            this.setState({
              dragAndDropModal: false,
            });
            this.setState({
              droppedFiles: [],
            });
          }
        });
      }
    }
    this.setState({
      droppedFiles: [],
    });
    this.setState({
      dragAndDropModal: false,
    });
    this.setState({ isBoxOpen: false });

    // driveSocket.emit("onFileUploaded", {
    //   userId: this.userDetails.userId,
    // });
  };

  removeFile = (indexToRemove) => {
    this.setState((prevState) => {
      const updatedFiles = prevState.droppedFiles.filter(
        (file, index) => index !== indexToRemove
      );
      return {
        droppedFiles: updatedFiles,
      };
    });
  };

  handleCancelModal = () => {
    this.setState((prev) => ({ ...prev, dragAndDropModal: false }));
    this.setState({
      droppedFiles: [],
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.droppedFiles.length === 0 &&
      prevState.droppedFiles.length > 0
    ) {
      this.setState({ dragAndDropModal: false });
      this.setState({ isBoxOpen: false });
    }
  }

  ///////////////////////////////////////////////////////////////////////

  render() {
    // console.log("this.props",this.props);
    const { t } = this.props;
    this.tState=t;

    const HtmlTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
      },
    }));
    const { isDraggingOver, isBoxOpen } = this.state;
    const { fileIdToDelete } = this.state;
    const { openFileModal, activeFile } = this.state;

    const { original } = this.props;

    const { selectedRows } = this.state;

    // console.log("this.state.data", this.state.data);
    const data = this.state.data
      .map((item) => {
        if (item.isFolder) {
          // Handle folders
          return {
            name: item.folderName,
            size: null,
            owner: this.userDetails.userName,
            date: item.folderCreatedDate,
            isFolder: item.isFolder,
            folderId: item.folderId,
            folderFileCount: item.folderFileCount,
            folderPath: item.folderPath,
            sharedBy: item.sharedBy,
          };
        } else {
          // Handle files
          // console.log("item.fileSize file", item.fileSize);
          return {
            name: item.fileName,
            filesize: Math.ceil(item.fileSize),
            size: parseFloat((item.fileSize / 1024).toFixed(2)),
            // Math.round((item.fileSize / 1024 + Number.EPSILON) * 100) / 100,
            owner: this.userDetails.userName,
            date: item.createdOn,
            isFolder: false,
            fileId: item.fileId,
            folderPath: item.folderPath,
            sharedBy: item.sharedBy,
            folderId: item.folderId,
          };
        }
      })
      .filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(this.state.searchQuery.toLowerCase())
      );

    const selectedFolderData = this.state.selectedFolderData
      .map((item) => {
        if (item.isFolder) {
          // Handle folders
          return {
            name: item.folderName,
            size: null,
            owner: this.userDetails.userName,
            date: item.folderCreatedDate,
            isFolder: item.isFolder,
            folderId: item.folderId,
            folderPath: item.folderPath,
            sharedBy: item.sharedBy,
          };
        } else {
          // Handle files
          // console.log("item.fileSize inside folder", item.fileSize);
          return {
            name: item.fileName,
            filesize: Math.ceil(item.fileSize),
            size: parseFloat((item.fileSize / 1024).toFixed(2)),
            // Math.round((item.fileSize / 1024 + Number.EPSILON) * 100) / 100,
            owner: this.userDetails.userName,
            date: item.createdOn,
            fileId: item.fileId,
            folderPath: item.folderPath,
            sharedBy: item.sharedBy,
            folderId: item.folderId,
          };
        }
      })
      .filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(this.state.searchQuery.toLowerCase())
      );

    const columns = [
      {
        Header: "",
        accessor: "selected",
        width: 50,
        Cell: (props) => (
          <span className="drvfileSize">
            {!props.original.isFolder && !props.original.sharedBy && (
              <Checkbox
                // checked={this.state.isSelect}
                checked={selectedRows.some(
                  (row) => row.fileId === props.original.fileId
                )}
                onChange={(event) =>
                  this.handleRowSelection(event, props.original)
                }
              />
            )}
          </span>
        ),
      },
      // {
      //   Header: "",
      //   accessor: "selected",
      //   width: 50,
      //   Cell: (props) => {
      //     const { selectedRows } = this.state;
      //     const selectedRow = selectedRows[0];

      //     const shouldRenderCheckbox = () => {
      //       if (!selectedRow) return true;
      //       if (
      //         selectedRow.sharedBy === null &&
      //         props.original.sharedBy !== null
      //       )
      //         return false;
      //       if (
      //         selectedRow.sharedBy !== null &&
      //         props.original.sharedBy === null
      //       )
      //         return false;
      //       return true;
      //     };

      //     return (
      //       <span className="drvfileSize">
      //         {!props.original.isFolder && shouldRenderCheckbox() && (
      //           <Checkbox
      //             checked={selectedRows.some(
      //               (row) => row.fileId === props.original.fileId
      //             )}
      //             onChange={(event) =>
      //               this.handleRowSelection(event, props.original)
      //             }
      //           />
      //         )}
      //       </span>
      //     );
      //   },
      // },
      {
        Header: t("file_name"),
        accessor: "name",
        Cell: (props) => {
          // console.log("isFolder:", props.original.isFolder);
          // console.log("props.original", props.original);
          // console.log("value:", props.value);
          // console.log("props", props);

          return (
            <span className="drvfileName">
              {/* <Description /> */}
              {props.original.isFolder ? (
                <Tooltip arrow title={t("open_folder")}>
                  <span
                    onClick={() => this.openFolder(props.original)}
                    style={{ cursor: "pointer" }}
                  >
                    <IconButton>
                      <FolderFill />
                      <Badge
                        // badgeContent={props.original.folderFileCount}
                        color="primary"
                        // sx={{ ".css-106c1u2-MuiBadge-badge":{
                        //     position: "relative !important",left: "-4px", top:"-10px"
                        //   }}}
                        sx={{ top: "-15px" }}
                      />

                      {/* <span className="drvfileName" >
                    {props.original.name}
                    </span> */}
                      {/* {props.value} */}
                    </IconButton>

                    {props.original.name}
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  arrow
                  title={`${
                    this.hasNotViewableExtension(
                      props.original.name.split(".").pop().toLowerCase()
                    )
                      ? t("download_file")
                      : t("open_file")
                  }`}
                >
                  <span
                    onClick={() => this.handleFileClickByType(props.original)}
                    style={{ cursor: "pointer" }}
                  >
                    <IconButton>
                      {this.getFileIcon(
                        props.original.name.split(".").pop().toLowerCase()
                      )}

                      {/* {props.original.name} */}
                      {/* {props.value} */}
                      {/* <span className="drvfileName">
                    {props.original.name}
                    </span> */}
                    </IconButton>

                    {props.original.name}
                  </span>
                </Tooltip>
              )}

              {/* {props.value} */}
            </span>
          );
        },
      },
      {
        Header: t("size"),
        accessor: "filesize",
        width: 90,
        Cell: (props) => (
          <span className="drvfileSize">
            {/* {props.original.isFolder ? "" : props.original.size + " MB"} */}
            {/* {props.original.isFolder
              ? ""
              : props.original.size < 0.1
              ? `${(props.original.size * 1024).toFixed(2)} KB`
              : `${props.original.size} MB`} */}
            {props.original.isFolder
              ? ""
              : props.original.filesize < 100
              ? `${props.original.filesize} KB`
              : `${(props.original.filesize / 1024).toFixed(2)} MB`}
          </span>
        ),
      },
      {
        Header: t("owner"),
        accessor: "owner",
        width: 170,
        Cell: (props) => (
          <span className="drvfileOwner">
            {/* {console.log("props.original.sharedBy-->>", props.original)} */}
            {!props.original.sharedBy
              ? this.userDetails.userName
              : props.original.sharedBy}
          </span>
        ),
      },
      {
        Header: t("last_modified"),
        accessor: "date",
        width: 170,
        Cell: (props) => (
          <span className="drvfileModf">
            {props.original.isFolder
              ? props.original.date
              : props.original.date}
          </span>
        ),
      },
      {
        Header: t("action"),
        width: 170,
        Cell: (props) => (
          <span className="drvfileActionBtnGrp">
            {!selectedRows.length > 0 && !props.original.isFolder && (
              <>
                {!AppUtils.isPersonal(this.userDetails) &&
                  !props.original.sharedBy && (
                    <Tooltip arrow title="Share">
                      <IconButton>
                        <Share
                          onClick={() => {
                            this.shareFile(props.original);
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                {/* <Tooltip arrow title="Copy">
                  <IconButton>
                    <FileCopy />
                  </IconButton>
                </Tooltip> */}

                <Tooltip arrow title={t("download")}>
                  <IconButton
                    onClick={() => {
                      console.log(props.original);
                      this.downloadFile(
                        props.original.fileId,
                        props.original.name,
                        props.original.folderPath,
                        props.original.folderId
                      );
                    }}
                  >
                    <FileDownload />
                  </IconButton>
                </Tooltip>

                {!props.original.sharedBy && (
                  <Tooltip arrow title={t("delete")}>
                    <IconButton
                      className="drvTrshBtn"
                      onClick={(event) =>
                        this.handleClick(event, props.original.fileId)
                      }
                    >
                      <Delete />
                    </IconButton>
                    <Popover
                      id={`delete-task-popover-${props.original.fileId}`}
                      open={Boolean(
                        this.state.anchorElpop[props.original.fileId]
                      )}
                      anchorEl={this.state.anchorElpop[props.original.fileId]}
                      onClose={this.handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <div className="popOverContain" style={{ padding: 16 }}>
                        {/* <p>
                          Are you sure you want to delete {props.original.name} ?
                        </p> */}
                         <p>{t('delete_confirmation', { name: props.original.name })}</p>

                        <div className="popOverBtnGrp">
                          <Button
                            className="dfultDarkSecondaryBtn"
                            onClick={this.handleClose}
                          >
                            {t("no")}
                          </Button>

                          <Button
                            className="dfultPrimaryBtn"
                            onClick={() => {
                              this.deleteFile(fileIdToDelete);
                              this.handleClose();
                            }}
                          >
                            {t("yes")}
                          </Button>
                        </div>
                      </div>
                    </Popover>
                  </Tooltip>
                )}
              </>
            )}
          </span>
        ),
      },
    ];

    // const { anchorElpop } = this.state;
    // const open = Boolean(anchorElpop);

    // const officeUrl = `${collaboraDomain}/browser/abd9205/cool.html?WOPISrc=${apiEndPoint}/wopi/files/${this.state.data.fileId}`;

    // console.log('activeFile', activeFile)

    // const { loadingData } = this.state;

    return (
      <>
        <Box className="driveContainerArea" sx={{ bgcolor: "dark.pageBg" }}>
          <Grid container spacing={0} className="driveContainArea">
            <Grid item xs={12} sx={{ color: "dark.contrastText" }}>
              <div className="driveContainer">
                <div className="driveHeadArea">
                  <div className="driveHedLft">
                    <div className="driveAddBtngrp">
                      <Button
                        className="driveAddBtn"
                        onClick={(event) => {
                          this.toggleMenu(event);
                        }}
                      >
                        <Add /> {t("add")}
                      </Button>

                      {this.state.isMenuOpen && (
                        <Menu
                          anchorEl={this.state.anchorEl}
                          open={this.state.isMenuOpen}
                          onClose={() =>
                            this.setState(() => ({ isMenuOpen: false }))
                          }
                          className="drivAddElemntMenu"
                        >
                          {!this.state.selectedFolder && (
                            <>
                              <MenuItem
                                onClick={() => {
                                  this.setState(() => ({ isMenuOpen: false }));
                                  this.setState(() => ({
                                    creatFileModel: true,
                                  }));
                                }}
                              >
                                <div className="menuwithIcons">
                                  <FolderPlus />
                                  <span>{t("new_folder")}</span>
                                </div>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  console.log("onclick");
                                  this.fileUploadRef.current.click();
                                }}
                              >
                                <div className="menuwithIcons">
                                  <FileEarmarkArrowUp />
                                  <span>{t("upload_file")}</span>
                                </div>
                              </MenuItem>
                              <input
                                ref={this.fileUploadRef}
                                type="file"
                                multiple
                                style={{
                                  position: "absolute",
                                  left: "-9999px",
                                }}
                                onChange={(e) => {
                                  console.log("onchange", e);
                                  this.handleFileUpload(e);
                                  this.fileUploadRef.current.value = null;
                                }}
                                accept={
                                  AppUtils.getValidExtentions() + "image/*"
                                }
                              />
                              <MenuItem
                                onClick={() => {
                                  this.setState(() => ({ isMenuOpen: false }));
                                  this.setState(() => ({
                                    createSlidesModal: true,
                                  }));
                                }}
                              >
                                <div className="menuwithIcons">
                                  <FileEarmarkPpt className="slideColor" />
                                  <span>{t("new_slides")}</span>
                                </div>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  this.setState(() => ({ isMenuOpen: false }));
                                  this.setState(() => ({
                                    createWordModal: true,
                                  }));
                                }}
                              >
                                <div className="menuwithIcons">
                                  <FileEarmarkWord className="wordColor" />
                                  <span>{t("new_word")}</span>
                                </div>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  this.setState(() => ({ isMenuOpen: false }));
                                  this.setState(() => ({
                                    createSheetModal: true,
                                  }));
                                }}
                              >
                                <div className="menuwithIcons">
                                  <FileEarmarkSpreadsheet className="sheetColor" />
                                  <span>{t("new_sheet")}</span>
                                </div>
                              </MenuItem>
                            </>
                          )}
                          {this.state.selectedFolder && (
                            <>
                              <MenuItem
                                onClick={() => {
                                  console.log("onclick");
                                  this.fileUploadRef.current.click();
                                }}
                              >
                                <div className="menuwithIcons">
                                  <FileEarmarkArrowUp />
                                  <span>{t("upload_file")}</span>
                                  {/* Include the input element within the menu item */}
                                  <input
                                    ref={this.fileUploadRef}
                                    type="file"
                                    multiple
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                    onChange={(e) => {
                                      console.log("onchange", e);
                                      this.handleFileUpload(e);
                                      this.fileUploadRef.current.value = null;
                                    }}
                                    accept={
                                      AppUtils.getValidExtentions() + "image/*"
                                    }
                                  />
                                </div>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  this.setState(() => ({ isMenuOpen: false }));
                                  this.setState(() => ({
                                    createSlidesModal: true,
                                  }));
                                }}
                              >
                                <div className="menuwithIcons">
                                  <FileEarmarkPpt className="slideColor" />
                                  <span>{t("new_slides")}</span>
                                </div>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  this.setState(() => ({ isMenuOpen: false }));
                                  this.setState(() => ({
                                    createWordModal: true,
                                  }));
                                }}
                              >
                                <div className="menuwithIcons">
                                  <FileEarmarkWord className="wordColor" />
                                  <span>{t("new_word")}</span>
                                </div>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  this.setState(() => ({ isMenuOpen: false }));
                                  this.setState(() => ({
                                    createSheetModal: true,
                                  }));
                                }}
                              >
                                <div className="menuwithIcons">
                                  <FileEarmarkSpreadsheet className="sheetColor" />
                                  <span>{t("new_sheet")}</span>
                                </div>
                              </MenuItem>
                            </>
                          )}
                        </Menu>
                      )}
                    </div>
                    <div className="driveSearch">
                      <div class="searchUser">
                        <div class="searchIcon">
                          <Search />
                        </div>
                        <TextField
                          class="serchInput"
                          hiddenLabel
                          placeholder={t("search")}
                          onChange={(e) => this.handleSearchChange(e)}
                          value={this.state.searchQuery}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="driveHedRight">
                    <div className="driveSpace">
                      <div className="driveSpIcon">
                        <WbCloudy />
                      </div>
                      <div className="drvAvlSpace">
                        <div className="spaceBar">
                          <div className="spaceFill">
                            <div
                              // up50 up80 up100
                              className="spaceFillData up50"
                              style={{
                                width: `${this.state.storagePercentage}%`,
                                opacity: 1,
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="spaceDtSE">
                          <div className="spaceDtSEFill">
                          {t("used")} <span>{this.state.usedStorage} MB</span>
                          </div>
                          <div className="spaceDtSEAvale">
                            <span>{this.state.totalStorage} GB</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="driveView">
                      <ButtonGroup
                        variant="contained"
                        className="driveViewBtnGrp"
                      >
                        <Tooltip arrow title="List View">
                          <IconButton className="dvBtn dViewActive">
                            <ViewList />
                          </IconButton>
                        </Tooltip>
                        <Tooltip arrow title="Grid View">
                          <IconButton className="dvBtn ">
                            <ViewModule />
                          </IconButton>
                        </Tooltip>
                      </ButtonGroup>
                    </div> */}
                    <div className="drivInfo">
                      <HtmlTooltip
                        title={
                          <>
                            <b>
                              <em>{"Information"}</em>
                            </b>
                            <ul className="htmlTooltipUl">
                              <li>
                                {/* <em>{"E: Editor, V: Viewer"}</em> */}
                                <em>
                                  <strong>E:</strong>
                                </em>{" "}
                                {t("editor")},{" "}
                                <em>
                                  <strong>V:</strong>
                                </em>{" "}
                                {t("viewer")}.
                              </li>
                              <li>
                                <em>
                                  <strong>{t("max_file_size")}:</strong>
                                </em>{" "}
                                100MB
                              </li>
                              <li>
                                {/* {
                                  "Allowed file types .pdf, .xml, .mp3, .ppt, .pptx, .xls, .xslx, .jpeg, .jpg, .png, .mp4, .gif, .svg,"
                                } */}
                                <strong>
                                  <em>{t("allowed_file_types")}:</em>
                                </strong>{" "}
                                .pdf, .xml, .mp3, .ppt, .pptx, .xls, .xslx,
                                .jpeg, .jpg, .png, .mp4, .gif, .svg, .tiff,
                                .tif, .ogg, .wav, .7z, .arj, .pkg, .rar, .rpm,
                                .tar, .zip, .bin, .dmg, .iso, .csv, .dat, .sql,
                                .log, .apk, .jar, .py, .bmp, .webp, .asp, .aspx,
                                .odp, .ods, .xlsm, .xlsx, .txt, .odt, .rtf,
                                .tex, .doc, .docx
                                {/* <Tooltip
                                  title={AppUtils.getValidExtentionsForDisplay(
                                    ".pdf,.xml,.mp3,.ppt,.pptx,.xls,.xslx"
                                  )}
                                  placement="right"
                                  className="driveValidFileToolTip"
                                >
                                  <MoreHorizIcon p={0} m={0} />
                                </Tooltip> */}
                              </li>
                            </ul>
                          </>
                        }
                      >
                        <IconButton className="drivInfoBtn">
                          {/* <QuestionMark /> */}
                          <Info />
                        </IconButton>
                      </HtmlTooltip>
                    </div>
                  </div>
                </div>

                {selectedRows.length > 0 && (
                  <div className="selectedFilesContainer">
                    <IconButton
                      className="closeIcon"
                      onClick={() => this.setState({ selectedRows: [] })}
                      style={{ color: "#ffffff" }}
                    >
                      <Close />
                    </IconButton>
                    <h4 className="selectedFilesTitle">
                      {/* {`Selected ${selectedRows.length} files`} */}
                      {t('selected_count_files', { count: selectedRows.length })}
                      </h4>
                    <div className="selectedFilesActions">
                      {this.state.selectedRows[0].sharedBy === null ? (
                        <>
                          {/* <Button
                        variant="contained"
                        className="drivCheckSlctBtn"
                        // onClick={this.shareSelectedFiles}
                        onClick={() =>
                          this.shareSelectedFiles(this.state.selectedRows)
                        }
                        startIcon={<Share />}
                      >
                        Share Selected Files
                      </Button> */}
                          {/* <Button
                            variant="contained"
                            className="drivCheckSlctBtn"
                            onClick={this.handleDownloadSelected}
                            startIcon={<FileDownload />}
                          >
                            Download Selected Files
                          </Button> */}
                          <Button
                            variant="contained"
                            className="drivCheckSlctBtn"
                            // onClick={this.handleDeleteSelected}
                            onClick={() =>
                              this.deleteSelectedFiles(this.state.selectedRows)
                            }
                            startIcon={<Delete />}
                          >
                            {t("delete_selected_files")}
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="contained"
                          className="drivCheckSlctBtn"
                          onClick={this.handleDownloadSelected}
                          startIcon={<FileDownload />}
                        >
                          {t("download_selected_files")}
                        </Button>
                      )}
                    </div>
                  </div>
                )}

                <div
                  ref={this.dropZoneRef}
                  onDragEnter={this.handleDragEnter}
                  onDragOver={this.handleDragOver}
                  onDragLeave={this.handleDragLeave}
                  onDrop={this.handleFileDrop}
                  style={{
                    position: "relative",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  {this.state.isBoxOpen && (
                    <div
                      className="overlay"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0,0,0,0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 10,
                        color: "#fff",
                        fontSize: "24px",
                      }}
                    >
                     {t("drop_files_to_upload_them")}
                    </div>
                  )}

                  {/* {loadingData && <CircularProgress color="inherit" />}  */}
                  {!this.state.selectedFolder ? (
                    <ReactTable
                      data={data}
                      columns={columns}
                      loading={this.state.loadingData}
                      loadingText={t("loading_drive_data")}
                      sx={{ cursor: "pointer" }}
                      NoDataComponent={() =>
                       !this.state.loadingData && data && data.length === 0 ? (
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            {t("no_files_folder_found")}
                          </div>
                        ) : null
                      }
                    />
                  ) : (
                    <>
                      <div className="driveFolderWthBack">
                        <div className="backDrivBtn">
                          <IconButton
                            onClick={() => {
                              this.setState({
                                selectedFolder: null,
                                selectedFolderData: [],
                              });
                              this.fetchDriveData();
                            }}
                          >
                            <KeyboardBackspace />
                          </IconButton>
                        </div>
                        <div className="drivFolderWithName">
                          <div
                            className="drvPerentFold"
                            onClick={() => {
                              this.setState({
                                selectedFolder: null,
                                selectedFolderData: [],
                              });
                              this.fetchDriveData();
                            }}
                          >
                            <span>{t("drive")}</span>
                          </div>

                          {/* <div className="drvPerentFold">
                        <Folder />
                        <span className="drvFlNm">Client Doc</span>
                      </div> */}
                          <div className="drvActiveFold">
                            <Folder />
                            <span className="drvFlNm">
                              {this.state.selectedFolder.name}
                            </span>
                          </div>
                        </div>
                      </div>
                      <ReactTable
                        data={selectedFolderData}
                        columns={columns}
                        loading={this.state.loadingData}
                        loadingText={t("loading_drive_data")}
                        NoDataComponent={() =>
                         !this.state.loadingData && selectedFolderData && selectedFolderData.length === 0 ? (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              {t("no_files_folder_found")}
                            </div>
                          ) : null
                        }
                      />
                    </>
                  )}
                </div>

                <SwipeableEdgeDrawer
                  className="swipUpDocContainer"
                  filesWithStatus={this.state.fileWithStatus}
                />

                {/* <ReactTable data={data} columns={columns} /> */}
              </div>
            </Grid>
          </Grid>
        </Box>

        {this.state.creatFileModel && (
          <Modal
            open={this.state.creatFileModel}
            onClose={() => this.setState(() => ({ creatFileModel: false }))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="coustomModel"
          >
            <Box
              className="modelBox"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "70vh",
                width: "400px",
                bgcolor: "background.paper",
                boxShadow: 24,
              }}
            >
              <div className="modeltitle">{t("new_folder")}</div>
              <div className="setModelFileName">
                <input
                  type="text"
                  className="modelInputField"
                  placeholder={t("enter_name")}
                  value={this.state.folderName}
                  // onChange={(event) => {
                  //   if (AppUtils.isValidFolderName(event.target.value.trim())) {
                  //     this.setState({
                  //       folderName: event.target.value.trim(),
                  //     });
                  //   }
                  // }}
                  onChange={(event) => {
                    const folderName = event.target.value;
                    // Allow only alphanumeric characters and spaces
                    if (/^[a-zA-Z0-9\s]*$/.test(folderName)) {
                      this.setState({
                        folderName: folderName,
                      });
                    }
                  }}
                />
              </div>
              <div className="modelFooter">
                <div className="modActionBtnGrp">
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={() => {
                      this.createFolder();
                      this.setState(() => ({ folderName: "" }));
                    }}
                  >
                    {t("create")}
                  </Button>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={() =>
                      this.setState(() => ({
                        creatFileModel: false,
                        folderName: "",
                      }))
                    }
                  >
                    {t("cancel")}
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        )}

        {/* {this.state.activeFile && (
          <FileViewer
            fileDetails={this.state.activeFile}
            onClose={this.closeFileViewer}
          />
        )} */}

        {this.state.createFileviewer && (
          <Modal
            open={this.state.createFileviewer}
            onClose={() => this.setState(() => ({ createFileviewer: false }))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="coustomModel"
          >
            <Box
              className="modelBox"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "95vh",
                width: "95vw",
                bgcolor: "background.paper",
                boxShadow: 24,
              }}
            >
              <div
                role="button"
                style={{ cursor: "pointer" }}
                className="closeDilog"
                onClick={() =>
                  this.setState(() => ({ createFileviewer: false }))
                }
              >
                <Cancel />
              </div>
              {/* <div className="modeltitle">{this.state.activeFile.name}</div> */}
              <div className="modeltitle">
                {this.state.openedFilesDetails.length > 0 && (
                  <DocumentEditor
                    officeUrl={`${collaboraDomain}/browser/abd9205/cool.html?WOPISrc=${apiEndPoint}/wopi/files/${this.state.activeFile.fileId}`}
                    fileId={this.state.activeFile.fileId}
                    userId={this.userDetails.userId}
                    sharedBy={this.state.activeFile.sharedBy}
                    fullScreen={this.state.activeFile.fullScreen}
                    fileName={this.state.activeFile.name}
                  />
                )}
              </div>
            </Box>
          </Modal>
        )}

        {this.state.createSlidesModal && (
          <Modal
            open={this.state.createSlidesModal}
            onClose={() => this.setState(() => ({ createSlidesModal: false }))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="coustomModel"
          >
            <Box
              className="modelBox"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "70vh",
                width: "400px",
                bgcolor: "background.paper",
                boxShadow: 24,
              }}
            >
              <div className="modeltitle">{t("create_slides")}</div>
              <div className="setModelFileName">
                <input
                  type="text"
                  className="modelInputField"
                  placeholder={t("enter_file_name")}
                  value={this.state.newFileName}
                  onChange={(e) =>
                    this.setState({ newFileName: e.target.value })
                  }
                />
              </div>
              <div className="modelFooter">
                <div className="modActionBtnGrp">
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={() => {
                      this.onSubmitNewFile(this.state.newFileName, "pptx");
                      this.setState({
                        // createSlidesModal: false,
                        newFileName: "",
                      });
                    }}
                  >
                    {t("create")}
                  </Button>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={() =>
                      this.setState(() => ({
                        createSlidesModal: false,
                        newFileName: "",
                      }))
                    }
                  >
                    {t("cancel")}
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        )}

        {this.state.createWordModal && (
          <Modal
            open={this.state.createWordModal}
            onClose={() => this.setState(() => ({ createWordModal: false }))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="coustomModel"
          >
            <Box
              className="modelBox"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "70vh",
                width: "400px",
                bgcolor: "background.paper",
                boxShadow: 24,
              }}
            >
              <div className="modeltitle">{t("create_word_document")}</div>
              <div className="setModelFileName">
                <input
                  type="text"
                  className="modelInputField"
                  placeholder={t("enter_file_name")}
                  value={this.state.newFileName}
                  onChange={(e) =>
                    this.setState({ newFileName: e.target.value })
                  }
                />
              </div>
              <div className="modelFooter">
                <div className="modActionBtnGrp">
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={() => {
                      this.onSubmitNewFile(this.state.newFileName, "docx");
                      this.setState(() => ({
                        // createWordModal: false,
                        newFileName: "",
                      }));
                    }}
                  >
                    {t("create")}
                  </Button>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={() =>
                      this.setState(() => ({
                        createWordModal: false,
                        newFileName: "",
                      }))
                    }
                  >
                    {t("cancel")}
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        )}

        {this.state.createSheetModal && (
          <Modal
            open={this.state.createSheetModal}
            onClose={() => this.setState(() => ({ createSheetModal: false }))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="coustomModel"
          >
            <Box
              className="modelBox"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "70vh",
                width: "400px",
                bgcolor: "background.paper",
                boxShadow: 24,
              }}
            >
              <div className="modeltitle">{t("create_sheets")}</div>
              <div className="setModelFileName">
                <input
                  type="text"
                  className="modelInputField"
                  placeholder={t("enter_file_name")}
                  value={this.state.newFileName}
                  onChange={(e) =>
                    this.setState({ newFileName: e.target.value })
                  }
                />
              </div>
              <div className="modelFooter">
                <div className="modActionBtnGrp">
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={() => {
                      this.onSubmitNewFile(this.state.newFileName, "xlsx");
                      this.setState(() => ({
                        // createSheetModal: false,
                        newFileName: "",
                      }));
                    }}
                  >
                   {t("create")}
                  </Button>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={() =>
                      this.setState(() => ({
                        createSheetModal: false,
                        newFileName: "",
                      }))
                    }
                  >
                    {t("cancel")}
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        )}

        {this.state.openShareModal && (
          <Modal
            open={this.state.openShareModal}
            // onClose={() => this.setState(() => ({ openShareModal: false }))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="coustomModel"
          >
            <Box
              className="modelBox"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "70vh",
                width: "400px",
                bgcolor: "background.paper",
                boxShadow: 24,
              }}
            >
              <Tooltip arrow title={this.state.selectedFileToShare?.name}>
                <div className="modeltitle">
                {t("share_file")}
                  {/* {this.state.selectedFileToShare?.name} */}
                  {this.state.selectedFileToShare.name.length > 20 ? (
                    <span>
                      {" "}
                      {this.state.selectedFileToShare.name.slice(0, 20)}...
                    </span>
                  ) : (
                    <span> {this.state.selectedFileToShare.name}</span>
                  )}
                </div>
              </Tooltip>
              <div className="setModelFileName">
                <ShareFileDrive
                  selectedFile={this.state.selectedFileToShare}
                  onClose={() =>
                    this.setState(() => ({ openShareModal: false }))
                  }
                />
              </div>
              <div className="modelFooter"></div>
            </Box>
          </Modal>
        )}
        {this.state.selectedFileToOpen && (
          <>
            <DriveChild
              selectedFileToOpen={this.state.selectedFileToOpen}
              userDetails={this.userDetails}
            />
          </>
        )}

        {this.state.openMultipleFileShareModal && (
          <Modal
            open={this.state.openMultipleFileShareModal}
            onClose={this.handleCloseShareModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="coustomModel"
          >
            <Box
              className="modelBox"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "70vh",
                width: "400px",
                bgcolor: "background.paper",
                boxShadow: 24,
              }}
            >
              <div className="modeltitle">
              {t("share_file")}{" "}
                {this.state.selectedFiles.map((file) => (
                  <div key={file.fileId}>{file.name}</div>
                ))}
              </div>
              <div className="setModelFileName">
                <FileMultiShare
                  selectedFile={this.state.selectedFiles}
                  onClose={this.handleCloseShareModal}
                />
              </div>
              <div className="modelFooter"></div>
            </Box>
          </Modal>
        )}

        {this.state.openDeleteModal && (
          <div className="bgOfset">
            <div className="bgOfsetOverlay"></div>
            <Box
              className="ModelBox"
              sx={{
                p: 4,
                boxShadow: 24,
              }}
            >
              <div className="addmoreAgenndaModel">
                <div className="modeltitle">
                {t("are_you_sure_you_want_to_delete_the_following_files")}{" "}
                </div>
                <div className="JDKRAContainer">
                  <ul>
                    {this.state.selectedDeleteFiles.map((file) => (
                      <li
                        key={file.fileId}
                        // style={{ wordBreak: "break-word", marginBottom: "10px" }}
                      >
                        {file.name}
                        <IconButton
                          style={{
                            color: "red",
                            borderRadius: "3px",
                            marginLeft: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => this.handleRemoveFile(file.fileId)}
                        >
                          <Close />
                        </IconButton>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="modActionBtnGrp">
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={this.handleDeleteSelected}
                  >
                    {t("delete")}
                  </Button>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={this.handleCloseDeleteModal}
                  >
                    {t("cancel")}
                  </Button>
                </div>
              </div>
            </Box>
          </div>
        )}

        {/* {this.state.dragAndDropModal && <DriveDragDropFiles/>} */}
        {this.state.dragAndDropModal && (
          <RightFlotingAdmin>
            <DriveDragDropFiles
              droppedFiles={this.state.droppedFiles}
              handleCancelModal={this.handleCancelModal}
              removeFile={this.removeFile}
              handleDragAndDropUpload={this.handleDragAndDropUpload}
            />
          </RightFlotingAdmin>
        )}
      </>
    );
  }
}

export default withTranslation()(DriveContainer);
