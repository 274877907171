import {
  CheckCircle,
  DownloadForOffline,
  Translate,
} from "@mui/icons-material";
import { Autocomplete, Button, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { XLg } from "react-bootstrap-icons";
import TranscriptionDetails from "./TranscriptionDetails";
import SelectSpokenLanguage from "./SelectSpokenLanguage";
import Participants from "./Participants";
import MeetingNotes from "./MeetingNotes";
import FileShare from "./FileShare";
import InviteUsers from "./InviteUsers";
import BreakOutRoom from "./BreakOutRoom";
import WhiteBoard from "./WhiteBoard";
import WebinarChat from "./WebinarChat";
import MeetingParticipants from "./MeetingParticipants";
import { MeetingParticipantsRnd } from "./MeetingParticipantsRnd";
import DoctorConsultations from "./DoctorConsultations";

// export default function MeetingTabRightPanel({
//   handleMenuCloseButton,
//   value,
//   eventId,
//   roomNumber,
//   selEvent,
//   jitsiObject,
// }) {
export default function MeetingTabRightPanel({
  handleMenuCloseButton,
  value,
  eventId,
  roomNumber,
  selEvent,
  jitsiObject,
  isWhiteBoardOpened,
  setIsWhiteBoardOpened,
  extraData,
  closeMeeting,
  prescriptionData,
  updatePrescriptionData
}) {
  const languages = [
    { label: "English" },
    { label: "Bengali" },
    { label: "French" },
    { label: "Spanish" },
    { label: "Arbic" },
  ];
  const [invitedUsersMain, setInvitedUsersMain] = useState([]);
  const [breakoutRoomId, setBreakoutRoomId] = React.useState(null);

  return (
    <>
      <div className="meetRightContainer healthContainer">
        <div className="meetRightContainDtl">
          <div className="metRightInfoContainer">
            {/* <div className="meetRightHeader">
              <div className="metRtTitle">Meeting Transcription</div>
              <div className="metRtAction">
                <IconButton aria-label="Close" onClick={()=>handleMenuCloseButton()}>
                  <XLg />
                </IconButton>
              </div>
            </div> */}
            {/* {value === 0 && (
              <TranscriptionDetails
                handleMenuCloseButton={handleMenuCloseButton}
                roomNumber={roomNumber}
                selEvent={selEvent}
              />
            )} */}
            {/* 
            {value === 1 && (
              <SelectSpokenLanguage
                handleMenuCloseButton={handleMenuCloseButton}
                roomNumber={roomNumber}
                source={"MeetingTabRightPanel"}
              />
            )} */}

            {/* {value === 2 && (
              <Participants
                handleMenuCloseButton={handleMenuCloseButton}
                roomNumber={roomNumber}
                jitsiObject={jitsiObject}
              />
            )} */}
            {value === 0 && (
              <TranscriptionDetails
                handleMenuCloseButton={handleMenuCloseButton}
                roomNumber={roomNumber}
                selEvent={selEvent}
              />
            )}

            {value === 1 && (
              <SelectSpokenLanguage
                handleMenuCloseButton={handleMenuCloseButton}
                roomNumber={roomNumber}
                source={"MeetingTabRightPanel"}
              />
            )}

            {value === 2 && (
              // <Participants
              //   handleMenuCloseButton={handleMenuCloseButton}
              //   roomNumber={roomNumber}
              //   jitsiObject={jitsiObject}
              // />
              <MeetingParticipants
                closeDialog={handleMenuCloseButton}
                roomNumber={roomNumber}
                jitsiObject={jitsiObject}
                reopenDialog={() => {}}
              />

              // <MeetingParticipantsRnd
              //   closeDialog={handleMenuCloseButton}
              //   roomNumber={roomNumber}
              //   jitsiObject={jitsiObject}
              //   reopenDialog={() => {}}
              // />
            )}

            {value === 3 && (
              <MeetingNotes
                handleMenuCloseButton={handleMenuCloseButton}
                eventId={selEvent.eventId}
              />
            )}

            {value === 4 && (
              // <FileShare
              //   handleMenuCloseButton={handleMenuCloseButton}
              //   roomNumber={roomNumber}
              //   jitsiObject={jitsiObject}
              // />
              <InviteUsers
                selEvent={selEvent}
                handleMenuCloseButton={handleMenuCloseButton}
                setInvitedUsersMain={setInvitedUsersMain}
                invitedUsersMain={invitedUsersMain}
              />
            )}

            {value === 5 && (
              <FileShare
                handleMenuCloseButton={handleMenuCloseButton}
                roomNumber={roomNumber}
                jitsiObject={jitsiObject}
              />
            )}

            {value === 6 && (
              <BreakOutRoom
                handleMenuCloseButton={handleMenuCloseButton}
                roomNumber={roomNumber}
                jitsiObject={jitsiObject}
                breakoutRoomId={breakoutRoomId}
                setBreakoutRoomId={setBreakoutRoomId}
                selEvent={selEvent}
              />
            )}

            {value === 7 && (
              // <BreakOutRoom
              //   handleMenuCloseButton={handleMenuCloseButton}
              //   roomNumber={roomNumber}
              //   jitsiObject={jitsiObject}
              //   breakoutRoomId={breakoutRoomId}
              //   setBreakoutRoomId={setBreakoutRoomId}
              //   selEvent={selEvent}
              // />
              <WhiteBoard
                handleMenuCloseButton={handleMenuCloseButton}
                roomNumber={roomNumber}
                jitsiObject={jitsiObject}
                isWhiteBoardOpened={isWhiteBoardOpened}
                setIsWhiteBoardOpened={setIsWhiteBoardOpened}
              />
            )}

            {value === 8 && (
              // <BreakOutRoom
              //   handleMenuCloseButton={handleMenuCloseButton}
              //   roomNumber={roomNumber}
              //   jitsiObject={jitsiObject}
              //   breakoutRoomId={breakoutRoomId}
              //   setBreakoutRoomId={setBreakoutRoomId}
              //   selEvent={selEvent}
              // />
              <WebinarChat
                roomId={roomNumber}
                handleMenuCloseButton={handleMenuCloseButton}
              />
            )}

            {value === 9 && (
              // <BreakOutRoom
              //   handleMenuCloseButton={handleMenuCloseButton}
              //   roomNumber={roomNumber}
              //   jitsiObject={jitsiObject}
              //   breakoutRoomId={breakoutRoomId}
              //   setBreakoutRoomId={setBreakoutRoomId}
              //   selEvent={selEvent}
              // />
              <DoctorConsultations
                // roomId={roomNumber}
                handleMenuCloseButton={handleMenuCloseButton}
                extraData={extraData}
                closeMeeting={closeMeeting}
                prescriptionData={prescriptionData}
                updatePrescriptionData={updatePrescriptionData}
              />
            )}

            {/* start Meeting Transcription */}

            {/* <div className="MeetingTranscriptionContainer">
              <div className="Meeting_Transcription">
                <div className="transcripHeader">
                  <Autocomplete
                    className="transcripAutoComplete"
                    disablePortal
                    id="transcripAutoComplete"
                    options={languages}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        hiddenLabel
                        placeholder="Select Translate Language"
                      />
                    )}
                  />

                  <Button className="transAllBtn" startIcon={<Translate />}>
                    Translate All
                  </Button>
                </div>
                <div className="transcriptionContainArea">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum. Why
                    do we use it? It is a long established fact that a reader
                    will be distracted by the readable content of a page when
                    looking at its layout. The point of using Lorem Ipsum is
                    that it has a more-or-less normal distribution of letters,
                    as opposed to using 'Content here, content here', making it
                    look like readable English. Many desktop publishing packages
                    and web page editors now use Lorem Ipsum as their default
                    model text, and a search for 'lorem ipsum' will uncover many
                    web sites still in their infancy. Various versions have
                    evolved over the years, sometimes by accident, sometimes on
                    purpose (injected humour and the like). Where does it come
                    from? Contrary to popular belief, Lorem Ipsum is not simply
                    random text. It has roots in a piece of classical Latin
                    literature from 45 BC, making it over 2000 years old.
                    Richard McClintock, a Latin professor at Hampden-Sydney
                    College in Virginia, looked up one of the more obscure Latin
                    words, consectetur, from a Lorem Ipsum passage, and going
                    through the cites of the word in classical literature,
                    discovered the undoubtable source. Lorem Ipsum comes from
                    sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
                    Malorum" (The Extremes of Good and Evil) by Cicero, written
                    in 45 BC. This book is a treatise on the theory of ethics,
                    very popular during the Renaissance. The first line of Lorem
                    Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in
                    section 1.10.32. The standard chunk of Lorem Ipsum used
                    since the 1500s is reproduced below for those interested.
                    Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et
                    Malorum" by Cicero are also reproduced in their exact
                    original form, accompanied by English versions from the 1914
                    translation by H. Rackham.
                  </p>
                  <div class="noDataCard">
                    <span>Oops!!</span>No data found !!
                  </div>
                </div>
              </div>
            </div> */}

            {/* End Meeting Transcription */}

            {/* start spoken language  */}
            {/* <div className="MeetingSelectLangContainer">
              <div className="meetLanguagesListContain">
                <div className="meetlangListGrp">
                  <div className="meetLangIndBtn meetLangSelected">
                    <div className="selectedLang">
                      <CheckCircle />
                    </div>
                    <span>English (India)</span>
                  </div>

                 
                </div>
              </div>
            </div>  */}
            {/* end spoken language  */}
            {/* ------------ */}
            {/* ------------ */}
            {/* <div className="MeetingTranscriptionContainer">
              <div className="Meeting_Transcription">

              </div>
             </div> */}

            {/* <div className="MeetRightFooter">
              <div className="meetRightFootBtnGrp">
                <Button
                  className="downloadTranscriptionBtn"
                  startIcon={<DownloadForOffline />}
                >
                  Download
                </Button>
                <Button className="saveMeetRightBtn"> Save </Button>
                <Button className="cancleTransBtn" variant="outlined" onClick={()=>handleMenuCloseButton()}>
                  Close
                </Button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
