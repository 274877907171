import { Button, Popover } from "@mui/material";
import React, { useState } from "react";
import { CheckCircle } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import DashboardServices from "../../../../../../services/DashboardServices";
import { useDispatch } from "react-redux";
import {
  clearSelectedTask,
  setSelectedTask,
} from "../../../../../../redux/reducers/rightSideTaskPanelSlice";
import { getLoginUserDetails } from "../../../../../../utils/Utils";

export const UpdateTask = ({
  data,
  refreshData,
  clearAllModals,
  setProgressWidth,
}) => {
  const loginUserDetail = getLoginUserDetails();
  const numberList = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const [tempUpdatePercent, setTempUpdatePercent] = useState(0);
  const [updatePercentValue, setUpdatePercentValue] = useState(0);
  const dispatch = useDispatch();

  const [approvalConPop, setApprovalConPop] = React.useState(null);
  const open = Boolean(approvalConPop);
  const id = open ? "simple-popover" : undefined;

  const closeApprovalConPop = () => {
    setApprovalConPop(null);
  };

  const openApprovalConPop = (event) => {
    clearAllModals();
    setApprovalConPop(event.currentTarget);
    setUpdatePercentValue(data.progress);
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<CheckCircle />}
        onClick={(e) => {
          clearAllModals();
          openApprovalConPop(e);
        }}
        // onClick={(e) => {
        //   console.log("update clicked");
        //   setShowUpdateModal(true);
        //   setUpdatePercentValue(data.progress);
        // }}
      >
        Update
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={approvalConPop}
        onClose={closeApprovalConPop}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div style={{ padding: "20px" }}>
          <div className="modelTitle">Confirmation</div>
          <div
            className="modInputFild"
            style={{ padding: "20px 10px 10px 0px" }}
          >
            Select Your Progress Of Task
          </div>
          <div className="workUpdateBtnGrp">
            {}
            {numberList &&
              numberList.map((number) => (
                <Button
                  className={`${
                    tempUpdatePercent === number ? "activeStatus" : ""
                  }`}
                  variant="contained"
                  disabled={number <= updatePercentValue}
                  onClick={(e) => {
                    setTempUpdatePercent(number);
                    console.log("");
                  }}
                >
                  {number}
                </Button>
              ))}
          </div>

          <div className="modActionBtnGrp">
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                if (tempUpdatePercent === 0) {
                  toast.error("Please Select Percentage of Work");
                  return;
                }
                DashboardServices.updateProgressOfTask(
                  data.taskId,
                  loginUserDetail.userId,
                  tempUpdatePercent
                ).then((response) => {
                  if (response.data.returnValue !== "0") {
                    toast.success(response.data.message);
                    const temp = { ...data };
                    temp.progress = tempUpdatePercent;
                    dispatch(setSelectedTask(temp));
                    setProgressWidth(tempUpdatePercent);
                    setUpdatePercentValue(tempUpdatePercent);
                    closeApprovalConPop();
                    if (tempUpdatePercent === 100) {
                      dispatch(clearSelectedTask());
                      refreshData();
                    }
                  } else {
                    toast.error(response.data.message);
                  }
                });
              }}
            >
              {tempUpdatePercent === 100 ? "Mark As Completed" : "Update"}
            </Button>
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={closeApprovalConPop}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};
