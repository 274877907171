import { Box, Button, FormControl, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import DashboardServices from "../../../../services/DashboardServices";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { clearSelectedTask } from "../../../../redux/reducers/rightSideTaskPanelSlice";
import { getLoginUserDetails } from "../../../../utils/Utils";

const ResumeModal = ({ data, refreshData, handleClose }) => {
  const [resumeReason, setResumeReason] = useState("");
  const dispatch = useDispatch();
  const loginUserDetail = getLoginUserDetails();

  return (
    <>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">Resume Request</div>

          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  className="modelTextFild"
                  label="Provide  Remark"
                  variant="outlined"
                  multiline
                  rows={4}
                  maxRows={7}
                  value={resumeReason}
                  onChange={(e) => {
                    setResumeReason(e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </div>

          {/* <div className="modInputFild" style={{ border: "1px solid black" }}>
            <TextField
              sx={{ color: "balck !important" }}
              label="Provide Concern Remark"
              variant="outlined"
              multiline
              rows={4}
              maxRows={7}
              value={resumeReason}
              onChange={(e) => {
                setResumeReason(e.target.value);
              }}
            />
          </div> */}

          <div className="modActionBtnGrp">
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                if (resumeReason.trim === "") {
                  toast.error("Please Provide Resume Reason");
                  return;
                }
                DashboardServices.resumeRequest(
                  data.taskId,
                  loginUserDetail.userId,
                  resumeReason
                ).then((response) => {
                  if (response.data.returnValue === "1") {
                    toast.success(response.data.message);
                    dispatch(clearSelectedTask());
                    refreshData();
                    handleClose();
                  } else {
                    toast.error(response.data.message);
                  }
                });
              }}
            >
              Submit
            </Button>
            <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      </Box>
    </>
  );
};

export default ResumeModal;
