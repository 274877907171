import { Add, Edit, Search } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Modal,
  Switch,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getOtpServices,
  activeDeactiveOtpService,
  getActiveOtpServices,
} from "../../../../services/AdminService";
import { checkOtp } from "../../../../utils/OtpServiceUtils";
import { useTranslation } from "react-i18next";

export default function DefineOtpService() {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();

  const [otpData, setOtpData] = useState([]);
  const [otpFilterData, setOtpFilterData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  // const [otp, setOtp] = useState("5678");

  useEffect(() => {
    fetchlogData();
  }, [userDetails.orgId]);

  const fetchlogData = () => {
    if (userDetails.orgId) {
      getOtpServices(userDetails.orgId)
        .then((response) => {
          console.log("OTP Data ======", response.data);
          setOtpData(response.data);
          setOtpFilterData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching OTP data:", error);
        });
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = otpData.filter(
      (otp) =>
        otp.otpServiceName &&
        otp.otpServiceName.toLowerCase().includes(query.toLowerCase())
    );

    setOtpFilterData(filteredData);
  };

  // const handleSwitchChange = (data) =>{
  //   console.log('data' , data);
  //   const reqDto = {
  //       otpServiceId: data.otpServiceId,
  //       otp: otp ,
  //       activeFlag: data.activeFlag
  //     }
  //     console.log('reqDto' , reqDto);

  //     activeDeactiveOtpService(userDetails.userId , reqDto)
  //     .then((response)=>{
  //       console.log('response' , response.data)
  //       if (response.data.returnValue === "1") {
  //           toast.success(response.data.message, {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
  //           fetchlogData();
  //       }
  //     })

  // }

  const fetchActiveOtpData = () => {
    if (userDetails.orgId) {
      getActiveOtpServices(userDetails.orgId).then((response) => {
        console.log("responseotpservicedata ===", response.data);
        if (Array.isArray(response.data) && response.data.length > 0) {
          localStorage.setItem("otpServices", JSON.stringify(response.data));
        }
      });
    }
  };

  const handleSwitchChange = async (data) => {
    try {
      console.log("data", data);

      const { otp, requestId } = await checkOtp(
        "/otp/activeDeactiveOtpService"
      );
      console.log("salaryComponentOTP ------->>>>", otp, requestId);

      const reqDto = {
        otpServiceId: data.otpServiceId,
        otp: otp,
        activeFlag: data.activeFlag === "Y" ? "N" : "Y",
      };
      console.log("reqDto", reqDto);

      const response = await activeDeactiveOtpService(
        userDetails.userId,
        reqDto
      );

      if (response.data.returnValue === "1") {
        toast.success(t("record_updated_successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        fetchlogData();
        fetchActiveOtpData();
      } else {
        toast.error(t("failed_to_update_otp_service_status"));
      }
    } catch (error) {
      console.error("Error updating OTP service status", error);
      toast.error(t("an_error_occurred_while_updating_the_otp_service_status"));
    }
  };

  return (
    <>
      <div className="fullContainArea">
        <div className="cuHeaderGrp">
          <div className="searchTasklist">
            <input
              type="text"
              className="serchTaskInput"
              placeholder={t("search_service")}
              value={searchQuery}
              onChange={handleSearch}
            />
            <IconButton className="seacrhIcon">
              <Search />
            </IconButton>
          </div>
        </div>

        <div className="cuContainArea">
          <div className="TableContainer">
            <table className="taskListTable">
              <thead className="taskListtableHeader">
                <tr>
                  <th>{t("otp_service_name")}</th>
                  {/* <th>{t("api_name")}</th> */}
                  <th className="width100">{t("action")}</th>
                </tr>
              </thead>
              <tbody className="scrolableTableBody">
                {otpFilterData.map((service) => (
                  <tr key={service.otpServiceId}>
                    <td>
                      <div className="tskLstTaskNM">
                        {service.otpServiceName}
                      </div>
                    </td>
                    {/* <td>
                      <div className="tskLstTaskNM">{service.apiName}</div>
                    </td> */}
                    <td>
                      <div className="tblActionBtnGrp">
                        <FormControlLabel
                          className="couponStatusSwitch"
                          control={
                            <Switch
                              checked={service.activeFlag === "Y"}
                              onChange={() => handleSwitchChange(service)}
                              color="primary"
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
