import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Check, Check2Circle, XLg } from "react-bootstrap-icons";
import ProfileService from "../../../../services/ProfileService";
import { Controller, useForm } from "react-hook-form";
import { getLoginUserDetails, validateEmail } from "../../../../utils/Utils";
import { toast } from "react-toastify";
import { useRef } from "react";
import { ArrowForward } from "@mui/icons-material";
import WebsiteTemplateService from "../../../../services/WebsiteTemplateService";
import AppUtils from "../../../../utils/AppUtils";
import { webApp } from "../../../../constants/url_provider";
//------- orgBusinessType ----->
import { PERSONAL_ORG_ID } from "../../../../constants/const_string";
import DashboardServices from "../../../../services/DashboardServices";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CreateOrganization({
  showCreateOrgModel,
  handelOrgCloseModel,
  setShowOnboarding,
  setCreateOrgModel,
  setOnboardingData,
}) {
  const loginUserDetail = getLoginUserDetails();
  const { t } = useTranslation();
  const [countryList, setCountryList] = useState([]);
  const [timezonelist, setTimeZoneList] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [loading, setLoading] = useState(false);
  const inputRefLogo = useRef();
  const [orgLogob64, setOrgLogob64] = useState(null);
  const [orgLogo, setOrgLogo] = useState(null);
  const [reqDto, setReqDto] = useState();
  const [showAgreementModal, setShowAgreementModal] = useState(false);
  const [agreementSigned, setAgreementSigned] = React.useState(false);
  const [CheckValue, setCheckValue] = useState(false);
  const agreementDivRef = React.useRef();
  const [shouldChangeOrgCommonName, setShouldChangeOrgCommonName] =
    useState(true);

  const orgTypes = [
    { id: "OT0001", name: "Public Limited Company" },
    { id: "OT0002", name: "Private Limited Company" },
    { id: "OT0003", name: "Joint-Venture Company" },
    { id: "OT0004", name: "Partnership Firm" },
    { id: "OT0005", name: "One Person Company" },
    { id: "OT0006", name: "Sole Proprietorship" },
    { id: "OT0007", name: "Branch Office" },
    { id: "OT0008", name: "Non-Government Organization (NGO)" },
  ];

  const unitTypes = [
    { id: "Single Unit", name: "Single Unit" },
    { id: "Multiple Unit", name: "Multiple Unit" },
  ];

  //------- orgBusinessType ----->

  const [bussinessTypes, setbussinessTypes] = useState([]);

  useEffect(() => {
    DashboardServices.getOrgBusinessTypes([PERSONAL_ORG_ID]).then(
      (response) => {
        console.log("response", response.data);
        const result = response.data;
        const filterValue = result.filter((val) => val.activeFlag === "Y");
        setbussinessTypes(filterValue);
      }
    );
  }, []);

  console.log("bussinessTypes", bussinessTypes);

  const { register, handleSubmit, control, getValues, setValue, watch } =
    useForm({
      defaultValues: { orgEmailId: loginUserDetail.userEmailId },
    });

  const {
    orgRegAddress,
    addressOfCorrespondence,
    orgName,
    commonName,
    corrBuildingNameNo,
    corrArea,
    corrPinCd,
    corrCity,
    corrCountryCode,
    countryCode,
    regBuildingNameNo,
    regArea,
    regCity,
    regPinCd,
  } = watch();

  useEffect(() => {
    if (shouldChangeOrgCommonName) {
      setValue("commonName", orgName);
    }
  }, [orgName]);

  useEffect(() => {
    console.log("orgRegAddress", orgRegAddress);
    console.log("CheckValue", CheckValue);
    if (CheckValue) {
      setValue("addressOfCorrespondence", getValues("orgRegAddress"));
    } else {
      setValue("addressOfCorrespondence", "");
    }
  }, [orgRegAddress, CheckValue]);

  useEffect(() => {
    ProfileService.getAllTimeZones().then((response) => {
      console.log(response.data);
      setTimeZoneList(response.data);
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // setSelectedTimeZone({ id: tz, name: tz });
    });
    ProfileService.getCountryList().then((response) => {
      console.log("getCountryList", response.data);
      setCountryList(response.data);
    });
  }, []);
  const convertToBase64 = (file) => {
    try {
      return new Promise((resolve) => {
        let baseURL = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          baseURL = reader.result;
          //resolve(baseURL.split(",")[1]);
          console.log(baseURL.split(",")[0]);
          resolve(baseURL);
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleUploadOrgLogo = async (e) => {
    console.log(e);
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setOrgLogo(file);
    const base64String = await convertToBase64(file);
    setOrgLogob64(base64String);

    // Display uploaded image with original aspect ratio and smaller size
    const img = new Image();
    img.src = base64String;
    img.onload = () => {
      const maxWidth = 150; // Maximum width for the image
      const maxHeight = 150; // Maximum height for the image

      let newWidth = img.width;
      let newHeight = img.height;

      if (img.width > img.height && img.width > maxWidth) {
        newWidth = maxWidth;
        newHeight = Math.round((img.height / img.width) * maxWidth);
      } else if (img.height > img.width && img.height > maxHeight) {
        newHeight = maxHeight;
        newWidth = Math.round((img.width / img.height) * maxHeight);
      } else if (img.width === img.height && img.width > maxWidth) {
        newWidth = maxWidth;
        newHeight = maxHeight;
      }

      // Update image dimensions
      img.width = newWidth;
      img.height = newHeight;

      // Update image src
      setOrgLogob64(img.src);
    };
  };

  const createOrganization = () => {
    console.log("creating organization");
    // return;
    try {
      ProfileService.createOrg(reqDto).then((response) => {
        console.log("createOrg--->>>>>>", response.data);
        console.log("createOrg--->>>>>>", response.data.status);
        const orgOnboardingData = JSON.parse(response.data.status);
        console.log("createOrg--->>>>>>", orgOnboardingData);
        if (response.data.returnValue === "1") {
          let reqDtoSubmit = {
            templateName: "template3",
            userId: loginUserDetail.userId,
            orgId: orgOnboardingData.orgId,
            templateId: 3,
            subdomain: reqDto.subDomain,
          };
          console.log("selectWebsiteTemplate-->>>>>", reqDtoSubmit);
          WebsiteTemplateService.selectWebsiteTemplate(reqDtoSubmit).then(
            (response2) => {
              console.log("selectWebsiteTemplate--->>>>>>", response2.data);
              if (response2.data.returnValue === "1") {
                setLoading(false);
                toast.success(t("organization_created_successfully"), {
                  position: toast.POSITION.TOP_RIGHT,
                });
                setShowAgreementModal(false);
                setCreateOrgModel(false);
                setShowOnboarding(true);
                setOnboardingData(orgOnboardingData);
              } else {
                toast.error(t("fail_to_publish_website"), {
                  position: toast.POSITION.TOP_RIGHT,
                });
                setLoading(false);
              }
            }
          );

          //setOrgCreated(true);
          //setTimeout(setShowOrgCreatedDetails(true), 1000);
        } else {
          setLoading(false);
          toast.error(t("something_went_wrong"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    } catch (err) {
      console.log(err.stack);
      ProfileService.clientLogs(err.stack).then((response) => {
        console.log(response.data);
      });
      setLoading(false);
      toast.error(t("something_went_wrong_please_try_again"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleAgreementCheckbox = (event) => {
    if (event.target.checked) {
      console.log("agreement checked");
      setAgreementSigned(true);
    } else {
      console.log("agreement unchecked");
      setAgreementSigned(false);
    }
  };

  // const countrylist = [
  //   {
  //     label: "India",
  //     code: "370",
  //   },
  //   {
  //     label: "USA",
  //     code: "320",
  //   },
  //   {
  //     label: "UAE",
  //     code: "321",
  //   },
  // ];
  // const timezonelist = [
  //   {
  //     label: "(UTC +05:30) Chennai, Kolkata, Mumbai, New Delhi",
  //     lg: "UTC +05:30",
  //   },
  //   {
  //     label: "(UTC +06:30) Chennai, Kolkata, Mumbai, New Delhi",
  //     lg: "UTC +05:30",
  //   },
  //   {
  //     label: "(UTC +07:30) Chennai, Kolkata, Mumbai, New Delhi",
  //     lg: "UTC +05:30",
  //   },
  //   {
  //     label: "(UTC +08:30) Chennai, Kolkata, Mumbai, New Delhi",
  //     lg: "UTC +05:30",
  //   },
  // ];

  const [subdomain, setSubdomain] = useState("");

  const onSubmit = async (data) => {
    console.log("data =", data);
    try {
      // return;

      // console.log(data, orgEmailId);
      // return;
      // setLoading(true);

      let isFormValid = true;
      if (data.orgName === "") {
        toast.error(t("organization_name_is_required"));
        isFormValid = false;
        return;
      }
      if (
        !data.orgUnitType ||
        typeof data.orgUnitType === "undefined" ||
        data.orgUnitType === ""
      ) {
        toast.error(t("organization_unit_type_is_required"));
        isFormValid = false;
        return;
      }
      if (data.orgRegAddress === "") {
        toast.error(t("organization_address_is_required"));

        isFormValid = false;
        return;
      }
      if (data.orgEmailId === "") {
        toast.error(t("organization_email_is_required"));

        isFormValid = false;
        return;
      }
      if (!validateEmail(data.orgEmailId)) {
        toast.error(t("organization_email_is_not_a_valid_email"));
        isFormValid = false;
        return;
        //return false;
      }
      if (data.contactNo === "") {
        toast.error(t("organization_contact_no_is_required"));
        isFormValid = false;
        return;
      }
      if (
        !data.orgType ||
        typeof data.orgType === "undefined" ||
        data.orgType === ""
      ) {
        toast.error(t("organization_type_is_required"));
        isFormValid = false;
        return;
      }
      //------- orgBusinessType ----->
      // if (data.orgBusinessTypes.length === 0) {
      //   toast.error("Please Type/Select Organization business type");

      //   isFormValid = false;
      //   return;
      // }

      if (subdomain === "") {
        toast.error(t("please_provide_a_subdomain_name"));
        isFormValid = false;
        return;
      }

      if (selectedTimeZone && selectedTimeZone.id === "") {
        toast.error(t("please_provide_a_timezone"));
        isFormValid = false;
        return;
      }
      // setLoading(false);
      // return;
      if (subdomain && subdomain.trim() !== "") {
        await ProfileService.duplicateSubdomain(subdomain).then((response) => {
          console.log(response.data);
          if (response.data === "Y") {
            toast.error(t("website_is_not_available"));
            isFormValid = false;
          }
        });
      }
      if (orgLogob64 === null) {
        toast.error(t("please_upload_you_organization_logo"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        isFormValid = false;
      }
      if (!isFormValid) {
        setLoading(false);
        return;
      }

      // if (doiSelected) {
      //   let doi = DateUtils.getDateInDDMMYYYY(doiSelected);
      //   data.doi = doi;
      // }

      let websiteDetails = [];

      let webDtlObj = {
        page: "home",
        key: "logo",
        value: orgLogob64,
      };
      websiteDetails.push(webDtlObj);

      console.log(websiteDetails);
      let tempReqDto = data;
      tempReqDto.timeZone = data.timeZone.id;
      tempReqDto.websiteTemplateDtlList = websiteDetails;
      // console.log(tempReqDto);
      tempReqDto.userId = loginUserDetail.userId;
      tempReqDto.subDomain = subdomain;
      tempReqDto.orgEmailId = data.orgEmailId;
      tempReqDto.countryCode = data.countryCode.id;
      tempReqDto.authPerCountryCode = data.authPerCountryCode;
      tempReqDto.corrCountryCode = data.corrCountryCode.id;
      tempReqDto.orgType = data.orgType.id;
      tempReqDto.singleUnit = data.orgUnitType.id === "Single Unit" ? "Y" : "N";
      console.log("tempReqdto", tempReqDto);

      // return;
      setReqDto(tempReqDto);

      setShowAgreementModal(true);
      setLoading(false);
    } catch (err) {
      console.log(err.stack);
      ProfileService.clientLogs(err.stack).then((response) => {
        console.log(response.data);
      });
      setLoading(false);
      toast.error(t("something_went_wrong_please_try_again"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // const subdomainInput = watch("subDomain", "");

  return (
    <>
      <div className="modelContainer" open={showCreateOrgModel}>
        <Box className="modelContain animated fullwidthModel">
          <IconButton className="closeModelIoc" onClick={handelOrgCloseModel}>
            <XLg />
          </IconButton>

          <div className="fwModelContainArea">
            <div className="fwmHeader">
              {t("payment")} <Check2Circle className="paymentChecked" />{" "}
              <ArrowForward /> {t("create_organization")}
            </div>

            <div className="elementFormContainer">
              <div className="fromHeading">{t("organization_detail")}</div>
              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      required
                      className="modelTextFild"
                      label={t('organization_name')}
                      variant="outlined"
                      multiline
                      {...register("orgName")}
                    />
                  </FormControl>
                </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <Controller
                      name="commonName"
                      control={control}
                      defaultValue={""}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          // required
                          className="modelTextFild"
                          label={t('organization_common_name')}
                          variant="outlined"
                          multiline
                          InputLabelProps={{
                            shrink: commonName ? true : false,
                          }}
                          value={value}
                          onChange={(event) => {
                            setShouldChangeOrgCommonName(false);
                            onChange(event.target.value);
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>

              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <Controller
                      name="orgUnitType"
                      control={control}
                      defaultValue={unitTypes[0]}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          disableClearable
                          className="modelformAutocompleteField"
                          variant="outlined"
                          id="orgUnitType"
                          options={unitTypes}
                          value={value}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, newVal) => {
                            onChange(newVal);
                          }}
                          renderInput={(params) => (
                            <TextField
                              className="formAutoComInputField autocompFildPlaceholder"
                              placeholder={t('select_unit_type')}
                              required
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      // required
                      disabled
                      className="modelTextFild"
                      label={t("email_id")}
                      variant="outlined"
                      multiline
                      value={loginUserDetail.userEmailId}
                      {...register("orgEmailId")}
                    />
                  </FormControl>
                </div>
              </div>

              {/* <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    required
                    className="modelTextFild"
                    label="Registered Address"
                    variant="outlined"
                    multiline
                    rows={3}
                    maxRows={5}
                    {...register("orgRegAddress")}
                  />
                </FormControl>
              </div> */}

              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <Controller
                      name="regBuildingNameNo"
                      control={control}
                      defaultValue={""}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          required
                          className="modelTextFild"
                          label={t('housing_no_building_name')}
                          variant="outlined"
                          multiline
                          value={value}
                          onChange={(e) => {
                            if (CheckValue) {
                              setValue("corrBuildingNameNo", e.target.value);
                            }
                            onChange(e.target.value);
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <Controller
                      name="regArea"
                      control={control}
                      defaultValue={""}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          required
                          className="modelTextFild"
                          label={t('area_colony')}
                          variant="outlined"
                          multiline
                          value={value}
                          onChange={(e) => {
                            if (CheckValue) {
                              setValue("corrArea", e.target.value);
                            }
                            onChange(e.target.value);
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>

              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <Controller
                      name="regCity"
                      control={control}
                      defaultValue={""}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          required
                          className="modelTextFild"
                          label={t('city')}
                          variant="outlined"
                          multiline
                          value={value}
                          onChange={(e) => {
                            if (CheckValue) {
                              setValue("corrCity", e.target.value);
                            }
                            onChange(e.target.value);
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <Controller
                      name="countryCode"
                      control={control}
                      defaultValue={{
                        id: "356",
                        name: "India",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          disableClearable
                          className="modelformAutocompleteField"
                          variant="outlined"
                          id="selectCountry"
                          options={countryList}
                          getOptionLabel={(option) => option.name}
                          value={value}
                          onChange={(e, newValue) => {
                            if (CheckValue) {
                              setValue("corrCountryCode", newValue);
                            }
                            onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              className="formAutoComInputField autocompFildPlaceholder"
                              placeholder={t('select_country')}
                              required
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl width50p ">
                    <Controller
                      name="regPinCd"
                      control={control}
                      defaultValue={""}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          required
                          className="modelTextFild"
                          label={t('pin')}
                          variant="outlined"
                          multiline
                          value={value}
                          onChange={(e) => {
                            if (CheckValue) {
                              setValue("corrPinCd", e.target.value);
                            }
                            onChange(e.target.value);
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>

              <div className="formElement">
                <FormControl className="formControl">
                  <FormGroup aria-label="position" row className="feformGroup">
                    <FormControlLabel
                      className="formCheckBox"
                      style={{ color: "#b2b2b2" }}
                      control={
                        <Checkbox
                          checked={CheckValue}
                          onChange={(e) => {
                            setCheckValue(e.target.checked);
                            setValue("corrCountryCode", countryCode);
                            setValue("corrBuildingNameNo", regBuildingNameNo);
                            setValue("corrArea", regArea);
                            setValue("corrCity", regCity);

                            setValue("corrPinCd", regPinCd);
                          }}
                        />
                      }
                      label={t("same_as_registered_address")}
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </div>

              {/* <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    required
                    className="modelTextFild"
                    label="Correspondence Address"
                    variant="outlined"
                    multiline
                    rows={3}
                    maxRows={5}
                    InputLabelProps={{
                      shrink:
                        addressOfCorrespondence &&
                        addressOfCorrespondence !== ""
                          ? true
                          : false,
                    }}
                    {...register("addressOfCorrespondence")}
                    disabled={CheckValue}
                  />
                 
                </FormControl>
              </div> */}

              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      disabled={CheckValue}
                      required
                      className="modelTextFild"
                      label={t('housing_no_building_name')}
                      variant="outlined"
                      multiline
                      InputLabelProps={{
                        shrink: corrBuildingNameNo ? true : false,
                      }}
                      {...register("corrBuildingNameNo")}
                    />
                  </FormControl>
                </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      required
                      disabled={CheckValue}
                      className="modelTextFild"
                      label={t('area_colony')} 
                      variant="outlined"
                      multiline
                      InputLabelProps={{
                        shrink: corrArea ? true : false,
                      }}
                      {...register("corrArea")}
                    />
                  </FormControl>
                </div>
              </div>

              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      disabled={CheckValue}
                      required
                      className="modelTextFild"
                      label={t('city')} 
                      variant="outlined"
                      InputLabelProps={{
                        shrink: corrCity ? true : false,
                      }}
                      multiline
                      {...register("corrCity")}
                    />
                  </FormControl>
                </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <Controller
                      name="corrCountryCode"
                      control={control}
                      defaultValue={{
                        id: "356",
                        name: "India",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          disableClearable
                          disabled={CheckValue}
                          className="modelformAutocompleteField"
                          variant="outlined"
                          id="selectCountry"
                          options={countryList}
                          getOptionLabel={(option) => option.name}
                          value={value}
                          onChange={(e, newValue) => {
                            onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              className="formAutoComInputField autocompFildPlaceholder"
                              placeholder={t('select_country')}
                              required
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl width50p ">
                    <TextField
                      disabled={CheckValue}
                      required
                      className="modelTextFild"
                      label={t('pin')}
                      variant="outlined"
                      multiline
                      InputLabelProps={{
                        shrink: corrPinCd ? true : false,
                      }}
                      {...register("corrPinCd")}
                    />
                  </FormControl>
                </div>
              </div>

              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      required
                      className="modelTextFild"
                      label={t("contact_number")}
                      variant="outlined"
                      multiline
                      {...register("contactNo")}
                    />
                  </FormControl>
                </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <Controller
                      name="orgType"
                      control={control}
                      defaultValue={null}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          disableClearable
                          className="modelformAutocompleteField"
                          variant="outlined"
                          id="orgType"
                          options={orgTypes}
                          value={value}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, newVal) => {
                            onChange(newVal);
                            // setSelectedTimeZone(newVal);
                          }}
                          renderInput={(params) => (
                            <TextField
                              className="formAutoComInputField autocompFildPlaceholder"
                              placeholder={t("select_org_type_placeholder")}
                              required
                              // {...register("timezone")}
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>

              {/* //------- orgBusinessType -----> */}

              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <Controller
                      name="orgBusinessTypes"
                      control={control}
                      defaultValue={[]}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          multiple
                          disableClearable
                          freeSolo
                          className="modelformAutocompleteField"
                          variant="outlined"
                          id="orgBusinessTypes"
                          options={bussinessTypes || []}
                          value={value}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option?.businessName || ""
                          }
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value?.id || option === value
                          }
                          onChange={(e, newVal) => {
                            const updatedValues = newVal.map((item) => {
                              if (typeof item === "string") {
                                const existingItem = bussinessTypes.find(
                                  (business) =>
                                    business.businessName.toLowerCase() ===
                                    item.toLowerCase()
                                );
                                if (existingItem) {
                                  return existingItem;
                                }
                                return { id: 0, businessName: item };
                              }
                              // If the item is an object from options, return it as is
                              return item;
                            });
                            onChange(updatedValues || []);
                          }}
                          renderInput={(params) => (
                            <TextField
                              className="formAutoComInputField autocompFildPlaceholder"
                              placeholder={t("org_business_types")}
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>

              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      required
                      className="modelTextFild"
                      label={t("auth_person")}
                      variant="outlined"
                      multiline
                      {...register("authPerson")}
                    />
                  </FormControl>
                </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      required
                      className="modelTextFild"
                      label={t("auth_per_contact_no")}
                      variant="outlined"
                      multiline
                      {...register("authPerContactNo")}
                    />
                  </FormControl>
                </div>
              </div>

              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      label={t("trade_lic_no")}
                      variant="outlined"
                      multiline
                      {...register("tradeLicNo")}
                    />
                  </FormControl>
                </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      label={t("cin")}
                      variant="outlined"
                      multiline
                      {...register("cin")}
                    />
                  </FormControl>
                </div>
              </div>

              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      label={t("tax_no")}
                      variant="outlined"
                      multiline
                      {...register("taxNo")}
                    />
                  </FormControl>
                </div>
                {/* <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      label="Address Of Correspondence"
                      variant="outlined"
                      multiline
                      {...register("addressOfCorrespondence")}
                    />
                  </FormControl>
                </div> */}
              </div>

              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <Controller
                      name="timeZone"
                      control={control}
                      defaultValue={{
                        id: "Asia/Calcutta",
                        name: "Asia/Calcutta",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          disableClearable
                          className="modelformAutocompleteField"
                          variant="outlined"
                          id="TimeZone"
                          options={timezonelist}
                          value={value}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, newVal) => {
                            onChange(newVal);
                            // setSelectedTimeZone(newVal);
                          }}
                          renderInput={(params) => (
                            <TextField
                              className="formAutoComInputField autocompFildPlaceholder"
                              placeholder={t("select_timezone")}
                              required
                              // {...register("timezone")}
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                {/* <div className="formElement">
                  <FormControl className="formControl">
                    <Controller
                      name="country"
                      control={control}
                      defaultValue={{
                        id: "356",
                        name: "India",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          disableClearable
                          className="modelformAutocompleteField"
                          variant="outlined"
                          id="selectCountry"
                          options={countryList}
                          getOptionLabel={(option) => option.name}
                          value={value}
                          onChange={(e, newValue) => {
                            onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              className="formAutoComInputField autocompFildPlaceholder"
                              placeholder="Select Country*"
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                  </FormControl>
                </div> */}
              </div>
            </div>

            <div className="elementFormContainer">
              <div className="fromHeading">{t("website")}</div>

              <div className="elementWithPostTextGrp">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      placeholder={t("website_name")}
                      variant="standard"
                      {...register("subDomain")}
                      value={subdomain}
                      onChange={(e) => {
                        console.log(e.currentTarget.value);
                        if (
                          e.currentTarget.value &&
                          e.currentTarget.value.trim() !== ""
                        ) {
                          if (
                            AppUtils.validateSubdomain(
                              e.currentTarget.value.trim().toLowerCase()
                            )
                          ) {
                            setSubdomain(
                              e.currentTarget.value.trim().toLowerCase()
                            );
                          }
                        } else {
                          setSubdomain("");
                        }
                      }}
                      // label="Website name"
                      fullWidth
                      inputProps={{
                        style: { textTransform: "lowercase" },
                        maxLength: 30,
                      }}
                    />
                  </FormControl>
                </div>
                <div className="postOrgUrl">
                  <span>{webApp}</span>
                </div>
                <span className="orgFinalDomain">
                  {subdomain !== "" && `  https://${subdomain}.${webApp}`}
                </span>
              </div>
            </div>

            <div className="elementFormContainer">
              <Button
                variant="contained"
                color="primary"
                onClick={() => inputRefLogo.current.click()}
                sx={{
                  marginBottom: "10px",
                  position: "relative",
                }}
              >
                {t("upload_logo")}
              </Button>
              {/* Hide the crappy looking default HTML input */}
              <input
                ref={inputRefLogo}
                type="file"
                multiple
                style={{ display: "none" }}
                onChange={(e) => {
                  handleUploadOrgLogo(e);
                  inputRefLogo.current.value = null;
                }}
                accept="image/*"
              />

              {null !== orgLogo && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={null != orgLogo ? URL.createObjectURL(orgLogo) : ""}
                  style={{
                    position: "relative",
                  }}
                >
                  <img
                    src={orgLogob64}
                    alt={t("upload_logo")}
                    style={{ maxWidth: "150px", maxHeight: "150px" }}
                  />
                </a>
              )}
            </div>

            <div className="modActionBtnGrp actionsCreateOrg">
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={handelOrgCloseModel}
              >
                {t("camcel")}
              </Button>
              <Button
                className="dfultPrimaryBtn"
                onClick={handleSubmit(onSubmit)}
              >
                {t("submit")}
              </Button>
            </div>
          </div>
        </Box>
      </div>
      {showAgreementModal && (
        <>
          <div className="modelContainer">
            <Box className="modelContain animated fullwidthModel">
              <IconButton
                className="closeModelIoc"
                onClick={(e) => setShowAgreementModal(false)}
              >
                <XLg />
              </IconButton>

              <div
                className="fwModelContainArea"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div className="fwmHeader">Disclaimer</div>
                <div
                  ref={agreementDivRef}
                  className="fwModelContainAreaMainDiv"
                  style={{ overflow: "scroll", maxHeight: "400px" }}
                >
                  {/* <Typography>
                    This agreement governs your use of Zoyel One Suite, an
                    online business productivity and collaboration software,
                    provided by Zoyel One. It establishes the terms and
                    conditions between you (referred to as "You" or "Your") and
                    Zoyel One (referred to as "Zoyel One").
                  </Typography> */}
                   <Typography>
      {t("agreement_text")}
    </Typography>
                  <br />
                  {/* <Typography>
                    To accept the Agreement, you must be of legal age to enter
                    into a binding agreement. If you do not agree to the General
                    Terms, refrain from using our Services. Acceptance of the
                    Agreement can be demonstrated by checking a checkbox,
                    clicking on a button indicating acceptance.
                  </Typography> */}
                   <Typography>
      {t("acceptance_text")}
    </Typography>
                  <br />
                  {/* <Typography>
                    Our company offers cloud software and applications, mobile
                    counterparts, collectively referred to as "Services". These
                    Services can be utilized for both personal and business
                    purposes, either individually or within the organization you
                    represent. Access to the Services is possible through any
                    Internet browser supported by the platform. It is your
                    responsibility to secure Internet access and acquire the
                    necessary equipment to utilize the Services. With your user
                    account, you have the capability to create, modify, publish,
                    and share content as per your preference
                  </Typography> */}
                   <Typography>
      {t("services_description")} 
    </Typography>
                  <br />

                  {/* <Typography>
                    If you sign up for a free trial of our Services, you will
                    have access to a set of applicable Services without charge
                    for the duration of the trial period, unless terminated
                    earlier by you or ZOYEL ONE. The trial period ends when
                    either (i) the free trial period concludes, (ii) the paid
                    subscription period for the Services begins, or (iii) ZOYEL
                    ONE terminates the trial at its discretion. It is important
                    to note that any data entered into the Services and any
                    customizations made during the trial will be permanently
                    lost unless you (i) purchase the corresponding paid
                    subscription plan, (ii) acquire applicable Service upgrades,
                    or (iii) export the data before the trial period ends.
                    Please be aware that during the free trial, the Services are
                    provided "as-is"; without any warranty, covenant, support,
                    or liability, to the extent permissible by law.
                  </Typography> */}
                   <Typography>
      {t("free_trial_description")}
    </Typography>
                  <br />
                  {/* <Typography>
                    To utilize the Services, registration of a user account and
                    providing all necessary information is required. If the
                    Services are being used for internal corporate purposes by
                    an organization, it is advisable for all users to create
                    user accounts using corporate contact details, preferably
                    the corporate email address. By signing up, you agree to
                    provide true, accurate, current, and complete information
                    about yourself and your organization during the registration
                    process and ensure that it remains truthful, accurate,
                    current, and complete by updating it promptly. If you
                    provide any information that is found to be untrue,
                    inaccurate, outdated, or incomplete, or if ZOYEL ONE has
                    reasonable grounds to suspect inaccuracies, ZOYEL ONE
                    reserves the right to terminate your user account and deny
                    present or future access to its Services. In addition to all
                    other terms and conditions of this Agreement, you shall not
                    transfer the Services or otherwise make them available to
                    any third party, or provide any service based on the
                    Services without prior written permission.
                  </Typography> */}
                   <Typography>
      {t("registration_description")} 
    </Typography>
                  <br />
                  {/* <Typography>
                    When signing up for an account for your organization, an
                    administrator account will be created who will have the
                    right to configure the Services according to your
                    requirements.
                  </Typography> */}
                  <Typography>
      {t("admin_account_description")} 
    </Typography>
                  <br />
                  {/* <Typography>
                    Your personal information provided to ZOYEL ONE through the
                    Service is governed by the terms of the agreement. By
                    electing to use the Service, you indicate your acceptance of
                    these terms. It is your responsibility to maintain the
                    confidentiality of your username, password, and other
                    sensitive information. You are accountable for all
                    activities that occur in your user account and agree to
                    promptly notify us of any unauthorized use through email or
                    phone. We are not liable for any loss or damage to you or
                    any third party resulting from unauthorized access or use of
                    your user account, or any other circumstances.
                  </Typography> */}
                   <Typography>
      {t("personal_info_description")} 
    </Typography>
                  <br />
                  {/* <Typography>
                    We acknowledge and respect your ownership rights over the
                    content created or stored by you. You retain ownership of
                    such content. Unless specifically authorized by you, your
                    use of the Services does not grant ZOYEL ONE a license to
                    utilize, reproduce, adapt, modify, publish, or distribute
                    the content you create or store in your user account for
                    their commercial, marketing, or similar purposes. However,
                    you grant ZOYEL ONE permission to access, copy, distribute,
                    store, transmit, reformat, publicly display, and publicly
                    perform the content in your user account, solely for the
                    purpose of providing the Services to you.
                  </Typography> */}
                   <Typography>
      {t("content_ownership_description")} 
    </Typography>
                  <br />
                  {/* <Typography>
                    Your use of the services is entirely at your own risk, as
                    they are provided on an "as-is" and "as-available" basis.
                    Zoyel One explicitly disclaims all warranties of any kind,
                    whether express or implied, including, but not limited to,
                    the implied warranties of merchantability and fitness for a
                    particular purpose. Zoyel One does not guarantee that the
                    services will be uninterrupted, timely, secure, or
                    error-free. You are solely responsible for any damage that
                    may occur to your computer system, mobile phone, wireless
                    device, or data as a result of using the services or
                    downloading any material obtained through them. Any
                    information or advice, whether written or oral, obtained
                    from Zoyel One, its employees, or representatives does not
                    create any warranty not expressly stated in the agreement.
                    Zoyel One will not be liable for any consequential,
                    incidental, indirect, special, punitive, or other loss or
                    damage, including loss of business profits, interruption of
                    business, computer failure, loss of business information, or
                    other loss, resulting from or caused by your use or
                    inability to use the service, even if Zoyel One has been
                    advised of the possibility of such damage.
                  </Typography> */}
                   <Typography>
      {t("use_of_services_risk")} 
    </Typography>
                  <br />
                  {/* <Typography>
                    By accepting these terms, you acknowledge and agree to fully
                    indemnify and protect ZOYEL ONE, including its officers,
                    directors, employees, suppliers, and affiliates, from any
                    and all losses, damages, fines, expenses (including
                    attorney's fees and costs), and liabilities. This
                    indemnification applies to claims arising from or relating
                    to your use of the Services, whether in violation of another
                    party's rights, any law, any provisions of the Agreement, or
                    any other claim, unless such use is expressly authorized by
                    ZOYEL ONE.
                  </Typography> */}
                   <Typography>
      {t("acceptance_and_indemnity")} 
    </Typography>
                  <br />
                  {/* <Typography>
                    In certain circumstances, we have the authority to suspend
                    or temporarily disable your user account, either partially
                    or entirely, for reasons such as suspected illegal activity,
                    extended periods of inactivity, or requests from law
                    enforcement or government agencies. If you object to the
                    suspension, you must communicate your concerns within thirty
                    days of receiving the notification in the below mentioned
                    email id. If no objection is raised, we reserve the right to
                    terminate a suspended or disabled user account after thirty
                    days. Furthermore, we retain the right to terminate your
                    user account and deny access to any Service if we reasonably
                    believe that you have violated the Agreement, or in the
                    event of unexpected technical issues or discontinuation of
                    the Service. You also have the option to request the
                    termination of your user account. Termination of your user
                    account entails the denial of access to all Services, the
                    deletion of your account information (including email
                    address and password), and the deletion of all data
                    associated with your user account.
                  </Typography> */}
                   <Typography>
      {t("account_suspension_and_termination")} 
    </Typography>
                  <br />
                  {/* <Typography>
                    We reserve the right to modify this Agreement, as and when
                    needed. It is your responsibility to stay updated to the
                    changes in the agreement. If you have any questions or
                    concerns about the terms and conditions outlined in this
                    Agreement, please don't hesitate to reach out to us. You can
                    contact us at info@zoyel.one. We are available to address
                    any inquiries or clarifications you may need.
                  </Typography> */}
                  <Typography>
      {t("agreement_modification")} 
    </Typography>
                  <br />
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleAgreementCheckbox}
                          name="termsAndConditions"
                          color="primary"
                        />
                      }
                      label={t("i_accept_the_terms_and_conditions")}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <span
                    // title={`${
                    //   !loading
                    //     ? "Please accept the terms and conditions by scrolling to the bottom of the disclaimer"
                    //     : "Creating organization, please wait..."
                    // }`}
                    title={
                      !loading
                        ? t("terms_message")
                        : t("creating_org_message") 
                    }
                  >
                    <Button
                      // className="dfultPrimaryBtn"
                      sx={{ cursor: loading ? "not-allowed" : "pointer" }}
                      onClick={() => {
                        if (loading) {
                          return false;
                        }

                        if (agreementSigned) {
                          setLoading(true);
                          createOrganization();
                          // setTimeout(() => {
                          //   setLoading(false)
                          // }, 3000)
                        } else {
                          const amountToScroll = 150;
                          const currentPosition =
                            agreementDivRef.current.scrollTop;
                          const scrollHeight =
                            agreementDivRef.current.scrollHeight;
                          const divHeight =
                            agreementDivRef.current.clientHeight;
                          const newPosition = currentPosition + amountToScroll;

                          if (currentPosition + divHeight <= scrollHeight) {
                            agreementDivRef.current.scrollTo({
                              top: newPosition,
                              behavior: "smooth",
                            });
                            // console.log("hello")
                            // agreementDivRef.current.scrollTop += 20
                          }

                          console.log(
                            "Has Reached end",
                            currentPosition + divHeight >= scrollHeight
                          );
                        }
                      }}
                    >
                      {loading && (
                        <CircularProgress
                          sx={
                            {
                              // color: (theme) => theme.palette.grey[100],
                            }
                          }
                          size={20}
                        />
                      )}
                      {!loading && t("i_agree")}
                    </Button>
                    {/* <Button
                      loading={loading}
                      done={orgCreated}
                      // fullWidth
                      variant="contained"
                      // type="submit"
                      // disabled={!agreementSigned}

                      sx={{
                        "&.Mui-disabled": {
                          backgroundColor: "#c4c3c3",
                          color: "black",
                          cursor: "not-allowed !important",
                        },
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      I Agree*
                    </Button> */}
                  </span>
                </div>
                <div className="disclaimerBottom">
                  *{t("disclaimer_message")}
                </div>
              </div>
            </Box>
          </div>
        </>
      )}
    </>
  );
}
