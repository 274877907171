import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { Download, XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

export default function ViewPayrollPayslip({ handelClosePayrollPayslip }) {
  const [pmsFromDate, setPmsFromDate] = useState(new Date());
  const { t } = useTranslation();
  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("employee_pay_slip")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelClosePayrollPayslip()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer payrollEditor">
                <div className="pmsDetailInfoGrp">
                  <div className="pmsDtlIndElement">
                    <div className="pmsDtlInfoTitle"> {t("name")} :</div>
                    <div className="pmsDtlInfoDescn">
                      <span>Sanket Santra</span>
                    </div>
                  </div>
                  <div className="pmsDtlIndElement">
                    <div className="pmsDtlInfoTitle"> {t("id")} :</div>
                    <div className="pmsDtlInfoDescn">
                      <span>MT0005</span>
                    </div>
                  </div>
                  <div className="pmsDtlIndElement">
                    <div className="pmsDtlInfoTitle"> {t("position")} :</div>
                    <div className="pmsDtlInfoDescn">
                      <span>Sr. UI/UX Designer</span>
                    </div>
                  </div>
                  <div className="pmsDtlIndElement">
                    <div className="pmsDtlInfoTitle"> {t("salary_type")} :</div>
                    <div className="pmsDtlInfoDescn">
                      <span>Full Time</span>
                    </div>
                  </div>
                  <div className="pmsDtlIndElement">
                    <div className="pmsDtlInfoTitle"> {t("employement_status")} :</div>
                    <div className="pmsDtlInfoDescn">
                      <span>Confirmed</span>
                    </div>
                  </div>
                  <div className="pmsDtlIndElement">
                    <div className="pmsDtlInfoTitle"> {t("current_ctc")} :</div>
                    <div className="pmsDtlInfoDescn">
                      <span>7.58 LPA</span>
                    </div>
                  </div>
                </div>

                <div class="tskElemHeding mt10 pastSalaryViewGrp">
                  <span>{t("past_salary")}</span>
                  <div className="formElement minMax150">
                    <FormControl className="formControl">
                      <InputLabel
                        id="setCompletionDate"
                        className="setCompletionDate"
                      >
                        {t("select_month")}*
                      </InputLabel>
                      <ReactDatePicker
                        showIcon
                        toggleCalendarOnIconClick
                        labelId="setCompletionDate"
                        className="formDatePicker"
                        dateFormat="MMMM/yyyy"
                        selected={pmsFromDate}
                        onChange={(date) => setPmsFromDate(date)}
                      />
                    </FormControl>
                  </div>
                </div>

                <div class="indentFormtable">
                  <div class="indtableResponsive">
                    <table>
                      <thead>
                        <tr>
                          <th>{t("month")}</th>
                          <th className="curntStrct">{t("net_pay")}</th>
                          <th className="curntStrct">{t("deduction")}</th>
                          <th className="width100"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="textOnly">Jan 2024</div>
                          </td>
                          <td>
                            <div className="curntStrct">48000.00</div>
                          </td>
                          <td>
                            <div className="curntStrct">2000.00</div>
                          </td>
                          <td>
                            <div className="tblActionBtnGrp">
                              <Tooltip arrow title="Download PaySlip">
                                <IconButton>
                                  <Download />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rfContFooter">
            <div className="formBtnElement">
              {/* <Button className="dfultPrimaryBtn" onClick={() => finalSubmit()}>
                Save
              </Button> */}

              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelClosePayrollPayslip()}
              >
                {t("cancel")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
