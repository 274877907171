import authHeader from "../config/auth-header-config";
import http from "../config/http-common";
class AttendanceService {
  markAttendance(userId, orgId, reqDto) {
    return http.post(`attendance/markAttendance/${userId}/${orgId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getAllAttendanceData(orgId, date) {
    return http.get(`attendance/getAllAttendanceData/${orgId}/${date}`, {
      headers: authHeader(),
    });
  }

  updateAttendanceStatus(userId, reqDto) {
    return http.put(`attendance/updateAttendanceStatus/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }
}

export default new AttendanceService();
