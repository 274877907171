import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddInvestigationAdmin({
  handelCloseAddInvestigationAdmin,
}) {
  const { t } = useTranslation();
  const [selectGender, setSelectGender] = useState([]);
  const genderList = ["Male", "Female", "Others"];
  const testList = [
    { testName: "Test 1" },
    { testName: "Test 2" },
    { testName: "Test 3" },
    { testName: "Test 4" },
    { testName: "Test 5" },
    { testName: "Test 6" },
  ];
  const testGroup = [
    { groupName: "Group 1" },
    { groupName: "Group 2" },
    { groupName: "Group 3" },
    { groupName: "Group 4" },
    { groupName: "Group 5" },
    { groupName: "Group 6" },
  ];
  const handleChangeGender = (event) => {
    console.log("handleChangeGender", event.target.value);
    const {
      target: { value },
    } = event;
    setSelectGender(typeof value === "string" ? value.split(",") : value);
  };
  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">Add New Investigation</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddInvestigationAdmin()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <Autocomplete
                      className="formAutocompleteField"
                      variant="outlined"
                      id="selectTestName"
                      options={testList.map((option) => option.testName)}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Add Test Name"
                          required
                          className="formAutoComInputField autocompFildPlaceholder"
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <Autocomplete
                      className="formAutocompleteField"
                      variant="outlined"
                      id="selectTestGroup"
                      options={testGroup.map((option) => option.groupName)}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Group"
                          required
                          className="formAutoComInputField autocompFildPlaceholder"
                        />
                      )}
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="Employee_Gender">
                      {t("select_gender")}*
                    </InputLabel>
                    <Select
                      className="formInputField"
                      variant="outlined"
                      labelId="Employee_Gender"
                      id="EmployeeGender"
                      multiple
                      value={selectGender}
                      onChange={handleChangeGender}
                      input={<OutlinedInput label={t("select_gender")} />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {genderList.map((gender) => (
                        <MenuItem key={gender} value={gender}>
                          <Checkbox
                            checked={selectGender.indexOf(gender) > -1}
                          />
                          <ListItemText primary={gender} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      // hiddenLabel
                      label="Location"
                      required
                      autoComplete="off"
                      variant="outlined"
                      className="formTextFieldArea"
                    />
                  </FormControl>
                </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      // hiddenLabel
                      label="View"
                      required
                      autoComplete="off"
                      variant="outlined"
                      className="formTextFieldArea"
                    />
                  </FormControl>
                </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      // hiddenLabel
                      label="Technique"
                      required
                      autoComplete="off"
                      variant="outlined"
                      className="formTextFieldArea"
                    />
                  </FormControl>
                </div>
              </div>
            </div>
          </div>

          <div className="rfContFooter">
            <div className="formBtnElement">
              <Button className="dfultPrimaryBtn">Save</Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseAddInvestigationAdmin()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
