import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";

import {
  Accessible,
  Add,
  ManageHistoryRounded,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Bar, Pie } from "react-chartjs-2";
import { ArrowRepeat, Eye } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export default function CardBookAppointment({
  handelOpenViewBookedAppointmentConsultation,
  handelOpenViewBookAppointmentDrList,
  chartDataOfItem,
  fetchDashBoardData
}) {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  const { t } = useTranslation();

  const data = {
    // labels: ["Completed", "Queue", "New", "Review"],
    labels: chartDataOfItem.todayDoctorWiseUnitAppointment?.length > 0 ? chartDataOfItem.todayDoctorWiseUnitAppointment.map((item) => item.label) : [] ,
    datasets: [
      {
        label: t("count"),
        // data: [12, 19, 3, 5],
        data: chartDataOfItem.todayDoctorWiseUnitAppointment?.length > 0 ? chartDataOfItem.todayDoctorWiseUnitAppointment.map((item) => item.count) : [],
        backgroundColor: [
          "rgba(79, 212, 0, 1)",
          "rgba(234, 184, 0, 1)",
          "rgba(241, 61, 60, 1)",
          "rgba(254, 162, 59, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, .6)",
          "rgba(255, 255, 255, .6)",
          "rgba(255, 255, 255, .6)",
          "rgba(255, 255, 255, .6)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: "80%",
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  const barLineData = {
    // labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    labels : chartDataOfItem?.todayHourlyUnitAppointment?.length > 0 ? chartDataOfItem.todayHourlyUnitAppointment.map((item) => item.label) : [],
    datasets: [
      {
        label: t("appointment"),
        // data: [12, 19, 3, 5, 2, 3],
        data: chartDataOfItem?.todayHourlyUnitAppointment?.length > 0 ? chartDataOfItem.todayHourlyUnitAppointment.map((item) => item.count) : [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const barLineOoptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "",
      },
    },
  };
  return (
    <>
      <div className="anDashIndCard">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">{t("book_appointment")} 
            <IconButton
            className={`refreshIcon`}
            onClick={fetchDashBoardData}
            >
            <ArrowRepeat />
            </IconButton>
            </div>
            <div className="andCrdUnitCount">
              <span title="Attrition">
                {/* <Male /> */}
                {/* This Month: <span className="fbold"> 215421</span> */}
              </span>

              <span title="Tenure">
                {/* <Female /> */}
                {/* This week: <span className="fbold">12154</span> */}
              </span>
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf">
              <div className="centTxtShow">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={data}
                  options={options}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  <span className="highDataCount">{chartDataOfItem.todayUnitAppointmentCount}</span>
                </div>
              </div>
            </div>
            <div className="anDRightGrf">
              <Bar
                className="hw100"
                type="bar"
                data={barLineData}
                options={barLineOoptions}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>

          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            <Button
              className="dfultPrimaryBtn "
              startIcon={<Eye />}
              onClick={() => handelOpenViewBookedAppointmentConsultation()}
            >
              {t("view_appointment")}
            </Button>
            <Button
              className="dfultPrimaryBtn "
              startIcon={<Accessible />}
              onClick={() => handelOpenViewBookAppointmentDrList()}
            >
              {t("appointment")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
