import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import "../../css/task.css";
import React from "react";
import { useState } from "react";
import { getLoginUserDetails } from "../../../../utils/Utils";
import { toast } from "react-toastify";
import DashboardServices from "../../../../services/DashboardServices";
import { useDispatch } from "react-redux";
import { clearSelectedTask } from "../../../../redux/reducers/rightSideTaskPanelSlice";
import { useTranslation } from "react-i18next";

const DisagreeTask = ({
  taskDetailId,
  assignByUserId,
  userList,
  onClose,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const loginUserDetail = getLoginUserDetails();
  const numberList = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const [tempUpdatePercent, setTempUpdatePercent] = useState(0);

  const [disagreementComment, setDisagreementComment] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);

  const getFilteredUser = () => {
    const taskCreator = assignByUserId;

    let filteredData = userList.filter((item) => {
      return (
        item.userId !== loginUserDetail.userId || item.userId !== taskCreator
      );
    });

    return filteredData;
  };

  const handleDisagreeTask = (e) => {
    let isValidForm = true;
    if (disagreementComment.trim() === "") {
      toast.error(t("please_provide_disagreement_reason"));
      isValidForm = false;
    }
    if (tempUpdatePercent === 0) {
      toast.error(t("please_select_work_progress"));
      isValidForm = false;
    }
    if (isValidForm == false) {
      return;
    }
    setLoading(true);
    DashboardServices.disagreementTask(
      taskDetailId,
      loginUserDetail.userId,
      tempUpdatePercent,
      disagreementComment
    ).then((response) => {
      if (response.data.returnValue === "1") {
        toast.success(t("your_disagreement_has_been_successfully_submitted"));
        refreshData();
        dispatch(clearSelectedTask);
        onClose(e);
      } else {
        toast.error(t("failed_to_submit_your_disagreement_please_try_again"));
      }
      setLoading(false);
    });
  };
  return (
    <>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">{t("reason_over_disagreement")}</div>

          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  required
                  className="modelTextFild"
                  label={t("enter_reason")}
                  variant="outlined"
                  multiline
                  rows={4}
                  maxRows={7}
                  value={disagreementComment}
                  onChange={(e) => {
                    setDisagreementComment(e.target.value);
                  }}
                />
              </FormControl>
            </div>

            <div className="modInputFild" style={{ marginTop: "10px" }}>
            {t("select_your_progress_of_task")}
            </div>
            <div className="workUpdateBtnGrp">
              {}
              {numberList &&
                numberList.map((number) => (
                  <Button
                    className={`${
                      tempUpdatePercent === number ? "activeStatus" : ""
                    }`}
                    variant="contained"
                    onClick={(e) => {
                      setTempUpdatePercent(number);
                      console.log("");
                    }}
                  >
                    {number}
                  </Button>
                ))}
            </div>
          </div>

          {/* <div className="formElement">
            <FormControl className="formControl">
              <Autocomplete
                id="adduser-select"
                disableClearable
                className="formAutocompleteField"
                variant="outlined"
                options={getFilteredUser()}
                getOptionLabel={(option) => option.userName}
                getOptionSelected={(option) => {
                  console.log("option is ", option);
                  return option.userId;
                }}
                onChange={(e, newValue) => {
                  console.log("new value = ", newValue);
                  setSelectedUsers(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="formAutoComInputField"
                    placeholder="Search User"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </FormControl>
          </div> */}

          {/* <div className="modAtchFileBtn">
          <label class="btn-up">
            <input type="file" hidden />
            Attach File
          </label>
        </div> */}
          <div className="modActionBtnGrp">
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                handleDisagreeTask();
              }}
            >
              {t("submit")}
            </Button>
            <Button className="dfultDarkSecondaryBtn" onClick={onClose}>
            {t("cancel")}
            </Button>
          </div>
        </div>
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
      </Box>
    </>
  );
};

export default DisagreeTask;
