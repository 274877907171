import { FormControl, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ArrowRepeat } from "react-bootstrap-icons";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { Bar, Chart, Doughnut, Pie } from "react-chartjs-2";

import "../../../css/websiteStatusCard.css";
import { Web, WebTwoTone } from "@mui/icons-material";
import Button from "../../../../RTE/ui/Button";
import miscService from "../../../../../services/MiscService";
import DateUtils from "../../../../../utils/DateUtils";
export default function CardWebsiteStatus() {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );
  // const pieData = {
  //   labels: ["Uptime", "Downtime"],
  //   datasets: [
  //     {
  //       label: "Website Status",
  //       data: [90, 10],
  //       backgroundColor: ["rgba(75, 192, 192, 0.2)", "rgba(255, 99, 132, 0.2)"],
  //       borderColor: ["rgba(75, 192, 192, 0.2)", "rgba(255, 99, 132, 0.2)"],
  //       borderWidth: 2,
  //     },
  //   ],
  // };

  const pieOptions = {
    responsive: true,

    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  const [pieData, setPieData] = useState(null);
  const [websiteStatus, setWebsiteStatus] = useState(null);
  useEffect(() => {
    console.log("getMassaludWebsiteStatus1");
    miscService.getMassaludWebsiteStatus().then((response) => {
      console.log("getMassaludWebsiteStatus", response.data);

      if (response && response.data) {
        const monitor = response.data.find(
          (item) => item.url === "https://massalud.zoyel.health"
        );
        if (monitor) {
          setWebsiteStatus(monitor);
          console.log("getMassaludWebsiteStatus", monitor?.uptime);
          const uptime = parseFloat(parseFloat(monitor?.uptime) * 100).toFixed(
            2
          );
          console.log(
            "getMassaludWebsiteStatus",
            uptime,
            parseFloat(100 - uptime).toFixed(2)
          );
          setPieData({
            labels: ["Uptime", "Downtime"],
            datasets: [
              {
                label: "Website Status",
                data: [uptime, parseFloat(100 - uptime).toFixed(2)],
                backgroundColor: [
                  "rgba(75, 192, 192, 0.2)",
                  "rgba(255, 99, 132, 0.2)",
                ],
                borderColor: [
                  "rgba(75, 192, 192, 0.2)",
                  "rgba(255, 99, 132, 0.2)",
                ],
                borderWidth: 2,
              },
            ],
          });
        }
      }
    });
  }, []);
  return (
    <>
      <div className="anDashIndCard manageWidth50">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
              Status of massalud.zoyel.health
              {/* <IconButton
                className={`refreshIcon`}
                // onClick={() => fetchRefreshManagementData()}
              >
                <ArrowRepeat />
              </IconButton> */}
            </div>
            <div className="andCrdUnitCount cardFrom">
              {/* <IconButton className={`refreshIcon`} title="View Web page">
                <WebTwoTone />
              </IconButton> */}
              <FormControl size="small" className="formControl">
                <Button
                  onClick={() => {
                    window.open(
                      "https://status-massalud.zoyel.health/",
                      "_blank"
                    );
                  }}
                  className="dfultPrimaryBtn"
                >
                  View Status Page
                </Button>
              </FormControl>
            </div>
          </div>

          <div className="andCrdGraf align-item-center">
            <div className="anDLftGrf">
              <div className="centTxtShow">
                {pieData && (
                  <Pie
                    className="hw100"
                    type="doughnut"
                    data={pieData}
                    options={pieOptions}
                  />
                )}
              </div>
            </div>
            <div className="anDRightGrf">
              <div class="status-card">
                <div class="card-content">
                  <div class="status-info">
                    <span class="label">Status:</span>
                    <span
                      className={`value ${
                        websiteStatus?.status === "online"
                          ? "status-online"
                          : "status-offline"
                      }`}
                    >
                      {websiteStatus?.status}
                    </span>
                  </div>
                  <div class="status-info">
                    <span class="label">UpTime:</span>
                    <span class="value">
                      {websiteStatus &&
                        websiteStatus.uptime &&
                        parseFloat(
                          parseFloat(websiteStatus?.uptime) * 100
                        ).toFixed(2)}{" "}
                      %
                    </span>
                  </div>
                  <div class="status-info">
                    <span class="label">Response Time:</span>
                    <span class="value">
                      {websiteStatus &&
                        websiteStatus.response_time &&
                        parseFloat(websiteStatus?.response_time).toFixed(2) +
                          " Secs"}
                    </span>
                  </div>
                  <div class="status-info">
                    <span class="label">Checked At:</span>
                    <span class="value">
                      {websiteStatus &&
                        websiteStatus.checked_at &&
                        DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
                          websiteStatus.checked_at
                        )}
                    </span>
                  </div>
                  <div class="status-info">
                    <span class="label">SSL Certificate:</span>
                    <span class="value">
                      {websiteStatus?.ssl_certificate_state}
                    </span>
                  </div>
                  {/* <div class="status-info">
                    <span class="label">SSL Expiration:</span>
                    <span class="value">2024-12-16</span>
                  </div> */}
                  <div class="status-info">
                    <span class="label">Uptime Check Frequency:</span>
                    <span class="value">
                      {websiteStatus?.uptime_check_frequency} Secs
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
