import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

export default function EarningComponent() {
  const [selectEarnigType, setSelectEarnigType] = useState("Basic");
  const [paytype, setPaytype] = useState("fixedPay");
  const [calculationtype, setCalculationtype] = useState("flatAmount");
  const [checked, setChecked] = useState([true, true]);
  const [radioValue, setRadioValue] = useState("includeEdli");

  const handleChangeEarnigType = (event) => {
    setSelectEarnigType(event.target.value);
  };

  const handleChangePaytype = (event) => {
    setPaytype(event.target.value);
  };

  const handleChangecalculationtype = (event) => {
    setCalculationtype(event.target.value);
  };

  const [epfContribution, setEpfContribution] = useState("pfwase15");

  const handleChange1 = (event) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const handleChangeEpfContribution = (event) => {
    setEpfContribution(event.target.value);
    setChecked([true, true]); // Ensuring the checkbox is checked when a radio button is selected
  };

  const children = (
    <div className="checkboxWithlist">
      <RadioGroup
        className="formRadioGroup gap0DC"
        name="paytype"
        value={epfContribution}
        onChange={handleChangeEpfContribution}
      >
        <FormControlLabel
          className="formRadioField"
          control={<Radio />}
          label="Always"
          value="always"
        />
        <FormControlLabel
          className="formRadioField"
          control={<Radio />}
          label="Only when PF Wage is less than 15000"
          value="pfwase15"
        />
      </RadioGroup>
    </div>
  );

  return (
    <>
      <div className="tskElemHeding">Earning Type</div>
      <div className="formElement">
        <FormControl className="formControl">
          <InputLabel id="select_EarnigType">Earning Type *</InputLabel>
          <Select
            className="formInputField"
            variant="outlined"
            labelId="select_EarnigType"
            id="Select-Earnig-type"
            value={selectEarnigType}
            label="Earning Type *"
            onChange={handleChangeEarnigType}
            required
          >
            <MenuItem value="Basic">Basic</MenuItem>
            <MenuItem value="HRA">House Rent Allowance</MenuItem>
            <MenuItem value="Bonus">Bonus</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="formElementGroup">
        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              label="Earning Name"
              variant="outlined"
              className="formTextFieldArea"
              required
            />
          </FormControl>
        </div>
        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              label="Name in Payslip"
              variant="outlined"
              className="formTextFieldArea"
              required
            />
          </FormControl>
        </div>
      </div>

      <div className="tskElemHeding mt10">
        Pay Type <span className="asteric">*</span>
      </div>
      <div className="formElement">
        <FormControl className="formControl">
          <RadioGroup
            className="formRadioGroup gap0DC"
            name="paytype"
            value={paytype}
            onChange={handleChangePaytype}
          >
            <FormControlLabel
              className="formRadioField"
              control={<Radio />}
              label="Fixed pay (Fixed amount paid at the end of every month.)"
              value="fixedPay"
            />
            <FormControlLabel
              className="formRadioField"
              control={<Radio />}
              label="Variable Pay (Variable amount paid during any payroll)."
              value="variablePay"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div className="tskElemHeding mt10">
        Calculation Type <span className="asteric">*</span>
      </div>

      <div className="formElement">
        <FormControl className="formControl">
          <RadioGroup
            className="formRadioGroup gap0DC"
            name="paytype"
            value={calculationtype}
            onChange={handleChangecalculationtype}
          >
            <FormControlLabel
              className="formRadioField"
              control={<Radio />}
              label="Flat Amount"
              value="flatAmount"
            />
            <FormControlLabel
              className="formRadioField"
              control={<Radio />}
              label="Percentage of Basic"
              value="percentageBasic"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="formElementGroup ">
        <div className="formElement width50p">
          <FormControl className="formControl">
            {calculationtype === "flatAmount" && (
              <TextField
                label="Enter Amount"
                variant="outlined"
                className="formTextFieldArea"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="prIA">
                      ₹
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {calculationtype === "percentageBasic" && (
              <TextField
                label="Enter Percentage"
                variant="outlined"
                className="formTextFieldArea"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="prIA">
                      %
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </FormControl>
        </div>
      </div>

      <div className="formElement bbsolid">
        <FormControl className="formControl">
          <FormGroup className="formRadioGroup ">
            <FormControlLabel
              className="formRadioField"
              control={<Checkbox />}
              label="Mark this as Active"
            />
          </FormGroup>
        </FormControl>
      </div>

      <div className="tskElemHeding">Other Configurations</div>

      <div className="formElement">
        <FormControl className="formControl">
          <FormGroup className="formRadioGroup gap0DC">
            <FormControlLabel
              disabled
              className="formRadioField"
              control={<Checkbox checked />}
              label="Make this earning a part of the employee's salary structure."
            />
          </FormGroup>
        </FormControl>
      </div>
      <div className="formElement">
        <FormControl className="formControl">
          <FormGroup className="formRadioGroup gap0DC">
            <FormControlLabel
              disabled
              className="formRadioField"
              control={<Checkbox checked />}
              label="This is a taxable earning."
            />
          </FormGroup>
          <div className="textOnlyCheck">
            The income tax amount will be divided equally and deducted every
            month across the financial year.
          </div>
        </FormControl>
      </div>

      <div className="formElement">
        <FormControl className="formControl">
          <FormGroup className="formRadioGroup gap0DC">
            <FormControlLabel
              className="formRadioField"
              control={<Checkbox checked />}
              label="Calculate on pro-rata basis"
            />
          </FormGroup>
          <div className="textOnlyCheck">
            Pay will be adjusted based on employee working days.
          </div>
        </FormControl>
      </div>
      <div className="formElement">
        <FormControl className="formControl">
          <FormGroup className="formRadioGroup gap0DC">
            <FormControlLabel
              className="formRadioField"
              control={<Checkbox />}
              label="Include this as a Flexible Benefit Plan component"
            />
          </FormGroup>
          <div className="textOnlyCheck">
            FBP allows your employees to personalize their salary structure by
            choosing how much they want to receive under each FBP component.
          </div>
        </FormControl>
      </div>

      <div className="empContListCheck">
        <div className="formElement">
          <FormControl className="formControl">
            <FormGroup className="formRadioGroup gap0DC ">
              <FormControlLabel
                className="formRadioField"
                label="Consider for EPF Contribution"
                control={<Checkbox />}
              />
              {children}
            </FormGroup>
          </FormControl>
        </div>
      </div>

      <div className="formElement">
        <FormControl className="formControl">
          <FormGroup className="formRadioGroup gap0DC">
            <FormControlLabel
              className="formRadioField"
              control={<Checkbox />}
              label="Consider for ESI Contribution"
            />
          </FormGroup>
        </FormControl>
      </div>

      <div className="formElement">
        <FormControl className="formControl">
          <FormGroup className="formRadioGroup gap0DC">
            <FormControlLabel
              disabled
              className="formRadioField"
              control={<Checkbox checked />}
              label="Show this conponent in payslip"
            />
          </FormGroup>
        </FormControl>
      </div>

      <div className="fromNotes ">
        <strong>Note:</strong> Once you associate this component with an
        employee, you will only be able to edit the name and Amount/Percentage.
        The changes you make to Amount/Percentage will apply only to new
        employees.
      </div>
    </>
  );
}
