import React from "react";

import vcicon from "../../images/VideoConference.png";
import storageicon from "../../images/Storage.png";
import ofSuiteicon from "../../images/OfficeSuite.png";
import comingSoonImg from "../../images/comingSoon2.png";
import analitic from "../../images/analitic.png";
import aiIcon from "../../images/aiIcon.png";
import webBilderIoc from "../../images/webbilder.png";
import webinerIoc from "../../images/webiner.png";
import crmIoc from "../../images/crm.png";
import { Button } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";

export default function ProfesionalPlan({
  activeStep,
  personalData,
  professionalData,
  handelClickShowPersonal,
  handlepaymentModelOpen,
  handelOrgOpenModel,
}) {
  const [featuresArray, setFeaturesArray] = useState([]);
  const [personalIconArray, setPersonalIconArray] = useState([]);
  const first = useMemo(() => {
    if (featuresArray) {
      const sliced = featuresArray.slice(0, 2);
      console.log("sliced value =", sliced);
      return sliced;
    } else {
      return [];
    }
  }, [featuresArray]);
  const second = useMemo(() => {
    if (featuresArray) {
      const sliced = featuresArray.slice(2, 5);
      console.log("sliced value =", sliced);
      return sliced;
    } else {
      return [];
    }
  }, [featuresArray]);

  const third = useMemo(() => {
    if (featuresArray) {
      const sliced = featuresArray.slice(5, 8);
      console.log("sliced value =", sliced);
      return sliced;
    } else {
      return [];
    }
  }, [featuresArray]);
  // const third = useMemo();

  useEffect(() => {
    if (professionalData) {
      const { otherKeys, featureMap } = professionalData;
      const profDataArray = Array.from(featureMap.entries());
      console.log("professional data array =", professionalData);
      profDataArray.sort((entry1, entry2) => {
        const featuresLength1 = entry1[1].features.length;
        const featuresLength2 = entry2[1].features.length;

        return featuresLength2 - featuresLength1;
      });
      setFeaturesArray(profDataArray);
      let tempPersonal = Array.from(personalData.featureMap.entries());
      // const
      tempPersonal = tempPersonal.map((item) => item[1].headerIcon);
      // console.log();
      setPersonalIconArray(tempPersonal);
    }
  }, []);

  return (
    <>
      <div className="profesionalPlanContainer">
        <div className="proOtherPlanShow">
          <div
            className="personalPlanOverView"
            onClick={() => handelClickShowPersonal()}
          >
            <div className="planContainer">
              <div className="planHeader personalHead">
                <div className="planName">PERSONAL</div>
              </div>
              <div className="personalIconGridShow">
                {/* {personalIconArray &&
                  personalIconArray.map((personalIcon) => {
                    return (
                      <div className="personalIocGrid">
                        <img src={personalIcon} alt="" />
                      </div>
                    );
                  })} */}
                <div className="personalIocGrid">
                  <img src={vcicon} alt="" />
                </div>
                <div className="personalIocGrid noRbord">
                  <img src={ofSuiteicon} alt="" />
                </div>
                <div className="personalIocGrid noBbord">
                  <img src={storageicon} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="enterprisePlanOverView">
            <div className="planContainer">
              <div className="planHeader enterprisehead">
                <div className="planName">Enterprise</div>
              </div>
              <div className="planDetails">
                <div className="comingSoonImg2">
                  <img src={comingSoonImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="profesionalPlanView">
          <div className="planContainer">
            <div className="planHeader  professionalhead">
              <div className="plNm_Price">
                <div className="planName">Professional</div>
                <div className="planprice">
                  {/* <span>$1</span>PER USER/MONTH */}
                  {professionalData.otherKeys.pricingDescription}
                </div>
              </div>

              <div className="upgredBtn">
                <Button
                  onClick={() => {
                    console.log('activeStep' , activeStep)
                    if (activeStep === 1) {
                      handelOrgOpenModel();
                      return;
                    }
                    handlepaymentModelOpen();
                  }}
                >
                  UPGRADE
                </Button>
              </div>
            </div>
            <div className="proPlanDetails">
              {/* <div className="plantext">
                The personal version of Zoyel gives you access to mail office
                suite, storage and more - everything you need to run your daily
                business.
              </div> */}
              <div className="proPlanServGrid">
                <div className="gridColumn">
                  {first &&
                    first.map((item) => {
                      return (
                        <>
                          <div className="gridElement">
                            <div className="gridElHead">
                              <div className="gridElicon">
                                <img src={item[1].headerIcon} alt="" />
                              </div>
                              <div className="gridElNm">
                                {item[1].headerName}
                              </div>
                            </div>
                            <div className="girdElListFun">
                              <ul>
                                {item[1].features &&
                                  item[1].features.map((feat) => {
                                    return <li>{feat}</li>;
                                  })}
                                {/* <li>Collaborative whiteboard.</li>
                                <li>Collaborative and powerful notepad</li>
                                <li>File sharing</li>
                                <li>
                                  Live transcription and live translation for
                                  over 70 supported languages
                                </li>
                                <li>Breakout rooms</li>
                                <li>Encrypted recordings</li>
                                <li>Audio notes and private notes</li>
                                <li>Summary of meetings by Zoyel AI</li>
                                <li>MOM Draft by Zoyel AI</li> */}
                              </ul>
                            </div>
                          </div>
                        </>
                      );
                    })}

                  {/* <div className="gridElement noborder">
                    <div className="gridElHead">
                      <div className="gridElicon">
                        <img src={storageicon} alt="" />
                      </div>
                      <div className="gridElNm">Storage</div>
                    </div>
                    <div className="girdElListFun">
                      <ul>
                        <li>1 GB of storage in Zoyel Drive.</li>
                      </ul>
                    </div>
                  </div> */}
                </div>

                <div className="gridColumn">
                  {second &&
                    second.map((item) => {
                      return (
                        <>
                          <div className="gridElement">
                            <div className="gridElHead">
                              <div className="gridElicon">
                                <img src={item[1].headerIcon} alt="" />
                              </div>
                              <div className="gridElNm">
                                {item[1].headerName}
                              </div>
                            </div>
                            <div className="girdElListFun">
                              <ul>
                                {item[1].features &&
                                  item[1].features.map((feat) => {
                                    return <li>{feat}</li>;
                                  })}
                              </ul>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  {/* <div className="gridElement">
                    <div className="gridElHead">
                      <div className="gridElicon">
                        <img src={ofSuiteicon} alt="" />
                      </div>
                      <div className="gridElNm">Office Suite</div>
                    </div>
                    <div className="girdElListFun">
                      <ul>
                        <li>
                          Powerful Zoyel Calendar with Convenient Meetings and
                          3rd Party integrations
                        </li>
                        <li>
                          Collaborative Zoyel Presentations, Collaborative,
                          Zoyel Spreadsheets, Zoyel Word Processor.
                        </li>
                        <li>Zoyel Chat.</li>
                      </ul>
                    </div>
                  </div>

                  <div className="gridElement">
                    <div className="gridElHead">
                      <div className="gridElicon">
                        <img src={aiIcon} alt="" />
                      </div>
                      <div className="gridElNm">Power of AI</div>
                    </div>
                    <div className="girdElListFun">
                      <ul>
                        <li>Zoyel AI Assistant</li>
                        <li>Summary of meetings by Zoyel AI</li>
                        <li>MOM Draft by Zoyel AI</li>
                      </ul>
                    </div>
                  </div>

                  <div className="gridElement noborder">
                    <div className="gridElHead">
                      <div className="gridElicon">
                        <img src={webBilderIoc} alt="" />
                      </div>
                      <div className="gridElNm">Website Builder</div>
                    </div>
                    <div className="girdElListFun">
                      <ul></ul>
                    </div>
                  </div> */}
                </div>

                <div className="gridColumn">
                  {third &&
                    third.map((item) => {
                      return (
                        <>
                          <div className="gridElement">
                            <div className="gridElHead">
                              <div className="gridElicon">
                                <img src={item[1].headerIcon} alt="" />
                              </div>
                              <div className="gridElNm">
                                {item[1].headerName}
                              </div>
                            </div>
                            <div className="girdElListFun">
                              <ul>
                                {item[1].features &&
                                  item[1].features.map((feat) => {
                                    return <li>{feat}</li>;
                                  })}
                              </ul>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  {/* <div className="gridElement ">
                    <div className="gridElHead">
                      <div className="gridElicon">
                        <img src={analitic} alt="" />
                      </div>
                      <div className="gridElNm">Analytics and Dashboards</div>
                    </div>
                    <div className="girdElListFun">
                      <ul>
                        <li> Organization builder </li>
                        <li>Customized admin dashboard</li>
                        <li>Customizable and actionable analytics</li>
                      </ul>
                    </div>
                  </div>

                  <div className="gridElement">
                    <div className="gridElHead">
                      <div className="gridElicon">
                        <img src={webinerIoc} alt="" />
                      </div>
                      <div className="gridElNm">Webinar</div>
                    </div>
                    <div className="girdElListFun">
                      <ul>
                        <li>Customized website for webinar</li>
                      </ul>
                    </div>
                  </div>

                  <div className="gridElement noborder">
                    <div className="gridElHead">
                      <div className="gridElicon">
                        <img src={crmIoc} alt="" />
                      </div>
                      <div className="gridElNm">CRM</div>
                    </div>
                    <div className="girdElListFun">
                      <ul>
                        <li>Finance and Account workflows</li>
                        <li>CRM</li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="proInfoBtnFloat">
            <Button
              className="proBtnInfo"
              onClick={() => {
                if (activeStep === 1) {
                  handelOrgOpenModel();
                  return;
                }
                handlepaymentModelOpen();
              }}
            >
              Upgrade Now
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
