import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { ArrowRepeat } from "react-bootstrap-icons";
import DiseaseTooltip from "./DiseaseTooltip";
import * as d3 from "d3";
import DashboardServices from "../../../../../services/DashboardServices";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateUtils from "../../../../../utils/DateUtils";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

export default function CardManagementDisease() {
  const { t } = useTranslation();
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const [filterBy, setFilterBy] = useState("daily");
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const chartRef = useRef(null);
  const [diseaseData, setDiseaseData] = useState({});

  const [primaryPosdate, setPrimaryPosdate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );

  const handleprimaryPosdate = (newDate) => {
    setPrimaryPosdate(newDate);
  };

  const [secondaryPosdate, setSecondaryPosdate] = useState(new Date());
  const handleSecondaryPosdate = (newDate) => {
    setSecondaryPosdate(newDate);
  };

  const getMaxDate = (date1) => {
    const date2 = new Date();
    if (!date1) {
      return date2;
    }
    const tempDate = date1 < date2 ? date1 : date2;
    return tempDate;
  };

  useEffect(() => {
    fetchDiseaseData();
  }, [primaryPosdate, secondaryPosdate]);

  const fetchDiseaseData = () => {
    const reqDto = {
      unitId: 0,
      orgId: userDetails.orgId,
      userId: "",
      periodDailyMnthly: "",
      dataLimit: 0,
      feedbackFromPatientDoctor: "",
      fromDate: DateUtils.getDateInDDMMYYYY(primaryPosdate),
      toDate: DateUtils.getDateInDDMMYYYY(secondaryPosdate),
      unitDoctor: "",
    };

    console.log("reqDto", reqDto);

    DashboardServices.refreshManagementDisease(reqDto).then((response) => {
      console.log("response", response.data);
      setDiseaseData(response.data);
    });
  };

  // Sample age group data for the stacked chart
  const data = [
    { ageGroup: "0-10", male: 30, female: 20 },
    { ageGroup: "11-20", male: 50, female: 45 },
    { ageGroup: "21-30", male: 60, female: 55 },
    { ageGroup: "31-40", male: 40, female: 35 },
    { ageGroup: "41-50", male: 35, female: 30 },
    { ageGroup: "51-60", male: 25, female: 20 },
  ];

  // Define margin, width, and height
  const margin = { top: 20, right: 30, bottom: 50, left: 40 }; // Increased bottom margin
  const width = 450 - margin.left - margin.right; // Adjust width
  const height = 180 - margin.top - margin.bottom; // Adjust height

  useEffect(() => {
    if (
      diseaseData &&
      diseaseData.mdDiseases &&
      diseaseData.mdDiseases.length
    ) {
      drawStackedBarChart(diseaseData.mdDiseases);
    }
  }, [diseaseData]);

  const drawStackedBarChart = (data) => {
    // Remove existing chart if present
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right} ${
          height + margin.top + margin.bottom
        }`
      )
      .attr("preserveAspectRatio", "xMinYMin")
      .append("g") // Add a group element to apply margins
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Prepare the data
    // const ageGroups = Object.keys(data[0].ageGroupCount);
    const ageGroups = Array.from(
      new Set(data.flatMap((d) => Object.keys(d.ageGroupCount)))
    );

    // Stack the data by age group counts
    const stack = d3.stack().keys(ageGroups)(
      data.map((d) => ({
        ...d.ageGroupCount,
        diseaseName: d.diseaseName,
        last30DaysCount: d.last30DaysCount,
        genderCount: d.genderCount,
        lastOccurrence: d.lastOccurrence,
        units: d.units,
        icd11Code: d.icd11Code
      }))
    );

    // X scale: based on diseaseName
    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.icd11Code))
      .range([0, width])
      .padding(0.2);

    // Y scale: based on the sum of age group counts
    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(stack[stack.length - 1], (d) => d[1])])
      .nice()
      .range([height, 0]);

    // Color scale for different age groups
    const color = d3
      .scaleOrdinal()
      .domain(ageGroups)
      // .range(d3.schemeCategory10);
      .range(["#66cb00", "#fe9901", "#fe0000", "#cc0098", "#0051d4"]);

    // Create the stacked bars
    svg
      .selectAll("g")
      .data(stack)
      .enter()
      .append("g")
      .attr("fill", (d) => color(d.key))
      // .attr("fill", (d) => {
      //   if (d.key === 'Category1') {
      //     return "#ff0000"; // Red
      //   } else if (d.key === 'Category2') {
      //     return "#00ff00"; // Green
      //   } else {
      //     return "#0000ff"; // Blue
      //   }
      // })
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d.data.icd11Code))
      .attr("y", (d) => yScale(d[1]))
      // .attr("height", (d) => yScale(d[0]) - yScale(d[1]))
      .attr("height", (d) => {
        const heightValue = yScale(d[0]) - yScale(d[1]);
        return heightValue >= 0 ? heightValue : 0;
      })
      // .attr("width", xScale.bandwidth())
      .attr("width", 12)
      .on("click", (event, d) => {
        // Show tooltip on hover
        console.log("d.data", d.data);
        setTooltipData(d.data);
        setTooltipPosition({ x: event.pageX, y: event.pageY });
      })
      .on("mousemove", (event) => {
        // Move tooltip with the mouse
        setTooltipPosition({ x: event.pageX, y: event.pageY });
      })
      .on("mouseout", () => {
        // Hide tooltip
        setTooltipData(null);
      });

    // Add X axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(xScale))
      .selectAll("text") // Select all text elements
      .attr("transform", "rotate(-45)") // Rotate labels
      .attr("dx", "-0.8em") // Adjust x position
      .attr("dy", ".15em") // Adjust y position
      .style("text-anchor", "end")
      .style("fill", "#B2B2B2") // Align text to the end
      .style("font-size", "8px");

    // Add Y axis
    svg
      .append("g")
      .call(d3.axisLeft(yScale))
      .selectAll("text")
      .style("font-size", "8px") // Adjust font size if necessary
      .style("fill", "#B2B2B2");
    // .style("fill", "#000"); // Set a color for visibility

    const legend = svg
    .append("g")
    .attr("transform", `translate(${width - 16}, -20)`);

  ageGroups.forEach((ageGroup, i) => {
    const legendRow = legend.append("g").attr("transform", `translate(0, ${i * 15})`);

    legendRow
      .append("rect")
      .attr("width", 12)
      .attr("height", 12)
      .attr("fill", color(ageGroup));

    legendRow
      .append("text")
      .attr("x", 14)
      .attr("y", 10)
      .style("fill", "#B2B2B2")
      .attr("text-anchor", "start")
      .style("font-size", "12px")
      .text(ageGroup);
  });
  };

  const handleClose = () => {
    console.log("close");
    setTooltipData(null);
  };

  return (
    <>
      <div className="anDashIndCard manageWidth50">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
              {t("disease")}
              <IconButton
                className={`refreshIcon`}
                onClick={() => fetchDiseaseData()}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount cardFrom">
              <div className="formElement maxw140">
                <FormControl size="small" className="formControl w110 ">
                  <InputLabel id="primPosition" className="setCompletionDate">
                    {t("from_date")}
                  </InputLabel>
                  <ReactDatePicker
                    toggleCalendarOnIconClick
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showMonthDropdown
                    showIcon
                    labelId="primPosition"
                    className="formDatePicker"
                    selected={primaryPosdate}
                    // minDate={new Date()}
                    maxDate={primaryPosdate}
                    // maxDate={new Date()}
                    // minDate={primaryPosdate}
                    // maxDate={getMaxDate(
                    //   primaryPosdate
                    //     ? new Date(
                    //         primaryPosdate.getFullYear(),
                    //         primaryPosdate.getMonth() + 3,
                    //         primaryPosdate.getDate()
                    //       )
                    //     : null
                    // )}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      handleprimaryPosdate(date);
                    }}
                  />
                </FormControl>
              </div>
              <div className="formElement maxw140">
                <FormControl size="small" className="formControl w110 ">
                  <InputLabel id="primPosition" className="setCompletionDate">
                    {t("to_date")}
                  </InputLabel>
                  <ReactDatePicker
                    toggleCalendarOnIconClick
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showMonthDropdown
                    showIcon
                    labelId="primPosition"
                    className="formDatePicker"
                    selected={secondaryPosdate}
                    // minDate={new Date()}
                    minDate={primaryPosdate}
                    maxDate={new Date()}
                    // maxDate={getMaxDate(
                    //   primaryPosdate
                    //     ? new Date(
                    //         primaryPosdate.getFullYear(),
                    //         primaryPosdate.getMonth() + 3,
                    //         primaryPosdate.getDate()
                    //       )
                    //     : null
                    // )}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      handleSecondaryPosdate(date);
                    }}
                  />
                </FormControl>
              </div>
            </div>
          </div>

          <div className="andCrdGraf align-items-center">
            <div className="anDCenterGrf  pRelative">
              {/* Age group wise bar chart. On hover there will be a geo chart that
              will display location wise this disease . Sharing of male females.
              Last occurance. Last 30 day occurrence count etc */}
              <div ref={chartRef}></div>
              {tooltipData && (
                <DiseaseTooltip
                  data={tooltipData}
                  position={tooltipPosition}
                  handleClose={handleClose}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
